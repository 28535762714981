import Api from "./Base";
import { API } from "constants/Constants";

const { FILE_RECORD } = API;

export function addFileRecord(data: any) {
  return Api.post(FILE_RECORD.BASIC, data);
}

export function fetchFileRecord() {
  return Api.get(FILE_RECORD.BASIC);
}

export function getFileRecordByID(id: any) {
  return Api.get(`${FILE_RECORD.BASIC}/${id}`);
}

export function searchFileRecord(data: any) {
  return Api.get(FILE_RECORD.BASIC, data);
}

export function updateFileRecord(data: any, id: any) {
  return Api.put(`${FILE_RECORD.BASIC}/${id}`, data);
}

export function uploadFileRecord(data: any) {
  return Api.postFormData(FILE_RECORD.UPLOAD_FILE_RECORD, data);
}
