import styled from "styled-components";
import ColorName from "constants/Variables";

const Wrapper = styled.div`
  display: flex;
  padding: 1em;
  display: flex;
  place-content: center;
  & img {
    width: 20px;
    margin-right: 5px;
  }
`;

const Link = styled.a`
  display: flex;
  width: 34px;
  justify-content: center;
  height: 34px;
  border-radius: 8px;
  color: ${ColorName.william};
  align-items: center;
  margin: 0 5px;
  cursor: pointer;
  user-select: none;
  &.arrow {
    background: ${ColorName.zircon};
    color: ${ColorName.regentGray};
    margin: 0 3px;
    &.clickable {
      color: ${ColorName.william};
      cursor: pointer;
    }
  }
  &.active {
    background: #f8faff;
    color: ${ColorName.william};
    box-shadow: 0px 4px 4px rgba(62, 138, 182, 0.25);
    border-radius: 7px;
  }
  &.for-more {
    color: ${ColorName.william};
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(62, 138, 182, 0.25);
    border-radius: 7px;
  }
  &.disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
`;

export { Wrapper, Link };
