const images = {
  bgReport: require("../assets/images/bg_report.svg"),
  logo: require("../assets/images/logo.svg"),
  notFound: require("../assets/images/not_found.jpg"),
  // defaultLogo: require("../assets/images/default-logo.svg"),
  defaultLogo: require("../assets/images/default_logo.png"),
  albanian: require("../assets/images/albanian.png"),
  english: require("../assets/images/english.png"),
  emptyArticles: require("../assets/images/empty_articles.jpg"),
  defaultPhoto: require("../assets/images/default_photo.png"),
  logoMobile: require("../assets/images/logo_mobile.png"),
  guideToSignIn: require("../assets/images/guide_to_signin.png"),
};

export default images;
