/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  SourceSettingsActions,
  CategoriesActions,
  DataRecordsActions,
} from "actions";
import { Button } from "components/Common/CommonLayout";
import {
  Dropdown,
  Input,
  FormGroup,
  FormLabel,
  FormInline,
  TagArea,
  LoadingLayer,
  Spinner,
  SelectDatetime,
} from "components/Common";
import { generateDropdown, popupAlert } from "libs/Utils";
import { RootState } from "reducers";
import {
  TemplateDetailsContent,
  TemplateDetailsContainer,
  TemplateDetailsForm,
  ButtonField,
  TemplateDetailsHeader,
} from "../Popup.styles";

const { fetchAllCategories } = CategoriesActions;
const { fetchAllSource } = SourceSettingsActions;
const { createDataRecord, updateDataRecord, setDefaultSendPostStatus } =
  DataRecordsActions;

interface SectionProps {
  onClose(isFetch?: boolean): void;
  data: any;
}

const LANGUAGE_OPTIONS = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "Albanian",
    value: "al",
  },
];

const InputStyleProps = {
  // margin: "10px 10px 10px 0",
  width: "300px",
};

const TVNews = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { onClose, data } = props;

  const dispatch = useDispatch();
  const sourceIsLoading = useSelector((state: RootState) => {
    return state.SourceSettings.isLoading;
  });
  const categoryIsLoading = useSelector((state: RootState) => {
    return state.Categories.isLoading;
  });
  const dataRecordIsLoading = useSelector((state: RootState) => {
    return state.DataRecord.isLoading;
  });
  const sources = useSelector((state: RootState) => {
    return state.SourceSettings.sourceSettings;
  });
  const categories = useSelector((state: RootState) => {
    return state.Categories.categories;
  });
  const submitStatus = useSelector((state: RootState) => {
    return state.DataRecord.submitSuccess;
  });

  const [recordDetails, setRecordDetails] = useState<any>({
    language: "al",
    author: "N/A",
  });
  const [sourceOptions, setSourceOptions] = useState<any[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);

  useEffect(() => {
    dispatch(fetchAllCategories());
    dispatch(fetchAllSource());

    return () => {
      dispatch(setDefaultSendPostStatus());
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const sourceId = _.get(data, "source.id");
      const categoryId = _.get(data, "category.id");
      const tags = _.split(_.get(data, "content"), ",");
      setRecordDetails({
        ...data,
        sourceId,
        categoryId,
        tags,
      });
    }
  }, [data]);

  useEffect(() => {
    if (submitStatus === 2) onClose(true);
  }, [submitStatus]);

  useEffect(() => {
    if (!_.isEmpty(sources) && _.isArray(sources)) {
      const resolveSources = _.filter(sources, (source) => {
        if (source?.mediaType === "tv")
          return {
            ...source,
            name: `${_.get(source, "name")} (${_.get(source, "code")})`,
          };
      });
      const getOptions = generateDropdown({
        data: resolveSources,
        key: "name",
        value: "id",
      });
      setSourceOptions(getOptions);
    }
  }, [sources]);

  useEffect(() => {
    if (!_.isEmpty(categories) && _.isArray(categories)) {
      const getOptions = generateDropdown({
        data: categories,
        key: "code",
        value: "id",
      });
      setCategoryOptions(getOptions);
    }
  }, [categories]);

  const onChangeSource = (sourceId: string) => {
    const findSource = _.find(sources, { id: sourceId });
    if (findSource) {
      const mediaType = _.get(findSource, "mediaType");
      if (mediaType !== "tv")
        return popupAlert({
          type: "warning",
          title: t("alert.warning"),
          text: t("alert.warningNotValidTVSource"),
        });
      else setRecordDetails({ ...recordDetails, sourceId });
    }
  };

  const validateData = () => {
    let flag = true;
    let message = `${t("alert.foundEmptyFields")}`;
    const data = [
      {
        label: t("label.title"),
        value: _.get(recordDetails, "title"),
      },
      {
        label: t("label.source"),
        value: _.get(recordDetails, "sourceId"),
      },
      {
        label: t("label.category"),
        value: _.get(recordDetails, "categoryId"),
      },
      {
        label: t("label.link"),
        value: _.get(recordDetails, "originalLink"),
      },
      {
        label: t("label.content"),
        value: _.get(recordDetails, "tags"),
      },
    ];
    _.map(data, (item) => {
      const value = _.get(item, "value");
      const label = _.get(item, "label");
      if ((_.isString(value) && _.isEmpty(_.trim(value))) || _.isEmpty(value)) {
        flag = false;
        message += `${label}\n`;
      }
    });
    return { flag, message };
  };

  const onSubmit = async () => {
    const { flag, message } = validateData();
    if (!flag) {
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });
    } else {
      const resolveData = {
        ...recordDetails,
      };
      const id = _.get(data, "id");
      if (id) {
        _.assign(resolveData, {
          content: _.join(_.get(recordDetails, "tags"), ","),
        });
        await dispatch(updateDataRecord(resolveData, id));
      } else {
        _.assign(resolveData, {
          content: _.join(_.get(recordDetails, "tags"), ","),
        });
        await dispatch(createDataRecord(resolveData));
      }
    }
  };

  const renderMain = () => {
    return (
      <TemplateDetailsContainer>
        <TemplateDetailsContent width="70%">
          {dataRecordIsLoading && (
            <LoadingLayer>
              <Spinner />
            </LoadingLayer>
          )}
          <TemplateDetailsHeader>
            {_.has(data, "id")
              ? t("headerPopup.editTVNews")
              : t("headerPopup.createTVNews")}
          </TemplateDetailsHeader>
          <TemplateDetailsForm height="440px">
            <FormInline>
              <FormGroup className="container-element">
                <FormLabel aria-label="require">{t("label.title")}</FormLabel>
                <Input
                  value={_.get(recordDetails, "title")}
                  onChangeHandler={(e: any) =>
                    setRecordDetails({
                      ...recordDetails,
                      title: e.target.value,
                    })
                  }
                  placeholder={t("placeholder.title")}
                  width="300px"
                  maxLength={128}
                />
              </FormGroup>
              {/* <FormGroup>
                <FormLabel aria-label="require">{t("label.author")}</FormLabel>
                <Input
                  value={_.get(recordDetails, "author")}
                  onChangeHandler={(e: any) =>
                    setRecordDetails({
                      ...recordDetails,
                      author: e.target.value,
                    })
                  }
                  placeholder={t("placeholder.author")}
                  width="300px"
                />
              </FormGroup> */}
            </FormInline>
            <FormInline>
              <FormGroup className="container-element">
                <FormLabel aria-label="require">{t("label.source")}</FormLabel>
                <Dropdown
                  value={_.get(recordDetails, "sourceId")}
                  callbackPayload={(value: string) => onChangeSource(value)}
                  placeholder={t("placeholder.source")}
                  width="300px"
                  options={sourceOptions}
                  search
                  loading={sourceIsLoading}
                  disabled={sourceIsLoading}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel aria-label="require">
                  {t("label.category")}
                </FormLabel>
                <Dropdown
                  value={_.get(recordDetails, "categoryId")}
                  callbackPayload={(value: string) =>
                    setRecordDetails({ ...recordDetails, categoryId: value })
                  }
                  placeholder={t("placeholder.category")}
                  width="300px"
                  options={categoryOptions}
                  search
                  loading={categoryIsLoading}
                  disabled={categoryIsLoading}
                />
              </FormGroup>
            </FormInline>
            <FormInline>
              <FormGroup>
                <FormLabel>
                  {t("label.language")}
                </FormLabel>
                <Dropdown
                  value={_.get(recordDetails, "language")}
                  callbackPayload={(value: string) =>
                    setRecordDetails({ ...recordDetails, language: value })
                  }
                  placeholder={t("placeholder.language")}
                  width="300px"
                  options={LANGUAGE_OPTIONS}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel aria-label="require">Date</FormLabel>
                <SelectDatetime
                  callbackPayload={(e) =>
                    setRecordDetails({ ...recordDetails, publishedAt: e })
                  }
                  defaultValue={recordDetails?.publishedAt}
                  placeholder="Date"
                  {...InputStyleProps}
                />
              </FormGroup>
            </FormInline>

            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.linkOfVideo")}
              </FormLabel>
              <Input
                value={_.get(recordDetails, "originalLink")}
                onChangeHandler={(e: any) =>
                  setRecordDetails({
                    ...recordDetails,
                    originalLink: e.target.value,
                  })
                }
                maxLength={255}
                placeholder={t("placeholder.linkOfVideo")}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.tagsOrKeyword")}
              </FormLabel>
              <TagArea
                tags={_.get(recordDetails, "tags")}
                onChange={(newTags: string[]) =>
                  setRecordDetails({ ...recordDetails, tags: newTags })
                }
                placeholder={t("placeholder.tagsOrKeyword")}
              />
            </FormGroup>
          </TemplateDetailsForm>
          <ButtonField>
            <Button onClick={() => onSubmit()} buttonMargin="10px 10px 10px 0">
              {_.has(data, "id") ? t("button.save") : t("button.create")}
            </Button>
            <Button
              onClick={() => onClose && onClose()}
              buttonMargin="10px 10px 10px 0"
            >
              {t("button.cancel")}
            </Button>
          </ButtonField>
        </TemplateDetailsContent>
      </TemplateDetailsContainer>
    );
  };
  return renderMain();
};

export default TVNews;
