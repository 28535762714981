import React, { useEffect } from 'react';
import { Wrapper, HeaderContainer, Content, Logo } from './Header.styles';
import { Images } from 'themes';

interface SectionProps {
  className?: string;
}

const Header: React.FC<SectionProps> = (props: SectionProps) => {
  const { className } = props;
  useEffect(() => { }, [props]);

  const renderHeader = () => {

    return (
      <HeaderContainer className={className}>
        <Content>
          <Logo><img src={Images.logo} alt='MMS Logo' /></Logo>
        </Content>
      </HeaderContainer>
    );
  };

  return (
    <Wrapper>
      {renderHeader()}
    </Wrapper>
  );
};

export default Header;
