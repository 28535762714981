import _ from "lodash";
import {
  Document,
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  WidthType,
  HeightRule,
  ExternalHyperlink,
  ImageRun,
  HeadingLevel,
  AlignmentType,
} from "docx";
interface SectionProps {
  data: any;
  total: any;
  logo: any;
  title: string;
}

const WordFile = (props: SectionProps) => {
  const { data, total, logo, title } = props;
  const getImage: any = async () => {
    return await fetch(
      // "https://raw.githubusercontent.com/dolanmiu/docx/master/demo/images/cat.jpg"
      _.get(logo, 'imagePath')
    ).then(r => r.blob());
  }

  const statisticalTable = new Table({
    columnWidths: [1500, 1500, 1500, 1500, 1500],
    rows: [
      new TableRow({
        tableHeader: true,
        height: { rule: HeightRule.EXACT, value: 300 },
        children: [
          new TableCell({
            width: { size: 100 / 5, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                children: [new TextRun({ text: "Print", bold: true })],
              }),
            ],
          }),
          new TableCell({
            width: { size: 100 / 5, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                children: [new TextRun({ text: "Web", bold: true })],
              }),
            ],
          }),
          new TableCell({
            width: { size: 100 / 5, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                children: [new TextRun({ text: "TV", bold: true })],
              }),
            ],
          }),
          new TableCell({
            width: { size: 100 / 5, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                children: [new TextRun({ text: "Social Media", bold: true })],
              }), 
            ],
          }),
          new TableCell({
            width: { size: 100 / 5, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                children: [new TextRun({ text: "All", bold: true })],
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        height: { rule: HeightRule.EXACT, value: 300 },
        children: [
          new TableCell({
            width: { size: 100 / 5, type: WidthType.PERCENTAGE },
            children: [new Paragraph({ text: `${_.get(total, "print")}` })],
          }),
          new TableCell({
            width: { size: 100 / 5, type: WidthType.PERCENTAGE },
            children: [new Paragraph({ text: `${_.get(total, "web")}` })],
          }),
          new TableCell({
            width: { size: 100 / 5, type: WidthType.PERCENTAGE },
            children: [new Paragraph({ text: `${_.get(total, "tv")}` })],
          }),
          new TableCell({
            width: { size: 100 / 5, type: WidthType.PERCENTAGE },
            children: [new Paragraph({ text: `${_.get(total, "social")}` })],
          }),
          new TableCell({
            width: { size: 100 / 5, type: WidthType.PERCENTAGE },
            children: [new Paragraph({ text: `${_.get(total, "all")}` })],
          }),
        ],
      }),
    ],
  });

  const recordTable = new Table({
    columnWidths: [700, 4500, 1700, 2100],
    rows: [
      new TableRow({
        tableHeader: true,
        height: { rule: HeightRule.EXACT, value: 300 },
        children: [
          new TableCell({
            width: { size: 100 / 5, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                children: [new TextRun({ text: "No", bold: true })],
              }),
            ],
          }),
          new TableCell({
            width: { size: 100 / 5, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                children: [new TextRun({ text: "Headline", bold: true })],
              }),
            ],
          }),
          new TableCell({
            width: { size: 100 / 5, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                children: [new TextRun({ text: "Published Date", bold: true })],
              }),
            ],
          }),
          new TableCell({
            width: { size: 100 / 5, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                children: [new TextRun({ text: "Source", bold: true })],
              }),
            ],
          }),
        ],
      }),
      ..._.map(data, (item, index) => {
        return new TableRow({
          height: { rule: HeightRule.AUTO, value: 300 },
          children: [
            new TableCell({
              width: { size: 100 / 5, type: WidthType.PERCENTAGE },
              children: [new Paragraph({ text: `${index}` })],
            }),
            new TableCell({
              width: { size: 100 / 5, type: WidthType.PERCENTAGE },
              children: [
                new Paragraph({
                  children: [
                    new ExternalHyperlink({
                      children: [
                        new TextRun({
                          text: `${_.get(item, "title")}`,
                          style: "Hyperlink",
                        }),
                      ],
                      link: `${process.env.REACT_APP_FE_URL}/articles/${_.get(
                        item,
                        "id"
                      )}`,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              width: { size: 100 / 5, type: WidthType.PERCENTAGE },
              children: [
                new Paragraph({
                  text: `${new Date(
                    _.get(item, "publishedAt")
                  ).toDateString()}`,
                }),
              ],
            }),
            new TableCell({
              width: { size: 100 / 5, type: WidthType.PERCENTAGE },
              children: [
                new Paragraph({ text: `${_.get(item, "source.name")}` }),
              ],
            }),
          ],
        });
      }),
    ],
  });

  const renderLogo = () => {
    if (_.get(logo, 'imagePath'))
      return new Paragraph({
        alignment: AlignmentType.CENTER,
        children: [
          new ImageRun({
            data: getImage(),
            transformation: {
              width: 300,
              height: 300
            }
          })
        ]
      })
    return new Paragraph({
      children: [], // Just newline without text
    })
  }
  const renderTitle = () => {
    if (title)
      return new Paragraph({
          text: title,
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.CENTER,
        })
    return new Paragraph({
      children: [], // Just newline without text
    })
  }

  const document = new Document({
    sections: [
      {
        children: [
          renderLogo(),

          renderTitle(),

          new Paragraph({
            children: [], // Just newline without text
            text: ``,
          }),
          new Paragraph({
            children: [], // Just newline without text
            text: ``,
          }),
         
          new Paragraph({
            text: `Date: ${new Date().toLocaleDateString()}   Time: ${new Date().toLocaleTimeString()}`,
          }),
          new Paragraph({
            children: [], // Just newline without text
          }),
          statisticalTable,
          new Paragraph({
            children: [], // Just newline without text
          }),
          new Paragraph({
            children: [], // Just newline without text
          }),
          recordTable,
        ],
      },
    ],
  });

  return document;
};

export default WordFile;
