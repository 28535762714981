import styled from "styled-components";
import ColorName from "constants/Variables";

interface SectionProps {
  width?: string;
}

const Spinner = styled.div`
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  width: 30px;
  height: 30px;
  border-top: 3px solid ${ColorName.burntSienna};
  border-right: 3px solid transparent;
  border-radius: 50%;
  animation: rotation 0.8s linear infinite;
`;

const LoadingAuthenForm = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AuthenticationContent = styled.div`
  width: ${(props: SectionProps) => props.width || "350px"};
  height: 550px;
  overflow: hidden;
  transition: all ease 0.25s;
  position: relative;
`;
const AuthenticationForm = styled.div`
  width: ${(props: SectionProps) => props.width || "350px"};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  background: white;
  border-radius: 5px;
  padding: 35px 50px;

  &.change-border-radius {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 1px solid ${ColorName.william};
  }

  & .header-login {
    text-align: center;
  }
`;

const AuthenticationBox = styled.div`
  display: flex;
  transform: translateX(0px);
  transition: all ease-in-out 0.3s;

  &.sign-up {
    transform: translateX(-450px);
  }
`;

const AuthenticationSwitch = styled.div`
  text-align: center;
  span {
    font-weight: bold;
    color: ${ColorName.william};
    margin-left: 5px;
    &:hover {
      cursor: pointer;
    }
  }
`;

const PopupWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
`;

const DescriptionWrapper = styled.div`
  transition: all ease 0.25s;
  background: ${ColorName.white};
  overflow: hidden;
  box-sizing: border-box;
  overflow-y: scroll;
  &.change-border-radius {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 20px 20px 20px 0;
  }
`;

const DescriptionContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 15px;
  box-sizing: border-box;

  & .label {
    width: 80px;
  }
`;

const DataRecordItem = styled.div`
  padding: 15px 0;
  border-top: 1px solid black;

  &:last-child {
    border-bottom: 1px solid black;
  }

  & .record-header {
    display: flex;
    justify-content: space-between;
    p {
      margin: 0;
      margin-bottom: 5px;
    }
  }
  & .record-content {
    h2 {
      line-height: 25px;
      margin-top: 10px;
    }
    p {
      line-height: 23px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }
`;

export {
  AuthenticationForm,
  AuthenticationSwitch,
  AuthenticationContent,
  PopupWrapper,
  AuthenticationBox,
  DescriptionWrapper,
  DescriptionContent,
  DataRecordItem,
  LoadingAuthenForm,
  Spinner,
};
