import React, { useEffect, useState } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import {
  Cell,
  DataTable,
  OptionItem,
  ListOption,
  ContainerPagination,
  WrapperTable,
  TitleTable,
  ViewMore,
  Title,
  TableScrollField,
  LoadingLayer,
  Spinner,
} from "./Table.styles";
import Pagination from "../Pagination";
import { Icons } from "themes";
import history from "../../../history";
import { CONSTANTS, LOG_LEVELS } from "constants/Constants";
import { useTranslation } from "react-i18next";
import { getSavedUserData } from "libs/Utils";
import { Box, Button, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface SectionProps {
  dataTable: Array<any>;
  dataHeader: Array<string>;
  listActionItem?: Array<string>;
  callBackAction?: (action: string, id: any, item: any) => void;
  callBackSort?: (sort: string) => void;
  callBackPreview?: (item: any) => void;
  metaPagination?: any;
  hasPagination?: boolean;
  hasTitle?: boolean;
  title?: string;
  callbackCurrentPageLink?: (currentPage: number) => void;
  listHeaderWithSort?: Array<string>;
  columnNotPrint?: Array<string>;
  fitColumns?: Array<number>;
  listColumnCenter?: Array<number>;
  urlViewmore?: string;
  isScroll?: boolean;
  loading?: boolean;
  type?: string;
  showMore?: () => void;
  isShowMore?: boolean;
}

const Table: React.FC<SectionProps> = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const {
    dataHeader,
    dataTable,
    listActionItem,
    callBackAction,
    callBackSort,
    metaPagination,
    callbackCurrentPageLink,
    hasPagination,
    listHeaderWithSort,
    fitColumns,
    listColumnCenter,
    hasTitle,
    title,
    callBackPreview,
    columnNotPrint,
    urlViewmore,
    isScroll,
    loading,
    type,
    showMore,
    isShowMore = true,
  } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [sort, setSort] = useState("");

  const userData = getSavedUserData();
  const currentUserId = _.get(userData, "id");

  useEffect(() => {
    if (metaPagination) {
      setCurrentPage(metaPagination.currentPage);
      setMaxPage(metaPagination.totalPages);
      setTotalCount(metaPagination.totalItems);
    }
  }, [metaPagination]);

  const setSortBy = (item: string) => {
    if (callBackSort) callBackSort(item);
    if (item === sort) return setSort("");
    setSort(item);
  };

  const callBackPreviewItem = (item: any) => {
    if (callBackPreview) callBackPreview(item);
  };

  const fetchShowMore = () => {
    if (showMore) showMore();
  };

  const _renderListAction = (id: any, item: any) => {
    const render = _.map(listActionItem, (option, index) => {
      let labelOption = option;
      let isDisabled = false;
      if (option === CONSTANTS.ACTION.DEACTIVATE) {
        const currentStatus = _.get(item, "status");
        labelOption =
          currentStatus === CONSTANTS.STATUS.ACTIVE ? "deactive" : "active";
        if (id === currentUserId) return;
      }
      if (option === CONSTANTS.ACTION.LIST_PACKAGES && id === currentUserId)
        return;
      return (
        <OptionItem
          className={`${isDisabled ? "disabled" : ""}`}
          onClick={() =>
            !isDisabled && callBackAction
              ? callBackAction(option, id, item)
              : {}
          }
          key={index}
        >
          {t(`table.${labelOption}`)}
        </OptionItem>
      );
    });
    return render;
  };

  const _renderRow = (item: any, index: number) => {
    let cellNumber = 1;
    const render = _.map(item, (value, key) => {
      if (key === "id" || _.includes(columnNotPrint, key)) return;
      if (key === "no") {
        return (
          <Cell
            className={`cell-no ${
              _.includes(listColumnCenter, cellNumber) && "justify-center"
            }`}
            key={key}
          >
            {/* {type === "source-settings" ? (
              <div>{index + 1}</div>
            ) : (
              <div>
                {currentPage === 1
                  ? index + 1
                  : (currentPage - 1) * 10 + index + 1}
              </div>
            )} */}
            {<div>{index + 1}</div>}
          </Cell>
        );
      }
      cellNumber++;
      if (key === "action")
        return (
          <Cell
            className={`action ${
              _.includes(listColumnCenter, cellNumber) && "justify-center"
            } `}
            key={key}
          >
            <span>
              <i className="fas fa-ellipsis-h"></i>
              <ListOption className={`${index >= 5 ? "top" : "bottom"}`}>
                {_renderListAction(item.id, item)}
              </ListOption>
            </span>
          </Cell>
        );
      if (key === "preview")
        return (
          <Cell
            key={key}
            className={`${
              _.includes(listColumnCenter, cellNumber) && "justify-center"
            }`}
          >
            <img
              src={Icons.preview}
              alt="Preview"
              onClick={() => callBackPreviewItem(item)}
            />
          </Cell>
        );

      if (key === "createdAt" || key === "updatedAt" || key === "publishedAt") {
        const timeValue = dayjs(new Date(value));
        let result;
        if (timeValue.isValid()) result = value;
        else {
          if (_.includes(value, "a.m."))
            result = _.replace(value, /a.m./g, "am");
          if (_.includes(value, "p.m."))
            result = _.replace(value, /p.m./g, "pm");
        }
        let resolveTime;
        if (key === "publishedAt")
          resolveTime = dayjs(result).format("DD-MM-YYYY, HH:mm:ss");
        else resolveTime = dayjs(_.split(result, ",")[0]).format("DD-MM-YYYY");

        return (
          <Cell
            key={key}
            className={`${
              _.includes(listColumnCenter, cellNumber) && "justify-center"
            } datetime`}
          >
            {resolveTime}
          </Cell>
        );
      }

      if (key === "status") {
        return (
          <Cell
            key={key}
            className={`value ${
              _.includes(listColumnCenter, cellNumber) && "justify-center"
            } datetime`}
          >
            <span className={value}>{_.capitalize(t(`table.${value}`))}</span>
          </Cell>
        );
      }

      if (key === "level") {
        let logLevel = LOG_LEVELS[1];
        if (typeof value === "number") {
          const isIncludes = _.includes([1, 2, 3], _.toNumber(value));
          if (isIncludes) logLevel = _.get(LOG_LEVELS, [_.toNumber(value)]);
        }
        return (
          <Cell
            key={key}
            className={`value ${
              _.includes(listColumnCenter, cellNumber) && "justify-center"
            } log-level`}
          >
            <span className={logLevel}>{t(`table.${logLevel}`)}</span>
          </Cell>
        );
      }

      // if (key === "link") {
      //   return (
      //     <Cell
      //       key={key}
      //       className={`value ${
      //         _.includes(listColumnCenter, cellNumber) && "justify-center"
      //       } log-level`}
      //     >
      //       <a
      //         href={value}
      //         target="_blank"
      //         style={{ color: "#0000EE" }}
      //         rel="noopener noreferrer"
      //       >
      //         {value}
      //       </a>
      //     </Cell>
      //   );
      // }

      if (key === "startDate" || key === "endDate") {
        return (
          <Cell
            key={key}
            className={`value ${
              _.includes(listColumnCenter, cellNumber) && "justify-center"
            } datetime`}
          >
            <span className={value}>
              {dayjs(`${value} 00:00:00`).format("DD-MM-YYYY")}
            </span>
          </Cell>
        );
      }

      if (key === "required")
        return (
          <Cell
            key={key}
            className={`${
              _.includes(listColumnCenter, cellNumber) && "justify-center"
            }`}
          >
            {value ? (
              <i className="fas fa-check icon-tick" />
            ) : (
              <i className="fas fa-times icon-times" />
            )}
          </Cell>
        );
      return (
        <Cell
          key={key}
          className={`${
            _.includes(listColumnCenter, cellNumber) && "justify-center"
          }`}
        >
          {value}
        </Cell>
      );
    });
    return render;
  };

  const _renderHeader = () => {
    const render = _.map(dataHeader, (item, index) => {
      const headerKey = _.replace(_.toLower(item), /\s/g, "-");
      if (
        _.isEmpty(listHeaderWithSort) ||
        !_.includes(listHeaderWithSort, item)
      ) {
        return (
          <Cell
            className={`header not-clickable ${headerKey} ${
              !_.isEmpty(listColumnCenter) &&
              _.includes(listColumnCenter, index + 1) &&
              "justify-center"
            }`}
            key={index}
          >
            {t(`table.${item}`)}
          </Cell>
        );
      }
      return (
        <Cell
          onClick={() => setSortBy(item)}
          className={`header ${headerKey} ${
            !_.isEmpty(listColumnCenter) &&
            _.includes(listColumnCenter, index + 1) &&
            "justify-center"
          }`}
          key={index}
        >
          {t(`table.${item}`)}
          <i
            className={
              sort === item
                ? "fas fa-caret-up icon-sort"
                : "fas fa-caret-down icon-sort"
            }
          />
        </Cell>
      );
    });
    return render;
  };

  const _renderDataTable = () => {
    const render = _.map(dataTable, (item, index) => {
      return _renderRow(item, index);
    });
    return render;
  };

  const _renderLoading = () => {
    return (
      <LoadingLayer>
        <Spinner />
      </LoadingLayer>
    );
  };

  const callbackCurrentPage = (e: number) => {
    if (callbackCurrentPageLink) return callbackCurrentPageLink(e);
  };

  const renderMain = () => {
    return (
      <WrapperTable>
        {loading && _renderLoading()}
        {hasTitle && (
          <TitleTable>
            <Title>{title}</Title>
            {urlViewmore && (
              <ViewMore onClick={() => history.push(urlViewmore)}>
                {t("table.viewMore")}
                <img src={Icons.rightArrow} alt="View More" />
              </ViewMore>
            )}
          </TitleTable>
        )}
        <DataTable colHead={dataHeader.length} fitColumns={fitColumns}>
          {_renderHeader()}
          {_renderDataTable()}
        </DataTable>
        {_.isEmpty(dataTable) && (
          <div className="data-not-found">{t("table.noDataFound")}</div>
        )}
        {/* {hasPagination &&
          _.get(metaPagination, "totalPages") > 1 &&
          type !== "source-settings" && (
            <ContainerPagination className="pagination">
              <Pagination
                maxPage={maxPage}
                currentPage={currentPage}
                totalCount={totalCount}
                numberPageDisplay={2}
                clickLinkPagination={(e) => callbackCurrentPage(e)}
                clickLinkNext={(e) => callbackCurrentPage(e)}
                clickLinkPrevious={(e) => callbackCurrentPage(e)}
                clickLinkFirst={() => callbackCurrentPage(1)}
                clickLinkLast={() => callbackCurrentPage(maxPage)}
              />
            </ContainerPagination>
          )} */}
        {!_.isEmpty(dataTable) && isShowMore && (
          <Box
            sx={{
              width: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
            }}
          >
            <LoadingButton
              loading={loading}
              // sx={{ border: "1px solid red", borderRadius: "20px", p: 1 }}
              onClick={() => fetchShowMore()}
              disabled={currentPage === maxPage}
            >
              {t("button.showMore")}{" "}
            </LoadingButton>
          </Box>
        )}
      </WrapperTable>
    );
  };
  if (isScroll)
    return (
      <>
        <TableScrollField>
          <div>
            <WrapperTable>
              {hasTitle && (
                <TitleTable>
                  <Title>{title}</Title>
                  {urlViewmore && (
                    <ViewMore onClick={() => history.push(urlViewmore)}>
                      {t("table.viewMore")}{" "}
                      <img src={Icons.rightArrow} alt="View More" />
                    </ViewMore>
                  )}
                </TitleTable>
              )}
              <DataTable colHead={dataHeader.length} fitColumns={fitColumns}>
                {_renderHeader()}
                {_renderDataTable()}
              </DataTable>
              {_.isEmpty(dataTable) && (
                <div className="data-not-found">{t("table.noDataFound")}</div>
              )}
            </WrapperTable>
          </div>
        </TableScrollField>
        {/* {hasPagination &&
          _.get(metaPagination, "totalPages") > 1 &&
          type !== "source-settings" && (
            <ContainerPagination className="pagination">
              <Pagination
                maxPage={maxPage}
                currentPage={currentPage}
                numberPageDisplay={2}
                totalCount={totalCount}
                clickLinkPagination={(e) => callbackCurrentPage(e)}
                clickLinkNext={(e) => callbackCurrentPage(e)}
                clickLinkPrevious={(e) => callbackCurrentPage(e)}
                clickLinkFirst={() => callbackCurrentPage(1)}
                clickLinkLast={() => callbackCurrentPage(maxPage)}
              />
            </ContainerPagination>
          )} */}
      </>
    );
  return renderMain();
};

export default Table;
