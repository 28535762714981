import styled from "styled-components";
import ColorName from "../../../constants/Variables";

const TagAreaContainer = styled.div`
  width: 100%;
  & .tag-area-container {
    width: 100%;
    & .tag-area__control {
      border: 1px solid ${ColorName.linkWater};
      box-shadow: 0px 0px 2px ${"rgb(62 138 182 / 25%)"};
      color: ${ColorName.william};
      transition: all ease 0.25s;

      &--is-focused {
        border: 1px solid ${ColorName.william};
      }
    }
  }
`;

export { TagAreaContainer };
