/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "reducers";

import countries from "libs/countries";

import RouteTypes from "constants/RouteTypes";
import { Checkbox, LoadingLayer, Spinner, Avatar } from "components/Common";

import UserDefaultLayout from "components/AdminLayout/DefaultLayout/UserDefaultLayout";
import {
  ProfileContainer,
  FormRow,
  InputItem,
  LabelInput,
  Button,
  TitlePage,
  Input,
  DropdownSelect,
  DropdownOption,
  ProfileMenu,
  ProfileMenuItem,
  ProfileColumn,
  DropdownLabel,
  DropdownMenu,
} from "./Profile.styles";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import history from "../../../history";
import {
  clearAllSavedData,
  getSavedToken,
  getSavedUserData,
  popupAlert,
  useOutsideClick,
  validatePassword,
} from "libs/Utils";
import { AuthenticationActions, UsersActions } from "actions";
import { Images } from "../../../themes";

const { logoutWithOutRedirect } = AuthenticationActions;
const { updateUserForSelf } = UsersActions;

const Articles = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const countryRef = useRef<HTMLInputElement>(null);
  const isUpdateSuccess = useSelector((state) =>
    _.get(state, "Users.isUpdateSuccess")
  );
  const userIsLoading = useSelector((state: RootState) => {
    return state.Users.isLoading;
  });
  const [isUpdatePassword, setIsUpdatePassword] = useState<boolean>(false);
  const token = getSavedToken();
  const getSavedData = getSavedUserData();

  const [urlAvatar, setUrlAvatar] = useState<string>("");
  const [avatar, setAvatar] = useState<any>();
  const [currentMenu, setCurrentMenu] = useState<string>("profile");
  const [isShowMenuCountry, setIsShowMenuCountry] = useState<boolean>(false);
  const [data, setData] = useState<any>({
    email: "",
    firstname: "",
    lastname: "",
    city: "",
    country: "",
    zipCode: "",
    phoneNumber: "",
  });
  const [password, setPassword] = useState<any>({
    password: "",
    confirmationPassword: "",
  });

  const onSetUserData = () => {
    const username = _.get(getSavedData, "username");
    const email = _.get(getSavedData, "email");
    const id = _.get(getSavedData, "id");
    const userData = _.get(getSavedData, "userData");
    const avatar = _.get(userData, "avatar");
    if (avatar) {
      const getImagePath = _.get(avatar, "imagePath");
      setUrlAvatar(getImagePath);
    }
    setData({ username, email, id, ...userData });
    setIsUpdatePassword(false);
  };

  useOutsideClick(countryRef, () => setIsShowMenuCountry(false));

  useEffect(() => {
    if (!token || _.isEmpty(getSavedData)) {
      clearAllSavedData();
      dispatch(logoutWithOutRedirect());
      history.push(RouteTypes.USER_ROUTES.USER_ARTICLES);
    } else onSetUserData();
  }, []);

  useEffect(() => {
    if (isUpdateSuccess === 2) onSetUserData();
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isShowMenuCountry && countryRef && countryRef.current)
      countryRef.current.focus();
  }, [isShowMenuCountry]);

  const processData = () => {
    const formData = new FormData();
    const userType = _.get(getSavedData, "userType");
    const clientId = _.get(getSavedData, "clientId.id");
    const accountId = _.get(getSavedData, "clientId.id");
    formData.append("clientId", clientId);
    formData.append("accountId", accountId);
    formData.append("email", data.email);
    // formData.append("username", data.username);
    formData.append("userType", userType);
    formData.append("userData[firstName]", data.firstName);
    formData.append("userData[lastName]", data.lastName);
    if (data.zipCode) formData.append("userData[address]", data.address);
    if (data.zipCode)
      formData.append("userData[phoneNumber]", data.phoneNumber);
    if (data.zipCode) formData.append("userData[country]", data.country);
    if (data.zipCode) formData.append("userData[city]", data.city);
    if (data.zipCode) formData.append("userData[zipCode]", data.zipCode);
    if (avatar) formData.append("userData[avatar]", avatar || "");
    if (isUpdatePassword) {
      formData.append("password", password?.password);
      formData.append("confirmationPassword", password?.confirmationPassword);
    }

    return formData;
  };

  const validateData = () => {
    let flag = true;
    let message = `${t("alert.foundEmptyFields")}`;
    const requireData: any[] = [
      {
        label: t("label.firstName"),
        value: _.trim(data.lastName),
      },
      {
        label: t("label.lastName"),
        value: _.trim(data.lastName),
      },
      {
        label: t("label.email"),
        value: _.trim(data.email),
      },
    ];
    if (isUpdatePassword) {
      requireData.push(
        ...[
          {
            label: t("label.newPassword"),
            value: _.trim(password.password),
          },
          {
            label: t("label.confirmPassword"),
            value: _.trim(password.confirmationPassword),
          },
        ]
      );
    }
    _.map(requireData, (item) => {
      const label = _.get(item, "label");
      const value = _.get(item, "value");
      if (_.isEmpty(value)) {
        flag = false;
        message += `${label}\n`;
      }
    });
    return { flag, message };
  };

  const onSubmit = async () => {
    const { flag, message } = validateData();
    if (!flag)
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });

    if (isUpdatePassword) {
      const isSamePassword =
        _.get(password, "password") === _.get(password, "confirmationPassword");
      if (!isSamePassword)
        return popupAlert({
          title: t("alert.warning"),
          type: "warning",
          text: t("alert.warningPasswordAndConfirmPasswordNotMatch"),
        });

      const valid = validatePassword({
        password: {
          label: t("label.password"),
          value: _.get(password, "password"),
        },
        confirmPassword: {
          label: t("label.confirmPassword"),
          value: _.get(password, "confirmationPassword"),
        },
        rules: {
          atLeastChars: 6,
          atLeastOneDigit: true,
          atLeastOneLowerCase: true,
        },
      });

      if (!valid) return;
    }

    const userData = processData();
    await dispatch(updateUserForSelf(userData));
    setPassword({ password: "", confirmationPassword: "" });
  };

  const onReset = async () => {
    const isAgree = await popupAlert({
      title: t("alert.warning"),
      type: "warning",
      text: t("alert.warningBeforeResetProfile"),
      buttons: true,
    });
    if (isAgree) onSetUserData();
  };

  const onSelectImage = (e: any) => {
    if (e.target.files[0]) {
      const url = URL.createObjectURL(e.target.files[0]);
      const file = e.target.files[0];
      setAvatar(file);
      setUrlAvatar(url);
    } else return;
  };

  const renderCountryLabel = (country: string) => {
    const findCountry = _.find(countries, { value: country });
    if (findCountry) return _.get(findCountry, "label");
    return "Country";
  };

  const renderProfile = () => {
    return (
      <>
        <FormRow>
          <InputItem>
            <LabelInput>{t("label.avatar")}</LabelInput>
            <Avatar
              src={urlAvatar || Images.defaultPhoto}
              onChange={(e: any) => onSelectImage(e)}
            />
          </InputItem>
          <InputItem />
        </FormRow>
        <FormRow>
          <InputItem>
            <LabelInput>{t("label.username")}</LabelInput>
            <Input value={_.get(data, "username") || ""} disabled />
          </InputItem>
          <InputItem>
            <LabelInput>{t("label.email")}</LabelInput>
            <Input value={_.get(data, "email") || ""} disabled />
          </InputItem>
        </FormRow>
        <FormRow>
          <InputItem>
            <LabelInput>
              {t("label.firstName")} <span>(*)</span>
            </LabelInput>
            <Input
              value={_.get(data, "firstName") || ""}
              onChange={(e: any) =>
                setData({ ...data, firstName: e.target.value })
              }
            />
          </InputItem>
          <InputItem>
            <LabelInput>
              {t("label.lastName")} <span>(*)</span>
            </LabelInput>
            <Input
              value={_.get(data, "lastName") || ""}
              onChange={(e: any) =>
                setData({ ...data, lastName: e.target.value })
              }
            />
          </InputItem>
        </FormRow>
        <FormRow>
          <InputItem>
            <LabelInput>{t("label.postalOrZipCode")}</LabelInput>
            <Input
              value={_.get(data, "zipCode") || ""}
              // onInput={(event: any) => {
              //   const isValid = event.target.validity.valid;
              //   if (isValid) setData({ ...data, zipCode: event.target.value });
              //   else event.preventDefault();
              // }}
              pattern="[0-9]*"
              onChange={(event: any) => {
                const isValid = event.target.validity.valid;
                if (isValid) setData({ ...data, zipCode: event.target.value });
                else event.preventDefault();
              }}
              // onKeyPress={(event: any) => {
              //   if (!/[0-9]/.test(event.key)) {
              //     event.preventDefault();
              //   }
              // }}
            />
          </InputItem>
          <InputItem>
            <LabelInput>{t("label.address")}</LabelInput>
            <Input
              value={_.get(data, "address") || ""}
              onChange={(e: any) =>
                setData({ ...data, address: e.target.value })
              }
            />
          </InputItem>
        </FormRow>
        <FormRow>
          <InputItem>
            <LabelInput>{t("label.city")}</LabelInput>
            <Input
              value={_.get(data, "city") || ""}
              onChange={(e: any) => setData({ ...data, city: e.target.value })}
            />
          </InputItem>
          <InputItem>
            <LabelInput>{t("label.country")}</LabelInput>
            <DropdownSelect
              ref={countryRef}
              tabIndex={1}
              onClick={() => setIsShowMenuCountry(!isShowMenuCountry)}
            >
              <DropdownLabel>
                {renderCountryLabel(_.get(data, "country"))}
              </DropdownLabel>
              {isShowMenuCountry && (
                <DropdownMenu>
                  <div>
                    {_.map(countries, (country) => (
                      <DropdownOption
                        key={`country-${_.get(country, "value")}`}
                        // value={_.get(country, "value")}
                        className={`${
                          _.get(country, "value") === _.get(data, "country")
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          setData({
                            ...data,
                            country: _.get(country, "value"),
                          });
                          setIsShowMenuCountry(false);
                        }}
                      >
                        {_.get(country, "label")}
                      </DropdownOption>
                    ))}
                  </div>
                </DropdownMenu>
              )}
            </DropdownSelect>
          </InputItem>
        </FormRow>
        <FormRow>
          <InputItem>
            <LabelInput>{t("label.phoneNumber")}</LabelInput>
            <Input
              value={_.get(data, "phoneNumber") || ""}
              // onInput={(event: any) => {
              //   const isValid = event.target.validity.valid;
              //   if (isValid)
              //     setData({ ...data, phoneNumber: event.target.value });
              //   else event.preventDefault();
              // }}
              onChange={(event: any) => {
                const isValid = event.target.validity.valid;
                if (isValid)
                  setData({ ...data, phoneNumber: event.target.value });
                else event.preventDefault();
              }}
              pattern="[0-9]*"
            />
          </InputItem>
          <InputItem />
        </FormRow>
        <Checkbox
          checked={isUpdatePassword}
          context="Also update password"
          onChangeHandler={(status) => setIsUpdatePassword(status)}
          color="#000"
        />
        <FormRow>
          <InputItem>
            <LabelInput>{t("label.newPassword")}</LabelInput>
            <Input
              value={_.get(password, "password") || ""}
              onChange={(e: any) =>
                setPassword({ ...password, password: e.target.value })
              }
              disabled={!isUpdatePassword}
              type="password"
            />
          </InputItem>
          <InputItem>
            <LabelInput>{t("label.confirmPassword")}</LabelInput>
            <Input
              value={_.get(password, "confirmationPassword") || ""}
              onChange={(e: any) =>
                setPassword({
                  ...password,
                  confirmationPassword: e.target.value,
                })
              }
              disabled={!isUpdatePassword}
              type="password"
            />
          </InputItem>
        </FormRow>
        <FormRow className="p-1">
          <Button onClick={() => onReset()}> {t("button.reset")}</Button>
          <Button className="primary-btn" onClick={() => onSubmit()}>
            {t("button.save")}
          </Button>
        </FormRow>
      </>
    );
  };

  const renderBelongTo = () => {
    return <div>Belong To</div>;
  };

  const renderMain = () => {
    return (
      <ProfileContainer>
        {userIsLoading && (
          <LoadingLayer>
            <Spinner />
          </LoadingLayer>
        )}
        {/* <TitlePage>{t("titlePage.profile")}</TitlePage> */}

        <ProfileMenu>
          <ProfileMenuItem
            className={`${currentMenu === "profile" ? "active" : ""}`}
            onClick={() => setCurrentMenu("profile")}
          >
            Profile
          </ProfileMenuItem>
          {/* <ProfileMenuItem
            className={`${currentMenu === "belongTo" ? "active" : ""}`}
            onClick={() => setCurrentMenu("belongTo")}
          >
            Belong to
          </ProfileMenuItem> */}
        </ProfileMenu>
        <ProfileColumn>
          {currentMenu === "profile" ? renderProfile() : renderBelongTo()}
        </ProfileColumn>
      </ProfileContainer>
    );
  };
  return (
    <UserDefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.USER_ROUTES.PROFILE}
    />
  );
};

export default Articles;
