import Api from "./Base";
import { API } from "constants/Constants";

const { ACCOUNT } = API;

export function addAccount(data: any) {
  return Api.postFormData(ACCOUNT.REGISTER, data);
}

export function fetchAccounts() {
  return Api.get(ACCOUNT.BASIC);
}

export function fetchUserForAccount(data: any) {
  return Api.get(ACCOUNT.USER_FOR_ACCOUNT, data);
}

export function getAccountByID(id: any) {
  return Api.get(`${ACCOUNT.BASIC}/${id}`);
}

export function searchAccounts(data: any) {
  return Api.get(ACCOUNT.BASIC, data);
}

export function updateAccount(data: any, id: any) {
  return Api.putFormData(`${ACCOUNT.UPDATE}/${id}`, data);
}

export function deactivateAccount(id: string) {
  return Api.put(`${ACCOUNT.DEACTIVATE}/${id}`);
}

export function activateAccount(id: string) {
  return Api.put(`${ACCOUNT.ACTIVATE}/${id}`);
}

export function fetchPackageForUserPortal(clientId: string) {
  return Api.get(ACCOUNT.FOR_USER_PORTAL, { clientId });
}
