import styled from "styled-components";
import ColorName from "constants/Variables";
import { device } from "constants/Devices";
import { HorizontalContainer } from "../CommonLayout";


const ContainerPopup = styled(HorizontalContainer)`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60%;
  min-width: 375px;
  border-radius: 20px;
  overflow: hidden;
  background-color: ${ColorName.white};
  position: relative;
  & .title {
    display: flex;
    padding: 20px;
    font-size: 22px;
    font-weight: bold;
    color: ${ColorName.william};
    border-bottom: 1px solid ${ColorName.linkWater};
    background: ${ColorName.spindle};
    place-content: center;
  }
  & .content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-height: 50vh;
    overflow: auto;
    white-space: pre-line;
    &.flex-center {
      place-items: center;
      min-height: 200px;
      & .form-center {
        min-width: 200px;
      }
    }
  }
  & .footer {
    display: flex;
    padding: 0 20px;
    justify-content: center;
    border-top: 1px solid ${ColorName.linkWater};
  }
  @media ${device.tablet} {
    max-width: 80%;
    width: 680px;
  }
`;

export {
  ContainerPopup,
  Content,
};
