import ActionTypes from "constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  isGetEmailLoading: false,
  reports: [],
  report: {},
  emailDistribution: [],
  emails: [],
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.CLEAR_REPORT_DETAILS:
      return {
        ...state,
        hasError: false,
        isSuccess: false,
        isLoading: false,
        isGetEmailLoading: false,
        // reports: [],
        report: {},
        emailDistribution: [],
        emails: [],
      };
    case ActionTypes.REPORT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CREATE_REPORT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.UPDATE_REPORT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        report: payload,
      };
    case ActionTypes.FETCH_REPORT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        reports: payload,
      };
    case ActionTypes.SEARCH_REPORT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        reports: payload,
      };
    case ActionTypes.GET_REPORT_BY_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        report: payload,
      };
    case ActionTypes.FETCH_REPORT_EMAIL_DISTIBUTION_LOADING:
      return {
        ...state,
        isSuccess: true,
        isGetEmailLoading: true,
      };
    case ActionTypes.FETCH_REPORT_EMAIL_DISTIBUTION_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isGetEmailLoading: false,
        emailDistribution: payload,
      };
    case ActionTypes.FETCH_REPORT_EMAIL_DISTIBUTION_FAILURE:
      return {
        ...state,
        isSuccess: false,
        hasError: true,
        isGetEmailLoading: false,
        emailDistribution: [],
      };
    case ActionTypes.GET_EMAIL_DISTIBUTION_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isGetEmailLoading: false,
        emails: payload,
      };
    case ActionTypes.GET_EMAIL_DISTIBUTION_FAILURE:
      return {
        ...state,
        isSuccess: false,
        hasError: true,
        isGetEmailLoading: false,
        emails: [],
      };
    default:
      return state;
  }
};
