import Api from "./Base";
import { API } from "constants/Constants";

const { ROLE } = API;

export function addRole(data: any) {
  return Api.post(ROLE.BASIC, data);
}

export function fetchRoles(data?: any) {
  return Api.get(ROLE.BASIC, data);
}

export function fetchPrivileges() {
  return Api.get(ROLE.FETCH_MODULE);
}

export function getRoleByID(id: any) {
  return Api.get(`${ROLE.GET_ROLE_BY_ID}/${id}`);
}

export function searchRoles(data: any) {
  return Api.get(ROLE.BASIC, data);
}

export function updateRole(data: any, id: any) {
  return Api.put(`${ROLE.BASIC}/${id}`, data);
}

export function deactivateRole(id: string) {
  return Api.put(`${ROLE.DEACTIVATE}/${id}`);
}

export function activateRole(id: string) {
  return Api.put(`${ROLE.ACTIVATE}/${id}`);
}
