import _ from "lodash";
import swal from "sweetalert";

export const responseAlert = (
  data: { label?: string; res?: any; message?: string; buttons?: any },
  isShowPayload?: boolean
) => {
  const { label, res, message, buttons } = data;
  let title = "";
  let type = "";
  let text = "";

  if (label) {
    title = `${label} successfully`;
    type = "success";
    const { payload } = res;
    if (payload && isShowPayload) {
      _.map(payload, (item) => (text += `${item}\n`));
    }
  } else {
    title = `Oops...`;
    type = "error";
    const message = _.get(res, "message");
    if (_.isArray(message)) text = _.join(message, "\n");
    if (_.isString(message)) text = message || 'The server has a problem. Please try again!';
  }
  if (message) text = message;

  const resolveButtons = {};
  if (buttons) _.assign(resolveButtons, buttons);
  else _.assign(resolveButtons, ["OK"]);

  return new Promise((resolve) => {
    swal({
      title,
      text,
      icon: type,
      buttons: resolveButtons,
    }).then((response: any) => resolve(response));
  });
};
