import ActionTypes from "constants/ActionTypes";
import API from "api";
import _ from "lodash";
import history from "../history";
import { ROUTES } from "constants/Constants";
import { responseAlert } from "libs/alert";
import { popupAlert } from "../libs/Utils";

const clearFileRecords = () => {
  return {
    type: ActionTypes.CLEAR_FILE_RECORDS,
  };
};

const fileRecordFail = () => {
  return {
    type: ActionTypes.FILE_RECORD_FAILURE,
  };
};

const fileRecordLoading = () => {
  return {
    type: ActionTypes.FILE_RECORD_REQUEST,
  };
};

const createFileRecordSuccess = () => {
  return {
    type: ActionTypes.CREATE_FILE_RECORD_SUCCESS,
  };
};

const updateFileRecordSuccess = (payload = {}) => {
  return {
    type: ActionTypes.UPDATE_FILE_RECORD_SUCCESS,
    payload,
  };
};

const fetchFileRecordsSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_FILE_RECORD_SUCCESS,
    payload,
  };
};

const getFileRecordByIdSuccess = (payload = {}) => {
  return {
    type: ActionTypes.GET_FILE_RECORD_BY_ID_SUCCESS,
    payload,
  };
};

const createFileRecord = (data: any) => {
  return async (dispatch: any) => {
    dispatch(fileRecordLoading());
    await API.addFileRecord(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(createFileRecordSuccess());
          responseAlert({ res, label: "File record uploaded" });
          history.push(ROUTES.FILE_RECORD);
        } else {
          responseAlert({ res });
          dispatch(fileRecordFail());
        }
      })
      .catch((e: any) => {
        dispatch(fileRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const uploadFileRecord = (data: any) => {
  return async (dispatch: any) => {
    dispatch(fileRecordLoading());
    await API.uploadFileRecord(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "File record uploaded" });
          dispatch(updateFileRecordSuccess(res.payload));
          history.push(ROUTES.FILE_RECORD);
        } else {
          dispatch(fileRecordFail());
          if (!_.isEmpty(res)) {
            const message = _.get(res, "message");
            const payload = _.get(res, "payload");
            const empty = _.values(_.get(payload, "empty"));
            popupAlert({
              type: "error",
              title: "Error",
              text: `${message}\n${empty.join("\n")}`,
            });
          }
        }
      })
      .catch((e: any) => {
        dispatch(fileRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchFileRecord = () => {
  return async (dispatch: any) => {
    dispatch(fileRecordLoading());
    await API.fetchFileRecord()
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchFileRecordsSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(fileRecordFail());
        }
      })
      .catch((e: any) => {
        dispatch(fileRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getFileRecordByID = (id: string) => {
  return async (dispatch: any) => {
    dispatch(fileRecordLoading());
    await API.getFileRecordByID(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(getFileRecordByIdSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(fileRecordFail());
        }
      })
      .catch((e: any) => {
        dispatch(fileRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const searchFileRecord = (data: any) => {
  return async (dispatch: any) => {
    dispatch(fileRecordLoading());
    await API.searchFileRecord(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchFileRecordsSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(fileRecordFail());
        }
      })
      .catch((e: any) => {
        dispatch(fileRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const updateFileRecord = (data: any, id: any) => {
  return async (dispatch: any) => {
    dispatch(fileRecordLoading());
    await API.updateFileRecord(data, id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res });
          dispatch(updateFileRecordSuccess(res.payload));
          history.push(ROUTES.FILE_RECORD);
        } else {
          responseAlert({ res });
          dispatch(fileRecordFail());
        }
      })
      .catch((e: any) => {
        dispatch(fileRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

export {
  createFileRecord,
  fetchFileRecord,
  getFileRecordByID,
  searchFileRecord,
  updateFileRecord,
  uploadFileRecord,
  clearFileRecords,
};
