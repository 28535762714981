import styled from 'styled-components';
import ColorName from 'constants/Variables';
import { VerticalContainer, HorizontalContainer } from 'components/Common';

const Wrapper = styled(VerticalContainer)``;

const Content = styled(HorizontalContainer)`
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  background-color: ${ColorName.zircon};
	&.show-sidebar-logo {
		& img {
			object-fit: scale-down;
			transition: 0.5s;
		}
	}
	&.hide-sidebar-logo {
		& ${Content} {
			padding: 0;
		}
		& img {
			position: absolute;
			object-fit: scale-down;
			width: 115px;
			height: 60px;
			top: 0;
			left: 0;
			transition: 0.5s;
		}
	}
`;

const Logo = styled.div`
	& img {
		object-fit: scale-down;
		width: 100%;
	}
`;


export {
	HeaderContainer,
	Content,
	Wrapper,
	Logo,
};