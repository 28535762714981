import ActionTypes from "constants/ActionTypes";
import API from "api";
import _ from "lodash";
import { responseAlert } from "libs/alert";
import history from "../history";

const clearCategories = () => {
  return {
    type: ActionTypes.CLEAR_CATEGORIES,
  };
};

const clearCategoryDetails = () => {
  return {
    type: ActionTypes.CLEAR_CATEGORY_DETAILS,
  };
};

const categoryFail = () => {
  return {
    type: ActionTypes.CATEGORY_FAILURE,
  };
};

const categoryLoading = () => {
  return {
    type: ActionTypes.CATEGORY_REQUEST,
  };
};

const categorySuccess = () => {
  return {
    type: ActionTypes.CATEGORY_SUCCESS,
  };
};

const createCategorySuccess = () => {
  return {
    type: ActionTypes.CREATE_CATEGORY_SUCCESS,
  };
};

const updateCategorySuccess = (payload = {}) => {
  return {
    type: ActionTypes.UPDATE_CATEGORY_SUCCESS,
    payload,
  };
};

const fetchCategoriesSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_CATEGORY_SUCCESS,
    payload,
  };
};

const getCategoryByIdSuccess = (payload = {}) => {
  return {
    type: ActionTypes.GET_CATEGORY_BY_ID_SUCCESS,
    payload,
  };
};

const createCategory = (data: any, filter: any) => {
  return async (dispatch: any) => {
    dispatch(categoryLoading());
    await API.addCategory(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(createCategorySuccess());
          await responseAlert({ res, label: "Category created" });
          history.push(filter);
        } else {
          responseAlert({ res });
          dispatch(categoryFail());
        }
      })
      .catch((e: any) => {
        dispatch(categoryFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchCategories = () => {
  return async (dispatch: any) => {
    dispatch(categoryLoading());
    await API.fetchCategories()
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchCategoriesSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(categoryFail());
        }
      })
      .catch((e: any) => {
        dispatch(categoryFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchAllCategories = (data?: any) => {
  return async (dispatch: any) => {
    dispatch(categoryLoading());
    await API.fetchAllCategories(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchCategoriesSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(categoryFail());
        }
      })
      .catch((e: any) => {
        dispatch(categoryFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getCategoryByID = (id: string) => {
  return async (dispatch: any) => {
    dispatch(categoryLoading());
    await API.getCategoryByID(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(getCategoryByIdSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(categoryFail());
        }
      })
      .catch((e: any) => {
        dispatch(categoryFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const searchCategories = (data: any) => {
  return async (dispatch: any) => {
    dispatch(categoryLoading());
    await API.searchCategories(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchCategoriesSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(categoryFail());
        }
      })
      .catch((e: any) => {
        dispatch(categoryFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const updateCategory = (data: any, id: any, filter: any) => {
  return async (dispatch: any) => {
    dispatch(categoryLoading());
    await API.updateCategory(data, id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(updateCategorySuccess(res.payload));
          await responseAlert({ res, label: "Category updated" });
          history.push(filter);
        } else {
          responseAlert({ res });
          dispatch(categoryFail());
        }
      })
      .catch((e: any) => {
        dispatch(categoryFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const deactivateCategory = (id: string) => {
  return async (dispatch: any) => {
    dispatch(categoryLoading());
    await API.deactivateCategory(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Category deactivated" });
          dispatch(categorySuccess());
        } else {
          responseAlert({ res });
          dispatch(categoryFail());
        }
      })
      .catch((e: any) => {
        dispatch(categoryFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const activateCategory = (id: string) => {
  return async (dispatch: any) => {
    dispatch(categoryLoading());
    await API.activateCategory(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Category activated" });
          dispatch(categorySuccess());
        } else {
          responseAlert({ res });
          dispatch(categoryFail());
        }
      })
      .catch((e: any) => {
        dispatch(categoryFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

export {
  createCategory,
  fetchCategories,
  getCategoryByID,
  searchCategories,
  updateCategory,
  clearCategoryDetails,
  fetchAllCategories,
  deactivateCategory,
  activateCategory,
  clearCategories,
};
