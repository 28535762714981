/* eslint-disable react-hooks/exhaustive-deps */
import _, { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { FoldersActions } from "actions";
import { RootState } from "reducers";

import { Button, Checkbox } from "components/Common";
import {
  TemplateDetailsContainer,
  TemplateDetailsHeader,
  TemplateDetailsContent,
  ButtonField,
  TemplateDetailsForm,
  LoadingLayer,
  Spinner,
} from "../Popup.styles";
import { useTranslation } from "react-i18next";
import { popupAlert } from "../../../../libs/Utils";

const { addDataRecordToFolder } = FoldersActions;

interface SectionProps {
  onClose(): void;
  // data: any;
  // packages: string[];
  postPayload: any;
}

const AddPostToFolders = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();

  const { onClose, postPayload } = props;
  const { data, packages } = postPayload;
  const [checked, setChecked] = useState<any>({});
  const [folderChecked, setFolderChecked] = useState<string[]>([]);

  const foldersIsLoading = useSelector((state: RootState) => {
    return state.Folders.isLoading;
  });
  const folders = useSelector((state: RootState) => {
    return state.Folders.folders;
  });
  const accounts = useSelector((state: RootState) => {
    return state.Account.accounts;
  });

  const [packagesList, setPackageList] = useState<any[]>([]);

  useEffect(() => {
    if (!_.isEmpty(packages)) {
      const getAccounts = _.get(accounts, "items");
      const resolvePackageList: any[] = [];
      _.forEach(packages, (item) => {
        const findPackage = _.find(getAccounts, { id: item });
        resolvePackageList.push(findPackage);
      });
      setPackageList(resolvePackageList);
    }
  }, [packages]);

  useEffect(() => {
    if (!_.isEmpty(data) && !_.isEmpty(packages)) {
      const resolveFolder: any = {};
      const resolveFolderChecked: string[] = [];
      _.forEach(_.get(folders, "items"), (folder) => {
        const accountId = _.get(folder, "accountId.id");
        const filterData: string[] = [];
        _.forEach(data, (item) => {
          if (_.get(item, "account.id") === accountId)
            filterData.push(_.get(item, "id"));
        });
        if (_.includes(packages, accountId)) {
          const folderId = _.get(folder, "id");
          // resolveFolder.assign(folderId);
          _.assign(resolveFolder, { [folderId]: filterData });
          resolveFolderChecked.push(folderId);
        }
      });
      setFolderChecked(resolveFolderChecked);
      setChecked(resolveFolder);
    }
  }, [data]);

  const onAdd = async () => {
    const resolvePostPayload = _.map(checked, (value, key) => {
      const findFolder = _.find(_.get(folders, "items"), { id: key });
      const accountId = _.get(findFolder, "accountId.id");
      if (!_.isEmpty(value))
        return {
          accountId,
          folderId: key,
          dataRecordId: value,
        };
    });
    if (isEmpty(_.compact(resolvePostPayload)))
      popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: t("alert.noFolderFoundToAddSelectedPosts"),
      });
    else {
      const resolvePayload = {
        dataProcess: _.compact(resolvePostPayload),
      };
      await dispatch(addDataRecordToFolder(resolvePayload));
    }
    if (onClose) onClose();
  };

  const handleClosePopup = () => {
    if (onClose) onClose();
  };

  const onCheckedFolder = (folderId: string, dataList: any[]) => {
    const isChecked = _.includes(folderChecked, folderId);
    const newChecked = {
      ...checked,
    };
    const dataListId: string[] = [];
    _.forEach(dataList, (item) => dataListId.push(_.get(item, "id")));
    if (isChecked) {
      const newFolderChecked: string[] = [];
      _.forEach(folderChecked, (folder) => {
        if (folderId !== folder) newFolderChecked.push(folder);
      });
      setFolderChecked(newFolderChecked);
      _.assign(newChecked, { [folderId]: [] });
    } else {
      _.assign(newChecked, { [folderId]: dataListId });
      setFolderChecked([...folderChecked, folderId]);
    }
    setChecked(newChecked);
  };

  const onCheckPost = (
    postId: string,
    folderId: string,
    dataLength: number
  ) => {
    const newChecked = {
      ...checked,
    };
    let isCheckedAll = false;
    const findCheckedPost = _.get(checked, folderId);
    if (_.includes(findCheckedPost, postId)) {
      const newCurrentCheckedPost = _.filter(
        findCheckedPost,
        (item) => item !== postId
      );
      _.assign(newChecked, { [folderId]: newCurrentCheckedPost });
    } else _.assign(newChecked, { [folderId]: [...findCheckedPost, postId] });

    const newCheckedPost = _.get(newChecked, folderId);

    if (newCheckedPost.length === dataLength) isCheckedAll = true;

    if (isCheckedAll) {
      setFolderChecked([...folderChecked, folderId]);
    } else {
      const newCheckedFolder: string[] = [];
      _.forEach(folderChecked, (folder) => {
        if (folder !== folderId) newCheckedFolder.push(folder);
      });
      setFolderChecked(newCheckedFolder);
    }
    setChecked(newChecked);
  };

  const renderSelectFolder = () => {
    const render = _.map(packagesList, (item, index) => {
      const getFolders = _.filter(_.get(folders, "items"), (folder) => {
        const folderAccountId = _.get(folder, "accountId.id");
        const accountId = _.get(item, "id");
        if (folderAccountId === accountId) return folder;
      });
      const resolveTree = _.map(getFolders, (folder) => {
        const resolveDataRecords = _.filter(data, (dataRecord) => {
          const recordPackageId = _.get(dataRecord, "account.id");
          if (recordPackageId === _.get(item, "id"))
            return {
              value: _.get(dataRecord, "id"),
              label: _.get(dataRecord, "title"),
            };
        });
        return {
          value: _.get(folder, "id"),
          label: _.get(folder, "folderName"),
          children: resolveDataRecords,
        };
      });

      return (
        <React.Fragment key={`package-${index}`}>
          <span
            style={{
              fontWeight: "bold",
              borderBottom: "1px solid black",
              paddingBottom: "5px",
              display: "block",
            }}
            className="package-header"
          >
            {t("label.package")}: {_.get(item, "accountName")}
          </span>
          {!_.isEmpty(resolveTree) ? (
            _.map(resolveTree, (tree, index) => {
              const children = _.get(tree, "children");
              const folderId = _.get(tree, "value");
              const currentChecked = _.get(checked, folderId);
              const isCheckedAll = currentChecked?.length === children?.length;
              return (
                <div className="folder" key={`folder-${index}`}>
                  <div className="folder-name">
                    <span>{_.get(tree, "label")} - </span>
                    <span
                      className={`select-btn ${
                        _.isEmpty(children) ? "disabled" : ""
                      }`}
                      onClick={() =>
                        !_.isEmpty(children) &&
                        onCheckedFolder(folderId, children)
                      }
                    >
                      {isCheckedAll
                        ? t("label.unselectedAll")
                        : t("label.selectedAll")}
                    </span>
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    {_.map(children, (itemChil, innerIndex) => {
                      return (
                        <Checkbox
                          width="100%"
                          checked={_.includes(
                            _.get(checked, folderId),
                            _.get(itemChil, "id")
                          )}
                          context={_.get(itemChil, "title")}
                          key={`checkbox-${index}-${innerIndex}`}
                          onChangeHandler={() =>
                            onCheckPost(
                              _.get(itemChil, "id"),
                              folderId,
                              children.length
                            )
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })
          ) : (
            <span>{t("label.thisPackageNoFolder")}</span>
          )}
        </React.Fragment>
      );
    });
    return render;
  };

  const renderMain = () => {
    return (
      <TemplateDetailsContainer>
        <TemplateDetailsContent width="650px">
          {foldersIsLoading && (
            <LoadingLayer>
              <Spinner />
            </LoadingLayer>
          )}
          <TemplateDetailsHeader>
            {t("headerPopup.addPostSelectedToFolder")}
          </TemplateDetailsHeader>
          <TemplateDetailsForm height="300px" className="flex-start add-post">
            <div>{renderSelectFolder()}</div>
          </TemplateDetailsForm>
          <ButtonField className="mt-2">
            <Button type="submit" buttonWidth="100px" onClick={() => onAdd()}>
              {t("button.add")}
            </Button>
            <Button
              onClick={() => handleClosePopup()}
              type="submit"
              buttonWidth="100px"
              buttonMargin="0 0 0 10px"
            >
              {t("button.cancel")}
            </Button>
          </ButtonField>
        </TemplateDetailsContent>
      </TemplateDetailsContainer>
    );
  };
  return renderMain();
};

export default AddPostToFolders;
