/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { CONSTANTS, ROUTES } from "constants/Constants";
import RouteTypes from "constants/RouteTypes";
import ColorName from "constants/Variables";

import { ClientActions } from "actions";
import { RootState } from "reducers";

import history from "../../../history";
import TableUltils from "libs/TableUltils";
import { generateFilter, popupAlert } from "libs/Utils";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Table,
  Input,
  Button,
  Checkbox,
  ContainerSearch,
  FlexWrapRow,
} from "components/Common";
import { useTranslation } from "react-i18next";
import { LogsDetails } from "components/Common/Popup";
import { useLocation } from "react-router-dom";

const {
  deactivateClient,
  activateClient,
  searchClients,
  exportClients,
  clearClientsPayload,
} = ClientActions;

const DEFAULT_FILTER = {
  clientName: "",
  status: CONSTANTS.STATUS.ACTIVE,
  clientNumber: "",
  accountManager: "",
  limit: 10,
  page: 1,
  sortBy: "updatedAt",
  orderBy: "DESC",
};

interface FilterStructure {
  clientName: string;
  status: string;
  clientNumber: string;
  accountManager: string;
  limit: number;
  page: number;
  sortBy: string;
  orderBy: string;
}

const InputStyleProps = {
  margin: "10px 10px 10px 0",
  width: "200px",
};

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "10px 10px 10px 0",
};

const Clients = () => {
  const { t } = useTranslation("translation");
  const location = useLocation();
  const dispatch = useDispatch();
  const clientIsLoading = useSelector((state: RootState) => {
    return state.Clients.isLoading;
  });
  const clients = useSelector((state: RootState) => {
    return state.Clients.clients;
  });

  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const [logData, setLogData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataClients, setDataClients] = useState<any>([]);
  const dataHead = [
    "no",
    "clientName",
    "clientNumber",
    "accountManager",
    "status",
    "startDate",
    "endDate",
    "actions",
  ];

  const dataTable = TableUltils._processDataTable(
    [
      "no",
      "id",
      "clientName",
      "clientNumber",
      "accountManager",
      "status",
      "startDate",
      "endDate",
      "action",
    ],
    dataClients
  );
  const listColumnCenter = [5, 7, 8];

  useEffect(() => {
    const stateFilter: any = _.get(location, "state.filter");
    const prevRoute = _.get(location, "state.from");
    const resolveFilter = {
      ...filter,
    };
    if (
      stateFilter &&
      _.includes(
        [
          RouteTypes.ADMIN_ROUTES.EDIT_CLIENT,
          RouteTypes.ADMIN_ROUTES.CREATE_CLIENT,
          RouteTypes.ADMIN_ROUTES.CLIENT_STEP_SECOND,
        ],
        prevRoute
      )
    ) {
      _.assign(resolveFilter, { ...stateFilter });
      history.replace({ ...history.location, state: {} });
    }
    setFilter(resolveFilter);
    dispatch(searchClients(generateFilter(resolveFilter)));

    return () => {
      dispatch(clearClientsPayload());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(clients)) {
      const getDataClients: any[] = _.get(clients, "items");
      if (currentPage > 1) {
        setDataClients([...dataClients, ...getDataClients]);
      } else setDataClients(getDataClients);
    }
  }, [clients]);

  const callbackCurrentPageLink = (page: number) => {
    const newFilter = {
      ...filter,
      page,
    };
    setFilter(newFilter);
    dispatch(searchClients(generateFilter(newFilter)));
  };

  const callbackAction = async (action: string, id: string, item: any) => {
    if (action === CONSTANTS.ACTION.LOG)
      setLogData({ logType: "client", rowId: id });

    if (action === CONSTANTS.ACTION.EDIT)
      history.push({
        pathname: "/clients/edit",
        state: {
          id,
          filter,
        },
      });

    if (action === CONSTANTS.ACTION.PACKAGES)
      history.push({ pathname: "/clients/packages", state: { id } });

    if (action === CONSTANTS.ACTION.DEACTIVATE) {
      const status = _.get(item, "status");
      const clientName = _.get(item, "clientName");
      const statusLabel =
        _.get(item, "status") === CONSTANTS.STATUS.ACTIVE
          ? t("alert.deactive")
          : t("alert.active");
      const content = document.createElement("span");
      content.innerHTML = `${t(
        "alert.areYouSureYouWantTo"
      )}</br> <b>${statusLabel}</b> ${t(
        "alert.theClient"
      )} <b>${clientName}</b>?`;
      await popupAlert({
        type: "warning",
        title: t("alert.warning"),
        content,
        buttons: true,
        dangerMode: true,
      }).then(async (willContinue) => {
        if (willContinue) {
          if (status === CONSTANTS.STATUS.ACTIVE)
            await dispatch(deactivateClient(id));
          else await dispatch(activateClient(id));

          await dispatch(searchClients(generateFilter(filter)));
        }
      });
    }
  };

  const setValueStatus = (status: string) => {
    const newFilter = {
      ...filter,
      status,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(searchClients(generateFilter(newFilter)));
    setCurrentPage(1);
  };

  const onReset = async () => {
    setCurrentPage(1);
    setFilter(DEFAULT_FILTER);
    await dispatch(searchClients(generateFilter(DEFAULT_FILTER)));
  };

  const onFilter = (page: number) => {
    const newFilter = {
      ...filter,
      page: page,
    };
    setCurrentPage(page);
    setFilter(newFilter);
    dispatch(searchClients(generateFilter(newFilter)));
  };

  const onCreate = () => {
    history.push({
      pathname: ROUTES.CREATE_CLIENT,
      state: {
        filter,
      },
    });
  };

  const onFetchMore = () => {
    if (!clientIsLoading && currentPage < _.get(clients, "meta.totalPages"))
      onFilter(filter.page + 1);
  };

  const onShowMore = () => {
    if (!clientIsLoading && currentPage < _.get(clients, "meta.totalPages"))
      onFilter(filter.page + 1);
  };

  const _renderTable = () => {
    return (
      <Table
        dataHeader={dataHead}
        dataTable={dataTable}
        fitColumns={[1, 5, 6, 7, 8]}
        listColumnCenter={listColumnCenter}
        hasPagination={true}
        callbackCurrentPageLink={callbackCurrentPageLink}
        metaPagination={clients.meta}
        listActionItem={[
          CONSTANTS.ACTION.EDIT,
          CONSTANTS.ACTION.DEACTIVATE,
          CONSTANTS.ACTION.LOG,
          CONSTANTS.ACTION.PACKAGES,
        ]}
        callBackAction={callbackAction}
        loading={clientIsLoading}
        showMore={onShowMore}
      />
    );
  };

  const renderMain = () => {
    return (
      <>
        {!_.isEmpty(logData) && (
          <LogsDetails onClose={() => setLogData({})} data={logData} />
        )}
        <ContainerSearch className={clientIsLoading ? "loading" : ""}>
          <FlexWrapRow>
            <Input
              value={_.get(filter, "clientName")}
              placeholder={t("placeholder.clientName")}
              onChangeHandler={(e: any) =>
                setFilter({ ...filter, clientName: e.target.value })
              }
              onEnter={() => onFilter(1)}
              {...InputStyleProps}
            />
            <Input
              value={_.get(filter, "clientNumber")}
              placeholder={t("placeholder.clientNumber")}
              onChangeHandler={(e: any) =>
                setFilter({ ...filter, clientNumber: e.target.value })
              }
              onEnter={() => onFilter(1)}
              isNum
              {...InputStyleProps}
            />
            <Input
              value={_.get(filter, "accountManager")}
              placeholder={t("placeholder.accountManager")}
              onChangeHandler={(e: any) =>
                setFilter({ ...filter, accountManager: e.target.value })
              }
              onEnter={() => onFilter(1)}
              {...InputStyleProps}
            />
            <Button onClick={() => onFilter(1)} {...ButtonStyleProps}>
              {t("button.search")}
            </Button>
            <Button onClick={() => onReset()} {...ButtonStyleProps}>
              {t("button.reset")}
            </Button>
            <Button
              onClick={() => dispatch(exportClients())}
              {...ButtonStyleProps}
            >
              {t("button.export")}
            </Button>
            <Button
              onClick={() => onCreate()}
              background={ColorName.william}
              {...ButtonStyleProps}
            >
              + {t("button.create")}
            </Button>
          </FlexWrapRow>
          <Checkbox
            checked={_.get(filter, "status") === CONSTANTS.STATUS.INACTIVE}
            context={t("label.showInactiveClients")}
            onChangeHandler={(e: boolean) =>
              setValueStatus(
                e ? CONSTANTS.STATUS.INACTIVE : CONSTANTS.STATUS.ACTIVE
              )
            }
            margin="10px 0 0 0"
          />
        </ContainerSearch>
        {_renderTable()}
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.ADMIN_ROUTES.CLIENTS}
      parent={RouteTypes.ADMIN_ROUTES.GROUPS}
      title={t("titlePage.clients")}
      onScrollBottom={() => onFetchMore()}
    />
  );
};

export default Clients;
