const ROUTES = {
  HOMEPAGE: "/",
  FILE_RECORD: "/file-record",
  AUTHENTICATION: "/authentication",
  ADD_CSV: "/add-csv",
  DATA_RECORD: "/data-record",
  CATEGORIES: "/categories",
  SOURCES_SETTINGS: "/sources-settings",
  CREATE_SOURCE: "/create-source",
  EDIT_SOURCE: "/edit-source",
  LOGS: "/logs",
  REGISTER: "/register",
  VERIFY_ACCOUNT: "/verify-account",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  DATA_RECORD_DETAILS: "/data-record-details",
  USERS: "/users",
  CLIENTS: "/clients",
  CREATE_CLIENT: "/clients/create",
  EDIT_CLIENT: "/clients/edit",
  CREATE_ACCOUNT: "/clients/add-account",
  EDIT_ACCOUNT: "/clients/edit-account",
  LIST_ACCOUNT: "/clients/list-accounts",
  CREATE_USER: "/users/create-user",
  ROLES: "/roles",
  CREATE_ROLE: "/roles/create",
  EDIT_ROLE: "/roles/edit-role",
  LIST_USER: "/clients/accounts/list-user",
  CLIENT_STEP_SECOND: "/clients/create-client-step-2",
  FILTERS: "/filters",
  SIMPLE_FILTER: "/create-simple-filter",
  FILTER_DETAILS: "/filter-details",
  EDIT_ADVANCE_FILTER: "/edit-advance-filter",
  EDIT_SIMPLE_FILTER: "/edit-simple-filter",
  PROFILE: "/profile",
  FOLDERS: "/folders",
  CREATE_FOLDERS: "/folders/create",
  EDIT_FOLDERS: "/folders/edit",
  EMAIL_TEMPLATE: "/template/email",
  CREATE_EMAIL_TEMPLATE: "/template/email/create",
  EDIT_EMAIL_TEMPLATE: "/template/email/edit",
  PDF_TEMPLATE: "/template/pdf",
  CREATE_PDF_TEMPLATE: "/template/pdf/create",
  EDIT_PDF_TEMPLATE: "/template/pdf/edit",
  DISTRIBUTION_LIST: "/delivery/distribution",
  CREATE_DISTRIBUTION_LIST: "/delivery/distribution/create",
  EDIT_DISTRIBUTION_LIST: "/delivery/distribution/edit",
  EMAIL_DELIVERY: "/delivery/email",
  CREATE_EMAIL_DELIVERY: "/delivery/email/create",
  EDIT_EMAIL_DELIVERY: "/delivery/email/edit",
  POST: "/post",
  MANAGE_RECORD: "/manage-record",
  MANAGE_SOURCES: "/manage-sources",
  TEMPLATE: "/template",
  DELIVERY: "/delivery",
  STMP: "/STMP",
};

const CONSTANTS = {
  STATUS: {
    ACTIVE: "active",
    INACTIVE: "inactive",
  },
  CONFIG_TYPE: {
    SMTP: "SMTP",
  },
  STATUS_USER: {
    VERIFIED: 1,
    APPROVED: 2,
  },
  ROLE: {
    SUPER_ADMIN: "super admin",
    CLIENT: "client",
    USER: "user",
  },
  USER_TYPE: {
    ADMIN: "admin",
    CLIENT: "client",
    USER: "user",
  },
  USER_REQUEST_TYPE: {
    FORGOT_PASSWORD: "forgot password",
    VERIFY_ACCOUNT: "verify account",
  },
  LOG_TYPE: {
    DATA_RECORD: "data record",
    SOURCE: "source",
  },
  MEDIA_TYPE: {
    PRINT: "print",
    WEB: "web",
    TV: "tv",
    SOCIAL_NETWORK: "social network",
  },
  BILLING_PERIOD: {
    THREE_MONTHS: "3 months",
    SIX_MONTHS: "6 months",
    EVERY_YEAR: "every year",
  },
  BILL_BY: {
    EMAIL: "email",
    POST: "post",
  },
  ACCOUNT_MANAGER: {
    ARCHIVE: "archive",
    TEST: "test",
  },
  TITLE_USER: {
    MR: "Mr",
    MRS: "Mrs",
    MS: "Ms",
  },
  ACTION: {
    EDIT: "edit",
    DEACTIVATE: "deactivate",
    ACCOUNT_LIBRARY: "accountLibrary",
    LIST_PACKAGES: "changePackage",
    USERS: "manageUsers",
    VIEW: "view",
    ACCOUNTS: "accounts",
    LOG: "logs",
    DELETE: "delete",
    REMOVE: "remove",
    PACKAGES: "managePackages",
    LOGIN_AS: "loginAs",
    UNHIDE: "unHide",
  },
  DATE_OF_WEEK: {
    SUN: 0,
    MON: 1,
    TUE: 2,
    WED: 3,
    THU: 4,
    FRI: 5,
    SAT: 6,
  },
  LIST_FIELD_EXPORT: {
    PUBLISHED_AT: "publishedAt",
    HEADLINE: "title",
    SOURCE: "source",
    LINK: "link",
  },
  EMAIL_TYPE: {
    FORGOT_PASSWORD: "Forgot password",
    VERIFY_ACCOUNT: "Verify email",
    EMAIL_SCHEDULE: "Email schedule",
    EMAIL_REPORT: "Email report",
    ITEM_NEWS: "Item News",
    EMAIL_TEMPLATE_FOR_NEWS: "Email template for news",
  },
  ORDER_BY: {
    CREATED_AT: {
      label: "Created at",
      value: "createdAt",
    },
    UPDATED_AT: {
      label: "Updated at",
      value: "updatedAt",
    },
    PUBLISHED_AT: {
      label: "Published at",
      value: "publishedAt",
    },
    SELECTED: {
      label: "Selected",
      value: "selected",
    },
  },
};

const API = {
  ACCOUNT: {
    BASIC: "/account",
    REGISTER: "/account/register",
    USER_FOR_ACCOUNT: "/user",
    UPDATE: "/account/update",
    DEACTIVATE: "/account/deactivate",
    ACTIVATE: "/account/activate",
    FOR_USER_PORTAL: "/account/get-accounts/all",
  },
  ACCOUNT_OF_CLIENT: {
    BASIC: "/account-of-client",
    REGISTER: "/account-of-client/register",
    USER_FOR_ACCOUNT: "/user",
    UPDATE: "/account-of-client/update",
    DEACTIVATE: "/account-of-client/deactivate",
    ACTIVATE: "/account-of-client/activate",
    FOR_USER_PORTAL: "/account-of-client/get-accounts/all",
  },
  AUTHENTICATION: {
    BASIC: "/auth",
    LOGIN: "/auth/login",
    LOGIN_AS: "/auth/login-as",
    REFRESH_TOKEN: "/auth/refresh",
  },
  CATEGORIES: {
    BASIC: "/category",
    GET_ALL: "/category/get-all-categories/fetch",
    DEACTIVATE: "/category/deactivate",
    ACTIVATE: "/category/activate",
  },
  CLIENT: {
    BASIC: "/client",
    USER_FOR_CLIENT: "/user",
    GET_CLIENT_BY_ID: "/client/get-client",
    UPDATE: "/client/update",
    REGISTER_FIRST_FORM: "/client/register",
    REGISTER_SECOND_FORM: "/client/register-form-2",
    DEACTIVATE: "/client/deactivate",
    ACTIVATE: "/client/activate",
    EXPORT: "/client/export",
    GET_ALL: "/client/get-clients/all",
  },
  CLIENT_USER: {
    BASIC: "/user-of-client",
    REGISTER_USER: "/user-of-client/register-user",
    UPDATE: "/user-of-client/update",
    UPDATE_ACCOUNT_LIST: "/user-of-client/update-account-library",
    DEACTIVATE: "/user-of-client/deactivate",
    ACTIVATE: "/user-of-client/activate",
    EXPORT: "/user-of-client/export",
    GET_USER_BY_ID: "/user-of-client/get-user",
  },
  DASHBOARD: {
    BASIC: "/common/count-dashboard-admin",
    STATIC: "/data-record/dashboard",
  },
  DATA_RECORD: {
    BASIC: "/data-record",
    GET_BY_ID: "/data-record/get-by-id",
    DELETE: "/data-record/delete",
    FILTER_BY_ADMIN: "/data-record/get-data/admin",
    GET_DATA_BY_FOLDER_ID:
      "/data-record/get-data-record-for-folder/data-records",
    PREVIEW_FILTER: "/data-record/get-data-record-for-preview/filter",
    SEND_REPORT_BY_EMAIL: "/data-record/send-email-report/report-csv",
    GET_DATA_RECORD_BY_ACCOUNT_ID: "/data-record/get-data/by-user",
    GET_PREVIEW_DATA_RECORDS_FOR_USER:
      "/data-record/get-data-record-for-preview-when-register/all",
    SEND_DATA_RECORD_TO_MAIL: "/data-record/send-data-records/emails",
    SEND_ATTACHMENT_TO_MAIL: "/data-record/send-attach-file/emails",
    UNHIDDEN_POSTS: "/data-record/update/unhidden",
    HIDDEN_POSTS: "/data-record/update/hidden",
  },
  EMAIL_DELIVERY: {
    BASIC: "/email-schedule",
    UPDATE: "/email-schedule/update",
    DEACTIVATE: "/email-schedule/deactivate",
  },
  EMAIL_TEMPLATE: {
    BASIC: "/email-template",
    DEACTIVATE: "/email-template/deactivate",
    ACTIVATE: "/email-template/activate",
    GET_BY_TYPE: "/email-template/get-email-template/by-type",
  },
  FILE_RECORD: {
    BASIC: "/file-record",
    UPLOAD_FILE_RECORD: "/file-record/upload-file-record",
  },
  FILTER: {
    BASIC: "/filter",
    UPDATE: "/filter/update",
    DEACTIVATE: "/filter/deactivate",
    ACTIVATE: "/filter/activate",
    EXPORT: "/filter/export/excel",
  },
  FOLDER: {
    BASIC: "/folders",
    CLIENT_FOR_FOLDER: "/client",
    UPDATE: "/folders/update",
    DEACTIVATE: "/folders/deactivate",
    ACTIVATE: "/folders/activate",
    EXPORT: "/folders/export",
    ADD_DATA_RECORD_TO_FOLDER: "/folders/add-data-records-for-folder/records",
    REMOVE_POST_FROM_FOLDER: "/folders/delete-post/folder",
  },
  LOGS: {
    BASIC: "/log",
  },
  PDF_TEMPLATE: {
    BASIC: "/pdf-template",
    FETCH_USER_FOR_PDF: "/user",
    DEACTIVATE: "/pdf-template/deactivate",
    ACTIVATE: "/pdf-template/activate",
    EXPORT: "/pdf-template/export",
  },
  REPORT: {
    BASIC: "/reports",
    UPDATE: "/reports/update",
  },
  ROLE: {
    BASIC: "/role",
    FETCH_MODULE: "/role/module-api",
    GET_ROLE_BY_ID: "/role/get-by-id",
    DEACTIVATE: "/role/deactivate",
    ACTIVATE: "/role/activate",
  },
  SOURCE: {
    BASIC: "/source",
    DEACTIVATE: "/source/deactivate",
    ACTIVATE: "/source/activate",
    GET_ALL: "/source/get-source/all",
  },
  USER: {
    BASIC: "/user",
    REGISTER: "/auth/register",
    REGISTER_USER: "/user/register-user",
    UPDATE: "/user/update",
    UPDATE_ACCOUNT_LIST: "/user/update-account-library",
    DEACTIVATE: "/user/deactivate",
    ACTIVATE: "/user/activate",
    FORGOT_PASSWORD: "/auth/forgot-password",
    RESET_PASSWORD: "/auth/reset-password",
    VERIFY_ACCOUNT: "/auth/verify-account",
    EXPORT: "/user/export",
    GET_USER_BY_ID: "/user/get-user",
    UPDATE_FOR_SELF: "user/update-for-self",
    GET_USER_PASSWORD_BY_ID: "/user/password",
    DELETE_USER: "user/terminate",
  },
  USER_OF_CLIENT: {
    BASIC: "/user-of-client",
    REGISTER: "/auth/register",
    REGISTER_USER: "/user-of-client/register-user",
    UPDATE: "/user-of-client/update",
    UPDATE_ACCOUNT_LIST: "/user-of-client/update-account-library",
    DEACTIVATE: "/user-of-client/deactivate",
    ACTIVATE: "/user-of-client/activate",
    FORGOT_PASSWORD: "/user-of-client/forgot-password",
    RESET_PASSWORD: "/user-of-client/reset-password",
    VERIFY_ACCOUNT: "/user-of-client/verify-account",
    EXPORT: "/user-of-client/export",
    GET_USER_BY_ID: "/user-of-client/get-user",
    UPDATE_FOR_SELF: "user-of-client/update-for-self",
    GET_ALL_MEMBER: "/user-of-client/get-all-member",
  },
  MEMBER: {
    BASIC: "/member",
    UPDATE: "/member/update",
    DEACTIVATE: "/member/deactivate",
    ACTIVATE: "/member/activate",
    EXPORT: "/member/export",
    GET_MEMBER_BY_ID: "/member/get-user",
  },
  MESSAGE: {
    BASIC: "/messages",
    GET_DIRECT_MESSAGE: "/messages/directMessages",
    REMOVE: "/messages/remove",
  },
  SYSTEM_CONFIG: {
    BASIC: "/system-config",
    UPDATE: "/system-config/update",
    TEST_EMAIL: "/system-config/testEmail",
    RESTART_SYSTEM: "/system-config/restartSystem",
  },
};

const LOG_LEVELS = {
  1: "normal",
  2: "warning",
  3: "danger",
};

export { ROUTES, CONSTANTS, API, LOG_LEVELS };
