import Api from "./Base";
import { API } from "constants/Constants";

const { USER } = API;

export function register(data: any) {
  return Api.postFormData(USER.REGISTER, data);
}

export function registerUser(data: any) {
  return Api.postFormData(USER.REGISTER_USER, data);
}

export function fetchUsers(data: any) {
  return Api.get(USER.BASIC, data);
}

export function updateUser(data: any, id: string) {
  return Api.putFormData(`${USER.UPDATE}/${id}`, data);
}

export function updateAccountListUser(id: string, data: any) {
  return Api.put(`${USER.UPDATE_ACCOUNT_LIST}/${id}`, data);
}

export function deactivateUser(id: string) {
  return Api.put(`${USER.DEACTIVATE}/${id}`);
}

export function activateUser(id: string) {
  return Api.put(`${USER.ACTIVATE}/${id}`);
}

export function forgotPassword(data: any) {
  return Api.post(USER.FORGOT_PASSWORD, data);
}

export function resetPassword(data: any) {
  return Api.post(USER.RESET_PASSWORD, data);
}

export function verifyAccount(data: any) {
  return Api.post(USER.VERIFY_ACCOUNT, data);
}

export function exportUsers() {
  return Api.getBlob(USER.EXPORT);
}

export function getUserById(id: string) {
  return Api.get(`${USER.GET_USER_BY_ID}/${id}`);
}

export function updateUserForSelf(data: any) {
  return Api.putFormData(USER.UPDATE_FOR_SELF, data);
}

export function getUserPasswordById(id: string) {
  return Api.putFormData(`${USER.GET_USER_PASSWORD_BY_ID}/${id}`);
}

export function deleteUser(id: string) {
  return Api.put(`${USER.DELETE_USER}/${id}`);
}
