/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import RouteTypes from "constants/RouteTypes";
import { create } from "apisauce";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Button,
  InputFile,
  FormDetails,
  FormGroup,
  FormLabel,
} from "components/Common";
import { Container } from "../AddCsv/AddCSV.styles";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

const ButtonStyleProps = {
  buttonMargin: "10px 10px 10px 0",
};

const AddCSV = () => {
  const { t } = useTranslation("translation");

  const [fileImport, setFileImport] = useState<File | null>(null);
  const [filename, setFilename] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");

  useEffect(() => {
    return () => {};
  }, []);

  const checkInputUpload = async () => {
    const formDataRecord = new FormData();
    formDataRecord.append("file", fileImport || "");
    const api = create({
      baseURL: `http://text-analyze.mediaportal.al/api/`,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
    setIsLoading(true);
    const res = await api.post("pharse", formDataRecord);
    setResponseMessage(_.get(res.data, "message"));
    setIsLoading(false);
    setTimeout(() => {
      setResponseMessage("");
    }, 2000);
  };

  const onSelectFile = (e: any) => {
    if (e.target.files[0]) {
      setFileImport(e.target.files[0]);
      const file = e.target.files[0];
      setFilename(_.get(file, "name"));
    } else return;
  };

  const _renderForUploadFile = () => {
    return (
      <FormDetails>
        <FormGroup>
          <FormLabel aria-label="require">{t("label.fileCSV")}</FormLabel>
          <InputFile
            onChangeHandler={(e: any) => onSelectFile(e)}
            accept=".csv"
            margin="10px 10px 10px 0"
            filename={filename}
          />
        </FormGroup>
        <div>
          <Button
            onClick={() => checkInputUpload()}
            {...ButtonStyleProps}
            disabled={!Boolean(filename)}
          >
            {t("titlePage.training")}
          </Button>
        </div>
        <div>
          <Typography>{responseMessage}</Typography>
        </div>
      </FormDetails>
    );
  };

  const _renderMain = () => {
    return <Container>{_renderForUploadFile()}</Container>;
  };

  return (
    <DefaultLayout
      content={_renderMain()}
      loading={isLoading}
      currentPage={RouteTypes.ADMIN_ROUTES.TRAINING}
      title={t("titlePage.training")}
    />
  );
};

export default AddCSV;
