/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { RootState } from "reducers";
import { UsersActions } from "actions";

import { CONSTANTS } from "constants/Constants";
import RouteTypes from "constants/RouteTypes";
import ColorName from "constants/Variables";

import TableUltils from "libs/TableUltils";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Button,
  Checkbox,
  Input,
  Table,
  ContainerSearch,
  FlexWrapRow,
} from "components/Common";
import { MemberDetails, LogsDetails } from "components/Common/Popup";
import { useTranslation } from "react-i18next";
import { generateFilter, popupAlert } from "libs/Utils";

const DEFAULT_FILTER = {
  keyword: "",
  email: "",
  status: CONSTANTS.STATUS.ACTIVE,
  userType: CONSTANTS.USER_TYPE.ADMIN,
  limit: 10,
  page: 1,
  sortBy: "updatedAt",
  orderBy: "DESC",
};

interface FilterStructure {
  keyword: string;
  email: string;
  status: string;
  userType: string;
  limit: number;
  page: number;
  sortBy: string;
  orderBy: string;
}

const InputStyleProps = {
  width: "200px",
  margin: "10px 10px 10px 0",
};

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "10px 10px 10px 0",
};

const { fetchUsers, deactivateUser, activateUser, clearUserPayload } =
  UsersActions;

const Users = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const usersIsLoading = useSelector((state: RootState) => {
    return state.Users.isLoading;
  });
  const users = useSelector((state: RootState) => {
    return state.Users.users;
  });

  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const [dataPopup, setDataPopup] = useState<any>({});
  const [logData, setLogData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isCreateUser, setIsCreateUser] = useState<boolean>(false);
  const [dataUser, setDataUser] = useState<any>([]);
  const dataHead = [
    "no",
    "fullName",
    "email",
    "userType",
    "address",
    "status",
    "actions",
  ];
  const dataTable = TableUltils._processDataTable(
    [
      "no",
      "id",
      "userData.fullName",
      "email",
      "role.name",
      "userData.address",
      "status",
      "action",
    ],
    dataUser
  );

  const listColumnCenter = [6, 7];

  useEffect(() => {
    dispatch(fetchUsers(generateFilter(filter)));
    return () => {
      dispatch(clearUserPayload());
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(users)) {
      const getDataUsers: any[] = _.get(users, "items");
      if (currentPage > 1) {
        setDataUser([...dataUser, ...getDataUsers]);
      } else setDataUser(getDataUsers);
    }
  }, [users]);

  const callbackCurrentPageLink = (page: number) => {
    const newFilter = {
      ...filter,
      page,
    };
    setFilter(newFilter);
    dispatch(fetchUsers(generateFilter(newFilter)));
  };

  const callbackAction = async (action: string, id: string, item: object) => {
    if (action === CONSTANTS.ACTION.EDIT) {
      const findMember = _.find(users.items, { id });
      setDataPopup(findMember);
      setIsCreateUser(true);
    }
    if (action === CONSTANTS.ACTION.LOG)
      setLogData({ logType: "user", rowId: id });

    if (action === CONSTANTS.ACTION.DEACTIVATE) {
      const clientName = _.get(item, "userData.fullName");
      const status = _.get(item, "status");
      const statusLabel =
        status === CONSTANTS.STATUS.ACTIVE
          ? t("alert.deactive")
          : t("alert.active");
      const content = document.createElement("span");
      content.innerHTML = `${t(
        "alert.areYouSureYouWantTo"
      )}</br> <b>${statusLabel}</b> ${t(
        "alert.theMember"
      )} <b>${clientName}</b>?`;
      await popupAlert({
        type: "warning",
        title: t("alert.warning"),
        content,
        buttons: true,
        dangerMode: true,
      }).then(async (willContinue) => {
        if (willContinue) {
          if (status === CONSTANTS.STATUS.ACTIVE)
            await dispatch(deactivateUser(id));
          else await dispatch(activateUser(id));
          await dispatch(fetchUsers(generateFilter(filter)));
        }
      });
    }
  };

  const onReset = async () => {
    setCurrentPage(1);
    setFilter(DEFAULT_FILTER);
    await dispatch(fetchUsers(generateFilter(DEFAULT_FILTER)));
  };

  const setValueStatus = (status: string) => {
    const newFilter = {
      ...filter,
      status,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(fetchUsers(generateFilter(newFilter)));
    setCurrentPage(1);
  };

  const _renderTable = () => {
    return (
      <Table
        dataHeader={dataHead}
        dataTable={dataTable}
        fitColumns={[1, 6, 7]}
        listColumnCenter={listColumnCenter}
        hasPagination={true}
        callbackCurrentPageLink={callbackCurrentPageLink}
        metaPagination={users.meta}
        callBackAction={callbackAction}
        listActionItem={[
          CONSTANTS.ACTION.EDIT,
          CONSTANTS.ACTION.DEACTIVATE,
          CONSTANTS.ACTION.LOG,
        ]}
        loading={usersIsLoading}
        showMore={onShowMore}
      />
    );
  };

  const addFilter = (page: number) => {
    const newFilter = {
      ...filter,
      page: page,
    };
    setFilter(newFilter);
    dispatch(fetchUsers(generateFilter(newFilter)));
    setCurrentPage(page);
  };

  const onFetchMore = () => {
    if (!usersIsLoading && currentPage < _.get(users, "meta.totalPages"))
      addFilter(filter.page + 1);
  };

  const onShowMore = () => {
    if (!usersIsLoading && currentPage < _.get(users, "meta.totalPages"))
      addFilter(filter.page + 1);
  };

  const renderMain = () => {
    return (
      <>
        {!_.isEmpty(logData) && (
          <LogsDetails onClose={() => setLogData({})} data={logData} />
        )}
        {isCreateUser && (
          <MemberDetails
            onClose={async () => {
              setIsCreateUser(false);
              setDataPopup({});
              await dispatch(fetchUsers(filter));
            }}
            data={dataPopup}
          />
        )}
        <ContainerSearch className={usersIsLoading ? "loading" : ""}>
          <FlexWrapRow>
            <Input
              value={_.get(filter, "keyword")}
              onChangeHandler={(e: any) =>
                setFilter({ ...filter, keyword: e.target.value })
              }
              onEnter={() => addFilter(1)}
              placeholder={t("placeholder.memberName")}
              {...InputStyleProps}
            />
            <Input
              value={_.get(filter, "email")}
              onChangeHandler={(e: any) =>
                setFilter({ ...filter, email: e.target.value })
              }
              onEnter={() => addFilter(1)}
              placeholder={t("placeholder.email")}
              {...InputStyleProps}
            />
            <Button onClick={() => addFilter(1)} {...ButtonStyleProps}>
              {t("button.search")}
            </Button>
            <Button onClick={() => onReset()} {...ButtonStyleProps}>
              {t("button.reset")}
            </Button>
            <Button
              onClick={() => setIsCreateUser(true)}
              background={ColorName.william}
              {...ButtonStyleProps}
            >
              + {t("button.create")}
            </Button>
          </FlexWrapRow>
          <Checkbox
            checked={_.get(filter, "status") === CONSTANTS.STATUS.INACTIVE}
            context={t("label.showInactiveMembers")}
            onChangeHandler={(e: boolean) =>
              setValueStatus(
                e ? CONSTANTS.STATUS.INACTIVE : CONSTANTS.STATUS.ACTIVE
              )
            }
            margin="10px 0 0 0"
          />
        </ContainerSearch>
        {_renderTable()}
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.ADMIN_ROUTES.MEMBERS}
      title={t("titlePage.members")}
      onScrollBottom={() => onFetchMore()}
    />
  );
};

export default Users;
