import styled from "styled-components";
import ColorName from "constants/Variables";
import { HorizontalContainer } from "../CommonLayout";

interface SectionProps {
  margin?: string;
  width?: string;
}

const ContainerFilter = styled(HorizontalContainer)`
  display: flex;
  margin: ${(props: SectionProps) => props.margin || "unset"};
  width: ${(props: SectionProps) => props.width || "unset"};
  color: ${ColorName.william};
  cursor: pointer;
  height: 40px;
  align-items: center;
  border: 1px solid transparent;
  padding: 0 10px;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0px 0px 4px rgb(62 138 182 / 25%);

  &.active {
    border: 1px solid ${ColorName.william};
  }

  &.disabled:hover {
    cursor: not-allowed;
  }

  & img {
    object-fit: contain;
    margin-right: 10px;
  }

  & span {
    font-family: Be Vietnam;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
  }
`;
export { ContainerFilter };
