import React, { useState } from "react";
import _, { values } from "lodash";
import dayjs from "dayjs";
import parse from "html-react-parser";
import ReactPlayer from "react-player";
import {
  Typography,
  Grid,
  Checkbox,
  Box,
  Button,
  Collapse,
  List,
  ListItemButton,
} from "@mui/material";

import { CONSTANTS } from "constants/Constants";

import {
  DataPostContainer,
  DataPostItem,
  PreviewContent,
  DatatPostContent,
  LogsEmptyData,
} from "./PostData.styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { MenuButton, PostMenu, PostMenuItem } from "./Post.styles";
import RenderPreviewPDF from "../../../components/Common/RenderPDF/RenderPreview";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMore from "@mui/icons-material/ExpandMore";

interface SectionProps {
  data: any;
  checked?: string[];
  onCheck?(post: string): void;
  isHidePostOptions?: boolean;
  onClick?(action: string, item?: any): void;
}

const PostData = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { data, checked, onCheck, onClick, isHidePostOptions } = props;
  const [openItems, setOpenItems] = useState<string[]>([]);

  const isDataRecordLoading = useSelector((state: RootState) => {
    return state.DataRecord.isLoading;
  });
  const [isShowPDFPost, setIsShowPDFPost] = useState<boolean>(false);
  const [dataPreview, setDataPreview] = useState();

  const generateContent = (content: string, keywords: string[]) => {
    if (_.isEmpty(keywords)) return content;
    let resolveContent = content;
    _.map(keywords, (keyword) => {
      const splitKeywords = keyword.trim().split("");
      let resolveKeyword = "";
      _.forEach(splitKeywords, (item) => {
        if (item.trim())
          resolveKeyword += `(${item.toLowerCase()}|${item.toUpperCase()})`;
        else resolveKeyword += " ";
      });
      const newContent = _.replace(
        resolveContent,
        new RegExp(resolveKeyword, "g"),
        `<mark>${keyword}</mark>`
      );
      resolveContent = newContent;
    });
    return parse(resolveContent);
  };

  const renderVideo = (item: any) => {
    let originalLink = _.get(item, "originalLink");
    if (!originalLink) return null;
    const isYoutube = _.includes(originalLink, "youtube")
    if (isYoutube) originalLink = originalLink.replace("watch?v=", "embed/");
    
    return (
      <iframe
        width="100%"
        height="480"
        src={originalLink}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    );
  };

  const previewPDF = (item: any) => {
    setDataPreview(item);
    setIsShowPDFPost(true);
  };

  const isValidURL = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const getBaseURL = (url: string) => {
    if (isValidURL(url)) {
      const baseUrl = new URL(url).origin;
      return baseUrl;
    } else return url;
  };

  const handleOpenItem = (value: string) => {
    let currentItems = [...openItems];
    if (_.includes(openItems, value))
      currentItems = _.filter(currentItems, (el) => el !== value);
    else currentItems.push(value);
    setOpenItems(currentItems);
  };

  const renderPostData = () => {
    const render = _.map(data, (item, index) => {
      const isHasContent = _.has(item, "content");
      const dataRecordId = _.get(item, "dataRecordId");
      const spreadData = {
        ...item,
        ...dataRecordId,
      };
      const resolveItem = isHasContent ? item : spreadData;
      const {
        id,
        title,
        content,
        publishedAt,
        createdAt,
        source,
        status,
        client,
        account,
        originalLink,
        file,
        metaData,
      } = resolveItem;

      const link = _.get(source, "link");
      const sourceName = _.get(source, "name");
      const mediaType = _.get(source, "mediaType");
      // const { name } = category;
      const category = _.get(resolveItem, "category");
      const categoryName = _.get(category, "name");
      const clientName = _.get(client, "clientName");
      const accountName = _.get(account, "accountName");
      const listKeywords = _.get(item, "listKeywords");
      const imagePath = _.get(file, "imagePath");
      const mediaCost = _.get(metaData, "mediaCost");
      const sentiment = _.get(metaData, "sentiment");
      const audience = _.get(metaData, "audience");
      const isOpenSub = _.includes(openItems, id);
      const timeValue = dayjs(new Date(createdAt));
      let resultCreatedAt;
      if (timeValue.isValid()) resultCreatedAt = createdAt;
      else {
        resultCreatedAt = _.split(createdAt, ",")[0];
      }

      return (
        <DataPostItem key={`post-item-${index}`}>
          <DatatPostContent
            className={`${status === CONSTANTS.STATUS.INACTIVE ? "blur" : ""}`}
          >
            <Grid
              container
              alignItems="center"
              spacing={1}
              sx={{ background: "#C0C0C0" }}
            >
              <Grid item xs={0.5}>
                <Checkbox
                  size="small"
                  onChange={() => onCheck && onCheck(item)}
                  checked={_.includes(checked, id)}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  onClick={() => previewPDF(item)}
                  sx={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    color: "#3c6379",
                    textAlign: "justify",
                  }}
                >
                  {title}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ px: 3 }}>
              <span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={mediaType === "print" ? imagePath : originalLink}
                >
                  {sourceName}
                </a>{" "}
                {t("label.createdAt")}{" "}
                {dayjs(resultCreatedAt).format("DD/MM/YYYY")}
              </span>
              <PreviewContent>
                {mediaType === "tv"
                  ? renderVideo(item)
                  : generateContent(content, listKeywords)}
              </PreviewContent>
              <Grid container display={"flex"} justifyContent={"space-between"}>
                <Grid item xs={11}>
                  {clientName && (
                    <span>
                      <b>{t("label.client")}:</b> {clientName}
                    </span>
                  )}
                  {accountName && (
                    <span>
                      <b>{t("label.package")}:</b> {accountName}
                    </span>
                  )}
                  {categoryName && (
                    <span>
                      <b>{t("label.category")}:</b>{" "}
                      {_.get(categoryName, "en")
                        ? _.get(categoryName, "en")
                        : categoryName}
                    </span>
                  )}
                  <span>
                    <b>{t("label.publishedDate")}:</b>{" "}
                    {dayjs(publishedAt).format("DD/MM/YYYY")}
                  </span>
                </Grid>
                <Grid item xs={0.5} display={"flex"} justifyContent={"end"}>
                  <Box>
                    <ListItemButton
                      key={index}
                      onClick={() => handleOpenItem(id)}
                    >
                      {isOpenSub ? (
                        <ExpandMore color="warning" />
                      ) : (
                        <ChevronRightIcon color="warning" />
                      )}
                    </ListItemButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Collapse in={isOpenSub} timeout="auto" unmountOnExit>
              <List sx={{ background: "#C0C0C0", px: 2 }}>
                {mediaCost && mediaCost !== null ? (
                  <span>
                    <b>{t("label.mediaCost")}:</b> {mediaCost}
                  </span>
                ) : (
                  <span>
                    <b>{t("label.mediaCost")}:</b> 0
                  </span>
                )}
                {audience && audience !== null ? (
                  <span>
                    <b>{t("label.audience")}:</b> {audience}
                  </span>
                ) : (
                  <span>
                    <b>{t("label.audience")}:</b> {0}
                  </span>
                )}
                {sentiment && sentiment !== null ? (
                  <span>
                    <b>sentiment:</b> {_.get(sentiment,'opinionIndex')}
                  </span>
                ) : (
                  <span>
                    <b>sentiment:</b> {0}
                  </span>
                )}
              </List>
            </Collapse>
          </DatatPostContent>
          {!isHidePostOptions && (
            <MenuButton>
              <i className="fas fa-ellipsis-v" />
              <PostMenu>
                {status === CONSTANTS.STATUS.ACTIVE && (
                  <PostMenuItem onClick={() => onClick && onClick("add", item)}>
                    {t("button.addThisPostToFolder")}
                  </PostMenuItem>
                )}
                <PostMenuItem onClick={() => onClick && onClick("view", item)}>
                  {t("button.view")}
                </PostMenuItem>
                <PostMenuItem
                  onClick={() => onClick && onClick("hidden", item)}
                >
                  Hide
                </PostMenuItem>
              </PostMenu>
            </MenuButton>
          )}
        </DataPostItem>
      );
    });
    return render;
  };

  return (
    <DataPostContainer>
      {isShowPDFPost && (
        <RenderPreviewPDF
          isDownload
          data={dataPreview}
          multipleRow
          clickClose={() => setIsShowPDFPost(false)}
        />
      )}

      {_.isEmpty(data) ? (
        <LogsEmptyData>{t("label.noDataRecordFound")}</LogsEmptyData>
      ) : (
        renderPostData()
      )}
    </DataPostContainer>
  );
};

export default PostData;
