import React, { useEffect, useState, useMemo, useRef } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import {
  Drawer,
  Box,
  Grid,
  Divider,
  Typography,
  Button,
  TextField,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  CircularProgress,
  FormControlLabel,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  Paper,
  TableRow,
  TablePagination,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { DatePicker } from "@mui/x-date-pickers";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";

import { RootState } from "reducers";
import { DashboardActions, AccountActions } from "actions";

import { CONSTANTS, ROUTES } from "constants/Constants";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import { ReportCard, ChartComponent } from "components/Common";
import ColorName from "../../../constants/Variables";

const {
  reportDashboard,
  clearDashboard,
  getDashboardStatistical,
  fetchAllSourceDashboard,
} = DashboardActions;
const { searchAccounts, clearPackages } = AccountActions;
const DEFAULT_MEDIA_TYPE = ["print", "web", "tv", "social network"];
const DEFAULT_CHARTS = [
  "coverageByMediaType",
  "latestStories",
  "storiesOverTime",
  "topSources",
  "topAuthors",
  "latestStories",
];
const CHART_OPTIONS = [
  { label: "Coverage by media type", value: "coverageByMediaType" },
  { label: "Media Cost", value: "mediaCost" },
  { label: "Media Cost By Media Type", value: "mediaCostByMediaType" },
  { label: "Media Reach", value: "mediaReach" },
  {
    label: "Media Reach By Media Type",
    value: "mediaReachByMediaType",
  },
  { label: "Stories Over time", value: "storiesOverTime" },
  { label: "Top Authors", value: "topAuthors" },
  { label: "Top Sources", value: "topSources" },
  { label: "Latest Stories", value: "latestStories" },
];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface DialogTitleProps {
  children?: React.ReactNode;
  onClose: () => void;
}
const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const Homepage: React.FC = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const elementRef = useRef<any>(null);
  const isLogged = useSelector((state: RootState) => {
    return state.Authentication.isLogged;
  });
  const dashboardIsLoading = useSelector((state: RootState) => {
    return state.Dashboard.isLoading;
  });
  const dashboardStatistical = useSelector((state: RootState) => {
    return state.Dashboard.dashboardStatistical;
  });
  const accountIsLoading = useSelector((state: RootState) => {
    return state.Account.isLoading;
  });
  const accounts = useSelector((state: RootState) => {
    return state.Account.accounts;
  });
  const sources = useSelector((state: RootState) => {
    return state.Dashboard.sourceSettingsDashboard;
  });
  const dashboardData = useSelector((state: RootState) => {
    return state.Dashboard.reportDashboard;
  });

  const [isShowOptions, setIsShowOptions] = useState<boolean>(false);
  const [checkedSources, setCheckedSources] = useState<string[]>([]);
  const [checkedAccounts, setCheckedAccounts] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState({
    dateFrom: dayjs().add(-30, "days").format("YYYY/MM/DD 00:00:00"),
    dateTo: dayjs().format("YYYY/MM/DD 23:59:59"),
  });
  const [mediaTypes, setMediaTypes] = useState<string[]>(DEFAULT_MEDIA_TYPE);
  const [topSources, setTopSources] = useState<number>(10);
  const [topAuthors, setTopAuthors] = useState<number>(10);
  const [charts, setCharts] = useState<string[]>(DEFAULT_CHARTS);
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(200);
  const [specChart, setSpecChart] = useState<string>("");
  const [filterSourcesState, setFilterSourcesState] = useState<string>("");
  const [filterAccountState, setFilterAccounntState] = useState<string>("");

  useEffect(() => {
    if (isLogged) {
      dispatch(reportDashboard());
      dispatch(fetchAllSourceDashboard());
      dispatch(
        searchAccounts({ status: CONSTANTS.STATUS.ACTIVE, limit: 1000 })
      );
    }
  }, [isLogged]);

  useEffect(() => {
    return () => {
      dispatch(clearDashboard());
      dispatch(clearPackages());
    };
  }, []);

  useEffect(() => {
    let sourceIds = [];
    // let accountIds = [];
    if (!_.isEmpty(sources)) sourceIds = _.map(sources, (source) => source.id);
    // if (!_.isEmpty(accounts?.items || []))
    //   accountIds = _.map(accounts?.items || [], (account) => account.id);
    if (!_.isEmpty(sourceIds)) {
      setCheckedSources(sourceIds);
      const filterPayload: any = {
        ...selectedDate,
        topSources,
        mediaTypes: _.join(mediaTypes, ","),
        topAuthors,
        sourceIds: _.join(sourceIds, ","),
      };
      dispatch(getDashboardStatistical(filterPayload, charts));
    }
  }, [accounts, sources]);

  // EVENTS
  const handleChangeChecked = (type: "sources" | "accounts", id: string) => {
    let currentIds =
      type === "sources" ? [...checkedSources] : [...checkedAccounts];
    if (!_.includes(currentIds, id)) currentIds.push(id);
    else currentIds = _.filter(currentIds, (cId) => cId !== id);

    if (type === "sources") setCheckedSources(currentIds);
    else setCheckedAccounts(currentIds);
  };

  const handleSelectMediaType = (type: string) => {
    let currentTypes = [...mediaTypes];
    if (!_.includes(currentTypes, type)) currentTypes.push(type);
    else currentTypes = _.filter(currentTypes, (cType) => cType !== type);
    setMediaTypes(currentTypes);
  };

  const handleSelectChart = (type: string) => {
    let currentCharts = [...charts];
    if (!_.includes(currentCharts, type)) currentCharts.push(type);
    else currentCharts = _.filter(currentCharts, (cType) => cType !== type);
    setCharts(currentCharts);
  };

  const onResetFilter = () => {
    const sourceIds = _.map(sources, (source) => source.id);
    if (!_.isEmpty(sourceIds)) setCheckedSources(sourceIds);
    setCheckedAccounts([]);
    setSelectedDate({
      dateFrom: dayjs().add(-1, "days").format("YYYY/MM/DD 00:00:00"),
      dateTo: dayjs().format("YYYY/MM/DD 23:59:59"),
    });
    setMediaTypes(DEFAULT_MEDIA_TYPE);
    setCharts(DEFAULT_CHARTS);
    setTopSources(10);
    setTopAuthors(10);
    setFilterSourcesState("");
    setFilterAccounntState("");
  };

  const _handleCheckDisableReset = () => {
    let count = 0;
    const totalSources = [...sources] || [];
    if (!_.isEqual(mediaTypes, DEFAULT_MEDIA_TYPE)) count += 1;
    if (!_.isEmpty(checkedAccounts)) count += 1;
    if (checkedSources.length !== totalSources.length) count += 1;
    if (
      !_.isEqual(selectedDate, {
        dateFrom: dayjs().add(-30, "days").format("YYYY/MM/DD 00:00:00"),
        dateTo: dayjs().format("YYYY/MM/DD 23:59:59"),
      })
    )
      count += 1;
    if (topSources !== 10) count += 1;
    if (topAuthors !== 10) count += 1;
    if (!_.isEqual(charts, DEFAULT_CHARTS)) count += 1;
    if (filterAccountState) count += 1;
    if (filterSourcesState) count += 1;

    return count;
  };

  const handleFilter = () => {
    const filterPayload: any = {
      ...selectedDate,
      topSources,
      mediaTypes: _.join(mediaTypes, ","),
      topAuthors,
    };
    if (!_.isEmpty(checkedAccounts))
      filterPayload.accountIds = _.join(checkedAccounts, ",");
    if (!_.isEmpty(checkedSources))
      filterPayload.sourceIds = _.join(checkedSources, ",");
    dispatch(getDashboardStatistical(filterPayload, charts));
    setIsShowOptions(false);
  };

  const handOnchangeChekedSources = () => {
    if (sources.length === checkedSources.length) {
      setCheckedSources([]);
    } else {
      const sourceIds = _.map(sources, (source) => source.id);
      setCheckedSources(sourceIds);
    }
  };

  const onchangeCheckedMediaType = () => {
    if (DEFAULT_MEDIA_TYPE?.length === mediaTypes?.length) {
      setMediaTypes([]);
    } else setMediaTypes(DEFAULT_MEDIA_TYPE);
  };

  const handleShowDialog = (type: string) => {
    const filterArticels: any = _.filter(dashboardStatistical?.articles || [], [
      "source.mediaType",
      _.toLower(type),
    ]);
    if (!_.isEmpty(filterArticels)) setArticles(filterArticels);
  };

  const checkShowFullChart = (value: string) => {
    return specChart === value;
  };

  const filterSources = sources.filter((item: any) =>
    item.name.toLowerCase().includes(filterSourcesState.toLowerCase())
  );

  const filterAccount = accounts?.items?.filter((item: any) =>
    item.accountName.toLowerCase().includes(filterAccountState.toLowerCase())
  );

  // RENDERS

  const _renderCoverageMediaTypes = (
    dateFrom: string,
    dateTo: string,
    grid?: number
  ) => {
    return (
      <Grid item xs={grid || 8} md={grid || 6} ref={elementRef}>
        <Card sx={{ width: 1 }}>
          <CardHeader
            title="Coverage by Media Type"
            subheader={`Period from: ${dateFrom} --- Period To: ${dateTo}`}
          />
          <CardContent>
            <Box>
              <ChartComponent.BarChart
                verticalText="Stories count"
                horizontalText="Media Types"
                data={
                  dashboardStatistical?.coverageByMediaType || { datasets: [] }
                }
                title=""
                subtitle={false}
                isShowLegend={false}
                onClick={(label) => handleShowDialog(label)}
              />
            </Box>
          </CardContent>
          <CardActions>
            <IconButton aria-label="Save As">
              <SaveIcon />
            </IconButton>
            <IconButton aria-label="Download">
              <DownloadIcon />
            </IconButton>
            <IconButton aria-label="Print">
              <PrintIcon />
            </IconButton>
          </CardActions>
        </Card>
      </Grid>
    );
  };

  const _renderMediaReach = (
    dateFrom: string,
    dateTo: string,
    grid?: number
  ) => {
    return (
      <Grid item xs={grid || 6}>
        <Card sx={{ width: 1 }}>
          <CardHeader
            title="Coverage by Media Type"
            subheader={`Period from: ${dateFrom} --- Period To: ${dateTo}`}
          />
          <CardContent>
            <Box>
              <ChartComponent.BarChart
                verticalText="Media Reach"
                horizontalText="Date"
                data={dashboardStatistical?.mediaReach || { datasets: [] }}
                title=""
                subtitle={false}
              />
            </Box>
          </CardContent>
          <CardActions>
            <IconButton aria-label="Save As">
              <SaveIcon />
            </IconButton>
            <IconButton aria-label="Download">
              <DownloadIcon />
            </IconButton>
            <IconButton aria-label="Print">
              <PrintIcon />
            </IconButton>
          </CardActions>
        </Card>
      </Grid>
    );
  };

  const _renderTopAuthors = (
    dateFrom: string,
    dateTo: string,
    grid?: number
  ) => {
    return (
      <Grid item xs={grid || 6}>
        <Card sx={{ width: 1 }}>
          <CardHeader
            title={`Top ${topAuthors} Authors`}
            subheader={`Period from: ${dateFrom} --- Period To: ${dateTo}`}
          />
          <CardContent>
            <Box>
              <ChartComponent.BarChart
                verticalText="Count"
                horizontalText="Authors"
                data={dashboardStatistical?.topAuthors || { datasets: [] }}
                title=""
                subtitle={false}
                isShowLegend={false}
              />
            </Box>
          </CardContent>
          <CardActions>
            <IconButton aria-label="Save As">
              <SaveIcon />
            </IconButton>
            <IconButton aria-label="Download">
              <DownloadIcon />
            </IconButton>
            <IconButton aria-label="Print">
              <PrintIcon />
            </IconButton>
          </CardActions>
        </Card>
      </Grid>
    );
  };

  const _renderTopSources = (
    dateFrom: string,
    dateTo: string,
    grid?: number
  ) => {
    return (
      <Grid item xs={grid || 6}>
        <Card sx={{ width: 1 }}>
          <CardHeader
            title={`Top ${topSources} Sources`}
            subheader={`Period from: ${dateFrom} --- Period To: ${dateTo}`}
          />
          <CardContent>
            <Box>
              <ChartComponent.BarChart
                verticalText="Count"
                horizontalText="Sources"
                data={dashboardStatistical?.topSources || { datasets: [] }}
                title=""
                subtitle={false}
                isShowLegend={false}
              />
            </Box>
          </CardContent>
          <CardActions>
            <IconButton aria-label="Save As">
              <SaveIcon />
            </IconButton>
            <IconButton aria-label="Download">
              <DownloadIcon />
            </IconButton>
            <IconButton aria-label="Print">
              <PrintIcon />
            </IconButton>
          </CardActions>
        </Card>
      </Grid>
    );
  };

  const _renderStoriesOverTime = (
    dateFrom: string,
    dateTo: string,
    grid?: number
  ) => {
    return (
      <Grid item xs={grid || 6}>
        <Card sx={{ width: 1 }}>
          <CardHeader
            title="Stories Over Time"
            subheader={`Period from: ${dateFrom} --- Period To: ${dateTo}`}
          />
          <CardContent>
            <Box>
              <ChartComponent.BarChart
                verticalText="Stories Count"
                horizontalText="Date"
                data={dashboardStatistical?.storiesOverTime || { datasets: [] }}
                title=""
                subtitle={false}
                isShowLegend={false}
              />
            </Box>
          </CardContent>
          <CardActions>
            <IconButton aria-label="Save As">
              <SaveIcon />
            </IconButton>
            <IconButton aria-label="Download">
              <DownloadIcon />
            </IconButton>
            <IconButton aria-label="Print">
              <PrintIcon />
            </IconButton>
          </CardActions>
        </Card>
      </Grid>
    );
  };

  const _renderLatestStories = (dateTo: string, grid?: number) => {
    return (
      <Grid
        item
        xs={grid || 6}
        sx={{ height: elementRef?.current?.offsetHeight }}
      >
        <Card
          sx={{
            width: 1,
            overflowY: "auto",
            height: 1,
          }}
        >
          <CardHeader title="Latest Stories" />
          <CardContent sx={{ py: 0 }}>
            <List dense sx={{ width: 1, pt: 0 }}>
              {!dashboardStatistical?.latestStories && (
                <Box>
                  <ListItem disablePadding>
                    <ListItemText primary={`No Stories Found In ${dateTo}`} />
                  </ListItem>
                  <Divider sx={{ width: 1 }} />
                </Box>
              )}
              {dashboardStatistical?.latestStories &&
                _.map(dashboardStatistical.latestStories, (story) => (
                  <Box key={_.get(story, "id")}>
                    <ListItem disablePadding>
                      <ListItemText
                        primary={_.get(story, "title")}
                        secondary={`${_.get(story, "source.name")} - ${dayjs(
                          _.get(story, "createdAt"), 'YYYY-MM-DD'
                        ).format("DD-MM-YYYY")}`}
                      />
                    </ListItem>
                    <Divider sx={{ width: 1 }} />
                  </Box>
                ))}
            </List>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const _renderOptionMediaTypes = useMemo(
    () => (
      <Grid item xs={2}>
        <Grid container direction="column">
          <Grid item>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Grid item>
                <Typography
                  sx={{ color: ColorName.burntSienna, fontWeight: "bold" }}
                >
                  Media Types
                </Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox checked={mediaTypes.length === 4} size="small" />
                  }
                  label="All"
                  onChange={() => onchangeCheckedMediaType()}
                />
              </Grid>
            </Grid>
            <Divider sx={{ width: 1 }} />
          </Grid>
          <Grid item>
            <List dense sx={{ width: 1 }}>
              <ListItem
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={() => handleSelectMediaType("web")}
                    checked={_.includes(mediaTypes, "web")}
                  />
                }
                disablePadding
              >
                <ListItemButton>
                  <ListItemText primary="Web" />
                </ListItemButton>
              </ListItem>
              <Divider sx={{ width: 1 }} />
              <ListItem
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={() => handleSelectMediaType("print")}
                    checked={_.includes(mediaTypes, "print")}
                  />
                }
                disablePadding
              >
                <ListItemButton>
                  <ListItemText primary="Print" />
                </ListItemButton>
              </ListItem>
              <Divider sx={{ width: 1 }} />
              <ListItem
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={() => handleSelectMediaType("tv")}
                    checked={_.includes(mediaTypes, "tv")}
                  />
                }
                disablePadding
              >
                <ListItemButton>
                  <ListItemText primary="TV" />
                </ListItemButton>
              </ListItem>
              <Divider sx={{ width: 1 }} />
              <ListItem
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={() => handleSelectMediaType("social network")}
                    checked={_.includes(mediaTypes, "social network")}
                  />
                }
                disablePadding
              >
                <ListItemButton>
                  <ListItemText primary="Social Network" />
                </ListItemButton>
              </ListItem>
              <Divider sx={{ width: 1 }} />
            </List>
          </Grid>
        </Grid>
      </Grid>
    ),
    [mediaTypes]
  );

  const _renderOptionSources = useMemo(
    () => (
      <Grid item xs={4}>
        <Grid container direction="column">
          <Grid item>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Grid item xs={2}>
                <Typography
                  sx={{ color: ColorName.burntSienna, fontWeight: "bold" }}
                >
                  Sources
                </Typography>
              </Grid>
              <Grid item xs={6} display={"flex"}>
                <TextField
                  label="Search"
                  size="small"
                  value={filterSourcesState}
                  onChange={(e) =>
                    setFilterSourcesState(_.trim(e.target.value))
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedSources.length === sources.length}
                      size="small"
                    />
                  }
                  label="All"
                  onChange={() => {
                    handOnchangeChekedSources();
                  }}
                />
              </Grid>
            </Grid>
            <Divider sx={{ width: 1 }} />
          </Grid>
          {dashboardIsLoading && (
            <Grid item sx={{ textAlign: "center" }}>
              <CircularProgress />
            </Grid>
          )}
          {!dashboardIsLoading && (
            <Grid item>
              <List dense sx={{ width: 1, maxHeight: 400, overflow: "auto" }}>
                {_.map(filterSources, (source) => {
                  const sourceId = _.get(source, "id");
                  return (
                    <Box key={sourceId}>
                      <ListItem
                        key={sourceId}
                        secondaryAction={
                          <Checkbox
                            edge="end"
                            onChange={() =>
                              handleChangeChecked("sources", sourceId)
                            }
                            checked={_.includes(checkedSources, sourceId)}
                          />
                        }
                        disablePadding
                      >
                        <ListItemButton>
                          <ListItemText primary={_.get(source, "name")} />
                        </ListItemButton>
                      </ListItem>
                      <Divider sx={{ width: 1 }} />
                    </Box>
                  );
                })}
              </List>
            </Grid>
          )}
        </Grid>
      </Grid>
    ),
    [dashboardIsLoading, sources, checkedSources, filterSources]
  );

  const _renderOptionAccounts = useMemo(
    () => (
      <Grid item xs={4}>
        <Grid container direction="column">
          <Grid item>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              height={38}
              mb={1}
            >
              <Grid item xs={4}>
                <Typography
                  sx={{ color: ColorName.burntSienna, fontWeight: "bold" }}
                >
                  Accounts
                </Typography>
              </Grid>
              <Grid item xs={8} display={"flex"} justifyContent={"end"}>
                <TextField
                  sx={{ mr: "32px" }}
                  label="Search"
                  size="small"
                  value={filterAccountState}
                  onChange={(e) =>
                    setFilterAccounntState(_.trim(e.target.value))
                  }
                />
              </Grid>
            </Grid>
            <Divider sx={{ width: 1 }} />
          </Grid>
          {accountIsLoading && (
            <Grid item sx={{ textAlign: "center" }}>
              <CircularProgress />
            </Grid>
          )}
          {!accountIsLoading && (
            <Grid item>
              <List dense sx={{ width: 1, maxHeight: 400, overflow: "auto" }}>
                {_.map(filterAccount || [], (account) => {
                  const accountId = _.get(account, "id");
                  return (
                    <Box key={accountId}>
                      <ListItem
                        key={accountId}
                        secondaryAction={
                          <Checkbox
                            edge="end"
                            onChange={() =>
                              handleChangeChecked("accounts", accountId)
                            }
                            checked={_.includes(checkedAccounts, accountId)}
                          />
                        }
                        disablePadding
                      >
                        <ListItemButton>
                          <ListItemText
                            primary={_.get(account, "accountName")}
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider sx={{ width: 1 }} />
                    </Box>
                  );
                })}
              </List>
            </Grid>
          )}
        </Grid>
      </Grid>
    ),
    [accountIsLoading, accounts, checkedAccounts, filterAccount]
  );

  const _renderDateOptions = useMemo(
    () => (
      <Grid item xs={2}>
        <Grid container direction="column">
          <Grid item>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              height={38}
              mb={1}
            >
              <Grid item>
                <Typography
                  sx={{ color: ColorName.burntSienna, fontWeight: "bold" }}
                >
                  Date
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ width: 1 }} />
          </Grid>
          <Grid item pt={2}>
            <DatePicker
              label="Date From"
              value={dayjs(selectedDate.dateFrom)}
              disabled={dashboardIsLoading}
              format="DD/MM/YYYY"
              onChange={(e: Dayjs | null) =>
                setSelectedDate({
                  ...selectedDate,
                  dateFrom: dayjs(e).format("MM/DD/YYYY 00:00:00"),
                })
              }
              sx={{ width: 1 }}
            />
          </Grid>
          <Grid item pt={2}>
            <DatePicker
              label="Date To"
              value={dayjs(selectedDate.dateTo)}
              format="DD/MM/YYYY"
              disabled={dashboardIsLoading}
              onChange={(e: Dayjs | null) =>
                setSelectedDate({
                  ...selectedDate,
                  dateTo: dayjs(e).format("MM/DD/YYYY 23:59:59"),
                })
              }
              sx={{ width: 1 }}
            />
          </Grid>
          <Grid item pt={2}>
            <TextField
              key="kSource"
              type="number"
              label="Top Sources"
              fullWidth
              value={topSources}
              onChange={(e) => setTopSources(parseInt(e.target.value))}
            />
          </Grid>
          <Grid item pt={2}>
            <TextField
              key="kAuthor"
              type="number"
              label="Top Authors"
              fullWidth
              value={topAuthors}
              onChange={(e) => setTopAuthors(parseInt(e.target.value))}
            />
          </Grid>
        </Grid>
      </Grid>
    ),
    [selectedDate, topSources, topAuthors]
  );

  const _renderChartOptions = useMemo(
    () => (
      <Grid container>
        {_.map(CHART_OPTIONS, (item) => (
          <FormControlLabel
            key={_.get(item, "value")}
            control={
              <Checkbox checked={_.includes(charts, _.get(item, "value"))} />
            }
            label={_.get(item, "label")}
            onChange={() => handleSelectChart(_.get(item, "value"))}
          />
        ))}
      </Grid>
    ),
    [charts, CHART_OPTIONS]
  );

  const _renderOptions = () => {
    return (
      <Drawer
        anchor="top"
        open={isShowOptions}
        onClose={() => setIsShowOptions(false)}
      >
        <Grid container direction="column" p={2}>
          <Grid item>
            <Grid container spacing={1}>
              {_renderDateOptions}
              {_renderOptionAccounts}
              {_renderOptionSources}
              {_renderOptionMediaTypes}
            </Grid>
          </Grid>
          <Divider sx={{ my: 1 }} />
          <Grid item>{_renderChartOptions}</Grid>
          <Divider sx={{ my: 1 }} />
          <Grid item>
            <Button
              variant="contained"
              color="success"
              startIcon={<FilterAltIcon />}
              onClick={() => handleFilter()}
            >
              Filter
            </Button>
            <Button
              variant="contained"
              color="info"
              sx={{ mx: 2 }}
              startIcon={<RotateLeftIcon />}
              disabled={!_handleCheckDisableReset()}
              onClick={() => onResetFilter()}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="error"
              startIcon={<CloseIcon />}
              onClick={() => setIsShowOptions(false)}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    );
  };

  const _renderDialog = () => (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={!_.isEmpty(articles)}
      maxWidth="lg"
    >
      <BootstrapDialogTitle
        onClose={() => {
          setArticles([]);
          setPage(0);
        }}
      >
        Stories With Details
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <TableContainer component={Paper} sx={{ maxHeight: 400, width: 1 }}>
          <Table stickyHeader sx={{ minWidth: 1 }}>
            <TableHead>
              <TableRow>
                <TableCell align="center" width={100}>
                  No
                </TableCell>
                <TableCell align="left">Headline</TableCell>
                <TableCell align="center" width={150}>
                  Media Type
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.isEmpty(articles) && (
                <TableRow>
                  <TableCell colSpan={3}>No Articel(s) found</TableCell>
                </TableRow>
              )}
              {!_.isEmpty(articles) &&
                _.map(
                  _.slice(articles, page * limit, page * limit + limit),
                  (article, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {page * limit + index + 1}
                        </TableCell>
                        <TableCell align="left">
                          {_.get(article, "title")}
                        </TableCell>
                        <TableCell align="center">
                          {_.get(article, "source.mediaType")}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={articles.length}
          rowsPerPage={limit}
          page={page}
          onPageChange={(_e, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setLimit(parseInt(e.target.value));
            setPage(0);
          }}
        />
      </DialogContent>
    </BootstrapDialog>
  );

  const _renderSummary = useMemo(
    () => (
      <Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Typography
              sx={{ color: ColorName.burntSienna, fontWeight: "bold" }}
            >
              Summary:
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <ReportCard
              text={t("label.clients")}
              number={_.get(dashboardData, "client")}
              loading={dashboardIsLoading}
            />
          </Grid>
          <Grid item xs={3}>
            <ReportCard
              text={"Articles"}
              number={_.get(dashboardData, "dataRecord")}
              loading={dashboardIsLoading}
            />
          </Grid>
          <Grid item xs={3}>
            <ReportCard
              text={t("label.sources")}
              number={_.get(dashboardData, "source")}
              loading={dashboardIsLoading}
            />
          </Grid>
        </Grid>
      </Box>
    ),
    [dashboardData, dashboardIsLoading]
  );

  const _renderDropDownAndFilter = () => (
    <Box>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        mt={2}
      >
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="select-label">View Expanded Chart</InputLabel>
            <Select
              labelId="select-label"
              value={specChart}
              label="View Expanded Chart"
              onChange={(e) => setSpecChart(e.target.value)}
            >
              <MenuItem value={""}>No Select</MenuItem>
              {_.map(CHART_OPTIONS, (option) => {
                if (_.includes(charts, option.value))
                  return (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1} sx={{ textAlign: "right" }}>
          <IconButton onClick={() => setIsShowOptions(true)}>
            <FilterAltIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );

  const renderMain = () => {
    const dateFrom = dayjs(selectedDate.dateFrom).format("DD/MM/YYYY");
    const dateTo = dayjs(selectedDate.dateTo).format("DD/MM/YYYY");
    return (
      <Box px={3}>
        {_renderDialog()}
        {_renderOptions()}
        {_renderSummary}
        {_renderDropDownAndFilter()}
        <Grid container spacing={2} mt={2}>
          {_.includes(charts, "coverageByMediaType") &&
            !specChart &&
            _renderCoverageMediaTypes(dateFrom, dateTo)}
          {_.includes(charts, "latestStories") &&
            !specChart &&
            _renderLatestStories(dateTo)}
          {_.includes(charts, "mediaReach") &&
            !specChart &&
            _renderMediaReach(dateFrom, dateTo)}
          {_.includes(charts, "topAuthors") &&
            !specChart &&
            _renderTopAuthors(dateFrom, dateTo)}
          {_.includes(charts, "topSources") &&
            !specChart &&
            _renderTopSources(dateFrom, dateTo)}
          {_.includes(charts, "storiesOverTime") &&
            !specChart &&
            _renderStoriesOverTime(dateFrom, dateTo)}
          {checkShowFullChart("coverageByMediaType") &&
            _renderCoverageMediaTypes(dateFrom, dateTo, 12)}
          {checkShowFullChart("latestStories") &&
            _renderLatestStories(dateTo, 12)}
          {checkShowFullChart("mediaReach") &&
            _renderMediaReach(dateFrom, dateTo, 12)}
          {checkShowFullChart("topAuthors") &&
            _renderTopAuthors(dateFrom, dateTo, 12)}
          {checkShowFullChart("topSources") &&
            _renderTopSources(dateFrom, dateTo, 12)}
          {checkShowFullChart("storiesOverTime") &&
            _renderStoriesOverTime(dateFrom, dateTo, 12)}
        </Grid>
      </Box>
    );
  };

  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={ROUTES.HOMEPAGE}
      title={t("titlePage.dashboard")}
    />
  );
};

export default Homepage;
