const ColorName = {
	zircon: "#F8FAFF",
	white: "#fff",
	william: "#3C6379",
	spindle: "#CADEED",
	regentGray: "#8F9CA6",
	linkWater: "#ECF2FA",
	burntSienna: "#E87844",
	piper: "#CA5924",
	nepal: "#8EAEC0",
	amaranth: "#E92542",
};

export default ColorName;
