import ActionTypes from "constants/ActionTypes";

const initialState = {
  isLogged: false,
  hasError: false,
  isLoading: false,
  loginResult: 0,
  name: "",
  username: "",
  password: "",
  isRefreshToken: true,
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_DEFAULT_LOGIN_RESULT:
      return {
        ...state,
        loginResult: 0,
      };
    case ActionTypes.IS_LOGGED:
      return {
        ...state,
        isLogged: true,
        hasError: false,
        isLoading: false,
        loginResult: 2,
      };
    case ActionTypes.LOGIN_HAS_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        loginResult: 1,
      };
    case ActionTypes.LOGIN_IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.LOGIN:
      return {
        ...state,
        isLogged: false,
        name: payload.name,
        username: payload.username,
        password: payload.password,
        loginResult: 2,
      };
    case ActionTypes.LOGOUT:
      return {
        ...state,
        isLogged: false,
        name: "",
        username: "",
        password: "",
      };

    case ActionTypes.REFRESH_TOKEN_IS_LOADING:
      return {
        ...state,
        isRefreshToken: false,
      };

    case ActionTypes.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        isRefreshToken: false,
      };

    case ActionTypes.REFRESH_TOKEN_HAS_ERROR:
      return {
        ...state,
        isRefreshToken: true,
      };
    default:
      return state;
  }
};
