/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "reducers";
import { DataRecordsActions, FoldersActions } from "actions";

import { popupAlert } from "libs/Utils";

import { Button, Pagination } from "components/Common";
import {
  DataPostContainer,
  DataPostItem,
  PreviewContent,
  MenuButton,
  PostMenu,
  PostMenuItem,
  DatatPostContent,
  TemplateDetailsContainer,
  TemplateDetailsContent,
  TemplateDetailsHeader,
  TemplateDetailsForm,
  ButtonField,
  TotalPostRow,
  LoadingLayer,
  Spinner,
} from "../Popup.styles";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

const { deletePostAddedFromFolder } = FoldersActions;
const { clearDataRecord, getDataRecordForFolderById } = DataRecordsActions;
interface SectionProps {
  folderId: string;
  isHidePostOptions?: boolean;
  onClose(): void;
}

const PostData = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();

  const { folderId, isHidePostOptions, onClose } = props;
  const dataRecords = useSelector((state: RootState) => {
    return state.DataRecord.dataRecord;
  });
  const dataRecordsIsLoading = useSelector((state: RootState) => {
    return state.DataRecord.isLoading;
  });

  useEffect(() => {
    dispatch(
      getDataRecordForFolderById({ folderId: [folderId], page: 1, limit: 10 })
    );

    return () => {
      dispatch(clearDataRecord());
    };
  }, []);

  const handleClose = (e: any) => {
    const className = e.target.classList;
    if (className.contains("blur_layer") && onClose) onClose();
  };

  const onDelete = async (dataRecordId: string) => {
    if (!dataRecordId) return;
    const isAgree = await popupAlert({
      type: "warning",
      title: t("alert.warning"),
      text: t("alert.warningBeforeRemovePostFromFolder"),
      buttons: true,
      dangerMode: true,
    });
    if (isAgree) {
      await dispatch(deletePostAddedFromFolder({ folderId, dataRecordId }));
      await dispatch(getDataRecordForFolderById({ folderId: [folderId] }));
    }
  };

  const renderTableArticleQty = () => {
    const meta = _.get(dataRecords, "meta");
    const totalItems = _.get(meta, "totalItems");
    return (
      <TotalPostRow>
        <span>
          {t("label.totalPosts")}: <b>{totalItems}</b>
        </span>
      </TotalPostRow>
    );
  };

  const callbackCurrentPage = (e: number) => {
    dispatch(
      getDataRecordForFolderById({ page: e, limit: 10, folderId: [folderId] })
    );
  };

  const renderOriginalLink = (link: string) => {
    if (link.length < 50) return link;
    const chunkLink = _.chunk(link, 25);
    const resolveLink = `${_.join(chunkLink[0], "")}...${
      chunkLink.length > 2 ? _.join(chunkLink[chunkLink.length - 1], "") : ""
    }`;
    return resolveLink;
  };

  const renderPost = () => {
    const data = _.get(dataRecords, "items");
    if (!_.isEmpty(data))
      return _.map(data, (item, index) => {
        const dataRecordId = _.get(item, "dataRecordId");
        const id = _.get(dataRecordId, "id");
        const content = _.get(dataRecordId, "content");
        const originalLink = _.get(dataRecordId, "originalLink");
        const publishedAt = _.get(dataRecordId, "publishedAt");
        const author = _.get(dataRecordId, "author");
        const createdAt = _.get(dataRecordId, "createdAt");
        const title = _.get(dataRecordId, "title");
        const source = _.get(dataRecordId, "source");
        const link = _.get(source, "link");

        const timeValue = dayjs(new Date(createdAt));
        let resultCreatedAt;
        if (timeValue.isValid()) resultCreatedAt = createdAt;
        else {
          if (_.includes(createdAt, "a.m."))
            resultCreatedAt = _.replace(createdAt, /a.m./g, "am");
          if (_.includes(createdAt, "p.m."))
            resultCreatedAt = _.replace(createdAt, /p.m./g, "pm");
        }

        return (
          <DataPostItem key={`post-item-${index}`}>
            <DatatPostContent>
              <div>
                <span>{title}</span>
                <span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={originalLink ? originalLink : link}
                  >
                    {originalLink ? renderOriginalLink(originalLink) : link}
                  </a>{" "}
                  {t("label.createdAt")}{" "}
                  {dayjs(resultCreatedAt).format("MM-DD-YYYY, HH:mm:ss")}
                </span>
              </div>

              <PreviewContent>
                {content ? parse(content) : t("label.dataRecordNotFound")}
              </PreviewContent>
              <span>
                {author && (
                  <span>
                    <b> {t("label.author")}:</b> {author}
                  </span>
                )}
                <span>
                  <b> {t("label.publishedDate")}:</b>{" "}
                  {dayjs(publishedAt).format("MM-DD-YYYY, HH:mm:ss")}
                </span>
              </span>
            </DatatPostContent>
            {!isHidePostOptions && (
              <MenuButton>
                <i className="fas fa-ellipsis-v" />
                <PostMenu>
                  <PostMenuItem onClick={() => onDelete(id)}>
                    {t("button.remove")}
                  </PostMenuItem>
                </PostMenu>
              </MenuButton>
            )}
          </DataPostItem>
        );
      });
    // return (
    //   <div>
    //     {isLoading
    //       ? "Loading..."
    //       : _.isEmpty(dataRecords)
    //       ? t("label.noPostsFound")
    //       : null}
    //   </div>
    // );
  };
  return (
    <TemplateDetailsContainer
      className="blur_layer"
      onClick={(e: any) => handleClose(e)}
    >
      <TemplateDetailsContent
        width={_.isEmpty(_.get(dataRecords, "items")) ? "30vw" : "90vw"}
        height="90vh"
      >
        {dataRecordsIsLoading && (
          <LoadingLayer>
            <Spinner />
          </LoadingLayer>
        )}
        <TemplateDetailsHeader>
          {t("headerPopup.postAddedToFolder")}
        </TemplateDetailsHeader>
        {renderTableArticleQty()}
        <TemplateDetailsForm height="70vh">
          <DataPostContainer>{renderPost()}</DataPostContainer>
          {!_.isEmpty(_.get(dataRecords, "items")) && (
            <Pagination
              maxPage={_.get(dataRecords, "meta.totalPages")}
              currentPage={_.get(dataRecords, "meta.currentPage")}
              numberPageDisplay={2}
              totalCount={_.get(dataRecords, "meta.totalItems")}
              clickLinkPagination={(e: any) => callbackCurrentPage(e)}
              clickLinkNext={(e: any) => callbackCurrentPage(e)}
              clickLinkPrevious={(e: any) => callbackCurrentPage(e)}
              clickLinkFirst={() => callbackCurrentPage(1)}
              clickLinkLast={() =>
                callbackCurrentPage(_.get(dataRecords, "meta.totalPages"))
              }
            />
          )}
        </TemplateDetailsForm>
        <Box sx={{ position: "absolute", bottom: 0, width: 1 }}>
          <ButtonField>
            <Button
              onClick={() => onClose()}
              type="submit"
              buttonWidth="100px"
              buttonMargin="0 0 0 10px"
            >
              {t("button.close")}
            </Button>
          </ButtonField>
        </Box>
      </TemplateDetailsContent>
    </TemplateDetailsContainer>
  );
};

export default PostData;
