import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { RootState } from "reducers";
import { ClientActions, RoleActions, UsersActions } from "actions";

import countries from "libs/countries";
import history from "../../../history";
import { ROUTES } from "constants/Constants";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Input,
  Button,
  SelectDatetime,
  Dropdown,
  Textarea,
  LoadingLayer,
  Spinner,
  FormDetails,
  FormGroup,
  FormInline,
  FormLabel,
  ButtonField,
  TitlePartOfForm,
  InputFile,
  ImagePreview,
} from "components/Common";
import { useTranslation } from "react-i18next";
import RouteTypes from "constants/RouteTypes";
import { popupAlert } from "libs/Utils";
import { UserDetails } from "components/Common/Popup";
import { useLocation } from "react-router-dom";
import { Images } from "themes";

const { fetchRoles } = RoleActions;
const { registerClient } = ClientActions;
const { searchUsers } = UsersActions;

const CreateClient = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state: RootState) => {
    return state.Clients.isLoading;
  });

  const [clientData, setClientData] = useState({
    clientNumber: "",
    clientName: "",
    billingPeriod: "",
    billViaEmail: false,
    billViaPost: false,
    accountManager: "",
    samePrimaryContact: false,
    sameSecondaryContact: false,
    startDate: "",
    endDate: "",
    description: "",
  });
  const [userData, setUserData] = useState({
    address: "",
    city: "",
    country: "",
    zipCode: "",
    phoneNumber: "",
    region: "",
  });
  const [userContract, setUserContract] = useState({
    accountManager: "",
    startDate: "",
    endDate: "",
  });

  const [urlPreview, setUrlPreview] = useState("");

  const [clientLogo, setClientLogo] = useState();
  const [filename, setFilename] = useState<string>("");
  const [isDisplayPopup, setIsDisplayPopup] = useState(false);

  useEffect(() => {
    dispatch(fetchRoles());
    dispatch(searchUsers({ limit: 100 }));
  }, [dispatch]);

  const processData = () => {
    const formData = new FormData();
    // formData.append("clientNumber", _.trim(clientData.clientNumber));
    formData.append("clientName", _.trim(clientData.clientName));
    formData.append("userData[address]", _.trim(userData.address));
    formData.append("userData[phoneNumber]", _.trim(userData.phoneNumber));
    formData.append("userData[country]", _.trim(userData.country));
    formData.append("userData[city]", _.trim(userData.city));
    if (userData.zipCode)
      formData.append("userData[zipCode]", _.trim(userData.zipCode));
    if (userData.region)
      formData.append("userData[regionArea]", _.trim(userData.region));
    formData.append("accountManager", _.trim(userContract.accountManager));
    formData.append("startDate", _.trim(userContract.startDate));
    formData.append("endDate", _.trim(userContract.endDate));
    formData.append("description", _.trim(clientData.description));
    if (clientLogo) formData.append("userData[avatar]", clientLogo || "");
    return formData;
  };

  const validateData = () => {
    let flag = true;
    let message = `${t("alert.foundEmptyFields")}`;
    const data = [
      {
        label: t("label.clientName"),
        value: _.get(clientData, "clientName"),
      },
      // {
      //   label: t("label.clientNumber"),
      //   value: _.get(clientData, "clientNumber"),
      // },
      {
        label: t("label.startDate"),
        value: _.get(userContract, "startDate"),
      },
      {
        label: t("label.endDate"),
        value: _.get(userContract, "endDate"),
      },
      // {
      //   label: t("label.primaryContact"),
      //   value: _.get(billing, "primaryContact"),
      // },
      {
        label: t("label.address"),
        value: _.get(userData, "address"),
      },
      {
        label: t("label.city"),
        value: _.get(userData, "city"),
      },
      {
        label: t("label.country"),
        value: _.get(userData, "country"),
      },
      {
        label: t("label.phoneNumber"),
        value: _.get(userData, "phoneNumber"),
      },
      {
        label: t("label.accountManager"),
        value: _.get(userContract, "accountManager"),
      },
      {
        label: t("label.description"),
        value: _.get(clientData, "description"),
      },
    ];
    _.map(data, (item) => {
      const { value, label } = item;
      if (_.isEmpty(_.trim(value))) {
        message += `${label}\n`;
        flag = false;
      }
    });
    return { flag, message };
  };

  const createClient = async () => {
    const { flag, message } = validateData();

    if (!flag)
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });
    else {
      const filter = _.get(location, "state.filter");
      const data = processData();
      await dispatch(registerClient(data, filter));
    }
  };

  const setLogo = (e: any) => {
    if (e) {
      const url = URL.createObjectURL(e.target.files[0]);
      setClientLogo(e.target.files[0]);
      setUrlPreview(url);
      setFilename(_.get(e.target.files[0], "name"));
    }
  };

  const onBack = () => {
    const filter = _.get(location, "state.filter");
    history.push({
      pathname: ROUTES.CLIENTS,
      state: { filter, from: RouteTypes.ADMIN_ROUTES.CREATE_CLIENT },
    });
  };

  const renderMain = () => {
    return (
      <>
        {isDisplayPopup && (
          <UserDetails data={{}} onClose={() => setIsDisplayPopup(false)} />
        )}
        <FormDetails>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.clientName")}
              </FormLabel>
              <Input
                value={clientData.clientName}
                onChangeHandler={(e) =>
                  setClientData({ ...clientData, clientName: e.target.value })
                }
                placeholder={t("placeholder.clientName")}
                width="300px"
              />
            </FormGroup>
            {/* <FormGroup>
              <FormLabel aria-label="require">{t("label.clientNumber")}</FormLabel>
              <Input
                value={clientData.clientNumber}
                margin="10px 20px 10px 0"
                onChangeHandler={(e) =>
                  setClientData({ ...clientData, clientNumber: e.target.value })
                }
                placeholder={t("placeholder.clientNumber")}
                width="300px"
                isNum
              />
            </FormGroup> */}
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.startDate")}</FormLabel>
              <SelectDatetime
                callbackPayload={(e) =>
                  setUserContract({ ...userContract, startDate: e })
                }
                defaultValue={_.get(userContract, "startDate")}
                placeholder={t("placeholder.startDate")}
                maxDate={userContract?.endDate}
                width="300px"
                disabledPreviousDate
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.endDate")}</FormLabel>
              <SelectDatetime
                callbackPayload={(e) =>
                  setUserContract({ ...userContract, endDate: e })
                }
                defaultValue={_.get(userContract, "endDate")}
                minDate={userContract?.startDate}
                placeholder={t("placeholder.endDate")}
                width="300px"
                disabledPreviousDate
              />
            </FormGroup>
          </FormInline>
          <TitlePartOfForm>{t("label.companyAddress")}</TitlePartOfForm>

          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.streetAddress")}
              </FormLabel>
              <Input
                value={userData.address}
                onChangeHandler={(e) =>
                  setUserData({ ...userData, address: e.target.value })
                }
                placeholder={t("placeholder.streetAddress")}
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.city")}</FormLabel>
              <Input
                value={userData.city}
                onChangeHandler={(e) =>
                  setUserData({ ...userData, city: e.target.value })
                }
                placeholder={t("placeholder.city")}
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.country")}</FormLabel>
              <Dropdown
                value={userData.country}
                width="300px"
                callbackPayload={(value) =>
                  setUserData({ ...userData, country: value })
                }
                options={countries}
                placeholder={t("placeholder.country")}
                search={true}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.postalOrZipCode")}</FormLabel>
              <Input
                value={userData.zipCode}
                onChangeHandler={(e) =>
                  setUserData({ ...userData, zipCode: e.target.value })
                }
                placeholder={t("placeholder.postalOrZipCode")}
                width="300px"
                isNum
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.contactNumber")}
              </FormLabel>
              <Input
                value={userData.phoneNumber}
                onChangeHandler={(e) =>
                  setUserData({ ...userData, phoneNumber: e.target.value })
                }
                placeholder={t("placeholder.contactNumber")}
                width="300px"
                isNum
              />
            </FormGroup>
          </FormInline>

          <TitlePartOfForm>{t("label.clientInformation")}</TitlePartOfForm>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.accountManager")}
              </FormLabel>
              <Input
                value={userContract.accountManager}
                onChangeHandler={(e) =>
                  setUserContract({
                    ...userContract,
                    accountManager: e.target.value,
                  })
                }
                placeholder={t("placeholder.accountManager")}
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormGroup>
            <FormLabel>
              {t("label.uploadAvatar")}{" "}
              <span className="description">({t("label.demensionImage")})</span>
            </FormLabel>
            <ImagePreview
              src={urlPreview || Images.defaultPhoto}
              alt={t("placeholder.avatar")}
            />
            <InputFile
              onChangeHandler={(e) => setLogo(e)}
              margin="10px 10px 10px 0"
              filename={filename}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel aria-label="require">{t("label.description")}</FormLabel>
            <Textarea
              value={_.get(clientData, "description")}
              onChangeHandler={(e: any) =>
                setClientData({
                  ...clientData,
                  description: e.target.value,
                })
              }
              margin="10px 20px 10px 0"
              placeholder={t("placeholder.clientDescription")}
              width="100%"
              rows={5}
            />
          </FormGroup>
          <ButtonField margin="10px 0 50px 0">
            <Button
              onClick={() => createClient()}
              buttonWidth="100px"
              buttonMargin="0 10px 0 0"
            >
              {t("button.create")}
              {isLoading && (
                <LoadingLayer>
                  <Spinner />
                </LoadingLayer>
              )}
            </Button>
            <Button onClick={() => onBack()} buttonWidth="100px">
              {t("button.back")}
            </Button>
          </ButtonField>
        </FormDetails>
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      parent={RouteTypes.ADMIN_ROUTES.GROUPS}
      currentPage={RouteTypes.ADMIN_ROUTES.CLIENTS}
      title={t("titlePage.clients")}
      subTitle={t("titlePage.createNewClient")}
    />
  );
};

export default CreateClient;
