import Api from "./Base";
import { API } from "constants/Constants";

const { FILTER } = API;

export function addFilter(data: any) {
  return Api.post(FILTER.BASIC, data);
}

export function fetchFilters() {
  return Api.get(FILTER.BASIC);
}

export function getFilterByID(id: any) {
  return Api.get(`${FILTER.BASIC}/${id}`);
}

export function searchFilters(data: any) {
  return Api.get(FILTER.BASIC, data);
}

export function updateFilter(data: any, id: any) {
  return Api.put(`${FILTER.UPDATE}/${id}`, data);
}

export function deactivateFilter(id: any) {
  return Api.put(`${FILTER.DEACTIVATE}/${id}`);
}

export function activateFilter(id: any) {
  return Api.put(`${FILTER.ACTIVATE}/${id}`);
}

export function exportFilter() {
  return Api.getBlob(FILTER.EXPORT);
}
