import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _, { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";

import { RootState } from "reducers";
import {
  ClientActions,
  RoleActions,
  UsersActions,
  AccountActions,
} from "actions";

import history from "../../../history";
import { CONSTANTS, ROUTES } from "constants/Constants";
import { popupAlert, processDataDropDownUser } from "libs/Utils";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import { AnotherContactField } from "./Clients.styles";
import {
  Button,
  Dropdown,
  // Checkbox,
  LoadingLayer,
  Spinner,
  FormDetails,
  FormGroup,
  FormInline,
  FormLabel,
  TitlePartOfForm,
  ButtonField,
} from "components/Common";
import { PackageDetails, UserForClient } from "components/Common/Popup";
import { useTranslation } from "react-i18next";
import RouteTypes from "constants/RouteTypes";

const { fetchRoles } = RoleActions;
const { getClientsByID, updateClientSecondStep, fetchUsersClient } =
  ClientActions;
const { setDefaultRegisterResult } = UsersActions;
const { setDefaultPackageStatus, searchAccounts } = AccountActions;

interface IDState {
  id: any;
}

const CreateClient = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => {
    return state.Clients.isLoading;
  });
  const userIsLoading = useSelector((state: RootState) => {
    return state.Users.isLoading;
  });
  const client = useSelector((state: RootState) => {
    return state.Clients.client;
  });
  const users = useSelector((state: RootState) => {
    return state.Clients.usersClient;
  });
  const packages = useSelector((state: RootState) => {
    return state.Account.accounts;
  });
  const location = useLocation();

  const [userContract, setUserContract] = useState({
    billingPeriod: "",
    billViaEmail: false,
    billViaPost: false,
    billingAddress: "",
    primaryContact: "",
    secondaryContact: "",
    samePrimaryContact: false,
    sameSecondaryContact: false,
  });

  const [isShowUserPopup, setIsShowUserPopup] = useState(false);
  const [isShowPackagePopup, setIsShowPackagePopup] = useState(false);
  const [isAddAnotherContact, setIsAddAnotherContact] = useState(false);

  useEffect(() => {
    dispatch(fetchRoles());
    if (location.state) {
      const id = (location.state as IDState).id || "";
      // if (!id) return history.push(ROUTES.CLIENTS);
      if (id) {
        dispatch(
          fetchUsersClient({
            clientId: id,
            limit: 100,
            status: CONSTANTS.STATUS.ACTIVE,
          })
        );
        dispatch(getClientsByID(id));
      }
    } else {
      // return history.push(ROUTES.CLIENTS);
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (!_.isEmpty(client)) {
      const clientId = _.get(client, "id");
      dispatch(searchAccounts({ clientId, page: 1, limit: 100 }));
    }
  }, [client, dispatch]);

  const dataDropdownUsers = processDataDropDownUser(_.get(users, "items"));
  // const dataDropdownPeriod = [
  //   { name: `3 ${t("dropdown.months")}`, value: "3 months" },
  //   { name: `6 ${t("dropdown.months")}`, value: "6 months" },
  //   { name: t("dropdown.everyYear"), value: "every yaer" },
  // ];

  const processData = () => {
    const formData = {
      primaryContact: _.trim(userContract.primaryContact),
    };
    if (userContract.secondaryContact)
      _.assign(formData, { secondContact: userContract.secondaryContact });
    // if (userContract.billingAddress)
    //   formData.append("billingAddress", _.trim(userContract.billingAddress));
    // if (userContract.billingPeriod)
    //   formData.append("billingPeriod", _.trim(userContract.primaryContact));
    // formData.append("primaryContact", _.trim(userContract.primaryContact));
    // if (userContract.secondaryContact && isAddAnotherContact) _.assign(formData, {})
    //   formData.assign("secondContact", _.trim(userContract.secondaryContact));
    return formData;
  };

  const validateData = () => {
    let flag = true;
    let message = `${t("alert.foundEmptyFields")}`;
    const data = [
      {
        label: t("label.primaryContact"),
        value: _.get(userContract, "primaryContact"),
      },
    ];
    if (isAddAnotherContact)
      data.push(
        ...[
          {
            label: t("label.secondaryContact"),
            value: _.get(userContract, "secondaryContact"),
          },
        ]
      );
    _.map(data, (item) => {
      const { value, label } = item;
      if (_.isEmpty(_.trim(value))) {
        message += `${label}\n`;
        flag = false;
      }
    });
    return { flag, message };
  };

  const createClient = () => {
    const { flag, message } = validateData();

    if (!flag)
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });
    else {
      const data = processData();
      const filter = _.get(location, "state.filter");
      const resolveFilter = {
        pathname: ROUTES.CLIENTS,
        state: { filter, from: RouteTypes.ADMIN_ROUTES.CLIENT_STEP_SECOND },
      };
      dispatch(updateClientSecondStep(data, client.id, resolveFilter));
    }
  };

  const generateUserOptions = (key: "primary" | "secondary") => {
    const resolveOptions: any[] = [];
    let preventId = "";
    if (key === "primary") preventId = _.get(userContract, "secondaryContact");

    if (key === "secondary") preventId = _.get(userContract, "primaryContact");

    _.forEach(dataDropdownUsers, (user: any) => {
      if (preventId !== _.get(user, "value")) resolveOptions.push(user);
    });
    return resolveOptions;
  };

  const onBack = () => {
    const filter = _.get(location, "state.filter");
    history.push({
      pathname: ROUTES.CLIENTS,
      state: { filter, from: RouteTypes.ADMIN_ROUTES.CLIENT_STEP_SECOND },
    });
  };

  const onCloseUserPopup = async () => {
    const id = _.get(location, "state.id");
    await dispatch(
      fetchUsersClient({
        clientId: id,
        limit: 100,
        status: CONSTANTS.STATUS.ACTIVE,
      })
    );
    dispatch(setDefaultRegisterResult());
    setIsShowUserPopup(false);
  };

  const onClosePackagePopup = async (isFetch?: boolean) => {
    const clientId = _.get(client, "id");
    if (isFetch && clientId)
      await dispatch(searchAccounts({ clientId, page: 1, limit: 100 }));
    setIsShowPackagePopup(false);
  };

  const renderMain = () => {
    return (
      <>
        {isShowUserPopup && (
          <UserForClient
            clientId={_.get(location, "state.id") || ''}
            onClose={() => onCloseUserPopup()}
          />
        )}
        {isShowPackagePopup && (
          <PackageDetails
            onClose={(isFetch?: boolean) => onClosePackagePopup(isFetch)}
            clientId={_.get(client, "id")}
          />
        )}
        <FormDetails>
          <TitlePartOfForm aria-label="require">
            {t("label.primaryContact")}
          </TitlePartOfForm>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.selectUser")}
              </FormLabel>
              {!_.isEmpty(dataDropdownUsers) && (
                <Dropdown
                  value={userContract.primaryContact || ""}
                  width="300px"
                  callbackPayload={(value) =>
                    setUserContract({ ...userContract, primaryContact: value })
                  }
                  options={generateUserOptions("primary")}
                  margin="10px 20px 10px 0"
                  placeholder={t(
                    `placeholder.${userIsLoading ? "loading" : "selectUser"}`
                  )}
                  search={false}
                  loading={userIsLoading}
                />
              )}
              {_.isEmpty(dataDropdownUsers) && (
                <div className="empty">{t("label.noUserFound")}</div>
              )}
            </FormGroup>
            <Button
              type="submit"
              onClick={() =>
                !isEmpty(_.get(packages, "items")) && setIsShowUserPopup(true)
              }
              buttonWidth="150px"
              buttonMargin="10px 10px -40px 0"
              disabled={isEmpty(_.get(packages, "items"))}
            >
              + {t("button.createUser")}
            </Button>
          </FormInline>
          <AnotherContactField className={isAddAnotherContact ? "show" : ""}>
            <TitlePartOfForm>{t("label.secondaryContact")}</TitlePartOfForm>
            <FormInline>
              <FormGroup>
                <FormLabel>{t("label.selectUser")}</FormLabel>
                {!_.isEmpty(dataDropdownUsers) && (
                  <Dropdown
                    value={userContract.secondaryContact || ""}
                    width="300px"
                    callbackPayload={(value) =>
                      setUserContract({
                        ...userContract,
                        secondaryContact: value,
                      })
                    }
                    options={generateUserOptions("secondary")}
                    margin="10px 20px 10px 0"
                    placeholder={t(
                      `placeholder.${userIsLoading ? "loading" : "selectUser"}`
                    )}
                    search={false}
                    loading={userIsLoading}
                  />
                )}
                {_.isEmpty(dataDropdownUsers) && (
                  <div className="empty">{t("label.noUserFound")}</div>
                )}
              </FormGroup>
              <Button
                type="submit"
                onClick={() =>
                  !isEmpty(_.get(packages, "items")) && setIsShowUserPopup(true)
                }
                buttonWidth="150px"
                buttonMargin="10px 10px -40px 0"
                disabled={isEmpty(_.get(packages, "items"))}
              >
                + {t("button.createUser")}
              </Button>
            </FormInline>
          </AnotherContactField>
          <FormInline>
            <Button
              onClick={() => setIsAddAnotherContact(!isAddAnotherContact)}
              buttonMargin="10px 10px 20px 0"
            >
              {isAddAnotherContact ? "Remove" : "Add"} another contact
            </Button>
            <Button
              type="submit"
              onClick={() => {
                dispatch(setDefaultPackageStatus());
                setIsShowPackagePopup(true);
              }}
              buttonWidth="150px"
              buttonMargin="10px 10px 20px 0"
            >
              + {t("button.createPackage")}
            </Button>
          </FormInline>
          {/* <TitlePartOfForm>{t("label.billingDetails")}</TitlePartOfForm>

          <div className="form-group">
            <div className="container-element">
              <span>{t("label.billingPeriod")}</span>
              <Dropdown
                value={userContract.billingPeriod}
                width="300px"
                callbackPayload={(value) =>
                  setUserContract({ ...userContract, billingPeriod: value })
                }
                options={dataDropdownPeriod}
                margin="10px 20px 10px 0"
                placeholder={t("placeholder.billingPeriod")}
                search={false}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="container-element">
              <Checkbox
                checked={userContract.billViaPost}
                context={t("label.billViaPost")}
                onChangeHandler={(value) =>
                  setUserContract({
                    ...userContract,
                    billViaPost: value,
                    billViaEmail: false,
                  })
                }
              />
            </div>
            <div className="container-element">
              <Checkbox
                checked={userContract.billViaEmail}
                context={t("label.billViaEmail")}
                onChangeHandler={(value) =>
                  setUserContract({
                    ...userContract,
                    billViaEmail: value,
                    billViaPost: false,
                  })
                }
              />
            </div>
          </div>

          <TitlePartOfForm>{t("label.billingAddress")}</TitlePartOfForm>
          <div className="form-group">
            <div className="container-element">
              <Checkbox
                checked={userContract.samePrimaryContact}
                context={t("label.sameAsPrimaryContact")}
                onChangeHandler={(value) =>
                  setUserContract({
                    ...userContract,
                    samePrimaryContact: value,
                    sameSecondaryContact: false,
                    billingAddress: value ? userContract.primaryContact : "",
                  })
                }
              />
              <div className="empty">
                {userContract.samePrimaryContact && !userContract.primaryContact
                  ? t("label.youDontHavePrimaryContact")
                  : " "}
              </div>
            </div>
            <div className="container-element">
              <Checkbox
                checked={userContract.sameSecondaryContact}
                context={t("label.sameAsSecondaryContact")}
                onChangeHandler={(value) =>
                  setUserContract({
                    ...userContract,
                    sameSecondaryContact: value,
                    samePrimaryContact: false,
                    billingAddress: value ? userContract.secondaryContact : "",
                  })
                }
              />
              <div className="empty">
                {userContract.sameSecondaryContact &&
                !userContract.secondaryContact
                  ? t("label.youDontHaveSecondaryContact")
                  : " "}
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="container-element">
              <span>{t("label.selectUser")}</span>
              {!_.isEmpty(dataDropdownUsers) && (
                <Dropdown
                  value={userContract.billingAddress}
                  width="300px"
                  callbackPayload={(value) =>
                    setUserContract({
                      ...userContract,
                      billingAddress: value,
                      sameSecondaryContact: false,
                      samePrimaryContact: false,
                    })
                  }
                  options={dataDropdownUsers}
                  margin="10px 20px 10px 0"
                  placeholder={t(
                    `placeholder.${userIsLoading ? "loading" : "selectUser"}`
                  )}
                  search={false}
                  loading={userIsLoading}
                />
              )}
              {_.isEmpty(dataDropdownUsers) && (
                <div className="empty">{t("label.noUserFound")}</div>
              )}
            </div>
            <div className="container-element">
              <Button
                type="submit"
                onClick={() => setIsDisplayPopup(true)}
                buttonWidth="150px"
                buttonMargin="10px 10px -40px 0"
              >
                + {t("button.create")}
              </Button>
            </div>
          </div> */}
          <ButtonField margin="10px 0 50px 0">
            <Button
              type="submit"
              onClick={() => createClient()}
              buttonWidth="150px"
              buttonMargin="10px 10px 10px 0"
              className={isLoading ? "loading" : ""}
            >
              {t("button.create")}
              {isLoading && (
                <LoadingLayer>
                  <Spinner />
                </LoadingLayer>
              )}
            </Button>
            <Button
              type="submit"
              onClick={() => onBack()}
              buttonWidth="150px"
              buttonMargin="10px 10px 10px 0"
            >
              {t("button.back")}
            </Button>
          </ButtonField>
        </FormDetails>
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      parent={RouteTypes.ADMIN_ROUTES.GROUPS}
      currentPage={RouteTypes.ADMIN_ROUTES.CLIENTS}
      title={t("titlePage.createContactOfClient")}
    />
  );
};

export default CreateClient;
