/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Stack,
} from "@mui/material";

import { RootState } from "reducers";
import { ConfigActions } from "actions";

import history from "../../../history";
import { CONSTANTS } from "constants/Constants";
import RouteTypes from "constants/RouteTypes";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Input,
  Button,
  SelectDatetime,
  Dropdown,
  ContainerSearch,
  FlexWrapRow,
} from "components/Common";

const { fetchSystemConfig, clearSystemConfig, updateSTMP, testEmail } =
  ConfigActions;

const DataRecord = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => {
    return state.Config.isLoading;
  });
  const SMTPdetail = useSelector((state: RootState) => {
    return state.Config.SMTPdetail;
  });
  const [SMTPdetailState, setSMTPdetailState] = useState<any>([]);

  useEffect(() => {
    dispatch(fetchSystemConfig({ type: CONSTANTS.CONFIG_TYPE.SMTP }));

    return () => {
      dispatch(clearSystemConfig());
    };
  }, []);

  useEffect(() => {
    if (SMTPdetail && SMTPdetail?.data)
      setSMTPdetailState(JSON.parse(SMTPdetail?.data));
  }, [SMTPdetail]);

  const onSave = () => {
    dispatch(
      updateSTMP(
        {
          ...SMTPdetail,
          data: JSON.stringify(SMTPdetailState),
        },
        SMTPdetail?.id
      )
    );
  };

  const renderMain = () => {
    return (
      <ContainerSearch className={isLoading ? "loading" : ""}>
        <Grid container justifyContent="center" gap={2}>
          <Grid item xs={7}>
            <TextField
              label={t("placeholder.mailServer")}
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={SMTPdetailState?.EMAIL_HOST}
              onChange={(e) =>
                setSMTPdetailState({
                  ...SMTPdetailState,
                  EMAIL_HOST: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={7}>
            <TextField
              label={t("placeholder.from")}
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={SMTPdetailState?.EMAIL_FROM}
              onChange={(e) =>
                setSMTPdetailState({
                  ...SMTPdetailState,
                  EMAIL_FROM: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={7}>
            <TextField
              label={t("placeholder.userName")}
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={SMTPdetailState?.EMAIL_ACC}
              onChange={(e) =>
                setSMTPdetailState({
                  ...SMTPdetailState,
                  EMAIL_ACC: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={7}>
            <TextField
              label={t("placeholder.password")}
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={SMTPdetailState?.EMAIL_PASS}
              onChange={(e) =>
                setSMTPdetailState({
                  ...SMTPdetailState,
                  EMAIL_PASS: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={7}>
            <TextField
              InputLabelProps={{ shrink: true }}
              label={t("placeholder.port")}
              size="small"
              fullWidth
              value={SMTPdetailState?.EMAIL_PORT}
              onChange={(e) =>
                setSMTPdetailState({
                  ...SMTPdetailState,
                  EMAIL_PORT: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={7}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!SMTPdetailState?.EMAIL_IGNORE_TLS}
                  onChange={(e) =>
                    setSMTPdetailState({
                      ...SMTPdetailState,
                      EMAIL_IGNORE_TLS: !e.target.checked,
                    })
                  }
                />
              }
              label="TLS/SSL"
            />
          </Grid>
          <Grid item xs={7}>
            <TextField
              InputLabelProps={{ shrink: true }}
              label="TLS/SSL ver"
              select
              size="small"
              fullWidth
              value={SMTPdetailState?.EMAIL_TLS_VER}
              onChange={(e) =>
                setSMTPdetailState({
                  ...SMTPdetailState,
                  EMAIL_TLS_VER: e.target.value,
                })
              }
            >
              <MenuItem value="TTLSv1_2">TTLSv1_2</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={7}>
            <Stack direction="row" justifyContent="center" gap={2}>
              <Button onClick={() => onSave()}>{t("button.save")}</Button>
              <Button onClick={() => dispatch(testEmail())}>
                {t("button.test")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </ContainerSearch>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.ADMIN_ROUTES.STMP}
      parent={RouteTypes.ADMIN_ROUTES.CONFIG}
      title={t("sideMenu.STMP")}
    />
  );
};

export default DataRecord;
