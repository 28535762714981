import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { RootState } from "reducers";
import { UsersActions } from "actions";

import { Images } from "themes";

import { Wrapper, Title } from "./Authentication.styles";
import { Loading } from "components/Common";

const { verifyAccount } = UsersActions;

const VerifyAccount = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state: RootState) => {
    return state.Users.isLoading;
  });
  const isSuccess = useSelector((state: RootState) => {
    return state.Users.isSuccess;
  });

  useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const email = params.get("e");
    const code = params.get("c");
    const data = {
      email,
      code,
    };
    dispatch(verifyAccount(data));
  }, [dispatch, location]);

  return (
    <Wrapper>
      <div className="container-login">
        <div className="header-login">
          <img src={Images.logo} alt="Media Monitoring system" />
        </div>
        {isLoading && <Loading />}
        <Title>
          {isLoading
            ? "Verifing..."
            : isSuccess
            ? "Verify Success"
            : "Verify Failure"}
        </Title>
      </div>
    </Wrapper>
  );
};

export default VerifyAccount;
