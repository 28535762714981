const images = {
  clients: require("../assets/icons/clients.svg"),
  dashboard: require("../assets/icons/dashboard.svg"),
  dataRecord: require("../assets/icons/data_record.svg"),
  delivery: require("../assets/icons/delivery.svg"),
  distribution: require("../assets/icons/distribution.svg"),
  email: require("../assets/icons/email.svg"),
  fileRecord: require("../assets/icons/file_record.svg"),
  filters: require("../assets/icons/filter.svg"),
  folders: require("../assets/icons/folders.svg"),
  logs: require("../assets/icons/logs.svg"),
  manageSources: require("../assets/icons/manage_sources.svg"),
  manageRecord: require("../assets/icons/manage_record.svg"),
  pdf: require("../assets/icons/pdf.svg"),
  post: require("../assets/icons/posts.svg"),
  roles: require("../assets/icons/roles.svg"),
  sources: require("../assets/icons/sources.svg"),
  template: require("../assets/icons/templates.svg"),
  users: require("../assets/icons/users.svg"),
  categories: require("../assets/icons/categories.svg"),
  defaultUser: require("../assets/icons/default_user.svg"),
  rightArrow: require("../assets/icons/right_arrow.svg"),
  preview: require("../assets/icons/preview.svg"),
  reports: require("../assets/icons/reports.svg"),
  group: require("../assets/icons/group.svg"),
  groupUsers: require("../assets/icons/user.svg"),
  package: require("../assets/icons/package.svg"),
  contact: require("../assets/icons/contact.svg"),
  hide: require("../assets/icons/hide.svg"),
  training: require("../assets/icons/training.svg"),
};

export default images;
