import styled from "styled-components";
import ColorName from "constants/Variables";

interface SectionProps {
  background?: string;
}

const UserHeaderContainer = styled.div`
  width: 100%;
  height: 60px;
  background: ${ColorName.linkWater};
  position: fixed;
  z-index: 1;
`;

const HeaderContent = styled.div`
  max-width: 800px;
  height: 100%;
  margin: 0 auto;
  padding: 0 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .logo-desktop {
    display: block;
  }
  & .logo-mobile {
    display: none;
  }

  @media screen and (max-width: 426px) {
    padding: 0 15px;
    & .logo-desktop {
      display: none;
    }
    & .logo-mobile {
      display: block;
    }
  }

  @media screen and (max-width: 810px) {
    padding: 0 30px;
  }
`;

const Logo = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

const LoginButton = styled.div`
  color: ${ColorName.william};
  position: relative;
  display: flex;
  align-items: center;
  @media screen and (max-width: 426px) {
    span.name {
      display: none;
    }
  }
  i {
    margin-left: 5px;
    margin-right: 5px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const UserMenu = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  padding: 10px 0;
  width: 120px;
  border: 1px solid ${ColorName.william};
  background: white;
  border-radius: 5px;
`;

const MenuItem = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  i {
    display: inline-block;
    font-size: 13px;
  }
  &:hover {
    cursor: pointer;
    background: ${ColorName.william};
    color: ${ColorName.white};
  }
`;

const Avatar = styled.div`
  width: 25px;
  height: 25px;
  background: url(${(props: SectionProps) => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-right: 10px;
`;

export {
  UserHeaderContainer,
  HeaderContent,
  Logo,
  LoginButton,
  UserMenu,
  MenuItem,
  Avatar,
};
