import ActionTypes from "constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  folders: [],
  folder: {},
  responseMessages: {},
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.CLEAR_FOLDERS:
      return {
        ...state,
        folders: [],
      };
    case ActionTypes.CLEAR_FOLDER_DETAILS:
      return {
        ...state,
        folder: {},
      };
    case ActionTypes.FOLDER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        responseMessages: {},
      };
    case ActionTypes.FOLDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FOLDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    case ActionTypes.CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.UPDATE_FOLDER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_FOLDER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        folders: payload,
      };
    case ActionTypes.SEARCH_FOLDER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        folders: payload,
      };
    case ActionTypes.GET_FOLDER_BY_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        folder: payload,
      };
    case ActionTypes.ACTIVATE_FOLDER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.DEACTIVATE_FOLDER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.ADD_POST_TO_FOLDER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        responseMessages: payload,
      };
    default:
      return state;
  }
};
