import Api from "./Base";
import { API } from "constants/Constants";

const { REPORT } = API;

export function fetchReports(data?: any) {
  return Api.post(REPORT.BASIC, data);
}

export function getReportById(id: string) {
  return Api.get(`${REPORT.BASIC}/${id}`);
}

export function searchReports(data: any) {
  return Api.get(REPORT.BASIC, data);
}

export function createReport(data: any) {
  return Api.postFormData(REPORT.BASIC, data);
}

export function updateReport(data: any, id: string) {
  return Api.putFormData(`${REPORT.UPDATE}/${id}`, data);
}
