import Api from "./Base";
import { API } from "constants/Constants";

const { EMAIL_TEMPLATE } = API;

export function fetchMailTemplate() {
  return Api.get(EMAIL_TEMPLATE.BASIC);
}

export function searchMailTemplate(data: any) {
  return Api.get(EMAIL_TEMPLATE.BASIC, data);
}

export function getMailTemplateById(id: any) {
  return Api.get(`${EMAIL_TEMPLATE.BASIC}/${id}`);
}

export function createMailTemplate(data: any) {
  return Api.post(EMAIL_TEMPLATE.BASIC, data);
}

export function updateMailTemplate(data: any) {
  const { id } = data;
  return Api.put(`${EMAIL_TEMPLATE.BASIC}/${id}`, data);
}

export function deactivateMailTemplate(id: string) {
  return Api.put(`${EMAIL_TEMPLATE.DEACTIVATE}/${id}`);
}

export function activateMailTemplate(id: string) {
  return Api.put(`${EMAIL_TEMPLATE.ACTIVATE}/${id}`);
}

export function getEmailTemplateByType(type: string) {
  return Api.get(`${EMAIL_TEMPLATE.GET_BY_TYPE}`, { type });
}
