import React from "react";
import _ from "lodash";
import dayjs from "dayjs";
import parse from "html-react-parser";

import { CONSTANTS } from "constants/Constants";

import { Pagination, Checkbox, LoadingLayer, Spinner } from "components/Common";
import {
  DataPostContainer,
  DataPostItem,
  PreviewContent,
  DatatPostContent,
  LogsEmptyData,
} from "./PostReport.styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";

interface SectionProps {
  data: any;
  checked: string[];
  onCheck(id: string): void;
  onChangePage(page: number): void;
  isHidePostOptions?: boolean;
  meta: any;
}

const PostReport = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { data, checked, onCheck, meta, onChangePage } = props;
  const limit = 10;
  const isDataRecordLoading = useSelector((state: RootState) => {
    return state.DataRecord.isLoading;
  });

  const generateContent = (content: string, keywords: string[]) => {
    if (_.isEmpty(keywords)) return content;
    let resolveContent = content;
    _.map(keywords, (keyword) => {
      const splitKeywords = keyword.trim().split("");
      let resolveKeyword = "";
      _.forEach(splitKeywords, (item) => {
        if (item.trim())
          resolveKeyword += `(${item.toLowerCase()}|${item.toUpperCase()})`;
        else resolveKeyword += " ";
      });
      const newContent = _.replace(
        resolveContent,
        new RegExp(resolveKeyword, "g"),
        `<mark>${keyword}</mark>`
      );
      resolveContent = newContent;
    });
    return parse(resolveContent);
  };

  const renderVideo = (item: any) => {
    let originalLink = _.get(item, "originalLink");
    if (!originalLink) return null;
    const isYoutube = _.includes(originalLink, "youtube");
    if (isYoutube) originalLink = originalLink.replace("watch?v=", "embed/");

    return (
      <iframe
        width="100%"
        height="480"
        src={originalLink}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    );
  };

  const renderPostData = () => {
    const render = _.map(data, (item, index) => {
      const isHasContent = _.has(item, "content");
      const dataRecordId = _.get(item, "dataRecordId");
      const spreadData = {
        ...item,
        ...dataRecordId,
      };
      const resolveItem = isHasContent ? item : spreadData;
      const {
        id,
        title,
        content,
        publishedAt,
        createdAt,
        source,
        status,
        client,
        account,
        originalLink,
      } = resolveItem;

      // const { link } = source;
      const link = _.get(source, "link");
      const mediaType = _.get(source, "mediaType");
      // const { name } = category;
      const category = _.get(resolveItem, "category");
      const categoryName = _.get(category, "name");
      const clientName = _.get(client, "clientName");
      const accountName = _.get(account, "accountName");
      const listKeywords = _.get(item, "listKeywords");

      const timeValue = dayjs(new Date(createdAt));
      let resultCreatedAt;
      if (timeValue.isValid()) resultCreatedAt = createdAt;
      else {
        if (_.includes(createdAt, "a.m."))
          resultCreatedAt = _.replace(createdAt, /a.m./g, "am");
        if (_.includes(createdAt, "p.m."))
          resultCreatedAt = _.replace(createdAt, /p.m./g, "pm");
      }

      return (
        <DataPostItem key={`post-item-${index}`}>
          <DatatPostContent
            className={`${status === CONSTANTS.STATUS.INACTIVE ? "blur" : ""}`}
          >
            <Checkbox
              checked={_.includes(checked, id)}
              context={title}
              onChangeHandler={() => onCheck(id)}
            />
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={originalLink ? originalLink : link}
              >
                {originalLink ? originalLink : link}
              </a>{" "}
              {t("label.createdAt")}{" "}
              {dayjs(resultCreatedAt).format("DD-MM-YYYY, HH:mm:ss")}
            </span>
            <PreviewContent>
              {mediaType === "tv"
                ? renderVideo(item)
                : generateContent(content, listKeywords)}
            </PreviewContent>
            <div>
              {clientName && (
                <span>
                  <b>{t("label.client")}:</b> {clientName}
                </span>
              )}
              {accountName && (
                <span>
                  <b>{t("label.package")}:</b> {accountName}
                </span>
              )}
              {categoryName && (
                <span>
                  <b>{t("label.category")}:</b>{" "}
                  {_.get(categoryName, "en")
                    ? _.get(categoryName, "en")
                    : categoryName}
                </span>
              )}
              <span>
                <b>{t("label.publishedDate")}:</b>{" "}
                {dayjs(publishedAt).format("DD-MM-YYYY, HH:mm:ss")}
              </span>
            </div>
          </DatatPostContent>
        </DataPostItem>
      );
    });
    return render;
  };
  return (
    <DataPostContainer>
      {isDataRecordLoading ? (
        <LoadingLayer>
          <Spinner />
        </LoadingLayer>
      ) : _.isEmpty(data) ? (
        <LogsEmptyData>No Articles found</LogsEmptyData>
      ) : (
        <>
          {renderPostData()}
          <Pagination
            maxPage={_.get(meta, "totalPages")}
            currentPage={_.get(meta, "currentPage") || 1}
            totalCount={_.get(meta, "totalItems") || 1}
            numberPageDisplay={2}
            pageSize={limit}
            clickLinkPagination={(e: number) => onChangePage(e)}
            clickLinkNext={(e: number) => onChangePage(e)}
            clickLinkPrevious={(e: number) => onChangePage(e)}
            clickLinkFirst={() => onChangePage(1)}
            clickLinkLast={() => onChangePage(_.get(meta, "totalPages"))}
          />
        </>
      )}
    </DataPostContainer>
  );
};

export default PostReport;
