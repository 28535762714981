import styled, { css } from "styled-components";
import ColorName from "constants/Variables";

interface SectionProps {
  colHead: number;
  fitColumns?: Array<number>;
}

const Spinner = styled.div`
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  width: 40px;
  height: 40px;
  border-top: 4px solid rgba(0, 0, 0, 0.7);
  border-right: 4px solid transparent;
  border-radius: 50%;
  animation: rotation 0.8s linear infinite;
`;

const LoadingLayer = styled.div`
  position: absolute;
  top: -1px;
  right: -1px;
  left: -1px;
  bottom: -1px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const OptionItem = styled.div`
  border-bottom: 1px solid ${ColorName.william};
  color: ${ColorName.william};
  padding: 8px 20px;
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 13px;
  cursor: pointer;

  &.disabled {
    opacity: 0.6;
    &:hover {
      background: ${ColorName.white};
      color: ${ColorName.william};
      cursor: not-allowed;
    }
  }

  &:hover {
    background: ${ColorName.william};
    color: ${ColorName.white};
  }
`;

const ListOption = styled.div`
  display: none;
  position: absolute;
  border: 1px solid ${ColorName.zircon};
  right: 0;
  z-index: 9;
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  color: ${ColorName.regentGray};
  box-sizing: border-box;
  background: white;
  /* white-space: nowrap; */
  font-size: 14px;
  /* min-width: 100px; */
  & img {
    object-fit: scale-down;
    cursor: pointer;
  }
  &.header {
    padding: 12px;
    min-width: 110px;
    &.no {
      min-width: 30px;
    }
    &.action {
      min-width: 50px;
    }
    & .icon-sort {
      margin-left: 5px;
      display: flex;
      align-items: center;
    }
    &.not-clickable {
      cursor: auto;
    }
    &.justify-center {
      justify-content: center;
    }
    color: ${ColorName.william};
    font-weight: bold;
    font-size: 14px;
    text-transform: capitalize;
    white-space: nowrap;
    display: flex;
    cursor: pointer;
    background: ${ColorName.zircon};
  }
  &.justify-center {
    justify-content: center;
  }
  &.datetime {
    white-space: nowrap;
  }
  .active {
    background: #cef1d3;
  }
  .inactive {
    background: #ffd6d6;
  }
  .inactive,
  .active {
    color: #3c6379;
    padding: 5px 20px;
    border-radius: 4px;
    width: 95px;
    box-sizing: border-box;
    text-align: center;
  }
  &.log-level {
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      width: 120px;
      height: 30px;
      padding: 5px 10px;
      box-sizing: border-box;
      text-transform: uppercase;
      border-radius: 3px;
    }
    & .normal {
      border: 1px solid #5ccd89;
      color: #5ccd89;
      background: rgba(92, 205, 137, 0.2);
    }
    & .warning {
      border: 1px solid #fdbb1e;
      color: #fdbb1e;
      background: rgba(253, 187, 30, 0.2);
    }
    & .danger {
      border: 1px solid #ec5f56;
      color: #ec5f56;
      background: rgba(236, 95, 86, 0.2);
    }
  }
  &.action {
    position: relative;
    display: flex;
    justify-content: center;
    border-right: none;
    span {
      width: 30px;
      height: 30px;
      border-radius: 54px;
      /* background: black; */
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &:hover {
        cursor: pointer;
        background-color: rgba(60, 99, 121, 0.6);
        i {
          color: ${ColorName.white};
        }
        &:before {
          content: "";
          position: absolute;
          right: calc(100% - 15px);
          width: 25px;
          height: 30px;
          background: transparent;
        }
        & ${ListOption} {
          display: flex;
          flex-direction: column;
          font-weight: 400;
          background: ${ColorName.white};
          margin-left: auto;
          margin-right: auto;
          /* top: 30px; */
          right: calc(100% + 10px);
          min-width: 130px;
          max-width: 150px;
          border: 1px solid ${ColorName.william};
          border-bottom: none;
          &.top {
            bottom: 0px;
          }
          &.bottom {
            top: 0px;
          }
        }
      }
    }
    & i {
      /* padding: 0 20px; */
      cursor: pointer;
      color: ${ColorName.william};
      display: flex;
      align-items: center;
      height: 100%;
      position: relative;
    }
  }
  & .icon-tick {
    color: #46a879;
    padding-left: 2em;
  }
  & .icon-times {
    color: #ff7658;
    padding-left: 2em;
  }
`;

const createCSS = (colHead: any) => {
  let styles = "";
  for (let i = 1; i <= colHead; i += 1) {
    styles += `
      & ${Cell}:nth-of-type(${colHead * 2}n + ${colHead + i}){
        background: ${ColorName.zircon};
      }
     `;
  }
  return css`
    ${styles}
  `;
};

const createGrid = (colHead: any, columns: any) => {
  let styles = "";
  for (let i = 1; i <= colHead; i += 1) {
    if (columns && columns.includes(i)) {
      styles += `
       min-content 
     `;
    } else {
      styles += `
      auto 
     `;
    }
  }
  return css`
    ${styles}
  `;
};

const DataTable = styled.div`
  display: grid;
  grid-template-columns: ${(props: SectionProps) =>
    createGrid(props.colHead, props.fitColumns)};
  grid-auto-columns: min-content;
  ${(props: SectionProps) => createCSS(props.colHead)};
  color: ${ColorName.william};
  // overflow-x: auto;
`;

const ContainerPagination = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
`;

const WrapperTable = styled.div`
  display: flex;
  flex-direction: column;
  background: ${ColorName.zircon};
  border: 1px solid ${ColorName.spindle};
  padding: 15px 35px;
  border-radius: 40px;
  margin-top: 2em;
  position: relative;
  & .data-not-found {
    display: flex;
    white-space: nowrap;
    padding: 10px;
  }
`;

const TitleTable = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em;
  align-items: center;
  margin-bottom: 1em;
`;

const ViewMore = styled.div`
  display: flex;
  color: ${ColorName.william};
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  & img {
    object-fit: scale-down;
    margin-left: 15px;
    display: flex;
    align-items: center;
  }
`;

const Title = styled.div`
  display: flex;
  color: ${ColorName.william};
  font-size: 26px;
  font-weight: bold;
`;

const TableScrollField = styled.div`
  overflow: auto;
  max-width: calc(100vw - 229px - 56px);
  background: ${ColorName.zircon};
  border: 1px solid ${ColorName.spindle};
  border-radius: 40px;
  margin-top: 2em;
  padding-bottom: 3em;
  & .data-not-found {
    display: flex;
    white-space: nowrap;
    padding: 10px;
  }

  ${WrapperTable} {
    background: unset;
    border: unset;
    margin-top: 0;
  }
  ${ListOption} {
    right: 50%;
  }
  ${DataTable} {
    width: 100vw;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${ColorName.linkWater};
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: ${ColorName.william};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${ColorName.william};
    border: 2px solid #555555;
  }
`;

export {
  DataTable,
  Cell,
  OptionItem,
  ListOption,
  ContainerPagination,
  WrapperTable,
  TitleTable,
  ViewMore,
  Title,
  TableScrollField,
  LoadingLayer,
  Spinner,
};
