import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Link,
  Document,
  Page,
  pdf,
  Image
} from "@react-pdf/renderer";
import _ from "lodash";
import dayjs from "dayjs";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  headerTable: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#ccc",
    height: "20px",
    width: "100%",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  table: {
    width: "100%",
    border: "1px solid black",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid black",
  },
  bold: {
    fontWeight: "bold",
  },
  statisticalTable: {
    width: "60%",
    marginTop: "10px",
  },
  headerStatisticalCell: {
    width: "20%",
    height: "100%",
    fontSize: "14px",
    fontWeight: "bold",
    padding: "2px",
  },
  statisticalCell: {
    width: "20%",
    height: "100%",
    padding: "5px",
    fontSize: "14px",
  },
  borderRight: {
    borderRight: "1px solid black",
  },
  recordsTable: {
    width: "100%",
    marginTop: "25px",
  },
  numberColumn: {
    width: "8%",
  },
  headlineColumn: {
    width: "50%",
  },
  publishColumn: {
    width: "15%",
  },
  sourceColumn: {
    width: "27%",
  },
  datetime: {
    fontSize: "15px",
    marginRight: "15px",
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  titleCover: {
    display: 'flex',
    alignContent: 'center',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'gray',
    textTransform: 'capitalize',
    marginTop: '20px'
  }
});

interface SectionProps {
  data: any;
  total: any;
  logo?: any;
  title?: string;
}

const PDFFile = (props: SectionProps) => {
  const { data, total, logo, title } = props;
  const MyDoc = (
    <Document>
      {(title || logo) &&
        <Page orientation="landscape" style={styles.body} wrap>
          {logo && (
            <View style={styles.centerContent}>
              <Image 
                src={_.get(logo, 'imagePath')}
                style={{ borderRadius: 100, width: '200px', height: '200px' }} />
            </View>
          )}
          {title && (
            <View style={styles.titleCover}>
              <Text>
                {title}
              </Text>
            </View>
          )}
        </Page>
      }

      <Page orientation="landscape" style={styles.body} wrap>
        <View style={styles.row}>
          <Text style={styles.datetime}>
            Date: {dayjs().format("MM/DD/YYYY")}
          </Text>
          <Text style={styles.datetime}>Time: {dayjs().format("HH:mm")}</Text>
        </View>
        <View style={styles.statisticalTable}>
          <View style={styles.headerTable}>
            <Text style={[styles.headerStatisticalCell, styles.borderRight]}>
              Print
            </Text>
            <Text style={[styles.headerStatisticalCell, styles.borderRight]}>
              Web
            </Text>
            <Text style={[styles.headerStatisticalCell, styles.borderRight]}>
              TV
            </Text>
            <Text style={[styles.headerStatisticalCell, styles.borderRight]}>
              Social Media
            </Text>
            <Text style={[styles.headerStatisticalCell]}>All</Text>
          </View>
          <View style={styles.row}>
            <Text style={[styles.statisticalCell, styles.borderRight]}>
              {_.get(total, "print")}
            </Text>
            <Text style={[styles.statisticalCell, styles.borderRight]}>
              {_.get(total, "web")}
            </Text>
            <Text style={[styles.statisticalCell, styles.borderRight]}>
              {_.get(total, "tv")}
            </Text>
            <Text style={[styles.statisticalCell, styles.borderRight]}>
              {_.get(total, "social")}
            </Text>
            <Text style={[styles.statisticalCell]}>{_.get(total, "all")}</Text>
          </View>
        </View>
        <View style={styles.recordsTable} wrap={true}>
          <View style={styles.headerTable}>
            <Text
              style={[
                styles.headerStatisticalCell,
                styles.numberColumn,
                styles.borderRight,
              ]}
            >
              No
            </Text>
            <Text
              style={[
                styles.headerStatisticalCell,
                styles.headlineColumn,
                styles.borderRight,
              ]}
            >
              Headline
            </Text>
            <Text
              style={[
                styles.headerStatisticalCell,
                styles.publishColumn,
                styles.borderRight,
              ]}
            >
              Published Date
            </Text>
            <Text style={[styles.headerStatisticalCell, styles.sourceColumn]}>
              Source
            </Text>
          </View>
          {_.map(data, (item, index) => {
            return (
              <View key={`table-item-${index}`} style={styles.row} wrap={false}>
                <Text
                  style={[
                    styles.statisticalCell,
                    styles.numberColumn,
                    styles.borderRight,
                  ]}
                >
                  {index + 1}
                </Text>
                <Text
                  style={[
                    styles.statisticalCell,
                    styles.headlineColumn,
                    styles.borderRight,
                  ]}
                >
                  <Link src={_.get(item, "originalLink")}>
                    <Text>{_.get(item, "title")}</Text>
                  </Link>
                </Text>
                <Text
                  style={[
                    styles.statisticalCell,
                    styles.publishColumn,
                    styles.borderRight,
                  ]}
                >
                  {new Date(_.get(item, "publishedAt")).toDateString()}
                </Text>
                <Text style={[styles.statisticalCell, styles.sourceColumn]}>
                  {_.get(item, "source.name")}
                </Text>
              </View>
            );
          })}
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );

  const blob = pdf(MyDoc).toBlob();

  return blob;
};

export default PDFFile;
