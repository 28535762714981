import React from "react";
import { Wrapper, Link } from "./Pagination.styles";
import _ from "lodash";
import { usePagination } from "./PaginationCalculation";
interface SectionProps {
  maxPage: number;
  currentPage: number;
  numberPageDisplay: number;
  totalCount: number;
  pageSize?: number;
  clickLinkPagination: (e: any) => any;
  clickLinkNext: (e: any) => any;
  clickLinkPrevious: (e: any) => any;
  clickLinkLast: () => void;
  clickLinkFirst: () => void;
}

const Pagination: React.FC<SectionProps> = (props: SectionProps) => {
  const {
    maxPage,
    currentPage,
    totalCount,
    pageSize = 10,
    clickLinkPagination,
    clickLinkFirst,
    clickLinkLast,
    clickLinkNext,
    clickLinkPrevious,
  } = props;

  const paginationRange: any = usePagination({
    currentPage,
    totalCount,
    siblingCount: 1,
    pageSize,
  });

  const _renderPrevious = () => {
    const isDisabled = maxPage === 1;
    return (
      <>
        <Link
          className={`${isDisabled ? "disabled" : ""} ${
            currentPage > 1 ? "clickable" : ""
          } arrow`}
          onClick={() => (currentPage > 1 ? clickLinkFirst() : null)}
        >
          <i className="fas fa-angle-double-left" />
        </Link>
        <Link
          className={`${isDisabled ? "disabled" : ""} ${
            currentPage > 1 ? "clickable" : ""
          } arrow`}
          onClick={() =>
            currentPage > 1 ? clickLinkNext(currentPage - 1) : null
          }
        >
          <i className="fas fa-angle-left" />
        </Link>
      </>
    );
  };

  const _renderNext = () => {
    const isDisabled = maxPage === 1;
    return (
      <>
        <Link
          className={`${isDisabled ? "disabled" : ""} ${
            currentPage < maxPage ? "clickable" : ""
          } arrow`}
          onClick={() =>
            currentPage < maxPage ? clickLinkPrevious(currentPage + 1) : null
          }
        >
          <i className="fas fa-angle-right" />
        </Link>
        <Link
          className={`${isDisabled ? "disabled" : ""} ${
            currentPage < maxPage ? "clickable" : ""
          } arrow`}
          onClick={() => (currentPage < maxPage ? clickLinkLast() : null)}
        >
          <i className="fas fa-angle-double-right" />
        </Link>
      </>
    );
  };

  const _renderLinkBefore = () => {
    return _renderPrevious();
  };

  const _renderLinkAfter = () => {
    return _renderNext();
  };

  const _renderLink = (value: number | string, key: number) => {
    if (value === "DOTS") return <Link key={key} className="for-more">...</Link>;
    else {
      const isDisabled = maxPage === 1;
      return (
        <Link
          key={key}
          className={`${isDisabled ? "disabled" : ""} ${
            value === currentPage ? "active" : ""
          }`}
          onClick={() => !isDisabled && clickLinkPagination(value)}
        >
          {value}
        </Link>
      );
    }
  };
  const _renderPagination = () => {
    return (
      <Wrapper>
        {_renderLinkBefore()}
        {_.map(paginationRange, function (value, key) {
          return _renderLink(value, _.toNumber(key));
        })}
        {_renderLinkAfter()}
      </Wrapper>
    );
  };

  const _rendermain = () => {
    return _renderPagination();
  };

  return _rendermain();
};

export default Pagination;
