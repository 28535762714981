import Api from "./Base";
import { API } from "constants/Constants";

const { DATA_RECORD } = API;

export function addDataRecord(data: any) {
  return Api.post(DATA_RECORD.BASIC, data);
}

export function fetchDataRecord() {
  return Api.get(DATA_RECORD.BASIC);
}

export function getDataRecordByID(id: any) {
  return Api.get(`${DATA_RECORD.GET_BY_ID}/${id}`);
}

export function searchDataRecord(data: any) {
  return Api.get(DATA_RECORD.BASIC, data);
}

export function updateDataRecord(data: any, id: any) {
  return Api.put(`${DATA_RECORD.BASIC}/${id}`, data);
}
export function deleteDataRecord(id: any) {
  return Api.del(`${DATA_RECORD.DELETE}/${id}`);
}

export function filterDataRecord(data: any) {
  return Api.get(DATA_RECORD.FILTER_BY_ADMIN, data);
}

export function getDataRecordForFolderById(data: any) {
  return Api.get(DATA_RECORD.GET_DATA_BY_FOLDER_ID, data);
}

export function previewDataRecordBeforeCreate(data: any) {
  return Api.post(DATA_RECORD.PREVIEW_FILTER, data);
}

export function sendReportByEmail(data: any) {
  return Api.post(DATA_RECORD.SEND_REPORT_BY_EMAIL, data);
}

export function getDataRecordForUserPortal(filter: any) {
  return Api.get(DATA_RECORD.GET_DATA_RECORD_BY_ACCOUNT_ID, filter);
}

export function getPreviewDataRecordsForUser(data: any) {
  return Api.get(DATA_RECORD.GET_PREVIEW_DATA_RECORDS_FOR_USER, data);
}

export function sendDataRecordToMail(data: any) {
  return Api.post(DATA_RECORD.SEND_DATA_RECORD_TO_MAIL, data);
}

export function sendAttachFileToMail(data: any) {
  return Api.postFormData(DATA_RECORD.SEND_ATTACHMENT_TO_MAIL, data);
}

export function unHiddenPost(data: any) {
  return Api.put(DATA_RECORD.UNHIDDEN_POSTS, data);
}

export function hiddenPosts(data: any) {
  return Api.put(DATA_RECORD.HIDDEN_POSTS, data);
}