import styled from "styled-components";
import ColorName from "constants/Variables";

const DataPostContainer = styled.div`
  // max-width: 1140px;
  min-height: 100px;
  overflow: hidden;
  position: relative;
`;

const LogsEmptyData = styled.div`
  margin-bottom: 15px;
`;

const DataPostItem = styled.div`
  position: relative;
  // padding: 25px 10px;
  box-sizing: border-box;
  border: 1px solid #55555524;
  margin-bottom: 25px;
  &:nth-child(even) {
    background: ${ColorName.linkWater};
  }

  span {
    color: ${ColorName.william};
    margin-right: 10px;
  }
`;

const PreviewContent = styled.div`
  margin: 15px 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const PostMenu = styled.div`
  position: absolute;
  right: 0px;
  top: 100%;
  width: 180px;
  height: 0px;
  background: transparent;
  border: 1px solid transparent;
  overflow: hidden;
`;

const PostMenuItem = styled.div`
  padding: 8px 20px;
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: ${ColorName.linkWater};
  }
`;

const MenuButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  display: flex;
  justify-content: center;
  i {
    color: ${ColorName.william};
  }
  &:hover {
    cursor: pointer;
    ${PostMenu} {
      background: #fff;
      height: auto;
      border: 1px solid #cadeed;
    }
  }
`;

const DatatPostContent = styled.div`
  &.blur {
    opacity: 0.4;
  }
  & .preview-pdf {
    display: flex;
  }
`;

export {
  DataPostContainer,
  DataPostItem,
  PreviewContent,
  MenuButton,
  PostMenu,
  PostMenuItem,
  DatatPostContent,
  LogsEmptyData,
};
