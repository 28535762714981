/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "reducers";
import { EmailTemplateActions } from "actions";

import { CONSTANTS } from "constants/Constants";
import ColorName from "constants/Variables";
import RouteTypes from "constants/RouteTypes";

import TableUltils from "libs/TableUltils";
import { generateFilter, popupAlert } from "libs/Utils";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Button,
  Input,
  Table,
  Checkbox,
  ContainerSearch,
  FlexWrapRow,
} from "components/Common";
import { EmailTemplateDetails, LogsDetails } from "components/Common/Popup";
import { useTranslation } from "react-i18next";

interface FilterStructure {
  emailTemplateName: string;
  emailTemplateSubject: string;
  status: string;
  page: number;
  limit: number;
  sortBy: string;
  orderBy: string;
}

const DEFAULT_FILTER = {
  emailTemplateName: "",
  emailTemplateSubject: "",
  status: CONSTANTS.STATUS.ACTIVE,
  page: 1,
  limit: 10,
  sortBy: "updatedAt",
  orderBy: "DESC",
};

const InputStyleProps = {
  width: "200px",
  margin: "10px 10px 10px 0",
};

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "10px 10px 10px 0",
};

const {
  activateEmailTemplate,
  deactivateEmailTemplate,
  searchEmailTemplate,
  clearEmailTemplates,
} = EmailTemplateActions;

const EmailTemplate = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const limit = 10;
  const emailTemplateIsLoading = useSelector((state: RootState) =>
    _.get(state, "EmailTemplate.isLoading")
  );
  const emailTemplates: any = useSelector((state: RootState) =>
    _.get(state, "EmailTemplate.emailTemplates")
  );

  const [logData, setLogData] = useState<any>({});
  const [isShowDetailsPopup, setIsShowDetailsPopup] = useState<boolean>(false);
  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const [dataPopup, setDataPopup] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataEmailTemplates, setDataEmailTemplates] = useState<any>([]);

  useEffect(() => {
    dispatch(searchEmailTemplate(generateFilter(filter)));
    return () => {
      dispatch(clearEmailTemplates());
    };
  }, [dispatch, limit]);

  useEffect(() => {
    if (!_.isEmpty(emailTemplates)) {
      const getDataEmailTemplates: any[] = _.get(emailTemplates, "items");
      if (currentPage > 1) {
        setDataEmailTemplates([
          ...dataEmailTemplates,
          ...getDataEmailTemplates,
        ]);
      } else setDataEmailTemplates(getDataEmailTemplates);
    }
  }, [emailTemplates]);

  const dataHead = [
    "no",
    "templateName",
    "subjectName",
    "emailType",
    "status",
    "actions",
  ];

  const dataTable = TableUltils._processDataTable(
    [
      "no",
      "id",
      "emailTemplateName",
      "emailTemplateSubject",
      "emailType",
      "status",
      "action",
    ],
    dataEmailTemplates
  );

  const listColumnCenter = [5, 6];

  const callbackCurrentPageLink = (page: number) => {
    const newFilter: any = {
      ...filter,
      page,
    };
    setFilter(newFilter);
    dispatch(searchEmailTemplate(generateFilter(newFilter)));
  };

  const callbackAction = async (action: string, id: string, item: any) => {
    if (action === CONSTANTS.ACTION.EDIT) {
      const findTemplate = _.find(emailTemplates?.items, { id });
      setDataPopup(findTemplate || {});
      setIsShowDetailsPopup(true);
    }

    if (action === CONSTANTS.ACTION.LOG) {
      setLogData({ rowId: id, logType: "email template" });
    }

    if (action === CONSTANTS.ACTION.DEACTIVATE) {
      const status = _.get(item, "status");
      const name = _.get(item, "emailTemplateName");
      const statusLabel =
        _.get(item, "status") === CONSTANTS.STATUS.ACTIVE
          ? t("alert.deactive")
          : t("alert.active");
      const content = document.createElement("span");
      content.innerHTML = `${t(
        "alert.areYouSureYouWantTo"
      )}</br> <b>${statusLabel}</b> ${t("alert.theTemplate")} <b>${name}</b>?`;
      await popupAlert({
        type: "warning",
        title: t("alert.warning"),
        content,
        buttons: true,
        dangerMode: true,
      }).then(async (willContinue) => {
        if (willContinue) {
          if (status === CONSTANTS.STATUS.ACTIVE)
            await dispatch(deactivateEmailTemplate(id));
          else await dispatch(activateEmailTemplate(id));
          await dispatch(searchEmailTemplate(generateFilter(filter)));
        }
      });
    }
  };

  const addFilter = async (page: number) => {
    const newFilter = {
      ...filter,
      page: page,
    };
    setFilter(newFilter);
    await dispatch(searchEmailTemplate(generateFilter(newFilter)));
    setCurrentPage(page);
  };

  const onReset = async () => {
    setFilter(DEFAULT_FILTER);
    await dispatch(searchEmailTemplate(generateFilter(DEFAULT_FILTER)));
    setCurrentPage(1);
  };

  const setValueStatus = (status: string) => {
    const newFilter = {
      ...filter,
      status,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(searchEmailTemplate(generateFilter(newFilter)));
    setCurrentPage(1);
  };

  const onCloseTemplateDetails = async () => {
    setIsShowDetailsPopup(false);
    setDataPopup({});
    await dispatch(searchEmailTemplate(generateFilter(filter)));
  };

  const onFetchMore = () => {
    if (
      !emailTemplateIsLoading &&
      currentPage < _.get(emailTemplates, "meta.totalPages")
    )
      addFilter(filter.page + 1);
  };

  const onShowMore = () => {
    if (
      !emailTemplateIsLoading &&
      currentPage < _.get(emailTemplates, "meta.totalPages")
    )
      addFilter(filter.page + 1);
  };

  const _renderTable = () => {
    return (
      <Table
        dataHeader={dataHead}
        dataTable={dataTable}
        fitColumns={[1, 5, 6]}
        listColumnCenter={listColumnCenter}
        hasPagination={true}
        callbackCurrentPageLink={callbackCurrentPageLink}
        metaPagination={emailTemplates?.meta || {}}
        listActionItem={[
          CONSTANTS.ACTION.EDIT,
          CONSTANTS.ACTION.DEACTIVATE,
          CONSTANTS.ACTION.LOG,
        ]}
        callBackAction={callbackAction}
        loading={emailTemplateIsLoading}
        showMore={onShowMore}
      />
    );
  };

  const renderMain = () => {
    return (
      <>
        {!_.isEmpty(logData) && (
          <LogsDetails onClose={() => setLogData({})} data={logData} />
        )}
        {isShowDetailsPopup && (
          <EmailTemplateDetails
            emailTemplate={dataPopup}
            onClose={() => onCloseTemplateDetails()}
          />
        )}
        <ContainerSearch className={emailTemplateIsLoading ? "loading" : ""}>
          <FlexWrapRow>
            <Input
              value={_.get(filter, "emailTemplateName")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, emailTemplateName: e.target.value })
              }
              onEnter={() => addFilter(1)}
              placeholder={t("placeholder.templateName")}
              {...InputStyleProps}
            />
            <Input
              value={_.get(filter, "emailTemplateSubject")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, emailTemplateSubject: e.target.value })
              }
              onEnter={() => addFilter(1)}
              placeholder={t("placeholder.subjectName")}
              {...InputStyleProps}
            />
            <Button onClick={() => addFilter(1)} {...ButtonStyleProps}>
              {t("button.search")}
            </Button>
            <Button onClick={() => onReset()} {...ButtonStyleProps}>
              {t("button.reset")}
            </Button>
            <Button
              onClick={() => setIsShowDetailsPopup(true)}
              background={ColorName.william}
              {...ButtonStyleProps}
            >
              + {t("button.create")}
            </Button>
          </FlexWrapRow>
          <Checkbox
            checked={_.get(filter, "status") === CONSTANTS.STATUS.INACTIVE}
            context={t("label.showInactiveEmailTemplates")}
            onChangeHandler={(e: boolean) =>
              setValueStatus(
                e ? CONSTANTS.STATUS.INACTIVE : CONSTANTS.STATUS.ACTIVE
              )
            }
            margin="10px 0 0 0"
          />
        </ContainerSearch>
        {_renderTable()}
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      title={t("titlePage.manageEmailTemplates")}
      parent={RouteTypes.ADMIN_ROUTES.MANAGE_TEMPLATE}
      currentPage={RouteTypes.ADMIN_ROUTES.EMAIL_TEMPLATE}
      onScrollBottom={() => onFetchMore()}
    />
  );
};

export default EmailTemplate;
