/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AccountActions, FoldersActions, ClientActions } from "actions";
import { Button } from "components/Common/CommonLayout";
import { Dropdown, Input, FormGroup, FormLabel } from "components/Common";
import { generateDropdown, popupAlert } from "libs/Utils";
import { RootState } from "reducers";
import {
  TemplateDetailsContent,
  TemplateDetailsContainer,
  TemplateDetailsForm,
  ButtonField,
  TemplateDetailsHeader,
  LoadingLayer,
  Spinner,
} from "../Popup.styles";

const { fetchPackagesForUserPortal } = AccountActions;
const { fetchAllClients } = ClientActions;
const { updateFolder, createFolder, clearFolderDetails } = FoldersActions;

interface SectionProps {
  onClose(): void;
  folder: any;
}

const FolderDetails = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { onClose, folder } = props;

  const dispatch = useDispatch();
  const accountIsLoading = useSelector((state: RootState) => {
    return state.Account.isLoading;
  });
  const clientIsLoading = useSelector((state: RootState) => {
    return state.Clients.isLoading;
  });
  const clients = useSelector((state: RootState) => {
    return state.Clients.clients;
  });
  const accounts = useSelector((state: RootState) => {
    return state.Account.packagesForUserPotal;
  });
  const foldersIsLoading = useSelector((state: RootState) => {
    return state.Folders.isLoading;
  });

  const [accountId, setAccountId] = useState<string>("");
  const [clientId, setClientId] = useState<string>("");
  const [folderName, setFolderName] = useState<string>("");

  const [clientOptions, setClientOptions] = useState<any[]>([]);
  const [packageOptions, setPackageOptions] = useState<any[]>([]);

  useEffect(() => {
    return () => {
      dispatch(clearFolderDetails());
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(clients) && _.isArray(clients)) {
      const getOptions = generateDropdown({
        data: clients,
        key: "clientName",
        value: "id",
      });
      setClientOptions(getOptions);
    }
  }, [clients]);

  useEffect(() => {
    if (!_.isEmpty(accounts) && _.isArray(accounts)) {
      const getOptions = generateDropdown({
        data: accounts,
        key: "accountName",
        value: "id",
      });
      setPackageOptions(getOptions);
    }
  }, [accounts]);

  useEffect(() => {
    dispatch(fetchAllClients());
    if (folder) {
      const folderName = _.get(folder, "folderName");
      const clientId = _.get(folder, "clientId.id");
      const accountId = _.get(folder, "accountId.id");
      if (accountId) dispatch(fetchPackagesForUserPortal(clientId));
      setAccountId(accountId);
      setFolderName(folderName);
      setClientId(clientId);
    }
  }, [folder]);

  const validateData = () => {
    let flag = true;
    let message = `${t("alert.foundEmptyFields")}`;
    const data = [
      {
        label: t("label.client"),
        value: clientId,
      },
      {
        label: t("label.package"),
        value: accountId,
      },
      {
        label: t("label.folderName"),
        value: folderName,
      },
    ];
    _.map(data, (item) => {
      const value = _.get(item, "value");
      const label = _.get(item, "label");
      if (_.isEmpty(_.trim(value))) {
        flag = false;
        message += `${label}\n`;
      }
    });
    return { flag, message };
  };

  const onSubmit = async () => {
    const { flag, message } = validateData();
    if (!flag) {
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });
    } else {
      const folderId = _.get(folder, "id");
      const resolveFolderData = {
        clientId,
        accountId,
        folderName: _.trim(folderName),
      };
      if (folderId)
        await dispatch(
          updateFolder(
            {
              ...folder,
              ...resolveFolderData,
            },
            folderId
          )
        );
      else await dispatch(createFolder(resolveFolderData));
      if (onClose) onClose();
    }
  };

  const renderMain = () => {
    return (
      <TemplateDetailsContainer>
        <TemplateDetailsContent width="405px">
          {foldersIsLoading && (
            <LoadingLayer>
              <Spinner />
            </LoadingLayer>
          )}
          <TemplateDetailsHeader>
            {_.has(folder, "id")
              ? t("headerPopup.editFolder")
              : t("headerPopup.createNewFolder")}
          </TemplateDetailsHeader>
          <TemplateDetailsForm height="440px">
            <FormGroup className="container-element">
              <FormLabel aria-label="require">{t("label.client")}</FormLabel>
              <Dropdown
                value={clientId}
                callbackPayload={(e) => {
                  setClientId(e);
                  setAccountId("");
                  setPackageOptions([]);
                  dispatch(fetchPackagesForUserPortal(e));
                }}
                placeholder={t("placeholder.client")}
                width="360px"
                options={clientOptions}
                search
                loading={clientIsLoading}
                disabled={clientIsLoading}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.package")}</FormLabel>
              <Dropdown
                value={accountId}
                callbackPayload={(value: string) => setAccountId(value)}
                placeholder={t("placeholder.package")}
                width="360px"
                options={packageOptions}
                search
                loading={accountIsLoading}
                disabled={accountIsLoading || _.isEmpty(clientId)}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.folderName")}
              </FormLabel>
              <Input
                value={folderName}
                onChangeHandler={(e: any) => setFolderName(e.target.value)}
                placeholder={t("placeholder.folderName")}
                width="360px"
              />
            </FormGroup>
          </TemplateDetailsForm>
          <ButtonField>
            <Button onClick={() => onSubmit()} buttonMargin="10px 10px 10px 0">
              {_.has(folder, "id") ? t("button.save") : t("button.create")}
            </Button>
            <Button
              onClick={() => onClose && onClose()}
              buttonMargin="10px 10px 10px 0"
            >
              {t("button.cancel")}
            </Button>
          </ButtonField>
        </TemplateDetailsContent>
      </TemplateDetailsContainer>
    );
  };
  return renderMain();
};

export default FolderDetails;
