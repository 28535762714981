import ActionTypes from "constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  posts: [],
  post: {},
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.POST_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.POST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CREATE_POST_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.UPDATE_POST_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_POST_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        posts: payload,
      };
    case ActionTypes.SEARCH_POST_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        posts: payload,
      };
    case ActionTypes.GET_POST_BY_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        post: payload,
      };
    case ActionTypes.ACTIVATE_POST_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.DEACTIVATE_POST_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
