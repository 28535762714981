import Api from "./Base";
import { API } from "constants/Constants";

const { LOGS } = API;

export function fetchLogs() {
  return Api.get(LOGS.BASIC);
}

export function getLogByID(id: any) {
  return Api.get(`${LOGS.BASIC}/${id}`);
}

export function searchLogs(data: any) {
  return Api.get(LOGS.BASIC, data);
}
