import styled from "styled-components";
import ColorName from "constants/Variables";
import { device } from "constants/Devices";

interface SectionProps {
  colSize?: number;
  gridTemplate?: number;
  direction?: string;
  padding?: string;
  background?: string;
  margin?: string;
  buttonMargin?: string;
  color?: string;
  flex?: string;
  disable?: boolean;
  buttonWidth?: string;
  column?: number;
  gridGap?: number;
  flexWrap?: boolean;
  maxWidth?: string;
  width?: string;
  height?: string;
}

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GridCol = styled.div`
  width: ${(props: SectionProps) =>
    props.colSize
      ? `${(100 / (props.gridTemplate || 5)) * props.colSize}% !important`
      : "0"};
  flex-direction: ${(props: SectionProps) => props.direction || "row"};
  @media ${device.tablet} {
    width: 100% !important;
  }

  &.custom__width {
    min-width: 155px;
    @media ${device.laptop} {
      min-width: 155px;
    }
    @media ${device.tablet} {
      width: 155px !important;
    }
  }
`;

const FlexGridFifth = styled.div`
  display: flex;
  padding: ${(props: SectionProps) => props.padding || "1em"};

  ${GridCol} {
    width: 20%;
  }
  @media ${device.tablet} {
    flex-direction: column;
    width: 100%;
    place-content: center;
    padding: 3em 0em;
  }
`;

const FlexGridSixth = styled.div`
  display: flex;
  padding: ${(props: SectionProps) => props.padding || "0em"};

  ${GridCol} {
    width: 16.7%;
  }
  &.base__line {
    align-items: baseline;
  }
`;

const Link = styled.a`
  text-decoration: none;
`;

const Button = styled.button`
  border-radius: 5px;
  border: 1px solid ${ColorName.spindle};
  box-shadow: 0px 0px 4px rgb(62 138 182 / 25%);
  background: ${(props: SectionProps) =>
    props.background ? props.background : ColorName.burntSienna};
  color: ${(props: SectionProps) =>
    props.disable ? ColorName.spindle : ColorName.white};
  width: ${(props: SectionProps) => props.buttonWidth || "max-content"};
  min-width: 120px;
  margin: ${(props: SectionProps) => props.buttonMargin || "unset"};
  padding: 8px 12px;
  height: 40px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  font-family: "Be Vietnam";
  position: relative;
  text-transform: uppercase;
  &:hover {
    background: ${(props: SectionProps) =>
      props.disable ? ColorName.white : ColorName.piper};
  }
  &.form-button {
    width: 100%;
  }
  &.loading:hover {
    background: ${(props: SectionProps) =>
      props.background ? props.background : ColorName.burntSienna};
  }
  &:disabled:hover {
    cursor: not-allowed;
    background: ${(props: SectionProps) =>
      props.background ? props.background : ColorName.burntSienna};
  }
`;

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: ${(props: SectionProps) =>
    props.column ? `repeat(${props.column}, 1fr)` : `repeat(2, 1fr)`};

  grid-gap: ${(props: SectionProps) =>
    props.gridGap ? `${props.gridGap}px` : "10px"};
`;

const FlexHorizontal = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: ${(props: SectionProps) => (props.flexWrap ? "wrap" : "unset")};

  &.align-center {
    align-items: center;
  }
`;

const FlexVertical = styled.div`
  display: flex;
  flex-direction: column;

  &.align-center {
    align-items: center;
  }
`;

const ContainerSearch = styled.div`
  position: relative;
  &.loading {
    &:before {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.35);
      content: "";
      position: absolute;
      z-index: 2;
    }
  }
  &.post-search-field {
    position: sticky;
    top: -32px;
    background: white;
    z-index: 1;
  }
`;

const FlexWrapRow = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  flex-wrap: wrap;
`;

const Spinner = styled.div`
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  width: 20px;
  height: 20px;
  border-top: 2px solid ${ColorName.burntSienna};
  border-right: 2px solid transparent;
  border-radius: 50%;
  animation: rotation 0.8s linear infinite;
`;

const LoadingLayer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const FormDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${(props: SectionProps) => props.maxWidth || "630px"};
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  margin-bottom: 15px;
  width: 100%;

  .rct-node-icon {
    display: none;
  }
`;

const FormLabel = styled.label`
  font-weight: bold;
  font-size: 16px;
  color: ${ColorName.william};
  margin-bottom: 8px;
  display: inline-block;
  &[aria-label="require"] {
    &::after {
      content: "*";
      margin-left: 4px;
      color: red;
    }
  }

  & .description {
    font-size: 14px;
    font-weight: 600;
  }
`;

const FormInline = styled.div`
  flex: 1;
  display: flex;

  ${FormGroup} {
    &:nth-child(2) {
      margin-left: 30px;
    }
  }
`;

const ButtonField = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props: SectionProps) => props.margin || "10px"};
`;

const TitlePartOfForm = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 41px;
  color: ${ColorName.william};
  border-top: 1px solid ${ColorName.william};
  width: 100%;
  padding-top: 10px;
  margin: 15px 0;

  &[aria-label="require"] {
    &::after {
      content: "*";
      margin-left: 4px;
      color: red;
    }
  }
`;

const ImagePreview = styled.img`
  width: ${(props: SectionProps) => props.width || "200px"};
  height: ${(props: SectionProps) => props.height || "200px"};
  object-fit: cover;
  border-radius: 50%;
`;

export {
  GridCol,
  FlexGridSixth,
  FlexGridFifth,
  HorizontalContainer,
  VerticalContainer,
  Link,
  Button,
  GridLayout,
  FlexHorizontal,
  FlexVertical,
  ContainerSearch,
  FlexWrapRow,
  LoadingLayer,
  Spinner,
  FormDetails,
  FormGroup,
  FormInline,
  FormLabel,
  ButtonField,
  TitlePartOfForm,
  ImagePreview,
};
