import React from "react";
import { Icons } from "themes";
import { ContainerFilter } from "./Filter.styles";

interface SectionProps {
  text?: string;
  width?: string;
  margin?: string;
  widthIcon?: boolean;
  onChangeHandler?: (e: any) => void;
  className?: string;
  disabled?: boolean;
}

const Filter: React.FC<SectionProps> = (props: SectionProps) => {
  const {
    text,
    width,
    margin,
    widthIcon = true,
    onChangeHandler,
    className,
    disabled,
  } = props;

  const clickFilter = (e: any) => {
    if (onChangeHandler && !disabled) onChangeHandler(e);
  };

  return (
    <ContainerFilter
      className={`${className} ${disabled ? "disabled" : ""}`}
      width={width}
      margin={margin}
      onClick={(e) => clickFilter(e)}
    >
      {widthIcon && <img src={Icons.filters} alt="Filter" />}
      <span>{text}</span>
    </ContainerFilter>
  );
};

export default Filter;
