/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { RootState } from "reducers";
import { AccountOfClientActions } from "actions";

import TableUltils from "libs/TableUltils";
import ColorName from "constants/Variables";
import history from "../../../history";
import { CONSTANTS } from "constants/Constants";
import { generateFilter, popupAlert } from "libs/Utils";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Table,
  Input,
  Button,
  SelectDatetime,
  Checkbox,
  ContainerSearch,
  FlexWrapRow,
} from "components/Common";
import RouteTypes from "constants/RouteTypes";
import { LogsDetails } from "components/Common/Popup";

const {
  searchAccountsOfClient,
  deactivateAccountOfClient,
  activateAccountOfClient,
  clearPackages,
} = AccountOfClientActions;

const DEFAULT_FILTER = {
  accountName: "",
  accountCode: "",
  startDate: "",
  endDate: "",
  status: CONSTANTS.STATUS.ACTIVE,
  clientId: "",
  limit: 10,
  page: 1,
  sortBy: "updatedAt",
  orderBy: "DESC",
};

interface FilterStructure {
  accountName: string;
  accountCode: string;
  startDate: string;
  endDate: string;
  status: string;
  clientId: string;
  limit: number;
  page: number;
  sortBy: string;
  orderBy: string;
}

const InputStyleProps = {
  margin: "10px 10px 10px 0",
  width: "200px",
};

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "10px 10px 10px 0",
};

const ListPackages = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => {
    return state.Account.isLoading;
  });
  const accounts = useSelector((state: RootState) => {
    return state.Account.accounts;
  });
  const location = useLocation();

  const [logData, setLogData] = useState<any>({});
  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const dataHead = [
    "no",
    "packageCode",
    "packageName",
    "startDate",
    "endDate",
    "status",
    "actions",
  ];
  const dataTable = TableUltils._processDataTable(
    [
      "no",
      "id",
      "accountCode",
      "accountName",
      "startDate",
      "endDate",
      "status",
      "action",
    ],
    _.get(accounts, "items")
  );
  const listColumnCenter = [1, 2, 3, 4, 5, 6, 7];

  useEffect(() => {
    const stateFilter: any = _.get(location, "state.filter");
    const prevRoute = _.get(location, "state.from");
    const resolveFilter = {
      ...filter,
    };
    if (
      stateFilter &&
      prevRoute === RouteTypes.CLIENT_ROUTES.PACKAGES_DETAILS
    ) {
      _.assign(resolveFilter, { ...stateFilter });
      history.replace({ ...history.location, state: {} });
    }
    dispatch(searchAccountsOfClient(resolveFilter));

    return () => {
      dispatch(clearPackages());
    };
  }, []);

  const callbackCurrentPageLink = (page: number) => {
    const newFilter: any = {
      ...filter,
      page,
    };
    setFilter(newFilter);
    dispatch(searchAccountsOfClient(generateFilter(newFilter)));
  };

  const callbackAction = async (action: string, id: string, item: any) => {
    if (action === CONSTANTS.ACTION.LOG)
      setLogData({ logType: "account", rowId: id });

    if (action === CONSTANTS.ACTION.USERS) {
      // dispatch(setUserFilter({ accountId: id }));
      history.push({
        pathname: "/client/users",
        state: {
          accountId: id,
        },
      });
    }
    if (action === CONSTANTS.ACTION.EDIT) {
      history.push({
        pathname: RouteTypes.CLIENT_ROUTES.PACKAGES_DETAILS,
        state: {
          id,
          filter,
        },
      });
    }

    if (action === CONSTANTS.ACTION.DEACTIVATE) {
      const itemStatus = _.get(item, "status");
      const packageName = _.get(item, "accountName");
      const statusLabel =
        itemStatus === CONSTANTS.STATUS.ACTIVE
          ? t("alert.deactive")
          : t("alert.active");
      const content = document.createElement("span");
      content.innerHTML = `${t(
        "alert.areYouSureYouWantTo"
      )}</br> <b>${statusLabel}</b> ${t(
        "alert.thePackage"
      )} <b>${packageName}</b>?`;
      await popupAlert({
        type: "warning",
        title: t("alert.warning"),
        content,
        buttons: true,
        dangerMode: true,
      }).then(async (willContinue) => {
        if (willContinue) {
          if (itemStatus === CONSTANTS.STATUS.ACTIVE)
            await dispatch(deactivateAccountOfClient(id));
          else await dispatch(activateAccountOfClient(id));

          await dispatch(searchAccountsOfClient(generateFilter(filter)));
        }
      });
    }
  };

  const setValueStatus = (status: string) => {
    const newFilter = {
      ...filter,
      status,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(searchAccountsOfClient(generateFilter(newFilter)));
  };

  const onReset = () => {
    setFilter(DEFAULT_FILTER);
    dispatch(searchAccountsOfClient(generateFilter(DEFAULT_FILTER)));
  };

  const _renderTable = () => {
    return (
      <Table
        dataHeader={dataHead}
        dataTable={dataTable}
        fitColumns={[1, 6, 7]}
        listColumnCenter={listColumnCenter}
        hasPagination={true}
        callbackCurrentPageLink={callbackCurrentPageLink}
        metaPagination={accounts.meta}
        listActionItem={[
          CONSTANTS.ACTION.EDIT,
          CONSTANTS.ACTION.DEACTIVATE,
          CONSTANTS.ACTION.LOG,
          CONSTANTS.ACTION.USERS,
        ]}
        callBackAction={callbackAction}
        loading={isLoading}
      />
    );
  };

  const addFilter = () => {
    const newFilter = {
      ...filter,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(searchAccountsOfClient(generateFilter(newFilter)));
  };

  const onCreate = () => {
    history.push({
      pathname: RouteTypes.CLIENT_ROUTES.PACKAGES_DETAILS,
      state: {
        filter,
      },
    });
  };

  const renderMain = () => {
    return (
      <>
        {!_.isEmpty(logData) && (
          <LogsDetails onClose={() => setLogData({})} data={logData} />
        )}
        <ContainerSearch className={isLoading ? "loading" : ""}>
          <FlexWrapRow>
            <Input
              value={_.get(filter, "accountCode")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, accountCode: e.target.value })
              }
              onEnter={() => addFilter()}
              placeholder={t("placeholder.packageCode")}
              {...InputStyleProps}
            />
            <Input
              value={filter?.accountName || ""}
              onChangeHandler={(e) =>
                setFilter({ ...filter, accountName: e.target.value })
              }
              onEnter={() => addFilter()}
              placeholder={t("placeholder.packageName")}
              {...InputStyleProps}
            />
            <SelectDatetime
              callbackPayload={(e) => setFilter({ ...filter, startDate: e })}
              placeholder={t("placeholder.startDate")}
              maxDate={filter?.startDate || new Date().toLocaleString()}
              defaultValue={filter?.startDate || ""}
              {...InputStyleProps}
            />
            <SelectDatetime
              callbackPayload={(e) => setFilter({ ...filter, endDate: e })}
              placeholder={t("placeholder.endDate")}
              minDate={filter?.endDate || new Date().toLocaleString()}
              defaultValue={filter?.endDate || ""}
              {...InputStyleProps}
            />
            <Button onClick={() => addFilter()} {...ButtonStyleProps}>
              {t("button.search")}
            </Button>
            <Button onClick={() => onReset()} {...ButtonStyleProps}>
              {t("button.reset")}
            </Button>
            <Button
              background={ColorName.william}
              onClick={() => onCreate()}
              {...ButtonStyleProps}
            >
              + {t("button.create")}
            </Button>
          </FlexWrapRow>
          <Checkbox
            checked={filter?.status === CONSTANTS.STATUS.INACTIVE}
            context={t("label.showInactivePackages")}
            onChangeHandler={(e: any) =>
              setValueStatus(
                e ? CONSTANTS.STATUS.INACTIVE : CONSTANTS.STATUS.ACTIVE
              )
            }
            margin="10px 0 0 0"
          />
        </ContainerSearch>

        {_renderTable()}
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.CLIENT_ROUTES.PACKAGES_MANAGEMENT}
      title={t("titlePage.packages")}
    />
  );
};

export default ListPackages;
