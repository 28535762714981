import ActionTypes from "constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  accounts: [],
  users: [],
  account: {},
  packagesForUserPotal: [],
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_DEFAULT_ACCOUNT_STATUS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.CLEAR_PACKAGES:
      return {
        ...state,
        accounts: [],
      };
    case ActionTypes.CLEAR_PACKAGE_DETAILS:
      return {
        ...state,
        account: {},
      };
    case ActionTypes.ACCOUNT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.ACCOUNT_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };
    case ActionTypes.CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_ACCOUNT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        accounts: payload,
      };
    case ActionTypes.GET_ACCOUNT_BY_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        account: payload,
      };
    case ActionTypes.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        account: payload,
      };
    case ActionTypes.SEARCH_ACCOUNT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        accounts: payload,
      };
    case ActionTypes.FETCH_USERS_FOR_ACCOUNT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        users: payload,
      };
    case ActionTypes.FETCH_PACKAGES_FOR_USER_PORTAL_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        packagesForUserPotal: payload,
      };
    default:
      return state;
  }
};
