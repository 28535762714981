import styled from "styled-components";
import ColorName from "constants/Variables";

const ContactContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const ContactListUser = styled.div`
  width: 250px;
  height: 100%;
`;

const ListUserHeader = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 15px;
  background: ${ColorName.william};
  span {
    font-size: 16px;
    font-weight: bold;
    color: ${ColorName.white};
  }
`;

const ListUser = styled.div``;

const UserItem = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 10px 15px;
  border-bottom: 1px solid ${ColorName.william};
  border-right: 1px solid ${ColorName.william};
  border-left: 1px solid ${ColorName.william};
  position: relative;

  img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    border: 1px solid ${ColorName.william};
    margin-right: 10px;
  }

  span {
    font-weight: bold;
  }

  p {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &.unread {
    &:before {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      top: calc(50% - 5px);
      right: 10px;
      background: ${ColorName.william};
    }
    p {
      font-weight: bold;
    }
  }

  &:hover,
  &.active {
    cursor: pointer;
    background: ${ColorName.linkWater};
  }
`;

const ContactChatbox = styled.div`
  flex: 1;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
`;

const HeaderChatbox = styled.div`
  display: flex;
  align-items: flex-start;
  height: 40px;
  padding: 5px 10px;

  img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    border: 1px solid ${ColorName.william};
    margin-right: 10px;
  }

  span {
    font-weight: bold;
    font-size: 16px;
  }
`;

const MessageChatbox = styled.div`
  border-top: 1px solid ${ColorName.william};
  flex: 1;
  overflow: auto;
  padding: 15px 10px;
  box-sizing: border-box;
`;

const MessageItem = styled.div`
  width: max-content;
  max-width: 550px;
  padding: 10px;
  border-radius: 5px;
  background: ${ColorName.linkWater};
  border: 1px solid ${ColorName.william};
  margin-bottom: 15px;

  /* &.same-id {
    margin-bottom: 10px;
  } */

  &:last-child {
    margin-bottom: 10px;
  }

  &.self {
    margin-right: unset;
    margin-left: auto;
    background: ${ColorName.william};
    color: ${ColorName.white};
  }

  &.sending {
    opacity: 0.7;
  }

  p {
    margin-top: 0;
    word-break: break-all;
  }

  span {
    font-size: 12px;
    margin-top: 5px;
    margin-right: 10px;
  }
`;

const InputChatbox = styled.div`
  display: flex;
  align-items: flex-start;
  height: 40px;
  border: 1px solid rgba(60, 99, 121, 0.6);
  border-radius: 5px;

  input {
    border: none;
    outline: none;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    font-size: 15px;
    border-radius: 5px;
    padding: 0 10px;

    &:placeholder {
      font-size: 15px;
    }
  }

  & .send-btn {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.disabled {
      opacity: 0.6;
      i {
        &:hover {
          cursor: not-allowed;
        }
      }
    }
    i {
      font-size: 18px;
      color: ${ColorName.william};

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.focus,
  &:hover {
    border: 1px solid ${ColorName.william};
  }
`;

const DateLine = styled.div`
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    flex: 1;
    width: 100%;
    height: 2px;
    background: ${ColorName.william};
  }
  p {
    margin: 0 15px;
    font-size: 16px;
  }
`;

const MessageSkeleton = styled.div`
  max-width: 550px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  position: relative;

  &:last-child {
    margin-bottom: 10px;
  }

  &.self {
    margin-right: unset;
    margin-left: auto;
  }

  p {
    margin-top: 0;
    word-break: break-all;
  }

  span {
    font-size: 12px;
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
  }
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
`;

const UserSkeleton = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 10px 15px;
  border-bottom: 1px solid ${ColorName.william};
  border-right: 1px solid ${ColorName.william};
  border-left: 1px solid ${ColorName.william};

  img {
    height: 30px;
    margin-right: 10px;
  }
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

  & .message_information {
    flex: 1;
    margin-left: 10px;
  }
  span {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
  span.avatar {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  span.username {
    width: 80px;
    height: 30px;
    display: inline-block;
    border-radius: 5px;
  }
  span.message {
    width: 150px;
    height: 20px;
    display: inline-block;
    border-radius: 5px;
  }
`;

const TypingField = styled.div`
  width: 100%;
  height: 0px;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: all ease 0.25s;

  &.typing {
    height: 30px;
  }
`;

const TypingDots = styled.div`
  position: relative;
  text-align: center;
  width: 30px;
  height: 30px;
  .dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 3px;
    background: #303131;
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }

  @keyframes wave {
    0%,
    60%,
    100% {
      transform: initial;
    }

    30% {
      transform: translateY(-8px);
    }
  }
`;

export {
  ContactContainer,
  ContactListUser,
  ContactChatbox,
  HeaderChatbox,
  MessageChatbox,
  InputChatbox,
  ListUserHeader,
  ListUser,
  UserItem,
  MessageItem,
  DateLine,
  UserSkeleton,
  MessageSkeleton,
  TypingField,
  TypingDots,
};
