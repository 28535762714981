/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { Button } from "components/Common";
import { LogsItem, LoadingSkeleton } from "./Logs.styles";
import {
  TemplateDetailsContent,
  TemplateDetailsContainer,
  TemplateDetailsForm,
  ButtonField,
  TemplateDetailsHeader,
  CloseButton,
} from "../Popup.styles";
import { useTranslation } from "react-i18next";

import { LogsActions } from "actions";
import { useDispatch, useSelector } from "react-redux";

const { searchLogs, clearLogs } = LogsActions;

interface SectionProps {
  onClose?: () => void;
  filter?: any;
  data?: any;
  deferData?: any;
}

const LogsDetails: React.FC<SectionProps> = (props: SectionProps) => {
  const dispatch = useDispatch();
  const logsIsLoading = useSelector((state) => _.get(state, "Logs.isLoading"));
  const logs: any = useSelector((state) => _.get(state, "Logs.logs"));
  const { t } = useTranslation("translation");
  const { onClose, data, deferData } = props;
  const [currentData, setCurrentData] = useState<any>([]);
  const [widthContent, setWidthContent] = useState<string>("600px");
  const [heightForm, setHeightForm] = useState<string>("400px");
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  useEffect(() => {
    if (deferData) setCurrentData(deferData);
    else {
      if (_.isEmpty(data) && onClose) onClose();
      else dispatch(searchLogs(data));

      return () => {
        dispatch(clearLogs());
        setFirstLoad(true);
      };
    }
  }, []);

  useEffect(() => {
    const logsItems = _.get(logs, "items");
    if (!_.isEmpty(logsItems) && !deferData) setCurrentData(logsItems);
  }, [logs]);

  useEffect(() => {
    if (!firstLoad) {
      if (logsIsLoading) {
        setWidthContent("600px");
        setHeightForm("400px");
      } else {
        if (_.isEmpty(logs?.items)) {
          setWidthContent("500px");
          setHeightForm("150px");
        }
      }
    }
    setFirstLoad(false);
  }, [logsIsLoading]);

  const _renderLogsDetails = () => {
    const render = _.map(currentData, (item, index) => {
      const { type, createdBy } = item?.data;
      return (
        <LogsItem key={`logs-${index}`}>
          <span>
            {t("label.type")}:{" "}
            <span className={`status-box ${_.lowerCase(type)}`}>{type}</span>
          </span>
          <span>
            {t("label.createdBy")}: {createdBy}
          </span>
        </LogsItem>
      );
    });
    return (
      <>
        {_.isEmpty(currentData) ? (
          <span>{t("label.noLogsFound")}</span>
        ) : (
          render
        )}
      </>
    );
  };

  const _renderLoading = () => {
    const rangeItems = _.range(0, 4);
    const render = _.map(rangeItems, (item, index) => {
      return (
        <LoadingSkeleton key={`log-loading-${index}`}>
          <label htmlFor=""></label>
          <span></span>
          <p></p>
        </LoadingSkeleton>
      );
    });
    return render;
  };

  return (
    <TemplateDetailsContainer>
      <TemplateDetailsContent width={widthContent}>
        <TemplateDetailsHeader>
          {t("headerPopup.logs")}
          <CloseButton onClick={() => onClose && onClose()}>
            <i className="fas fa-times" />
          </CloseButton>
        </TemplateDetailsHeader>
        <TemplateDetailsForm height={heightForm}>
          {logsIsLoading ? _renderLoading() : _renderLogsDetails()}
        </TemplateDetailsForm>
        <ButtonField>
          <Button
            type="submit"
            onClick={() => onClose && onClose()}
            buttonWidth="100px"
          >
            {t("button.close")}
          </Button>
        </ButtonField>
      </TemplateDetailsContent>
    </TemplateDetailsContainer>
  );
};

export default LogsDetails;
