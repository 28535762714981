import ActionTypes from "constants/ActionTypes";
import API from "api";
import {
  saveToken,
  saveUserName,
  saveUserData,
  clearAllSavedData,
  saveClientId,
  saveRememberMe,
  saveRefreshToken,
} from "libs/Utils";
import history from "../history";
import _ from "lodash";
import ROUTES from "constants/RouteTypes";
import { CONSTANTS } from "constants/Constants";
import { responseAlert } from "libs/alert";

const setDefaultLoginResult = () => {
  return {
    type: ActionTypes.SET_DEFAULT_LOGIN_RESULT,
  };
};

const isLogged = () => {
  return {
    type: ActionTypes.IS_LOGGED,
  };
};

const refreshTokenSuccess = () => {
  return {
    type: ActionTypes.REFRESH_TOKEN_SUCCESS,
  };
};

const refreshTokenHasError = () => {
  return {
    type: ActionTypes.REFRESH_TOKEN_HAS_ERROR,
  };
};

const refreshTokenIsLoading = () => {
  return {
    type: ActionTypes.REFRESH_TOKEN_IS_LOADING,
  };
};

const refreshToken = (refresh_token: string) => {
  return async (dispatch: any) => {
    dispatch(refreshTokenIsLoading());
    await API.refreshToken(refresh_token)
      .then(async (res: any) => {
        const payload = res.data.payload;
        if (_.isEmpty(payload)) {
          const { accessToken } = res.data.payload;
          const token = _.get(accessToken, "token");
          await saveToken(token);
          dispatch(refreshTokenSuccess());
        } else {
          dispatch(refreshTokenHasError());
        }
      })
      .catch((e: any) => {
        if (!_.isEmpty(e)) responseAlert({ res: e });
        dispatch(loginHasError());
      });
  };
};

const loginHasError = () => {
  return {
    type: ActionTypes.LOGIN_HAS_ERROR,
  };
};

const loginIsLoading = () => {
  return {
    type: ActionTypes.LOGIN_IS_LOADING,
  };
};

const login = (data: any) => {
  return async (dispatch: any) => {
    dispatch(loginIsLoading());
    if (!data.account || !data.password) {
      responseAlert({ res: { message: "Found empty field(s)" } });
      dispatch(loginHasError());
      return;
    }
    await API.login(data)
      .then(async (res: any) => {
        if (res?.data?.status) {
          const { accessToken, user } = res.data.payload;
          const token = _.get(accessToken, "token");
          const refreshToken = _.get(accessToken, "refresh_token");
          const omitUserData = _.omit(user, ["password", "userRequest"]);
          const clientId = _.get(user, "clientId.id");
          if (clientId) saveClientId(clientId);
          await saveToken(token);
          await saveRefreshToken(refreshToken);
          await saveUserName(data.email);
          await saveUserData({ ...omitUserData });

          saveRememberMe(data?.rememberMe);

          await API.setToken(token);
          const { userType } = omitUserData;
          await dispatch(isLogged());
          if (userType === CONSTANTS.USER_TYPE.ADMIN)
            history.push(ROUTES.ADMIN_ROUTES.POST);
          if (userType === CONSTANTS.USER_TYPE.CLIENT)
            history.push(ROUTES.CLIENT_ROUTES.PACKAGES_MANAGEMENT);
          if (userType === CONSTANTS.USER_TYPE.USER)
            history.push(ROUTES.USER_ROUTES.USER_ARTICLES);
        } else {
          const message = _.get(res, "data.message");
          if (message) responseAlert({ message });
          dispatch(loginHasError());
        }
      })
      .catch((e: any) => {
        if (!_.isEmpty(e)) responseAlert({ res: e });
        dispatch(loginHasError());
      });
  };
};

const logout = () => {
  clearAllSavedData();
  history.push(ROUTES.AUTHENTICATION_ROUTES.AUTHENTICATION);
  return {
    type: ActionTypes.LOGOUT,
  };
};

const logoutWithOutRedirect = () => {
  clearAllSavedData();
  return {
    type: ActionTypes.LOGOUT,
  };
};

const userLogOut = () => {
  clearAllSavedData();
  history.push(ROUTES.USER_ROUTES.USER_ARTICLES);
  return {
    type: ActionTypes.LOGOUT,
  };
};

const loginAs = (userId: string) => {
  return async (dispatch: any) => {
    dispatch(loginIsLoading());
    await API.loginAs(userId)
      .then(async (res: any) => {
        const { status, payload, message } = res;
        if (status && payload) {
          await dispatch(logout());
          const { accessToken, user } = payload;
          const token = _.get(accessToken, "token");
          const refreshToken = _.get(accessToken, "refresh_token");
          const omitUserData = _.omit(user, ["password", "userRequest"]);
          const clientId = _.get(user, "clientId.id");
          if (clientId) saveClientId(clientId);
          await dispatch(isLogged());
          await saveToken(token);
          await saveRefreshToken(refreshToken);
          await saveUserData({ ...omitUserData });
          await API.setToken(token);
          const { userType } = omitUserData;
          if (userType === CONSTANTS.USER_TYPE.ADMIN)
            history.push(ROUTES.ADMIN_ROUTES.HOMEPAGE);
          if (userType === CONSTANTS.USER_TYPE.CLIENT)
            history.push(ROUTES.CLIENT_ROUTES.PACKAGES_MANAGEMENT);
          if (userType === CONSTANTS.USER_TYPE.USER)
            history.push(ROUTES.USER_ROUTES.USER_ARTICLES);
          responseAlert({ label: "Login as", res });
        } else {
          if (message) responseAlert({ message });
          dispatch(loginHasError());
        }
      })
      .catch((e: any) => {
        if (!_.isEmpty(e)) responseAlert({ res: e });
        dispatch(loginHasError());
      });
  };
};

export {
  isLogged,
  loginHasError,
  loginIsLoading,
  login,
  logout,
  logoutWithOutRedirect,
  userLogOut,
  setDefaultLoginResult,
  loginAs,
  refreshToken,
};
