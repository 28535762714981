import styled from "styled-components";
import ColorName from "constants/Variables";

const LogsItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-bottom: 1px solid ${ColorName.william};

  span {
    display: flex;
    align-items: center;
  }

  & .status-box {
    padding: 4px 10px;
    border-radius: 4px;
    margin-left: 5px;
    text-transform: uppercase;

    &.update {
      border: 1px solid #96c2ed;
      color: #96c2ed;
      background: rgba(150, 194, 237, 0.2);
    }
    &.create {
      border: 1px solid #fdbb1e;
      color: #fdbb1e;
      background: rgba(253, 187, 30, 0.2);
    }
    &.activate {
      border: 1px solid #5ccd89;
      color: #5ccd89;
      background: rgba(92, 205, 137, 0.2);
    }
    &.deactivate {
      border: 1px solid #ec5f56;
      color: #ec5f56;
      background: rgba(236, 95, 86, 0.2);
    }
  }
  &:last-child {
    border-bottom: unset;
  }
`;

const LoadingSkeleton = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid ${ColorName.william};
  &:last-child {
    border-bottom: unset;
  }
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
  label,
  span,
  p {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  label {
    width: 60px;
    height: 25px;
    display: inline-block;
    margin-right: 10px;
  }
  span {
    width: 100px;
    height: 30px;
    display: inline-block;
  }
  p {
    width: 300px;
    height: 30px;
    margin: 5px 0 0 0;
  }
`;

export { LogsItem, LoadingSkeleton };
