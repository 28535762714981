import { setToken, getToken } from "./Base";
import * as AuthenticationApis from "./Authentication.api";
import * as CategoryApis from "./Categories.api";
import * as AccountApis from "./Account.api";
import * as RoleApis from "./Role.api";
import * as FolderApis from "./Folder.api";
import * as SourceSettingApis from "./SourcesSettings.api";
import * as FileRecordApis from "./FileRecord.api";
import * as DataRecordApis from "./DataRecord.api";
import * as LogApis from "./Logs.api";
import * as UserApis from "./Users.api";
import * as ReportApis from "./Reports.api";
import * as ClientApis from "./Client.api";
import * as FilterApis from "./Filter.api";
import * as EmailDeliveryApis from "./EmailDelivery.api";
import * as DashboardApis from "./Dashboard.api";
import * as EmailTemplateApis from "./EmailTemplate.api";
import * as ClientUserApis from "./ClientUser.api";
import * as MemberApis from "./Member.api";
import * as AccountOfClientApis from "./AccountOfClient.api";
import * as UserOfClientApis from "./UserOfClient.api";
import * as MessageApis from "./Message.api";
import * as ConfigApis from "./Config.api";

export default {
  setToken,
  getToken,
  ...AuthenticationApis,
  ...CategoryApis,
  ...AccountApis,
  ...RoleApis,
  ...FolderApis,
  ...SourceSettingApis,
  ...FileRecordApis,
  ...DataRecordApis,
  ...LogApis,
  ...UserApis,
  ...ReportApis,
  ...ClientApis,
  ...FilterApis,
  ...EmailDeliveryApis,
  ...DashboardApis,
  ...EmailTemplateApis,
  ...ClientUserApis,
  ...MemberApis,
  ...AccountOfClientApis,
  ...UserOfClientApis,
  ...MessageApis,
  ...ConfigApis,
};
