/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import _ from "lodash";
import dayjs from "dayjs";
import parse from "html-react-parser";

import { Button } from "components/Common";
import {
  TemplateDetailsContent,
  TemplateDetailsContainer,
  TemplateDetailsForm,
  ButtonField,
  TemplateDetailsHeader,
  DataPostItem,
  PreviewContent,
  DatatPostContent,
  LoadingLayer,
  Spinner,
} from "../Popup.styles";
import { useTranslation } from "react-i18next";

interface SectionProps {
  onClose(): void;
  data: any[];
  loading?: boolean;
}

const ResultFilterTest = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { onClose, data, loading } = props;

  const handleClose = (e: any) => {
    const className = e.target.classList;
    if (className.contains("blur_layer") && onClose) onClose();
  };

  const renderOriginalLink = (link: string) => {
    if (link.length < 50) return link;
    const chunkLink = _.chunk(link, 25);
    const resolveLink = `${_.join(chunkLink[0], "")}...${
      chunkLink.length > 2 ? _.join(chunkLink[chunkLink.length - 1], "") : ""
    }`;
    return resolveLink;
  };

  const generateContent = (content: string, keywords: string[]) => {
    if (_.isEmpty(keywords)) return content;
    let resolveContent = content;
    _.map(keywords, (keyword) => {
      const splitKeywords = keyword.trim().split("");
      let resolveKeyword = "";
      _.forEach(splitKeywords, (item) => {
        if (item.trim())
          resolveKeyword += `(${item.toLowerCase()}|${item.toUpperCase()})`;
        else resolveKeyword += " ";
      });
      const newContent = _.replace(
        resolveContent,
        new RegExp(resolveKeyword, "g"),
        `<mark>${keyword}</mark>`
      );
      resolveContent = newContent;
    });
    return parse(resolveContent);
  };

  const renderVideo = (item: any) => {
    let originalLink = _.get(item, "originalLink");
    if (!originalLink) return null;
    const isYoutube = _.includes(originalLink, "youtube");
    if (isYoutube) originalLink = originalLink.replace("watch?v=", "embed/");

    return (
      <iframe
        width="100%"
        height="480"
        src={originalLink}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    );
  };

  const renderPostData = () => {
    if (_.isEmpty(data)) return;
    const render = _.map(data, (item, index) => {
      const {
        title,
        content,
        publishedAt,
        createdAt,
        source,
        category,
        client,
        account,
        originalLink,
      } = item;
      const link = _.get(source, "link");
      const mediaType = _.get(source, "mediaType");
      const sourceName = _.get(source, "name");
      const name = _.get(category, "name");
      const clientName = _.get(client, "clientName");
      const accountName = _.get(account, "accountName");
      const listKeywords = _.get(item, "listKeywords");

      const timeValue = dayjs(new Date(createdAt));
      let resultCreatedAt;
      if (timeValue.isValid()) resultCreatedAt = createdAt;
      else {
        if (_.includes(createdAt, "a.m."))
          resultCreatedAt = _.replace(createdAt, /a.m./g, "am");
        if (_.includes(createdAt, "p.m."))
          resultCreatedAt = _.replace(createdAt, /p.m./g, "pm");
      }

      return (
        <DataPostItem key={`post-item-${index}`}>
          <DatatPostContent>
            <span style={{ fontWeight: "bold" }}>
              {_.isObject(title) ? _.get(title, "en") : title}
            </span>
            <div>
              <span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={originalLink ? originalLink : link}
                >
                  {sourceName}
                </a>{" "}
                {t("label.createdAt")}{" "}
                {dayjs(resultCreatedAt).format("MM-DD-YYYY")}
              </span>
            </div>
            <PreviewContent>
              {mediaType === "tv"
                ? renderVideo(item)
                : generateContent(content, listKeywords)}
            </PreviewContent>
            <div>
              {clientName && (
                <span>
                  <b>{t("label.client")}:</b> {clientName}
                </span>
              )}
              {accountName && (
                <span>
                  <b>{t("label.package")}:</b> {accountName}
                </span>
              )}
              {name && (
                <span>
                  <b>{t("label.category")}:</b>{" "}
                  {_.isObject(name) ? _.get(name, "en") : name}
                </span>
              )}
              <span>
                <b>{t("label.publishedDate")}:</b>{" "}
                {dayjs(publishedAt).format("MM-DD-YYYY")}
              </span>
            </div>
          </DatatPostContent>
        </DataPostItem>
      );
    });
    return render;
  };

  const renderMain = () => {
    return (
      <TemplateDetailsContainer
        className="blur_layer"
        onClick={(e: any) => handleClose(e)}
      >
        <TemplateDetailsContent
          width={`${_.isEmpty(data) ? "400px" : "800px"}`}
        >
          <TemplateDetailsHeader>
            {t("headerPopup.testResults")}
          </TemplateDetailsHeader>
          <TemplateDetailsForm
            height={`${_.isEmpty(data) ? "200px" : "400px"}`}
          >
            {loading ? (
              <LoadingLayer>
                <Spinner />
              </LoadingLayer>
            ) : _.isEmpty(data) ? (
              <h3>{t("label.resultNotFound")}</h3>
            ) : (
              renderPostData()
            )}
          </TemplateDetailsForm>
          <ButtonField>
            <Button
              type="submit"
              onClick={() => onClose && onClose()}
              buttonWidth="100px"
              buttonMargin="10px 10px 10px 0"
            >
              {t("button.close")}
            </Button>
          </ButtonField>
        </TemplateDetailsContent>
      </TemplateDetailsContainer>
    );
  };
  return renderMain();
};

export default ResultFilterTest;
