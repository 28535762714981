import ActionTypes from "constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  isHiddenSuccess: false,
  isHiddenError: false,
  isSendMailSuccess: false,
  dataRecords: [],
  dataRecord: {},
  dataForUser: [],
  sendPostStatus: 0,
  sendAttachStatus: 0,
  submitSuccess: 0,
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.CLEAR_DATA_RECORD_FOR_USER:
      return {
        ...state,
        dataForUser: {},
      };
    case ActionTypes.CLEAR_DATA_RECORD:
      return {
        ...state,
        dataRecord: {},
      };
    case ActionTypes.CLEAR_DATA_RECORD:
      return {
        ...state,
        dataRecord: {},
      };
    case ActionTypes.CLEAR_HIDE_POST:
      return {
        ...state,
        isHiddenSuccess: false,
        isHiddenError: false,
        isSendMailSuccess: false,
      };
    case ActionTypes.DATA_RECORD_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        sendAttachStatus: 1,
        submitSuccess: 1,
      };
    case ActionTypes.DATA_RECORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.DATA_RECORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        dataRecords: [],
        dataForUser: [],
      };
    case ActionTypes.SET_DEFAULT_SEND_POST_STATUS:
      return {
        ...state,
        sendPostStatus: 0,
        sendAttachStatus: 0,
        submitSuccess: 0,
      };
    case ActionTypes.SEND_SELECTED_POST_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        hasError: false,
        isSendMailSuccess: true,
        sendPostStatus: 2,
      };
    case ActionTypes.SEND_SELECTED_POST_FAILURE:
      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        hasError: true,
        isSendMailSuccess: true,
        sendPostStatus: 1,
      };
    case ActionTypes.CREATE_DATA_RECORD_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        submitSuccess: 2,
      };
    case ActionTypes.FETCH_DATA_RECORD_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        dataRecords: payload,
      };
    case ActionTypes.SEARCH_DATA_RECORD_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        dataRecords: payload,
      };
    case ActionTypes.GET_DATA_RECORD_BY_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        dataRecord: payload,
      };
    case ActionTypes.UPDATE_DATA_RECORD_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        dataRecord: payload,
        submitSuccess: 2,
      };

    case ActionTypes.FILTER_DATA_RECORD_IN_POST_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        dataRecord: payload,
      };
    case ActionTypes.GET_DATA_RECORD_FOR_FOLDER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        dataRecord: payload,
      };
    case ActionTypes.PREVIEW_DATA_RECORD_BEFORE_CREATE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        dataRecord: payload,
      };
    case ActionTypes.SEND_REPORT_BY_EMAIL_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_DATA_RECORDS_FOR_USER_PORTAL_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        dataForUser: payload,
      };
    case ActionTypes.SEND_ATTACHMENT_TO_MAIL_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        sendAttachStatus: 2,
      };

    case ActionTypes.HIDDEN_POST_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        isHiddenSuccess: true,
      };
    case ActionTypes.HIDDEN_POST_FAILURE:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        isHiddenSuccess: false,
      };

    case ActionTypes.UNHIDDEN_POST_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.UNHIDDEN_POST_FAILURE:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.HIDDEN_MULTIPLE_POSTS_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isHiddenSuccess: true,
        isLoading: false,
      };
    case ActionTypes.HIDDEN_MULTIPLE_POSTS_FAILURE:
      return {
        ...state,
        isSuccess: true,
        isHiddenSuccess: false,
        isLoading: false,
      };
    default:
      return state;
  }
};
