import styled from "styled-components";
import ColorName from "../../../constants/Variables";

interface SectionProps {
  width?: string;
  height?: string;
  background?: string;
}

const AvatarContainer = styled.div`
  position: relative;
  width: ${(props: SectionProps) => props.width || "200px"};
  height: ${(props: SectionProps) => props.height || "200px"};
  background: url(${(props: SectionProps) => props.background});
  border-radius: 50%;
  border: 2px solid ${ColorName.william};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  input {
    display: none;
  }
`;

const EditButton = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 17px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50%;
  transition: all ease 0.25s;
  color: ${ColorName.william};
  border: 2px solid ${ColorName.william};
  background: ${ColorName.white};
  &:hover {
    cursor: pointer;
    background: ${ColorName.william};
    color: white;
  }
`;

export { AvatarContainer, EditButton };
