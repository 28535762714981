import styled from "styled-components";

const GroupMessagesResponse = styled.div``;

const FolderLabel = styled.div`
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px solid black;
`;

const PostLabel = styled.div`
  width: 500px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

const Status = styled.div`
  padding: 3px 10px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;

  &.success {
    color: #5ccd89;
    border-color: #5ccd89;
  }
  &.warning {
    color: #ec5f56;
    border-color: #ec5f56;
  }
  &.error {
    color: #96c2ed;
    border-color: #96c2ed;
  }
`;

const FolderItem = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  height: 40px;
  span {
    margin: 0 15px;
  }
`;

export { FolderLabel, GroupMessagesResponse, PostLabel, Status, FolderItem };
