import styled from "styled-components";
import ColorName from "constants/Variables";

const DefaultLayoutContainer = styled.div`
  * {
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: ${ColorName.linkWater};
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: ${ColorName.william};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${ColorName.william};
      border: 2px solid #555555;
    }
  }
  position: relative;
`;

const Container = styled.div`
  display: flex;
`;

const ContainerMenu = styled.div`
  display: flex;
  flex-direction: column;
  background: ${ColorName.white};
`;

const ContainerContent = styled.div`
  display: block;
  width: 100%;
  background: ${ColorName.white};
  overflow-x:auto;
`;

const Content = styled.div`
  height: calc(100vh - 81px);
  display: flex;
  flex-direction: column;
  padding: 2em;
  overflow: auto;
  box-sizing: border-box;
`;

const UserContent = styled.div`
  width: 100%;
  min-height: calc(100vh - 120px);
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
`;

const UserContainer = styled.div`
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export {
  DefaultLayoutContainer,
  Container,
  ContainerMenu,
  Content,
  ContainerContent,
  UserContent,
  UserContainer,
};
