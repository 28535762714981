/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";

import { RootState } from "reducers";

import {
  FolderLabel,
  GroupMessagesResponse,
  PostLabel,
  Status,
  FolderItem,
} from "./Folders.styles";
import { Button } from "components/Common";
import {
  TemplateDetailsContainer,
  TemplateDetailsContent,
  TemplateDetailsHeader,
  TemplateDetailsForm,
  ButtonField,
} from "../Popup.styles";
import { useTranslation } from "react-i18next";

interface SectionProps {
  onClose(): void;
}

const AddPostResult = (props: SectionProps) => {
  const { t } = useTranslation("translation");

  const { onClose } = props;
  const responseMessages = useSelector((state: RootState) => {
    return state.Folders.responseMessages;
  });

  useEffect(() => {
    return () => {
      // dispatch(clearDataRecord());
    };
  }, []);

  const handleClose = (e: any) => {
    const className = e.target.classList;
    if (className.contains("blur_layer") && onClose) onClose();
  };

  const renderClassStatus = (status: string) => {
    if (_.includes(_.lowerCase(status), "successful")) return "success";
    if (_.includes(_.lowerCase(status), "exist")) return "warning";
    return "error";
  };

  const renderLabelStatus = (status: string) => {
    if (_.includes(_.lowerCase(status), "successful"))
      return t("label.addPostSuccess");
    if (_.includes(_.lowerCase(status), "exist"))
      return t("label.existPostInFolder");
    return t("alert.addPostFailed");
  };

  const renderResponseMessages = () => {
    const render = _.map(responseMessages, (message, key) => {
      if (key === "errorUUID") return;
      const renderMessage = _.map(message, (item, index) => {
        const classStatus = renderClassStatus(_.get(item, "status"));
        return (
          <FolderItem key={`message-${key}-${index}`}>
            <b>{index + 1}. </b>
            <PostLabel title={_.get(item, "title")}>
              {_.get(item, "title")}
            </PostLabel>
            <span>-</span>
            <Status className={classStatus}>
              {renderLabelStatus(_.get(item, "status"))}
            </Status>
          </FolderItem>
        );
      });
      return (
        <GroupMessagesResponse key={`message-${key}`}>
          <FolderLabel>{key}</FolderLabel>
          <div>{renderMessage}</div>
        </GroupMessagesResponse>
      );
    });
    return render;
  };

  return (
    <TemplateDetailsContainer
      className="blur_layer"
      onClick={(e: any) => handleClose(e)}
    >
      <TemplateDetailsContent width="800px">
        <TemplateDetailsHeader>
          {t("headerPopup.postStatus")}
        </TemplateDetailsHeader>
        <TemplateDetailsForm height="400px">
          {renderResponseMessages()}
        </TemplateDetailsForm>
        <ButtonField>
          <Button
            onClick={() => onClose()}
            buttonWidth="100px"
            buttonMargin="0 0 0 10px"
          >
            {t("button.close")}
          </Button>
        </ButtonField>
      </TemplateDetailsContent>
    </TemplateDetailsContainer>
  );
};

export default AddPostResult;
