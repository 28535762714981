import styled from "styled-components";
import ColorName from "constants/Variables";

const ContainerDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: 1140px;
  flex-wrap: wrap;
  flex-direction: column;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

  &.loading .group-details {
    span {
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      border-radius: 5px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
      height: 25px;
      color: transparent;
      &.title {
        width: 550px;
      }
      &.author,
      &.time {
        width: 200px;
      }
      &.category {
        width: 150px;
      }
    }
  }

  & .group-details {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: ${ColorName.william};
    display: flex;
    & label {
      width: 120px;
      line-height: 2;
      font-weight: 500;
    }
    & span {
      color: ${ColorName.william};
      font-weight: 700;
    }
    &.content {
      display: unset;
      align-items: unset;
      margin-top: 2em;
      padding: 1em;
      border: 1px solid ${ColorName.nepal};
      border-radius: 30px;
      background: ${ColorName.zircon};
      display: flex;
      flex-direction: column;
      & span {
        display: flex;
        border-bottom: 1px solid ${ColorName.spindle};
        padding: 1em;
      }
      & .wrap-content {
        padding: 1em;
        display: flex;
        max-height: 60vh;
        overflow: auto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
        color: ${ColorName.regentGray};
        border: none;
        resize: none;
        outline: none;
        min-height: 40vh;
      }
    }
  }
`;

export { ContainerDetails };
