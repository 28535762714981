import ActionTypes from "constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  distributionDeliveries: [],
  distributionDelivery: {},
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.DISTRIBUTION_DELIVERY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.DISTRIBUTION_DELIVERY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CREATE_DISTRIBUTION_DELIVERY_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.UPDATE_DISTRIBUTION_DELIVERY_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_DISTRIBUTION_DELIVERY_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        distributionDeliveries: payload,
      };
    case ActionTypes.SEARCH_DISTRIBUTION_DELIVERY_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        distributionDeliveries: payload,
      };
    case ActionTypes.GET_DISTRIBUTION_DELIVERY_BY_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        distributionDelivery: payload,
      };
    case ActionTypes.ACTIVATE_DISTRIBUTION_DELIVERY_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.DEACTIVATE_DISTRIBUTION_DELIVERY_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
