import ActionTypes from "constants/ActionTypes";
import API from "api";
import _ from "lodash";
import { responseAlert } from "libs/alert";
import history from "../history";

const clearSources = () => {
  return {
    type: ActionTypes.CLEAR_SOURCES,
  };
};

const clearSourceDetails = () => {
  return {
    type: ActionTypes.CLEAR_SOURCE_DETAILS,
  };
};

const sourceSettingFail = () => {
  return {
    type: ActionTypes.SOURCE_SETTING_FAILURE,
  };
};

const sourceSettingLoading = () => {
  return {
    type: ActionTypes.SOURCE_SETTING_REQUEST,
  };
};

const sourceSettingSuccess = () => {
  return {
    type: ActionTypes.SOURCE_SETTING_SUCCESS,
  };
};

const createSourceSettingSuccess = () => {
  return {
    type: ActionTypes.CREATE_SOURCE_SETTING_SUCCESS,
  };
};

const updateSourceSettingSuccess = (payload = {}) => {
  return {
    type: ActionTypes.UPDATE_SOURCE_SETTING_SUCCESS,
    payload,
  };
};

const fetchSourceSettingsSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_SOURCE_SETTING_SUCCESS,
    payload,
  };
};

const getSourceSettingByIdSuccess = (payload = {}) => {
  return {
    type: ActionTypes.GET_SOURCE_SETTING_BY_ID_SUCCESS,
    payload,
  };
};

const createSourceSetting = (data: any, filter: any) => {
  return async (dispatch: any) => {
    dispatch(sourceSettingLoading());
    await API.addSourceSetting(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          await responseAlert({ res, label: "Source created" });
          dispatch(createSourceSettingSuccess());
          history.push(filter);
        } else {
          responseAlert({ res });
          dispatch(sourceSettingFail());
        }
      })
      .catch((e: any) => {
        dispatch(sourceSettingFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchSourceSettings = () => {
  return async (dispatch: any) => {
    dispatch(sourceSettingLoading());
    await API.fetchSourceSettings()
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchSourceSettingsSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(sourceSettingFail());
        }
      })
      .catch((e: any) => {
        dispatch(sourceSettingFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchAllSource = (data?: any) => {
  return async (dispatch: any) => {
    dispatch(sourceSettingLoading());
    await API.getAllSources(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchSourceSettingsSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(sourceSettingFail());
        }
      })
      .catch((e: any) => {
        dispatch(sourceSettingFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getSourceSettingByID = (id: string) => {
  return async (dispatch: any) => {
    dispatch(sourceSettingLoading());
    await API.getSourceSettingByID(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(getSourceSettingByIdSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(sourceSettingFail());
        }
      })
      .catch((e: any) => {
        dispatch(sourceSettingFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const searchSourceSettings = (data: any) => {
  return async (dispatch: any) => {
    dispatch(sourceSettingLoading());
    await API.searchSourceSettings(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchSourceSettingsSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(sourceSettingFail());
        }
      })
      .catch((e: any) => {
        dispatch(sourceSettingFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const updateSourceSetting = (data: any, id: any, filter: any) => {
  return async (dispatch: any) => {
    dispatch(sourceSettingLoading());
    await API.updateSourceSetting(data, id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          await responseAlert({ res, label: "Source updated" });
          dispatch(updateSourceSettingSuccess(res.payload));
          history.push(filter);
        } else {
          responseAlert({ res });
          dispatch(sourceSettingFail());
        }
      })
      .catch((e: any) => {
        dispatch(sourceSettingFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const deactivateSourceSetting = (id: string) => {
  return async (dispatch: any) => {
    dispatch(sourceSettingLoading());
    await API.deactivateSourceSetting(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Source deactivated" });
          dispatch(sourceSettingSuccess());
        } else {
          responseAlert({ res });
          dispatch(sourceSettingFail());
        }
      })
      .catch((e: any) => {
        dispatch(sourceSettingFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const activateSourceSetting = (id: string) => {
  return async (dispatch: any) => {
    dispatch(sourceSettingLoading());
    await API.activateSourceSetting(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Source activated" });
          dispatch(sourceSettingSuccess());
        } else {
          responseAlert({ res });
          dispatch(sourceSettingFail());
        }
      })
      .catch((e: any) => {
        dispatch(sourceSettingFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

export {
  createSourceSetting,
  fetchSourceSettings,
  getSourceSettingByID,
  searchSourceSettings,
  updateSourceSetting,
  deactivateSourceSetting,
  activateSourceSetting,
  fetchAllSource,
  clearSourceDetails,
  clearSources,
};
