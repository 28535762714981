/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { RootState } from "reducers";
import { AccountActions, UsersActions } from "actions";

import history from "../../../history";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Button,
  Dropdown,
  Input,
  SelectDatetime,
  Textarea,
  LoadingLayer,
  Spinner,
  FormDetails,
  FormGroup,
  FormInline,
  FormLabel,
  ButtonField,
  TitlePartOfForm,
} from "components/Common";
import { UserDetails } from "components/Common/Popup";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import RouteTypes from "constants/RouteTypes";
import countries from "libs/countries";
import { popupAlert } from "libs/Utils";

const { createAccount, updateAccount, clearPackageDetails, getAccountByID } =
  AccountActions;
const { searchUsers } = UsersActions;

const InputStyleProps = {
  width: "100%",
};

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "20px 10px 30px 0",
};

const PackageDetails = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state: RootState) =>
    _.get(state, "Account.isLoading")
  );

  const account = useSelector((state: RootState) => {
    return state.Account.account;
  });
  const [accountData, setAccountData] = useState({
    accountName: "",
    accountCode: "",
    startDate: "",
    endDate: "",
    description: "",
  });
  const [userData, setUserData] = useState({
    address: "",
    city: "",
    country: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    region: "",
  });
  const [clientId, setClientId] = useState<string>("");
  const [isDisplayPopup, setIsDisplayPopup] = useState(false);

  useEffect(() => {
    const getAccountId = _.get(location, "state.accountId");
    if (getAccountId) dispatch(getAccountByID(getAccountId));
    const getClientId = _.get(location, "state.clientId");
    if (getClientId) {
      setClientId(getClientId);
      dispatch(searchUsers({ clientId: getClientId }));
    } else history.push({ pathname: RouteTypes.ADMIN_ROUTES.CLIENTS });
    return () => {
      dispatch(clearPackageDetails());
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(account)) {
      const getUserData = _.get(account, "userData");
      setAccountData(account);
      setUserData(getUserData);
    }
  }, [account, dispatch]);

  const processData = () => {
    const formData = new FormData();
    formData.append("accountName", _.trim(accountData.accountName));
    formData.append("accountCode", _.trim(accountData.accountCode));
    formData.append("clientId", _.trim(clientId));
    formData.append("startDate", _.trim(accountData.startDate));
    formData.append("endDate", _.trim(accountData.endDate));
    if (accountData.description)
      formData.append("description", _.trim(accountData.description));
    formData.append("userData[address]", _.trim(userData.address));
    formData.append("userData[city]", _.trim(userData.city));
    formData.append("userData[phoneNumber]", _.trim(userData.phoneNumber));
    formData.append("userData[country]", _.trim(userData.country));
    if (userData.zipCode)
      formData.append("userData[zipCode]", _.trim(userData.zipCode));
    return formData;
  };

  const validateData = () => {
    let flag = true;
    let message = `${t("alert.foundEmptyFields")}`;
    const data = [
      {
        label: t("label.packageName"),
        value: _.get(accountData, "accountName"),
      },
      {
        label: t("label.packageCode"),
        value: _.get(accountData, "accountCode"),
      },
      {
        label: t("label.startDate"),
        value: _.get(accountData, "startDate"),
      },
      {
        label: t("label.endDate"),
        value: _.get(accountData, "endDate"),
      },
      {
        label: t("label.streetAddress"),
        value: _.get(userData, "address"),
      },
      {
        label: t("label.city"),
        value: _.get(userData, "city"),
      },
      {
        label: t("label.country"),
        value: _.get(userData, "country"),
      },
      {
        label: t("label.contactNumber"),
        value: _.get(userData, "phoneNumber"),
      },
      {
        label: t("label.description"),
        value: _.get(accountData, "description"),
      },
    ];
    _.map(data, (item) => {
      const { value, label } = item;
      if (_.isEmpty(_.trim(value))) {
        message += `${label}\n`;
        flag = false;
      }
    });
    return { flag, message };
  };

  const editAccount = async () => {
    const { flag, message } = validateData();

    if (!flag) {
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });
    }

    const data = processData();
    const accountId = _.get(account, "id");

    const filter = _.get(location, "state.filter");
    const resolveFilter = {
      pathname: RouteTypes.ADMIN_ROUTES.PACKAGES,
      state: {
        id: clientId,
        filter,
        from: RouteTypes.ADMIN_ROUTES.PACKAGE_DETAILS,
      },
    };

    if (accountId) await dispatch(updateAccount(data, accountId, resolveFilter));
    else await dispatch(createAccount(data, resolveFilter));
  };

  const onBack = () => {
    const filter = _.get(location, "state.filter");
    history.push({
      pathname: RouteTypes.ADMIN_ROUTES.PACKAGES,
      state: {
        id: clientId,
        filter,
        from: RouteTypes.ADMIN_ROUTES.PACKAGE_DETAILS,
      },
    });
  };

  const renderMain = () => {
    return (
      <>
        {isDisplayPopup && (
          <UserDetails
            data={account}
            onClose={() => setIsDisplayPopup(false)}
          />
        )}
        <FormDetails>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.packageName")}
              </FormLabel>
              <Input
                value={accountData.accountName || ""}
                onChangeHandler={(e) =>
                  setAccountData({
                    ...accountData,
                    accountName: e.target.value,
                  })
                }
                placeholder={t(
                  `placeholder.${isLoading ? "loading" : "packageName"}`
                )}
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.packageCode")}
              </FormLabel>
              <Input
                value={accountData.accountCode || ""}
                onChangeHandler={(e) =>
                  setAccountData({
                    ...accountData,
                    accountCode: e.target.value,
                  })
                }
                placeholder={t(
                  `placeholder.${isLoading ? "loading" : "packageCode"}`
                )}
                {...InputStyleProps}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.startDate")}</FormLabel>
              <SelectDatetime
                callbackPayload={(e) =>
                  setAccountData({ ...accountData, startDate: e })
                }
                maxDate={accountData?.endDate}
                placeholder={t(
                  `placeholder.${isLoading ? "loading" : "startDate"}`
                )}
                defaultValue={accountData.startDate}
                disabledPreviousDate
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.endDate")}</FormLabel>
              <SelectDatetime
                callbackPayload={(e) =>
                  setAccountData({ ...accountData, endDate: e })
                }
                minDate={accountData?.startDate}
                placeholder={t(
                  `placeholder.${isLoading ? "loading" : "endDate"}`
                )}
                defaultValue={accountData.endDate}
                disabledPreviousDate
                {...InputStyleProps}
              />
            </FormGroup>
          </FormInline>
          <TitlePartOfForm>{t("label.contact")}</TitlePartOfForm>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.streetAddress")}
              </FormLabel>
              <Input
                value={userData.address || ""}
                onChangeHandler={(e) =>
                  setUserData({ ...userData, address: e.target.value })
                }
                placeholder={t(
                  `placeholder.${isLoading ? "loading" : "streetAddress"}`
                )}
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup />
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.city")}</FormLabel>
              <Input
                value={userData.city || ""}
                onChangeHandler={(e) =>
                  setUserData({ ...userData, city: e.target.value })
                }
                placeholder={t(`placeholder.${isLoading ? "loading" : "city"}`)}
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.country")}</FormLabel>
              <Dropdown
                value={userData.country || ""}
                callbackPayload={(value) =>
                  setUserData({ ...userData, country: value })
                }
                options={countries}
                placeholder={t(
                  `placeholder.${isLoading ? "loading" : "country"}`
                )}
                search={true}
                {...InputStyleProps}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.postalOrZipCode")}</FormLabel>
              <Input
                value={userData.zipCode || ""}
                onChangeHandler={(e) =>
                  setUserData({ ...userData, zipCode: e.target.value })
                }
                placeholder={t(
                  `placeholder.${isLoading ? "loading" : "postalOrZipCode"}`
                )}
                isNum
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.contactNumber")}
              </FormLabel>
              <Input
                value={userData.phoneNumber || ""}
                onChangeHandler={(e) =>
                  setUserData({ ...userData, phoneNumber: e.target.value })
                }
                placeholder={t(
                  `placeholder.${isLoading ? "loading" : "contactNumber"}`
                )}
                isNum
                {...InputStyleProps}
              />
            </FormGroup>
          </FormInline>
          <FormGroup>
            <FormLabel aria-label="require">{t("label.description")}</FormLabel>
            <Textarea
              value={_.get(accountData, "description")}
              onChangeHandler={(e: any) =>
                setAccountData({
                  ...accountData,
                  description: e.target.value,
                })
              }
              placeholder={t(
                `placeholder.${isLoading ? "loading" : "packageDescription"}`
              )}
              rows={5}
              {...InputStyleProps}
            />
          </FormGroup>
          <ButtonField margin="10px 0 50px 0px">
            <Button onClick={() => editAccount()} {...ButtonStyleProps}>
              {_.has(account, "id") ? t("button.save") : t("button.create")}
              {isLoading && (
                <LoadingLayer>
                  <Spinner />
                </LoadingLayer>
              )}
            </Button>
            <Button onClick={() => onBack()} {...ButtonStyleProps}>
              {t("button.back")}
            </Button>
          </ButtonField>
        </FormDetails>
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      parent={RouteTypes.ADMIN_ROUTES.GROUPS}
      currentPage={RouteTypes.ADMIN_ROUTES.CLIENTS}
      title={t("titlePage.packages")}
      subTitle={
        _.get(location, "state.accountId")
          ? t("titlePage.editPackage")
          : t("titlePage.createNewPackage")
      }
    />
  );
};

export default PackageDetails;
