import ActionTypes from "constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  clients: [],
  client: {},
  usersClient: [],
  clientsForUserPortal: [],
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.CLIENT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.CLIENT_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
      };
    case ActionTypes.CLIENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CLEAR_CLIENTS_PAYLOAD:
      return {
        ...state,
        clients: [],
      };
    case ActionTypes.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_CLIENT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        clients: payload,
      };
    case ActionTypes.FETCH_USERS_CLIENT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        usersClient: payload,
      };
    case ActionTypes.GET_CLIENT_BY_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        client: payload,
      };
    case ActionTypes.REGISTER_CLIENT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        client: payload,
      };
    case ActionTypes.UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        client: payload,
      };
    case ActionTypes.FETCH_ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        clients: payload,
      };
    default:
      return state;
  }
};
