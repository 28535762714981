/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import PDFFile from "./ExportReportPDFFile";
import WordFile from "./ExportReportWordFile";
import ExcelFile from "./ExportReportToExcel";
import { saveAs } from "file-saver";
import { Packer } from "docx";

import { RootState } from "reducers";
import {
  DataRecordsActions,
  FoldersActions,
  ReportActions,
  EmailTemplateActions,
} from "actions";

import {
  generateFilter,
  popupAlert,
  processDataDropDownFolders,
} from "libs/Utils";
import history from "../../../history";

import { Images } from "themes";
import RouteTypes from "constants/RouteTypes";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  LabelSection,
  ContentSection,
  ContentRow,
  OrderLayer,
  ReportDetails,
  DataTab,
  DataWrapper,
  DataHeader,
  DataBody,
} from "./Reports.styles";
import {
  Checkbox,
  Button,
  Dropdown,
  SelectDatetime,
  Input,
  Radio,
  LoadingLayer,
  Spinner,
  FormInline,
  FormGroup,
  FormLabel,
  FormDetails,
  ButtonField,
  Avatar,
  PostData,
} from "components/Common";
import { EmailTemplateDetails } from "components/Common/Popup";
import { useTranslation } from "react-i18next";
import { CONSTANTS } from "constants/Constants";
import PostReport from "./PostReports";

const { getEmailTemplateByType, clearEmailTemplateDetails } =
  EmailTemplateActions;
const { fetchFolders } = FoldersActions;
const { searchDataRecord, clearDataRecords, getDataRecordForFolderById } =
  DataRecordsActions;
const {
  createReport,
  getReportByID,
  searchReports,
  updateReport,
  clearReportDetails,
} = ReportActions;

interface ReportDetailsStructure {
  reportName: string;
  logo: string;
  listField: string[];
  orderBy: string;
  posts: string[];
  listData: string[];
  folders: string[];
  fromDate: string;
  toDate: string;
}

interface FilterStructure {
  dateFrom: string;
  dateTo: string;
  keyword: string;
  sortBy: string;
  orderBy: string;
  limit: number;
  page: number;
}

const DEFAULT_FILTER = {
  dateFrom: dayjs().format("YYYY-MM-DD"),
  dateTo: dayjs().format("YYYY-MM-DD"),
  keyword: "",
  sortBy: "title",
  orderBy: "DESC",
  limit: 10,
  page: 1,
};

const DEFAULT_DETAILS = {
  reportName: "",
  logo: "",
  listField: ["title", "publishedAt", "source", "link"],
  orderBy: "mediaType",
  posts: [],
  listData: [],
  folders: [],
  fromDate: dayjs().format("YYYY-MM-DD"),
  toDate: dayjs().format("YYYY-MM-DD"),
};

const ReportsFilter = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const isFolderLoading = useSelector((state: RootState) => {
    return state.Folders.isLoading;
  });
  const foldersPayload = useSelector((state: RootState) => {
    return state.Folders.folders;
  });
  const dataRecords = useSelector((state: RootState) => {
    return state.DataRecord.dataRecords;
  });
  const dataRecord = useSelector((state: RootState) => {
    return state.DataRecord.dataRecord;
  });
  const reportFilter = useSelector((state: RootState) => {
    return state.Reports.report;
  });
  const reportFilterIsLoading = useSelector((state: RootState) => {
    return state.Reports.isLoading;
  });
  const emailTemplate = useSelector((state: RootState) =>
    _.get(state, "EmailTemplate.emailTemplate")
  );
  const emailTemplateIsLoading = useSelector((state: RootState) =>
    _.get(state, "EmailTemplate.isLoading")
  );

  const LIST_OF_FIELDS = [
    { label: t("label.headline"), value: "title" },
    { label: t("label.publishedDate"), value: "publishedAt" },
    { label: t("label.sourceName"), value: "source" },
    { label: t("label.webURL"), value: "link" },
  ];

  const LIST_POST_BY = [
    { label: t("label.mediaType"), value: "mediaType" },
    { label: t("label.publishedDate"), value: "publishedAt" },
    { label: t("label.newestPostsFirst"), value: "newestPosts" },
    { label: t("label.oldestPostsFirst"), value: "oldestPosts" },
  ];

  const EXPORT_BY = [
    { label: "Word", value: "word" },
    { label: "PDF", value: "pdf" },
    { label: "XLS", value: "xls" },
  ];

  const location = useLocation();

  const [isShowCreateTemplate, setIsShowCreateTemplate] =
    useState<boolean>(false);
  const [urlPreview, setUrlPreview] = useState("");
  const [isUseSelectedFolders, setIsUseSelectedFolders] =
    useState<boolean>(false);
  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const [reportDetails, setReportDetails] =
    useState<ReportDetailsStructure>(DEFAULT_DETAILS);
  const [exportBy, setExportBy] = useState<string>("word");
  const [isShowCheckedFirst, setIsShowCheckedFirst] = useState<boolean>(false);
  const [dataTab, setDataTab] = useState<string>("filterBy");
  const [checkedPost, setCheckedPost] = useState<string[]>([]);
  const [total, setTotal] = useState<any>({});

  useEffect(() => {
    if (location.state) {
      const id = _.get(location, "state.id");
      if (id) {
        dispatch(getReportByID(id));
        setDataTab("selected");
      } else dispatch(clearDataRecords());
    }
    dispatch(fetchFolders({ page: 1, limit: 0 }));
    dispatch(getEmailTemplateByType(CONSTANTS.EMAIL_TYPE.EMAIL_REPORT));

    dispatch(searchDataRecord(generateFilter(DEFAULT_FILTER)));

    return () => {
      dispatch(clearReportDetails());
      dispatch(clearEmailTemplateDetails());
      dispatch(clearDataRecords());
      history.replace({ ...history.location, state: {} });
    };
  }, []);

  useEffect(() => {
    if (checkedPost) {
      let web = 0;
      let tv = 0;
      let print = 0;
      let social = 0;
      let all = 0;
      _.forEach(checkedPost, (item) => {
        const mediaType = _.get(item, "source.mediaType");
        if (mediaType === "web") web += 1;
        if (mediaType === "tv") tv += 1;
        if (mediaType === "print") print += 1;
        if (mediaType === "social network") social += 1;
        all += 1;
      });
      setTotal({
        web,
        tv,
        print,
        social,
        all,
      });
    }
  }, [checkedPost]);

  useEffect(() => {
    if (!_.isEmpty(reportFilter)) {
      const { folders, logo, fromDate, toDate, orderBy } = reportFilter;

      const resolveReportFilter = {
        ...reportFilter,
      };

      const listData = _.get(reportFilter, "listData");
      const getCheckedListData: string[] = [];
      _.forEach(listData, (item) => getCheckedListData.push(_.get(item, "id")));
      setCheckedPost(listData);
      _.assign(resolveReportFilter, { listData: getCheckedListData });

      setReportDetails(resolveReportFilter);

      if (logo) {
        const getImagePath = _.get(logo, "imagePath");
        setUrlPreview(getImagePath);
      }
      if (!_.isEmpty(folders)) setIsUseSelectedFolders(true);
      else setIsUseSelectedFolders(false);

      const resolveFilter = {
        ...filter,
        dateFrom: fromDate,
        dateTo: toDate,
      };
      if (orderBy === "newestPosts")
        _.assign(resolveFilter, { orderBy: "DESC", sortBy: "createdAt" });

      if (orderBy === "oldestPosts")
        _.assign(resolveFilter, { orderBy: "ASC", sortBy: "createdAt" });
      setFilter(resolveFilter);
      dispatch(searchDataRecord(generateFilter(resolveFilter)));
    }
  }, [reportFilter]);

  const onChangeListOfFied = (value: string) => {
    const listFields = _.get(reportDetails, "listField");
    const includes = _.includes(listFields, value);
    if (includes) {
      const removeValue = _.filter(listFields, (field) => field !== value);
      setReportDetails({
        ...reportDetails,
        listField: removeValue,
      });
    } else {
      setReportDetails({
        ...reportDetails,
        listField: [...listFields, value],
      });
    }
  };

  const onChangeCheckedPost = (
    newCheckedPost: string[],
    isSelected?: boolean
  ) => {
    const resolveCheckedPost: any[] = [...checkedPost];
    if (isSelected) {
      const oldCheckedPost = [...checkedPost];
      _.forEach(oldCheckedPost, (item) => {
        const id = _.get(item, "id");
        if (_.includes(newCheckedPost, id)) resolveCheckedPost.push(item);
      });
    } else {
      _.forEach(_.get(dataRecords, "items"), (item) => {
        if (_.includes(newCheckedPost, _.get(item, "id")))
          resolveCheckedPost.push(item);
      });
      _.forEach(_.get(dataRecord, "items"), (item) => {
        if (_.includes(newCheckedPost, _.get(item, "dataRecordId.id"))) {
          const { dataRecordId } = item;
          resolveCheckedPost.push(dataRecordId);
        }
      });
    }
    const uniqDataRecord = _.uniqBy(resolveCheckedPost, "id");
    setCheckedPost(uniqDataRecord);
  };
  const onCheckedPost = (value: string, isSelected?: boolean) => {
    const listData = _.get(reportDetails, "listData");
    const includes = _.includes(listData, value);

    if (includes) {
      const removeCheckedValue = _.filter(listData, (post) => post !== value);
      onChangeCheckedPost(removeCheckedValue, isSelected);
      setReportDetails({
        ...reportDetails,
        listData: removeCheckedValue,
      });
    } else {
      onChangeCheckedPost([...listData, value], isSelected);
      setReportDetails({
        ...reportDetails,
        listData: [...listData, value],
      });
    }
  };

  const setLogo = (e: any) => {
    if (e.target.files[0]) {
      const url = URL.createObjectURL(e.target.files[0]);
      setReportDetails({ ...reportDetails, logo: e.target.files[0] });
      setUrlPreview(url);
    }
  };

  const validateData = () => {
    let flag = true;
    let message = `${t("alert.foundEmptyFields")}`;
    const data = [
      {
        label: t("label.reportName"),
        value: _.get(reportDetails, "reportName"),
      },
    ];
    _.map(data, (item) => {
      const { value, label } = item;
      if (_.isEmpty(_.trim(value))) {
        message += `${label}\n`;
        flag = false;
      }
    });
    return { flag, message };
  };

  const onCreate = async () => {
    const { flag, message } = validateData();

    if (!flag) {
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });
    }
    const formData = new FormData();
    formData.append("reportName", reportDetails.reportName);
    formData.append("fromDate", reportDetails.fromDate);
    formData.append("toDate", reportDetails.toDate);
    if (isUseSelectedFolders && !_.isEmpty(reportDetails.folders))
      _.map(reportDetails.folders, (folder) => {
        if (folder) formData.append("folders[]", folder);
      });

    if (reportDetails.listField)
      _.map(reportDetails.listField, (field) => {
        if (field) formData.append("listField[]", field);
      });

    formData.append("orderBy", reportDetails.orderBy);
    if (!_.isEmpty(reportDetails.listData))
      _.map(reportDetails.listData, (post) => {
        if (post) formData.append("listData[]", post);
      });
    else
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: t("alert.atLeastOneDataRecord"),
      });
    if (reportDetails.logo && !_.has(reportDetails.logo, "id"))
      formData.append("logo", reportDetails.logo || "");
    const reportFilterId = _.get(reportFilter, "id");

    if (reportFilterId) await dispatch(updateReport(formData, reportFilterId));
    else await dispatch(createReport(formData));
    await dispatch(searchReports({ fetch: "all" }));
  };

  const onClear = async () => {
    const isAgree = await popupAlert({
      title: t("alert.warning"),
      type: "warning",
      text: t("alert.warningBeforeClearAll"),
      buttons: ["No", true],
    });
    if (!isAgree) return;
    dispatch(clearReportDetails());
    dispatch(clearDataRecords());
    history.replace({ ...history.location, state: {} });
    setReportDetails(DEFAULT_DETAILS);
    setUrlPreview("");
    setIsShowCheckedFirst(false);
    setIsUseSelectedFolders(false);
  };

  const orderByPostData = () => {
    const dataRecordPayload =
      dataTab === "filterBy"
        ? _.get(dataRecords, "items")
        : _.get(dataRecord, "items");
    if (
      dataRecordPayload &&
      _.isArray(dataRecordPayload) &&
      isShowCheckedFirst
    ) {
      const listData = _.get(reportDetails, "listData");
      const checkedData = _.filter(dataRecords.items, (item) =>
        _.includes(listData, _.get(item, "id"))
      );
      const cloneDeepData = _.cloneDeep(dataRecordPayload);
      const reOrderData = _.sortBy(cloneDeepData, (a, b) => {
        const createdAtOfA: any = _.get(a, "createdAt");
        const createdAtOfB: any = _.get(b, "createdAt");
        const dateA: any = new Date(createdAtOfA);
        const dateB: any = new Date(createdAtOfB);
        return dateA - dateB;
      });
      return _.uniqBy([...checkedData, ...reOrderData], "id");
    }
    return dataRecordPayload;
  };

  const onCloseTemplate = async () => {
    await setIsShowCreateTemplate(false);
    await dispatch(getEmailTemplateByType(CONSTANTS.EMAIL_TYPE.EMAIL_REPORT));
  };

  const onFilter = () => {
    if (dataTab === "filterBy")
      dispatch(searchDataRecord(generateFilter(filter)));
    else
      dispatch(
        getDataRecordForFolderById({
          folderId: _.get(reportDetails, "folders"),
          keyword: _.get(filter, "keyword"),
        })
      );
  };

  const onChangeOrderBy = (newOrder: string) => {
    let resolveOrder = {
      orderBy: "DESC",
      sortBy: newOrder,
    };
    if (newOrder === "newestPosts")
      _.assign(resolveOrder, { sortBy: "createdAt" });
    if (newOrder === "oldestPosts")
      _.assign(resolveOrder, { sortBy: "createdAt", orderBy: "ASC" });

    const newFilter = {
      ...filter,
      ...resolveOrder,
    };

    setFilter(newFilter);
    setReportDetails({
      ...reportDetails,
      orderBy: newOrder,
    });
    dispatch(searchDataRecord(generateFilter(newFilter)));
  };

  const onClickFilters = () => {
    setDataTab("filterBy");
    setFilter({ ...filter, page: 1 });
    dispatch(
      searchDataRecord(
        generateFilter(_.omit({ ...filter }, ["orderBy", "sortBy"]))
      )
    );
  };

  const onClickFolders = () => {
    const folderId = _.get(reportDetails, "folders");
    if (_.isEmpty(folderId))
      return popupAlert({
        type: "warning",
        title: t("alert.warning"),
        text: t("alert.warningSelectUseFolders"),
      });

    setDataTab("folders");
    setFilter({ ...filter, page: 1 });
    dispatch(
      getDataRecordForFolderById({
        folderId: _.get(reportDetails, "folders"),
        keyword: _.get(filter, "keyword"),
      })
    );
  };

  const onExport = async () => {
    if (_.isEmpty(checkedPost))
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: t("alert.atLeastOnePost"),
      });

    const reportName = _.lowerCase(_.get(reportDetails, "reportName"));
    const reportDate = dayjs().format("DD_MM_YYYY");
    const fileName = `${reportName}_${reportDate}`;
    const resolveReportName = _.replace(fileName, new RegExp(" ", "g"), "_");
    if (exportBy === "word") {
      const doc = WordFile({
        data: checkedPost,
        total,
        logo: _.get(reportDetails, "logo"),
        title: _.get(reportDetails, "coverTitle") || "",
      });

      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, `${resolveReportName}.docx`);
      });
    }
    if (exportBy === "pdf") {
      // const pdfFile: any = await PDFFile({ data: checkedPost, total });
      const pdfFile: any = await PDFFile({
        data: checkedPost,
        total,
        logo: _.get(reportDetails, "logo"),
        title: _.get(reportDetails, "coverTitle") || "",
      });
      saveAs(pdfFile, `${resolveReportName}.pdf`);
    }
    if (exportBy === "xls") {
      const excelFile: any = ExcelFile({ data: checkedPost, total });
      saveAs(excelFile, `${resolveReportName}.xlsx`);
    }
  };

  const renderBasic = () => {
    return (
      <div>
        <ContentSection className="row">
          <FormDetails>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.reportName")}
              </FormLabel>
              <Input
                onChangeHandler={(e: any) =>
                  setReportDetails({
                    ...reportDetails,
                    reportName: e.target.value,
                  })
                }
                width="300px"
                value={_.get(reportDetails, "reportName") || ""}
                placeholder={t("placeholder.reportName")}
              />
            </FormGroup>

            <LabelSection>{t("label.filterBy")}</LabelSection>
            <FormInline>
              <FormGroup>
                <FormLabel aria-label="require">
                  {t("label.dateFrom")}
                </FormLabel>
                <SelectDatetime
                  defaultValue={_.get(reportDetails, "fromDate")}
                  callbackPayload={(e: any) => {
                    setFilter({
                      ...filter,
                      dateFrom: e,
                    });
                    setReportDetails({
                      ...reportDetails,
                      fromDate: e,
                    });
                  }}
                  width="300px"
                />
              </FormGroup>
              <FormGroup>
                <FormLabel aria-label="require">{t("label.dateTo")}</FormLabel>
                <SelectDatetime
                  defaultValue={_.get(reportDetails, "toDate")}
                  callbackPayload={(e: any) => {
                    setFilter({
                      ...filter,
                      dateTo: e,
                    });
                    setReportDetails({
                      ...reportDetails,
                      toDate: e,
                    });
                  }}
                  width="300px"
                />
              </FormGroup>
            </FormInline>
            <FormGroup>
              <Checkbox
                context={t("label.useSelectedFolders")}
                checked={isUseSelectedFolders}
                onChangeHandler={(e: boolean) => setIsUseSelectedFolders(e)}
                width="300px"
              />
            </FormGroup>
            <FormInline>
              <FormGroup>
                <FormLabel>{t("label.folders")}</FormLabel>
                <Dropdown
                  value={_.get(reportDetails, "folders")}
                  width="300px"
                  callbackPayload={(value) =>
                    setReportDetails({ ...reportDetails, folders: value })
                  }
                  options={processDataDropDownFolders(foldersPayload.items)}
                  placeholder={t("label.folders")}
                  search
                  multiple
                  disabled={!isUseSelectedFolders}
                  loading={isFolderLoading}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t("label.keyword")}</FormLabel>
                <Input
                  onChangeHandler={(e: any) =>
                    setFilter({ ...filter, keyword: e.target.value })
                  }
                  width="300px"
                  value={_.get(filter, "keyword") || ""}
                  placeholder={t("placeholder.keyword")}
                />
              </FormGroup>
            </FormInline>
            <Button onClick={() => onFilter()}>{t("button.filter")}</Button>
          </FormDetails>
        </ContentSection>
      </div>
    );
  };

  const renderPostDisplay = () => {
    return (
      <div>
        <LabelSection>{t("label.postDisplay")}</LabelSection>
        <ContentSection>
          <FormGroup>
            <FormLabel>
              {t("label.logo")}{" "}
              <span className="description">({t("label.demensionImage")})</span>
            </FormLabel>
            <Avatar
              src={urlPreview || Images.defaultLogo}
              onChange={(e: any) => setLogo(e)}
            />
          </FormGroup>
          <FormLabel className="label-options">
            {t("label.listOfFields")}
          </FormLabel>
          <ContentRow>
            {_.map(LIST_OF_FIELDS, (field, index) => {
              const { value, label } = field;
              const listOfFields = _.get(reportDetails, "listField");
              const checked = _.includes(listOfFields, value);
              return (
                <Checkbox
                  key={`field-${value}-${index}`}
                  context={label}
                  checked={checked}
                  onChangeHandler={() => onChangeListOfFied(value)}
                  margin="0 10px 0 0"
                />
              );
            })}
          </ContentRow>
          <FormLabel className="label-options">
            {t("label.listPostsBy")}
          </FormLabel>
          <ContentRow>
            {_.map(LIST_POST_BY, (field, index) => {
              const { value, label } = field;
              const listPostsBy = _.get(reportDetails, "orderBy");
              const checked = listPostsBy === value;
              return (
                <Radio
                  key={`field-${value}-${index}`}
                  context={label}
                  checked={checked}
                  onChangeHandler={() => onChangeOrderBy(value)}
                  margin="0 10px 0 0"
                />
              );
            })}
          </ContentRow>
          <FormLabel className="label-options">{t("label.exportBy")}</FormLabel>
          <ContentRow>
            {_.map(EXPORT_BY, (field, index) => {
              const { value, label } = field;
              const checked = exportBy === value;
              return (
                <Radio
                  key={`field-${value}-${index}`}
                  context={label}
                  checked={checked}
                  onChangeHandler={() => setExportBy(value)}
                  margin="0 10px 0 0"
                />
              );
            })}
            <Button buttonMargin="0 10px 0 20px" onClick={() => onExport()}>
              {t("button.export")}
            </Button>
          </ContentRow>
          {/* <div>
            <Checkbox
              context={t("label.showSelectedDataFirst")}
              checked={isShowCheckedFirst}
              onChangeHandler={() => setIsShowCheckedFirst(!isShowCheckedFirst)}
            />
          </div> */}
          <DataWrapper>
            <DataHeader>
              <DataTab
                className={dataTab === "selected" ? "active" : ""}
                onClick={() => setDataTab("selected")}
              >
                Selected Posts
              </DataTab>
              <DataTab
                className={dataTab === "filterBy" ? "active" : ""}
                onClick={() => onClickFilters()}
              >
                Filter by
              </DataTab>
              <DataTab
                className={dataTab === "folders" ? "active" : ""}
                onClick={() => onClickFolders()}
              >
                Folders
              </DataTab>
            </DataHeader>
            <DataBody>
              {dataTab === "selected" ? (
                <PostData
                  checked={_.get(reportDetails, "listData")}
                  data={checkedPost}
                  onCheck={(value: string) => onCheckedPost(value, true)}
                  isHidePostOptions
                />
              ) : (
                <PostReport
                  checked={_.get(reportDetails, "listData")}
                  data={orderByPostData()}
                  onCheck={(value: string) => onCheckedPost(value)}
                  meta={
                    dataTab === "filterBy"
                      ? _.get(dataRecords, "meta")
                      : _.get(dataRecord, "meta")
                  }
                  onChangePage={(page: number) => {
                    dataTab === "filterBy"
                      ? dispatch(
                          searchDataRecord(
                            generateFilter(
                              _.omit({ ...filter, page }, ["orderBy", "sortBy"])
                            )
                          )
                        )
                      : dispatch(
                          getDataRecordForFolderById({
                            folderId: _.get(reportDetails, "folders"),
                            page,
                            keyword: _.get(filter, "keyword"),
                          })
                        );
                  }}
                />
              )}
            </DataBody>
          </DataWrapper>

          <ButtonField margin="10px 0 50px 0px">
            <Button buttonMargin="0 10px 0 0" onClick={() => onCreate()}>
              {_.has(reportFilter, "id")
                ? t("button.save")
                : t("button.create")}
            </Button>
            <Button
              buttonMargin="0 10px 0 0"
              onClick={() =>
                history.push({ pathname: RouteTypes.ADMIN_ROUTES.REPORT })
              }
            >
              {t("button.back")}
            </Button>
            <Button buttonMargin="0 10px 0 0" onClick={() => onClear()}>
              {t("button.clearAll")}
            </Button>
          </ButtonField>
        </ContentSection>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <ReportDetails>
        {!_.has(emailTemplate, "id") && (
          <OrderLayer>
            <p>{t("description.emptyReportTemplate")}</p>
            <Button
              buttonWidth="150px"
              onClick={() => setIsShowCreateTemplate(true)}
            >
              {t("button.createTemplate")}
            </Button>
          </OrderLayer>
        )}
        {isShowCreateTemplate && (
          <EmailTemplateDetails onClose={() => onCloseTemplate()} />
        )}
        {(emailTemplateIsLoading || reportFilterIsLoading) && (
          <LoadingLayer>
            <Spinner />
          </LoadingLayer>
        )}
        {renderBasic()}
        {renderPostDisplay()}
      </ReportDetails>
    );
  };

  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.ADMIN_ROUTES.REPORT}
      title={t("titlePage.reports")}
      subTitle={
        _.has(reportFilter, "id")
          ? t("titlePage.editReport")
          : t("titlePage.createNewReport")
      }
    />
  );
};
export default ReportsFilter;
