import RouteTypes from "constants/RouteTypes";
import { Icons } from "themes";

export default {
  DASHBOARD: {
    label: "dashboard",
    router: RouteTypes.ADMIN_ROUTES.HOMEPAGE,
    icon: Icons.dashboard,
  },
  "MANAGE RECORDS": {
    label: "manageRecords",
    router: RouteTypes.ADMIN_ROUTES.MANAGE_RECORD,
    icon: Icons.manageRecord,
    child: [
      {
        label: "fileRecords",
        router: RouteTypes.ADMIN_ROUTES.FILE_RECORD,
        icon: Icons.fileRecord,
      },
      {
        label: "dataRecords",
        router: RouteTypes.ADMIN_ROUTES.DATA_RECORD,
        icon: Icons.dataRecord,
      },
    ],
  },
  "MANAGE SOURCES": {
    label: "manageSources",
    router: RouteTypes.ADMIN_ROUTES.MANAGE_SOURCES,
    icon: Icons.manageSources,
    child: [
      {
        label: "sources",
        router: RouteTypes.ADMIN_ROUTES.SOURCES_SETTINGS,
        icon: Icons.sources,
      },
      {
        label: "categories",
        router: RouteTypes.ADMIN_ROUTES.CATEGORIES,
        icon: Icons.categories,
      },
    ],
  },
  MEMBERS: {
    label: "members",
    router: RouteTypes.ADMIN_ROUTES.MEMBERS,
    icon: Icons.users,
  },
  GROUPS: {
    label: "groups",
    router: RouteTypes.ADMIN_ROUTES.GROUPS,
    icon: Icons.group,
    child: [
      {
        label: "clients",
        router: RouteTypes.ADMIN_ROUTES.CLIENTS,
        icon: Icons.clients,
      },
      {
        label: "users",
        router: RouteTypes.ADMIN_ROUTES.USERS,
        icon: Icons.groupUsers,
      },
    ],
  },
  ROLES: {
    label: "roles",
    router: RouteTypes.ADMIN_ROUTES.ROLES,
    icon: Icons.roles,
  },
  FILTERS: {
    label: "filters",
    router: RouteTypes.ADMIN_ROUTES.FILTERS,
    icon: Icons.filters,
  },
  LOGS: {
    label: "logs",
    router: RouteTypes.ADMIN_ROUTES.LOGS,
    icon: Icons.logs,
  },
  "MANAGE REPORTS": {
    label: "manageReports",
    router: RouteTypes.ADMIN_ROUTES.MANAGE_REPORTS,
    icon: Icons.reports,
    child: [
      {
        label: "reports",
        router: RouteTypes.ADMIN_ROUTES.REPORT,
        icon: Icons.reports,
      },
      {
        label: "posts",
        router: RouteTypes.ADMIN_ROUTES.POST,
        icon: Icons.post,
      },
      {
        label: "folders",
        router: RouteTypes.ADMIN_ROUTES.FOLDERS,
        icon: Icons.folders,
      },
    ],
  },
  "EMAIL TEMPLATES": {
    label: "emailTemplates",
    router: RouteTypes.ADMIN_ROUTES.EMAIL_TEMPLATE,
    icon: Icons.template,
  },
  "EMAIL SCHEDULES": {
    label: "emailSchedules",
    router: RouteTypes.ADMIN_ROUTES.EMAIL_DELIVERY,
    icon: Icons.email,
  },
  CONTACT: {
    label: "contact",
    router: RouteTypes.ADMIN_ROUTES.CONTACT,
    icon: Icons.contact,
  },
  "FILE RECORDS": {
    label: "fileRecords",
    router: RouteTypes.ADMIN_ROUTES.FILE_RECORD,
    icon: Icons.fileRecord,
  },
  "DATA RECORDS": {
    label: "dataRecords",
    router: RouteTypes.ADMIN_ROUTES.DATA_RECORD,
    icon: Icons.dataRecord,
  },
  SOURCES: {
    label: "sources",
    router: RouteTypes.ADMIN_ROUTES.SOURCES_SETTINGS,
    icon: Icons.sources,
  },

  CATEGORIES: {
    label: "categories",
    router: RouteTypes.ADMIN_ROUTES.CATEGORIES,
    icon: Icons.categories,
  },
  CLIENTS: {
    label: "clients",
    router: RouteTypes.ADMIN_ROUTES.CLIENTS,
    icon: Icons.clients,
  },

  PACKAGES: {
    label: "packages",
    router: RouteTypes.CLIENT_ROUTES.PACKAGES_MANAGEMENT,
    icon: Icons.package,
  },
  USERS: {
    label: "users",
    router: RouteTypes.ADMIN_ROUTES.USERS,
    icon: Icons.groupUsers,
  },
  REPORTS: {
    label: "reports",
    router: RouteTypes.ADMIN_ROUTES.REPORT,
    icon: Icons.reports,
  },
  POSTS: {
    label: "posts",
    router: RouteTypes.ADMIN_ROUTES.POST,
    icon: Icons.post,
  },
  FOLDERS: {
    label: "folders",
    router: RouteTypes.ADMIN_ROUTES.FOLDERS,
    icon: Icons.folders,
  },
};
