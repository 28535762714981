import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import RouteTypes from "constants/RouteTypes";
import {
  ContactContainer,
  ContactListUser,
  ContactChatbox,
  HeaderChatbox,
  MessageChatbox,
  InputChatbox,
  ListUserHeader,
  ListUser,
  UserItem,
  MessageItem,
  DateLine,
  UserSkeleton,
  MessageSkeleton,
  TypingField,
  TypingDots,
} from "./Contact.styles";
import DefaultLayout from "components/AdminLayout/DefaultLayout";
import { Icons } from "themes";
import { generateDropdown, getSavedUserData } from "../../../libs/Utils";
import { MessageActions, UserOfClientActions } from "../../../actions";
import { Dropdown } from "../../../components/Common";
import { CONSTANTS } from "../../../constants/Constants";
import { setDirectMessages } from "../../../actions/Message.action";

// Declare constants
const socket = io(process.env.REACT_APP_BE_URL || "");
const { fetchMessages, fetchDirectMessage, setAllMessages } = MessageActions;
const { getAllMembers, clearUserPayload } = UserOfClientActions;

const Contact = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const [message, setMessage] = useState<string>("");
  const [currentReceiverId, setReceiverId] = useState<string>("");
  const [receiverName, setReceiverName] = useState<string>("");
  const [receiverAvatar, setReceiverAvatar] = useState<string>("");
  const messagesRef = useRef<HTMLDivElement>(null);
  const allMessages: any = useSelector((state) =>
    _.get(state, "Message.allMessages")
  );
  const directMessage = useSelector((state) =>
    _.get(state, "Message.messageDirectory")
  );
  const fetchAllMessageIsLoading = useSelector((state) =>
    _.get(state, "Message.fetchAllMessageIsLoading")
  );
  const isLoading = useSelector((state) => _.get(state, "Message.isLoading"));
  const users: any = useSelector((state) => _.get(state, "Users.users"));
  const [usersDropdown, setUsersDropdown] = useState<any[] | []>([]);
  const [currentDirectMessage, setCurrentDirectMessage] = useState<any>({});
  const [newMessage, setNewMessage] = useState<any>({});
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [typingId, setTypingId] = useState<string>("");

  const scrollToBottom = () => {
    if (messagesRef)
      setTimeout(
        () =>
          messagesRef.current?.scrollTo(0, messagesRef.current.scrollHeight),
        100
      );
  };

  useEffect(() => {
    if (!_.isEmpty(newMessage)) {
      const roomId = _.get(newMessage, "roomId");
      const newAllMessages = {
        ...allMessages,
        [roomId]: newMessage,
      };
      dispatch(setAllMessages(newAllMessages));

      const userId = _.get(getSavedUserData(), "id");
      const receiverId = _.get(newMessage, "receiver.id");
      const senderId = _.get(newMessage, "sender.id");
      if (senderId === userId && receiverId === currentReceiverId) {
        const currentDate = dayjs().format("MM/DD/YYYY");
        const cloneMessages = _.cloneDeep(currentDirectMessage);
        if (cloneMessages[currentDate])
          _.assign(cloneMessages, {
            [currentDate]: [...cloneMessages[currentDate], newMessage],
          });
        else
          _.assign(cloneMessages, {
            [currentDate]: [newMessage],
          });
        const removeSendingMessage: any[] = [];
        _.forEach(cloneMessages[currentDate], (item) => {
          const type = _.get(item, "type");
          if (!type || type !== "Sending") removeSendingMessage.push(item);
        });
        _.assign(cloneMessages, {
          [currentDate]: removeSendingMessage,
        });

        setCurrentDirectMessage(cloneMessages);
        dispatch(setDirectMessages(cloneMessages));
        setNewMessage({});
        scrollToBottom();
      }
    }
  }, [newMessage]);

  useEffect(() => {
    if (typingId) {
      if (typingId === currentReceiverId) setIsTyping(true);
      else setIsTyping(false);
    } else setIsTyping(false);
  }, [typingId]);

  useEffect(() => {
    const userId = _.get(getSavedUserData(), "id");
    socket.emit("connectToServer", userId);
    socket.on("receiveMessage", (payload) => {
      setNewMessage(payload);
      dispatch(fetchMessages());
    });
    socket.on("typing", (senderId: string) => {
      setTypingId(senderId);
    });
    socket.on("stopType", () => {
      setTypingId("");
    });
    dispatch(fetchMessages());
    dispatch(getAllMembers({ status: CONSTANTS.STATUS.ACTIVE }));
    scrollToBottom();
    return () => {
      dispatch(clearUserPayload());
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(users)) {
      const resolveUserDropdown = generateDropdown({
        data: users,
        key: "userData.fullName",
        value: "id",
      });
      setUsersDropdown(resolveUserDropdown);
    }
  }, [users]);

  useEffect(() => {
    if (!_.isEmpty(directMessage)) {
      setCurrentDirectMessage(directMessage);
      scrollToBottom();
    } else setCurrentDirectMessage({});
  }, [directMessage]);

  const onSendMessage = () => {
    const senderId = _.get(getSavedUserData(), "id");
    const senderUsername = _.get(getSavedUserData(), "username");
    const currentDate = dayjs().format("MM/DD/YYYY");
    const newValue = {
      message,
      sender: {
        username: senderUsername,
      },
      type: "Sending",
    };
    const cloneMessages = _.cloneDeep(currentDirectMessage);
    if (cloneMessages[currentDate])
      _.assign(cloneMessages, {
        [currentDate]: [...cloneMessages[currentDate], newValue],
      });
    else
      _.assign(cloneMessages, {
        [currentDate]: [newValue],
      });
    setCurrentDirectMessage(cloneMessages);
    scrollToBottom();
    socket.emit("stopType", { receiverId: currentReceiverId });
    socket.emit(
      "sendMessage",
      {
        senderId,
        receiverId: currentReceiverId,
        message,
      },
      (payload: any) => {
        setNewMessage(payload);
      }
    );
    setMessage("");
  };

  const onChangeReceiver = async (id: string, name: string, avatar: string) => {
    setReceiverId(id);
    setReceiverName(name);
    await dispatch(fetchDirectMessage({ receiverId: id }));
    dispatch(fetchMessages());
    setReceiverAvatar(avatar);
    scrollToBottom();
  };

  const onTyping = (e: any) => {
    const userId = _.get(getSavedUserData(), "id");
    socket.emit("typing", { receiverId: currentReceiverId, senderId: userId });
    setMessage(e.target.value);
    setTimeout(() => {
      socket.emit("stopType", {
        receiverId: currentReceiverId,
        senderId: userId,
      });
    }, 4000);
  };

  const renderMessageUser = () => {
    const userId = _.get(getSavedUserData(), "id");
    const render = _.map(allMessages, (item, key) => {
      const receiveUser = _.get(item, "receiver.id") || '';
      const receiveName = _.get(item, "receiver.userData.fullName")|| '';
      const lastStatus = _.get(item, "status");
      const senderUser = _.get(item, "sender.id")|| '';
      const senderName = _.get(item, "sender.userData.fullName")|| '';
      const senderAvatar = _.get(item, "sender.userData.avatar.imagePath")|| '';
      const receiverAvatar = _.get(item, "receiver.userData.avatar.imagePath")|| '';
      const resolveUserId = receiveUser === userId ? senderUser : receiveUser;
      const resolveUsername = receiveUser === userId ? senderName : receiveName;
      const resolveLabel = receiveUser === userId ? "" : "You: ";
      const resolveAvatar =
        receiveUser === userId ? senderAvatar : receiverAvatar;
      return (
        <UserItem
          key={`user-${key}`}
          className={`${
            senderUser !== userId && lastStatus === "New" ? "unread" : ""
          } ${resolveUserId === currentReceiverId ? "active" : ""}`}
          onClick={() =>
            onChangeReceiver(resolveUserId, resolveUsername, resolveAvatar)
          }
        >
          <img src={resolveAvatar || Icons.defaultUser} alt="" />
          <div className="message_information">
            <span className="username">{resolveUsername}</span>
            <p>
              {" "}
              {resolveLabel}
              {_.get(item, "message")}
            </p>
          </div>
        </UserItem>
      );
    });
    return render;
  };

  const renderSkeletonListUser = () => {
    const rangeUser = _.range(1, 10);
    const render = _.map(rangeUser, (item, index) => {
      return (
        <UserSkeleton key={`user-skeleton-${index}`}>
          <span className="avatar" />
          <div className="message_information">
            <span className="username" />
            <span className="message" />
          </div>
        </UserSkeleton>
      );
    });
    return render;
  };

  const renderListUser = () => {
    return (
      <ContactListUser>
        <Dropdown
          value=""
          width="100%"
          callbackPayload={(value: string) => {
            const findName = _.find(users, { id: value });
            const avatar = _.get(findName, "userData.avatar.imagePath");
            onChangeReceiver(
              value,
              _.get(findName, "userData.fullName"),
              avatar
            );
          }}
          options={usersDropdown}
          margin="10px 0 10px 0"
          placeholder={t("placeholder.users")}
          search={true}
        />
        <ListUserHeader>
          <span>Chats</span>
        </ListUserHeader>
        <ListUser>
          <div>
            {isLoading && _.isEmpty(allMessages)
              ? renderSkeletonListUser()
              : renderMessageUser()}
          </div>
        </ListUser>
      </ContactListUser>
    );
  };

  const renderSkeletonMessage = () => {
    const typeMessage = ["self", "guest"];
    const rangeUser = _.range(1, 7);
    const heightRandom = ["30px", "45px"];
    const widthRandom = ["400px", "520px"];
    const render = _.map(rangeUser, (item, index) => {
      const randomNumber = _.random(0, 1);
      return (
        <MessageSkeleton
          className={`${typeMessage[randomNumber]}`}
          style={{
            width: widthRandom[randomNumber],
            height: heightRandom[randomNumber],
          }}
          key={`message-skeleton-${index}`}
        />
      );
    });
    return render;
  };

  const generateMessageStaus = (status: string) => {
    if (status === "New") return t("label.sent");
    if (status === "Received") return t("label.seen");
  };

  const renderMessages = () => {
    const username = _.get(getSavedUserData(), "username");
    const render = _.map(currentDirectMessage, (messages, key) => {
      const renderMessage = _.map(messages, (message, index) => {
        const messageId = _.get(message, "id");
        const currentUsername = _.get(message, "sender.username");
        const messageType = _.get(message, "type");
        const isSelfMessage = currentUsername === username ? "self" : "";
        return (
          <MessageItem
            key={`message-${messageId || Math.floor(Math.random() * 1000)}`}
            className={`${isSelfMessage} ${
              _.get(message, "type") === "sending" ? "sending" : ""
            }`}
          >
            <p>{_.get(message, "message")}</p>

            <div className="infor">
              {messageType ? (
                <span>{t("label.sending")}</span>
              ) : (
                <>
                  <span className="status">
                    {generateMessageStaus(_.get(message, "status"))}
                  </span>
                  <span>-</span>
                  <span>
                    {_.get(message, "time")}
                  </span>
                </>
              )}
            </div>
          </MessageItem>
        );
      });
      return (
        <React.Fragment key={`messages-date-${key}`}>
          <DateLine>
            <span />
            <p>{key}</p>
            <span />{" "}
          </DateLine>
          {renderMessage}
        </React.Fragment>
      );
    });
    return render;
  };

  const renderChatbox = () => {
    return (
      <ContactChatbox>
        <HeaderChatbox>
          <img src={receiverAvatar || Icons.defaultUser} alt="" />
          <div className="message_information">
            <span className="username">{receiverName}</span>
          </div>
        </HeaderChatbox>
        <MessageChatbox ref={messagesRef}>
          <div>
            {fetchAllMessageIsLoading
              ? renderSkeletonMessage()
              : renderMessages()}
          </div>
        </MessageChatbox>
        <TypingField className={`${isTyping ? "typing" : ""}`}>
          <TypingDots>
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
          </TypingDots>
          {receiverName} is typing
        </TypingField>
        <InputChatbox>
          <input
            type="text"
            placeholder={`${t("placeholder.typeMessageHere")}...`}
            value={message}
            onChange={(e: any) => onTyping(e)}
            onKeyDown={(e: any) =>
              e.keyCode === 13 && _.trim(message) && onSendMessage()
            }
          />
          <div
            className={`send-btn ${_.trim(message) ? "" : "disabled"}`}
            onClick={() => _.trim(message) && onSendMessage()}
          >
            <i className="fas fa-paper-plane" />
          </div>
        </InputChatbox>
      </ContactChatbox>
    );
  };

  const renderEmptyReceiver = () => {
    return (
      <ContactChatbox>
        <h4>{t("label.selectSomeOneFirst")}</h4>
      </ContactChatbox>
    );
  };

  const renderMain = () => {
    return (
      <ContactContainer>
        {renderListUser()}
        {currentReceiverId ? renderChatbox() : renderEmptyReceiver()}
      </ContactContainer>
    );
  };

  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.CLIENT_ROUTES.CONTACT}
      title={t("titlePage.contact")}
    />
  );
};

export default Contact;
