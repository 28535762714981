import { combineReducers } from "redux";
import Authentication from "./Authentication.reducer";
import Categories from "./Categories.reducer";
import SourceSettings from "./SourceSettings.reducer";
import FileRecord from "./FileRecord.reducer";
import Logs from "./Logs.reducer";
import Users from "./Users.reducer";
import DataRecord from "./DataRecord.reducer";
import Clients from "./Clients.reducer";
import Reports from "./Reports.reducer";
import Account from "./Account.reducer";
import Role from "./Role.reducer";
import Filters from "./Filters.reducer";
import Folders from "./Folders.reducer";
import DistributionDelivery from "./DistributionDelivery.reducer";
import EmailDelivery from "./EmailDelivery.reducer";
import EmailTemplate from "./EmailTemplate.reducer";
import PDFTemplate from "./PDFTemplate.reducer";
import Post from "./Post.reducer";
import Dashboard from "./Dashboard.reducer";
import Member from "./Member.reducer";
import Message from "./Message.reducer";
import Config from "./Config.reducer";

const rootReducer = combineReducers({
  Authentication,
  Categories,
  SourceSettings,
  FileRecord,
  Logs,
  Users,
  DataRecord,
  Clients,
  Reports,
  Account,
  Role,
  Filters,
  Folders,
  DistributionDelivery,
  EmailDelivery,
  EmailTemplate,
  PDFTemplate,
  Post,
  Dashboard,
  Member,
  Message,
  Config
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
