import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Document,
  Page,
  pdf,
  Font,
} from "@react-pdf/renderer";
import _ from "lodash";
import dayjs from "dayjs";

const BeVietnamBold = require("assets/fonts/BeVietnam-Bold.ttf");
const BeVietnamRegular = require("assets/fonts/BeVietnam-Regular.ttf");

Font.register({
  family: "Be Vietnam",
  fonts: [
    {
      src: BeVietnamRegular,
    },
    {
      src: BeVietnamBold,
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  page: {
    backgroundColor: "#E4E4E4",
    fontFamily: "Be Vietnam",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 21,
    lineHeight: 1.2,
    textAlign: "left",
    marginTop: 10,
    paddingBottom: 10,
    fontWeight: "bold",
    fontFamily: "Be Vietnam",
    borderBottom: "1px solid black",
  },
  text: {
    fontSize: 13,
    lineHeight: 1.5,
    textAlign: "left",
    fontFamily: "Be Vietnam",
  },
  line: {
    width: "100%",
    borderBottom: "1px solid black",
  },
  sourceAndAuthor: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Be Vietnam",
  },
  dataInfo: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 11,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

interface SectionProps {
  data: any;
}

const PDFFile = (props: SectionProps) => {
  const { data } = props;

  const MyDoc = (
    <Document>
      <Page wrap style={styles.body}>
        {_.map(data, (item, index) => {
          return (
            <View key={`post-${index}`} break={_.toNumber(index) > 0}>
              <View>
                <Text style={styles.title}>{_.get(item, "title")}</Text>
              </View>
              <View style={styles.dataInfo}>
                <Text style={styles.sourceAndAuthor}>
                  {_.get(item, "source.name")} - {_.get(item, "author")}
                </Text>
                <Text style={styles.sourceAndAuthor}>
                  {_.capitalize(_.get(item, "source.mediaType"))} -{" "}
                  {dayjs(_.get(item, "publishedAt")).format("YYYY, MMM D")}
                </Text>
              </View>
              <Text style={styles.text}>{_.get(item, "content")}</Text>
            </View>
          );
        })}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );

  const blob = pdf(MyDoc).toBlob();

  return blob;
};

export default PDFFile;
