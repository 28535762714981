/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import _ from "lodash";

import {
  Dropdown,
  Button,
  Input,
  FormGroup,
  FormLabel,
} from "components/Common";

import {
  AccountActions,
  AccountOfClientActions,
  UserOfClientActions,
  UsersActions,
} from "actions";
import {
  TemplateDetailsContent,
  TemplateDetailsContainer,
  TemplateDetailsForm,
  ButtonField,
  TemplateDetailsHeader,
  LoadingLayer,
  Spinner,
} from "../Popup.styles";
import { useTranslation } from "react-i18next";
import { popupAlert, processDataDropDownAccount } from "libs/Utils";

const { fetchPackagesForUserPortal } = AccountActions;
const { updateAccountListUser, setDefaultUpdatePackageCode } = UsersActions;
const { updateAccountListUserOfClient } = UserOfClientActions;
const { fetchAccountsOfClient } = AccountOfClientActions;

interface SectionProps {
  onClose: (isFetch?: boolean) => void;
  data: any;
  isAdmin?: boolean;
}

const UserDetails: React.FC<SectionProps> = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { onClose, data, isAdmin } = props;
  const dispatch = useDispatch();
  const packages = useSelector((state: RootState) => {
    return state.Account.packagesForUserPotal;
  });
  const packagesPayload = useSelector((state: RootState) => {
    return state.Account.accounts;
  });
  const packagesIsLoading = useSelector((state: RootState) => {
    return state.Account.isLoading;
  });
  const usersIsLoading = useSelector((state: RootState) => {
    return state.Users.isLoading;
  });
    const userPackageCode = useSelector((state: RootState) => {
    return state.Users.changePackageCode;
  });
  const [accountId, setAccountId] = useState<string[]>([]);
  const [heightContent, setHeightContent] = useState<string>("200px");

  const packageDropdown = processDataDropDownAccount(
    isAdmin ? packages : packagesPayload.items
  );

  useEffect(() => {
    const clientId = _.get(data, "clientId.id");
    const getAccountId: string[] = [];
    const accountManager = _.get(data, "accountManageUser");
    _.forEach(accountManager, (account) =>
      getAccountId.push(_.get(account, "accountId.id"))
    );
    setAccountId(getAccountId);
    if (isAdmin) dispatch(fetchPackagesForUserPortal(clientId));
    else dispatch(fetchAccountsOfClient());

    setTimeout(() => {
      setHeightContent("380px");
    }, 150);

    return () => {
      dispatch(setDefaultUpdatePackageCode());
    };
  }, []);

  useEffect(() => {
    if (userPackageCode === 2 && onClose) onClose(true); 
  }, [userPackageCode]);

  const createAccount = async () => {
    const userId = _.get(data, "id");
    if (_.isEmpty(accountId))
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: t("alert.atLeastOnePackage"),
      });

    const accountAddId = [...accountId];
    const accountDeleteId: string[] = [];
    _.forEach(packageDropdown, (item) => {
      const value = _.get(item, "value");
      if (!_.includes(accountId, value)) accountDeleteId.push(value);
    });

    if (isAdmin)
      await dispatch(
        updateAccountListUser(userId, { accountAddId, accountDeleteId })
      );
    else
      await dispatch(
        updateAccountListUserOfClient(userId, { accountAddId, accountDeleteId })
      );
  };

  return (
    <TemplateDetailsContainer>
      <TemplateDetailsContent width="400px">
        {usersIsLoading && (
          <LoadingLayer>
            <Spinner />
          </LoadingLayer>
        )}
        <TemplateDetailsHeader>
          {t("headerPopup.changePackage")}
        </TemplateDetailsHeader>
        <TemplateDetailsForm height={heightContent}>
          <FormGroup>
            <FormLabel>{t("label.currentUser")}</FormLabel>
            <Input disabled value={_.get(data, "userData.fullName")} />
          </FormGroup>
          <FormGroup>
            <FormLabel aria-label="require">{t("label.package")}</FormLabel>
            <Dropdown
              value={accountId}
              width="100%"
              callbackPayload={(value: any) => setAccountId(value)}
              options={packageDropdown}
              placeholder={t("placeholder.package")}
              search={false}
              loading={packagesIsLoading}
              multiple
            />
          </FormGroup>
        </TemplateDetailsForm>
        <ButtonField>
          <Button
            type="submit"
            onClick={() => createAccount()}
            buttonWidth="100px"
            buttonMargin="0 10px 0 0"
          >
            {t("button.save")}
          </Button>
          <Button type="submit" onClick={() => onClose()} buttonWidth="100px">
            {t("button.cancel")}
          </Button>
        </ButtonField>
      </TemplateDetailsContent>
    </TemplateDetailsContainer>
  );
};

export default UserDetails;
