import React, { useEffect, useState } from "react";
import _ from "lodash";

import { getSavedUserData, popupAlert, validatePassword } from "libs/Utils";

import { ROUTES } from "constants/Constants";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Input,
  Button,
  Checkbox,
  FormDetails,
  FormGroup,
  FormInline,
  FormLabel,
  ButtonField,
  Avatar,
} from "components/Common";
// import { Container, ContainerForm } from "./Profile.styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { UsersActions } from "actions";
import { Images } from "../../../themes";

const { updateUserForSelf } = UsersActions;

const Profile = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const userIsLoading = useSelector((state) => _.get(state, "Users.isLoading"));
  const isUpdateSuccess = useSelector((state) =>
    _.get(state, "Users.isUpdateSuccess")
  );
  const [avatar, setAvatar] = useState();
  const [urlPreview, setUrlPreview] = useState("");
  const [userData, setUserData] = useState<any>({});
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [isUpdatePassword, setIsUpdatePassword] = useState<boolean>(false);
  const onReset = () => {
    const savedUserData = getSavedUserData();
    const avatar = _.get(savedUserData, "userData.avatar");
    if (avatar) {
      const getImagePath = _.get(avatar, "imagePath");
      setUrlPreview(getImagePath);
    }
    if (!_.isEmpty(savedUserData))
      setUserData({ ...savedUserData, password: "" });
    setIsUpdatePassword(false);
  };

  useEffect(() => {
    onReset();

    return () => {
      setUserData({});
      setIsEdited(false);
    };
  }, []);

  useEffect(() => {
    if (isUpdateSuccess === 2) onReset();
  }, [isUpdateSuccess]);

  const onChangeInput = (key: string, value: string) => {
    const newUserData = {
      ...userData,
      [key]: value,
    };
    setUserData(newUserData);
  };

  const handleResetForm = async () => {
    if (isEdited) {
      const isAgree = await popupAlert({
        title: t("alert.warning"),
        text: `${t("alert.warningBeforeResetProfile")}`,
        type: "warning",
        buttons: ["No", true],
      });
      if (isAgree) onReset();
    }
  };

  const processFormData = () => {
    const formData = new FormData();
    formData.append("email", _.trim(_.get(userData, "email")));
    formData.append("username", _.trim(_.get(userData, "username")));
    formData.append(
      "userData[firstName]",
      _.trim(_.get(userData, "userData.firstName"))
    );
    formData.append(
      "userData[lastName]",
      _.trim(_.get(userData, "userData.lastName"))
    );
    formData.append(
      "userData[address]",
      _.trim(_.get(userData, "userData.address"))
    );
    formData.append(
      "userData[phoneNumber]",
      _.trim(_.get(userData, "userData.phoneNumber"))
    );
    if (avatar) formData.append("userData[avatar]", avatar || "");
    if (isUpdatePassword) {
      formData.append("password", _.trim(_.get(userData, "newPassword")));
      formData.append(
        "confirmationPassword",
        _.trim(_.get(userData, "confirmPassword"))
      );
    }
    return formData;
  };

  const onSubmit = async () => {
    const validateData = [
      {
        label: t("label.firstName"),
        value: _.get(userData, "userData.firstName"),
      },
      {
        label: t("label.lastName"),
        value: _.get(userData, "userData.lastName"),
      },
      {
        label: t("label.address"),
        value: _.get(userData, "userData.address"),
      },
      {
        label: t("label.phoneNumber"),
        value: _.get(userData, "userData.phoneNumber"),
      },
    ];
    if (isUpdatePassword) {
      const newPassword = _.get(userData, "newPassword");
      const confirmPassword = _.get(userData, "confirmPassword");
      validateData.push(
        ...[
          // {
          //   value: _.get(userData, "password"),
          //   label: t("label.password"),
          // },
          {
            value: _.get(userData, "newPassword"),
            label: t("label.newPassword"),
          },
          {
            value: _.get(userData, "confirmPassword"),
            label: t("label.confirmPassword"),
          },
        ]
      );
      if (newPassword !== confirmPassword)
        return await popupAlert({
          title: t("alert.warning"),
          text: t("alert.warningPasswordAndConfirmPasswordNotMatch"),
          type: "warning",
        });
    }

    let isValid = true;
    let message = "";
    _.map(validateData, (item) => {
      const { value, label } = item;
      if (_.isEmpty(_.trim(value))) {
        isValid = false;
        message = message + `${label}\n`;
      }
    });

    if (!isValid)
      return await popupAlert({
        title: t("alert.warning"),
        text: `${t("alert.foundEmptyFields")} ${message}`,
        type: "warning",
      });
    if (isUpdatePassword) {
      const valid = validatePassword({
        password: {
          label: t("label.password"),
          value: _.get(userData, "newPassword"),
        },
        confirmPassword: {
          label: t("label.confirmPassword"),
          value: _.get(userData, "confirmPassword"),
        },
        rules: {
          atLeastChars: 6,
          atLeastOneDigit: true,
          atLeastOneLowerCase: true,
        },
      });

      if (!valid) return;
    }

    const data = processFormData();
    await dispatch(updateUserForSelf(data));
  };

  const onSelectFile = (e: any) => {
    if (e.target.files[0]) {
      const url = URL.createObjectURL(e.target.files[0]);
      setAvatar(e.target.files[0]);
      setUrlPreview(url);
    } else return;
  };

  const renderMain = () => {
    return (
      <FormDetails>
        <FormInline>
          <FormGroup>
            <Avatar
              src={urlPreview || Images.defaultPhoto}
              onChange={(e: any) => onSelectFile(e)}
            />
          </FormGroup>
        </FormInline>
        <FormInline>
          <FormGroup>
            <FormLabel aria-label="require">{t("label.username")}</FormLabel>
            <Input
              value={_.get(userData, "username") || ""}
              width="100%"
              placeholder={t("placeholder.username")}
              onChangeHandler={(e) => onChangeInput("username", e.target.value)}
              disabled
            />
          </FormGroup>
          <FormGroup>
            <FormLabel aria-label="require">{t("label.email")}</FormLabel>
            <Input
              value={_.get(userData, "email") || ""}
              width="100%"
              disabled
            />
          </FormGroup>
        </FormInline>
        <FormInline>
          <FormGroup>
            <FormLabel aria-label="require">{t("label.firstName")}</FormLabel>
            <Input
              value={_.get(userData, "userData.firstName") || ""}
              onChangeHandler={(e) =>
                onChangeInput("userData", {
                  ...userData.userData,
                  firstName: e.target.value,
                })
              }
              width="100%"
              placeholder={t("placeholder.firstName")}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel aria-label="require">{t("label.lastName")}</FormLabel>
            <Input
              value={_.get(userData, "userData.lastName") || ""}
              onChangeHandler={(e) =>
                onChangeInput("userData", {
                  ...userData.userData,
                  lastName: e.target.value,
                })
              }
              width="100%"
              placeholder={t("placeholder.lastName")}
            />
          </FormGroup>
        </FormInline>
        <FormInline>
          <FormGroup>
            <FormLabel aria-label="require">{t("label.address")}</FormLabel>
            <Input
              value={_.get(userData, "userData.address") || ""}
              placeholder={t("placeholder.address")}
              onChangeHandler={(e) =>
                onChangeInput("userData", {
                  ...userData.userData,
                  address: e.target.value,
                })
              }
              width="100%"
            />
          </FormGroup>
          <FormGroup>
            <FormLabel aria-label="require">{t("label.phoneNumber")}</FormLabel>
            <Input
              value={_.get(userData, "userData.phoneNumber") || ""}
              placeholder={t("placeholder.phoneNumber")}
              onChangeHandler={(e) =>
                onChangeInput("userData", {
                  ...userData.userData,
                  phoneNumber: e.target.value,
                })
              }
              width="100%"
              isNum
            />
          </FormGroup>
        </FormInline>
        <Checkbox
          checked={isUpdatePassword}
          context={t("label.alsoUpdateThePassword")}
          onChangeHandler={(type: boolean) => {
            setIsUpdatePassword(!isUpdatePassword);
            setIsEdited(true);
          }}
        />
        {/* <FormGroup>
            <FormLabel aria-label='require'>
              {t("label.oldPassword")}
              {isUpdatePassword && "(*)"}
            </FormLabel>
            <Input
              value={_.get(userData, "password") || ""}
              margin="10px 20px 10px 0"
              onChangeHandler={(e) => onChangeInput("password", e.target.value)}
              placeholder={t("placeholder.oldPassword")}
              width="100%"
              type="password"
              disabled={!isUpdatePassword}
            />
          </FormGroup> */}
        <FormInline>
          <FormGroup>
            <FormLabel aria-label="require">
              {t("label.newPassword")}
              {isUpdatePassword && "(*)"}
            </FormLabel>
            <Input
              value={_.get(userData, "newPassword") || ""}
              onChangeHandler={(e) =>
                onChangeInput("newPassword", e.target.value)
              }
              placeholder={t("placeholder.newPassword")}
              width="100%"
              type="password"
              disabled={!isUpdatePassword}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel aria-label="require">
              {t("label.confirmNewPassword")}
            </FormLabel>
            <Input
              value={_.get(userData, "confirmPassword") || ""}
              onChangeHandler={(e) =>
                onChangeInput("confirmPassword", e.target.value)
              }
              placeholder={t("placeholder.confirmNewPassword")}
              width="100%"
              type="password"
              disabled={!isUpdatePassword}
            />
          </FormGroup>
        </FormInline>
        <ButtonField margin="20px 0 50px 0">
          <Button onClick={() => onSubmit()} buttonMargin="0 10px 0 0">
            {t("button.save")}
          </Button>
          <Button
            onClick={() => handleResetForm()}
            buttonMargin="0"
            disabled={!isEdited}
          >
            {t("button.reset")}
          </Button>
        </ButtonField>
      </FormDetails>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      loading={userIsLoading}
      currentPage={ROUTES.PROFILE}
      title={t("titlePage.profile")}
    />
  );
};

export default Profile;
