import ActionTypes from "constants/ActionTypes";
import API from "api";
import _ from "lodash";
import { responseAlert } from "libs/alert";

const clearFolders = () => {
  return {
    type: ActionTypes.CLEAR_FOLDERS,
  };
};

const clearFolderDetails = () => {
  return {
    type: ActionTypes.CLEAR_FOLDER_DETAILS,
  };
};

const folderFail = () => {
  return {
    type: ActionTypes.FOLDER_FAILURE,
  };
};

const folderLoading = () => {
  return {
    type: ActionTypes.FOLDER_REQUEST,
  };
};

const folderSuccess = () => {
  return {
    type: ActionTypes.FOLDER_SUCCESS,
  };
};

const createFolderSuccess = () => {
  return {
    type: ActionTypes.CREATE_FOLDER_SUCCESS,
  };
};

const updateFolderSuccess = (payload = {}) => {
  return {
    type: ActionTypes.UPDATE_FOLDER_SUCCESS,
    payload,
  };
};

const fetchFoldersSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_FOLDER_SUCCESS,
    payload,
  };
};

const searchFoldersSuccess = (payload = {}) => {
  return {
    type: ActionTypes.SEARCH_FOLDER_SUCCESS,
    payload,
  };
};

const getFolderByIdSuccess = (payload = {}) => {
  return {
    type: ActionTypes.GET_FOLDER_BY_ID_SUCCESS,
    payload,
  };
};

const addPostToFolderSuccess = (payload = {}) => {
  return {
    type: ActionTypes.ADD_POST_TO_FOLDER_SUCCESS,
    payload,
  };
};

const createFolder = (data: any) => {
  return async (dispatch: any) => {
    dispatch(folderLoading());
    await API.createFolders(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Folder created" });
          dispatch(createFolderSuccess());
        } else {
          responseAlert({ res });
          dispatch(folderFail());
        }
      })
      .catch((e: any) => {
        dispatch(folderFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchFolders = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(folderLoading());
    await API.fetchFolders(payload)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchFoldersSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(folderFail());
        }
      })
      .catch((e: any) => {
        dispatch(folderFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getFolderByID = (id: string) => {
  return async (dispatch: any) => {
    dispatch(folderLoading());
    await API.getFolderById(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(getFolderByIdSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(folderFail());
        }
      })
      .catch((e: any) => {
        dispatch(folderFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const searchFolders = (data: any) => {
  return async (dispatch: any) => {
    dispatch(folderLoading());
    await API.searchFolders(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(searchFoldersSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(folderFail());
        }
      })
      .catch((e: any) => {
        dispatch(folderFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const updateFolder = (data: any, id: any) => {
  return async (dispatch: any) => {
    dispatch(folderLoading());
    await API.updateFolders(data, id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Folder updated" });
          dispatch(updateFolderSuccess(res.payload));
        } else {
          responseAlert({ res });
          dispatch(folderFail());
        }
      })
      .catch((e: any) => {
        dispatch(folderFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const deactivateFolder = (id: string) => {
  return async (dispatch: any) => {
    dispatch(folderLoading());
    await API.deactivateFolder(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Folder deactivated" });
          dispatch(folderSuccess());
        } else {
          responseAlert({ res });
          dispatch(folderFail());
        }
      })
      .catch((e: any) => {
        dispatch(folderFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const activateFolder = (id: string) => {
  return async (dispatch: any) => {
    dispatch(folderLoading());
    await API.activateFolder(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Folder activated" });
          dispatch(folderSuccess());
        } else {
          responseAlert({ res });
          dispatch(folderFail());
        }
      })
      .catch((e: any) => {
        dispatch(folderFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const addDataRecordToFolder = (data: any) => {
  return async (dispatch: any) => {
    dispatch(folderLoading());
    await API.addDataRecordToFolder(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          const buttons = {
            cancel: {
              text: "Close",
              value: null,
              visible: true,
              className: "",
              closeModal: true,
            },
            confirm: {
              text: "View details",
              value: true,
              visible: true,
              className: "",
              closeModal: true,
              focus: true,
            },
          };
          const selectedChoice = await responseAlert({
            res,
            label: "Add data record to folder",
            buttons,
          });
          const resolveMessage = selectedChoice ? _.get(res, "payload") : {};
          dispatch(addPostToFolderSuccess(resolveMessage));
        } else {
          responseAlert({ res });
          dispatch(folderFail());
        }
      })
      .catch((e: any) => {
        dispatch(folderFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const deletePostAddedFromFolder = (data: any) => {
  return async (dispatch: any) => {
    dispatch(folderLoading());
    await API.deletePostInFolder(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Remove data record from folder" });
          dispatch(folderSuccess());
        } else {
          responseAlert({ res });
          dispatch(folderFail());
        }
      })
      .catch((e: any) => {
        dispatch(folderFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

export {
  createFolder,
  fetchFolders,
  getFolderByID,
  searchFolders,
  updateFolder,
  deactivateFolder,
  activateFolder,
  addDataRecordToFolder,
  clearFolderDetails,
  deletePostAddedFromFolder,
  clearFolders,
};
