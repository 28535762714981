import Api from "./Base";
import { API } from "constants/Constants";

const { AUTHENTICATION } = API;

export function login(data: any) {
  return Api.authRequest(AUTHENTICATION.LOGIN, data);
}

export function loginAs(userId: string) {
  return Api.post(AUTHENTICATION.LOGIN_AS, { userId });
}

export function refreshToken(refresh_token: string) {
  return Api.post(AUTHENTICATION.REFRESH_TOKEN, { refresh_token });
}
