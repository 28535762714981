import styled from "styled-components";
import ColorName from "constants/Variables";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  & .seperate {
    padding: 3em;
    display: flex;
    align-items: center;
    color: ${ColorName.william};
    font-weight: bold;
  }
`;

export { Container };
