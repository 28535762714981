/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { RootState } from "reducers";
import { ClientActions, FoldersActions } from "actions";

import TableUltils from "libs/TableUltils";

import Table from "components/Common/Table";
import { CONSTANTS } from "constants/Constants";
import ColorName from "constants/Variables";
import RouteTypes from "constants/RouteTypes";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Button,
  Checkbox,
  Input,
  ContainerSearch,
  FlexWrapRow,
} from "components/Common";
import {
  FolderDetails,
  LogsDetails,
  ViewAddedPost,
} from "components/Common/Popup";
import { useTranslation } from "react-i18next";
import { generateFilter, popupAlert } from "libs/Utils";

interface FilterStructure {
  folderName: string;
  accountName: string;
  clientName: string;
  status: string;
  page: number;
  limit: number;
  sortBy: string;
  orderBy: string;
}

const DEFAULT_FILTER = {
  folderName: "",
  accountName: "",
  clientName: "",
  status: CONSTANTS.STATUS.ACTIVE,
  page: 1,
  limit: 10,
  sortBy: "updatedAt",
  orderBy: "DESC",
};

const InputStyleProps = {
  margin: "10px 10px 10px 0",
  width: "200px",
};

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "10px 10px 10px 0",
};

const { fetchClients } = ClientActions;
const { searchFolders, activateFolder, deactivateFolder, clearFolders } =
  FoldersActions;

const Folders = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const foldersIsLoading = useSelector((state: RootState) => {
    return state.Folders.isLoading;
  });
  const folders = useSelector((state: RootState) => {
    return state.Folders.folders;
  });

  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const [folderId, setFolderId] = useState<string>("");
  const [isShowDetailsPopup, setIsShowDetailsPopup] = useState<boolean>(false);
  const [isShowViewPostPopup, setIsShowViewPostPopup] =
    useState<boolean>(false);
  const [logData, setLogData] = useState<any>({});
  const [dataPopup, setDataPopup] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataFolders, setDataFolders] = useState<any>([]);

  useEffect(() => {
    dispatch(searchFolders(generateFilter(filter)));
    return () => {
      dispatch(clearFolders());
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(folders)) {
      const getDataFolder: any[] = _.get(folders, "items");
      if (currentPage > 1) {
        setDataFolders([...dataFolders, ...getDataFolder]);
      } else setDataFolders(getDataFolder);
    }
  }, [folders]);

  const dataHead = [
    "no",
    "folderName",
    "clientNumber",
    "clientName",
    "packageCode",
    "packageName",
    "modifiedTime",
    "status",
    "actions",
  ];

  const dataTable = TableUltils._processDataTable(
    [
      "no",
      "id",
      "folderName",
      "clientId.clientNumber",
      "clientId.clientName",
      "accountId.accountCode",
      "accountId.accountName",
      "createdAt",
      "status",
      "action",
    ],
    dataFolders
  );
  const listColumnCenter = [1, 3, 7, 8, 9];

  const addFilter = (page: number) => {
    const newFilter = {
      ...filter,
      page: page,
    };
    setFilter(newFilter);
    setCurrentPage(page);
    dispatch(searchFolders(generateFilter(newFilter)));
  };

  const onReset = async () => {
    setFilter(DEFAULT_FILTER);
    setCurrentPage(1);
    dispatch(searchFolders(generateFilter(DEFAULT_FILTER)));
  };

  const callbackCurrentPageLink = (page: number) => {
    const newFilter: any = {
      ...filter,
      page,
    };
    setFilter(newFilter);
    dispatch(searchFolders(generateFilter(newFilter)));
  };

  const setValueStatus = (status: string) => {
    const newFilter = {
      ...filter,
      status,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(searchFolders(generateFilter(newFilter)));
    setCurrentPage(1);
  };

  const callbackAction = async (action: string, id: string, item: object) => {
    const getItem = _.find(folders.items, {
      folderName: _.get(item, "folderName"),
    });
    const folderId = _.get(getItem, "id");
    if (action === CONSTANTS.ACTION.EDIT) {
      if (id) {
        // dispatch(fetchClients());
        const findFolder = _.find(folders?.items, { id });
        setDataPopup(findFolder || {});
      }
      setIsShowDetailsPopup(true);
    }
    if (action === CONSTANTS.ACTION.VIEW) {
      setFolderId(folderId);
      setIsShowViewPostPopup(true);
    }
    if (action === CONSTANTS.ACTION.LOG) {
      setLogData({ logType: "folder", rowId: folderId });
    }
    if (action === CONSTANTS.ACTION.DEACTIVATE) {
      const folderName = _.get(item, "folderName");
      const currentStatus = _.get(item, "status");
      const statusLabel =
        currentStatus === CONSTANTS.STATUS.ACTIVE
          ? t("alert.deactive")
          : t("alert.active");
      const content = document.createElement("span");
      content.innerHTML = `${t(
        "alert.areYouSureYouWantTo"
      )}</br> <b>${statusLabel}</b> ${t(
        "alert.theFolder"
      )} <b>${folderName}</b>  ?`;
      popupAlert({
        type: "warning",
        title: t("alert.warning"),
        content,
        dangerMode: true,
        buttons: true,
      }).then(async (willContinue) => {
        if (willContinue) {
          if (currentStatus === CONSTANTS.STATUS.ACTIVE)
            await dispatch(deactivateFolder(id));
          else await dispatch(activateFolder(id));
          await dispatch(searchFolders(generateFilter(filter)));
        }
      });
    }
  };

  const onCreate = () => {
    setIsShowDetailsPopup(true);
    dispatch(fetchClients());
  };

  const onCloseFolderDetails = async () => {
    dispatch(searchFolders(generateFilter(filter)));
    setIsShowDetailsPopup(false);
    setDataPopup({});
  };

  const onFetchMore = () => {
    if (!foldersIsLoading && currentPage < _.get(folders, "meta.totalPages"))
      addFilter(filter.page + 1);
  };

  const onShowMore = () => {
    if (!foldersIsLoading && currentPage < _.get(folders, "meta.totalPages"))
      addFilter(filter.page + 1);
  };

  const _renderTable = () => {
    return (
      <Table
        dataHeader={dataHead}
        dataTable={dataTable}
        fitColumns={[1, 8, 9]}
        listColumnCenter={listColumnCenter}
        hasPagination={true}
        callbackCurrentPageLink={callbackCurrentPageLink}
        metaPagination={folders.meta}
        callBackAction={callbackAction}
        listActionItem={[
          CONSTANTS.ACTION.EDIT,
          CONSTANTS.ACTION.DEACTIVATE,
          CONSTANTS.ACTION.LOG,
          CONSTANTS.ACTION.VIEW,
        ]}
        loading={foldersIsLoading}
        showMore={onShowMore}
      />
    );
  };

  const renderMain = () => {
    return (
      <>
        {isShowViewPostPopup && (
          <ViewAddedPost
            onClose={() => setIsShowViewPostPopup(false)}
            folderId={folderId}
          />
        )}
        {!_.isEmpty(logData) && (
          <LogsDetails onClose={() => setLogData({})} data={logData} />
        )}
        {isShowDetailsPopup && (
          <FolderDetails
            onClose={() => onCloseFolderDetails()}
            folder={dataPopup}
          />
        )}
        <ContainerSearch className={foldersIsLoading ? "loading" : ""}>
          <FlexWrapRow>
            <Input
              value={_.get(filter, "folderName")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, folderName: e.target.value })
              }
              onEnter={() => addFilter(1)}
              placeholder={t("placeholder.folderName")}
              {...InputStyleProps}
            />
            <Input
              value={_.get(filter, "clientName")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, clientName: e.target.value })
              }
              onEnter={() => addFilter(1)}
              placeholder={t("placeholder.clientName")}
              {...InputStyleProps}
            />
            <Input
              value={_.get(filter, "accountName")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, accountName: e.target.value })
              }
              onEnter={() => addFilter(1)}
              placeholder={t("placeholder.packageName")}
              {...InputStyleProps}
            />
            <Button onClick={() => addFilter(1)} {...ButtonStyleProps}>
              {t("button.search")}
            </Button>
            <Button onClick={() => onReset()} {...ButtonStyleProps}>
              {t("button.reset")}
            </Button>
            <Button
              onClick={() => onCreate()}
              background={ColorName.william}
              {...ButtonStyleProps}
            >
              + {t("button.create")}
            </Button>
          </FlexWrapRow>
          <Checkbox
            checked={_.get(filter, "status") === CONSTANTS.STATUS.INACTIVE}
            context={t("label.showInactiveFolders")}
            onChangeHandler={(e: boolean) =>
              setValueStatus(
                e ? CONSTANTS.STATUS.INACTIVE : CONSTANTS.STATUS.ACTIVE
              )
            }
            margin="10px 0 0 0"
          />
        </ContainerSearch>
        {_renderTable()}
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      title={t("titlePage.folders")}
      currentPage={RouteTypes.ADMIN_ROUTES.FOLDERS}
      parent={RouteTypes.ADMIN_ROUTES.FOLDERS}
      onScrollBottom={() => onFetchMore()}
    />
  );
};

export default Folders;
