import ActionTypes from "constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  SMTPdetail: {},
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.CLEAR_SYSTEM_CONFIG:
      return {
        ...state,
        SMTPdetail: {},
      };
    case ActionTypes.SYSTEM_CONFIG_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FETCH_SYSTEM_CONFIG_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_SYSTEM_CONFIG_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        SMTPdetail: payload,
      };
    case ActionTypes.UPDATE_SYSTEM_CONFIG_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.UPDATE_SYSTEM_CONFIG_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        SMTPdetail: payload,
      };
    case ActionTypes.TEST_EMAIL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.TEST_EMAIL_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
