import { SxProps, Theme } from '@mui/system';

import ColorName from "constants/Variables";

export const mainStyles: SxProps<Theme> = {
  flexGrow: 1,
  position: 'fixed',
  zIndex: 3,
};

export const fabStyle = (pos: boolean): SxProps<Theme> => {
  return {
    width: '40px',
    height: '40px',
    position: 'fixed',
    bottom: 16,
    right: 10,
    flexDirection: 'row',
    opacity: !pos ? '0' : '1',
    transition: 'all .3s',
  };
};

export const speedDialStyles: SxProps<Theme> = {
  position: 'fixed',
  bottom: 16,
  right: 16,
  '& .MuiFab-primary': {
    width: '40px',
    height: '40px',
    backgroundColor: `${ColorName.burntSienna}`,
    marginTop: -2,
  },
};

export const iconSpeedDialStyles: SxProps<Theme> = {
  color: `${ColorName.burntSienna}`,
  mt: 1,
};
