import React from "react";
import { render } from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { logger } from "redux-logger";
import {
  Router,
  // Route
} from "react-router";
import { routerMiddleware } from "react-router-redux";
import advancedFormat from "dayjs/plugin/advancedFormat";
import DayJsInstance from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CssBaseline } from "@mui/material";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import thunk from "redux-thunk";
import "./i18n";
import coreThemes from "./coreTheme";

import "./App.css";
import reducers from "./reducers";
import history from "./history";
import {
  PortalRoute,
  // ProtectedRoute,
  // ValidationRoute
} from "./router";
import * as serviceWorker from "./serviceWorker";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers =
  (window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as typeof compose) || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk, logger, routerMiddleware(history)))
);

// Create your own root div in the body element before rendering into it
const root = document.createElement("div");

// Add id 'root' and append the div to body element
root.id = "root";
document.body.appendChild(root);
DayJsInstance.extend(advancedFormat);

render(
  <LocalizationProvider dateAdapter={AdapterDayjs}
      dateLibInstance={DayJsInstance}>
    <Provider store={store}>
      <Router history={history}>
        {/* <ValidationRoute /> */}
        <StyledEngineProvider>
            <ThemeProvider theme={coreThemes}>
              <CssBaseline />
              <PortalRoute />
            </ThemeProvider>
        </StyledEngineProvider>
        {/* <ProtectedRoute exact path={ROUTES.HOMEPAGE} component={Overview} /> */}
        {/* <Route exact path={ROUTES.HOMEPAGE} component={Overview} /> */}
      </Router>
    </Provider>
  </LocalizationProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
