/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import {
  Grid,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { RootState } from "reducers";
import { SourceSettingsActions } from "actions";

import history from "../../../history";
import TableUltils from "libs/TableUltils";
import { generateFilter, popupAlert } from "libs/Utils";

import { CONSTANTS } from "constants/Constants";
import RouteTypes from "constants/RouteTypes";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import { Table, Checkbox, ContainerSearch } from "components/Common";
import { LogsDetails } from "components/Common/Popup";
import ColorName from "constants/Variables";

const {
  searchSourceSettings,
  deactivateSourceSetting,
  activateSourceSetting,
  clearSources,
} = SourceSettingsActions;

interface FilterStructure {
  name: string;
  code: string;
  status: string;
  limit: number;
  page: number;
  sortBy: string;
  orderBy: string;
  mediaType: string;
}

const DEFAULT_FILTER = {
  page: 1,
  limit: 20,
  name: "",
  code: "",
  status: CONSTANTS.STATUS.ACTIVE,
  sortBy: "updatedAt",
  orderBy: "DESC",
  mediaType: "all",
};

const SourceSettings: React.FC = () => {
  // Declare package
  const { t } = useTranslation("translation");
  const location = useLocation();
  const dispatch = useDispatch();
  // Declare reducers
  const sourcesIsLoading = useSelector((state: RootState) => {
    return state.SourceSettings.isLoading;
  });
  const sourceSettings = useSelector((state: RootState) => {
    return state.SourceSettings.sourceSettings;
  });
  // Declare state
  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const [logData, setLogData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataSources, setDataSources] = useState<any>([]);

  const MEDIA_TYPES = [
    {
      label: t("dropdown.all"),
      value: "all",
    },
    {
      label: "TV",
      value: "tv",
    },
    {
      label: t("dropdown.print"),
      value: "print",
    },
    {
      label: "Web",
      value: "web",
    },
    {
      label: t("dropdown.socialNetwork"),
      value: "social network",
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(clearSources());
    };
  }, []);

  useEffect(() => {
    const stateFilter: any = _.get(location, "state.filter");
    const prevRoute = _.get(location, "state.from");
    const resolveFilter = {
      ...filter,
    };
    if (stateFilter && prevRoute === RouteTypes.ADMIN_ROUTES.SOURCE_DETAILS) {
      _.assign(resolveFilter, { ...stateFilter });
      setFilter(stateFilter);
      history.replace({ ...history.location, state: {} });
    }

    dispatch(searchSourceSettings(generateFilter(resolveFilter)));
  }, [dispatch]);

  const dataHead = [
    "no",
    "sourceName",
    "sourceCode",
    "link",
    "mediaType",
    "status",
    "actions",
  ];

  const dataTable = TableUltils._processDataTable(
    ["no", "id", "name", "code", "link", "mediaType", "status", "action"],
    dataSources
  );

  const listColumnCenter = [1, 3, 5, 6, 7, 8, 9];

  const callbackCurrentPageLink = (e: number) => {
    const newFilter = {
      ...filter,
      page: e,
    };
    dispatch(searchSourceSettings(generateFilter(newFilter)));
  };

  const callbackAction = async (action: string, id: string, item: any) => {
    if (action === CONSTANTS.ACTION.EDIT) {
      history.push({
        pathname: RouteTypes.ADMIN_ROUTES.SOURCE_DETAILS,
        state: { id, filter },
      });
    }
    if (action === CONSTANTS.ACTION.LOG)
      setLogData({ logType: "source", rowId: id });

    if (action === CONSTANTS.ACTION.DEACTIVATE) {
      const sourceName = _.get(item, "name");
      const status = _.get(item, "status");
      const statusLabel =
        status === CONSTANTS.STATUS.ACTIVE
          ? t("alert.deactive")
          : t("alert.active");
      const content = document.createElement("span");
      content.innerHTML = `${t(
        "alert.areYouSureYouWantTo"
      )} <b>${statusLabel}</b> ${t(
        "alert.theSource"
      )}</br> <b>${sourceName}</b>  ?`;
      popupAlert({
        type: "warning",
        title: t("alert.warning"),
        content,
        dangerMode: true,
        buttons: true,
      }).then(async (willContinue) => {
        if (willContinue) {
          if (status === CONSTANTS.STATUS.ACTIVE)
            await dispatch(deactivateSourceSetting(id));
          else await dispatch(activateSourceSetting(id));
          await dispatch(
            searchSourceSettings(
              generateFilter({ ...filter, limit: 10, page: 1, status })
            )
          );
        }
      });
    }
  };

  useEffect(() => {
    if (!_.isEmpty(sourceSettings)) {
      const getDataRecords: any[] = _.get(sourceSettings, "items");
      if (currentPage > 1) {
        setDataSources([...dataSources, ...getDataRecords]);
      } else setDataSources(getDataRecords);
    }
  }, [sourceSettings]);

  const showMore = () => {
    if (
      !sourcesIsLoading &&
      currentPage < _.get(sourceSettings, "meta.totalPages")
    )
      searchFilter(filter.page + 1);
  };

  const _renderTable = () => {
    return (
      <Table
        dataHeader={dataHead}
        dataTable={dataTable}
        listActionItem={[
          CONSTANTS.ACTION.EDIT,
          CONSTANTS.ACTION.DEACTIVATE,
          CONSTANTS.ACTION.LOG,
        ]}
        callBackAction={callbackAction}
        fitColumns={[1, 3, 6, 7, 8, 9]}
        // listColumnCenter={listColumnCenter}
        hasPagination={true}
        callbackCurrentPageLink={callbackCurrentPageLink}
        metaPagination={sourceSettings?.meta}
        loading={sourcesIsLoading}
        type="source-settings"
        showMore={showMore}
      />
    );
  };

  const searchFilter = (page: number) => {
    const newFilter = {
      ...filter,
      page: page,
    };
    setFilter(newFilter);
    dispatch(searchSourceSettings(generateFilter(newFilter)));
    setCurrentPage(page);
  };

  const onChangeStatus = (st: string) => {
    const newFilter = { ...filter, status: st, page: 1 };
    setFilter(newFilter);
    dispatch(searchSourceSettings(generateFilter(newFilter)));
    setCurrentPage(1);
  };

  const onReset = async () => {
    setCurrentPage(1);
    setFilter(DEFAULT_FILTER);
    dispatch(searchSourceSettings(generateFilter(DEFAULT_FILTER)));
  };

  const onFetchMore = () => {
    if (
      !sourcesIsLoading &&
      currentPage < _.get(sourceSettings, "meta.totalPages")
    )
      searchFilter(filter.page + 1);
  };

  const renderMain = () => {
    return (
      <>
        {!_.isEmpty(logData) && (
          <LogsDetails onClose={() => setLogData({})} data={logData} />
        )}
        <ContainerSearch className={sourcesIsLoading ? "loading" : ""}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2}>
              <TextField
                key="kSourceName"
                value={_.get(filter, "name") || ""}
                label={t("placeholder.sourceName")}
                size="small"
                fullWidth
                onChange={(e) => setFilter({ ...filter, name: e.target.value })}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                key="kSourceCode"
                label={t("placeholder.sourceCode")}
                fullWidth
                size="small"
                value={_.get(filter, "code") || ""}
                onChange={(e) => setFilter({ ...filter, code: e.target.value })}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="select-label">Media Types</InputLabel>
                <Select
                  labelId="select-label"
                  value={_.get(filter, "mediaType")}
                  label="Media Types"
                  size="small"
                  onChange={(e) =>
                    setFilter({ ...filter, mediaType: e.target.value })
                  }
                >
                  {_.map(MEDIA_TYPES, (option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1.4}>
              <Button
                variant="contained"
                onClick={() => searchFilter(1)}
                fullWidth
                color="burntSienna"
                sx={{ fontFamily: "Be Vietnam", fontWeight: 600 }}
              >
                {t("button.search")}
              </Button>
            </Grid>
            <Grid item xs={1.4}>
              <Button
                variant="contained"
                onClick={() => onReset()}
                fullWidth
                color="burntSienna"
                sx={{ fontFamily: "Be Vietnam", fontWeight: 600 }}
              >
                {t("button.reset")}
              </Button>
            </Grid>
            <Grid item xs={1.4}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: ColorName.william,
                  fontWeight: 600,
                  fontFamily: "Be Vietnam",
                  "&:hover": { background: ColorName.piper },
                }}
                onClick={() =>
                  history.push({
                    pathname: RouteTypes.ADMIN_ROUTES.SOURCE_DETAILS,
                    state: { filter },
                  })
                }
                fullWidth
                color="success"
                startIcon={<AddIcon />}
              >
                {t("button.create")}
              </Button>
            </Grid>
          </Grid>
          <Checkbox
            checked={_.get(filter, "status") === CONSTANTS.STATUS.INACTIVE}
            context={t("label.showInactiveSources")}
            onChangeHandler={(e: boolean) =>
              onChangeStatus(
                e ? CONSTANTS.STATUS.INACTIVE : CONSTANTS.STATUS.ACTIVE
              )
            }
            margin="10px 0 0 0"
          />
        </ContainerSearch>
        {_renderTable()}
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.ADMIN_ROUTES.SOURCES_SETTINGS}
      parent={RouteTypes.ADMIN_ROUTES.MANAGE_SOURCES}
      title={t("titlePage.sourceSettings")}
      onScrollBottom={() => onFetchMore()}
    />
  );
};

export default SourceSettings;
