import styled from "styled-components";
import { HorizontalContainer } from "../CommonLayout";
import ColorName from "constants/Variables";

interface SectionProps {
  margin?: string;
}

const ComponentCheckBox = styled(HorizontalContainer)`
  display: flex;
  align-items: center;
  margin: ${(props: SectionProps) => props.margin || "unset"};
  color: ${ColorName.william};
  label {
    display: block;
    padding-left: 25px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
        &:after {
          display: block;
        }
      }
    }

    & .checkmark {
      position: absolute;
      top: 4px;
      left: 0;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      border: 2px solid ${ColorName.william};
      background-color: ${ColorName.white};

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 0px;
        top: 0px;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: ${ColorName.william};
      }
    }
  }
`;

export { ComponentCheckBox };
