import React, { useState } from 'react';
import {
	ComponentCheckBox
} from "./Radio.styles";

interface SectionProps {
	margin?: string;
	context?: string;
	checked?: boolean;
	onChangeHandler?: (checked: boolean, callbackPayload?: any) => void;
}

const Radio: React.FC<SectionProps> = (props: SectionProps) => {
	const { margin, context, checked, onChangeHandler } = props;
	const [callbackPayload, setCallbackPayload] = useState('');

	const handleChange = (checked: boolean) => {
		if (onChangeHandler) {
			if (!callbackPayload) onChangeHandler(checked);
			else onChangeHandler(checked, callbackPayload);
		}
		setCallbackPayload('temp');
	};

	return (
		<ComponentCheckBox margin={margin}>
			<label>
				<input
					type="checkbox"
					onChange={(e) => handleChange(e.target.checked)}
					checked={checked}
				/>
				<span className="checkmark" />
				<span>{context}</span>
			</label>
		</ComponentCheckBox>
	)
};

export default Radio;

