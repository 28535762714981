import Api from "./Base";
import { API } from "constants/Constants";

const { CLIENT } = API;

export function fetchClients() {
  return Api.get(CLIENT.BASIC);
}

export function fetchUsersClient(data: any) {
  return Api.get(CLIENT.USER_FOR_CLIENT, data);
}

export function getClientByID(id: any) {
  return Api.get(`${CLIENT.GET_CLIENT_BY_ID}/${id}`);
}

export function searchClients(data: any) {
  return Api.get(CLIENT.BASIC, data);
}

export function registerClient(data: any) {
  return Api.postFormData(CLIENT.REGISTER_FIRST_FORM, data);
}

export function updateClient(data: any, id: string) {
  return Api.putFormData(`${CLIENT.UPDATE}/${id}`, data);
}

export function updateClientSecondStep(data: any, id: string) {
  return Api.post(`${CLIENT.REGISTER_SECOND_FORM}/${id}`, data);
}

export function deactivateClient(id: string) {
  return Api.put(`${CLIENT.DEACTIVATE}/${id}`);
}

export function activateClient(id: string) {
  return Api.put(`${CLIENT.ACTIVATE}/${id}`);
}

export function exportClients() {
  return Api.getBlob(CLIENT.EXPORT);
}

export function fetchAllClients(data: any) {
  return Api.get(CLIENT.GET_ALL, data);
}
