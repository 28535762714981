import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ColorName from "constants/Variables";
import { Button } from "../CommonLayout";

interface SectionProps {
  onChangeHandler?: (e: any) => void;
  accept?: string;
  margin?: string;
  filename?: string;
}

const InputContainer = styled.div`
  input {
    display: none;
  }
  label {
    color: ${ColorName.william};
  }
`;

const InputFile = (props: SectionProps) => {
  const { onChangeHandler, accept, margin, filename } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation("translation");

  const onChange = (e: any) => {
    if (e.target.files[0] && onChangeHandler) onChangeHandler(e);
  };

  return (
    <InputContainer style={{ margin }}>
      <Button
        buttonWidth="150px"
        buttonMargin="0 10px 0 0 "
        onClick={() => inputRef && inputRef.current && inputRef.current.click()}
      >
        {t("label.chooseAFile")}
      </Button>
      <input
        ref={inputRef}
        type="file"
        accept={accept ? accept : "image/*"}
        onChange={(e: any) => onChange(e)}
      />
      <label htmlFor="">{filename ? filename : t("label.noFileChosen")}</label>
    </InputContainer>
  );
};

export default InputFile;
