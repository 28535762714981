/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { RootState } from "reducers";

import {
  Dropdown,
  Button,
  Input,
  FormGroup,
  FormInline,
  FormLabel,
  TitlePartOfForm,
  Avatar,
} from "components/Common";

import countries from "libs/countries";
import { UserOfClientActions } from "actions";
import PasswordGenerator from "generate-password";
import {
  TemplateDetailsContent,
  TemplateDetailsContainer,
  TemplateDetailsForm,
  ButtonField,
  TemplateDetailsHeader,
  LoadingLayer,
  Spinner,
} from "../Popup.styles";
import { useTranslation } from "react-i18next";
import {
  getSavedUserData,
  popupAlert,
  processDataDropDownAccount,
} from "libs/Utils";
import { Images } from "themes";

const { updateUserOfClient, registerUserOfClient, clearUserDetails } =
  UserOfClientActions;

interface SectionProps {
  onClose: (isFetch?: boolean) => void;
  data: any;
}

const InputStyleProps = {
  width: "300px",
};

const UserDetailsOfClient: React.FC<SectionProps> = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { onClose, data } = props;
  const dispatch = useDispatch();

  const userIsLoading = useSelector((state: RootState) => {
    return state.Users.isLoading;
  });
  const packages = useSelector((state: RootState) => {
    return state.Account.accounts;
  });

  const [country, setCountry] = useState("");
  const [accountImage, setAccountImage] = useState();
  const [accountData, setAccountData] = useState<any>({
    accountId: "",
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    contactNumber: "",
    address: "",
    city: "",
    region: "",
    zipCode: "",
    userType: "user",
  });
  const [urlPreview, setUrlPreview] = useState("");
  const [extraData, setExtraData] = useState({
    facebook: "",
    twitter: "",
    linkedin: "",
    pinterest: "",
    instagram: "",
  });
  const userData = getSavedUserData();

  useEffect(() => {
    return () => {
      dispatch(clearUserDetails());
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const accountManager = _.get(data, "accountManageUser");
      const accountId: string[] = [];
      _.forEach(accountManager, (account) =>
        accountId.push(_.get(account, "accountId.id"))
      );

      const avatar = _.get(data, "userData.avatar");
      if (avatar) {
        const getImagePath = _.get(avatar, "imagePath");
        setUrlPreview(getImagePath);
      }

      setAccountData({
        firstName: _.get(data, "userData.firstName"),
        lastName: _.get(data, "userData.lastName"),
        email: _.get(data, "email"),
        username: _.get(data, "username"),
        contactNumber: _.get(data, "userData.phoneNumber"),
        address: _.get(data, "userData.address"),
        city: _.get(data, "userData.city"),
        region: _.get(data, "userData.regionArea"),
        zipCode: _.get(data, "userData.zipCode"),
        userType: _.get(data, "userType"),
        accountId,
      });
      if (!_.isEmpty(_.get(data, "userData.extraData")))
        setExtraData(JSON.parse(_.get(data, "userData.extraData")));
      setCountry(_.get(data, "userData.country"));
    }
  }, [data]);

  const processData = () => {
    const formData = new FormData();
    const clientId = _.get(userData, "clientId.id");
    const accountId = _.get(accountData, "accountId");
    formData.append("clientId", clientId);
    for (const accId of accountId) formData.append("accountId[]", accId);

    formData.append("email", _.trim(accountData.email));
    formData.append("username", _.trim(accountData.username));
    formData.append("userType", _.trim(accountData.userType));
    formData.append("userData[firstName]", _.trim(accountData.firstName));
    formData.append("userData[lastName]", _.trim(accountData.lastName));
    formData.append("userData[address]", _.trim(accountData.address));
    formData.append("userData[phoneNumber]", _.trim(accountData.contactNumber));
    formData.append("userData[country]", country);
    formData.append("userData[city]", _.trim(accountData.city));
    if (accountData.zipCode)
      formData.append("userData[zipCode]", _.trim(accountData.zipCode));
    if (accountData.region)
      formData.append("userData[regionArea]", _.trim(accountData.region));
    formData.append("userData[extraData]", JSON.stringify(extraData));
    if (accountImage) formData.append("userData[avatar]", accountImage || "");

    const userId = _.get(data, "id");
    if (!userId) {
      const password = PasswordGenerator.generate({
        length: 10,
        numbers: true,
        lowercase: true,
        uppercase: true,
        symbols: true,
        strict: true,
      });
      formData.append("password", password);
      formData.append("confirmationPassword", password);
    }

    return formData;
  };

  const validateData = () => {
    let flag = true;
    let message = `${t("alert.foundEmptyFields")}`;
    const requireData: any[] = [
      {
        label: t("label.firstName"),
        value: accountData.lastName,
      },
      {
        label: t("label.lastName"),
        value: accountData.lastName,
      },
      {
        label: t("label.email"),
        value: accountData.email,
      },
      {
        label: t("label.userType"),
        value: accountData.userType,
      },
      {
        label: t("label.streetAddress"),
        value: accountData.address,
      },
      {
        label: t("label.country"),
        value: country,
      },
      {
        label: t("label.city"),
        value: accountData.city,
      },
      {
        label: t("label.contactNumber"),
        value: accountData.contactNumber,
      },
    ];
    if (!_.has(data, "id"))
      requireData.push(
        ...[
          {
            label: t("label.username"),
            value: accountData.username,
          },
        ]
      );
    _.map(requireData, (item) => {
      const label = _.get(item, "label");
      const value = _.get(item, "value");
      if (_.isEmpty(_.trim(value))) {
        flag = false;
        message += `${label}\n`;
      }
    });
    return { flag, message };
  };

  const createAccount = async () => {
    const { flag, message } = validateData();
    if (!flag)
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });
    else {
      const processUserData = processData();
      const userId = _.get(data, "id");
      if (userId) await dispatch(updateUserOfClient(processUserData, userId));
      else await dispatch(registerUserOfClient(processUserData));
      onClose(true);
    }
  };

  const setLogo = (e: any) => {
    if (e) {
      const url = URL.createObjectURL(e.target.files[0]);
      setAccountImage(e.target.files[0]);
      setUrlPreview(url);
    }
  };

  return (
    <TemplateDetailsContainer>
      <TemplateDetailsContent>
        {userIsLoading && (
          <LoadingLayer>
            <Spinner />
          </LoadingLayer>
        )}
        <TemplateDetailsHeader>
          {_.has(data, "id")
            ? t("headerPopup.editUser")
            : t("headerPopup.createNewUser")}
        </TemplateDetailsHeader>
        <TemplateDetailsForm height="500px">
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.firstName")}</FormLabel>
              <Input
                value={accountData.firstName || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({
                    ...accountData,
                    firstName: e.target.value,
                  })
                }
                placeholder={t("placeholder.firstName")}
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.lastName")}</FormLabel>
              <Input
                value={accountData.lastName || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, lastName: e.target.value })
                }
                placeholder={t("placeholder.lastName")}
                {...InputStyleProps}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.username")}</FormLabel>
              <Input
                value={accountData.username || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, username: e.target.value })
                }
                placeholder={t("placeholder.username")}
                disabled={_.has(data, "id")}
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.email")}</FormLabel>
              <Input
                value={accountData.email || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, email: e.target.value })
                }
                placeholder={t("placeholder.email")}
                disabled={_.has(data, "id")}
                maxLength={64}
                {...InputStyleProps}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.userType")}</FormLabel>
              <Dropdown
                value={accountData.userType || ""}
                callbackPayload={(value: any) =>
                  setAccountData({ ...accountData, userType: value })
                }
                options={[
                  {
                    label: t("dropdown.client"),
                    value: "client",
                  },
                  {
                    label: t("dropdown.user"),
                    value: "user",
                  },
                ]}
                placeholder={t("placeholder.userType")}
                disabled
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup />
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.client")}</FormLabel>
              <Dropdown
                value=""
                options={[]}
                placeholder={_.get(userData, "clientId.clientName")}
                disabled
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.package")}</FormLabel>
              <Dropdown
                value={accountData?.accountId || ""}
                callbackPayload={(value: any) =>
                  setAccountData({ ...accountData, accountId: value })
                }
                options={processDataDropDownAccount(packages.items)}
                placeholder={t("placeholder.package")}
                multiple
                disabled={_.has(data, "id")}
                {...InputStyleProps}
              />
            </FormGroup>
          </FormInline>
          <TitlePartOfForm>
            {t("titleSection.contactInformation")}
          </TitlePartOfForm>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.streetAddress")}
              </FormLabel>
              <Input
                value={accountData.address || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, address: e.target.value })
                }
                placeholder={t("placeholder.streetAddress")}
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.country")}</FormLabel>
              <Dropdown
                value={country}
                callbackPayload={(value: any) => setCountry(value)}
                options={countries}
                placeholder={t("placeholder.country")}
                search={false}
                {...InputStyleProps}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.city")}</FormLabel>
              <Input
                value={accountData.city || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, city: e.target.value })
                }
                placeholder={t("placeholder.city")}
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t("label.regionArea")}</FormLabel>
              <Input
                value={accountData.region || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, region: e.target.value })
                }
                placeholder={t("placeholder.regionArea")}
                {...InputStyleProps}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.postalOrZipCode")}</FormLabel>
              <Input
                value={accountData.zipCode || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, zipCode: e.target.value })
                }
                placeholder={t("placeholder.postalOrZipCode")}
                isNum
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.contactNumber")}
              </FormLabel>
              <Input
                value={accountData.contactNumber || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({
                    ...accountData,
                    contactNumber: e.target.value,
                  })
                }
                placeholder={t("placeholder.phoneNumber")}
                isNum
                {...InputStyleProps}
              />
            </FormGroup>
          </FormInline>
          <TitlePartOfForm>{t("titleSection.social")}</TitlePartOfForm>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.facebookProfile")}</FormLabel>
              <Input
                value={extraData.facebook || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, facebook: e.target.value })
                }
                placeholder="http://"
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t("label.instagramProfile")}</FormLabel>
              <Input
                value={extraData.instagram || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, instagram: e.target.value })
                }
                placeholder="http://"
                {...InputStyleProps}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.twitterProfile")}</FormLabel>
              <Input
                value={extraData.twitter || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, twitter: e.target.value })
                }
                placeholder="http://"
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t("label.linkedInProfile")}</FormLabel>
              <Input
                value={extraData.linkedin || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, linkedin: e.target.value })
                }
                placeholder="http://"
                {...InputStyleProps}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.pinterestProfile")}</FormLabel>
              <Input
                value={extraData.pinterest || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, pinterest: e.target.value })
                }
                placeholder="http://"
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup />
          </FormInline>
          <FormGroup>
            <FormLabel>
              {t("label.uploadAvatar")}{" "}
              <span className="description">({t("label.demensionImage")})</span>
            </FormLabel>
            <Avatar
              src={urlPreview || Images.defaultPhoto}
              onChange={(e: any) => setLogo(e)}
            />
          </FormGroup>
        </TemplateDetailsForm>
        <ButtonField>
          <Button
            type="submit"
            onClick={() => createAccount()}
            buttonMargin="0 10px 0 0"
          >
            {_.has(data, "id") ? t("button.save") : t("button.create")}
          </Button>
          <Button type="submit" onClick={() => onClose()} buttonWidth="100px">
            {t("button.cancel")}
          </Button>
        </ButtonField>
      </TemplateDetailsContent>
    </TemplateDetailsContainer>
  );
};

export default UserDetailsOfClient;
