import ActionTypes from "constants/ActionTypes";
import API from "api";
import _ from "lodash";
import { responseAlert } from "libs/alert";

const generateLogsForTable = (payload: any) => {
  const resolveLogs: any[] = [];
  const items = _.get(payload, "items");
  const meta = _.get(payload, "meta");
  _.forEach(items, (item) => {
    const fullName = _.get(item, "userId.userData.fullName");
    const resolveItem = {
      ...item,
    };
    if (fullName)
      _.assign(resolveItem, {
        data: { ...resolveItem.data, createdBy: fullName },
      });
    resolveLogs.push(resolveItem);
  });
  return {
    items: resolveLogs,
    meta,
  };
};

const clearLogs = () => {
  return {
    type: ActionTypes.CLEAR_LOGS,
  };
};

const logFail = () => {
  return {
    type: ActionTypes.LOG_FAILURE,
  };
};

const logLoading = () => {
  return {
    type: ActionTypes.LOG_REQUEST,
  };
};

const fetchLogsSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_LOG_SUCCESS,
    payload,
  };
};

const getLogByIdSuccess = (payload = {}) => {
  return {
    type: ActionTypes.GET_LOG_BY_ID_SUCCESS,
    payload,
  };
};

const fetchLogs = () => {
  return async (dispatch: any) => {
    dispatch(logLoading());
    await API.fetchLogs()
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          const generateLogs = generateLogsForTable(res.payload);
          dispatch(fetchLogsSuccess(generateLogs));
        } else {
          responseAlert({ res });
          dispatch(logFail());
        }
      })
      .catch((e: any) => {
        dispatch(logFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getLogsByID = (id: string) => {
  return async (dispatch: any) => {
    dispatch(logLoading());
    await API.getLogByID(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          const generateLogs = generateLogsForTable(res.payload);
          dispatch(getLogByIdSuccess(generateLogs));
        } else {
          responseAlert({ res });
          dispatch(logFail());
        }
      })
      .catch((e: any) => {
        dispatch(logFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const searchLogs = (data: any) => {
  return async (dispatch: any) => {
    dispatch(logLoading());
    await API.searchLogs(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          const generateLogs = generateLogsForTable(res.payload);
          dispatch(fetchLogsSuccess(generateLogs));
        } else {
          responseAlert({ res });
          dispatch(logFail());
        }
      })
      .catch((e: any) => {
        dispatch(logFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

export { fetchLogs, getLogsByID, searchLogs, clearLogs };
