import styled from "styled-components";
import ColorName from "constants/Variables";
import { device } from "constants/Devices";
import { HorizontalContainer } from "../CommonLayout";

interface SectionProps {
  width?: string;
  height?: string;
  maxHeight?: string;
  overflow?: string;
}

const Spinner = styled.div`
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  width: 40px;
  height: 40px;
  border-top: 4px solid ${ColorName.burntSienna};
  border-right: 4px solid transparent;
  border-radius: 50%;
  animation: rotation 0.8s linear infinite;
`;

const LoadingLayer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

const ContainerPopup = styled(HorizontalContainer)`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60%;
  min-width: 375px;
  border-radius: 20px;
  overflow: hidden;
  background-color: ${ColorName.white};
  position: relative;
  & .title {
    display: flex;
    padding: 20px;
    font-size: 22px;
    font-weight: bold;
    color: ${ColorName.william};
    border-bottom: 1px solid ${ColorName.linkWater};
    background: ${ColorName.spindle};
    place-content: center;
  }
  & .content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-height: 50vh;
    overflow: auto;
    white-space: pre-line;
    &.flex-center {
      place-items: center;
      min-height: 200px;
      & .form-center {
        min-width: 200px;
      }
    }
  }
  & .footer {
    display: flex;
    padding: 0 20px;
    justify-content: center;
    border-top: 1px solid ${ColorName.linkWater};
  }
  @media ${device.tablet} {
    max-width: 80%;
    width: 680px;
  }
`;

const ContainerFormCreateUser = styled.div`
  overflow: auto;
  display: flex;
  background: white;
  justify-content: space-between;
  max-width: 680px;
  height: 70vh;
  padding: 1em 2em;
  flex-direction: column;
  position: relative;
  & .form-title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 41px;
    color: ${ColorName.william};
    border-top: 1px solid ${ColorName.william};
    width: 100%;
    padding-top: 1em;
  }
  & .form-group {
    color: ${ColorName.william};
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    flex-wrap: wrap;

    & .container-element {
      width: 280px;
      padding: 5px;
    }

    & span {
      min-height: 1px;
    }
    &.fields {
      align-items: flex-start;
      margin: 10px 0;
    }
    @media ${device.tablet} {
      flex-direction: row;
    }
    flex-direction: column;
  }
  & #custom-file-input {
    border: none;
    box-shadow: none;
    padding: 0;
    width: 120px;
  }

  & #custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }

  & #custom-file-input::before {
    content: "Choose a file";
    display: inline-block;
    border: 1px solid #999;
    border-radius: 3px;
    padding: 8px 12px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 600;
    background: ${ColorName.burntSienna};
    color: white;
  }
`;

const OrderLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    color: ${ColorName.william};
  }
`;

const TemplateDetailsContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.25);
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  & .justify-between {
    display: flex;
    justify-content: space-between;
  }
`;

const TemplateDetailsHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${ColorName.william};
  color: white;
  font-size: 15px;
  height: 35px;
  position: relative;
`;

const TemplateDetailsContent = styled.div`
  width: ${(props: SectionProps) => props.width || "auto"};
  height: ${(props: SectionProps) => props.height || "auto"};
  background: white;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
`;

const PDFTemplateDetailsContent = styled.div`
  width: 74vw;
  height: 70vh;
  max-height: 600px;
  background: white;
`;

const TemplateDetailsForm = styled.div`
  padding: 15px 20px;
  height: ${(props: SectionProps) => props.height || "100%"};
  overflow: ${(props: SectionProps) => props.overflow || "hidden auto"};
  transition: all ease 0.25s;

  & .form-title {
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 41px;
    color: ${ColorName.william};
    border-top: 1px solid ${ColorName.william};
    max-width: 620px;
    width: 100%;
    padding-top: 1em;
  }

  & .form-group-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    & .form-group {
      flex: 1;
    }
  }

  & .form-group {
    display: flex;
    color: ${ColorName.william};
    align-items: center;
    font-size: 16px;
    margin-bottom: 15px;

    & .container-element {
      width: 100%;
      margin: 0 10px 10px 0;

      &:nth-child(2) {
        margin: 0 0 10px 10px;
      }

      span {
        margin-bottom: 5px;
      }
    }

    &.align-start {
      align-items: flex-start;
    }

    &.column {
      display: block;
    }
    & .empty {
      color: ${ColorName.amaranth};
      font-size: 12px;
      height: 17px;
    }
    span {
      display: inline-block;
      min-height: 1px;
      font-weight: bold;
    }
    &.fields {
      align-items: flex-start;
      margin: 10px 0;
    }
  }
  & .row-fields {
    display: flex;
    & .form-group:nth-child(2) {
      flex: 1;
    }
  }
  & .folder {
    margin: 5px 0;

    & .folder-name {
      margin-bottom: 5px;
      user-select: none;

      & .select-btn {
        color: ${ColorName.william};
        margin: 0 5px;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }

        &.disabled {
          opacity: 0.8;
          &:hover {
            cursor: not-allowed;
            text-decoration: none;
          }
        }
      }
    }
  }
`;

const ButtonField = styled.div`
  display: flex;
  background: ${ColorName.white};
  justify-content: flex-end;
  padding: 10px 20px 10px 0;
  align-items: center;
  background: ${ColorName.linkWater};
`;

const CheckboxRow = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border: 1px solid #ccc;
`;

const TagMailList = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span {
    font-size: 14px;
  }
`;

const TagMailItem = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  padding: 2px 10px;
  background: ${ColorName.linkWater};
  border: 1px solid ${ColorName.linkWater};
  border-radius: 5px;
  margin: 0 10px 5px 0;
  i {
    transform: translateY(1px);
    font-size: 12px;
    margin-left: 8px;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid ${ColorName.william};
  }
`;

const InputField = styled.div`
  position: relative;

  input {
    padding: 7px 60px 7px 14px;
  }

  & .input-icons {
    position: absolute;
    right: 5px;
    top: 7px;
    font-weight: bold;
    span {
      font-size: 14px;
      margin-left: 10px;
      transition: all ease 0.25s;

      &.blur {
        opacity: 0.5;
        &:hover {
          cursor: not-allowed;
          color: ${ColorName.william};
        }
      }

      &:hover {
        cursor: pointer;
        color: ${ColorName.burntSienna};
      }
    }
  }
`;

const TotalPostRow = styled.div`
  margin-top: 20px;
  margin-left: 30px;
  height: 40px;
  span {
    font-size: 16px;
    margin-right: 10px;
    color: ${ColorName.william};
  }
`;

const DatatPostContent = styled.div`
  h3 {
    margin-top: 0;
    margin-bottom: 5px;
    color: ${ColorName.william};
  }
`;

const DataPostContainer = styled.div`
  width: 100%;
  // max-width: 1140px;
  height: auto;
  background: white;
`;

const DataPostItem = styled.div`
  position: relative;
  padding: 25px 10px;
  box-sizing: border-box;
  &:nth-child(even) {
    background: ${ColorName.linkWater};
  }

  span {
    color: ${ColorName.william};
    margin-right: 10px;
  }
`;

const PreviewContent = styled.div`
  margin: 15px 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const PostMenu = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  width: 230px;
  height: 0px;
  background: transparent;
  border: 1px solid transparent;
  overflow: hidden;
`;

const PostMenuItem = styled.div`
  padding: 8px 20px;
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: ${ColorName.linkWater};
  }
  &.disabled {
    background: white;
    opacity: 0.5;
    &:hover {
      cursor: not-allowed;
    }
  }
`;

const MenuButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  display: flex;
  justify-content: center;
  i {
    color: ${ColorName.william};
  }
  &:hover {
    cursor: pointer;
    ${PostMenu} {
      background: #fff;
      height: auto;
      border: 1px solid #cadeed;
    }
  }
`;

const PreviewButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  width: 30px;
  height: 30px;
  border: 1px solid ${ColorName.william};
  color: ${ColorName.william};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background: ${ColorName.william};
    color: ${ColorName.white};
  }
`;

const TemplatePreview = styled.div`
  width: 100%;
  height: 100%;
  max-height: 400px;
  overflow: auto;
  position: relative;
  margin-top: 15px;
  iframe {
    width: 100%;
    height: 100%;
  }

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    content: "";
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  transition: all ease 0.25s;
  &:hover {
    cursor: pointer;
    color: ${ColorName.burntSienna};
  }
`;

const ContentInformation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & .action-btn {
    display: inline-flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: ${ColorName.linkWater};
    color: ${ColorName.william};
    margin-right: 5px;
    margin-top: 5px;

    &:hover {
      cursor: pointer;
      background: ${ColorName.william};
      color: ${ColorName.linkWater};
    }

    &.disabled {
      opacity: 0.7;
      cursor: not-allowed;

      &:hover {
        background: ${ColorName.linkWater};
        color: ${ColorName.william};
      }
    }
  }
`;

const TitleContent = styled.h2`
  text-align: left;
`;

const DescriptionContent = styled.p`
  white-space: pre-wrap;
`;

const Tag = styled.span`
  margin-right: 8px;
  background: ${ColorName.linkWater};
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 500;
`;

export {
  ContainerPopup,
  Content,
  ContainerFormCreateUser,
  OrderLayer,
  TemplateDetailsContainer,
  TemplateDetailsHeader,
  TemplateDetailsContent,
  PDFTemplateDetailsContent,
  TemplateDetailsForm,
  ButtonField,
  CheckboxRow,
  TagMailItem,
  TagMailList,
  InputField,
  TotalPostRow,
  DatatPostContent,
  DataPostContainer,
  DataPostItem,
  PreviewContent,
  MenuButton,
  PostMenu,
  PostMenuItem,
  PreviewButton,
  TemplatePreview,
  CloseButton,
  ContentInformation,
  TitleContent,
  DescriptionContent,
  LoadingLayer,
  Spinner,
  Tag,
};
