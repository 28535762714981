/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useLocation } from "react-router-dom";

import { RootState } from "reducers";
import { AccountActions, ClientActions } from "actions";

import TableUltils from "libs/TableUltils";
import ColorName from "constants/Variables";
import history from "../../../history";
import { CONSTANTS } from "constants/Constants";
import { generateFilter, popupAlert } from "libs/Utils";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Table,
  Input,
  Button,
  Filter,
  SelectDatetime,
  Checkbox,
  ContainerSearch,
  FlexWrapRow,
} from "components/Common";
import { useTranslation } from "react-i18next";
import RouteTypes from "constants/RouteTypes";
import { LogsDetails } from "components/Common/Popup";

const { searchAccounts, deactivateAccount, activateAccount, clearPackages } =
  AccountActions;
const { getClientsByID } = ClientActions;

const DEFAULT_FILTER = {
  keyword: "",
  accountCode: "",
  startDate: "",
  endDate: "",
  status: CONSTANTS.STATUS.ACTIVE,
  clientId: "",
  limit: 10,
  page: 1,
  sortBy: "updatedAt",
  orderBy: "DESC",
};

interface FilterStructure {
  keyword: string;
  accountCode: string;
  startDate: string;
  endDate: string;
  status: string;
  clientId: string;
  limit: number;
  page: number;
  sortBy: string;
  orderBy: string;
}

const FilterStyleProps = {
  width: "fit-content",
  margin: "10px 10px 10px 0",
  widthIcon: false,
};

const InputStyleProps = {
  margin: "10px 10px 10px 0",
  width: "200px",
};

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "10px 10px 10px 0",
};

const ListPackages = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const accountsIsLoading = useSelector((state: RootState) => {
    return state.Account.isLoading;
  });
  const accounts = useSelector((state: RootState) => {
    return state.Account.accounts;
  });
  const client = useSelector((state: RootState) => {
    return state.Clients.client;
  });
  const location = useLocation();

  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const clientId = _.get(location, "state.id") || '';
  const [logData, setLogData] = useState<any>({});
  const dataHead = [
    "no",
    "packageCode",
    "packageName",
    "startDate",
    "endDate",
    "status",
    "actions",
  ];
  const dataTable = TableUltils._processDataTable(
    [
      "no",
      "id",
      "accountCode",
      "accountName",
      "startDate",
      "endDate",
      "status",
      "action",
    ],
    _.get(accounts, "items")
  );
  const listColumnCenter = [1, 2, 3, 4, 5, 6, 7];

  useEffect(() => {
    return () => {
      dispatch(clearPackages());
    };
  }, []);

  useEffect(() => {
    const stateFilter: any = _.get(location, "state.filter");
    const prevRoute = _.get(location, "state.from");
    const resolveFilter = {
      ...filter,
    };
    if (stateFilter && prevRoute === RouteTypes.ADMIN_ROUTES.PACKAGE_DETAILS) {
      _.assign(resolveFilter, { ...stateFilter });
      history.replace({ ...history.location, state: {} });
    }
    if (clientId) {
      setFilter({ ...filter, clientId });
      dispatch(searchAccounts(generateFilter({ ...resolveFilter, clientId })));
      dispatch(getClientsByID(clientId));
    } else history.push({ pathname: RouteTypes.ADMIN_ROUTES.CLIENTS });
  }, []);

  const callbackCurrentPageLink = (page: number) => {
    const newFilter: any = {
      ...filter,
      page,
    };
    setFilter(newFilter);
    dispatch(searchAccounts(generateFilter(newFilter)));
  };

  const callbackAction = async (action: string, id: string, item: any) => {
    if (action === CONSTANTS.ACTION.LOG)
      setLogData({ logType: "account", rowId: id });

    if (action === CONSTANTS.ACTION.USERS) {
      history.push({
        pathname: "/users",
        state: { filter: { clientId: client.id, accountId: id } },
      });
    }
    if (action === CONSTANTS.ACTION.EDIT) {
      history.push({
        pathname: "/clients/packages/details",
        state: {
          accountId: id,
          clientId: _.get(filter, "clientId"),
          filter,
        },
      });
    }

    if (action === CONSTANTS.ACTION.DEACTIVATE) {
      const itemStatus = _.get(item, "status");
      const packageName = _.get(item, "accountName");
      const statusLabel =
        itemStatus === CONSTANTS.STATUS.ACTIVE
          ? t("alert.deactive")
          : t("alert.active");
      const content = document.createElement("span");
      content.innerHTML = `${t(
        "alert.areYouSureYouWantTo"
      )}</br> <b>${statusLabel}</b> ${t(
        "alert.thePackage"
      )} <b>${packageName}</b>?`;
      await popupAlert({
        type: "warning",
        title: t("alert.warning"),
        content,
        buttons: true,
        dangerMode: true,
      }).then(async (willContinue) => {
        if (willContinue) {
          if (itemStatus === CONSTANTS.STATUS.ACTIVE)
            await dispatch(deactivateAccount(id));
          else await dispatch(activateAccount(id));

          await dispatch(searchAccounts(generateFilter(filter)));
        }
      });
    }
  };

  const setValueStatus = (status: string) => {
    const newFilter = {
      ...filter,
      status,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(searchAccounts(generateFilter(newFilter)));
  };

  const onReset = () => {
    setFilter({ ...DEFAULT_FILTER, clientId });
    dispatch(searchAccounts(generateFilter({ ...DEFAULT_FILTER, clientId })));
    dispatch(getClientsByID(clientId));
  };

  const addFilter = () => {
    const newFilter = {
      ...filter,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(searchAccounts(generateFilter(newFilter)));
  };

  const onCreate = () => {
    history.push({
      pathname: RouteTypes.ADMIN_ROUTES.PACKAGE_DETAILS,
      state: {
        clientId: client.id,
        filter,
      },
    });
  };

  const _renderTable = () => {
    return (
      <Table
        dataHeader={dataHead}
        dataTable={dataTable}
        fitColumns={[1, 6, 7]}
        listColumnCenter={listColumnCenter}
        hasPagination={true}
        callbackCurrentPageLink={callbackCurrentPageLink}
        metaPagination={accounts.meta}
        listActionItem={[
          CONSTANTS.ACTION.EDIT,
          CONSTANTS.ACTION.DEACTIVATE,
          CONSTANTS.ACTION.LOG,
          CONSTANTS.ACTION.USERS,
        ]}
        callBackAction={callbackAction}
        loading={accountsIsLoading}
      />
    );
  };

  const renderMain = () => {
    return (
      <>
        {!_.isEmpty(logData) && (
          <LogsDetails onClose={() => setLogData({})} data={logData} />
        )}
        <ContainerSearch className={accountsIsLoading ? "loading" : ""}>
          <FlexWrapRow>
            <Filter
              text={`${t("label.clientNumber")}: ${
                client.clientNumber || "N/I"
              }`}
              {...FilterStyleProps}
            />
            <Filter
              text={`${t("label.clientName")}: ${client.clientName || "N/I"}`}
              {...FilterStyleProps}
            />
          </FlexWrapRow>
          <FlexWrapRow>
            <Input
              value={_.get(filter, "accountCode")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, accountCode: e.target.value })
              }
              onEnter={() => addFilter()}
              placeholder={t("placeholder.packageCode")}
              {...InputStyleProps}
            />
            <Input
              value={_.get(filter, "keyword")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, keyword: e.target.value })
              }
              onEnter={() => addFilter()}
              placeholder={t("placeholder.packageName")}
              {...InputStyleProps}
            />
            <SelectDatetime
              callbackPayload={(e) => setFilter({ ...filter, startDate: e })}
              placeholder={t("placeholder.startDate")}
              defaultValue={_.get(filter, "startDate")}
              maxDate={filter?.endDate || new Date().toLocaleString()}
              {...InputStyleProps}
            />
            <SelectDatetime
              callbackPayload={(e) => setFilter({ ...filter, endDate: e })}
              placeholder={t("placeholder.endDate")}
              defaultValue={_.get(filter, "endDate")}
              minDate={filter?.startDate || new Date().toLocaleString()}
              {...InputStyleProps}
            />
            <Button onClick={() => addFilter()} {...ButtonStyleProps}>
              {t("button.search")}
            </Button>
            <Button onClick={() => onReset()} {...ButtonStyleProps}>
              {t("button.reset")}
            </Button>
            <Button
              onClick={() => onCreate()}
              background={ColorName.william}
              {...ButtonStyleProps}
            >
              + {t("button.create")}
            </Button>
          </FlexWrapRow>
          <Checkbox
            checked={_.get(filter, "status") === CONSTANTS.STATUS.INACTIVE}
            context={t("label.showInactivePackages")}
            onChangeHandler={(e: boolean) =>
              setValueStatus(
                e ? CONSTANTS.STATUS.INACTIVE : CONSTANTS.STATUS.ACTIVE
              )
            }
            margin="10px 0 0 0"
          />
        </ContainerSearch>

        {_renderTable()}
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      parent={RouteTypes.ADMIN_ROUTES.GROUPS}
      currentPage={RouteTypes.ADMIN_ROUTES.CLIENTS}
      title={t("titlePage.packages")}
    />
  );
};

export default ListPackages;
