/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from "apisauce";
import {
  popupAlert,
  getSavedToken,
  clearAllSavedData,
  getSavedUserData,
} from "libs/Utils";
import _ from "lodash";
import history from "../history";
import RouteTypes from "constants/RouteTypes";

let token = getSavedToken();
const BASE_URL = process.env.REACT_APP_BE_URL;

// DEFINE THE URL API
const api = create({
  baseURL: `${BASE_URL}api/`,
  headers: {
    Accept: "application/json",
  },
});

if (token) api.setHeader("Authorization", `Bearer ${token}`);

// HANDLE RESPONSR FUNCTION
const handleResponse = async (res: any, resolve: any, reject: any) => {
  if (res.status === 401) {
    // const message = _.get(res, "data.message");
    // await popupAlert({
    //   title: message,
    //   text: "Your account doesn’t have permission to access that page!",
    //   // text: 'Your login session has expired or your token was invalid, please login again, system will redirect to login screen ...',
    //   type: "error",
    // });
    // popupAlert({
    //   type: "error",
    //   title: "Oops...",
    //   text: "You don't have permission to access this page!\n Please login again",
    //   dangerMode: true,
    // });
    const userData = getSavedUserData();
    const userType = _.get(userData, "userType");
    await clearAllSavedData();
    if (userType === "user") history.push(RouteTypes.USER_ROUTES.USER_ARTICLES);
    if (userType === "client" || userType === "admin")
      history.push(RouteTypes.AUTHENTICATION_ROUTES.AUTHENTICATION);
    // reject(res.data);
  } else if (
    res.status === 501 ||
    res.status === 422 ||
    res.status === 400 ||
    res.status === 413
  ) {
    reject(res);
  } else if (res.problem) {
    const error = "Server error";
    popupAlert({
      type: `error`,
      text: `Error detail: ${error}`,
    });
    reject(error);
  } else {
    resolve(res.data);
  }
};

// REQUEST FUNCTIONS
const authRequest = async (url: string, data = {}) => {
  data = { ...data };
  const res = await api.post(url, data);
  return new Promise((resolve, reject) => {
    if (res.status === 401 || res.status === 400) {
      reject(res.data);
    } else if (res.status === 501 || res.status === 422) {
      reject(res);
    } else if (res.problem) {
      reject("Server error");
    } else {
      resolve(res);
    }
  });
};

const post = async (url: string, data = {}) => {
  data = { ...data };
  const res = await api.post(url, data);
  return new Promise((resolve, reject) => {
    handleResponse(res, resolve, reject);
  });
};

const postMany = async (url: string, data = []) => {
  const res = await api.post(url, data);
  return new Promise((resolve, reject) => {
    handleResponse(res, resolve, reject);
  });
};

const postFormData = async (url: string, data: any) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  const res = await api.post(url, data, { headers });
  return new Promise((resolve, reject) => {
    handleResponse(res, resolve, reject);
  });
};

const get = async (url: string, data = {}) => {
  const res = await api.get(url, data);
  return new Promise((resolve, reject) => {
    handleResponse(res, resolve, reject);
  });
};
const getBlob = async (url: string, data = {}) => {
  const res = await api.get(url, data, { responseType: "blob" });
  return new Promise((resolve, reject) => {
    handleResponse(res, resolve, reject);
  });
};

const put = async (url: string, data = {}) => {
  token = getSavedToken();
  data = { ...data };
  const res = await api.put(url, data);
  return new Promise((resolve, reject) => {
    handleResponse(res, resolve, reject);
  });
};
const putFormData = async (url: string, data = {}) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  const res = await api.put(url, data, { headers });
  return new Promise((resolve, reject) => {
    handleResponse(res, resolve, reject);
  });
};

const patch = async (url: string, data = {}) => {
  token = getSavedToken();
  data = { ...data };
  const res = await api.patch(url, data);
  return new Promise((resolve, reject) => {
    handleResponse(res, resolve, reject);
  });
};

const del = async (url: string, data = {}) => {
  token = getSavedToken();
  data = { ...data };
  const res = await api.delete(url, data);
  return new Promise((resolve, reject) => {
    handleResponse(res, resolve, reject);
  });
};

const upload = (url: string, data: any) => {
  return api.put(url, data.payload, {
    onUploadProgress: data.onUploadProgress,
  });
};

const setToken = (newToken: string) => {
  token = newToken;
  api.setHeader("Authorization", `Bearer ${token}`);
};

const getToken = () => {
  return token;
};

export default {
  postFormData,
  post,
  put,
  del,
  patch,
  upload,
  get,
  authRequest,
  postMany,
  putFormData,
  getBlob,
};

export { setToken, getToken };
