import Api from "./Base";
import { API } from "constants/Constants";

const { SOURCE } = API;

export function addSourceSetting(data: any) {
  return Api.postFormData(SOURCE.BASIC, data);
}

export function fetchSourceSettings() {
  return Api.get(SOURCE.BASIC);
}

export function getAllSources(data?: any) {
  return Api.get(SOURCE.GET_ALL, data);
}

export function getSourceSettingByID(id: any) {
  return Api.get(`${SOURCE.BASIC}/${id}`);
}

export function searchSourceSettings(data: any) {
  return Api.get(SOURCE.BASIC, data);
}

export function updateSourceSetting(data: any, id: any) {
  return Api.putFormData(`${SOURCE.BASIC}/${id}`, data);
}

export function deactivateSourceSetting(id: any) {
  return Api.putFormData(`${SOURCE.DEACTIVATE}/${id}`);
}

export function activateSourceSetting(id: any) {
  return Api.putFormData(`${SOURCE.ACTIVATE}/${id}`);
}
