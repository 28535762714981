import styled from "styled-components";
import { HorizontalContainer } from "../CommonLayout";
import ColorName from "constants/Variables";

interface SectionProps {
  margin?: string;
  height?: string;
  width?: string;
  isPositionLeft?: boolean;
  isRequired?: boolean;
}

const ContainerDropdownDate = styled(HorizontalContainer)`
  padding: 10px 15px;
  background: white;
  border-radius: 16px;
  color: ${ColorName.william};
  align-items: center;
  margin: ${(props: SectionProps) => props.margin || "unset"};
  height: ${(props: SectionProps) => props.height || "max-content"};
  width: ${(props: SectionProps) => props.width || "max-content"};
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  & .title-calendar {
    margin: 0 10px;
    color: ${ColorName.william};
  }

  &:hover {
    cursor: pointer;
  }
`;

const ContainerDateTime = styled(HorizontalContainer)`
  box-shadow: 0px 0px 5px
    ${(props: SectionProps) =>
      props.isRequired ? "#f85353" : "rgb(62 138 182 / 25%)"};
  border-radius: 5px;
  color: ${ColorName.william};
  height: 40px;
  position: relative;
  margin: ${(props: SectionProps) => props.margin || "unset"};
  width: ${(props: SectionProps) => props.width || "unset"};
  & .component-date {
    & span {
      color: gray;
      font-size: 14px;
      font-weight: 400;
    }
  }
  & .active {
    border: 1px solid ${ColorName.william} !important;
  }

  & .component-date {
    border: 1px solid
      ${(props: SectionProps) =>
        props.isRequired ? "#f85353" : ColorName.linkWater};
    border-radius: 5px;
    padding: 0.5em 1em;
    width: ${(props: SectionProps) => props.width || "unset"};
    height: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    /* box-shadow: 0px 0px 4px rgba(62, 138, 182, 0.25); */
    span {
      margin-right: 1em;
    }
    & i {
      color: ${ColorName.william};
    }
  }

  &.disabled {
    opacity: 0.5;
    ${ContainerDropdownDate}:hover, .component-date {
      cursor: not-allowed;
    }
  }
`;

const ComponentCalendar = styled.div`
  font-family: "Be Vietnam";
  position: absolute;
  top: 46px;
  right: 0;
  left: ${(props: SectionProps) =>
    props.isPositionLeft === true ? "0" : "unset"};
  z-index: 3;
  width: 350px;
  & .react-calendar {
    border: none;
    border-radius: 16px;
    box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.75);
    padding: 10px;
    font-family: "Be Vietnam";
  }

  & .react-calendar__navigation__label {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: ${ColorName.william};
    pointer-events: none;
    font-family: "Be Vietnam";
  }

  & .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button {
    display: none;
  }

  & .react-calendar__navigation__next-button,
  .react-calendar__navigation__prev-button {
    border-radius: 10px;
    margin: 0 10px;
  }

  & .react-calendar__month-view__weekdays {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: ${ColorName.burntSienna};
    div {
      abbr {
        text-decoration: none;
      }
    }
  }

  & .react-calendar__month-view__days {
    & button {
      border-radius: 10px;
      flex-basis: 14.2857% !important;
      max-width: 14.2857% !important;
      border-radius: 50%;
    }
    & button {
      abbr {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  & .react-calendar__tile--now {
    border-radius: 10px;
    flex-basis: 14.2857% !important;
    max-width: 14.2857% !important;
    background: ${ColorName.william};
    color: ${ColorName.white};
    border-radius: 50%;
  }

  & .react-calendar__tile--active,
  .react-calendar__tile--hasActive {
    border-radius: 10px;
    flex-basis: 14.2857% !important;
    max-width: 14.2857% !important;
    background: ${ColorName.burntSienna};
    border-radius: 50%;
  }

  .react-calendar__tile--now {
    border-radius: 10px;
    flex-basis: 14.2857% !important;
    max-width: 14.2857% !important;
    background: ${ColorName.william};
    border-radius: 50%;
  }

  & .react-calendar__tile--hover {
    border-radius: 10px;
    flex-basis: 14.2857% !important;
    max-width: 14.2857% !important;
    background: ${ColorName.burntSienna};
    border-radius: 50%;
  }

  & .react-calendar__tile {
    &:enabled:hover {
      border-radius: 10px;
      flex-basis: 14.2857% !important;
      max-width: 14.2857% !important;
      background: ${ColorName.burntSienna};
      border-radius: 50%;
    }
  }
`;

export { ContainerDropdownDate, ComponentCalendar, ContainerDateTime };
