import ActionTypes from "constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  categories: [],
  category: {},
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.CLEAR_CATEGORIES:
      return {
        ...state,
        categories: [],
      };
    case ActionTypes.CLEAR_CATEGORY_DETAILS:
      return {
        ...state,
        category: {},
      };
    case ActionTypes.CATEGORY_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.CATEGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    case ActionTypes.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        categories: payload,
      };
    case ActionTypes.GET_CATEGORY_BY_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        category: payload,
      };
    case ActionTypes.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        category: payload,
      };
    default:
      return state;
  }
};
