/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { RootState } from "reducers";
import { FileRecordActions, SourceSettingsActions } from "actions";
import RouteTypes from "constants/RouteTypes";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Input,
  Button,
  InputFile,
  Dropdown,
  FormDetails,
  FormGroup,
  FormLabel,
} from "components/Common";
import { Container } from "./AddCSV.styles";
import { useTranslation } from "react-i18next";
import { popupAlert } from "libs/Utils";
import { Box, TextField, Autocomplete } from "@mui/material";
import history from "../../../history";

const { createFileRecord, uploadFileRecord } = FileRecordActions;
const { fetchAllSource, clearSources } = SourceSettingsActions;

const InputStyleProps = {
  width: "300px",
};

const ButtonStyleProps = {
  buttonMargin: "10px 10px 10px 0",
};

const AddCSV = () => {
  const { t } = useTranslation("translation");

  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => {
    return state.FileRecord.isLoading;
  });
  const sourceIsLoading = useSelector((state: RootState) => {
    return state.SourceSettings.isLoading;
  });
  const sources = useSelector((state: RootState) => {
    return state.SourceSettings.sourceSettings;
  });

  const [fileImport, setFileImport] = useState();
  const [formData, setFormData] = useState({
    link: "",
    name: "",
    sourceCode: "",
    sourceLink: "",
  });
  const [formDataUpload, setFormDataUpload] = useState({
    sourceCode: "",
    sourceLink: "",
  });
  const [sourcesOptions, setSourcesOptions] = useState<any[]>([]);
  const [filename, setFilename] = useState<string>("");

  useEffect(() => {
    dispatch(fetchAllSource());

    return () => {
      dispatch(clearSources());
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(sources)) {
      const options: any[] = [];
      _.map(sources, (item) => {
        const name = _.get(item, "name");
        const code = _.get(item, "code");
        options.push({
          label: `${name} (${code})`,
          value: code,
        });
      });
      setSourcesOptions(_.orderBy(options, ["label", "asc"]));
    }
  }, [sources]);

  const checkInput = () => {
    let emptyKeys = `${t("alert.foundEmptyFields")}`;
    let flag = false;
    const data = [
      {
        label: t("label.linkCSV"),
        value: _.get(formData, "link"),
      },
      {
        label: t("label.nameCSV"),
        value: _.get(formData, "name"),
      },
      {
        label: t("label.sourceCode"),
        value: _.get(formData, "sourceCode"),
      },
    ];
    _.map(data, (item: any) => {
      const { value, label } = item;
      if (_.isEmpty(_.trim(value))) {
        flag = true;
        emptyKeys += label + "\n";
      }
    });
    if (flag)
      return popupAlert({
        type: "warning",
        title: t("alert.warning"),
        text: emptyKeys,
      });
    const resolveData = {
      link: _.trim(_.get(formData, "link")),
      name: _.trim(_.get(formData, "name")),
      sourceCode: _.trim(_.get(formData, "sourceCode")),
      sourceLink: _.trim(_.get(formData, "sourceLink")),
    };
    dispatch(createFileRecord(resolveData));
  };

  const checkInputUpload = () => {
    const data = [
      {
        label: t("label.sourceCode"),
        value: _.get(formDataUpload, "sourceCode"),
      },
      {
        label: t("label.fileCSV"),
        value: fileImport,
      },
    ];
    let flag = false;
    let emptyKeys = `${t("alert.foundEmptyFields")}`;
    _.map(data, (item: any) => {
      const { value, label } = item;
      const isHasName = value?.name;
      if (
        (_.isString(value) && _.isEmpty(_.trim(value))) ||
        (_.isObject(value) && !isHasName) ||
        !value
      ) {
        flag = true;
        emptyKeys += label + "\n";
      }
    });
    if (flag)
      return popupAlert({
        type: "warning",
        title: t("alert.warning"),
        text: emptyKeys,
      });

    const formDataRecord = new FormData();
    formDataRecord.append("sourceCode", _.trim(formDataUpload.sourceCode));
    if (fileImport) formDataRecord.append("fileRecord", fileImport || "");
    dispatch(uploadFileRecord(formDataRecord));
  };

  const findSourceLink = (code: string) => {
    if (code) {
      const findSource = _.find(sources, { code });
      if (findSource) return _.get(findSource, "link");
    }
    return "N/I";
  };

  const onSelectFile = (e: any) => {
    if (e.target.files[0]) {
      setFileImport(e.target.files[0]);
      const file = e.target.files[0];
      setFilename(_.get(file, "name"));
    } else return;
  };

  const _renderForUploadFile = () => {
    return (
      <FormDetails maxWidth="300px">
        <FormGroup>
          <FormLabel aria-label="require">{t("label.sourceCode")}</FormLabel>
          <Autocomplete
            disablePortal
            options={sourcesOptions}
            sx={{ width: 1 }}
            disabled={sourceIsLoading}
            autoHighlight
            onChange={(event: any, newValue: any) => {
              const sourceLink = findSourceLink(newValue?.value);
              setFormDataUpload({
                ...formData,
                sourceCode: newValue?.value,
                sourceLink,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder={t("placeholder.sourceCode")}
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel aria-label="require">{t("label.sourceLink")}</FormLabel>
          <TextField
            value={formDataUpload.sourceLink}
            placeholder={t("placeholder.sourceLink")}
            size="small"
            fullWidth
            disabled
          />
        </FormGroup>
        <FormGroup>
          <FormLabel aria-label="require">{t("label.fileCSV")}</FormLabel>
          <InputFile
            onChangeHandler={(e: any) => onSelectFile(e)}
            accept=".csv"
            margin="10px 10px 10px 0"
            filename={filename}
          />
        </FormGroup>
        <div>
          <Button onClick={() => checkInputUpload()} {...ButtonStyleProps}>
            {t("button.import")}
          </Button>
        </div>
        <Box sx={{ mt: 3, textAlign: "end" }}>
          <Button
            onClick={() =>
              history.push({ pathname: RouteTypes.ADMIN_ROUTES.FILE_RECORD })
            }
            {...ButtonStyleProps}
          >
            {t("button.back")}
          </Button>
        </Box>
      </FormDetails>
    );
  };

  const _renderForAddCSV = () => {
    return (
      <FormDetails maxWidth="300px">
        <FormGroup>
          <FormLabel aria-label="require">{t("label.linkCSV")}</FormLabel>
          <TextField
            value={formData.link}
            onChange={(e) => setFormData({ ...formData, link: e.target.value })}
            placeholder={t("placeholder.linkCSV")}
            size="small"
            fullWidth
          />
        </FormGroup>
        <FormGroup>
          <FormLabel aria-label="require">{t("label.nameCSV")}</FormLabel>
          <TextField
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            placeholder={t("placeholder.name")}
            size="small"
            fullWidth
          />
        </FormGroup>
        <FormGroup>
          <FormLabel aria-label="require">{t("label.sourceCode")}</FormLabel>
          <Autocomplete
            disablePortal
            options={sourcesOptions}
            sx={{ width: 1 }}
            disabled={sourceIsLoading}
            autoHighlight
            onChange={(event: any, newValue: any) => {
              const sourceLink = findSourceLink(newValue?.value);
              setFormData({
                ...formData,
                sourceCode: newValue?.value,
                sourceLink,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder={t("placeholder.sourceCode")}
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>{t("label.sourceLink")}</FormLabel>
          <TextField
            value={formData.sourceLink}
            placeholder={t("placeholder.sourceLink")}
            size="small"
            fullWidth
            disabled
          />
        </FormGroup>

        <div>
          <Button onClick={() => checkInput()} {...ButtonStyleProps}>
            {t("button.import")}
          </Button>
        </div>
      </FormDetails>
    );
  };

  const _renderMain = () => {
    return (
      <Container>
        {_renderForAddCSV()}
        <span className="seperate">{t("label.or")}</span>
        {_renderForUploadFile()}
      </Container>
    );
  };

  return (
    <DefaultLayout
      content={_renderMain()}
      loading={isLoading}
      currentPage={RouteTypes.ADMIN_ROUTES.FILE_RECORD}
      parent={RouteTypes.ADMIN_ROUTES.MANAGE_RECORD}
      title={t("titlePage.fileRecords")}
      subTitle={t("titlePage.addCSV")}
    />
  );
};

export default AddCSV;
