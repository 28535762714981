import React from "react";
import { useLocation } from "react-router-dom";
import RouteTypes from "../../../constants/RouteTypes";
import history from "../../../history";
import { Images } from "../../../themes";
import {
  NotFoundContent,
  NotFoundPageContainer,
  Paragraph,
} from "./NotFoundPage.styles";

const NotFoundPage = () => {
  const pathname = useLocation().pathname;

  const renderMain = () => {
    return (
      <NotFoundPageContainer>
        <NotFoundContent>
          <img
            src={Images.notFound}
            alt=""
            style={{ width: "100%", maxWidth: "500px" }}
          />
          <Paragraph>
            The requested URL <b>{pathname}</b> was not found on this server
            <span
              onClick={() => history.push(RouteTypes.USER_ROUTES.USER_ARTICLES)}
            >
              Back to home
            </span>
          </Paragraph>
        </NotFoundContent>
      </NotFoundPageContainer>
    );
  };
  return renderMain();
};
export default NotFoundPage;
