import styled from "styled-components";
import { device } from "constants/Devices";
import ColorName from "constants/Variables";

const ContainerSearch = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: 1140px;
  flex-wrap: wrap;
`;

const ContainerInformation = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const ContainerForm = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 1140px;
  flex-wrap: wrap;
`;

const ContainerFormCreateUser = styled.div`
  overflow: auto;
  display: flex;
  background: white;
  justify-content: space-between;
  flex-direction: column;
  & .form-title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 41px;
    color: ${ColorName.william};
    border-top: 1px solid ${ColorName.william};
    width: 100%;
    padding-top: 1em;
  }
  & .form-group {
    color: ${ColorName.william};
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    flex-wrap: wrap;
    & .container-element {
      width: 100%;
      min-width: 280px;
      padding: 5px;
      flex: 1;
      & .advanced-field {
        display: flex;
      }
    }
    & .uppercase-item {
      border: 1px solid ${ColorName.william};
      color: ${ColorName.william};
      font-weight: 700;
      border-radius: 50%;
      display: flex;
      height: 40px;
      width: 45px;
      margin: 10px 10px 0 0px;
      line-height: 38px;
      place-content: center;
      cursor: pointer;
    }
    & span {
      min-height: 1px;
    }
    &.fields {
      align-items: flex-start;
      margin: 10px 0;
    }
    @media ${device.tablet} {
      flex-direction: row;
    }
    flex-direction: column;
    & .textarea-search {
      resize: none;
      min-width: 280px;
      width: 390px;
      padding: 10px;
      border: 1px solid ${ColorName.william};
      outline: none;
      height: 265px;
      font-family: "Be Vietnam";
    }
  }
  & #custom-file-input {
    border: none;
    box-shadow: none;
    padding: 0;
    width: 120px;
  }

  & #custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }

  & #custom-file-input::before {
    content: "Choose a file";
    display: inline-block;
    border: 1px solid #999;
    border-radius: 3px;
    padding: 8px 12px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 600;
    background: ${ColorName.burntSienna};
    color: white;
  }
`;

const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  /* max-width: 60%; */
  min-width: 375px;
  border-radius: 20px;
  background-color: ${ColorName.white};
  & .title {
    display: flex;
    padding: 20px;
    font-size: 22px;
    font-weight: bold;
    color: ${ColorName.william};
    border-bottom: 1px solid ${ColorName.linkWater};
    background: ${ColorName.spindle};
    place-content: center;
  }
  & .content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: auto;
  }
  & .footer {
    display: flex;
    padding: 0 20px;
    justify-content: center;
    border-top: 1px solid ${ColorName.linkWater};
  }
  /* @media ${device.tablet} {
    max-width: 80%;
    width: 840px;
  } */
`;

const RelativeField = styled.div`
  width: 100px;
  margin-right: 15px;
`;

const QueryBox = styled.div`
  width: 100%;
  border: 1px solid ${ColorName.william};
  padding: 5px;
  box-sizing: border-box;
  position: relative;
`;

const RemoveButton = styled.div`
  background: ${ColorName.burntSienna};
  color: ${ColorName.white};
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;

  &:hover {
    cursor: pointer;
    background: ${ColorName.piper};
  }
`;

const PreviewRawContent = styled.div`
  height: 100%;
  width: 400px;
  padding: 15px;
  box-sizing: border-box;
`;

const TemplateDetailsContent = styled.div`
  width: 50vw;
  height: auto;
  max-width: 1040px;
  max-height: 400px;
  background: white;
`;

const TemplateDetailsForm = styled.div`
  padding: 15px 30px;
  max-height: 460px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  & .form-group {
    color: ${ColorName.william};
    align-items: center;
    font-size: 16px;
    margin-bottom: 15px;
    & .empty {
      color: ${ColorName.amaranth};
      font-size: 12px;
      height: 17px;
    }
    > span {
      display: inline-block;
      min-height: 1px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &.fields {
      align-items: flex-start;
      margin: 10px 0;
    }
  }
`;

const ButtonField = styled.div`
  display: flex;
  background: ${ColorName.white};
  justify-content: flex-end;
  padding: 10px 15px 10px 0;
`;

const TemplateDetailsHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${ColorName.william};
  color: white;
  font-size: 15px;
  height: 35px;
`;

const TemplateDetailsContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.25);
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  & .justify-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & .justify-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .justify-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

export {
  ContainerSearch,
  ContainerInformation,
  ContainerForm,
  Content,
  ContainerFormCreateUser,
  RelativeField,
  QueryBox,
  RemoveButton,
  PreviewRawContent,
  TemplateDetailsContent,
  TemplateDetailsForm,
  ButtonField,
  TemplateDetailsHeader,
  TemplateDetailsContainer,
};
