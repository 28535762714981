import React, { useRef } from "react";
import { AvatarContainer, EditButton } from "./Avatar.styles";
import { Images } from "../../../themes";

interface SectionProps {
  width?: string;
  height?: string;
  src: string;
  onChange(e: any): void;
}

const Avatar = (props: SectionProps) => {
  const { width, height, src, onChange } = props;
  const avatarRef = useRef<HTMLInputElement>(null);

  return (
    <AvatarContainer width={width} height={height} background={src || Images.defaultPhoto}>
      <input
        type="file"
        accept="*/image"
        ref={avatarRef}
        onChange={(e: any) => onChange(e)}
      />
      <EditButton
        onClick={() =>
          avatarRef && avatarRef.current && avatarRef.current.click()
        }
      >
        <i className="fas fa-pen" />
      </EditButton>
    </AvatarContainer>
  );
};

export default Avatar;
