import ActionTypes from "constants/ActionTypes";
import API from "api";
import _ from "lodash";
import { saveAs } from "file-saver";
import { responseAlert } from "libs/alert";
import history from "../history";

const clearFilters = () => {
  return {
    type: ActionTypes.CLEAR_FILTERS,
  };
};

const clearFilterDetails = () => {
  return {
    type: ActionTypes.CLEAR_FILTER_DETAILS,
  };
};

const filterFail = () => {
  return {
    type: ActionTypes.FILTER_FAILURE,
  };
};

const filterLoading = () => {
  return {
    type: ActionTypes.FILTER_REQUEST,
  };
};

const createFilterSuccess = () => {
  return {
    type: ActionTypes.CREATE_FILTER_SUCCESS,
  };
};

const updateFilterSuccess = (payload = {}) => {
  return {
    type: ActionTypes.UPDATE_FILTER_SUCCESS,
    payload,
  };
};

const fetchFiltersSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_FILTER_SUCCESS,
    payload,
  };
};

const getFilterByIdSuccess = (payload = {}) => {
  return {
    type: ActionTypes.GET_FILTER_BY_ID_SUCCESS,
    payload,
  };
};

const deactivateFilterSuccess = (payload = {}) => {
  return {
    type: ActionTypes.DEACTIVATE_FILTER_SUCCESS,
    payload,
  };
};

const activateFilterSuccess = (payload = {}) => {
  return {
    type: ActionTypes.ACTIVATE_FILTER_SUCCESS,
    payload,
  };
};

const createFilter = (data: any, filter: any) => {
  return async (dispatch: any) => {
    dispatch(filterLoading());
    await API.addFilter(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          await responseAlert({ res, label: "Filter created" });
          dispatch(createFilterSuccess());
          history.push(filter);
        } else {
          responseAlert({ res });
          dispatch(filterFail());
        }
      })
      .catch((e: any) => {
        dispatch(filterFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchFilters = () => {
  return async (dispatch: any) => {
    dispatch(filterLoading());
    await API.fetchFilters()
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchFiltersSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(filterFail());
        }
      })
      .catch((e: any) => {
        dispatch(filterFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getFilterByID = (id: string) => {
  return async (dispatch: any) => {
    dispatch(filterLoading());
    await API.getFilterByID(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(getFilterByIdSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(filterFail());
        }
      })
      .catch((e: any) => {
        dispatch(filterFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const searchFilters = (data: any) => {
  return async (dispatch: any) => {
    dispatch(filterLoading());
    await API.searchFilters(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchFiltersSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(filterFail());
        }
      })
      .catch((e: any) => {
        dispatch(filterFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const updateFilter = (data: any, id: any, filter: any) => {
  return async (dispatch: any) => {
    dispatch(filterLoading());
    await API.updateFilter(data, id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          await responseAlert({ res, label: "Filter updated" });
          dispatch(updateFilterSuccess(res.payload));
          history.push(filter);
        } else {
          responseAlert({ res });
          dispatch(filterFail());
        }
      })
      .catch((e: any) => {
        dispatch(filterFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const deactivateFilter = (id: string) => {
  return async (dispatch: any) => {
    dispatch(filterLoading());
    await API.deactivateFilter(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Filter deactivated" });
          dispatch(deactivateFilterSuccess(res.payload));
        } else {
          responseAlert({ res });
          dispatch(filterFail());
        }
      })
      .catch((e: any) => {
        dispatch(filterFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const activateFilter = (id: string) => {
  return async (dispatch: any) => {
    dispatch(filterLoading());
    await API.activateFilter(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Filter activated" });
          dispatch(activateFilterSuccess(res.payload));
        } else {
          responseAlert({ res });
          dispatch(filterFail());
        }
      })
      .catch((e: any) => {
        dispatch(filterFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const exportFilter = () => {
  return async (dispatch: any) => {
    dispatch(filterLoading());
    await API.exportFilter()
      .then(async (res: any) => {
        saveAs(res, "filters.xlsx");
        dispatch(activateFilterSuccess(res.payload));
      })
      .catch((e: any) => {
        dispatch(filterFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

export {
  createFilter,
  fetchFilters,
  getFilterByID,
  searchFilters,
  updateFilter,
  deactivateFilter,
  activateFilter,
  exportFilter,
  clearFilterDetails,
  clearFilters,
};
