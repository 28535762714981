import React from "react";
import { ComponentTextarea, TextareaMain } from "./Textarea.styles";

interface SectionProps {
  margin?: string;
  id?: string;
  width?: string;
  placeholder?: string;
  value?: any;
  ref?: any;
  onChangeHandler?: (e: any) => void;
  onKeyPress?: (e: any) => void;
  isRequired?: boolean;
  disabled?: boolean;
  minHeight?: string;
  rows?: number;
  flex?: number;
}

const Textarea: React.FC<SectionProps> = (props: SectionProps) => {
  const {
    margin,
    width,
    minHeight,
    placeholder,
    value,
    onChangeHandler,
    isRequired = false,
    id,
    onKeyPress,
    ref,
    disabled = false,
    rows,
    flex,
  } = props;

  const onChangeInput = (e: any) => {
    if (onChangeHandler) onChangeHandler(e);
  };

  return (
    <ComponentTextarea flex={flex} width={width} margin={margin}>
      <TextareaMain
        placeholder={placeholder}
        onChange={(e) => onChangeInput(e)}
        value={value}
        isRequired={isRequired}
        id={id}
        onKeyPress={onKeyPress}
        ref={ref}
        disabled={disabled}
        minHeight={!rows ? minHeight : "unset"}
        rows={rows}
      />
    </ComponentTextarea>
  );
};

export default Textarea;
