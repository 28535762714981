import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  // Redirect,
  useLocation,
} from "react-router-dom";
import {
  authenticationValidate,
  clearAllSavedData,
  getSavedUserData,
  getSavedRememberMe,
  getSavedRefreshToken,
} from "libs/Utils";
import API from "api";
import history from "../history";
import ROUTES from "constants/RouteTypes";
import _ from "lodash";
// import { CONSTANTS } from "constants/Constants";
import { AuthenticationActions } from "actions";
import { CONSTANTS } from "constants/Constants";
import RouteTypes from "constants/RouteTypes";

const PrivateRoute = (props: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLogged = useSelector((state) =>
    _.get(state, "Authentication.isLogged")
  );
  const isRefreshToken = useSelector((state) =>
    _.get(state, "Authentication.isRefreshToken")
  );
  const token = API.getToken();
  const arrayListNotAuthentication = _.values(ROUTES.AUTHENTICATION_ROUTES);
  const routerNotValidate = [
    ROUTES.USER_ROUTES.USER_ARTICLES,
    ROUTES.USER_ROUTES.USER_ARTICLES_DETAILS,
  ];
  const arrayAdminRoutes = _.values(ROUTES.ADMIN_ROUTES);
  const arrayClientRoutes = _.values(ROUTES.CLIENT_ROUTES);
  const arrayUserRoutes = _.values(ROUTES.USER_ROUTES);
  const pathName = location.pathname;
  const userData = getSavedUserData();

  const isURLsNotAuthentication = () => {
    let flag = false;
    _.map(arrayListNotAuthentication, (value, index) => {
      if (pathName === value) return (flag = true);
    });
    return flag;
  };

  const isAdminRoutes = () => {
    let flag = false;
    _.map(arrayAdminRoutes, (value) => {
      if (pathName === value) return (flag = true);
    });
    return flag;
  };

  const isClientRoutes = () => {
    let flag = false;
    _.map(arrayClientRoutes, (value) => {
      if (pathName === value) return (flag = true);
    });
    return flag;
  };

  const isUserRoutes = () => {
    let flag = false;
    const regex =
      /\/articles\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    _.map(arrayUserRoutes, (value) => {
      if (pathName === value || regex.test(pathName)) return (flag = true);
    });
    return flag;
  };

  const validateArticlesDetails = () => {
    const regex = new RegExp(
      /\/articles\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
    );
    const result = regex.test(pathName);
    return result;
  };

  const redirectPage = () => {
    if (userData) {
      const isAdmin = isAdminRoutes();
      const isUser = isUserRoutes();
      const isClient = isClientRoutes();
      const { userType } = userData;

      const isArticlesDetails = validateArticlesDetails();
      if (!isArticlesDetails) {
        if (userType === CONSTANTS.USER_TYPE.CLIENT) {
          if (!isClient)
            return history.push(RouteTypes.CLIENT_ROUTES.PACKAGES_MANAGEMENT);
        } else if (userType === CONSTANTS.USER_TYPE.ADMIN) {
          if (!isAdmin) {
            return history.push(RouteTypes.ADMIN_ROUTES.HOMEPAGE);
          }
        } else if (userType === CONSTANTS.USER_TYPE.USER) {
          if (!isUser) {
            return history.push(RouteTypes.USER_ROUTES.USER_ARTICLES);
          }
        }
      }
    } else {
      clearAllSavedData();
      return history.push(RouteTypes.USER_ROUTES.USER_ARTICLES);
    }
  };

  const _checkAuthentication = () => {
    authenticationValidate({
      found: async (access_token: string) => {
        const refreshToken = getSavedRefreshToken();
        const isRemember = getSavedRememberMe();

        if (!refreshToken && isRemember) {
          dispatch(AuthenticationActions.logout());
          return history.push(RouteTypes.AUTHENTICATION_ROUTES.AUTHENTICATION);
        }

        if (!isURLsNotAuthentication()) {
          if (!access_token) {
            clearAllSavedData();
            return history.push(
              RouteTypes.AUTHENTICATION_ROUTES.AUTHENTICATION
            );
          } else {
            if (refreshToken && isRefreshToken) {
              dispatch(AuthenticationActions.refreshToken(refreshToken));
            }
            if (!isLogged) await dispatch(AuthenticationActions.isLogged());
            if (!token) API.setToken(access_token);
            redirectPage();
          }
        } else {
          if (!access_token) {
            clearAllSavedData();
            return history.push(
              RouteTypes.AUTHENTICATION_ROUTES.AUTHENTICATION
            );
          } else {
            if (refreshToken && isRefreshToken) {
              dispatch(AuthenticationActions.refreshToken(refreshToken));
            }
            if (!isLogged) await dispatch(AuthenticationActions.isLogged());
            if (!token) API.setToken(access_token);
            redirectPage();
          }
        }
      },
      notFound: () => {
        if (!isURLsNotAuthentication()) {
          const isRouterNotValidate = _.includes(routerNotValidate, pathName);
          if (!isRouterNotValidate) {
            clearAllSavedData();
            return history.push(
              RouteTypes.AUTHENTICATION_ROUTES.AUTHENTICATION
            );
          }
        }
      },
    });
  };

  _checkAuthentication();

  return <Route {...props} />;
};

export default PrivateRoute;
