import React from "react";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import { LoadingButton } from "@mui/lab";
import ReactDOMServer from "react-dom/server";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  BlobProvider,
  Font,
  Link,
  Image,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import _ from "lodash";
import ColorName from "constants/Variables";
import { useTranslation } from "react-i18next";
import { popupAlert } from "../../../libs/Utils";
const BeVietnamBold = require("assets/fonts/BeVietnam-Bold.ttf");
const BeVietnamRegular = require("assets/fonts/BeVietnam-Regular.ttf");

Font.register({
  family: "Be Vietnam",
  fonts: [
    {
      src: BeVietnamRegular,
    },
    {
      src: BeVietnamBold,
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  page: {
    backgroundColor: "#E4E4E4",
    fontFamily: "Be Vietnam",
  },
  table: {
    width: "100%",
    border: "1px solid black",
    display: "flex",
    height: "100px",
    flexDirection: "row",
    marginBottom: 10
  },
  sourceRow: {
    borderRight: "1px solid black",
    flex: 1,
    padding: "10px",
    display: "flex",
    flexDirection: "column",
  },
  detailRow: {
    borderRight: "1px solid black",
    flex: 1,
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 21,
    lineHeight: 1.2,
    textAlign: "left",
    marginTop: 10,
    marginBottom: 10,
    fontWeight: "bold",
    fontFamily: "Be Vietnam",
  },
  text: {
    fontSize: 13,
    lineHeight: 1.5,
    textAlign: "left",
    fontFamily: "Be Vietnam",
  },
  line: {
    width: "100%",
    borderBottom: "1px solid black",
  },
  sourceAndAuthor: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Be Vietnam",
  },
  detail: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Be Vietnam",
    marginBottom: 10,
  },
  dataInfo: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  footer: {
    position: "absolute",
    fontSize: 9,
    bottom: 40,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    paddingHorizontal: "10px",
  },
  link: {
    textDecoration: "none",
    color: "black",
    fontWeight: "bold",
    fontSize: 10,
  },
});

interface SectionProps {
  data?: any;
  isDownload?: boolean;
  multipleRow?: boolean;
  isHaveButton?: boolean;
  id?: string;
}

const ButtonStyleProps = {
  // buttonWidth: "100px",
  buttonMargin: "10px 10px 10px 0",
};

const RenderPDF = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { data, isDownload, multipleRow, isHaveButton } = props;

  const generateFilename = () => {
    const title = _.get(data, "title");
    if (title) {
      const convertNormalize = _.lowerCase(
        title.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      )
        .replace(/[^a-zA-Z ]/g, "")
        .replace(/ /g, "_");
      return `${convertNormalize}.pdf`;
    }
    return "post.pdf";
  };

  const MyDoc = () => {
    if (!multipleRow)
      return (
        <Document title={get(data, "title")}>
          <Page wrap style={styles.body}>
            <View style={styles.table}>
              <View style={styles.sourceRow}>
                <Link
                  style={{ ...styles.link, fontSize: 15 }}
                  src={get(data, "originalLink")}
                >
                  <Text>{get(data, "source.name")}</Text>
                </Link>
                <Text
                  style={{
                    ...styles.sourceAndAuthor,
                    fontWeight: "normal",
                    marginTop: "5px",
                  }}
                >
                  Publish Date:{" "}
                  {dayjs(get(data, "publishedAt")).format("DD/MM/YYYY")}
                </Text>
              </View>
              <View style={{ flex: 1, borderRight: "1px solid black" }} />
              <View style={{ ...styles.detailRow, padding: 0 }}>
                <Text
                  style={{
                    borderBottom: "1px solid black",
                    fontSize: 10,
                    padding: 5,
                    flex: 1,
                  }}
                >
                  Media Type: {capitalize(get(data, "source.mediaType"))}
                </Text>
                <Text
                  style={{
                    borderBottom: "1px solid black",
                    fontSize: 10,
                    padding: 5,
                    flex: 1,
                  }}
                >
                  Category:{" "}
                  {get(data, `category.name.${data?.language || "en"}`)}
                </Text>
                <Text style={{ fontSize: 10, padding: 5, flex: 1 }}>
                  Author: {get(data, "author")}
                </Text>
              </View>
            </View>
            <Text style={styles.line}></Text>
            <Text style={styles.title}>{get(data, "title")}</Text>
            <Text style={styles.detail}>
              Media Type: {capitalize(get(data, "source.mediaType"))}
            </Text>
            {get(data, "source.mediaType") === "print" &&
              get(data, "file.imagePath") && (
                <Image src={get(data, "file.imagePath")} cache={false} />
              )}
            {((get(data, "source.mediaType") === "print" &&
              !get(data, "file.imagePath")) ||
              get(data, "source.mediaType") !== "print") && (
              <Text style={styles.text}>{get(data, "content")}</Text>
            )}
            <Text style={styles.footer} fixed>
              Informacioni i mësiperm mund të jetë i mbrojtur nga të drejtat e
              autorit. Ju nuk mund të kopjoni, riprodhoni, regjistroni,
              transmetoni, shisni,publikoni, shpërndani, ta ndani me të tjere
              apo ta ruani këtë informacion në cdo forme apo me cdo mjet pa leje
              të shkruar nga publikuesit e tij.
            </Text>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </Page>
        </Document>
      );
    return (
      <Document title="PDF">
        {_.map(data, (item, index) => {
          return (
            <Page wrap style={styles.body} key={index}>
              <View style={styles.table}>
                <View style={styles.sourceRow}>
                  <Link
                    style={{ ...styles.link, fontSize: 15 }}
                    src={get(item, "originalLink")}
                  >
                    <Text>{get(item, "source.name")}</Text>
                  </Link>
                  <Text
                    style={{
                      ...styles.sourceAndAuthor,
                      fontWeight: "normal",
                      marginTop: "5px",
                    }}
                  >
                    Publish Date:{" "}
                    {dayjs(get(item, "publishedAt")).format("DD/MM/YYYY")}
                  </Text>
                </View>
                <View style={{ flex: 1, borderRight: "1px solid black" }} />
                <View style={{ ...styles.detailRow, padding: 0 }}>
                  <Text
                    style={{
                      borderBottom: "1px solid black",
                      fontSize: 10,
                      padding: 5,
                      flex: 1,
                    }}
                  >
                    Media Type: {capitalize(get(item, "source.mediaType"))}
                  </Text>
                  <Text
                    style={{
                      borderBottom: "1px solid black",
                      fontSize: 10,
                      padding: 5,
                      flex: 1,
                    }}
                  >
                    Category:{" "}
                    {get(item, `category.name.${item?.language || "en"}`)}
                  </Text>
                  <Text style={{ fontSize: 10, padding: 5, flex: 1 }}>
                    Author: {get(item, "author")}
                  </Text>
                </View>
              </View>
              <Text style={styles.line}></Text>
              <Text style={styles.title}>{get(item, "title")}</Text>
              <Text style={styles.text}>{get(item, "content")}</Text>
              <Text style={styles.footer} fixed>
                Informacioni i mësiperm mund të jetë i mbrojtur nga të drejtat e
                autorit. Ju nuk mund të kopjoni, riprodhoni, regjistroni,
                transmetoni, shisni,publikoni, shpërndani, ta ndani me të tjere
                apo ta ruani këtë informacion në cdo forme apo me cdo mjet pa
                leje të shkruar nga publikuesit e tij.
              </Text>
              <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </Page>
          );
        })}
      </Document>
    );
  };

  if (isDownload)
    return (
      <BlobProvider document={<MyDoc />}>
        {({ blob, url, loading, error }) => {
          let file;
          if (blob)
            file = new File([blob], "PDF.pdf", { type: "application/pdf" });
          const newUrl = file ? URL.createObjectURL(file) : url;
          return (
            <LoadingButton
              variant="contained"
              color="success"
              loading={loading}
              fullWidth
              size="small"
              onClick={(e: any) => {
                if (_.isEmpty(data))
                  return popupAlert({
                    type: "warning",
                    title: t("alert.warning"),
                    text: t("alert.atLeastOnePost"),
                  });
                else if (newUrl) {
                  const previewWindow = window.open(newUrl, "_blank");
                  if (previewWindow) {
                    previewWindow.onload = () => {
                      const downloadLink =
                        previewWindow.document.createElement("a");
                      downloadLink.href = newUrl;
                      downloadLink.download = !multipleRow
                        ? generateFilename()
                        : `ExportAt${dayjs().format("MM-DD-YYYY")}`; // set the desired filename here
                      downloadLink.style.position = "absolute";
                      downloadLink.style.right = "93px";
                      downloadLink.style.top = "8px";
                      downloadLink.style.width = "30px";
                      downloadLink.style.height = "30px";
                      downloadLink.style.display = "flex";
                      downloadLink.style.padding = "5px";
                      downloadLink.style.borderRadius = "50%";
                      downloadLink.addEventListener("mouseover", function () {
                        this.style.backgroundColor = "rgba(255,255,255,0.1)";
                      });
                      downloadLink.addEventListener("mouseout", function () {
                        this.style.backgroundColor = "transparent";
                      });
                      previewWindow.document.body.appendChild(downloadLink);
                    };
                  }
                }
              }}
            >
              PDF
            </LoadingButton>
          );
        }}
      </BlobProvider>
    );
  return (
    <BlobProvider document={<MyDoc />}>
      {({ blob, url, loading, error }) => {
        // Do whatever you need with blob here
        return <div>There's something going on on the fly</div>;
      }}
    </BlobProvider>
  );
};

export default RenderPDF;
