/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { RootState } from "reducers";
import { CategoriesActions, DataRecordsActions } from "actions";

import TableUltils from "libs/TableUltils";
import { generateDropdown, generateFilter, popupAlert } from "libs/Utils";
import history from "../../../history";
import { CONSTANTS } from "constants/Constants";
import RouteTypes from "constants/RouteTypes";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Table,
  Input,
  Button,
  SelectDatetime,
  Dropdown,
  ContainerSearch,
  FlexWrapRow,
} from "components/Common";
import { TVNews, ViewDetailsNews } from "components/Common/Popup";
import { useLocation } from "react-router-dom";

const DEFAULT_FILTER = {
  dateFrom: "",
  dateTo: "",
  category: "",
  keyword: "",
  page: 1,
  limit: 10,
  sortBy: "updatedAt",
  orderBy: "DESC",
  status: "hidden",
};

interface FilterStructure {
  dateFrom: string;
  dateTo: string;
  category: string;
  keyword: string;
  page: number;
  limit: number;
  sortBy: string;
  orderBy: string;
}

const ButtonStyleProps = {
  buttonMargin: "10px 10px 10px 0",
};

const InputStyleProps = {
  margin: "10px 10px 10px 0",
  width: "200px",
};

const { fetchAllCategories, clearCategories } = CategoriesActions;
const {
  deleteDataRecord,
  searchDataRecord,
  clearDataRecords,
  unhiddenDataRecord,
} = DataRecordsActions;

const DataRecord = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const dataRecordIsLoading = useSelector((state: RootState) => {
    return state.DataRecord.isLoading;
  });
  const categoryIsLoading = useSelector((state: RootState) => {
    return state.Categories.isLoading;
  });
  const dataRecords = useSelector((state: RootState) => {
    return state.DataRecord.dataRecords;
  });
  const categories = useSelector((state: RootState) => {
    return state.Categories.categories;
  });
  const location = useLocation();
  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const [tvData, setTVData] = useState<any>({});
  const [isPreViewPopup, setIsPreViewPopup] = useState<boolean>(false);
  const [isShowTVNewsDetails, setIsShowTVNewsDetails] =
    useState<boolean>(false);
  const [contentPopup, setContentPopup] = useState({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataRecordsState, setDataRecordsState] = useState<any>([]);

  const dataHead = [
    "no",
    "title",
    "author",
    "createdAt",
    "publishedAt",
    "preview",
    "actions",
  ];

  const dataTable = TableUltils._processDataTable(
    [
      "no",
      "id",
      "title",
      "author",
      "createdAt",
      "publishedAt",
      "preview",
      "action",
      "content",
    ],
    dataRecordsState
  );

  useEffect(() => {
    const stateFilter: any = _.get(location, "state.filter");
    const prevRoute = _.get(location, "state.from");
    const resolveFilter = {
      ...filter,
    };
    if (
      stateFilter &&
      prevRoute === RouteTypes.ADMIN_ROUTES.DATA_RECORD_DETAILS
    ) {
      _.assign(resolveFilter, { ...stateFilter });
      history.replace({ ...history.location, state: {} });
    }
    dispatch(searchDataRecord(generateFilter(filter)));
    dispatch(fetchAllCategories({ status: CONSTANTS.STATUS.ACTIVE }));

    return () => {
      dispatch(clearDataRecords());
      dispatch(clearCategories());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(dataRecords)) {
      const getDataLogs: any[] = _.get(dataRecords, "items");
      if (currentPage > 1) {
        setDataRecordsState([...dataRecordsState, ...getDataLogs]);
      } else setDataRecordsState(getDataLogs);
    }
  }, [dataRecords]);

  const dataDropdownCategories = generateDropdown({
    data: categories,
    key: "name",
    value: "id",
  });
  const listColumnCenter = [4, 5, 6, 7];

  const callbackCurrentPageLink = (page: number) => {
    const newFilter: FilterStructure = {
      ...filter,
      page,
    };
    setFilter(newFilter);
    dispatch(searchDataRecord(generateFilter(newFilter)));
  };

  const addFilter = (page: number) => {
    const newFilter = {
      ...filter,
      page: page,
    };
    setFilter(newFilter);
    setCurrentPage(page);
    dispatch(searchDataRecord(generateFilter(newFilter)));
  };

  const onReset = async () => {
    setFilter(DEFAULT_FILTER);
    setCurrentPage(1);
    dispatch(searchDataRecord(generateFilter(DEFAULT_FILTER)));
  };

  const onFetchMore = () => {
    if (
      !dataRecordIsLoading &&
      currentPage < _.get(dataRecords, "meta.totalPages")
    )
      addFilter(filter.page + 1);
  };

  const onShowMore = () => {
    if (
      !dataRecordIsLoading &&
      currentPage < _.get(dataRecords, "meta.totalPages")
    )
      addFilter(filter.page + 1);
  };

  const callbackAction = (action: string, id: string, item: any) => {
    const dataRecordName = _.get(item, "title");
    const content = document.createElement("span");
    if (action === CONSTANTS.ACTION.UNHIDE) {
      content.innerHTML = `${t("alert.areYouSureYouWantToUnhide")} ${t(
        "alert.theRecord"
      )}</br> <b>${dataRecordName}</b>?`;
    }
    if (action === CONSTANTS.ACTION.REMOVE) {
      content.innerHTML = `${t("alert.areYouSureYouWantToRemove")} ${t(
        "alert.theRecord"
      )}</br> <b>${dataRecordName}</b>?`;
    }
    popupAlert({
      type: "warning",
      title: t("alert.warning"),
      content: content,
      buttons: true,
      dangerMode: true,
    }).then(async (willContinue) => {
      if (willContinue) {
        if (action === CONSTANTS.ACTION.UNHIDE)
          await dispatch(
            unhiddenDataRecord({ ids: [id] }, generateFilter(filter))
          );
        else await dispatch(deleteDataRecord(id, filter));
      }
    });
  };

  const callBackPreview = (item: any) => {
    const id = _.get(item, "id");
    const findDataRecord = _.find(dataRecords?.items, { id });
    if (_.isEmpty(findDataRecord))
      popupAlert({
        title: t("alert.error"),
        type: "error",
        text: t("alert.errorViewDetailsDataRecord"),
      });
    else {
      setContentPopup(findDataRecord);
      setIsPreViewPopup(true);
    }
  };

  const _renderTable = () => {
    return (
      <Table
        dataHeader={dataHead}
        dataTable={dataTable}
        listActionItem={[CONSTANTS.ACTION.UNHIDE, CONSTANTS.ACTION.REMOVE]}
        callBackAction={callbackAction}
        callBackPreview={callBackPreview}
        fitColumns={[6, 7]}
        listColumnCenter={listColumnCenter}
        hasPagination={true}
        callbackCurrentPageLink={callbackCurrentPageLink}
        metaPagination={dataRecords.meta}
        columnNotPrint={["content"]}
        loading={dataRecordIsLoading}
        showMore={onShowMore}
      />
    );
  };

  const renderMain = () => {
    return (
      <>
        {isPreViewPopup && (
          <ViewDetailsNews
            data={contentPopup}
            onClose={() => {
              setIsPreViewPopup(false);
              setContentPopup({});
            }}
          />
        )}
        {isShowTVNewsDetails && (
          <TVNews
            data={tvData}
            onClose={() => {
              setIsShowTVNewsDetails(false);
              setTVData({});
              dispatch(searchDataRecord(generateFilter(filter)));
            }}
          />
        )}
        <ContainerSearch className={dataRecordIsLoading ? "loading" : ""}>
          <FlexWrapRow>
            <SelectDatetime
              callbackPayload={(e) => setFilter({ ...filter, dateFrom: e })}
              defaultValue={filter?.dateFrom}
              placeholder={t("placeholder.dateFrom")}
              {...InputStyleProps}
            />
            <SelectDatetime
              callbackPayload={(e) => setFilter({ ...filter, dateTo: e })}
              defaultValue={filter?.dateTo}
              placeholder={t("placeholder.dateTo")}
              {...InputStyleProps}
            />
            <Dropdown
              value={filter?.category}
              callbackPayload={(value) =>
                setFilter({ ...filter, category: value })
              }
              options={dataDropdownCategories}
              placeholder={t("placeholder.category")}
              search={true}
              multiple={true}
              loading={categoryIsLoading}
              {...InputStyleProps}
            />
            <Input
              value={filter?.keyword}
              onChangeHandler={(e) =>
                setFilter({ ...filter, keyword: e.target.value })
              }
              onEnter={() => addFilter(1)}
              placeholder={t("placeholder.keyword")}
              {...InputStyleProps}
            />
            <Button
              type="submit"
              onClick={() => addFilter(1)}
              {...ButtonStyleProps}
            >
              {t("button.search")}
            </Button>
            <Button
              type="submit"
              onClick={() => onReset()}
              {...ButtonStyleProps}
            >
              {t("button.reset")}
            </Button>
          </FlexWrapRow>
        </ContainerSearch>
        {_renderTable()}
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.ADMIN_ROUTES.HIDDEN}
      parent={RouteTypes.ADMIN_ROUTES.FOLDERS}
      title={t("button.hidden")}
      onScrollBottom={() => onFetchMore()}
    />
  );
};

export default DataRecord;
