import styled from 'styled-components';
import ColorName from 'constants/Variables';

interface SectionProps {
  padding?: string;
  margin?: string;
  width?: string;
  borderWidth?: string;
  height?: string;
}

const Option = styled.div`
  cursor: pointer;
  text-transform: capitalize;
  &:hover{
    background: ${ColorName.william};
  }
`;

const ListOption = styled.div`
  display: none;
  position: absolute;
  background: ${ColorName.white};
  border-radius: 16px;
  & ${Option} {
    padding: 5px 10px;
    border-bottom: 1px solid ${ColorName.william};
    display: flex;
    justify-content: center;
    &:last-child {
    	border-width: 0;
    }
  }
`;

const Action = styled.div`
  background: ${ColorName.white};
  display: flex;
  position: relative;
  align-items: center;
  padding: 8px 20px;
  border: solid ${ColorName.zircon};
  border-width: ${(props: SectionProps) => props.borderWidth || '1px'};
  border-radius: 16px;
  cursor: pointer;
  width: ${(props: SectionProps) => props.width || 'unset'};
  height: ${(props: SectionProps) => props.height || 'unset'};
  justify-content: space-between;
  box-sizing: border-box;
  text-transform: capitalize;

  & i {
    margin-left: 10px;
  }
  &.active {
    & ${ListOption} {
      display: flex;
      flex-direction: column;
      top: 105%;
      left: 0;
      right: 0;
      border: 1px solid ${ColorName.william};
      width: 100%;
      background: ${ColorName.white};
      z-index: 15;
      overflow: hidden;
    }
  }
  & img {
    margin-right: 10px;
  }
`;


const Wrapper = styled.div`
  padding: ${(props: SectionProps) => props.padding || '0'};
  margin: ${(props: SectionProps) => props.margin || '0'};
  font-size: 14px;
  &.dropdown-pagination {
    & ${Action} {
      &.active {
        & ${ListOption} {
          top: -98px;
          flex-direction: column-reverse;
          & ${Option} {
            border-width: 1px;
            &:first-child {
              border-width: 0;
            }
          }
        }
      }
    }
  }
`;

export { Wrapper, Action, ListOption, Option };
