import ActionTypes from "constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  roles: [],
  role: {},
  privileges: {},
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.CLEAR_ROLES:
      return {
        ...state,
        roles: [],
      };
    case ActionTypes.CLEAR_ROLE_DETAILS:
      return {
        ...state,
        role: {},
      };
    case ActionTypes.ROLE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.ROLE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.ROLE_SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: true,
      };
    case ActionTypes.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_ROLE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        roles: payload,
      };
    case ActionTypes.GET_ROLE_BY_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        role: payload,
      };
    case ActionTypes.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        role: payload,
      };
    case ActionTypes.FETCH_PRIVILEGES_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        privileges: payload,
      };
    default:
      return state;
  }
};
