/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "reducers";
import {
  AccountActions,
  EmailDeliveryActions,
  FiltersActions,
  UsersActions,
  EmailTemplateActions,
} from "actions";

import {
  generateDropdown,
  popupAlert,
  processDataDropDownAccount,
} from "libs/Utils";
import history from "../../../history";
import RouteTypes from "constants/RouteTypes";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  TemplateDetailsForm,
  ButtonField,
  EmailTemplateContent,
  PreLayer,
} from "./Delivery.styles";
import {
  Button,
  Dropdown,
  Input,
  Checkbox,
  GridLayout,
  MailTagList,
  LoadingLayer,
  Spinner,
  FormGroup,
  FormInline,
  FormLabel,
} from "components/Common";
import { EmailTemplateDetails } from "components/Common/Popup";
import { useTranslation } from "react-i18next";
import { CONSTANTS } from "constants/Constants";

const { searchAccounts } = AccountActions;
const {
  createEmailDelivery,
  getEmailDeliveryById,
  updateEmailDelivery,
  clearEmailDelivery,
} = EmailDeliveryActions;
const { searchFilters } = FiltersActions;
const { searchUsers } = UsersActions;
const { getEmailTemplateByType, clearEmailTemplateDetails } =
  EmailTemplateActions;

const TIME_IN_DAY = [
  {
    label: "06:00",
    value: 6,
  },
  {
    label: "12:00",
    value: 12,
  },
  {
    label: "18:00",
    value: 18,
  },
];

const InputStyleProps = {
  width: "100%",
};

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "0 0 0 10px",
};

const EmailDeliveryDetails = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation("translation");
  const location = useLocation();
  const mailDeliveryLoading = useSelector((state: RootState) => {
    return state.EmailDelivery.isLoading;
  });
  const mailTemplateIsLoading = useSelector((state: RootState) =>
    _.get(state, "EmailTemplate.isLoading")
  );
  const accounts = useSelector((state: RootState) => {
    return state.Account.accounts;
  });
  const filters = useSelector((state: RootState) => {
    return state.Filters.filters;
  });
  const filtersIsLoading = useSelector((state: RootState) => {
    return state.Filters.isLoading;
  });
  const users = useSelector((state: RootState) => {
    return state.Clients.usersClient;
  });
  const usersIsLoading = useSelector((state: RootState) => {
    return state.Users.isLoading;
  });
  const mailDelivery = useSelector((state: RootState) => {
    return state.EmailDelivery.emailDelivery;
  });
  const emailTemplate = useSelector((state: RootState) =>
    _.get(state, "EmailTemplate.emailTemplate")
  );
  const accountIsLoading = useSelector((state: RootState) => {
    return state.Account.isLoading;
  });

  const [isShowCreateTemplate, setIsShowCreateTemplate] =
    useState<boolean>(false);
  const [accountId, setAccountId] = useState("");
  const [mailDeliveryData, setMailDeliveryData] = useState<any>({
    scheduleName: "",
    accountId: "",
    filters: [],
    dateDelivery: [],
    deliveryAt: [],
    emailReceive: [],
    excludeEmails: [],
  });
  const [required, setRequired] = useState<any>({
    scheduleName: false,
    accountId: false,
    filters: false,
    dateDelivery: false,
    deliveryAt: false,
    emailReceive: false,
    excludeEmails: [],
  });

  const DAY_OF_WEEK = [
    {
      label: t("dropdown.monday"),
      value: 1,
    },
    {
      label: t("dropdown.tuesday"),
      value: 2,
    },
    {
      label: t("dropdown.wednesday"),
      value: 3,
    },
    {
      label: t("dropdown.thursday"),
      value: 4,
    },
    {
      label: t("dropdown.friday"),
      value: 5,
    },
    {
      label: t("dropdown.saturday"),
      value: 6,
    },
    {
      label: t("dropdown.sunday"),
      value: 0,
    },
  ];

  useEffect(() => {
    const id = _.get(location, "state.id");
    if (id) dispatch(getEmailDeliveryById(id));

    dispatch(searchAccounts({ status: CONSTANTS.STATUS.ACTIVE, limit: 100 }));
    dispatch(getEmailTemplateByType(CONSTANTS.EMAIL_TYPE.EMAIL_SCHEDULE));

    return () => {
      setMailDeliveryData({
        scheduleName: "",
        accountId: "",
        filters: "",
        dateDelivery: [],
        deliveryAt: [],
        emailReceive: [],
      });
      setAccountId(accountId);
      history.replace({ ...history.location, state: {} });
      dispatch(clearEmailDelivery());
      dispatch(clearEmailTemplateDetails());
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(mailDelivery)) {
      const accountId = _.get(mailDelivery, "accountId.id");
      const clientId = _.get(mailDelivery, "clientId.id");
      const { excludeEmails, ...rest } = mailDeliveryData;
      const resolveData = {
        ...rest,
        ...mailDelivery,
        accountId,
        clientId,
      };
      setAccountId(accountId);
      setMailDeliveryData(resolveData);
    }
  }, [mailDelivery]);

  useEffect(() => {
    const currentAccount = _.find(accounts.items, { id: accountId });
    const accountName = _.get(currentAccount, "accountName");
    if (accountName && accountId !== _.get(mailDeliveryData, "accountId"))
      dispatch(searchFilters({ accountName }));
  }, [mailDeliveryData]);

  useEffect(() => {
    if (!_.isEmpty(accountId)) {
      dispatch(searchUsers({ accountId }));
      dispatch(
        searchFilters({ status: CONSTANTS.STATUS.ACTIVE, accountId, limit: 50 })
      );
    }
  }, [accountId]);

  const onClear = async () => {
    const isAgree = await popupAlert({
      type: "warning",
      title: t("alert.warning"),
      text: t("alert.warningBeforeClear"),
      buttons: true,
      dangerMode: true,
    });
    if (isAgree) {
      setMailDeliveryData({
        scheduleName: "",
        accountId: "",
        filters: [],
        dateDelivery: [],
        deliveryAt: [],
        emailReceive: [],
      });
      setAccountId("");
      setRequired({
        scheduleName: false,
        accountId: false,
        filters: false,
        dateDelivery: false,
        deliveryAt: false,
        emailReceive: false,
      });
    }
  };

  const validateData = () => {
    let flag = true;
    let message = `${t("alert.foundEmptyFields")}`;
    const data = [
      {
        label: t("label.emailDeliveryName"),
        value: _.get(mailDeliveryData, "scheduleName"),
      },
      {
        label: t("label.packages"),
        value: _.get(mailDeliveryData, "accountId"),
      },
      {
        label: t("label.filters"),
        value: _.get(mailDeliveryData, "filters"),
      },
      {
        label: t("label.dateDelivery"),
        value: _.get(mailDeliveryData, "dateDelivery"),
      },
      {
        label: t("label.timeDelivery"),
        value: _.get(mailDeliveryData, "deliveryAt"),
      },
    ];
    _.map(data, (item) => {
      const value = _.get(item, "value");
      const label = _.get(item, "label");
      if (_.isEmpty(_.trim(value))) {
        flag = false;
        message += `${label}\n`;
      }
    });
    return { flag, message };
  };

  const onSubmit = async () => {
    const { flag, message } = validateData();
    if (!flag) {
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });
    }
    const findAccount = _.find(accounts.items, { id: accountId });
    const excludeEmails = _.get(mailDeliveryData, "excludeEmails");
    const trimEmail = _.map(excludeEmails, (email) => _.trim(email));
    const emailReceive = _.map(
      _.get(mailDeliveryData, "emailReceive"),
      (email) => _.trim(email)
    );
    const clientId = _.get(findAccount, "clientId.id");
    const data = {
      ...mailDeliveryData,
      clientId,
      excludeEmails: _.compact(trimEmail),
      emailReceive,
      scheduleName: _.trim(_.get(mailDeliveryData, "scheduleName")),
    };
    const mailDeliveryId = _.get(mailDelivery, "id");

    const filter = _.get(location, "state.filter");
    const resolveFilter = {
      pathname: RouteTypes.ADMIN_ROUTES.EMAIL_DELIVERY,
      state: { filter, from: RouteTypes.ADMIN_ROUTES.EMAIL_DELIVERY_DETAILS },
    };

    if (mailDeliveryId)
      await dispatch(updateEmailDelivery(data, resolveFilter));
    else await dispatch(createEmailDelivery(data, resolveFilter));
  };

  const onChangeInput = (value: any, key: string) => {
    const newData = {
      ...mailDeliveryData,
      [key]: value,
    };
    const currentRequired = _.get(required, key);
    if (currentRequired) setRequired({ ...required, [key]: false });
    if (key === "accountId") {
      const findAccount = _.find(
        accounts.items,
        (account) => account.id === value
      );
      const clientId = _.get(findAccount, "clientId.id");
      if (clientId) _.assign(newData, { clientId });
    }
    setMailDeliveryData(newData);
  };

  const generateOptions = () => {
    const getFilters = _.get(filters, "items");
    const getFiltersByAccount = _.filter(getFilters, (filter) => {
      const filterAccountId = _.get(filter, "accountId.id");
      if (filterAccountId === accountId) return filter;
    });
    const resolveOptions = generateDropdown({
      data: getFiltersByAccount,
      key: "filterName",
      value: "id",
    });
    return resolveOptions;
  };

  const onCheckedEmail = (value: string) => {
    const excludeEmails = _.get(mailDeliveryData, "excludeEmails");
    const newExcludeEmails = [...excludeEmails];
    if (_.includes(excludeEmails, value)) {
      const resolveExcludeEmail = _.filter(
        newExcludeEmails,
        (email) => email !== value
      );
      setMailDeliveryData({
        ...mailDeliveryData,
        excludeEmails: resolveExcludeEmail,
      });
    } else
      setMailDeliveryData({
        ...mailDeliveryData,
        excludeEmails: [...excludeEmails, value],
      });
  };

  const onCloseTemplate = async () => {
    await setIsShowCreateTemplate(false);
    await dispatch(getEmailTemplateByType(CONSTANTS.EMAIL_TYPE.EMAIL_SCHEDULE));
  };

  const renderMain = () => {
    return (
      <EmailTemplateContent>
        {!_.has(emailTemplate, "id") && (
          <PreLayer>
            <p>{t("description.emptyDeliveryEmailTemplate")}</p>
            <Button
              buttonWidth="150px"
              onClick={() => setIsShowCreateTemplate(true)}
            >
              {t("button.createTemplate")}
            </Button>
          </PreLayer>
        )}
        {isShowCreateTemplate && (
          <EmailTemplateDetails onClose={() => onCloseTemplate()} />
        )}
        {(mailDeliveryLoading || mailTemplateIsLoading) && (
          <LoadingLayer>
            <Spinner />
          </LoadingLayer>
        )}
        <TemplateDetailsForm>
          <div className="content">
            <FormInline>
              <FormGroup>
                <FormLabel aria-label="require">
                  {t("label.scheduleName")}
                </FormLabel>
                <Input
                  value={_.get(mailDeliveryData, "scheduleName")}
                  onChangeHandler={(e) =>
                    onChangeInput(e.target.value, "scheduleName")
                  }
                  placeholder={t(
                    `placeholder.${
                      mailDeliveryLoading ? "loading" : "emailDeliveryName"
                    }`
                  )}
                  {...InputStyleProps}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel aria-label="require">
                  {t("label.selectPackage")}
                </FormLabel>
                <Dropdown
                  value={_.get(mailDeliveryData, "accountId")}
                  callbackPayload={(e) => {
                    setAccountId(e);
                    onChangeInput(e, "accountId");
                  }}
                  options={processDataDropDownAccount(accounts.items)}
                  placeholder={t(
                    `placeholder.${mailDeliveryLoading ? "loading" : "package"}`
                  )}
                  loading={accountIsLoading || usersIsLoading}
                  {...InputStyleProps}
                />
              </FormGroup>
            </FormInline>
            <FormGroup>
              <FormLabel>{t("label.emailOrEmails")}</FormLabel>
              <GridLayout column={3} gridGap={20}>
                {accountId &&
                  _.map(_.get(users, "items"), (user) => {
                    const excludeEmails = _.get(
                      mailDeliveryData,
                      "excludeEmails"
                    );
                    const email = _.get(user, "email");
                    const userId = _.get(user, "id");
                    return (
                      <Checkbox
                        checked={!_.includes(excludeEmails, userId)}
                        key={`email-${email}`}
                        context={email}
                        onChangeHandler={() => onCheckedEmail(userId)}
                      />
                    );
                  })}
              </GridLayout>
              <MailTagList
                required={_.get(required, "emailReceive")}
                mails={_.get(mailDeliveryData, "emailReceive")}
                onChange={(newMails: any[]) =>
                  onChangeInput(newMails, "emailReceive")
                }
              />
            </FormGroup>
            <FormInline>
              <FormGroup>
                <FormLabel aria-label="require">
                  {t("label.selectFilter")}
                </FormLabel>
                <Dropdown
                  value={_.get(mailDeliveryData, "filters")}
                  callbackPayload={(e) => onChangeInput(e, "filters")}
                  options={generateOptions()}
                  placeholder={t(
                    `placeholder.${mailDeliveryLoading ? "loading" : "filters"}`
                  )}
                  multiple={true}
                  loading={filtersIsLoading}
                  disabled={!_.get(mailDeliveryData, "accountId")}
                  {...InputStyleProps}
                  isUp
                />
              </FormGroup>
            </FormInline>
            <FormInline>
              <FormGroup>
                <FormLabel aria-label="require">
                  {t("label.selectDay")}
                </FormLabel>
                <Dropdown
                  value={_.get(mailDeliveryData, "dateDelivery")}
                  callbackPayload={(e) =>
                    onChangeInput(_.sortBy(e), "dateDelivery")
                  }
                  options={DAY_OF_WEEK}
                  placeholder={t(
                    `placeholder.${
                      mailDeliveryLoading ? "loading" : "dateDelivery"
                    }`
                  )}
                  isUp
                  multiple={true}
                  {...InputStyleProps}
                />
              </FormGroup>
            </FormInline>
            <FormInline>
              <FormGroup>
                <FormLabel aria-label="require">
                  {t("label.selectTime")}
                </FormLabel>
                <Dropdown
                  value={_.get(mailDeliveryData, "deliveryAt")}
                  callbackPayload={(e) =>
                    onChangeInput(_.sortBy(e), "deliveryAt")
                  }
                  options={TIME_IN_DAY}
                  multiple={true}
                  placeholder={t(
                    `placeholder.${
                      mailDeliveryLoading ? "loading" : "deliveryAt"
                    }`
                  )}
                  isUp
                  loading={mailDeliveryLoading}
                  {...InputStyleProps}
                />
              </FormGroup>
              <FormGroup />
            </FormInline>
          </div>
        </TemplateDetailsForm>
        <ButtonField className="flex-justify-start">
          <Button onClick={() => onSubmit()} {...ButtonStyleProps}>
            {_.get(location, "state.id")
              ? t("button.save")
              : t("button.create")}
          </Button>

          <Button onClick={() => onClear()} {...ButtonStyleProps}>
            {t("button.clearAll")}
          </Button>
          <Button
            onClick={() => history.push(RouteTypes.ADMIN_ROUTES.EMAIL_DELIVERY)}
            {...ButtonStyleProps}
          >
            {t("button.back")}
          </Button>
        </ButtonField>
      </EmailTemplateContent>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      title={t("titlePage.manageEmailDeliveries")}
      subTitle={
        _.get(location, "state.id")
          ? t("titlePage.editEmailSchedule")
          : t("titlePage.createNewEmailSchedule")
      }
      parent={RouteTypes.ADMIN_ROUTES.MANAGE_DELIVERY}
      currentPage={RouteTypes.ADMIN_ROUTES.EMAIL_DELIVERY}
    />
  );
};

export default EmailDeliveryDetails;
