import styled from "styled-components";
import ColorName from "constants/Variables";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 9;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  margin: 0 auto;
  font-family: Be Vietnam;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  color: ${ColorName.william};

  & .container-login {
    width: 420px;
    background-color: ${ColorName.zircon};
    border: 1px solid ${ColorName.spindle};
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(202, 222, 237, 0.25);
    border-radius: 20px;
    padding: 32px 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & .footer {
      display: flex;
    }
  }
  & .link {
    & i {
      font-size: 16px;
    }
    padding: 5px;
    color: ${ColorName.burntSienna};
    cursor: pointer;
    &.back {
      color: ${ColorName.william};
    }
  }
`;

const Title = styled.div`
  font-family: "Be Vietnam";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 58px;
  color: #e87844;

  margin: 10px 0;

  &.center {
    text-align: center;
  }
`;

export { Wrapper, Title };
