import ActionTypes from "constants/ActionTypes";
import API from "api";
import _ from "lodash";
import { responseAlert } from "libs/alert";
import history from "../history";

const clearEmailDeliveries = () => {
  return {
    type: ActionTypes.CLEAR_MAIL_DELIVERIES,
  };
};

const clearEmailDelivery = () => {
  return {
    type: ActionTypes.CLEAR_MAIL_DELIVERY,
  };
};

const emailDeliveryFail = () => {
  return {
    type: ActionTypes.EMAIL_DELIVERY_FAILURE,
  };
};

const emailDeliveryLoading = () => {
  return {
    type: ActionTypes.EMAIL_DELIVERY_REQUEST,
  };
};

const createEmailDeliverySuccess = () => {
  return {
    type: ActionTypes.CREATE_EMAIL_DELIVERY_SUCCESS,
  };
};

const updateEmailDeliverySuccess = (payload = {}) => {
  return {
    type: ActionTypes.UPDATE_EMAIL_DELIVERY_SUCCESS,
    payload,
  };
};

const fetchEmailDeliverySuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_EMAIL_DELIVERY_SUCCESS,
    payload,
  };
};

const getEmailDeliveryByIdSuccess = (payload = {}) => {
  return {
    type: ActionTypes.GET_EMAIL_DELIVERY_BY_ID_SUCCESS,
    payload,
  };
};

const searchEmailDeliverySuccess = (payload = {}) => {
  return {
    type: ActionTypes.SEARCH_EMAIL_DELIVERY_SUCCESS,
    payload,
  };
};

const deactivateEmailDeliverySuccess = (payload = {}) => {
  return {
    type: ActionTypes.DEACTIVATE_EMAIL_DELIVERY_SUCCESS,
    payload,
  };
};

const createEmailDelivery = (data: any, filter: any) => {
  return async (dispatch: any) => {
    dispatch(emailDeliveryLoading());
    await API.createEmailDelivery(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          await responseAlert({ res, label: "Email schedule" });
          dispatch(createEmailDeliverySuccess());
          history.push(filter);
        } else {
          responseAlert({ res });
          dispatch(emailDeliveryFail());
        }
      })
      .catch((e: any) => {
        dispatch(emailDeliveryFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const updateEmailDelivery = (data: any, filter: any) => {
  return async (dispatch: any) => {
    dispatch(emailDeliveryLoading());
    await API.updateEmailDelivery(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          await responseAlert({ res, label: "Email schedule updated" });
          dispatch(updateEmailDeliverySuccess(res.payload));
          history.push(filter);
        } else {
          responseAlert({ res });
          dispatch(emailDeliveryFail());
        }
      })
      .catch((e: any) => {
        dispatch(emailDeliveryFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const searchEmailDelivery = (data: any) => {
  return async (dispatch: any) => {
    dispatch(emailDeliveryLoading());
    await API.searchEmailDelivery(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(searchEmailDeliverySuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(emailDeliveryFail());
        }
      })
      .catch((e: any) => {
        dispatch(emailDeliveryFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchEmailDelivery = () => {
  return async (dispatch: any) => {
    dispatch(emailDeliveryLoading());
    await API.fetchEmailDelivery()
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchEmailDeliverySuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(emailDeliveryFail());
        }
      })
      .catch((e: any) => {
        dispatch(emailDeliveryFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getEmailDeliveryById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(emailDeliveryLoading());
    await API.getEmailDeliveryByID(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(getEmailDeliveryByIdSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(emailDeliveryFail());
        }
      })
      .catch((e: any) => {
        dispatch(emailDeliveryFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const deactivateEmailDelivery = (id: string) => {
  return async (dispatch: any) => {
    dispatch(emailDeliveryLoading());
    await API.deactivateEmailDelivery(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Email schedule deactivated" });
          dispatch(deactivateEmailDeliverySuccess());
        } else {
          responseAlert({ res });
          dispatch(emailDeliveryFail());
        }
      })
      .catch((e: any) => {
        dispatch(emailDeliveryFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

export {
  createEmailDelivery,
  deactivateEmailDelivery,
  fetchEmailDelivery,
  searchEmailDelivery,
  updateEmailDelivery,
  getEmailDeliveryById,
  clearEmailDelivery,
  clearEmailDeliveries,
};
