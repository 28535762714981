import ActionTypes from "constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  sources: [],
  source: {},
  errors: {},
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.CLEAR_FILE_RECORDS:
      return {
        ...state,
        sources: [],
      };
    case ActionTypes.FILE_RECORD_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.FILE_RECORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CREATE_FILE_RECORD_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_FILE_RECORD_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        sources: payload,
      };
    case ActionTypes.GET_FILE_RECORD_BY_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        source: payload,
      };
    case ActionTypes.UPDATE_FILE_RECORD_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        source: payload,
      };
    default:
      return state;
  }
};
