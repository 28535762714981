import Api from "./Base";
import { API } from "constants/Constants";

const { EMAIL_DELIVERY } = API;

export function fetchEmailDelivery() {
  return Api.get(EMAIL_DELIVERY.BASIC);
}

export function getEmailDeliveryByID(id: string) {
  return Api.get(`${EMAIL_DELIVERY.BASIC}/${id}`);
}

export function searchEmailDelivery(data: any) {
  return Api.get(EMAIL_DELIVERY.BASIC, data);
}

export function createEmailDelivery(data: any) {
  return Api.post(EMAIL_DELIVERY.BASIC, data);
}

export function updateEmailDelivery(data: any) {
  const { id } = data;
  return Api.put(`${EMAIL_DELIVERY.UPDATE}/${id}`, data);
}

export function deactivateEmailDelivery(id: string) {
  return Api.put(`${EMAIL_DELIVERY.DEACTIVATE}/${id}`);
}
