/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { RootState } from "reducers";
import { AuthenticationActions, ClientActions } from "actions";

import { Images } from "themes";

import { Title } from "./Authentication.styles";
import {
  AuthenticationForm,
  AuthenticationSwitch,
  AuthenticationContent,
  PopupWrapper,
  LoadingAuthenForm,
  Spinner,
} from "./UserAuthentication.styles";
import { Input, Button } from "components/Common";
import history from "../../history";
import RouteTypes from "../../constants/RouteTypes";

const { login, setDefaultLoginResult } = AuthenticationActions;
const { fetchAllClients } = ClientActions;

interface SectionProps {
  onClose?(): void;
}

const UserAuthentication = (props: SectionProps) => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => {
    return state.Authentication.isLoading;
  });
  const isLogged = useSelector((state: RootState) => {
    return state.Authentication.isLogged;
  });
  const loginResult = useSelector((state: RootState) =>
    _.get(state, "Authentication.loginResult")
  );
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    dispatch(fetchAllClients());
  }, []);

  useEffect(() => {
    if ((loginResult === 2 || isLogged) && onClose) {
      onClose();
      dispatch(setDefaultLoginResult());
    }
  }, [loginResult]);

  const onLogin = async () => {
    await dispatch(login({ account: email, password }));
  };

  const onClosePopup = (e: any) => {
    const classList = _.get(e, "target.classList");
    if (classList.contains("blur_layer") && onClose) onClose();
  };

  const loginForm = () => {
    return (
      <AuthenticationForm>
        <div className="header-login">
          <img src={Images.logo} alt="Media Monitoring system" />
        </div>
        <Title className="center">Sign in</Title>
        <Input
          margin="5px 0"
          onChangeHandler={(e) => setEmail(e.target.value)}
          onEnter={() => onLogin()}
          placeholder="Enter your email"
          width="100%"
        />
        <Input
          type="password"
          margin="5px 0"
          onChangeHandler={(e) => setPassword(e.target.value)}
          onEnter={() => onLogin()}
          placeholder="Password"
          width="100%"
        />
        <div className="footer">
          <Button
            onClick={() => onLogin()}
            className="form-button"
            buttonMargin="10px 0"
          >
            Login
          </Button>
        </div>
        <AuthenticationSwitch>
          No account?{" "}
          <span
            onClick={() =>
              history.push(RouteTypes.AUTHENTICATION_ROUTES.USER_REGISTER)
            }
          >
            Create one
          </span>
        </AuthenticationSwitch>
      </AuthenticationForm>
    );
  };

  const renderMain = () => {
    return (
      <AuthenticationContent width="350px">
        {loginForm()}

        {isLoading && (
          <LoadingAuthenForm>
            <Spinner />
          </LoadingAuthenForm>
        )}
      </AuthenticationContent>
    );
  };

  return (
    <PopupWrapper className="blur_layer" onClick={(e: any) => onClosePopup(e)}>
      {renderMain()}
    </PopupWrapper>
  );
};

export default UserAuthentication;
