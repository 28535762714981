import React from "react";

import { Button } from "components/Common";
import {
  TemplateDetailsContainer,
  TemplateDetailsHeader,
  TemplateDetailsContent,
  ButtonField,
  TemplateDetailsForm,
  CloseButton,
  ContentInformation,
  TitleContent,
  DescriptionContent,
  Tag,
} from "../Popup.styles";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import _ from "lodash";
import RenderPDF from "../../RenderPDF";
import parse from "html-react-parser";

interface SectionProps {
  onClose(): void;
  data: any;
}

const NewsDetails = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { onClose, data } = props;

  const renderContent = () => {
    const listKeywords = _.get(data, "listKeywords");
    const content = _.get(data, "content");
    const mediaType = _.get(data, "source.mediaType");
    if (mediaType !== "tv") {
      if (_.isEmpty(listKeywords)) return content;
      let resolveContent = content;
      _.map(listKeywords, (keyword) => {
        const splitKeywords = keyword.trim().split("");
        let resolveKeyword = "";
        _.forEach(splitKeywords, (item) => {
          if (item.trim())
            resolveKeyword += `(${item.toLowerCase()}|${item.toUpperCase()})`;
          else resolveKeyword += " ";
        });
        const newContent = _.replace(
          resolveContent,
          new RegExp(resolveKeyword, "g"),
          `<mark>${keyword}</mark>`
        );
        resolveContent = newContent;
      });
      return parse(resolveContent);
    }
    const splitContent = _.split(content, ",");
    const renderTags = _.map(splitContent, (item, index) => {
      return <Tag key={`tag-${index}`}>#{item}</Tag>;
    });
    return renderTags;
  };

  const _renderDownloadVideoBtn = () => {
    const mediaType = _.get(data, "source.mediaType");
    if (mediaType === "tv") {
      let originalLink = _.get(data, "originalLink");
      let isDownload = true;
      const isGoogleDriveLink = _.includes(originalLink, "drive.google");
      if (isGoogleDriveLink) {
        const replacePrefix = `${originalLink.replace(
          "file/d/",
          "u/0/uc?id="
        )}`;
        originalLink = `${replacePrefix.replace("/preview", "")}`;
      } else isDownload = false;
      return (
        <a
          href={originalLink}
          target="__blank"
          className={`action-btn`}
          download={isDownload}
        >
          <i className="fas fa-download" />
        </a>
      );
    }
    return null;
  };

  const _renderVideo = () => {
    const mediaType = _.get(data, "source.mediaType");
    if (mediaType === "tv") {
      let originalLink = _.get(data, "originalLink");
      if (!originalLink) return null;
      const isYoutube = _.includes(originalLink, "youtube");
      if (isYoutube) originalLink = originalLink.replace("watch?v=", "embed/");

      return (
        <iframe
          width="100%"
          height="480"
          src={originalLink}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      );
    }
    return null;
  };
  const renderMain = () => {
    return (
      <TemplateDetailsContainer>
        <TemplateDetailsContent width="720px">
          <TemplateDetailsHeader>
            {t("headerPopup.newsDetails")}
            <CloseButton onClick={() => onClose && onClose()}>
              <i className="fas fa-times" />
            </CloseButton>
          </TemplateDetailsHeader>
          <TemplateDetailsForm height="500px">
            <ContentInformation>
              <div>
                <div>
                  {t("label.author")}: <b>{_.get(data, "author")}</b>
                </div>
                <div>
                  {t("label.publishedDate")}:{" "}
                  <b>
                    {dayjs(_.get(data, "publishedAt")).format("DD-MM-YYYY")}
                  </b>
                </div>
                <div>
                  {t("label.source")}:{" "}
                  <a href={_.get(data, "originalLink")} target="_blank">
                    {_.get(data, "source.name")}
                  </a>
                </div>
              </div>
              <div>
                <span>
                  {t("label.mediaType")}:{" "}
                  <b>{_.capitalize(_.get(data, "source.mediaType"))}</b>
                </span>
                <div>
                  {_.get(data, "source.mediaType") !== "tv" && (
                    <RenderPDF isDownload data={data} />
                  )}
                  {_renderDownloadVideoBtn()}
                </div>
              </div>
            </ContentInformation>
            <TitleContent>{_.get(data, "title")}</TitleContent>
            <DescriptionContent>
              {renderContent()}
              {/* <div>
                <a href={_.get(data, "originalLink")} target="_blank">
                  From: {_.get(data, "originalLink")}
                </a>
              </div> */}
            </DescriptionContent>
            {_renderVideo()}
          </TemplateDetailsForm>
          <ButtonField>
            <Button
              onClick={() => onClose()}
              type="submit"
              buttonWidth="100px"
              buttonMargin="0 0 0 10px"
            >
              {t("button.close")}
            </Button>
          </ButtonField>
        </TemplateDetailsContent>
      </TemplateDetailsContainer>
    );
  };
  return renderMain();
};

export default NewsDetails;
