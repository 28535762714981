import React, { useEffect, useState } from "react";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  BlobProvider,
  Font,
  Link,
  Image,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { popupAlert } from "../../../libs/Utils";
import PreviewPDF from "./PreviewPDF";
import OpenInNewSharpIcon from "@mui/icons-material/OpenInNewSharp";
import { Box } from "@mui/system";
import { Images } from "themes";
import { Stack } from "@mui/material";
const BeVietnamBold = require("assets/fonts/BeVietnam-Bold.ttf");
const BeVietnamRegular = require("assets/fonts/BeVietnam-Regular.ttf");

Font.register({
  family: "Be Vietnam",
  fonts: [
    {
      src: BeVietnamRegular,
    },
    {
      src: BeVietnamBold,
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  page: {
    backgroundColor: "#E4E4E4",
    fontFamily: "Be Vietnam",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 21,
    lineHeight: 1.2,
    textAlign: "left",
    marginTop: 10,
    marginBottom: 10,
    fontWeight: "bold",
    fontFamily: "Be Vietnam",
  },
  text: {
    fontSize: 13,
    lineHeight: 1.5,
    textAlign: "left",
    fontFamily: "Be Vietnam",
  },
  line: {
    width: "100%",
    borderBottom: "1px solid black",
  },
  sourceAndAuthor: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Be Vietnam",
  },
  dataInfo: {
    width: "34%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-around",
    margin: "10px 0",
    border: "1px solid black",
    padding: "20px 5px",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  footer: {
    position: "absolute",
    fontSize: 9,
    bottom: 40,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    paddingHorizontal: "10px",
  },
  headerTop: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    maxHeight: "100px",
  },
  link: {
    textDecoration: "none",
    color: "black",
    fontWeight: "bold",
  },
  dataInfoTextView: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    margin: "10px 0",
    padding: "15px 10px",
    backgroundColor: "#f5f5f5",
  },
  bodyText: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    backgroundColor: "#e6e6e6",
  },
  image: {
    width: 200,
    height: 200,
    marginBottom: 10,
  },
  table: {
    width: "100%",
    border: "1px solid black",
    display: "flex",
    height: "100px",
    flexDirection: "row",
    marginBottom: 10,
  },
  sourceRow: {
    borderRight: "1px solid black",
    flex: 1,
    padding: "10px",
    display: "flex",
    flexDirection: "column",
  },
  detailRow: {
    borderRight: "1px solid black",
    flex: 1,
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
});

interface SectionProps {
  data?: any;
  isDownload?: boolean;
  multipleRow?: boolean;
  buttonDownload?: JSX.Element;
  id?: string;
  clickClose: () => void;
}

const ButtonStyleProps = {
  // buttonWidth: "100px",
  buttonMargin: "10px 10px 10px 0",
};

const RenderPreviewPDF = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { data, clickClose } = props;
  const [isPDFView, setIsPDFView] = useState<boolean>(true);
  const MyDoc = () => {
    return (
      <Document title={get(data, "title")}>
        <Page wrap style={styles.body}>
          <View style={styles.table}>
            <View style={styles.sourceRow}>
              <Link
                style={{ ...styles.link, fontSize: 15 }}
                src={get(data, "originalLink")}
              >
                <Text>{get(data, "source.name")}</Text>
              </Link>
              <Text
                style={{
                  ...styles.sourceAndAuthor,
                  fontWeight: "normal",
                  marginTop: "5px",
                }}
              >
                Publish Date:{" "}
                {dayjs(get(data, "publishedAt")).format("DD/MM/YYYY")}
              </Text>
            </View>
            <View style={{ flex: 1, borderRight: "1px solid black" }} />
            <View style={{ ...styles.detailRow, padding: 0 }}>
              <Text
                style={{
                  borderBottom: "1px solid black",
                  fontSize: 10,
                  padding: 5,
                  flex: 1,
                }}
              >
                Media Type: {capitalize(get(data, "source.mediaType"))}
              </Text>
              <Text
                style={{
                  borderBottom: "1px solid black",
                  fontSize: 10,
                  padding: 5,
                  flex: 1,
                }}
              >
                Category: {get(data, `category.name.${data?.language || "en"}`)}
              </Text>
              <Text style={{ fontSize: 10, padding: 5, flex: 1 }}>
                Author: {get(data, "author")}
              </Text>
            </View>
          </View>
          <Text style={styles.line}></Text>
          <View>
            <Text style={styles.title}>{get(data, "title")}</Text>
          </View>
          {get(data, "source.mediaType") === "print" &&
            get(data, "file.imagePath") && (
              <Image src={get(data, "file.imagePath")} cache={true} />
            )}
          {((get(data, "source.mediaType") === "print" &&
            !get(data, "file.imagePath")) ||
            get(data, "source.mediaType") !== "print") && (
            <Text style={styles.text}>{get(data, "content")}</Text>
          )}
          <Text style={styles.footer} fixed>
            Informacioni i mësiperm mund të jetë i mbrojtur nga të drejtat e
            autorit. Ju nuk mund të kopjoni, riprodhoni, regjistroni,
            transmetoni, shisni,publikoni, shpërndani, ta ndani me të tjere apo
            ta ruani këtë informacion në cdo forme apo me cdo mjet pa leje të
            shkruar nga publikuesit e tij.
          </Text>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
      </Document>
    );
  };

  const _renderText = () => {
    return (
      <Box style={styles.bodyText}>
        <Box style={styles.dataInfoTextView}>
          <Text style={styles.sourceAndAuthor}>
            Publication Name: {get(data, "source.name")}
          </Text>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Text style={styles.sourceAndAuthor}>
            Publication Date:{" "}
            {dayjs(get(data, "publishedAt")).format("YYYY, MMM D")}
          </Text>
        </Box>
        <Text style={styles.text}>{get(data, "content")}</Text>
        {get(data, "source.mediaType") === "print" &&
          get(data, "file.imagePath") && (
            <img src={get(data, "file.imagePath")} />
          )}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Box>
    );
  };

  const _renderHeader = (url: any) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#363b65",
          width: "100%",
          p: "10px",
        }}
      >
        <Stack
          sx={{
            position: "relative",
            color: "white",
            fontWeight: "bold",
            fontSize: "21px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {get(data, "title")}
          <a
            style={{
              cursor: "pointer",
              color: "white",
              height: "40px",
              margin: "0 0 0 5px",
            }}
            onClick={(e: any) => {
              if (_.isEmpty(data))
                return popupAlert({
                  type: "warning",
                  title: t("alert.warning"),
                  text: t("alert.atLeastOnePost"),
                });
              else if (url) {
                const previewWindow = window.open(url, "_blank");
                if (previewWindow) {
                  previewWindow.onload = () => {
                    const downloadLink =
                      previewWindow.document.createElement("a");
                    downloadLink.href = url;
                    downloadLink.download = get(data, "title");
                    downloadLink.style.width = "30px";
                    downloadLink.style.height = "30px";
                    downloadLink.style.position = "absolute";
                    downloadLink.style.color = "white";
                    downloadLink.style.right = "94px";
                    downloadLink.style.top = "8px";
                    downloadLink.style.display = "flex";
                    downloadLink.style.padding = "5px";
                    downloadLink.style.borderRadius = "50%";
                    downloadLink.addEventListener("mouseover", function () {
                      this.style.backgroundColor = "rgba(255,255,255,0.1)";
                    });
                    downloadLink.addEventListener("mouseout", function () {
                      this.style.backgroundColor = "transparent";
                    });
                    previewWindow.document.body.appendChild(downloadLink);
                  };
                }
              }
            }}
          >
            <OpenInNewSharpIcon />
          </a>
        </Stack>
        <Stack sx={{ color: "#3e9fcf", fontWeight: "bold", fontSize: "16px" }}>
          {get(data, "source.name")}
        </Stack>
      </Box>
    );
  };

  const changeView = () => {
    setIsPDFView(!isPDFView);
  };

  return (
    <BlobProvider document={<MyDoc />}>
      {({ blob, url, loading, error }) => {
        let file;
        if (blob)
          file = new File([blob], "PDF.pdf", { type: "application/pdf" });
        const newUrl = file ? URL.createObjectURL(file) : url;
        // Do whatever you need with blob here
        return (
          <PreviewPDF
            file={newUrl}
            title={get(data, "title")}
            header={_renderHeader(newUrl)}
            content={_renderText()}
            clickClose={() => clickClose()}
            changeView={() => changeView()}
            isPDFView={isPDFView}
          />
        );
      }}
    </BlobProvider>
  );
};

export default RenderPreviewPDF;
