import ActionTypes from "../constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  fetchAllMessageIsLoading: false,
  allMessages: [],
  messageDirectory: [],
  totalNewMessages: 0,
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.FETCH_ALL_MESSAGE_IS_LOADING:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccess: false,
        fetchAllMessageIsLoading: true,
        messageDirectory: [],
      };
    case ActionTypes.SET_DIRECT_MESSAGES:
      return {
        ...state,
        messageDirectory: payload,
      };
    case ActionTypes.SET_ALL_MESSAGES:
      return {
        ...state,
        allMessages: payload,
      };
    case ActionTypes.SET_TOTAL_NEW_MESSAGES:
      return {
        ...state,
        totalNewMessages: payload,
      };

    case ActionTypes.MESSAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        isSuccess: false,
        fetchAllMessageIsLoading: false,
      };
    case ActionTypes.MESSAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        isSuccess: false,
        fetchAllMessageIsLoading: false,
      };

    case ActionTypes.FETCH_MESSAGE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        hasError: false,
        fetchAllMessageIsLoading: false,
        allMessages: payload,
      };

    case ActionTypes.FETCH_DIRECT_MESSAGE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        hasError: false,
        fetchAllMessageIsLoading: false,
        messageDirectory: payload,
      };

    case ActionTypes.REMOVE_MESSAGE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        hasError: false,
        fetchAllMessageIsLoading: false,
      };

    default:
      return state;
  }
};
