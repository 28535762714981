import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import trim from "lodash/trim";
import map from "lodash/map";

import { AuthenticationActions } from "actions";
import { RootState } from "reducers";

import { Images } from "themes";
import { ROUTES } from "constants/Constants";

import history from "../../history";
import { getSavedRememberMe, saveRememberMe, popupAlert } from "libs/Utils";

import { Wrapper, Title } from "./Authentication.styles";
import { Input, Button, Loading, Checkbox } from "components/Common";

const { login } = AuthenticationActions;

const Authentication = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => {
    return state.Authentication.isLoading;
  });
  const isLogged = useSelector((state: RootState) => {
    return state.Authentication.isLogged;
  });
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  useEffect(() => {
    const isRemember = getSavedRememberMe();
    if (isRemember) setRememberMe(isRemember);
  }, []);

  useEffect(() => {
    if (!isLogged) return history.push(ROUTES.AUTHENTICATION);
  }, [isLogged]);

  const checkRequireFields = () => {
    let flag = true;
    let message = `Found empty field(s):\n`;
    const data = [
      { label: "Email", value: email },
      { label: "Password", value: password },
    ];
    map(data, (item) => {
      const { label, value } = item;
      if (!trim(value)) {
        flag = false;
        message += `${label}\n`;
      }
    });
    return { flag, message };
  };

  const onKeyEnterPress = () => {
    const { flag, message } = checkRequireFields();
    if (!flag)
      return popupAlert({
        title: "Warning",
        type: "warning",
        text: message,
      });
    dispatch(login({ account: email, password, rememberMe }));
  };

  const onRememberMe = () => {
    setRememberMe(!rememberMe);
    saveRememberMe(!rememberMe);
  };

  return (
    <Wrapper>
      <div className="container-login">
        <div className="header-login">
          <img src={Images.logo} alt="Media Monitoring system" />
        </div>
        {isLoading && <Loading />}
        <Title>Welcome</Title>
        <Input
          margin="10px 0"
          onChangeHandler={(e: any) => setEmail(e.target.value)}
          placeholder="Enter your email"
          onEnter={() => onKeyEnterPress()}
        />
        <Input
          type="password"
          margin="10px 0"
          onChangeHandler={(e: any) => setPassword(e.target.value)}
          placeholder="Enter your password"
          onEnter={() => onKeyEnterPress()}
        />
        <Button
          onClick={() =>
            dispatch(login({ account: email, password, rememberMe }))
          }
          className="form-button"
          buttonMargin="10px 0"
        >
          Login
        </Button>
        <Checkbox
          context="Remember me!"
          checked={rememberMe}
          onChangeHandler={() => onRememberMe()}
        />
        <span
          className="link"
          onClick={() => history.push(ROUTES.FORGOT_PASSWORD)}
        >
          Forgot Password?
        </span>
      </div>
    </Wrapper>
  );
};

export default Authentication;
