import styled from "styled-components";
import { HorizontalContainer } from "../CommonLayout";
import ColorName from "constants/Variables";
import { Images } from "themes";

const Spinner = styled.div`
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  width: 30px;
  height: 30px;
  border-top: 3px solid ${ColorName.burntSienna};
  border-right: 3px solid transparent;
  border-radius: 50%;
  animation: rotation 0.8s linear infinite;
`;

const ComponentReportCard = styled(HorizontalContainer)`
  display: flex;
  // min-width: 300px;
  // // min-height: 130px;
  // position: relative;
  // // color: ${ColorName.william};
  // // background-image: url(${Images.bgReport});
  // // background-repeat: no-repeat;
  // // align-items: center;
  // // place-content: center;
  // margin-top: 2em;
  margin-right: 10px;
  &:last-child {
    margin-right: 0px;
  }
  // & .number {
  //   position: absolute;
  //   top: -25px;
  //   left: 5px;
  //   width: 175px;
  //   background-color: ${ColorName.zircon};
  //   border: 1px solid ${ColorName.spindle};
  //   border-radius: 30px;
  //   color: ${ColorName.burntSienna};
  //   font-weight: bold;
  //   font-size: 15px;
  //   display: flex;
	// 	align-items: center;
  //   place-content: center;
  //   height: 50px;
  // }
  // // & .text {
  // //   font-size: 16px;
  // //   font-weight: 600;
  // // }
  width: 175px;
  background-color: ${ColorName.zircon};
  border: 1px solid ${ColorName.spindle};
  border-radius: 30px;
  color: ${ColorName.burntSienna};
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;
  place-content: center;
  height: 50px;
`;

export { ComponentReportCard, Spinner };
