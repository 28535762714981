import Api from "./Base";
import { API } from "constants/Constants";

const { MEMBER } = API;

export function createMember(data: any) {
  return Api.postFormData(MEMBER.BASIC, data);
}

export function updateMember(data: any, id: string) {
  return Api.putFormData(`${MEMBER.UPDATE}/${id}`, data);
}

export function getMemberById(id: string) {
  return Api.get(`${MEMBER.GET_MEMBER_BY_ID}/${id}`)
}

export function fetchMembers(data: any) {
  return Api.get(MEMBER.BASIC, data);
}

export function searchMembers(data: any) {
  return Api.get(MEMBER.BASIC, data);
}

export function deactivateMember(id: string) {
  return Api.put(`${MEMBER.DEACTIVATE}/${id}`);
}

export function activateMember(id: string) {
  return Api.put(`${MEMBER.ACTIVATE}/${id}`);
}

export function exportUsers() {
  return Api.getBlob(MEMBER.EXPORT);
}

