/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { RootState } from "reducers";

import {
  Dropdown,
  Button,
  Input,
  FormGroup,
  FormInline,
  FormLabel,
  TitlePartOfForm,
  SelectDatetime,
  Textarea,
} from "components/Common";

import countries from "libs/countries";
import { AccountActions } from "actions";
import {
  TemplateDetailsContent,
  TemplateDetailsContainer,
  TemplateDetailsForm,
  ButtonField,
  TemplateDetailsHeader,
  LoadingLayer,
  Spinner,
} from "../Popup.styles";
import { useTranslation } from "react-i18next";
import { popupAlert } from "libs/Utils";

const { createAccount } = AccountActions;

interface SectionProps {
  onClose: (isFetch?: boolean) => void;
  clientId: string;
}

const InputStyleProps = {
  width: "300px",
};

const PackageDetails: React.FC<SectionProps> = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { onClose, clientId } = props;
  const dispatch = useDispatch();
  const packageIsLoading = useSelector((state: RootState) =>
    _.get(state, "Account.isLoading")
  );
  const packageIsSuccess = useSelector((state: RootState) =>
    _.get(state, "Account.isSuccess")
  );

  const [accountData, setAccountData] = useState({
    accountName: "",
    accountCode: "",
    startDate: "",
    endDate: "",
    description: "",
  });
  const [userData, setUserData] = useState({
    address: "",
    city: "",
    country: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    region: "",
  });

  useEffect(() => {
    if (packageIsSuccess) onClose(true);
  }, [packageIsSuccess]);

  const processData = () => {
    const formData = new FormData();

    formData.append("accountName", _.trim(accountData.accountName));
    formData.append("accountCode", _.trim(accountData.accountCode));
    formData.append("clientId", _.trim(clientId));
    formData.append("startDate", _.trim(accountData.startDate));
    formData.append("endDate", _.trim(accountData.endDate));
    if (accountData.description)
      formData.append("description", _.trim(accountData.description));
    formData.append("userData[address]", _.trim(userData.address));
    formData.append("userData[city]", _.trim(userData.city));
    formData.append("userData[phoneNumber]", _.trim(userData.phoneNumber));
    formData.append("userData[country]", _.trim(userData.country));
    if (userData.zipCode)
      formData.append("userData[zipCode]", _.trim(userData.zipCode));
    return formData;
  };

  const validateData = () => {
    let flag = true;
    let message = `${t("alert.foundEmptyFields")}`;
    const data = [
      {
        label: t("label.packageName"),
        value: _.get(accountData, "accountName"),
      },
      {
        label: t("label.packageCode"),
        value: _.get(accountData, "accountCode"),
      },
      {
        label: t("label.startDate"),
        value: _.get(accountData, "startDate"),
      },
      {
        label: t("label.endDate"),
        value: _.get(accountData, "endDate"),
      },
      {
        label: t("label.streetAddress"),
        value: _.get(userData, "address"),
      },
      {
        label: t("label.city"),
        value: _.get(userData, "city"),
      },
      {
        label: t("label.country"),
        value: _.get(userData, "country"),
      },
      {
        label: t("label.contactNumber"),
        value: _.get(userData, "phoneNumber"),
      },
      {
        label: t("label.description"),
        value: _.get(accountData, "description"),
      },
    ];
    _.map(data, (item) => {
      const { value, label } = item;
      if (_.isEmpty(_.trim(value))) {
        message += `${label}\n`;
        flag = false;
      }
    });
    return { flag, message };
  };

  const onSubmit = async () => {
    const { flag, message } = validateData();

    if (!flag) {
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });
    }

    const data = processData();

    dispatch(createAccount(data));
  };

  return (
    <TemplateDetailsContainer>
      <TemplateDetailsContent>
        {packageIsLoading && (
          <LoadingLayer>
            <Spinner />
          </LoadingLayer>
        )}
        <TemplateDetailsHeader>Create New Package</TemplateDetailsHeader>
        <TemplateDetailsForm height="500px">
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.packageName")}
              </FormLabel>
              <Input
                value={accountData.accountName || ""}
                onChangeHandler={(e) =>
                  setAccountData({
                    ...accountData,
                    accountName: e.target.value,
                  })
                }
                placeholder={t("placeholder.packageName")}
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.packageCode")}
              </FormLabel>
              <Input
                value={accountData.accountCode || ""}
                onChangeHandler={(e) =>
                  setAccountData({
                    ...accountData,
                    accountCode: e.target.value,
                  })
                }
                placeholder={t("placeholder.packageCode")}
                {...InputStyleProps}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.startDate")}</FormLabel>
              <SelectDatetime
                callbackPayload={(e) =>
                  setAccountData({ ...accountData, startDate: e })
                }
                maxDate={accountData?.endDate}
                placeholder={t("placeholder.startDate")}
                defaultValue={accountData.startDate}
                disabledPreviousDate
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.endDate")}</FormLabel>
              <SelectDatetime
                callbackPayload={(e) =>
                  setAccountData({ ...accountData, endDate: e })
                }
                leftPosition={false}
                minDate={accountData?.startDate}
                placeholder={t("placeholder.endDate")}
                defaultValue={accountData.endDate}
                disabledPreviousDate
                {...InputStyleProps}
              />
            </FormGroup>
          </FormInline>
          <TitlePartOfForm>{t("label.contact")}</TitlePartOfForm>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.streetAddress")}
              </FormLabel>
              <Input
                value={userData.address || ""}
                onChangeHandler={(e) =>
                  setUserData({ ...userData, address: e.target.value })
                }
                placeholder={t("placeholder.streetAddress")}
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup />
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.city")}</FormLabel>
              <Input
                value={userData.city || ""}
                onChangeHandler={(e) =>
                  setUserData({ ...userData, city: e.target.value })
                }
                placeholder={t("placeholder.city")}
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.country")}</FormLabel>
              <Dropdown
                value={userData.country || ""}
                callbackPayload={(value) =>
                  setUserData({ ...userData, country: value })
                }
                options={countries}
                placeholder={t("placeholder.country")}
                search={true}
                {...InputStyleProps}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.postalOrZipCode")}</FormLabel>
              <Input
                value={userData.zipCode || ""}
                onChangeHandler={(e) =>
                  setUserData({ ...userData, zipCode: e.target.value })
                }
                placeholder={t("placeholder.postalOrZipCode")}
                isNum
                {...InputStyleProps}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.contactNumber")}
              </FormLabel>
              <Input
                value={userData.phoneNumber || ""}
                onChangeHandler={(e) =>
                  setUserData({ ...userData, phoneNumber: e.target.value })
                }
                placeholder={t("placeholder.contactNumber")}
                isNum
                {...InputStyleProps}
              />
            </FormGroup>
          </FormInline>
          <FormGroup>
            <FormLabel aria-label="require">{t("label.description")}</FormLabel>
            <Textarea
              value={_.get(accountData, "description")}
              onChangeHandler={(e: any) =>
                setAccountData({
                  ...accountData,
                  description: e.target.value,
                })
              }
              placeholder={t("placeholder.packageDescription")}
              rows={5}
            />
          </FormGroup>
        </TemplateDetailsForm>
        <ButtonField>
          <Button
            type="submit"
            onClick={() => onSubmit()}
            buttonMargin="0 10px 0 0"
          >
            {t("button.create")}
          </Button>
          <Button type="submit" onClick={() => onClose()} buttonWidth="100px">
            {t("button.cancel")}
          </Button>
        </ButtonField>
      </TemplateDetailsContent>
    </TemplateDetailsContainer>
  );
};

export default PackageDetails;
