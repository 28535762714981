import ActionTypes from "constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  emailTemplates: [],
  emailTemplate: {},
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.CLEAR_EMAIL_TEMPLATES:
      return {
        ...state,
        emailTemplates: [],
      };
    case ActionTypes.CLEAR_EMAIL_TEMPLATE_DETAILS:
      return {
        ...state,
        emailTemplate: {},
      };
    case ActionTypes.EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.GET_EMAIL_TEMPLATE_BY_TYPE_SUCCESS:
      return {
        isLoading: false,
        isSuccess: true,
        emailTemplate: payload,
      };
    case ActionTypes.EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CREATE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.UPDATE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        emailTemplates: payload,
      };
    case ActionTypes.SEARCH_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        emailTemplates: payload,
      };
    case ActionTypes.GET_EMAIL_TEMPLATE_BY_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        emailTemplate: payload,
      };
    case ActionTypes.ACTIVATE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.DEACTIVATE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
