import React, { useMemo, useRef } from "react";
import _ from "lodash";
import "chart.js/auto";
import "chartjs-adapter-moment";
import { ChartOptions, ChartData } from "chart.js";
import { Bar, getElementAtEvent } from "react-chartjs-2";

interface ISectionProps {
  data: ChartData<"bar">;
  title: string;
  verticalText?: string;
  horizontalText?: string;
  subtitle?: boolean;
  isShowLegend?: boolean;
  axis?: "x" | "y";
  minYear?: string;
  isFormatDate?: boolean;
  onClick?(label: any, dataset: any): void;
}

const BarChart: React.FC<ISectionProps> = (props: ISectionProps) => {
  // Constructors
  const chartRef: any = useRef();
  const {
    data,
    title,
    verticalText,
    horizontalText,
    subtitle = true,
    isShowLegend = true,
    axis = "x",
    minYear,
    isFormatDate,
    onClick,
  } = props;

  const options: ChartOptions<"bar"> = useMemo(() => {
    let xScales: any = {
      display: true,
      title: {
        display: Boolean(horizontalText),
        text: horizontalText,
      },
    };
    if (isFormatDate)
      xScales = {
        ...xScales,
        type: "time",
        time: {
          unit: "year",
          offsetAfterAutoskip: true,
          tooltipFormat: "MM/YYYY",
        },
        min: minYear,
      };
    return {
      responsive: true,
      interaction: {
        intersect: false,
      },
      indexAxis: axis,
      plugins: {
        filler: {
          propagate: false,
        },
        title: {
          display: true,
          text: title,
          align: "start",
          fullSize: true,
          font: {
            size: 19,
          },
        },
        subtitle: {
          display: subtitle,
          align: "end",
          position: "bottom",
          text: "(*) Click on legend to show/hide element(s)",
        },
        legend: {
          display: isShowLegend,
          position: "top",
          labels: {
            usePointStyle: true,
          },
        },
      },
      scales: {
        x: xScales,
        y: {
          display: true,
          title: {
            display: Boolean(verticalText),
            text: verticalText,
          },
        },
      },
    };
  }, [props]);

  const handleClick = (
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>
  ) => {
    const singleDataSet = getElementAtEvent(chartRef?.current, event);
    if (!_.isEmpty(singleDataSet)) {
      const index = singleDataSet[0].index;
      if (onClick) onClick(data?.labels?.[index], data.datasets[index]);
    }
  };

  return (
    <Bar
      ref={chartRef}
      onClick={handleClick}
      options={{ ...options }}
      data={data}
    />
  );
};

export default BarChart;

// cubicInterpolationMode: 'monotone',
// tension: 0.4,
// use for smooth line
