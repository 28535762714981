/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import RouteTypes from "constants/RouteTypes";

import { RootState } from "reducers";
import { CategoriesActions } from "actions";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Input,
  Button,
  Dropdown,
  FormDetails,
  FormGroup,
  FormInline,
  FormLabel,
  ButtonField,
} from "components/Common";
import history from "../../../history";
import { useTranslation } from "react-i18next";
import { popupAlert } from "libs/Utils";
import { useLocation } from "react-router-dom";

const {
  createCategory,
  updateCategory,
  clearCategoryDetails,
  getCategoryByID,
} = CategoriesActions;

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "10px 10px 10px 0",
};

const Categories = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state: RootState) => {
    return state.Categories.isLoading;
  });
  const category = useSelector((state: RootState) => {
    return state.Categories.category;
  });
  const [categoryName, setCategoryName] = useState({
    en: "",
    al: "",
  });
  const [categoryCode, setCategoryCode] = useState("");
  const [status, setStatus] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const optionStatus = [
    { label: "Active", value: "active" },
    { label: "Block", value: "inactive" },
  ];
  const [require, setRequire] = useState({
    en: false,
    al: false,
    code: false,
  });

  useEffect(() => {
    const id = _.get(location, "state.id");
    if (id) dispatch(getCategoryByID(id));

    return () => {
      dispatch(clearCategoryDetails());
      history.replace({ ...history.location, state: {} });
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(category)) {
      if (category.name && category.status && category.id) {
        setCategoryID(category.id);
        setCategoryName(category.name);
        if (category.code) setCategoryCode(category.code);
        setStatus(category.status);
      }
    }
  }, [category]);

  const checkValidData = () => {
    const data = [
      {
        label: t("label.englishName"),
        value: _.get(categoryName, "en"),
      },
      {
        label: t("label.shqipName"),
        value: _.get(categoryName, "al"),
      },
      {
        label: t("label.code"),
        value: categoryCode,
      },
    ];
    let isValid = true;
    let message = "Found empty field(s):\n";
    _.map(data, (item) => {
      const { value, label } = item;
      if (_.isEmpty(_.trim(value))) {
        isValid = false;
        message += `${label}\n`;
      }
    });
    return { isValid, message };
  };

  const onSubmit = async () => {
    const { isValid, message } = checkValidData();
    if (!isValid)
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });

    const data = {
      name: {
        en: _.trim(categoryName?.en),
        al: _.trim(categoryName?.al),
      },
      code: _.trim(categoryCode),
    };
    const filter = _.get(location, "state.filter");
    const resolveFilter = {
      pathname: RouteTypes.ADMIN_ROUTES.CATEGORIES,
      state: { filter, from: RouteTypes.ADMIN_ROUTES.CATEGORIES_DETAILS },
    };

    if (categoryID)
      await dispatch(updateCategory(data, categoryID, resolveFilter));
    else await dispatch(createCategory(data, resolveFilter));
  };

  const onBack = () => {
    if (!_.isEmpty(category)) {
      const filter = _.get(location, "state.filter");
      dispatch(clearCategoryDetails());
      history.replace({
        ...history.location,
        state: { filter, from: RouteTypes.ADMIN_ROUTES.CATEGORIES_DETAILS },
      });
    }
    history.goBack();
  };

  const handleRequire = (key: string) => {
    const isRequire = _.get(require, key);
    if (isRequire) setRequire({ ...require, [key]: false });
  };

  const renderMain = () => {
    return (
      <FormDetails>
        <FormInline>
          <FormGroup>
            <FormLabel aria-label="require">{t("label.englishName")}</FormLabel>
            <Input
              value={_.get(categoryName, "en")}
              onChangeHandler={(e) => {
                setCategoryName({ ...categoryName, en: e.target.value });
                handleRequire("en");
              }}
              placeholder={t(
                `placeholder.${isLoading ? "loading" : "englishName"}`
              )}
              width="300px"
            />
          </FormGroup>
          <FormGroup>
            <FormLabel aria-label="require">{t("label.shqipName")}</FormLabel>
            <Input
              value={_.get(categoryName, "al")}
              onChangeHandler={(e) => {
                setCategoryName({ ...categoryName, al: e.target.value });
                handleRequire("al");
              }}
              placeholder={t(
                `placeholder.${isLoading ? "loading" : "shqipName"}`
              )}
              width="300px"
            />
          </FormGroup>
        </FormInline>
        <FormInline>
          <FormGroup>
            <FormLabel aria-label="require">{t("label.code")}</FormLabel>
            <Input
              value={categoryCode}
              onChangeHandler={(e) => {
                setCategoryCode(e.target.value);
                handleRequire("code");
              }}
              placeholder={t("placeholder.code")}
              width="300px"
            />
          </FormGroup>
          {_.get(location, "state.id") && (
            <FormGroup>
              <FormLabel aria-label="require">{t("label.status")}</FormLabel>
              <Dropdown
                value={status}
                options={optionStatus}
                placeholder={t("placeholder.status")}
                loading={isLoading}
                disabled={true}
                width="300px"
              />
            </FormGroup>
          )}
        </FormInline>
        <ButtonField margin="10px 0">
          <Button onClick={() => onSubmit()} {...ButtonStyleProps}>
            {_.has(category, "id") ? t("button.save") : t("button.create")}
          </Button>
          <Button onClick={() => onBack()} {...ButtonStyleProps}>
            {t("button.back")}
          </Button>
        </ButtonField>
      </FormDetails>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.ADMIN_ROUTES.CATEGORIES}
      parent={RouteTypes.ADMIN_ROUTES.MANAGE_SOURCES}
      title={t("titlePage.categories")}
      subTitle={
        _.get(location, "state.id")
          ? t("titlePage.editCategory")
          : t("titlePage.createNewCategory")
      }
    />
  );
};

export default Categories;
