/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { CONSTANTS } from "constants/Constants";
import { useDispatch, useSelector } from "react-redux";

import { EmailTemplateActions } from "actions";

import {
  TemplateDetailsContainer,
  TemplateDetailsContent,
  TemplateDetailsHeader,
  TemplateDetailsForm,
  ButtonField,
  TemplatePreview,
  PreviewButton,
  LoadingLayer,
  Spinner,
} from "../Popup.styles";
import {
  Button,
  Input,
  Dropdown,
  Textarea,
  EmailEditor,
  FormGroup,
  FormInline,
  FormLabel,
} from "components/Common";
import { Design } from "react-email-editor";
import { useTranslation } from "react-i18next";
import { popupAlert } from "libs/Utils";
import { RootState } from "reducers";

const { clearEmailTemplateDetails, createEmailTemplate, updateEmailTemplate } =
  EmailTemplateActions;

const EMAIL_VARIABLES = {
  [CONSTANTS.EMAIL_TYPE.EMAIL_REPORT]: "##USER_EMAIL##",
  [CONSTANTS.EMAIL_TYPE.EMAIL_SCHEDULE]: "##CONTENT##\n##USER_EMAIL##",
  [CONSTANTS.EMAIL_TYPE.FORGOT_PASSWORD]:
    "##USERNAME##\n##RESET_PASSWORD##\n##REGISTERED_EMAIL##",
  [CONSTANTS.EMAIL_TYPE.VERIFY_ACCOUNT]:
    "##USERNAME##\n##LINK_VERIFY##\n##REGISTERED_EMAIL##\n##PASSWORD##",
  [CONSTANTS.EMAIL_TYPE.ITEM_NEWS]:
    "##SOURCE##\n##TITLE##\n##CONTENT##\n##DATE##",
  [CONSTANTS.EMAIL_TYPE.EMAIL_TEMPLATE_FOR_NEWS]: "##POSTS##",
};

interface SectionProps {
  onClose(): void;
  emailTemplate?: any;
  filter?: any;
}

const EmailTemplateDetails = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { onClose, emailTemplate } = props;

  const dispatch = useDispatch();
  const emailTemplateIsLoading = useSelector((state: RootState) =>
    _.get(state, "EmailTemplate.isLoading")
  );
  const [emailVariables, setEmailVariables] = useState<string>("");
  const [isShowEditMailContent, setIsShowEditMailContent] =
    useState<boolean>(false);
  const [emailData, setEmailData] = useState({
    emailTemplateName: "",
    emailTemplateSubject: "",
    emailTemplateContent: "",
    emailTemplateDesign: {},
    emailType: "",
  });
  const [required, setRequired] = useState({
    emailTemplateName: false,
    emailTemplateSubject: false,
    emailTemplateContent: false,
    emailType: false,
  });

  const EMAIL_TYPES_DROPDOWN = [
    {
      label: t("dropdown.emailReport"),
      value: CONSTANTS.EMAIL_TYPE.EMAIL_REPORT,
    },
    {
      label: t("dropdown.emailSchedule"),
      value: CONSTANTS.EMAIL_TYPE.EMAIL_SCHEDULE,
    },
    {
      label: t("dropdown.forgotPassword"),
      value: CONSTANTS.EMAIL_TYPE.FORGOT_PASSWORD,
    },
    {
      label: t("dropdown.verifyAccount"),
      value: CONSTANTS.EMAIL_TYPE.VERIFY_ACCOUNT,
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(clearEmailTemplateDetails());
    };
  }, []);

  useEffect(() => {
    const previewContent: any = document.querySelector(
      "iframe#preview-content"
    );
    if (!_.isEmpty(emailTemplate)) {
      const emailTemplateContent = _.get(emailTemplate, "emailTemplateContent");
      setEmailData(emailTemplate);
      if (previewContent) {
        const iframeDocument = previewContent.contentDocument;
        if (iframeDocument) {
          iframeDocument.open();
          iframeDocument.write(emailTemplateContent);
          iframeDocument.close();
        }
      }
    }
  }, [emailTemplate]);

  useEffect(() => {
    const previewContent: any = document.querySelector(
      "iframe#preview-content"
    );
    const emailType = _.get(emailData, "emailType");
    const emailTemplateContent = _.get(emailData, "emailTemplateContent");
    if (!_.isEmpty(emailTemplateContent)) {
      if (previewContent) {
        const iframeDocument = previewContent.contentDocument;
        if (iframeDocument) {
          iframeDocument.open();
          iframeDocument.write(emailTemplateContent);
          iframeDocument.close();
        }
      }
    }
    const getEmailVariables = _.get(EMAIL_VARIABLES, emailType);
    setEmailVariables(getEmailVariables);
  }, [emailData]);

  const validateData = () => {
    let message = `${t("alert.foundEmptyFields")}`;
    let flag = true;
    const data = [
      {
        label: t("label.emailType"),
        value: _.get(emailData, "emailType"),
      },
      {
        label: t("label.templateName"),
        value: _.get(emailData, "emailTemplateName"),
      },

      {
        label: t("label.emailSubject"),
        value: _.get(emailData, "emailTemplateSubject"),
      },
      {
        label: t("label.emailContent"),
        value: _.get(emailData, "emailTemplateContent"),
      },
    ];
    _.map(data, (item) => {
      const value = _.get(item, "value");
      const label = _.get(item, "label");
      if (_.isEmpty(_.trim(value))) {
        message += `${label}\n`;
        flag = false;
      }
    });
    return { flag, message };
  };

  const onSubmit = async () => {
    const { flag, message } = validateData();
    if (!flag)
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });
    else {
      const isHasId = _.get(emailData, "id");

      const resolveEmailData = {
        ...emailData,
        emailTemplateName: _.trim(_.get(emailData, "emailTemplateName")),
        emailTemplateSubject: _.trim(_.get(emailData, "emailTemplateSubject")),
      };

      if (isHasId) await dispatch(updateEmailTemplate(resolveEmailData));
      else await dispatch(createEmailTemplate(resolveEmailData));
      // await dispatch(searchEmailTemplate({ limit: 10 }));
      if (onClose) onClose();
    }
  };

  const renderMain = () => {
    return (
      <TemplateDetailsContainer>
        {isShowEditMailContent && (
          <EmailEditor
            onClose={(html: string, design: Design) => {
              if (!_.isEmpty(html) && !_.isEmpty(design))
                setEmailData({
                  ...emailData,
                  emailTemplateContent: html,
                  emailTemplateDesign: design,
                });
              setIsShowEditMailContent(false);
            }}
            design={
              _.get(emailData, "emailTemplateDesign") as unknown as Design
            }
            variables={emailVariables}
          />
        )}
        <TemplateDetailsContent width="670px" height="595px">
          {emailTemplateIsLoading && (
            <LoadingLayer>
              <Spinner />
            </LoadingLayer>
          )}
          <TemplateDetailsHeader>
            {_.has(emailData, "id")
              ? t(`headerPopup.editEmailTemplate`)
              : t(`headerPopup.createEmailTemplate`)}
          </TemplateDetailsHeader>
          <TemplateDetailsForm height="470px">
            <FormInline>
              <FormGroup>
                <FormLabel aria-label="require">
                  {t("label.emailType")}
                </FormLabel>
                <Dropdown
                  value={_.get(emailData, "emailType")}
                  width="300px"
                  callbackPayload={(value) => {
                    setRequired({ ...required, emailType: false });
                    setEmailData({ ...emailData, emailType: value });
                  }}
                  options={EMAIL_TYPES_DROPDOWN}
                  placeholder={t("placeholder.emailType")}
                  isRequired={_.get(required, "emailType")}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel aria-label="require">
                  {t("label.templateName")}
                </FormLabel>
                <Input
                  value={_.get(emailData, "emailTemplateName")}
                  onChangeHandler={(e) => {
                    setRequired({ ...required, emailTemplateName: false });
                    setEmailData({
                      ...emailData,
                      emailTemplateName: e.target.value,
                    });
                  }}
                  isRequired={_.get(required, "emailTemplateName")}
                  placeholder={t("placeholder.templateName")}
                  width="300px"
                />
              </FormGroup>
            </FormInline>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.emailSubject")}
              </FormLabel>
              <Input
                value={_.get(emailData, "emailTemplateSubject")}
                onChangeHandler={(e) => {
                  setRequired({ ...required, emailTemplateSubject: false });
                  setEmailData({
                    ...emailData,
                    emailTemplateSubject: e.target.value,
                  });
                }}
                isRequired={_.get(required, "emailTemplateSubject")}
                placeholder={t("placeholder.emailSubject")}
                width="100%"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.emailContent")}
              </FormLabel>
              <TemplatePreview>
                <PreviewButton
                  onClick={() =>
                    setIsShowEditMailContent(!isShowEditMailContent)
                  }
                  title="Edit content"
                >
                  <i className="fas fa-edit" />
                </PreviewButton>
                {_.isEmpty(_.get(emailData, "emailTemplateContent")) ? (
                  <h3>{t("description.emailContentIsEmpty")}</h3>
                ) : (
                  <iframe
                    frameBorder="0"
                    id="preview-content"
                    title="Preview content"
                    name="preview"
                  />
                )}
              </TemplatePreview>
            </FormGroup>
            <FormGroup>
              <FormLabel>{t("label.emailVariables")}</FormLabel>
              <Textarea
                value={emailVariables}
                isRequired={_.get(required, "variables")}
                placeholder={t("placeholder.emailVariables")}
                width="100%"
                disabled={true}
                rows={5}
              />
            </FormGroup>
          </TemplateDetailsForm>
          <ButtonField>
            <Button
              type="submit"
              buttonWidth="100px"
              onClick={() => onSubmit()}
            >
              {_.has(emailData, "id") ? t("button.save") : t("button.create")}
            </Button>
            <Button
              onClick={() => onClose && onClose()}
              type="submit"
              buttonWidth="100px"
              buttonMargin="0 0 0 10px"
            >
              {t("button.cancel")}
            </Button>
          </ButtonField>
        </TemplateDetailsContent>
      </TemplateDetailsContainer>
    );
  };
  return renderMain();
};

export default EmailTemplateDetails;
