/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { RootState } from "reducers";
import { CategoriesActions, DataRecordsActions } from "actions";

import TableUltils from "libs/TableUltils";
import { generateDropdown, generateFilter, popupAlert } from "libs/Utils";
import history from "../../../history";
import { CONSTANTS } from "constants/Constants";
import RouteTypes from "constants/RouteTypes";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Table,
  Input,
  Button,
  SelectDatetime,
  Dropdown,
  ContainerSearch,
  FlexWrapRow,
} from "components/Common";
import { TVNews, ViewDetailsNews } from "components/Common/Popup";
import { useLocation } from "react-router-dom";
import ColorName from "../../../constants/Variables";

const DEFAULT_FILTER = {
  dateFrom: "",
  dateTo: "",
  category: "",
  keyword: "",
  page: 1,
  limit: 100,
  sortBy: "createdAt",
  orderBy: "DESC",
};

interface FilterStructure {
  dateFrom: string;
  dateTo: string;
  category: string;
  keyword: string;
  page: number;
  limit: number;
  sortBy: string;
  orderBy: string;
}

const ButtonStyleProps = {
  buttonMargin: "10px 10px 10px 0",
};

const InputStyleProps = {
  margin: "10px 10px 10px 0",
  width: "200px",
};

const { fetchAllCategories, clearCategories } = CategoriesActions;
const { deleteDataRecord, searchDataRecord, clearDataRecords } =
  DataRecordsActions;

const DataRecord = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const dataRecordIsLoading = useSelector((state: RootState) => {
    return state.DataRecord.isLoading;
  });
  const categoryIsLoading = useSelector((state: RootState) => {
    return state.Categories.isLoading;
  });
  const dataRecords = useSelector((state: RootState) => {
    return state.DataRecord.dataRecords;
  });
  const categories = useSelector((state: RootState) => {
    return state.Categories.categories;
  });
  const location = useLocation();
  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const [tvData, setTVData] = useState<any>({});
  const [isPreViewPopup, setIsPreViewPopup] = useState<boolean>(false);
  const [isShowTVNewsDetails, setIsShowTVNewsDetails] =
    useState<boolean>(false);
  const [contentPopup, setContentPopup] = useState({});
  const dataHead = [
    "no",
    "headline",
    "source",
    "publishedAt",
    "preview",
    "actions",
  ];

  const dataTable = TableUltils._processDataTable(
    [
      "no",
      "id",
      "title",
      "source.name",
      "publishedAt",
      "preview",
      "action",
      "content",
    ],
    _.get(dataRecords, "items")
  );

  useEffect(() => {
    const stateFilter: any = _.get(location, "state.filter");
    const prevRoute = _.get(location, "state.from");
    const resolveFilter = {
      ...filter,
    };
    if (
      stateFilter &&
      prevRoute === RouteTypes.ADMIN_ROUTES.DATA_RECORD_DETAILS
    ) {
      _.assign(resolveFilter, { ...stateFilter });
      history.replace({ ...history.location, state: {} });
    }
    dispatch(searchDataRecord(generateFilter(filter)));
    dispatch(fetchAllCategories({ status: CONSTANTS.STATUS.ACTIVE }));

    return () => {
      dispatch(clearDataRecords());
      dispatch(clearCategories());
    };
  }, [dispatch]);

  const dataDropdownCategories = generateDropdown({
    data: categories,
    key: "name",
    value: "id",
  });
  const listColumnCenter = [4, 5, 6, 7];

  const callbackCurrentPageLink = (page: number) => {
    const newFilter: FilterStructure = {
      ...filter,
      page,
    };
    setFilter(newFilter);
    dispatch(searchDataRecord(generateFilter(newFilter)));
  };

  const addFilter = () => {
    const newFilter = {
      ...filter,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(searchDataRecord(generateFilter(newFilter)));
  };

  const onReset = async () => {
    setFilter(DEFAULT_FILTER);
    dispatch(searchDataRecord(generateFilter(DEFAULT_FILTER)));
  };

  const callbackAction = (action: string, id: string, item: any) => {
    if (action === CONSTANTS.ACTION.EDIT) {
      const findRecord = _.find(_.get(dataRecords, "items"), { id });
      if (findRecord) {
        const mediaType = _.get(findRecord, "source.mediaType");
        if (mediaType === "tv") {
          setTVData(findRecord);
          setIsShowTVNewsDetails(true);
        } else
          history.push({
            pathname: "/data-record-details",
            state: { id, item },
          });
      }
    }

    if (action === CONSTANTS.ACTION.REMOVE) {
      const dataRecordName = _.get(item, "title");
      const content = document.createElement("span");
      content.innerHTML = `${t("alert.areYouSureYouWantToRemove")} ${t(
        "alert.theRecord"
      )}</br> <b>${dataRecordName}</b>?`;
      popupAlert({
        type: "warning",
        title: t("alert.warning"),
        content: content,
        buttons: true,
        dangerMode: true,
      }).then(async (willContinue) => {
        if (willContinue) {
          await dispatch(deleteDataRecord(id));
          await dispatch(searchDataRecord(generateFilter(filter)));
        }
      });
    }
  };

  const callBackPreview = (item: any) => {
    const id = _.get(item, "id");
    const findDataRecord = _.find(dataRecords?.items, { id });
    if (_.isEmpty(findDataRecord))
      popupAlert({
        title: t("alert.error"),
        type: "error",
        text: t("alert.errorViewDetailsDataRecord"),
      });
    else {
      setContentPopup(findDataRecord);
      setIsPreViewPopup(true);
    }
  };

  const _renderTable = () => {
    return (
      <Table
        dataHeader={dataHead}
        dataTable={dataTable}
        listActionItem={[CONSTANTS.ACTION.EDIT, CONSTANTS.ACTION.REMOVE]}
        callBackAction={callbackAction}
        callBackPreview={callBackPreview}
        fitColumns={[6, 7]}
        listColumnCenter={listColumnCenter}
        hasPagination={true}
        callbackCurrentPageLink={callbackCurrentPageLink}
        metaPagination={dataRecords.meta}
        columnNotPrint={["content"]}
        loading={dataRecordIsLoading}
      />
    );
  };

  const renderMain = () => {
    return (
      <>
        {isPreViewPopup && (
          <ViewDetailsNews
            data={contentPopup}
            onClose={() => {
              setIsPreViewPopup(false);
              setContentPopup({});
            }}
          />
        )}
        {isShowTVNewsDetails && (
          <TVNews
            data={tvData}
            onClose={() => {
              setIsShowTVNewsDetails(false);
              setTVData({});
              dispatch(searchDataRecord(generateFilter(filter)));
            }}
          />
        )}
        <ContainerSearch className={dataRecordIsLoading ? "loading" : ""}>
          <FlexWrapRow>
            <SelectDatetime
              callbackPayload={(e) => setFilter({ ...filter, dateFrom: e })}
              defaultValue={filter?.dateFrom}
              placeholder={t("placeholder.dateFrom")}
              {...InputStyleProps}
            />
            <SelectDatetime
              callbackPayload={(e) => setFilter({ ...filter, dateTo: e })}
              defaultValue={filter?.dateTo}
              placeholder={t("placeholder.dateTo")}
              {...InputStyleProps}
            />
            <Dropdown
              value={filter?.category}
              callbackPayload={(value) =>
                setFilter({ ...filter, category: value })
              }
              options={dataDropdownCategories}
              placeholder={t("placeholder.category")}
              search={true}
              multiple={true}
              loading={categoryIsLoading}
              {...InputStyleProps}
            />
            <Input
              value={filter?.keyword}
              onChangeHandler={(e) =>
                setFilter({ ...filter, keyword: e.target.value })
              }
              onEnter={() => addFilter()}
              placeholder={t("placeholder.keyword")}
              {...InputStyleProps}
            />
            <Button
              type="submit"
              onClick={() => addFilter()}
              {...ButtonStyleProps}
            >
              {t("button.search")}
            </Button>
            <Button
              type="submit"
              onClick={() => onReset()}
              {...ButtonStyleProps}
            >
              {t("button.reset")}
            </Button>
            <Button
              type="submit"
              onClick={() => setIsShowTVNewsDetails(true)}
              background={ColorName.william}
              {...ButtonStyleProps}
            >
              {t("button.createTVNews")}
            </Button>
          </FlexWrapRow>
        </ContainerSearch>
        {_renderTable()}
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.ADMIN_ROUTES.DATA_RECORD}
      parent={RouteTypes.ADMIN_ROUTES.MANAGE_RECORD}
      title={t("titlePage.reports")}
    />
  );
};

export default DataRecord;
