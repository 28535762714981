/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import _ from "lodash";

import { DataRecordsActions } from "actions";
import { RootState } from "reducers";

import history from "../../../history";
import { ROUTES } from "constants/Constants";
import RouteTypes from "constants/RouteTypes";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import { ContainerDetails } from "./DataRecord.styles";
import { Button } from "components/Common";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { validateData } from "../../../libs/Utils";

const { getDataRecordByID, updateDataRecord, clearDataRecord } =
  DataRecordsActions;

interface IDState {
  id: any;
}

const DataRecordDetails = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state: RootState) => {
    return state.DataRecord.isLoading;
  });
  const dataRecord = useSelector((state: RootState) => {
    return state.DataRecord.dataRecord;
  });

  const [record, setRecord]: any = useState();

  useEffect(() => {
    return () => {
      dispatch(clearDataRecord());
    };
  }, []);

  useEffect(() => {
    if (dataRecord) setRecord(dataRecord);
  }, [dataRecord]);

  useEffect(() => {
    if (location.state) {
      const id = (location.state as IDState).id || "";
      if (!id) return history.push(ROUTES.DATA_RECORD);
      if (id) dispatch(getDataRecordByID(id));
    } else {
      history.push(ROUTES.DATA_RECORD);
    }
  }, [location, dispatch]);

  const saveDataRecord = () => {
    const requireData = [
      {
        label: t("label.content"),
        value: _.get(record, "content"),
        key: "content",
      },
    ];
    const isValid = validateData(requireData);
    if (!_.isEmpty(isValid)) return;

    const filter = _.get(location, "state.filter");

    const resolveFilter = {
      pathname: RouteTypes.ADMIN_ROUTES.DATA_RECORD,
      state: { filter, from: RouteTypes.ADMIN_ROUTES.DATA_RECORD_DETAILS },
    };

    dispatch(updateDataRecord(record, record.id, resolveFilter));
  };

  const renderCreatedAt = (value: string) => {
    const timeValue = dayjs(new Date(value));
    let result;
    if (timeValue.isValid()) result = value;
    else {
      if (_.includes(value, "a.m.")) result = _.replace(value, /a.m./g, "am");
      if (_.includes(value, "p.m.")) result = _.replace(value, /p.m./g, "pm");
    }
    let resolveTime = dayjs(result).format("DD/MM/YY, HH:mm:ss");
    return resolveTime;
  };

  const renderMain = () => {
    return (
      <ContainerDetails className={isLoading ? "loading" : ""}>
        <div className="group-details">
          <label>{t("label.title")}:</label>
          <span className="title">{_.get(record, "title")}</span>
        </div>
        <div className="group-details">
          <label>{t("label.author")}:</label>
          <span className="author">{_.get(record, "author")}</span>
        </div>
        <div className="group-details">
          <label>{t("label.category")}:</label>
          <span className="category">
            {_.get(record, "category.name.en") || "N/I"}
          </span>
        </div>
        <div className="group-details">
          <label>{t("label.createdAt")}:</label>
          <span className="time">
            {renderCreatedAt(_.get(record, "createdAt"))}
          </span>
        </div>
        <div className="group-details content">
          <label className="title">{t("label.content")}</label>
          <textarea
            spellCheck={false}
            className="wrap-content"
            value={
              isLoading ? t("placeholder.loading") : _.get(record, "content")
            }
            style={{ fontSize: "15px", fontFamily: "Be Vietnam" }}
            onChange={(e) => setRecord({ ...record, content: e.target.value })}
          />
        </div>
        <div className="group-details">
          <Button
            buttonWidth="unset"
            buttonMargin="20px 10px 20px 0"
            onClick={() => saveDataRecord()}
          >
            {t("button.save")}
          </Button>
          <Button
            buttonWidth="unset"
            buttonMargin="20px 0 20px 0"
            onClick={() => history.goBack()}
          >
            {t("button.back")}
          </Button>
        </div>
      </ContainerDetails>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      // loading={isLoading}
      currentPage={RouteTypes.ADMIN_ROUTES.DATA_RECORD}
      parent={RouteTypes.ADMIN_ROUTES.MANAGE_RECORD}
      title={t("titlePage.dataRecords")}
      subTitle={t("titlePage.dataRecordDetails")}
    />
  );
};

export default DataRecordDetails;
