import Api from "./Base";
import { API } from "constants/Constants";

const { FOLDER } = API;

export function fetchFolders(data: any) {
  return Api.get(FOLDER.BASIC, data);
}

export function fetchClientsForFolders() {
  return Api.get(FOLDER.CLIENT_FOR_FOLDER);
}

export function getFolderById(id: any) {
  return Api.get(`${FOLDER.BASIC}/${id}`);
}

export function searchFolders(data: any) {
  return Api.get(FOLDER.BASIC, data);
}

export function createFolders(data: any) {
  return Api.post(FOLDER.BASIC, data);
}

export function updateFolders(data: any, id: string) {
  return Api.put(`${FOLDER.UPDATE}/${id}`, data);
}

export function deactivateFolder(id: string) {
  return Api.put(`${FOLDER.DEACTIVATE}/${id}`);
}

export function activateFolder(id: string) {
  return Api.put(`${FOLDER.ACTIVATE}/${id}`);
}

export function exportFolders() {
  return Api.getBlob(FOLDER.EXPORT);
}

export function addDataRecordToFolder(data: any) {
  return Api.post(FOLDER.ADD_DATA_RECORD_TO_FOLDER, data);
}

export function deletePostInFolder(data: any) {
  return Api.del(FOLDER.REMOVE_POST_FROM_FOLDER, data);
}
