import ActionTypes from "constants/ActionTypes";
import API from "api";
import _ from "lodash";
import { responseAlert } from "libs/alert";
import history from "../history";

const clearAccountDetails = () => {
  return {
    type: ActionTypes.CLEAR_PACKAGE_DETAILS,
  };
};

const clearPackages = () => {
  return {
    type: ActionTypes.CLEAR_PACKAGES,
  };
};

const accountFail = () => {
  return {
    type: ActionTypes.ACCOUNT_FAILURE,
  };
};

const accountSuccess = () => {
  return {
    type: ActionTypes.ACCOUNT_SUCCESS,
  };
};

const accountLoading = () => {
  return {
    type: ActionTypes.ACCOUNT_REQUEST,
  };
};

const createAccountSuccess = () => {
  return {
    type: ActionTypes.CREATE_ACCOUNT_SUCCESS,
  };
};

const updateAccountSuccess = (payload = {}) => {
  return {
    type: ActionTypes.UPDATE_ACCOUNT_SUCCESS,
    payload,
  };
};

const fetchAccountsSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_ACCOUNT_SUCCESS,
    payload,
  };
};

const searchAccountsSuccess = (payload = {}) => {
  return {
    type: ActionTypes.SEARCH_ACCOUNT_SUCCESS,
    payload,
  };
};

const getAccountByIdSuccess = (payload = {}) => {
  return {
    type: ActionTypes.GET_ACCOUNT_BY_ID_SUCCESS,
    payload,
  };
};

const createAccountOfClient = (data: any, filter: any) => {
  return async (dispatch: any) => {
    dispatch(accountLoading());
    await API.addAccountOfClient(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(createAccountSuccess());
          await responseAlert({ label: "Package created", res });
          history.push(filter);
        } else {
          responseAlert({ res });
          dispatch(accountFail());
        }
      })
      .catch((e: any) => {
        dispatch(accountFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchAccountsOfClient = () => {
  return async (dispatch: any) => {
    dispatch(accountLoading());
    await API.fetchAccountsOfClient()
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchAccountsSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(accountFail());
        }
      })
      .catch((e: any) => {
        dispatch(accountFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchAllAccount = () => {
  return async (dispatch: any) => {
    dispatch(accountLoading());
    await API.fetchAllAccount()
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchAccountsSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(accountFail());
        }
      })
      .catch((e: any) => {
        dispatch(accountFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getAccountOfClientByID = (id: string) => {
  return async (dispatch: any) => {
    dispatch(accountLoading());
    await API.getAccountOfClientByID(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(getAccountByIdSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(accountFail());
        }
      })
      .catch((e: any) => {
        dispatch(accountFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const searchAccountsOfClient = (data: any) => {
  return async (dispatch: any) => {
    dispatch(accountLoading());
    await API.searchAccountsOfClient(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(searchAccountsSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(accountFail());
        }
      })
      .catch((e: any) => {
        dispatch(accountFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const updateAccountOfClient = (data: any, id: any, filter: any) => {
  return async (dispatch: any) => {
    dispatch(accountLoading());
    await API.updateAccountOfClient(data, id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(updateAccountSuccess(res.payload));
          await responseAlert({ res, label: "Package updated" });
          history.push(filter);
        } else {
          responseAlert({ res });
          dispatch(accountFail());
        }
      })
      .catch((e: any) => {
        dispatch(accountFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const deactivateAccountOfClient = (id: string) => {
  return async (dispatch: any) => {
    dispatch(accountLoading());
    await API.deactivateAccountOfClient(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Account deactivated" });
          dispatch(accountSuccess());
        } else {
          responseAlert({ res });
          dispatch(accountFail());
        }
      })
      .catch((e: any) => {
        dispatch(accountFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const activateAccountOfClient = (id: string) => {
  return async (dispatch: any) => {
    dispatch(accountLoading());
    await API.activateAccountOfClient(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Account activated" });
          dispatch(accountSuccess());
        } else {
          responseAlert({ res });
          dispatch(accountFail());
        }
      })
      .catch((e: any) => {
        dispatch(accountFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

export {
  clearAccountDetails,
  createAccountOfClient,
  fetchAccountsOfClient,
  getAccountOfClientByID,
  searchAccountsOfClient,
  updateAccountOfClient,
  deactivateAccountOfClient,
  activateAccountOfClient,
  fetchAllAccount,
  clearPackages,
};
