/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { RootState } from "reducers";
import { FiltersActions } from "actions";

import { CONSTANTS } from "constants/Constants";
import RouteTypes from "constants/RouteTypes";

import TableUltils from "libs/TableUltils";
import {
  clearAllSavedData,
  generateFilter,
  getClientId,
  popupAlert,
} from "../../../libs/Utils";
import history from "../../../history";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Input,
  Button,
  Table,
  Checkbox,
  ContainerSearch,
  FlexWrapRow,
} from "components/Common";
import { LogsDetails } from "components/Common/Popup";

const { searchFilters, clearFilters } = FiltersActions;

const DEFAULT_FILTER = {
  filterName: "",
  clientId: "",
  accountName: "",
  status: CONSTANTS.STATUS.ACTIVE,
  page: 1,
  limit: 10,
  sortBy: "updatedAt",
  orderBy: "DESC",
};

interface FilterStructure {
  filterName: string;
  accountName: string;
  status: string;
  page: number;
  limit: number;
  sortBy: string;
  orderBy: string;
}

const InputStyleProps = {
  width: "200px",
  margin: "10px 10px 10px 0",
};

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "10px 10px 10px 0",
};

const Filters = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state: RootState) => {
    return state.Filters.isLoading;
  });
  const filters = useSelector((state: RootState) => {
    return state.Filters.filters;
  });

  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const [logData, setLogData] = useState<any>({});
  const dataHead = [
    "no",
    "filterName",
    "clientName",
    "clientNumber",
    "packageName",
    "status",
    "actions",
  ];

  const dataTable = TableUltils._processDataTable(
    [
      "no",
      "id",
      "filterName",
      "clientId.clientName",
      "clientId.clientNumber",
      "accountId.accountName",
      "status",
      "action",
    ],
    filters.items
  );

  const listColumnCenter = [6, 7];

  const checkClientId = async () => {
    await popupAlert({
      title: t("alert.warning"),
      type: "warning",
      text: t("alert.emptyClientID"),
    });
    clearAllSavedData();
    history.push(RouteTypes.AUTHENTICATION_ROUTES.AUTHENTICATION);
  };

  useEffect(() => {
    return () => {
      dispatch(clearFilters());
    };
  }, []);

  useEffect(() => {
    const stateFilter: any = _.get(location, "state.filter");
    const prevRoute = _.get(location, "state.from");
    const clientId = getClientId();

    if (!clientId) checkClientId();

    const resolveFilter = {
      ...filter,
      clientId,
    };
    if (stateFilter && prevRoute === RouteTypes.CLIENT_ROUTES.FILTERS_DETAILS) {
      _.assign(resolveFilter, { ...stateFilter });
      history.replace({ ...history.location, state: {} });
    }
    setFilter(resolveFilter);
    dispatch(searchFilters(generateFilter(resolveFilter)));
  }, [dispatch]);

  const callbackCurrentPageLink = (page: number) => {
    const newFilter: any = {
      ...filter,
      page,
    };
    setFilter(newFilter);
    dispatch(searchFilters(generateFilter(newFilter)));
  };

  const callbackAction = async (action: string, id: string, item: object) => {
    if (action === CONSTANTS.ACTION.VIEW)
      history.push({
        pathname: RouteTypes.CLIENT_ROUTES.FILTERS_DETAILS,
        state: {
          id,
          filter,
        },
      });

    if (action === CONSTANTS.ACTION.LOG)
      setLogData({ logType: "filter", rowId: id });
  };

  const onReset = async () => {
    const clientId = getClientId();
    const resolveFilter = {
      ...filter,
      clientId,
    };
    setFilter(resolveFilter);
    await dispatch(searchFilters(generateFilter(resolveFilter)));
  };

  const _renderTable = () => {
    return (
      <Table
        dataHeader={dataHead}
        dataTable={dataTable}
        fitColumns={[1, 6, 7]}
        listColumnCenter={listColumnCenter}
        hasPagination={true}
        callbackCurrentPageLink={callbackCurrentPageLink}
        metaPagination={filters.meta}
        callBackAction={callbackAction}
        listActionItem={[CONSTANTS.ACTION.VIEW, CONSTANTS.ACTION.LOG]}
        loading={isLoading}
      />
    );
  };

  const setValueStatus = (status: string) => {
    const newFilter = {
      ...filter,
      status,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(searchFilters(generateFilter(newFilter)));
  };

  const addFilter = () => {
    const newFilter = {
      ...filter,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(searchFilters(generateFilter(newFilter)));
  };

  const renderMain = () => {
    return (
      <>
        {!_.isEmpty(logData) && (
          <LogsDetails onClose={() => setLogData({})} data={logData} />
        )}
        <ContainerSearch className={isLoading ? "loading" : ""}>
          <FlexWrapRow>
            <Input
              value={filter?.filterName || ""}
              onChangeHandler={(e) =>
                setFilter({ ...filter, filterName: e.target.value })
              }
              onEnter={() => addFilter()}
              placeholder={t("placeholder.filterName")}
              {...InputStyleProps}
            />
            <Input
              value={filter?.accountName}
              onChangeHandler={(e) =>
                setFilter({ ...filter, accountName: e.target.value })
              }
              onEnter={() => addFilter()}
              placeholder={t("placeholder.packageName")}
              {...InputStyleProps}
            />

            <Button onClick={() => addFilter()} {...ButtonStyleProps}>
              {t("button.search")}
            </Button>
            <Button onClick={() => onReset()} {...ButtonStyleProps}>
              {t("button.reset")}
            </Button>
          </FlexWrapRow>
          <Checkbox
            checked={filter?.status === CONSTANTS.STATUS.INACTIVE}
            context={t("label.showInactiveFilters")}
            onChangeHandler={(e: boolean) =>
              setValueStatus(
                e ? CONSTANTS.STATUS.INACTIVE : CONSTANTS.STATUS.ACTIVE
              )
            }
            margin="10px 0 0 0"
          />
        </ContainerSearch>
        {_renderTable()}
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.CLIENT_ROUTES.FILTERS}
      title={t("titlePage.filters")}
    />
  );
};

export default Filters;
