/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "reducers";
import { EmailDeliveryActions } from "actions";

import { CONSTANTS } from "constants/Constants";
import ColorName from "constants/Variables";
import RouteTypes from "constants/RouteTypes";

import TableUltils from "libs/TableUltils";
import { generateFilter, popupAlert } from "libs/Utils";
import history from "../../../history";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Button,
  Checkbox,
  Input,
  Table,
  ContainerSearch,
  FlexWrapRow,
} from "components/Common";
import { LogsDetails } from "components/Common/Popup";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface FilterStructure {
  scheduleName: string;
  accountName: string;
  clientName: string;
  status: string;
  page: number;
  limit: number;
  sortBy: string;
  orderBy: string;
}

const DEFAULT_FILTER = {
  scheduleName: "",
  accountName: "",
  clientName: "",
  status: CONSTANTS.STATUS.ACTIVE,
  page: 1,
  limit: 10,
  sortBy: "updatedAt",
  orderBy: "DESC",
};

const InputStyleProps = {
  width: "200px",
  margin: "10px 10px 10px 0",
};

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "10px 10px 10px 0",
};

const { deactivateEmailDelivery, searchEmailDelivery, clearEmailDeliveries } =
  EmailDeliveryActions;

const EmailDeliveryManagement = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const location = useLocation();
  const limit = 10;
  const emailDeliveryIsLoading = useSelector((state: RootState) => {
    return state.EmailDelivery.isLoading;
  });
  const emailDeliveries = useSelector((state: RootState) => {
    return state.EmailDelivery.emailDeliveries;
  });
  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const [logData, setLogData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataEmailDeliveries, setDataEmailDeliveries] = useState<any>([]);

  const dataHead = [
    "no",
    "scheduleName",
    "clientName",
    "clientNumber",
    "packageName",
    "packageCode",
    "status",
    "actions",
  ];

  const dataTable = TableUltils._processDataTable(
    [
      "no",
      "scheduleName",
      "clientId.clientName",
      "clientId.clientNumber",
      "accountId.accountName",
      "accountId.accountCode",
      "status",
      "action",
    ],
    dataEmailDeliveries
  );

  const listColumnCenter = [1, 4, 7, 8];

  useEffect(() => {
    const stateFilter: any = _.get(location, "state.filter");
    const prevRoute = _.get(location, "state.from");
    const resolveFilter = {
      ...filter,
    };
    if (
      stateFilter &&
      prevRoute === RouteTypes.ADMIN_ROUTES.EMAIL_DELIVERY_DETAILS
    ) {
      _.assign(resolveFilter, { ...stateFilter });
      history.replace({ ...history.location, state: {} });
    }
    setFilter(resolveFilter);
    dispatch(searchEmailDelivery(generateFilter(resolveFilter)));

    return () => {
      dispatch(clearEmailDeliveries());
    };
  }, [dispatch, limit]);

  useEffect(() => {
    if (!_.isEmpty(emailDeliveries)) {
      const getDataEmailDeliveries: any[] = _.get(emailDeliveries, "items");
      if (currentPage > 1) {
        setDataEmailDeliveries([
          ...dataEmailDeliveries,
          ...getDataEmailDeliveries,
        ]);
      } else setDataEmailDeliveries(getDataEmailDeliveries);
    }
  }, [emailDeliveries]);

  const callbackCurrentPageLink = (page: number) => {
    const newFilter: any = {
      ...filter,
      page,
    };
    setFilter(newFilter);
    dispatch(searchEmailDelivery(generateFilter(newFilter)));
  };

  const callbackAction = async (action: string, id: string, item: any) => {
    const findSchedule = _.find(emailDeliveries.items, {
      scheduleName: item.scheduleName,
    });
    const scheduleId = _.get(findSchedule, "id");
    if (action === CONSTANTS.ACTION.EDIT) {
      history.push({
        pathname: RouteTypes.ADMIN_ROUTES.EMAIL_DELIVERY_DETAILS,
        state: {
          id: scheduleId,
          filter,
        },
      });
    }

    if (action === CONSTANTS.ACTION.LOG)
      setLogData({ type: "email schedule", rowId: scheduleId });

    if (action === CONSTANTS.ACTION.DEACTIVATE) {
      const status = _.get(item, "status");
      const scheduleName = _.get(item, "scheduleName");
      const statusLabel =
        status === CONSTANTS.STATUS.ACTIVE
          ? t("alert.deactive")
          : t("alert.active");
      const content = document.createElement("span");
      content.innerHTML = `${t(
        "alert.areYouSureYouWantTo"
      )}</br> <b>${statusLabel}</b> ${t(
        "alert.theEmailSchedule"
      )} <b>${scheduleName}</b>?`;
      await popupAlert({
        type: "warning",
        content,
        buttons: true,
        dangerMode: true,
      }).then(async (willContinue) => {
        if (willContinue) {
          await dispatch(deactivateEmailDelivery(scheduleId));

          await dispatch(searchEmailDelivery(generateFilter(filter)));
        }
      });
    }
  };

  const onReset = async () => {
    setCurrentPage(1);
    setFilter(DEFAULT_FILTER);
    await dispatch(searchEmailDelivery(generateFilter(DEFAULT_FILTER)));
  };

  const addFilter = (page: number) => {
    const newFilter = {
      ...filter,
      page: page,
    };
    setFilter(newFilter);
    dispatch(searchEmailDelivery(generateFilter(newFilter)));
    setCurrentPage(page);
  };

  const setValueStatus = (status: string) => {
    const newFilter = {
      ...filter,
      status,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(searchEmailDelivery(generateFilter(newFilter)));
    setCurrentPage(1);
  };

  const onCreate = () => {
    history.push({
      pathname: RouteTypes.ADMIN_ROUTES.EMAIL_DELIVERY_DETAILS,
      state: { filter },
    });
  };

  const onFetchMore = () => {
    if (
      !emailDeliveryIsLoading &&
      currentPage < _.get(emailDeliveries, "meta.totalPages")
    )
      addFilter(filter.page + 1);
  };

  const onShowMore = () => {
    if (
      !emailDeliveryIsLoading &&
      currentPage < _.get(emailDeliveries, "meta.totalPages")
    )
      addFilter(filter.page + 1);
  };

  const _renderTable = () => {
    return (
      <Table
        // isScroll
        dataHeader={dataHead}
        dataTable={dataTable}
        fitColumns={[1, 7, 8]}
        listColumnCenter={listColumnCenter}
        hasPagination={true}
        callbackCurrentPageLink={callbackCurrentPageLink}
        metaPagination={emailDeliveries.meta}
        listActionItem={[
          CONSTANTS.ACTION.EDIT,
          CONSTANTS.ACTION.DEACTIVATE,
          CONSTANTS.ACTION.LOG,
        ]}
        callBackAction={callbackAction}
        loading={emailDeliveryIsLoading}
        showMore={onShowMore}
      />
    );
  };

  const renderMain = () => {
    return (
      <>
        {!_.isEmpty(logData) && (
          <LogsDetails data={logData} onClose={() => setLogData({})} />
        )}
        <ContainerSearch className={emailDeliveryIsLoading ? "loading" : ""}>
          <FlexWrapRow>
            <Input
              value={_.get(filter, "scheduleName")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, scheduleName: e.target.value })
              }
              onEnter={() => addFilter(1)}
              placeholder={t("placeholder.scheduleName")}
              {...InputStyleProps}
            />
            <Input
              value={_.get(filter, "clientName")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, clientName: e.target.value })
              }
              onEnter={() => addFilter(1)}
              placeholder={t("placeholder.clientName")}
              {...InputStyleProps}
            />
            <Input
              value={_.get(filter, "accountName")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, accountName: e.target.value })
              }
              onEnter={() => addFilter(1)}
              placeholder={t("placeholder.packageName")}
              {...InputStyleProps}
            />
            <Button onClick={() => addFilter(1)} {...ButtonStyleProps}>
              {t("button.search")}
            </Button>
            <Button onClick={() => onReset()} {...ButtonStyleProps}>
              {t("button.reset")}
            </Button>
            <Button
              onClick={() => onCreate()}
              background={ColorName.william}
              {...ButtonStyleProps}
            >
              + {t("button.create")}
            </Button>
          </FlexWrapRow>
          <Checkbox
            checked={_.get(filter, "status") === CONSTANTS.STATUS.INACTIVE}
            context={t("label.showInactiveEmailSchedules")}
            onChangeHandler={(e: boolean) =>
              setValueStatus(
                e ? CONSTANTS.STATUS.INACTIVE : CONSTANTS.STATUS.ACTIVE
              )
            }
            margin="10px 0 0 0"
          />
        </ContainerSearch>
        {_renderTable()}
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      title={t("titlePage.emailSchedules")}
      parent={RouteTypes.ADMIN_ROUTES.MANAGE_DELIVERY}
      currentPage={RouteTypes.ADMIN_ROUTES.EMAIL_DELIVERY}
      onScrollBottom={() => onFetchMore()}
    />
  );
};

export default EmailDeliveryManagement;
