import ActionTypes from "constants/ActionTypes";
import API from "api";
import _ from "lodash";
import history from "../history";
import { responseAlert } from "libs/alert";
import { generateFilter } from "libs/Utils";

const clearDataRecord = () => {
  return {
    type: ActionTypes.CLEAR_DATA_RECORD,
  };
};

const clearDataRecordForUser = () => {
  return {
    type: ActionTypes.CLEAR_DATA_RECORD_FOR_USER,
  };
};

const clearDataRecords = () => {
  return {
    type: ActionTypes.CLEAR_DATA_RECORDS,
  };
};

const clearHidePost = () => {
  return {
    type: ActionTypes.CLEAR_HIDE_POST,
  };
};

const dataRecordFail = () => {
  return {
    type: ActionTypes.DATA_RECORD_FAILURE,
  };
};

const dataRecordSuccess = () => {
  return {
    type: ActionTypes.DATA_RECORD_SUCCESS,
  };
};

const dataRecordLoading = () => {
  return {
    type: ActionTypes.DATA_RECORD_REQUEST,
  };
};

const createDataRecordSuccess = () => {
  return {
    type: ActionTypes.CREATE_DATA_RECORD_SUCCESS,
  };
};

const updateDataRecordSuccess = (payload = {}) => {
  return {
    type: ActionTypes.UPDATE_DATA_RECORD_SUCCESS,
    payload,
  };
};

const fetchDataRecordSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_DATA_RECORD_SUCCESS,
    payload,
  };
};

const searchDataRecordSuccess = (payload = {}) => {
  return {
    type: ActionTypes.SEARCH_DATA_RECORD_SUCCESS,
    payload,
  };
};

const getDataRecordByIdSuccess = (payload = {}) => {
  return {
    type: ActionTypes.GET_DATA_RECORD_BY_ID_SUCCESS,
    payload,
  };
};

const filterDataRecordSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FILTER_DATA_RECORD_IN_POST_SUCCESS,
    payload,
  };
};

const getDataRecordForFolderSuccess = (payload = {}) => {
  return {
    type: ActionTypes.GET_DATA_RECORD_FOR_FOLDER_SUCCESS,
    payload,
  };
};

const previewDataRecordBeforeCreateSuccess = (payload = {}) => {
  return {
    type: ActionTypes.PREVIEW_DATA_RECORD_BEFORE_CREATE_SUCCESS,
    payload,
  };
};

const sendReportByEmailSuccess = (payload = {}) => {
  return {
    type: ActionTypes.SEND_REPORT_BY_EMAIL_SUCCESS,
    payload,
  };
};

const sendSelectedPostsSuccess = () => {
  return {
    type: ActionTypes.SEND_SELECTED_POST_SUCCESS,
  };
};

const sendSelectedPostsFail = () => {
  return {
    type: ActionTypes.SEND_SELECTED_POST_FAILURE,
  };
};

const setDefaultSendPostStatus = () => {
  return {
    type: ActionTypes.SET_DEFAULT_SEND_POST_STATUS,
  };
};

const fetchDataRecordForUserSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_DATA_RECORDS_FOR_USER_PORTAL_SUCCESS,
    payload,
  };
};

const sendAttachmentToMailSuccess = () => {
  return {
    type: ActionTypes.SEND_ATTACHMENT_TO_MAIL_SUCCESS,
  };
};

const generatePayload = (payload: any) => {
  const listRecords = _.get(payload, "listRecords");
  const meta = _.get(payload, "meta");
  const resolveListRecords = _.unionBy(listRecords, "id");
  let totalPrint = 0;
  let totalWeb = 0;
  let totalTv = 0;
  let totalSocial = 0;
  let total = 0;

  _.map(resolveListRecords, (record) => {
    const type = _.get(record, "source.mediaType");
    if (type === "web") totalWeb += 1;
    else if (type === "print") totalPrint += 1;
    else if (type === "tv") totalTv += 1;
    else if (type === "social network") totalSocial += 1;
    total += 1;
  });

  if (_.has(payload, "total")) {
    total = _.get(payload, "total");
    totalPrint = _.get(payload, "totalPrint");
    totalWeb = _.get(payload, "totalWeb");
    totalTv = _.get(payload, "totalTv");
    totalSocial = _.get(payload, "totalSocial") || 0;
  }

  return {
    totalPrint,
    totalWeb,
    totalTv,
    total,
    totalSocial,
    meta,
    listRecords: resolveListRecords,
  };
};

const generateCamelCase = (payload: any) => {
  const items = _.get(payload, "items");
  const meta = _.get(payload, "meta");
  const resolvePayload: any[] = [];
  _.map(items, (item) => {
    const result = {};
    _.map(item, (innerItem, key) => {
      const replaceKey = key.replace(/_([a-z])/g, function (g) {
        return g[1].toUpperCase();
      });
      _.assign(result, { [replaceKey]: innerItem });
    });
    resolvePayload.push(result);
  });
  return {
    meta,
    items: resolvePayload,
  };
};

const createDataRecord = (data: any) => {
  return async (dispatch: any) => {
    dispatch(dataRecordLoading());
    await API.addDataRecord(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          await responseAlert({ res, label: "Data record created" });
          dispatch(createDataRecordSuccess());
        } else {
          responseAlert({ res });
          dispatch(dataRecordFail());
        }
      })
      .catch((e: any) => {
        dispatch(dataRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchDataRecords = () => {
  return async (dispatch: any) => {
    dispatch(dataRecordLoading());
    await API.fetchDataRecord()
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchDataRecordSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(dataRecordFail());
        }
      })
      .catch((e: any) => {
        dispatch(dataRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getDataRecordByID = (id: string) => {
  return async (dispatch: any) => {
    dispatch(dataRecordLoading());
    await API.getDataRecordByID(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(getDataRecordByIdSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(dataRecordFail());
        }
      })
      .catch((e: any) => {
        dispatch(dataRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const searchDataRecord = (data: any, callback?: any) => {
  return async (dispatch: any) => {
    dispatch(dataRecordLoading());
    await API.searchDataRecord(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(searchDataRecordSuccess(res.payload));
          if (callback) callback(res.payload?.items || []);
        } else {
          responseAlert({ res });
          dispatch(dataRecordFail());
        }
      })
      .catch((e: any) => {
        dispatch(dataRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const updateDataRecord = (data: any, id: any, filter?: any) => {
  return async (dispatch: any) => {
    dispatch(dataRecordLoading());
    await API.updateDataRecord(data, id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          await responseAlert({ res, label: "Data record updated" });
          dispatch(updateDataRecordSuccess(res.payload));
          if (filter) history.push(filter);
        } else {
          responseAlert({ res });
          dispatch(dataRecordFail());
        }
      })
      .catch((e: any) => {
        dispatch(dataRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const deleteDataRecord = (id: any, filter?: any) => {
  return async (dispatch: any) => {
    dispatch(dataRecordLoading());
    await API.deleteDataRecord(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Data record removed" });
          dispatch(dataRecordSuccess());
          dispatch(searchDataRecord(generateFilter(filter)));
        } else {
          responseAlert({ res });
          dispatch(dataRecordFail());
        }
      })
      .catch((e: any) => {
        dispatch(dataRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const filterDataRecordInPost = (data: any) => {
  return async (dispatch: any) => {
    dispatch(dataRecordLoading());
    await API.filterDataRecord(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          const responsed = generatePayload(res.payload);
          dispatch(filterDataRecordSuccess(responsed));
          dispatch(dataRecordSuccess());
        } else {
          responseAlert({ res });
          dispatch(dataRecordFail());
        }
      })
      .catch((e: any) => {
        dispatch(dataRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getDataRecordForFolderById = (data: any) => {
  return async (dispatch: any) => {
    dispatch(dataRecordLoading());
    await API.getDataRecordForFolderById(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          const resolvePayload = generateCamelCase(res.payload);
          dispatch(getDataRecordForFolderSuccess(resolvePayload));
        } else {
          responseAlert({ res });
          dispatch(dataRecordFail());
        }
      })
      .catch((e: any) => {
        dispatch(dataRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const previewDataRecordBeforeCreate = (data: any) => {
  return async (dispatch: any) => {
    dispatch(dataRecordLoading());
    await API.previewDataRecordBeforeCreate(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(previewDataRecordBeforeCreateSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(dataRecordFail());
        }
      })
      .catch((e: any) => {
        dispatch(dataRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const sendReportByEmail = (data: any) => {
  return async (dispatch: any) => {
    dispatch(dataRecordLoading());
    await API.sendReportByEmail(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Email report sent" });
          dispatch(sendReportByEmailSuccess());
        } else {
          responseAlert({ res });
          dispatch(dataRecordFail());
        }
      })
      .catch((e: any) => {
        dispatch(dataRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getDataRecordForUserPortal = (filter: any) => {
  return async (dispatch: any) => {
    dispatch(dataRecordLoading());
    await API.getDataRecordForUserPortal(filter)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchDataRecordForUserSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(dataRecordFail());
        }
      })
      .catch((e: any) => {
        dispatch(dataRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getPreviewDataRecordsForUser = (data: any) => {
  return async (dispatch: any) => {
    dispatch(dataRecordLoading());
    await API.getPreviewDataRecordsForUser(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchDataRecordForUserSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(dataRecordFail());
        }
      })
      .catch((e: any) => {
        dispatch(dataRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const sendCheckedPostToMail = (data: any) => {
  return async (dispatch: any) => {
    dispatch(dataRecordLoading());
    await API.sendDataRecordToMail(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          await responseAlert({ res, label: "Send posts" });
          dispatch(sendSelectedPostsSuccess());
        } else {
          await responseAlert({ res });
          dispatch(sendSelectedPostsFail());
        }
      })
      .catch((e: any) => {
        dispatch(dataRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const sendAttachmentToMail = (data: any) => {
  return async (dispatch: any) => {
    dispatch(dataRecordLoading());
    await API.sendAttachFileToMail(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          await responseAlert({ res, label: "Send mail" });
          dispatch(sendAttachmentToMailSuccess());
        } else {
          await responseAlert({ res });
          dispatch(dataRecordFail());
        }
      })
      .catch((e: any) => {
        dispatch(dataRecordFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const hiddenPostsSuccess = () => {
  return {
    type: ActionTypes.HIDDEN_POST_SUCCESS,
  };
};

const hiddenPostsFail = () => {
  return {
    type: ActionTypes.HIDDEN_POST_FAILURE,
  };
};

const hiddenDataRecord = (data: any, id: any, filter: any) => {
  return async (dispatch: any) => {
    dispatch(dataRecordLoading());
    await API.updateDataRecord({ ...data, status: "hidden" }, id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          await responseAlert({ res, label: "Posts hidden" });
          dispatch(hiddenPostsSuccess());
          dispatch(filterDataRecordInPost(filter));
        } else {
          responseAlert({ res });
          dispatch(hiddenPostsFail());
        }
      })
      .catch((e: any) => {
        dispatch(hiddenPostsFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const unhiddenPostsSuccess = () => {
  return {
    type: ActionTypes.UNHIDDEN_POST_SUCCESS,
  };
};

const unhiddenPostsFail = () => {
  return {
    type: ActionTypes.UNHIDDEN_POST_FAILURE,
  };
};

const unhiddenDataRecord = (data: any, filter: any) => {
  return async (dispatch: any) => {
    dispatch(dataRecordLoading());
    await API.unHiddenPost(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          await responseAlert({ res, label: "Data record was unhidden" });
          dispatch(unhiddenPostsSuccess());
          dispatch(searchDataRecord(filter));
        } else {
          responseAlert({ res });
          dispatch(unhiddenPostsFail());
        }
      })
      .catch((e: any) => {
        dispatch(unhiddenPostsFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const hiddenMultiPostsSuccess = () => {
  return {
    type: ActionTypes.HIDDEN_MULTIPLE_POSTS_SUCCESS,
  };
};

const hiddenMultiPostsFail = () => {
  return {
    type: ActionTypes.HIDDEN_MULTIPLE_POSTS_FAILURE,
  };
};

const hiddenDataRecords = (data: any, filter: any) => {
  return async (dispatch: any) => {
    dispatch(dataRecordLoading());
    await API.hiddenPosts({ ids: data })
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          await responseAlert({ res, label: "Posts hidden " });
          dispatch(hiddenMultiPostsSuccess());
          dispatch(filterDataRecordInPost(filter));
        } else {
          responseAlert({ res });
          dispatch(hiddenMultiPostsFail());
        }
      })
      .catch((e: any) => {
        dispatch(hiddenMultiPostsFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

export {
  clearDataRecordForUser,
  createDataRecord,
  fetchDataRecords,
  getDataRecordByID,
  searchDataRecord,
  updateDataRecord,
  deleteDataRecord,
  clearDataRecord,
  clearDataRecords,
  filterDataRecordInPost,
  getDataRecordForFolderById,
  previewDataRecordBeforeCreate,
  sendReportByEmail,
  getDataRecordForUserPortal,
  getPreviewDataRecordsForUser,
  sendCheckedPostToMail,
  setDefaultSendPostStatus,
  sendAttachmentToMail,
  hiddenDataRecord,
  unhiddenDataRecord,
  hiddenDataRecords,
  clearHidePost,
};
