import React, { useState } from "react";
import { ComponentCheckBox } from "./CheckboxStyle.styles";

interface SectionProps {
  margin?: string;
  width?: string;
  context?: string;
  checked?: boolean;
  onChangeHandler?: (checked: boolean, callbackPayload?: any) => void;
  color?: string;
  disabled?: boolean;
}

const CheckBox: React.FC<SectionProps> = (props: SectionProps) => {
  const { margin, context, checked, onChangeHandler, color, disabled, width } = props;
  const [callbackPayload, setCallbackPayload] = useState("");

  const handleChange = (checked: boolean) => {
    if (onChangeHandler) {
      if (!callbackPayload) onChangeHandler(checked);
      else onChangeHandler(checked, callbackPayload);
    }
    setCallbackPayload("temp");
  };

  return (
    <ComponentCheckBox
      className={`${disabled ? "disabled" : ""}`}
      color={color}
      margin={margin}
      width={width}
    >
      <label>
        <input
          type="checkbox"
          onChange={(e) => handleChange(e.target.checked)}
          checked={checked}
          disabled={disabled}
        />
        <span className="checkmark" />
        <span>{context}</span>
      </label>
    </ComponentCheckBox>
  );
};

export default CheckBox;
