import styled from "styled-components";
import ColorName from "constants/Variables";

interface SectionProps {
  background?: string;
}

const ProfileContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 800px;
  padding: 0 80px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 200px 1fr;
  padding: 15px 0;
`;

const TitlePage = styled.h2`
  font-size: 30px;
  margin: 10px 0 20px 0;
`;

const FormRow = styled.div`
  display: flex;

  &.p-1 {
    margin: 1rem 0;
  }
`;

const InputItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 10px;

  &:nth-child(2) {
    margin-left: 20px;
  }
`;

const LabelInput = styled.label`
  /* color: ${ColorName.william}; */
  font-weight: 600;
  margin-bottom: 5px;

  span {
    color: ${ColorName.burntSienna};
  }
`;

const Input = styled.input`
  height: 25px;
  font-size: 14px;
  padding: 0 5px;
`;

const Button = styled.div`
  width: max-content;
  height: auto;
  padding: 5px 10px;
  transition: all ease 0.25s;

  color: black;
  background-color: ${ColorName.white};
  border: 1px solid black;
  margin-right: 10px;
  border-radius: 5px;
  padding: 7px 20px;

  &:hover {
    background-color: black;
    color: white;
    cursor: pointer;
  }

  &.primary-btn {
    background-color: ${ColorName.burntSienna};
    border: 1px solid ${ColorName.burntSienna};
    color: white;

    &:hover {
      background-color: ${ColorName.piper};
    }
  }
`;

const DropdownSelect = styled.div`
  width: 100%;
  height: 29px;
  border: 1px solid rgb(133, 133, 133);
  border-radius: 2px;
  box-sizing: border-box;
  user-select: none;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`;

const DropdownOption = styled.div`
  padding: 0 5px;
  height: 26px;
  transition: all ease 0.25s;

  &.active {
    background: black;
    color: ${ColorName.white};
  }

  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.6);
    color: ${ColorName.white};
  }
`;

const ProfileMenu = styled.div`
  padding: 10px 0;
  height: max-content;
  margin-right: 25px;
`;
const ProfileMenuItem = styled.div`
  width: 100%;
  font-size: 15px;
  padding: 5px 10px;
  box-sizing: border-box;
  position: relative;

  &:hover {
    background: rgba(208, 215, 222, 0.24);
    cursor: pointer;
  }
  &.active {
    font-weight: bold;
    background: rgba(208, 215, 222, 0.24);
    &:before {
      content: "";
      position: absolute;
      width: 3px;
      height: 100%;
      right: 100%;
      top: 0;
      background: ${ColorName.william};
    }
  }
`;

const ProfileColumn = styled.div``;

const Avatar = styled.div`
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 50%;
  background: url(${(props: SectionProps) => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  input {
    display: none;
  }

  & .edit-btn {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 17px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 50%;
    transition: all ease 0.25s;
    &:hover {
      cursor: pointer;
      background: black;
      color: white;
    }
  }
`;

const DropdownLabel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 5px;
  box-sizing: border-box;
`;

const DropdownMenu = styled.div`
  width: 100%;
  left: 0;
  border: 1px solid rgb(133, 133, 133);
  top: calc(100% + 5px);
  position: absolute;
  border-radius: 2px;
  background: ${ColorName.white};
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 200px;
`;

export {
  ProfileContainer,
  DropdownLabel,
  DropdownMenu,
  FormRow,
  InputItem,
  LabelInput,
  Button,
  Input,
  TitlePage,
  DropdownSelect,
  DropdownOption,
  ProfileMenu,
  ProfileMenuItem,
  ProfileColumn,
  Avatar,
};
