/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { VerticalContainer, Loading, FloatAction } from "components/Common";
import {
  DefaultLayoutContainer,
  Container,
  ContainerMenu,
  Content,
  ContainerContent,
} from "./DefaultLayout.styles";
import Navigation from "../Layout/Navigation";
import SideMenu from "../Layout/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import history from "../../../history";
import { clearAllSavedData, getSavedUserData } from "libs/Utils";
import _ from "lodash";
import API from "api";
import { AuthenticationActions, MessageActions } from "actions";
import io from "socket.io-client";
import dayjs from "dayjs";
import { logout } from "../../../actions/Authentication.action";
import Disconnect from "../../Common/Disconnect";

interface SectionProps {
  content: JSX.Element;
  currentPage?: string;
  title?: string;
  subTitle?: string;
  parent?: string;
  loading?: boolean;
  onScrollBottom?(): void;
}

const {
  fetchMessages,
  setTotalNewMessages,
  setAllMessages,
  setDirectMessages,
} = MessageActions;
const { userLogOut } = AuthenticationActions;
const socket = io(process.env.REACT_APP_BE_URL || "");

const DefaultLayout: React.FC<SectionProps> = (props: SectionProps) => {
  const dispatch = useDispatch();

  const isLogged = useSelector((state: RootState) =>
    _.get(state, "Authentication.isLogged")
  );
  const allMessages = useSelector((state: RootState) =>
    _.get(state, "Message.allMessages")
  );
  const messageDirectory = useSelector((state: RootState) =>
    _.get(state, "Message.messageDirectory")
  );
  const {
    content,
    loading,
    currentPage,
    title,
    parent,
    subTitle,
    onScrollBottom,
  } = props;
  const mainRef = useRef<HTMLInputElement>(null);
  const [newMessage, setNewMessage] = useState<any>({});
  const [pos, setPos] = useState(false);
  const [isDisconnect, setIsDisconnect] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(newMessage)) {
      const newAllMessages = {
        ...allMessages,
      };
      const roomId = _.get(newMessage, "roomId");
      _.assign(newAllMessages, { [roomId]: newMessage });
      let totalNewMessage = 0;
      _.forEach(newAllMessages, (message) => {
        const status = _.get(message, "status");
        if (_.lowerCase(status) === "new") totalNewMessage += 1;
      });
      dispatch(setTotalNewMessages(totalNewMessage));
      dispatch(setAllMessages(newAllMessages));
      const currentDate = dayjs().format("DD/MM/YYYY");
      const cloneMessages = _.cloneDeep(messageDirectory);
      if (cloneMessages[currentDate])
        _.assign(cloneMessages, {
          [currentDate]: [...cloneMessages[currentDate], newMessage],
        });
      else
        _.assign(cloneMessages, {
          [currentDate]: [newMessage],
        });
      dispatch(setDirectMessages(cloneMessages));
    }
  }, [newMessage]);

  const handleShowFloat = () => {
    if (mainRef.current != null && mainRef.current.scrollTop > 50) {
      if (!pos) setPos(true);
    } else setPos(false);
  };

  useEffect(() => {
    const userId = _.get(getSavedUserData(), "id");
    socket.on("connect", () => setIsDisconnect(false));
    socket.on("disconnect", () => setIsDisconnect(true));
    socket.emit("connectToServer", userId);
    socket.on("receiveMessage", (payload) => {
      setNewMessage(payload);
    });
    dispatch(fetchMessages());

    const onChangeStorage = () => {
      const userData = getSavedUserData();
      const isUserRouter = _.includes(window.location.pathname, "article");
      if (_.isEmpty(userData) && isLogged) {
        if (isUserRouter) dispatch(userLogOut());
        else dispatch(logout());

        clearAllSavedData();
      }
    };
    onChangeStorage();

    window.addEventListener("storage", onChangeStorage);
  }, []);

  useEffect(() => {
    const token = API.getToken();
    if (!isLogged && !token) {
      return history.push("/");
    }
  }, [isLogged]);

  useEffect(() => {
    let temp: HTMLElement;
    if (mainRef.current != null) {
      temp = mainRef.current;
      temp.addEventListener("scroll", handleShowFloat);
    }
    return () => temp.removeEventListener("scroll", handleShowFloat);
  });

  const handleScroll = async (e: any) => {
    e.preventDefault();
    const node = e.currentTarget;
    const isAtBottom =
      node.scrollHeight - node.clientHeight <= node.scrollTop + 10;
    if (isAtBottom && onScrollBottom) onScrollBottom();
  };

  const handleTop = () => {
    if (mainRef.current != null) {
      mainRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setPos(false);
    }
  };
  return (
    <>
      <DefaultLayoutContainer>
        {loading && <Loading />}
        {isDisconnect && <Disconnect />}
        <Container>
          <ContainerMenu>
            <SideMenu currentPage={currentPage} parent={parent} />
          </ContainerMenu>
          <ContainerContent>
            <VerticalContainer>
              <Navigation title={title} subTitle={subTitle} />
              <Content ref={mainRef} onScroll={(e) => handleScroll(e)}>
                {content}
              </Content>
            </VerticalContainer>
          </ContainerContent>
        </Container>
        <FloatAction callback={handleTop} pos={pos} />
      </DefaultLayoutContainer>
    </>
  );
};

export default DefaultLayout;
