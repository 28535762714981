/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { CONSTANTS } from "constants/Constants";
import RouteTypes from "constants/RouteTypes";
import ColorName from "constants/Variables";

import { RootState } from "reducers";
import { CategoriesActions } from "actions";
import TableUtils from "libs/TableUltils";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Table,
  Input,
  Button,
  Checkbox,
  ContainerSearch,
  FlexWrapRow,
  Dropdown,
} from "components/Common";
import history from "../../../history";
import { generateFilter, popupAlert } from "libs/Utils";
import { LogsDetails } from "components/Common/Popup";

const {
  searchCategories,
  activateCategory,
  deactivateCategory,
  clearCategories,
} = CategoriesActions;

interface FilterStructure {
  keyword: string;
  status: string;
  language: string;
  page: number;
  limit: number;
  sortBy: string;
  orderBy: string;
}

const DEFAULT_FILTER = {
  keyword: "",
  status: CONSTANTS.STATUS.ACTIVE,
  page: 1,
  limit: 10,
  language: "en",
  sortBy: "updatedAt",
  orderBy: "DESC",
};

const LANGUAGE_OPTIONS = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "Albanian",
    value: "al",
  },
];

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "10px 10px 10px 0",
};

const Categories = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const location = useLocation();
  const categoriesIsLoading = useSelector((state: RootState) => {
    return state.Categories.isLoading;
  });
  const categories = useSelector((state: RootState) => {
    return state.Categories.categories;
  });

  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const [logData, setLogData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataCategory, setDataCategory] = useState<any>([]);

  useEffect(() => {
    return () => {
      dispatch(clearCategories());
    };
  }, []);

  useEffect(() => {
    const stateFilter: any = _.get(location, "state.filter");
    const prevRoute = _.get(location, "state.from");
    const resolveFilter = {
      ...filter,
    };
    if (
      stateFilter &&
      prevRoute === RouteTypes.ADMIN_ROUTES.CATEGORIES_DETAILS
    ) {
      _.assign(resolveFilter, { ...stateFilter });
      history.replace({ ...history.location, state: {} });
    }
    setFilter(resolveFilter);
    dispatch(searchCategories(generateFilter(resolveFilter)));
  }, [dispatch]);

  const dataHead = [
    "no",
    "name",
    "status",
    "createdAt",
    "updatedAt",
    "actions",
  ];

  const dataTable = TableUtils._processDataTable(
    [
      "no",
      "id",
      `name.${_.get(filter, "language")}`,
      "status",
      "createdAt",
      "updatedAt",
      "action",
    ],
    dataCategory
  );

  const listColumnCenter = [3, 6];

  useEffect(() => {
    if (!_.isEmpty(categories)) {
      const getDataCategory: any[] = _.get(categories, "items");
      if (currentPage > 1) {
        setDataCategory([...dataCategory, ...getDataCategory]);
      } else setDataCategory(getDataCategory);
    }
  }, [categories]);

  const callbackCurrentPageLink = (page: number) => {
    const newFilter = {
      ...filter,
      page,
    };
    setFilter(newFilter);
    dispatch(searchCategories(generateFilter(newFilter)));
  };

  const callbackAction = async (action: string, id: string, item: any) => {
    if (action === CONSTANTS.ACTION.EDIT) {
      history.push({
        pathname: RouteTypes.ADMIN_ROUTES.CATEGORIES_DETAILS,
        state: { id, filter },
      });
    }
    if (action === CONSTANTS.ACTION.LOG)
      setLogData({ logType: "category", rowId: id });

    if (action === CONSTANTS.ACTION.DEACTIVATE) {
      const categoryName = _.get(item, "name.en");
      const status = _.get(item, "status");
      const statusLabel =
        status === CONSTANTS.STATUS.ACTIVE
          ? t("alert.deactive")
          : t("alert.active");
      const content = document.createElement("span");
      content.innerHTML = `${t(
        "alert.areYouSureYouWantTo"
      )}</br> <b>${statusLabel}</b> ${t(
        "alert.theCategory"
      )} <b>${categoryName}</b> ?`;
      popupAlert({
        type: "warning",
        title: t("alert.warning"),
        content,
        dangerMode: true,
        buttons: true,
      }).then(async (willContinue) => {
        if (willContinue) {
          if (status === CONSTANTS.STATUS.ACTIVE)
            await dispatch(deactivateCategory(id));
          else await dispatch(activateCategory(id));
          const itemCount = _.get(categories, "meta.itemCount");
          const currentPage = _.get(categories, "meta.currentPage");
          let newCurrentPage = _.get(filter, "page");
          if (itemCount === 1) {
            if (currentPage === 1) newCurrentPage = 1;
            if (currentPage > 1) newCurrentPage = currentPage - 1;
          }
          await dispatch(
            searchCategories({ ...filter, status, currentPage: newCurrentPage })
          );
        }
      });
    }
  };

  const showMore = () => {
    if (
      !categoriesIsLoading &&
      currentPage < _.get(categories, "meta.totalPages")
    )
      getSearchCategories(filter.page + 1);
  };

  const _renderTable = () => {
    return (
      <Table
        dataHeader={dataHead}
        dataTable={dataTable}
        listActionItem={[
          CONSTANTS.ACTION.EDIT,
          CONSTANTS.ACTION.DEACTIVATE,
          CONSTANTS.ACTION.LOG,
        ]}
        callBackAction={callbackAction}
        fitColumns={[1, 3, 4, 5, 6]}
        listColumnCenter={listColumnCenter}
        hasPagination={true}
        callbackCurrentPageLink={callbackCurrentPageLink}
        metaPagination={categories?.meta}
        loading={categoriesIsLoading}
        showMore={showMore}
      />
    );
  };

  const setValueStatus = (status: string) => {
    const newFilter = {
      ...filter,
      status,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(searchCategories(generateFilter(newFilter)));
    setCurrentPage(1);
  };

  const onReset = async () => {
    setCurrentPage(1);
    setFilter(DEFAULT_FILTER);
    dispatch(searchCategories(generateFilter(DEFAULT_FILTER)));
  };

  const onCreate = () => {
    history.push({
      pathname: RouteTypes.ADMIN_ROUTES.CATEGORIES_DETAILS,
      state: { filter },
    });
  };

  const getSearchCategories = (page: number) => {
    const newFilter = {
      ...filter,
      page: page,
    };
    setFilter(newFilter);
    dispatch(searchCategories(newFilter));
    setCurrentPage(page);
  };

  const onFetchMore = () => {
    if (
      !categoriesIsLoading &&
      currentPage < _.get(categories, "meta.totalPages")
    )
      getSearchCategories(filter.page + 1);
  };

  const renderMain = () => {
    return (
      <>
        {!_.isEmpty(logData) && (
          <LogsDetails onClose={() => setLogData({})} data={logData} />
        )}
        <ContainerSearch className={categoriesIsLoading ? "loading" : ""}>
          <FlexWrapRow>
            <Input
              margin="10px 10px 10px 0"
              placeholder={t("placeholder.categoryName")}
              width="200px"
              value={_.get(filter, "keyword")}
              onChangeHandler={(e: any) =>
                setFilter({ ...filter, keyword: e.target.value })
              }
              onEnter={() => getSearchCategories(1)}
            />
            <Dropdown
              value={_.get(filter, "language")}
              options={LANGUAGE_OPTIONS}
              placeholder={t("placeholder.language")}
              callbackPayload={(e: any) =>
                setFilter({ ...filter, language: e })
              }
              width="130px"
              margin="10px 10px 10px 0"
            />
            <Button
              onClick={() => getSearchCategories(1)}
              {...ButtonStyleProps}
            >
              {t("button.search")}
            </Button>
            <Button onClick={() => onReset()} {...ButtonStyleProps}>
              {t("button.reset")}
            </Button>
            <Button
              onClick={() => onCreate()}
              {...ButtonStyleProps}
              background={ColorName.william}
            >
              + {t("button.create")}
            </Button>
          </FlexWrapRow>
          <Checkbox
            checked={_.get(filter, "status") === CONSTANTS.STATUS.INACTIVE}
            context={t("label.showInactiveCategories")}
            onChangeHandler={(e: boolean) =>
              setValueStatus(
                e ? CONSTANTS.STATUS.INACTIVE : CONSTANTS.STATUS.ACTIVE
              )
            }
            margin="10px 0 0 0"
          />
        </ContainerSearch>
        {_renderTable()}
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.ADMIN_ROUTES.CATEGORIES}
      parent={RouteTypes.ADMIN_ROUTES.MANAGE_SOURCES}
      title={t("titlePage.categories")}
      onScrollBottom={() => onFetchMore()}
    />
  );
};

export default Categories;
