import { useEffect } from "react";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import history from "../history";
import swal from "sweetalert";
import _ from "lodash";
import { CONSTANTS, ROUTES } from "constants/Constants";

const SAVED_NAME_KEY = "@MMS:name";
const SAVED_CLIENT_ID = "@MMS:client_id";
// const SAVED_PACKAGE_ID = "@MMS:package_id";
// const SAVED_USER_ID = "@MMS:user_id";
const SAVED_USER_DATA_KEY = "@MMS:udata";
const SAVED_SECURE_TOKEN = "@MMS:secure_token";
const SAVED_SECURE_REFRESH_TOKEN = "@MMS:secure_refresh_token";
const SAVED_REMEMBER_ME = "@MMS:remember";
const SAVED_LANGUAGE = "@MMS:language";

const cookies = new Cookies();
const saveToken = (token: string) => {
  cookies.set(SAVED_SECURE_TOKEN, token, { path: "/" });
};

const getSavedToken = () => {
  const token = cookies.get(SAVED_SECURE_TOKEN);
  return token;
};

const saveRefreshToken = (token: string) => {
  cookies.set(SAVED_SECURE_REFRESH_TOKEN, token, {
    path: "/",
    maxAge: 60 * 60 * 24,
  });
};

const getSavedRefreshToken = () => {
  const token = cookies.get(SAVED_SECURE_REFRESH_TOKEN);
  return token;
};

const saveLanguage = (lang: string) => {
  localStorage.setItem(SAVED_LANGUAGE, JSON.stringify(lang));
};

const getSavedLanguage = () => {
  const lang = localStorage.getItem(SAVED_LANGUAGE);
  if (lang) return JSON.parse(lang);
  return "en";
};

const saveRememberMe = (isRemember: boolean) => {
  localStorage.setItem(SAVED_REMEMBER_ME, JSON.stringify(isRemember));
};

const getSavedRememberMe = () => {
  const isRemember = localStorage.getItem(SAVED_REMEMBER_ME);
  if (isRemember !== "undefined" && isRemember) return JSON.parse(isRemember);

  return false;
};

const authenticationValidate = (params: any) => {
  const token = cookies.get(SAVED_SECURE_TOKEN);
  if (token) {
    const decodedToken: any = jwt_decode(token);
    const dateNow = new Date();
    if (decodedToken.exp < Math.floor(dateNow.getTime() / 1000)) {
      clearAllSavedData();
      return history.push(ROUTES.AUTHENTICATION);
    }
    params.found(token);
  } else {
    params.notFound();
  }
};

const saveUserName = (name: string) => {
  if (name) {
    const firstName = name.split(" ")[0];
    cookies.set(SAVED_NAME_KEY, firstName, { path: "/" });
  }
};

const getSavedUserName = () => {
  return cookies.get(SAVED_NAME_KEY);
};

const saveClientId = (clientId: string) => {
  if (clientId) {
    cookies.set(SAVED_CLIENT_ID, clientId, { path: "/" });
  }
};

const getClientId = () => {
  return cookies.get(SAVED_CLIENT_ID);
};

const getSavedUserData = () => {
  const userData = localStorage.getItem(SAVED_USER_DATA_KEY);
  if (userData) return JSON.parse(userData);
  return null;
};

const saveUserData = (userData: any) => {
  if (userData) {
    let getUserData = { ...userData };
    localStorage.setItem(SAVED_USER_DATA_KEY, JSON.stringify(getUserData));
  }
};

const clearAllSavedData = async () => {
  cookies.remove(SAVED_SECURE_REFRESH_TOKEN, { path: "/" });
  cookies.remove(SAVED_SECURE_TOKEN, { path: "/" });
  cookies.remove(SAVED_NAME_KEY, { path: "/" });
  localStorage.removeItem(SAVED_USER_DATA_KEY);
};

const popupAlert = (data: any) => {
  const { type, title, text, buttons, dangerMode, content } = data;
  return new Promise((resolve) => {
    swal({
      title,
      text,
      icon: type,
      content,
      buttons,
      dangerMode,
    }).then((response: any) => {
      resolve(response);
    });
  });
};

const useOutsideClick = (ref: any, callback: any) => {
  const handleClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

const processDataDropDown = (data: any) => {
  const results = _.map(data, (item, key) => {
    const status = _.get(item, "status");
    if (status === CONSTANTS.STATUS.ACTIVE) {
      const name = _.get(item, "name");
      if (_.isObject(name)) return { label: item.name.en, value: item.id };
      else return { label: item.name, value: item.id };
    }
    return null;
  });
  return _.compact([{ label: "--All--", value: "all" }, ...results]);
};

const processDataDropDownClient = (data: any) => {
  return _.map(data, (item, key) => {
    return { label: item.clientName, value: item.id };
  });
};

const processDataDropDownAccount = (data: any) => {
  return _.map(data, (item, key) => {
    return { label: item.accountName, value: item.id };
  });
};

const processDataDropDownRole = (data: any) => {
  const roleNotAccepted = ["super admin", "account", "client"];
  return _.without(
    _.map(data, (item, key) => {
      if (!roleNotAccepted.includes(item.name))
        return { label: _.capitalize(item.name), value: item.id };
    }),
    undefined
  );
};

const processDataDropDownUser = (data: any) => {
  return _.map(data, (item, key) => {
    return { label: item.email, value: item.id };
  });
};

const processDataDropDownFilters = (data: any) => {
  return _.map(data, (item, key) => {
    return { label: item.filterName, value: item.id };
  });
};

const processDataDropDownFolders = (data: any) => {
  return _.map(data, (item, key) => {
    return { label: item.folderName, value: item.id };
  });
};

const processDataDropDownReports = (data: any) => {
  return _.map(data, (item, key) => {
    return { label: item.reportName, value: item.id };
  });
};

const camelToSentence = (text: string) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const generateDropdown = (payload: {
  data: any[];
  key: string;
  value: string;
}) => {
  const { data, key, value } = payload;
  const lang = getSavedLanguage();

  if (_.isEmpty(data)) return [];

  const resolveData: any[] = [];
  _.map(data, (item) => {
    let itemKey = _.get(item, key);
    let itemName = "";

    if (_.isObject(itemKey)) itemName = _.get(itemKey, lang);
    if (_.isString(itemKey)) itemName = itemKey;

    const itemValue = _.get(item, value);
    resolveData.push({ label: itemName, value: itemValue });
  });

  return resolveData;
};

const validateData = (
  data: { label: string; key: string; value: string | number }[]
) => {
  const resolveRequired = {};
  let alertMessage = "";
  _.map(data, (item) => {
    const { value, key, label } = item;
    if (_.isString(value) && _.isEmpty(_.trim(value))) {
      _.assign(resolveRequired, { [key]: true });
      alertMessage += `${label}\n`;
    }
  });
  if (!_.isEmpty(alertMessage))
    popupAlert({
      type: "warning",
      title: "Warning",
      text: `Found empty field(s)\n${alertMessage}`,
    });
  return resolveRequired;
};

const generateFilter = (payload: any) => {
  const resolveFilter: any = {};
  _.forEach(payload, (item, key) => {
    if (key === "mediaType" && item === "all") return;
    if (
      (_.isString(item) && !_.isEmpty(_.trim(item))) ||
      item ||
      key === "status"
    )
      _.assign(resolveFilter, { [key]: item });
  });
  return resolveFilter;
};

const PASSWORD_ALERT = {
  en: {
    passwordContains: "You password contains",
    atLeastOneDigit: "at least one digit",
    atLeastOneUpperCase: "at least one uppercase",
    atLeastOneLowerCase: "at least one lowercase",
    atLeastOneSpecialChar: "at least one special character",
    passwordNotContainsSpace: "Password must not contain spaces",
    atLeast: "at least ",
    character: "character",
    warning: "Warning",
    passwordNotSame:
      "Password and confirm password are not same! Please retype.",
    and: "and",
  },
  al: {
    passwordContains: "Fjalekalimi juaj permban",
    atLeastOneDigit: "te pakten nje shifer",
    atLeastOneUpperCase: "te pakten nje shkronje te madhe",
    atLeastOneLowerCase: "te pakten nje shkronje te vogel",
    atLeastOneSpecialChar: "te pakten nje personazh te vecante",
    atLeast: "te pakten",
    character: "karakter",
    warning: "Paralajmerim",
    passwordNotContainsSpace: "Fjalekalimi nuk duhet te permbaje hapesira",
    passwordNotSame:
      "Fjalekalimi dhe fjalekalimi i konfirmimit nuk jane te njejta! Ju lutem rishkruajeni.",
    and: "dhe",
  },
};

const validatePassword = (data: {
  password: { label: string; value: string };
  confirmPassword?: { label: string; value: string };
  rules: {
    atLeastChars?: number;
    atLeastOneUpperCase?: boolean;
    atLeastOneLowerCase?: boolean;
    atLeastOneDigit?: boolean;
    atLeastSpecialCharacter?: boolean;
  };
}) => {
  const lang = localStorage.getItem("i18nextLng") || "en";
  const { password, confirmPassword, rules } = data;
  const {
    atLeastChars,
    atLeastOneDigit,
    atLeastOneLowerCase,
    atLeastOneUpperCase,
    atLeastSpecialCharacter,
  } = rules;
  let message = "Your password contains</br>";
  let messages = [];
  const defaultPassword = _.get(password, "value");
  const valuePassword = _.trim(defaultPassword);

  if (defaultPassword.length !== valuePassword.length) {
    popupAlert({
      type: "warning",
      title: _.get(PASSWORD_ALERT, `${lang}.warning`),
      text: _.get(PASSWORD_ALERT, `${lang}.passwordNotContainsSpace`),
    });
    return false;
  }

  if (atLeastOneDigit) {
    const digitRegex = /(?=.*?[0-9])/;
    const isNotValid = !digitRegex.test(valuePassword);
    if (isNotValid)
      messages.push(_.get(PASSWORD_ALERT, `${lang}.atLeastOneDigit`));
  }
  if (atLeastOneLowerCase) {
    const lowerCaseRegex = /(?=.*?[a-z])/;
    const isNotValid = !lowerCaseRegex.test(valuePassword);
    if (isNotValid)
      messages.push(_.get(PASSWORD_ALERT, `${lang}.atLeastOneLowerCase`));
  }
  if (atLeastOneUpperCase) {
    const upperCaseRegex = /(?=.*?[A-Z])/;
    const isNotValid = !upperCaseRegex.test(valuePassword);
    if (isNotValid)
      messages.push(_.get(PASSWORD_ALERT, `${lang}.atLeastOneUpperCase`));
  }
  if (atLeastSpecialCharacter) {
    const specialCharRegex = /(?=.*?[#?!@$%^&*-])/;
    const isNotValid = !specialCharRegex.test(valuePassword);
    if (isNotValid)
      messages.push(_.get(PASSWORD_ALERT, `${lang}.atLeastOneSpecialChar`));
  }
  const miniumCharRegex = new RegExp(`.{${atLeastChars || 6},}$`);
  const isNotValid = !miniumCharRegex.test(valuePassword);
  if (isNotValid)
    messages.push(
      `${_.get(PASSWORD_ALERT, `${lang}.atLeast`)} ${atLeastChars || 6} ${_.get(
        PASSWORD_ALERT,
        `${lang}.character`
      )}`
    );
  for (let i = 0; i < messages.length; i++) {
    message += ` <b>${messages[i]}</b>${i < messages.length - 2 ? "," : ""}${
      i === messages.length - 2
        ? ` ${_.get(PASSWORD_ALERT, `${lang}.and`)}`
        : ""
    }${i === messages.length - 1 ? "." : ""}`;
  }

  const content = document.createElement("span");
  content.innerHTML = message;

  if (!_.isEmpty(messages)) {
    popupAlert({
      type: "warning",
      title: _.get(PASSWORD_ALERT, `${lang}.warning`),
      content,
    });
    return false;
  }

  if (confirmPassword) {
    let isNotSamePwd =
      _.trim(_.get(password, "value")) !==
      _.trim(_.get(confirmPassword, "value"));
    if (isNotSamePwd) {
      popupAlert({
        type: "warning",
        title: _.get(PASSWORD_ALERT, `${lang}.warning`),
        text: _.get(PASSWORD_ALERT, `${lang}.passwordNotSame`),
      });
      return false;
    }
  }
  return true;
};

const trimObjectBeforeSubmit = (data: any) => {
  const resolveData = _.keys(data).forEach((k) => (data[k] = _.trim(data[k])));
  return resolveData;
};

export {
  saveRefreshToken,
  getSavedRefreshToken,
  saveToken,
  getSavedToken,
  authenticationValidate,
  saveUserName,
  getSavedUserName,
  saveUserData,
  getSavedUserData,
  clearAllSavedData,
  popupAlert,
  useOutsideClick,
  processDataDropDown,
  processDataDropDownRole,
  processDataDropDownUser,
  processDataDropDownClient,
  camelToSentence,
  processDataDropDownAccount,
  processDataDropDownFolders,
  processDataDropDownFilters,
  processDataDropDownReports,
  generateDropdown,
  saveRememberMe,
  getSavedRememberMe,
  validateData,
  saveLanguage,
  getSavedLanguage,
  generateFilter,
  validatePassword,
  trimObjectBeforeSubmit,
  saveClientId,
  getClientId,
};
