import styled from "styled-components";
import { HorizontalContainer } from "../CommonLayout";
import ColorName from "constants/Variables";

interface SectionProps {
  margin?: string;
  width?: string;
  isRequired?: boolean;
}

const ComponentInput = styled(HorizontalContainer)`
  width: ${(props: SectionProps) => props.width || "100%"};
  margin: ${(props: SectionProps) => props.margin || "unset"};
  position: relative;
  color: ${ColorName.william};
  & .label-required {
    position: absolute;
    z-index: 1;
    top: -10px;
    left: 1.1em;
    padding: 0 4px;
    /* background-color: white; */
    font-weight: 400;
    font-size: 12px;
    color: ${ColorName.william};
  }
  & .background-error {
    position: absolute;
    left: 1em;
    padding: 0 0.4em;
    font-size: 12px;
    line-height: 9px;
    color: transparent;
    background: white;
  }
  &[data-tip] {
    position: relative;
  }
  &[data-tip]:not([class*="disabled"]):before {
    content: "";
    /* hides the tooltip when not hovered */
    display: block;
    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid ${ColorName.william};
    position: absolute;
    top: calc(100% - 5px);
    left: 35px;
    z-index: 8;
    font-size: 0;
    line-height: 0;
    width: 0;
    height: 0;
  }
  &[data-tip]:not([class*="disabled"]):after {
    display: block;
    content: attr(data-tip);
    position: absolute;
    top: calc(100%);
    left: 0px;
    padding: 5px 8px;
    background: ${ColorName.william};
    color: ${ColorName.white};
    z-index: 9;
    font-size: 13px;
    height: 18px;
    line-height: 18px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    white-space: nowrap;
    word-wrap: normal;
  }
  &[data-tip]:not([class*="disabled"]):before,
  &[data-tip]:not([class*="disabled"]):after {
    transform: translateY(5px);
    opacity: 0;
    z-index: -1;
    transition: all ease 0.25s;
  }
  &.show-tooltip {
    &[data-tip]:before,
    &[data-tip]:after {
      transform: translateY(0px);
      opacity: 1;
    }
    &[data-tip]:before {
      z-index: 8;
    }
    &[data-tip]:after {
      z-index: 9;
    }
  }
`;

const InputMain = styled.input`
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding: 0.5em 1em;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  border: 1px solid
    ${(props: SectionProps) =>
      props.isRequired ? "#f85353" : ColorName.linkWater};
  box-shadow: 0px 0px 4px
    ${(props: SectionProps) =>
      props.isRequired ? "#f85353" : "rgb(62 138 182 / 25%)"};
  color: ${ColorName.william};
  height: 40px;
  font-family: "Be Vietnam";

  &:focus {
    border: 1px solid ${ColorName.william};
  }
  &:disabled {
    &:hover {
      cursor: not-allowed;
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  &#custom-file-input {
    border: none;
    box-shadow: none;
    padding: 0;
  }

  &#custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }

  &#custom-file-input::before {
    content: "Choose a file";
    display: inline-block;
    border: 1px solid #999;
    border-radius: 3px;
    padding: 8px 12px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 600;
    background: ${ColorName.burntSienna};
    color: white;
  }
`;

const EyeButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    transition: all ease 0.15s;
    font-size: 14px;
    opacity: 0.6;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
    &.disabled:hover {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
`;

export { ComponentInput, InputMain, EyeButton };
