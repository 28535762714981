import Api from "./Base";
import { API } from "constants/Constants";

const { CATEGORIES } = API;

export function addCategory(data: any) {
  return Api.post(CATEGORIES.BASIC, data);
}

export function fetchAllCategories(data: any) {
  return Api.get(CATEGORIES.GET_ALL, data);
}

export function fetchCategories() {
  return Api.get(CATEGORIES.BASIC);
}

export function getCategoryByID(id: any) {
  return Api.get(`${CATEGORIES.BASIC}/${id}`);
}

export function searchCategories(data: any) {
  return Api.get(CATEGORIES.BASIC, data);
}

export function updateCategory(data: any, id: any) {
  return Api.put(`${CATEGORIES.BASIC}/${id}`, data);
}

export function deactivateCategory(id: any) {
  return Api.put(`${CATEGORIES.DEACTIVATE}/${id}`);
}

export function activateCategory(id: any) {
  return Api.put(`${CATEGORIES.ACTIVATE}/${id}`);
}
