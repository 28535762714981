import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "reducers";
import { UsersActions } from "actions";

import history from "../../history";

import { Images } from "themes";
import { ROUTES } from "constants/Constants";

import { Wrapper, Title } from "./Authentication.styles";
import { Input, Button, Loading } from "components/Common";

const { forgotPassword } = UsersActions;

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => {
    return state.Users.isLoading;
  });
  const isLogged = useSelector((state: RootState) => {
    return state.Authentication.isLogged;
  });

  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    if (isLogged) return history.push(ROUTES.HOMEPAGE);
  }, [isLogged]);

  const fotgotPassword = () => {
    dispatch(forgotPassword({ email }));
  };

  return (
    <Wrapper>
      <div className="container-login">
        <div className="header-login">
          <img src={Images.logo} alt="Media Monitoring system" />
        </div>
        {isLoading && <Loading />}
        <Title>Welcome</Title>
        <Input
          margin="10px 0"
          onChangeHandler={(e: any) => setEmail(e.target.value)}
          placeholder="Enter your email"
          onEnter={() => fotgotPassword()}
        />
        <Button
          onClick={() => fotgotPassword()}
          className="form-button"
          buttonMargin="10px 0"
        >
          Forgot Password
        </Button>
        <span
          className="link back"
          onClick={() => history.push(ROUTES.AUTHENTICATION)}
        >
          <i className="fas fa-arrow-left"></i> Back
        </span>
      </div>
    </Wrapper>
  );
};

export default ForgotPassword;
