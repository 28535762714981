import Api from "./Base";
import { API } from "constants/Constants";

const { CLIENT_USER } = API;

export function createClientUser(data: any) {
  return Api.postFormData(CLIENT_USER.REGISTER_USER, data);
}

export function fetchClientUsers() {
  return Api.get(CLIENT_USER.BASIC);
}

export function getClientUserByID(id: any) {
  return Api.get(`${CLIENT_USER.BASIC}/${id}`);
}

export function searchClientUsers(data: any) {
  return Api.get(CLIENT_USER.BASIC, data);
}

export function updateClientUser(data: any, id: any) {
  return Api.putFormData(`${CLIENT_USER.UPDATE}/${id}`, data);
}

export function deactivateClientUser(id: string) {
  return Api.put(`${CLIENT_USER.DEACTIVATE}/${id}`);
}

export function activateClientUser(id: string) {
  return Api.put(`${CLIENT_USER.ACTIVATE}/${id}`);
}

export function exportClientUsers() {
  return Api.getBlob(CLIENT_USER.EXPORT);
}
