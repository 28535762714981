import ActionTypes from "constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  reportDashboard: {},
  dashboardStatistical: null,
  sourceSettingsDashboard: [],
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.CLEAR_DASHBOARD:
      return {
        ...state,
        reportDashboard: {},
        dashboardStatistical: null,
      };
    case ActionTypes.DASHBOARD_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.DASHBOARD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        reportDashboard: payload,
      };

    case ActionTypes.GET_DASHBOARD_STATISTICAL_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.GET_DASHBOARD_STATISTICAL_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        dashboardStatistical: payload,
      };
    case ActionTypes.FETCH_DASHBOARD_SOURCE_SETTINGS_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        sourceSettingsDashboard: payload,
      };
    default:
      return state;
  }
};
