import ActionTypes from "constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  sourceSettings: [],
  sourceSetting: {},
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.CLEAR_SOURCES:
      return {
        ...state,
        sourceSettings: [],
      };
    case ActionTypes.CLEAR_SOURCE_DETAILS:
      return {
        ...state,
        sourceSetting: {},
      };
    case ActionTypes.SOURCE_SETTING_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.SOURCE_SETTING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.SOURCE_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        isSuccess: true,
      };
    case ActionTypes.CREATE_SOURCE_SETTING_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_SOURCE_SETTING_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        sourceSettings: payload,
      };
    case ActionTypes.GET_SOURCE_SETTING_BY_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        sourceSetting: payload,
      };
    case ActionTypes.UPDATE_SOURCE_SETTING_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        sourceSetting: payload,
      };
    default:
      return state;
  }
};
