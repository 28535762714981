import _ from "lodash";
import React, { useRef } from "react";
import EmailEditor, { Design } from "react-email-editor";
import { useTranslation } from "react-i18next";
import { Button } from "components/Common";

import {
  EmailEditorContainer,
  EmailEditorHeader,
  EmailEditorButton,
  EmailEditorContent,
  VariablesContainer,
  VariablesItem,
} from "./EmailEditor.styles";

interface SectionProps {
  onClose(html?: string, design?: Design): void;
  design: Design;
  variables: any;
}

const EmailTemplateEditor = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { onClose, design, variables } = props;
  const emailEditorRef = useRef<EmailEditor>(null);

  const exportHtml = () => {
    if (emailEditorRef.current)
      emailEditorRef.current.exportHtml((data) => {
        const { design, html } = data;
        if (onClose) onClose(html, design);
      });
  };

  const onReady = () => {
    if (emailEditorRef.current && _.has(design, "body"))
      emailEditorRef.current.loadDesign(design);
  };

  const onCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  const renderVariables = () => {
    const splitVariables = _.split(variables, "\n");
    const render = _.map(splitVariables, (variable) => {
      return (
        <VariablesItem
          title="Click to copy"
          key={variable}
          onClick={() => onCopy(variable)}
        >
          {variable}
        </VariablesItem>
      );
    });
    return (
      <VariablesContainer>
        <b>{t("label.variables")}</b>
        {render}
      </VariablesContainer>
    );
  };

  return (
    <EmailEditorContainer>
      <EmailEditorContent>
        {renderVariables()}
        <EmailEditorHeader>
          {t("headerPopup.emailTemplateEditor")}{" "}
        </EmailEditorHeader>
        <EmailEditor minHeight={550} ref={emailEditorRef} onReady={onReady} />
        <EmailEditorButton>
          <Button
            buttonWidth="100px"
            buttonMargin="10px 10px 10px 0"
            onClick={() => exportHtml()}
          >
            {t("button.save")}
          </Button>
          <Button
            buttonWidth="100px"
            buttonMargin="10px 10px 10px 0"
            onClick={() => onClose && onClose()}
          >
            {t("button.cancel")}
          </Button>
        </EmailEditorButton>
      </EmailEditorContent>
    </EmailEditorContainer>
  );
};

export default EmailTemplateEditor;
