import ActionTypes from "constants/ActionTypes";
import API from "api";
import _ from "lodash";
import { responseAlert } from "libs/alert";

const clearEmailTemplates = () => {
  return {
    type: ActionTypes.CLEAR_EMAIL_TEMPLATES,
  };
};

const clearEmailTemplateDetails = () => {
  return {
    type: ActionTypes.CLEAR_EMAIL_TEMPLATE_DETAILS,
  };
};

const emailTemplateFail = () => {
  return {
    type: ActionTypes.EMAIL_TEMPLATE_FAILURE,
  };
};

const emailTemplateLoading = () => {
  return {
    type: ActionTypes.EMAIL_TEMPLATE_REQUEST,
  };
};

const createEmailTemplateSuccess = () => {
  return {
    type: ActionTypes.CREATE_EMAIL_TEMPLATE_SUCCESS,
  };
};

const updateEmailTemplateSuccess = (payload = {}) => {
  return {
    type: ActionTypes.UPDATE_EMAIL_TEMPLATE_SUCCESS,
    payload,
  };
};

const fetchEmailTemplateSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_EMAIL_TEMPLATE_SUCCESS,
    payload,
  };
};

const getEmailTemplateByIdSuccess = (payload = {}) => {
  return {
    type: ActionTypes.GET_EMAIL_TEMPLATE_BY_ID_SUCCESS,
    payload,
  };
};

const searchEmailTemplateSuccess = (payload = {}) => {
  return {
    type: ActionTypes.SEARCH_EMAIL_TEMPLATE_SUCCESS,
    payload,
  };
};

const deactiveEmailTemplateSuccess = (payload = {}) => {
  return {
    type: ActionTypes.DEACTIVATE_EMAIL_TEMPLATE_SUCCESS,
    payload,
  };
};

const activateEmailTemplateSuccess = (payload = {}) => {
  return {
    type: ActionTypes.ACTIVATE_EMAIL_TEMPLATE_SUCCESS,
    payload,
  };
};

const getEmailTemplateByTypeSuccess = (payload = {}) => {
  return {
    type: ActionTypes.GET_EMAIL_TEMPLATE_BY_TYPE_SUCCESS,
    payload,
  };
};

const createEmailTemplate = (data: any) => {
  return async (dispatch: any) => {
    dispatch(emailTemplateLoading());
    await API.createMailTemplate(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(createEmailTemplateSuccess());
          responseAlert({ res, label: "Email template created" });
        } else {
          responseAlert({ res });
          dispatch(emailTemplateFail());
        }
      })
      .catch((e: any) => {
        dispatch(emailTemplateFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const updateEmailTemplate = (data: any) => {
  return async (dispatch: any) => {
    dispatch(emailTemplateLoading());
    await API.updateMailTemplate(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Email template updated" });
          dispatch(updateEmailTemplateSuccess(res.payload));
        } else {
          responseAlert({ res });
          dispatch(emailTemplateFail());
        }
      })
      .catch((e: any) => {
        dispatch(emailTemplateFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const searchEmailTemplate = (data: any) => {
  return async (dispatch: any) => {
    dispatch(emailTemplateLoading());
    await API.searchMailTemplate(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(searchEmailTemplateSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(emailTemplateFail());
        }
      })
      .catch((e: any) => {
        dispatch(emailTemplateFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchEmailTemplate = () => {
  return async (dispatch: any) => {
    dispatch(emailTemplateLoading());
    await API.fetchMailTemplate()
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchEmailTemplateSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(emailTemplateFail());
        }
      })
      .catch((e: any) => {
        dispatch(emailTemplateFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getEmailTemplateById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(emailTemplateLoading());
    await API.getMailTemplateById(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(getEmailTemplateByIdSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(emailTemplateFail());
        }
      })
      .catch((e: any) => {
        dispatch(emailTemplateFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const deactivateEmailTemplate = (id: string) => {
  return async (dispatch: any) => {
    dispatch(emailTemplateLoading());
    await API.deactivateMailTemplate(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Email template deactivated" });
          dispatch(deactiveEmailTemplateSuccess());
        } else {
          responseAlert({ res });
          dispatch(emailTemplateFail());
        }
      })
      .catch((e: any) => {
        dispatch(emailTemplateFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const activateEmailTemplate = (id: string) => {
  return async (dispatch: any) => {
    dispatch(emailTemplateLoading());
    await API.activateMailTemplate(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Email template activated" });
          dispatch(activateEmailTemplateSuccess());
        } else {
          responseAlert({ res });
          dispatch(emailTemplateFail());
        }
      })
      .catch((e: any) => {
        dispatch(emailTemplateFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getEmailTemplateByType = (id: string) => {
  return async (dispatch: any) => {
    dispatch(emailTemplateLoading());
    await API.getEmailTemplateByType(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status)
          dispatch(getEmailTemplateByTypeSuccess(_.get(res, "payload")));
        else {
          responseAlert({ res });
          dispatch(emailTemplateFail());
        }
      })
      .catch((e: any) => {
        dispatch(emailTemplateFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

export {
  clearEmailTemplateDetails,
  activateEmailTemplate,
  deactivateEmailTemplate,
  createEmailTemplate,
  fetchEmailTemplate,
  searchEmailTemplate,
  updateEmailTemplate,
  getEmailTemplateById,
  getEmailTemplateByType,
  clearEmailTemplates,
};
