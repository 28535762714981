import Api from "./Base";
import { API } from "constants/Constants";

const { DASHBOARD } = API;

export function reportDashboard() {
  return Api.get(DASHBOARD.BASIC);
}

export function getDashboardStatistical(payload: any) {
  return Api.post(DASHBOARD.STATIC, payload);
}
