import Api from "./Base";
import { API } from "constants/Constants";

const { ACCOUNT_OF_CLIENT } = API;

export function addAccountOfClient(data: any) {
  return Api.postFormData(ACCOUNT_OF_CLIENT.REGISTER, data);
}

export function fetchAccountsOfClient() {
  return Api.get(ACCOUNT_OF_CLIENT.BASIC);
}

export function getAccountOfClientByID(id: any) {
  return Api.get(`${ACCOUNT_OF_CLIENT.BASIC}/${id}`);
}

export function searchAccountsOfClient(data: any) {
  return Api.get(ACCOUNT_OF_CLIENT.BASIC, data);
}

export function updateAccountOfClient(data: any, id: any) {
  return Api.putFormData(`${ACCOUNT_OF_CLIENT.UPDATE}/${id}`, data);
}

export function deactivateAccountOfClient(id: string) {
  return Api.put(`${ACCOUNT_OF_CLIENT.DEACTIVATE}/${id}`);
}

export function activateAccountOfClient(id: string) {
  return Api.put(`${ACCOUNT_OF_CLIENT.ACTIVATE}/${id}`);
}

export function fetchAllAccount() {
  return Api.get(ACCOUNT_OF_CLIENT.FOR_USER_PORTAL);
}