const createActionMap = (actionList: any) => {
  const map: any = {};
  const actions = actionList.split("\n");
  actions.forEach((action: any) => {
    const name = action.trim();
    if (name !== "") {
      map[`${name}_REQUEST`] = `${name}_REQUEST`;
      map[`${name}_SUCCESS`] = `${name}_SUCCESS`;
      map[`${name}_FAILURE`] = `${name}_FAILURE`;
      map[`CREATE_${name}_SUCCESS`] = `CREATE_${name}_SUCCESS`;
      map[`UPDATE_${name}_SUCCESS`] = `UPDATE_${name}_SUCCESS`;
      map[`FETCH_${name}_SUCCESS`] = `FETCH_${name}_SUCCESS`;
      map[`SEARCH_${name}_SUCCESS`] = `SEARCH_${name}_SUCCESS`;
      map[`GET_${name}_BY_ID_SUCCESS`] = `GET_${name}_BY_ID_SUCCESS`;
      map[`DEACTIVATE_${name}_SUCCESS`] = `DEACTIVATE_${name}_SUCCESS`;
      map[`ACTIVATE_${name}_SUCCESS`] = `ACTIVATE_${name}_SUCCESS`;
    }
  });
  return map;
};

const asyncActions = createActionMap(`
  LOGIN
  LOGOUT
  REGISTER
  CATEGORY
  FILE_RECORD
  SOURCE_SETTING
  LOG
  USER
  DATA_RECORD
  REPORT
  CLIENT
  ACCOUNT
  ROLE
  FILTER
  FOLDER
  DISTRIBUTION_DELIVERY
  EMAIL_DELIVERY
  EMAIL_TEMPLATE
  PDF_TEMPLATE
  POST
  REPORT
  CLIENT_USER
  MEMBER
  DASHBOARD
  MESSAGE
  DASHBOARD_SOURCE_SETTINGS
  SYSTEM_CONFIG
`);

const singleActions = {
  IS_LOGGED: "IS_LOGGED",
  LOGIN_IS_LOADING: "LOGIN_IS_LOADING",
  LOGIN_HAS_ERROR: "LOGIN_HAS_ERROR",
  REFRESH_TOKEN_IS_LOADING: "REFRESH_TOKEN_IS_LOADING",
  REFRESH_TOKEN_HAS_ERROR: "REFRESH_TOKEN_HAS_ERROR",
  REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",

  LOGOUT: "LOGOUT",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  VERIFY_SUCCESS: "VERIFY_SUCCESS",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_FAILURE: "REGISTER_USER_FAILURE",
  FETCH_PRIVILEGES_SUCCESS: "FETCH_PRIVILEGES_SUCCESS",
  SEARCH_USER_SUCCESS: "SEARCH_USER_SUCCESS",
  FETCH_USERS_CLIENT_SUCCESS: "FETCH_USERS_CLIENT_SUCCESS",
  REGISTER_CLIENT_SUCCESS: "REGISTER_CLIENT_SUCCESS",
  FETCH_USERS_FOR_ACCOUNT_SUCCESS: "FETCH_USERS_FOR_ACCOUNT_SUCCESS",
  SEARCH_ACCOUNT_SUCCESS: "SEARCH_ACCOUNT_SUCCESS",
  DEACTIVATE_FILTER_SUCCESS: "DEACTIVATE_FILTER_SUCCESS",
  ACTIVATE_FILTER_SUCCESS: "ACTIVATE_FILTER_SUCCESS",
  FILTER_DATA_RECORD_IN_POST_SUCCESS: "FILTER_DATA_RECORD_IN_POST_SUCCESS",
  CLEAR_DATA_RECORD: "CLEAR_DATA_RECORD",
  CLEAR_DATA_RECORDS: "CLEAR_DATA_RECORDS",
  CLEAR_MAIL_DELIVERY: "CLEAR_MAIL_DELIVERY",
  CLEAR_FILTER_DETAILS: "CLEAR_FILTER_DETAILS",
  CLEAR_FOLDER_DETAILS: "CLEAR_FOLDER_DETAILS",
  GET_DATA_RECORD_FOR_FOLDER_SUCCESS: "GET_DATA_RECORD_FOR_FOLDER_SUCCESS",
  PREVIEW_DATA_RECORD_BEFORE_CREATE_SUCCESS:
    "PREVIEW_DATA_RECORD_BEFORE_CREATE_SUCCESS",
  CLEAR_REPORT_DETAILS: "CLEAR_REPORT_DETAILS",
  SEND_REPORT_BY_EMAIL_SUCCESS: "SEND_REPORT_BY_EMAIL_SUCCESS",
  CLEAR_EMAIL_TEMPLATE_DETAILS: "CLEAR_EMAIL_TEMPLATE_DETAILS",
  CLEAR_CATEGORY_DETAILS: "CLEAR_CATEGORY_DETAILS",
  CLEAR_ROLE_DETAILS: "CLEAR_ROLE_DETAILS",
  SET_USERS_FILTER: "SET_USERS_FILTER",
  CLEAR_USERS_FILTER: "CLEAR_USERS_FILTER",
  CLEAR_PACKAGE_DETAILS: "CLEAR_PACKAGE_DETAILS",
  GET_EMAIL_TEMPLATE_BY_TYPE_SUCCESS: "GET_EMAIL_TEMPLATE_BY_TYPE_SUCCESS",
  FETCH_PACKAGES_FOR_USER_PORTAL_SUCCESS:
    "FETCH_PACKAGES_FOR_USER_PORTAL_SUCCESS",
  FETCH_ALL_CLIENTS_SUCCESS: "FETCH_ALL_CLIENTS_SUCCESS",
  FETCH_DATA_RECORDS_FOR_USER_PORTAL_SUCCESS:
    "FETCH_DATA_RECORDS_FOR_USER_PORTAL_SUCCESS",
  CLEAR_CLIENTS_PAYLOAD: "CLEAR_CLIENTS_PAYLOAD",
  CLEAR_DATA_RECORD_FOR_USER: "CLEAR_DATA_RECORD_FOR_USER",
  CLEAR_MEMBER_DETAILS: "CLEAR_MEMBER_DETAILS",
  CLEAR_USERS_PAYLOAD: "CLEAR_USERS_PAYLOAD",
  CLEAR_USER_DETAILS: "CLEAR_USER_DETAILS",
  CLEAR_SOURCE_DETAILS: "CLEAR_SOURCE_DETAILS",
  SET_DEFAULT_REGISTER_RESULT: "SET_DEFAULT_REGISTER_RESULT",
  UPDATE_USER_FOR_SELF_SUCCESS: "UPDATE_USER_FOR_SELF_SUCCESS",
  SET_DEFAULT_LOGIN_RESULT: "SET_DEFAULT_LOGIN_RESULT",
  CLEAR_DASHBOARD: "CLEAR_DASHBOARD",
  CLEAR_FILE_RECORDS: "CLEAR_FILE_RECORDS",
  CLEAR_LOGS: "CLEAR_LOGS",
  CLEAR_CATEGORIES: "CLEAR_CATEGORIES",
  CLEAR_SOURCES: "CLEAR_SOURCES",
  CLEAR_PACKAGES: "CLEAR_PACKAGES",
  CLEAR_ROLES: "CLEAR_ROLES",
  CLEAR_FILTERS: "CLEAR_FILTERS",
  CLEAR_MAIL_DELIVERIES: "CLEAR_MAIL_DELIVERIES",
  CLEAR_EMAIL_TEMPLATES: "CLEAR_EMAIL_TEMPLATES",
  CLEAR_FOLDERS: "CLEAR_FOLDERS",
  REGISTER_CLIENT_USER_SUCCESS: "REGISTER_CLIENT_USER_SUCCESS",
  FETCH_DIRECT_MESSAGE_SUCCESS: "FETCH_DIRECT_MESSAGE_SUCCESS",
  REMOVE_MESSAGE_SUCCESS: "REMOVE_MESSAGE_SUCCESS",
  FETCH_MESSAGE_SUCCESS: "FETCH_MESSAGE_SUCCESS",
  SET_DEFAULT_UPDATE_PACKAGE_CODE: "SET_DEFAULT_UPDATE_PACKAGE_CODE",
  UPDATE_USER_PACKAGE_SUCCESS: "UPDATE_USER_PACKAGE_SUCCESS",
  SET_TOTAL_NEW_MESSAGES: "SET_TOTAL_NEW_MESSAGES",
  SEND_SELECTED_POST_SUCCESS: "SEND_SELECTED_POST_SUCCESS",
  SEND_SELECTED_POST_FAILURE: "SEND_SELECTED_POST_FAILURE",
  SET_DEFAULT_SEND_POST_STATUS: "SET_DEFAULT_SEND_POST_STATUS",
  ADD_POST_TO_FOLDER_SUCCESS: "ADD_POST_TO_FOLDER_SUCCESS",
  SEND_ATTACHMENT_TO_MAIL_SUCCESS: "SEND_ATTACHMENT_TO_MAIL_SUCCESS",
  SET_ALL_MESSAGES: "SET_ALL_MESSAGES",
  SET_DIRECT_MESSAGES: "SET_DIRECT_MESSAGES",
  CLEAR_ACCOUNT_PAYLOAD: "CLEAR_ACCOUNT_PAYLOAD",
  FETCH_ALL_MESSAGE_IS_LOADING: "FETCH_ALL_MESSAGE_IS_LOADING",
  SET_DEFAULT_ACCOUNT_STATUS: "SET_DEFAULT_ACCOUNT_STATUS",
  HIDDEN_POST_SUCCESS: "HIDDEN_POST_SUCCESS",
  HIDDEN_POST_FAILURE: "HIDDEN_POST_FAILURE",
  UNHIDDEN_POST_SUCCESS: "UNHIDDEN_POST_SUCCESS",
  UNHIDDEN_POST_FAILURE: "UNHIDDEN_POST_FAILURE",
  HIDDEN_MULTIPLE_POSTS_SUCCESS: "HIDDEN_MULTIPLE_POSTS_SUCCESS",
  HIDDEN_MULTIPLE_POSTS_FAILURE: "HIDDEN_MULTIPLE_POSTS_FAILURE",

  GET_DASHBOARD_STATISTICAL_SUCCESS: "GET_DASHBOARD_STATISTICAL_SUCCESS",
  GET_DASHBOARD_STATISTICAL_FAILURE: "GET_DASHBOARD_STATISTICAL_FAILURE",
  FETCH_REPORT_EMAIL_DISTIBUTION_LOADING:
    "FETCH_REPORT_EMAIL_DISTIBUTION_LOADING",
  FETCH_REPORT_EMAIL_DISTIBUTION_SUCCESS:
    "FETCH_REPORT_EMAIL_DISTIBUTION_SUCCESS",
  FETCH_REPORT_EMAIL_DISTIBUTION_FAILURE:
    "FETCH_REPORT_EMAIL_DISTIBUTION_FAILURE",
  GET_EMAIL_DISTIBUTION_SUCCESS: "GET_EMAIL_DISTIBUTION_SUCCESS",
  GET_EMAIL_DISTIBUTION_FAILURE: "GET_EMAIL_DISTIBUTION_FAILURE",
  CLEAR_HIDE_POST: "CLEAR_HIDE_POST",
  CLEAR_SYSTEM_CONFIG: "CLEAR_SYSTEM_CONFIG",
  TEST_EMAIL_SUCCESS: "TEST_EMAIL_SUCCESS",
  TEST_EMAIL_FAILURE: "TEST_EMAIL_FAILURE",
};

const types = {
  ...asyncActions,
  ...singleActions,
};

export default types;
