/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Wrapper,
  Title,
  SubTitle,
  Avatar,
  UserOption,
  FlexHorizontal,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from "./Navigation.styles";
import {
  getSavedLanguage,
  getSavedUserData,
  popupAlert,
  saveLanguage,
  useOutsideClick,
} from "libs/Utils";
import { Icons, Images } from "themes";
import _ from "lodash";
import history from "../../../../history";
import { AuthenticationActions, UsersActions } from "actions";
import { CONSTANTS } from "constants/Constants";
import { useTranslation } from "react-i18next";
import i18next from "i18n";
import RouteTypes from "constants/RouteTypes";

interface SectionProps {
  title?: string;
  subTitle?: string;
}

const LANGUAGES = [
  {
    label: "AL",
    value: "al",
    icon: Images.albanian,
  },
  {
    label: "EN",
    value: "en",
    icon: Images.english,
  },
];

const { logout } = AuthenticationActions;
const { setDefaultRegisterResult } = UsersActions;

const Navigation: React.FC<SectionProps> = (props: SectionProps) => {
  const { t } = useTranslation("translation");

  const { title, subTitle } = props;
  useEffect(() => {}, [props]);

  const isUpdateSuccess = useSelector((state) =>
    _.get(state, "Users.isUpdateSuccess")
  );
  const dispatch = useDispatch();
  const [currentLanguage, setCurrentLanguage] = useState<string>("en");
  const [showUserOption, setShowUserOption] = useState(false);
  const [urlAvatar, setUrlAvatar] = useState<string>("");
  const [showLanguageOptions, setShowLanguageOptions] = useState(false);
  const userData = getSavedUserData();
  const userOptionRef = useRef<HTMLHeadingElement>(null);
  const languageToggleRef = useRef<HTMLDivElement>(null);
  useOutsideClick(userOptionRef, () => setShowUserOption(false));
  useOutsideClick(languageToggleRef, () => setShowLanguageOptions(false));

  useEffect(() => {
    const lang = getSavedLanguage();
    if (lang) setCurrentLanguage(lang);

    const savedUserData = getSavedUserData();
    const avatar = _.get(savedUserData, "userData.avatar");
    if (avatar) {
      const getImagePath = _.get(avatar, "imagePath");
      setUrlAvatar(getImagePath);
    }
    dispatch(setDefaultRegisterResult());
  }, [isUpdateSuccess]);

  const _renderFullName = () => {
    if (
      !_.isUndefined(_.get(userData, "userData.firstName")) &&
      !_.isUndefined(_.get(userData, "userData.lastName"))
    )
      return `${_.get(userData, "userData.firstName")} ${_.get(
        userData,
        "userData.lastName"
      )}`;
    return t("label.unknown");
  };

  const onLogOut = async () => {
    const isAgree = await popupAlert({
      type: "warning",
      title: "Warning",
      text: t("alert.warningBeforeLogOut"),
      buttons: ["No", true],
    });
    if (isAgree) dispatch(logout());
  };

  const onChangeLanguage = (language: string) => {
    setCurrentLanguage(language);
    saveLanguage(language);
    setShowLanguageOptions(false);
    i18next.changeLanguage(language);
  };

  const onClickProfile = () => {
    const currentType = _.get(userData, "userType");
    const currentRoute =
      currentType === CONSTANTS.USER_TYPE.ADMIN
        ? RouteTypes.ADMIN_ROUTES.PROFILE
        : RouteTypes.CLIENT_ROUTES.PROFILE;
    history.push({
      pathname: currentRoute,
      state: { id: _.get(userData, "id") },
    });
  };

  const renderLanguageDropdown = () => {
    return (
      <Dropdown>
        <DropdownToggle
          ref={languageToggleRef}
          onClick={() => setShowLanguageOptions(!showLanguageOptions)}
        >
          <img
            src={currentLanguage === "al" ? Images.albanian : Images.english}
            alt="language flag"
          />
        </DropdownToggle>
        {showLanguageOptions && (
          <DropdownMenu>
            {_.map(LANGUAGES, (language) => (
              <DropdownItem
                key={`language-${_.get(language, "value")}`}
                onClick={() => onChangeLanguage(_.get(language, "value"))}
              >
                <img src={_.get(language, "icon")} alt="" />
                <span>{_.get(language, "label")}</span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        )}
      </Dropdown>
    );
  };

  return (
    <Wrapper>
      <>
        <Title>
          <span>{title}</span>
          <FlexHorizontal className="align-center">
            {renderLanguageDropdown()}
            <Avatar background={urlAvatar || Icons.defaultUser}>
              <div
                className="wrapper-avatar"
                ref={userOptionRef}
                onClick={() => setShowUserOption(!showUserOption)}
              >
                {/* <img src={urlAvatar || Icons.defaultUser} alt="Settings" /> */}
              </div>
              {showUserOption && (
                <UserOption>
                  <div className="user-info">
                    <span className="status">
                      {_.findKey(
                        CONSTANTS.STATUS_USER,
                        (value) =>
                          value === _.parseInt(_.get(userData, "status"))
                      )}
                    </span>
                    <span className="full-name">{_renderFullName()}</span>
                  </div>
                  <div className="user-option">
                    <div
                      className="item-option"
                      onClick={() => onClickProfile()}
                    >
                      {t("header.myProfile")}
                    </div>
                    <div className="item-option" onClick={() => onLogOut()}>
                      {t("header.logOut")}
                    </div>
                  </div>
                </UserOption>
              )}
            </Avatar>
          </FlexHorizontal>
        </Title>
        <SubTitle>{subTitle}</SubTitle>
      </>
    </Wrapper>
  );
};

export default Navigation;
