import Api from "./Base";
import { API } from "../constants/Constants";

const { MESSAGE } = API;

export function fetchAllMessage() {
  return Api.get(MESSAGE.BASIC);
}

export function getDirectMessage(data: any) {
  return Api.get(MESSAGE.GET_DIRECT_MESSAGE, data);
}

export function removeMessage(id: any) {
  return Api.del(`${MESSAGE.REMOVE}`, { id });
}
