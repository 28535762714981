import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  Grid,
  FormControlLabel,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormLabel,
  Button,
  FormGroup,
  Checkbox,
  Stack,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { LoadingButton } from "@mui/lab";
import writeXlsxFile from "write-excel-file";
import {
  CategoriesActions,
  DataRecordsActions,
  SourceSettingsActions,
} from "actions";
import { generateDropdown, getSavedLanguage } from "libs/Utils";
import DefaultLayout from "components/AdminLayout/DefaultLayout";
import RouteTypes from "constants/RouteTypes";
import { CONSTANTS } from "constants/Constants";
import { RootState } from "reducers";

const { fetchAllCategories, clearCategories } = CategoriesActions;
const { searchDataRecord, clearDataRecords } = DataRecordsActions;
const { clearSources, searchSourceSettings } = SourceSettingsActions;

const DownloadSource: React.FC = () => {
  const { t } = useTranslation("translation");
  const lang = getSavedLanguage();
  const LIST_OF_FIELDS = [
    { label: t("label.headline"), value: "title" },
    { label: t("label.content"), value: "content" },
    { label: t("label.publishedDate"), value: "publishedAt" },
    { label: t("label.webURL"), value: "originalLink" },
    { label: t("label.sourceName"), value: "source.name" },
    { label: t("label.category"), value: `category.name.${lang}` },
    { label: t("label.author"), value: "author" },
  ];
  const dispatch = useDispatch();
  const categoryIsLoading = useSelector((state: RootState) => {
    return state.Categories.isLoading;
  });
  const categories = useSelector((state: RootState) => {
    return state.Categories.categories;
  });
  const sourcesIsLoading = useSelector((state: RootState) => {
    return state.SourceSettings.isLoading;
  });
  const dataRecordIsLoading = useSelector((state: RootState) => {
    return state.DataRecord.isLoading;
  });
  const sourceSettings = useSelector((state: RootState) => {
    return state.SourceSettings.sourceSettings;
  });
  const dataRecords = useSelector((state: RootState) => {
    return state.DataRecord.dataRecords;
  });
  const [filterData, setFilterData] = useState({
    mediaType: CONSTANTS.MEDIA_TYPE.WEB,
    source: "",
    categoryId: "",
    dateFrom: dayjs().startOf("month").format("YYYY-MM-DD"),
    dateTo: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  const [listOfField, setListOfField] = useState<string[]>([]);
  const dataDropdownCategories = generateDropdown({
    data: categories,
    key: "name",
    value: "id",
  });
  const dataDropdownSources = generateDropdown({
    data: sourceSettings?.items || [],
    key: "name",
    value: "id",
  });

  useEffect(() => {
    dispatch(fetchAllCategories({ status: CONSTANTS.STATUS.ACTIVE }));
    dispatch(
      searchSourceSettings({
        status: CONSTANTS.STATUS.ACTIVE,
        mediaType: CONSTANTS.MEDIA_TYPE.WEB,
        page: 1,
        limit: 0,
      })
    );
    return () => {
      dispatch(clearCategories());
      dispatch(clearSources());
      dispatch(clearDataRecords());
    };
  }, []);

  const onChangeListOfFied = (value: string) => {
    const currentFields = [...listOfField];
    const isIncludes = _.includes(currentFields, value);
    if (isIncludes) {
      const removeValue = _.filter(currentFields, (field) => field !== value);
      setListOfField(removeValue);
    } else setListOfField([...currentFields, value]);
  };

  const exportExcel = async (data: any) => {
    const fileExtension = ".xlsx";
    let totalRows: any[] = [];
    const schema: any[] = [];
    _.forEach(data, (item, index) => {
      let filteredData: any = {};
      _.forEach(LIST_OF_FIELDS, (option) => {
        if (_.includes(listOfField, option.value))
          filteredData[option.value] =
            option.value === "publishedAt"
              ? dayjs(_.get(item, option.value)).format("MM-DD-YYYY, HH:mm:ss")
              : _.get(item, option.value);
      });
      totalRows = [
        ...totalRows,
        {
          no: index + 1,
          ...filteredData,
        },
      ];
    });
    schema.push({
      column: "No",
      value: (row: any) => row?.no,
      borderStyle: "medium",
      align: "center",
    });
    const getWidth = (value: number) => {
      switch (value) {
        case 0:
          return 80;
        case 1:
          return 180;
        case 3:
          return 50;
        default:
          return 30;
      }
    };
    _.forEach(LIST_OF_FIELDS, (option, index) => {
      if (_.includes(listOfField, option.value))
        schema.push({
          column: option.label,
          width: getWidth(index),
          value: (row: any) => row[option.value],
          borderStyle: "medium",
        });
    });
    const fileName =
      `${
        _.find(dataDropdownSources, ["value", filterData.source])?.label
      }_${new Date().getTime()}` + fileExtension;
    await writeXlsxFile(totalRows, {
      schema,
      headerStyle: {
        fontWeight: "bold",
        fontSize: 14,
        wrap: true,
        align: "center",
      },
      fileName,
    });
  };

  const onFilterRecord = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const resolveFilter: any = {
      page: 1,
      limit: 0,
      status: CONSTANTS.STATUS.ACTIVE,
      source: filterData.source,
      dateFrom: filterData.dateFrom,
      dateTo: filterData.dateTo,
      category: [],
      sortBy: "createdAt",
      orderBy: "ASC",
    };
    if (filterData.categoryId === "all")
      resolveFilter.category = _.map(
        dataDropdownCategories,
        (catId) => catId.value
      );
    else resolveFilter.category = [filterData.categoryId];
    dispatch(searchDataRecord(resolveFilter, exportExcel));
  };

  const _renderMain = () => (
    <Grid
      container
      justifyContent="center"
      rowGap={2}
      component="form"
      onSubmit={onFilterRecord}
    >
      <Grid item xs={8}>
        <Stack gap={2} direction="row">
          <FormControl fullWidth disabled={sourcesIsLoading} required>
            <InputLabel id="select-label">Source</InputLabel>
            <Select
              labelId="select-label"
              value={_.get(filterData, "source")}
              label="Source"
              onChange={(e) =>
                setFilterData({ ...filterData, source: e.target.value })
              }
            >
              {_.map(
                _.orderBy(dataDropdownSources, ["label", "asc"]),
                (option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <FormControl fullWidth disabled={categoryIsLoading} required>
            <InputLabel id="select-label">Category</InputLabel>
            <Select
              labelId="select-label"
              value={_.get(filterData, "categoryId")}
              label="Category"
              onChange={(e) =>
                setFilterData({ ...filterData, categoryId: e.target.value })
              }
            >
              <MenuItem value="all">--All--</MenuItem>
              {_.map(
                _.orderBy(dataDropdownCategories, ["label", "asc"]),
                (option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Stack>
      </Grid>
      <Grid item xs={8}>
        <Stack gap={2} direction="row">
          <DatePicker
            label="Date From"
            format="DD/MM/YYYY"
            value={filterData.dateFrom ? dayjs(filterData.dateFrom) : null}
            onChange={(e: Dayjs | null) => {
              setFilterData({
                ...filterData,
                dateFrom: dayjs(e).format("YYYY-MM-DD"),
              });
            }}
            sx={{ width: 1 }}
          />
          <DatePicker
            label="Date To"
            format="DD/MM/YYYY"
            value={filterData.dateTo ? dayjs(filterData.dateTo) : null}
            onChange={(e: Dayjs | null) => {
              setFilterData({
                ...filterData,
                dateTo: dayjs(e).format("YYYY-MM-DD"),
              });
            }}
            minDate={dayjs(filterData.dateFrom)}
            sx={{ width: 1 }}
          />
        </Stack>
      </Grid>
      <Grid item xs={8}>
        <FormLabel>{t("label.listOfFields")}</FormLabel>
        <FormGroup>
          {_.map(LIST_OF_FIELDS, (field, index) => {
            const { value, label } = field;
            const checked = _.includes(listOfField, value);
            return (
              <FormControlLabel
                key={`listOfFields-${value}-${index}`}
                control={<Checkbox checked={checked} />}
                label={label}
                onChange={() => onChangeListOfFied(value)}
              />
            );
          })}
        </FormGroup>
      </Grid>
      <Grid item xs={8} textAlign="center">
        <LoadingButton
          variant="contained"
          color="success"
          type="submit"
          startIcon={<FileDownloadIcon />}
          loading={dataRecordIsLoading}
        >
          {t("button.export")}
        </LoadingButton>
      </Grid>
    </Grid>
  );

  return (
    <DefaultLayout
      content={_renderMain()}
      currentPage={RouteTypes.ADMIN_ROUTES.DOWNLOAD_SOURCE}
      title="Download Sources"
    />
  );
};
export default DownloadSource;
