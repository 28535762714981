import styled from "styled-components";
import ColorName from "constants/Variables";

const MailTagListContainer = styled.div`
  width: 100%;
  & .note {
    display: inline-block;
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

const MailTagRow = styled.div`
  display: flex;
`;

const MailsField = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin-left: 20px;
  height: auto;
`;

const MailItem = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  height: 28px;
  padding: 5px 10px;
  background: ${ColorName.linkWater};
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid ${ColorName.linkWater};
  user-select: none;
  font-size: 14px;

  i {
    margin-left: 5px;
    font-size: 12px;
    transform: translateY(2px);
  }

  &:hover {
    border: 1px solid ${ColorName.william};
    cursor: pointer;
  }
`;

export { MailTagListContainer, MailTagRow, MailsField, MailItem };
