/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _, { upperFirst, lowerCase } from "lodash";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

import { RootState } from "reducers";
import { RoleActions } from "actions";

import history from "../../../history";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Button,
  Input,
  LoadingLayer,
  Spinner,
  FormDetails,
  FormGroup,
  FormLabel,
  ButtonField,
} from "components/Common";
import { useTranslation } from "react-i18next";
import { popupAlert } from "libs/Utils";
import RouteTypes from "constants/RouteTypes";
import { useLocation } from "react-router-dom";

const {
  clearRoleDetails,
  fetchPrivileges,
  updateRole,
  createRole,
  getRoleByID,
} = RoleActions;

const InputStyleProps = {
  width: "300px",
};

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "10px 10px 10px 0",
};

const CreateRole = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state: RootState) => {
    return state.Role.isLoading;
  });
  const privilegesData = useSelector((state: RootState) => {
    return state.Role.privileges;
  });
  const role = useSelector((state: RootState) => {
    return state.Role.role;
  });
  const [roleData, setRoleData] = useState({
    name: "",
    description: "",
  });
  const [require, setRequire] = useState({
    name: false,
  });
  const [privileges, setPrivileges]: any = useState();
  const [checked, setChecked]: any = useState([]);
  const [expanded, setExpanded]: any = useState([]);

  useEffect(() => {
    const id = _.get(location, "state.id");
    if (id) dispatch(getRoleByID(id));

    dispatch(fetchPrivileges());

    return () => {
      dispatch(clearRoleDetails());
    };
  }, [dispatch]);

  useEffect(() => {
    setChecked(_.get(role, "acceptApi") || []);
    setRoleData(role);
  }, [role]);

  useEffect(() => {
    const array = _.map(privilegesData, (item, index) => {
      return {
        label: upperFirst(lowerCase(index)),
        value: index,
        children: _.map(item, (child, i) => {
          return { value: child, label: i };
        }),
      };
    });
    setPrivileges(array);
  }, [privilegesData]);

  const validateData = () => {
    const data = [
      {
        label: t("label.roleName"),
        value: _.get(roleData, "name"),
      },
      {
        label: t("label.privileges"),
        value: checked,
      },
    ];
    let flag = true;
    let message = `${t("alert.foundEmptyFields")}`;
    _.map(data, (item) => {
      const { label, value } = item;
      if (_.isEmpty(_.trim(value))) {
        flag = false;
        message += `${label}\n`;
      }
    });
    return { flag, message };
  };

  const onSubmit = async () => {
    const { flag, message } = validateData();
    if (!flag) {
      return popupAlert({
        type: "warning",
        title: t("alert.warning"),
        text: message,
      });
    }
    const id = _.get(role, "id");
    const data = {
      ...roleData,
      name: _.trim(roleData.name),
      description: _.trim(roleData.description),
      acceptApi: checked,
    };
    const filter = _.get(location, "state.filter");
    const resolveFilter = {
      pathname: RouteTypes.ADMIN_ROUTES.ROLES,
      state: { filter, from: RouteTypes.ADMIN_ROUTES.ROLE_DETAILS },
    };

    if (id) await dispatch(updateRole(data, id, resolveFilter));
    else await dispatch(createRole(data, resolveFilter));
  };

  const onBack = () => {
    const filter = _.get(location, "state.filter");
    history.push({
      pathname: RouteTypes.ADMIN_ROUTES.ROLES,
      state: { filter, from: RouteTypes.ADMIN_ROUTES.ROLE_DETAILS },
    });
  };

  const renderMain = () => {
    return (
      <FormDetails>
        <FormGroup>
          <FormLabel aria-label="require">{t("label.roleName")}</FormLabel>
          <Input
            value={roleData.name || ""}
            onChangeHandler={(e) => {
              setRoleData({ ...roleData, name: e.target.value });
              setRequire({ ...require, name: false });
            }}
            placeholder={t(`placeholder.${isLoading ? "loading" : "name"}`)}
            {...InputStyleProps}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>{t("label.description")}</FormLabel>
          <Input
            value={roleData.description || ""}
            onChangeHandler={(e) =>
              setRoleData({ ...roleData, description: e.target.value })
            }
            placeholder={t(
              `placeholder.${isLoading ? "loading" : "description"}`
            )}
            {...InputStyleProps}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel aria-label="require">{t("label.privileges")}</FormLabel>
          <div className="container-checkbox">
            <CheckboxTree
              nodes={privileges || []}
              checked={checked}
              expanded={expanded}
              onCheck={(e) => setChecked(e)}
              onExpand={(e) => setExpanded(e)}
              iconsClass="fa5"
            />
          </div>
        </FormGroup>
        <ButtonField margin="10px 0 50px 0">
          <Button onClick={() => onSubmit()} {...ButtonStyleProps}>
            {_.get(location, "state.id")
              ? t("button.save")
              : t("button.create")}
            {isLoading && (
              <LoadingLayer>
                <Spinner />
              </LoadingLayer>
            )}
          </Button>
          <Button onClick={() => onBack()} {...ButtonStyleProps}>
            {t("button.back")}
          </Button>
        </ButtonField>
      </FormDetails>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.ADMIN_ROUTES.ROLES}
      title="Roles"
      subTitle={
        _.get(location, "state.id")
          ? t("titlePage.editRole")
          : t("titlePage.createNewRole")
      }
    />
  );
};

export default CreateRole;
