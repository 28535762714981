import styled from "styled-components";
import ColorName from "constants/Variables";

interface SectionProps {
  width?: string;
  height?: string;
}

const ContainerSearch = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 1140px;
  flex-wrap: wrap;
`;

const ReportDetails = styled.div`
  position: relative;
`;

const ContainerInformation = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  overflow: auto;
`;

const LabelSelected = styled.div`
  display: flex;
  align-items: center;

  i {
    margin-right: 10px;
  }

  &.current-date {
    margin-bottom: 10px;
    font-size: 20px;
    color: ${ColorName.william};
  }
`;

const DataPostContainer = styled.div`
  max-width: 1140px;
`;

const DataPostItem = styled.div`
  position: relative;
  padding: 25px 10px;
  box-sizing: border-box;
  &:nth-child(even) {
    background: ${ColorName.linkWater};
  }

  span {
    color: ${ColorName.william};
    margin-right: 10px;
  }
`;

const PreviewContent = styled.div`
  margin: 15px 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const PostMenu = styled.div`
  position: absolute;
  left: calc(50% - 100px);
  top: 100%;
  width: 180px;
  height: 0px;
  background: transparent;
  border: 1px solid transparent;
  overflow: hidden;
`;

const PostMenuItem = styled.div`
  padding: 8px 20px;
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: ${ColorName.linkWater};
  }
`;

const MenuButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  display: flex;
  justify-content: center;
  i {
    color: ${ColorName.william};
  }
  &:hover {
    cursor: pointer;
    ${PostMenu} {
      background: #fff;
      height: auto;
      border: 1px solid #cadeed;
    }
  }
`;

const FitlerButton = styled.div`
  margin: 10px 20px 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${ColorName.william};
  color: ${ColorName.william};
  border-radius: 3px;
  &.enable:hover {
    cursor: pointer;
    color: ${ColorName.white};
    border: 1px solid ${ColorName.white};
    background: ${ColorName.william};
  }

  &.disable {
    opacity: 0.6;
    &:hover {
      cursor: not-allowed;
    }
  }
`;

const TemplateDetailsContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.25);
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  & .justify-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & .justify-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .justify-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

const TemplateDetailsHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${ColorName.william};
  color: white;
  font-size: 15px;
  height: 35px;
`;

const TemplateDetailsContent = styled.div`
  width: ${(props: SectionProps) => props.width || "70vw"};
  height: ${(props: SectionProps) => props.height || "70vw"};
  max-width: 1120px;
  max-height: 390px;
  background: white;
  position: relative;
`;

const TemplateDetailsForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  max-height: 260px;
  justify-content: center;
  overflow: auto;
  height: 100%;

  &.add-post {
    height: 100%;
    max-height: 200px;
    overflow: auto;
  }

  &.flex-start {
    justify-content: flex-start;
  }

  & .form-group {
    color: ${ColorName.william};
    align-items: center;
    font-size: 16px;
    flex: 1;
    & .empty {
      color: ${ColorName.amaranth};
      font-size: 12px;
      height: 17px;
    }
    > span {
      display: inline-block;
      min-height: 1px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &.fields {
      align-items: flex-start;
      margin: 10px 0;
    }
  }
`;

const ButtonField = styled.div`
  display: flex;
  background: ${ColorName.white};
  justify-content: flex-end;
  padding: 10px 15px 10px 0;

  &.mt-2 {
    margin-top: 20px;
  }
`;

const DatatPostContent = styled.div`
  &.blur {
    opacity: 0.4;
  }
`;

const FilterMediaTypeRow = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

const FilterMediaTypeButton = styled.div`
  margin-right: 5px;
  padding: 5px;
  border: 1px solid transparent;
  height: max-content;
  border-radius: 5px;
  user-select: none;

  i {
    margin-right: 5px;
  }

  &.active,
  &:hover {
    border-color: ${ColorName.william};
    color: ${ColorName.william};
    cursor: pointer;
  }
`;

const RowItem = styled.div`
  display: flex;
`;

const ColItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  &.mb-1 {
    margin-bottom: 1rem;
  }

  & .label {
    width: 70px;
    display: inline-block;
  }
`;

const LabelSection = styled.h2`
  font-size: 25px;
  color: ${ColorName.william};

  &[aria-label="require"] {
    &::after {
      content: "*";
      margin-left: 4px;
      color: red;
    }
  }
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;

  &.row {
    flex-direction: row;
  }
`;

const ContentRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 15px;
    border: 1px solid ${ColorName.linkWater};
    box-shadow: 0px 0px 2px rgb(62 138 182 / 25%);

    &.required {
      border: 1px solid #f85353;
      box-shadow: 0px 0px 2px #f85353;
    }
  }
`;

const FormLabel = styled.span`
  display: flex;
  align-items: center;
  width: ${(props: SectionProps) => props.width || "max-content"};
  min-width: 100px;
  color: ${ColorName.william};

  &.label-options {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

const TotalPostRow = styled.div`
  margin-top: 10px;
  span {
    font-size: 16px;
    margin-right: 10px;
    color: ${ColorName.william};
  }
`;

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  width: 100%;
  max-width: 600px;
`;

const TagMailList = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span {
    font-size: 14px;
  }
`;

const TagMailItem = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  padding: 2px 10px;
  background: ${ColorName.linkWater};
  border: 1px solid ${ColorName.linkWater};
  border-radius: 5px;
  margin: 0 10px 5px 0;
  i {
    transform: translateY(1px);
    font-size: 12px;
    margin-left: 8px;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid ${ColorName.william};
  }
`;

const InputField = styled.div`
  position: relative;

  input {
    padding: 7px 60px 7px 14px;
  }

  & .input-icons {
    position: absolute;
    right: 5px;
    top: 7px;
    font-weight: bold;
    span {
      font-size: 14px;
      margin-left: 10px;
      transition: all ease 0.25s;

      &.blur {
        opacity: 0.5;
        &:hover {
          cursor: not-allowed;
          color: ${ColorName.william};
        }
      }

      &:hover {
        cursor: pointer;
        color: ${ColorName.burntSienna};
      }
    }
  }
`;

const OrderLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-weight: 600;
    font-size: 16px;
    color: ${ColorName.william};
    text-align: center;
  }
`;

const DataWrapper = styled.div`
  margin-top: 10px;
`;
const DataHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const DataTab = styled.div`
  padding: 5px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid ${ColorName.william};
  border-bottom: 0px;
  transition: all ease 0.25s;

  &:hover {
    background: rgba(60, 99, 121, 0.8);
    color: ${ColorName.white};
    cursor: pointer;
  }

  &.active {
    color: ${ColorName.white};
    background: ${ColorName.william};
  }
`;

const DataBody = styled.div`
  border: 1px solid ${ColorName.william};
  border-radius: 5px;
  border-top-left-radius: 0px;
  width: 100%;
  // max-width: 1140px;
`;

export {
  ContainerSearch,
  ContainerInformation,
  LabelSelected,
  DataPostContainer,
  DataPostItem,
  PreviewContent,
  MenuButton,
  PostMenu,
  PostMenuItem,
  FitlerButton,
  TemplateDetailsContainer,
  TemplateDetailsHeader,
  TemplateDetailsContent,
  ButtonField,
  TemplateDetailsForm,
  DatatPostContent,
  FilterMediaTypeButton,
  FilterMediaTypeRow,
  RowItem,
  ColItem,
  LabelSection,
  ContentSection,
  ContentRow,
  FormLabel,
  TotalPostRow,
  GridLayout,
  TagMailList,
  TagMailItem,
  InputField,
  OrderLayer,
  ReportDetails,
  DataTab,
  DataWrapper,
  DataHeader,
  DataBody,
};
