export default {
  AUTHENTICATION_ROUTES: {
    AUTHENTICATION: "/authentication",
    VERIFY_ACCOUNT: "/verify-account",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",
    USER_REGISTER: "/user-register",
    USER_LOGIN: "/user-authentication",
  },
  ADMIN_ROUTES: {
    CONTACT: "/contact",
    HOMEPAGE: "/",
    FILE_RECORD: "/file-record",
    ADD_CSV: "/add-csv",
    DATA_RECORD: "/data-record",
    CATEGORIES_DETAILS: "/categories/details",
    CATEGORIES: "/categories",
    USERS: "/users",
    USER_DETAILS: "/users/details",
    SOURCE_DETAILS: "/sources-settings/details",
    SOURCES_SETTINGS: "/sources-settings",
    CREATE_SOURCE: "/create-source",
    EDIT_SOURCE: "/edit-source",
    LOGS: "/logs",
    DATA_RECORD_DETAILS: "/data-record-details",
    PACKAGES: "/clients/packages",
    PACKAGE_DETAILS: "/clients/packages/details",
    CLIENTS: "/clients",
    CREATE_CLIENT: "/clients/create",
    EDIT_CLIENT: "/clients/edit",
    CREATE_ACCOUNT: "/clients/add-account",
    EDIT_ACCOUNT: "/clients/edit-account",
    LIST_ACCOUNT: "/clients/list-accounts",
    CREATE_USER: "/users/create-user",
    ROLES: "/roles",
    CREATE_ROLE: "/roles/create",
    EDIT_ROLE: "/roles/edit-role",
    LIST_USER: "/clients/accounts/list-user",
    CLIENT_STEP_SECOND: "/clients/create-client-step-2",
    FILTERS: "/filters",
    SIMPLE_FILTER: "/create-simple-filter",
    FILTER_DETAILS: "/filter-details",
    EDIT_ADVANCE_FILTER: "/edit-advance-filter",
    EDIT_SIMPLE_FILTER: "/edit-simple-filter",
    PROFILE: "/profile",
    FOLDERS: "/folders",
    CREATE_FOLDERS: "/folders/create",
    EDIT_FOLDERS: "/folders/edit",
    EMAIL_TEMPLATE: "/template/email",
    CREATE_EMAIL_TEMPLATE: "/template/email/create",
    EDIT_EMAIL_TEMPLATE: "/template/email/edit",
    PDF_TEMPLATE: "/template/pdf",
    CREATE_PDF_TEMPLATE: "/template/pdf/create",
    EDIT_PDF_TEMPLATE: "/template/pdf/edit",
    DISTRIBUTION_LIST: "/delivery/distribution",
    CREATE_DISTRIBUTION_LIST: "/delivery/distribution/create",
    EDIT_DISTRIBUTION_LIST: "/delivery/distribution/edit",
    EMAIL_DELIVERY: "/delivery/email",
    EMAIL_DELIVERY_DETAILS: "/delivery/email/details",
    CREATE_EMAIL_DELIVERY: "/delivery/email/create",
    EDIT_EMAIL_DELIVERY: "/delivery/email/edit",
    POST: "/post",
    MANAGE_RECORD: "/manage-record",
    MANAGE_SOURCES: "/manage-sources",
    MANAGE_TEMPLATE: "/manage-template",
    MANAGE_DELIVERY: "/manage-delivery",
    REPORT: "/report",
    REPORT_FILTER: "/report/filters",
    CREATE_REPORT: "/report/create",
    UPDATE_REPORT: "/report/update",
    MEMBERS: "/members",
    MANAGE_REPORTS: "/manage-reports",
    ROLE_DETAILS: "/roles/details",
    GROUPS: "/groups",
    HIDDEN: "/hidden",
    TRAINING: "/training",
    CONFIG: "/config",
    STMP: "/STMP",
    DOWNLOAD_SOURCE: "/download-source",
  },
  USER_ROUTES: {
    USER_ARTICLES_DETAILS: "/articles/:id",
    USER_ARTICLES: "/articles",
    PROFILE: "/me/profile",
  },
  CLIENT_ROUTES: {
    LOGS: "/client/logs",
    PACKAGES_MANAGEMENT: "/client/packages",
    PACKAGES_DETAILS: "/client/packages/details",
    USER_MANAGEMENT: "/client/users",
    ACCOUNT_DETAILS: "/client/details",
    FILTERS: "/client/filters",
    FILTERS_DETAILS: "/client/filters/details",
    CONTACT: "/client/contact",
    PROFILE: "/client/profile",
  },
  PROFILE: "/profile",
};
