import styled from 'styled-components';
import ColorName from 'constants/Variables';

interface SectionProps {
  margin?: string;
}

const Wrapper = styled.div`
  margin: ${(props: SectionProps) => props.margin || '20px 24px'};
  display: flex;
  color: ${ColorName.william};
`;
const ContainerSearch = styled.div`
  padding: 10px 17px;
  display: flex;
  border: 1px solid ${ColorName.william};
  align-items: center;
  border-radius: 16px;
  flex: 1;
  & i {
    margin-right: 5px;
  }
`;

const Search = styled.input`
  border: none;
  width: 100%;
  outline: none;
  color: ${ColorName.william};
`;

export { Wrapper, ContainerSearch, Search };
