import ActionTypes from "constants/ActionTypes";

const defaultFilter = {
  accountId: "",
  clientId: "",
  keyword: "",
  userType: "",
};

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  isRegisterSuccess: 0,
  isUpdateSuccess: 0,
  changePackageCode: 0,
  users: [],
  user: {},
  filter: defaultFilter,
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_DEFAULT_UPDATE_PACKAGE_CODE:
      return {
        ...state,
        changePackageCode: 0,
      };
    case ActionTypes.SET_DEFAULT_REGISTER_RESULT:
      return {
        ...state,
        isRegisterSuccess: 0,
        isUpdateSuccess: 0,
      };
    case ActionTypes.CLEAR_USER_DETAILS:
      return {
        ...state,
        user: {},
      };
    case ActionTypes.CLEAR_USERS_PAYLOAD:
      return {
        ...state,
        users: [],
      };
    case ActionTypes.SET_USERS_FILTER:
      return {
        ...state,
        filter: payload,
      };
    case ActionTypes.CLEAR_USERS_FILTER:
      return {
        ...state,
        filter: defaultFilter,
      };
    case ActionTypes.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        user: payload,
        isLoading: false,
        isSuccess: true,
      };
    case ActionTypes.USER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        isUpdateSuccess: 1,
        changePackageCode: 1,
      };
    case ActionTypes.USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.REGISTER_USER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        isRegisterSuccess: 2,
      };
    case ActionTypes.REGISTER_USER_FAILURE:
      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        hasError: true,
        isRegisterSuccess: 1,
      };
    case ActionTypes.FETCH_USERS_CLIENT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        users: payload,
      };
    case ActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        isUpdateSuccess: 2,
      };
    case ActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.REGISTER_CLIENT_USER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_CLIENT_USER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        users: payload,
      };
    case ActionTypes.UPDATE_CLIENT_USER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        user: payload,
      };
    case ActionTypes.UPDATE_USER_FOR_SELF_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        isUpdateSuccess: 2,
      };
    case ActionTypes.UPDATE_USER_PACKAGE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        changePackageCode: 2,
      }
    default:
      return state;
  }
};
