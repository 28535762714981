import Api from "./Base";
import { API } from "constants/Constants";

const { SYSTEM_CONFIG } = API;

export function fetchSystemConfig(payload: any) {
  return Api.get(SYSTEM_CONFIG.BASIC, payload);
}

export function updateSTMPConfig(payload: any, id: string) {
  return Api.put(`${SYSTEM_CONFIG.UPDATE}/${id}`, payload);
}

export function testEmail() {
  return Api.post(`${SYSTEM_CONFIG.TEST_EMAIL}`);
}

export function restartSystem() {
  return Api.post(`${SYSTEM_CONFIG.RESTART_SYSTEM}`);
}