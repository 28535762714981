import ActionTypes from "constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  pdfTemplates: [],
  pdfTemplate: {},
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.PDF_TEMPLATE_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.PDF_TEMPLATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CREATE_PDF_TEMPLATE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.UPDATE_PDF_TEMPLATE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_PDF_TEMPLATE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        pdfTemplates: payload,
      };
    case ActionTypes.SEARCH_PDF_TEMPLATE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        pdfTemplates: payload,
      };
    case ActionTypes.GET_PDF_TEMPLATE_BY_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        pdfTemplate: payload,
      };
    case ActionTypes.ACTIVATE_PDF_TEMPLATE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.DEACTIVATE_PDF_TEMPLATE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
