import ActionTypes from "constants/ActionTypes";
import API from "api";
import _ from "lodash";
import { responseAlert } from "libs/alert";
import { getSavedUserData } from "../libs/Utils";

const orderByCreated = (payload: any) => {
  const resolvePayload: any = {};
  _.forEach(payload, (item, key) =>
    _.assign(resolvePayload, { [key]: _.orderBy(item, ["createdAt"], "asc") })
  );
  return payload;
};

const resolveTotalNewsMessages = (messages: any) => {
  const userId = _.get(getSavedUserData(), "id");
  let newMessages = 0;
  _.forEach(messages, (message) => {
    const status = _.get(message, "status");
    const receiverId = _.get(message, "receiver.id");
    if (_.lowerCase(status) === "new" && receiverId === userId) newMessages += 1;
  });
  return newMessages;
};

const setTotalNewMessages = (payload: any) => {
  return {
    type: ActionTypes.SET_TOTAL_NEW_MESSAGES,
    payload,
  };
};

const setAllMessages = (payload: any) => {
  return {
    type: ActionTypes.SET_ALL_MESSAGES,
    payload,
  };
};

const setDirectMessages = (payload: any) => {
  return {
    type: ActionTypes.SET_DIRECT_MESSAGES,
    payload,
  };
};

const messageHasError = () => {
  return {
    type: ActionTypes.MESSAGE_FAILURE,
  };
};
const messageLoading = () => {
  return {
    type: ActionTypes.MESSAGE_REQUEST,
  };
};

const fetchAllMessageIsLoading = () => {
  return {
    type: ActionTypes.FETCH_ALL_MESSAGE_IS_LOADING,
  };
};

const fetchMessagesSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_MESSAGE_SUCCESS,
    payload,
  };
};

const fetchDirectMessagesSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_DIRECT_MESSAGE_SUCCESS,
    payload,
  };
};

const removeMessageSuccess = () => {
  return {
    type: ActionTypes.REMOVE_MESSAGE_SUCCESS,
  };
};

const fetchMessages = () => {
  return async (dispatch: any) => {
    dispatch(messageLoading());
    await API.fetchAllMessage()
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          const totalNewMessage = resolveTotalNewsMessages(
            _.get(res, "payload")
          );
          dispatch(setTotalNewMessages(totalNewMessage));
          dispatch(fetchMessagesSuccess(_.get(res, "payload")));
        } else {
          responseAlert({ res });
          dispatch(messageHasError());
        }
      })
      .catch((e: any) => {
        dispatch(messageHasError());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchDirectMessage = (data: any) => {
  return async (dispatch: any) => {
    dispatch(fetchAllMessageIsLoading());
    await API.getDirectMessage(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          const resolvePayload = orderByCreated(_.get(res, "payload"));
          dispatch(fetchDirectMessagesSuccess(resolvePayload));
        } else {
          responseAlert({ res });
          dispatch(messageHasError());
        }
      })
      .catch((e: any) => {
        dispatch(messageHasError());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const removeMessage = (id: string) => {
  return async (dispatch: any) => {
    dispatch(messageLoading());
    await API.removeMessage(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          await responseAlert({ res, label: "Remove message" }, true);
          dispatch(removeMessageSuccess());
        } else {
          responseAlert({ res });
          dispatch(messageHasError());
        }
      })
      .catch((e: any) => {
        dispatch(messageHasError());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

export {
  fetchMessages,
  fetchDirectMessage,
  removeMessage,
  setAllMessages,
  setTotalNewMessages,
  setDirectMessages
};
