import styled, { keyframes } from "styled-components";
import ColorName from "constants/Variables";

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;
const visibleOverflow = keyframes`
  0% {
    overflow: hidden;
  }
  99% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
`;

const hiddenOverflow = keyframes`
  0% {
    overflow: visible;
  }
  100% {
    overflow: hidden;
  }
`;

const ArticlesContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 960px;
  padding: 0 80px;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (max-width: 426px) {
    padding: 0 15px 30px 15px;
  }

  @media screen and (max-width: 810px) {
    padding: 0 30px 30px 30px;
  }
  h2 {
    color: ${ColorName.william};
    margin-bottom: 5px;
  }
`;

const CategoryTitle = styled.h1`
  width: max-content;
  transition: all ease 0.25s;
  i {
    font-size: 20px;
    transition: all ease 0.25s;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: ${ColorName.burntSienna};
    i {
      transform: translateX(5px);
    }
  }
`;

const ArticlesTitle = styled.h2`
  font-size: 22px;
  font-weight: 700;
  margin: 0;
  transition: all ease 0.25s;

  &:hover {
    cursor: pointer;
    color: ${ColorName.burntSienna};
  }
`;

const ArticlesDescription = styled.p`
  font-size: 16px;
  color: rgba(117, 117, 117, 1);
  margin: 5px 0 15px 0;
`;

const ArticleTime = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${ColorName.burntSienna};
`;

const ArticleAuthor = styled.div`
  font-size: 13px;
`;

const ArticleContent = styled.div`
  padding: 25px 0;
  border-bottom: 1px solid black;

  a {
    font-size: 22px;
    font-weight: 700;
    margin: 0;
    transition: all ease 0.25s;
    color: black;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      color: ${ColorName.burntSienna};
    }
  }

  &:nth-child(2) {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: 1px solid transparent;
    padding-bottom: 0;
  }
`;

const ArticlesDetailsContainer = styled.div`
  width: 100%;
  max-width: 960px;
  height: 100%;
  margin: 0 auto;
  padding: 0 80px 30px 80px;
  box-sizing: border-box;

  h1 {
    border-bottom: 1px solid black;
    padding-bottom: 5px;
  }

  a {
    color: black;
    height: 29px;
    width: 29px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid transparent;
    transition: all ease 0.25s;

    &:hover {
      border-color: black;
    }
  }

  & .source {
    font-weight: bold;
  }
  & .media-type {
    padding: 3px 5px;
    border: 1px solid black;
    color: black;
    border-radius: 5px;
    margin-right: 5px;
  }
  & .time {
    font-weight: bold;
    color: ${ColorName.burntSienna};
    margin: 0 5px;
  }
  & .content {
    margin-top: 15px;
    line-height: 1.7;
    font-size: 15px;
    white-space: pre-line;
  }

  @media screen and (max-width: 426px) {
    padding: 0 15px 30px 15px;
    h1 {
      font-size: 22px;
    }
  }

  @media screen and (max-width: 810px) {
    padding: 0 30px 30px 30px;
  }
`;

const BackButton = styled.div`
  padding: 5px;
  border: 1px solid black;
  transition: all ease 0.25s;
  &:hover {
    cursor: pointer;
    color: white;
    background: black;
  }
`;

const ColItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const NotFoundContainer = styled.div`
  width: 100%;
  height: calc(100vh - 120px);
  max-width: 720px;
  padding: 0 80px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  img {
    width: 100%;
    /* max-width: 550px; */
  }
  h2 {
    margin: 0;
  }
  p {
    color: ${ColorName.regentGray};
  }

  &.empty_list {
    box-sizing: border-box;
    img {
      max-width: 370px;
    }
  }
`;

const SkeletonLoading = styled.div`
  background: ${ColorName.white};
  box-sizing: border-box;
  transition: all ease-in-out 0.55s;
  transition-delay: 0.1s;
  position: relative;
  will-change: background;
  min-height: 237px;
  padding: 40px 0;
  z-index: -1;
  &.articles-list {
    border-bottom: 1px solid #ccc;
  }

  & .author,
  & .author-details,
  & .title-details,
  & .content-details,
  & .title,
  & .content,
  & .category,
  & .date {
    width: 100px;
    position: relative;
    height: 30px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: #dddbdd;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        #ddd 0px,
        #e8e8e8 40px,
        #ddd 80px
      );
      animation: ${shimmer} 2s infinite;
      content: "";
    }
  }

  & .author {
    width: 20%;
    height: 25px;
    margin-bottom: 10px;
  }
  & .author-details {
    width: 20%;
    height: 25px;
  }
  & .title {
    width: 85%;
    height: 40px;
    margin-bottom: 15px;
  }
  & .content {
    width: 45%;
    height: 28px;
    &:nth-child(3) {
      margin-bottom: 5px;
      width: 100%;
    }
  }
  & .category {
    width: 60%;
    height: 32px;
  }
  & .date-time {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  & .category {
    display: inline-block;
    margin-top: 10px;
    width: 100px;
    margin-right: 15px;
    height: 20px;
  }
  & .date {
    display: inline-block;
    margin-top: 10px;
    width: 100px;
    height: 20px;
  }
  & .date-time-details {
    width: 100%;
    margin-bottom: 20px;
  }
  & .title-details {
    width: 100%;
    height: 35px;

    &:nth-child(2) {
      width: 40%;
      margin-top: 5px;
      margin-bottom: 15px;
    }
  }
  & .content-details {
    width: 100%;
    height: 30px;
    margin-bottom: 8px;

    &:last-child {
      width: 30%;
    }
  }
`;

const ArticlesFilter = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Button = styled.div`
  width: max-content;
  height: auto;
  padding: 5px 10px;
  transition: all ease 0.25s;

  color: ${ColorName.william};
  background-color: ${ColorName.white};
  border: 1px solid ${ColorName.william};
  margin-right: 10px;
  border-radius: 5px;
  padding: 7px 20px;

  &:hover {
    background-color: ${ColorName.william};
    color: white;
    cursor: pointer;
  }
`;

const AdvanceFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  max-height: 0px;
  overflow: hidden;
  transition: all ease 0.25s;
  animation: ${hiddenOverflow} ease 0.25s;

  &.show {
    max-height: 170px;
    overflow: visible;
    animation: ${visibleOverflow} ease 0.25s;
  }
`;

const AdvanceFilterLabel = styled.label`
  color: ${ColorName.william};
  user-select: none;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export {
  ArticlesContainer,
  CategoryTitle,
  ArticlesTitle,
  ArticlesDescription,
  ArticleTime,
  ArticleAuthor,
  ArticleContent,
  ArticlesDetailsContainer,
  BackButton,
  ColItem,
  NotFoundContainer,
  SkeletonLoading,
  ArticlesFilter,
  Button,
  AdvanceFilter,
  AdvanceFilterLabel,
};
