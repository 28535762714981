import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Typography,
  TextField,
  IconButton,
  FormControlLabel,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormLabel,
  RadioGroup,
  Radio,
  Button,
  Divider,
  Chip,
  FormGroup,
  Checkbox,
  Collapse,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

import { RootState } from "reducers";
import {
  AccountActions,
  DataRecordsActions,
  FiltersActions,
  FoldersActions,
  SourceSettingsActions,
} from "actions";

import { popupAlert, generateDropdown } from "libs/Utils";

import { CONSTANTS } from "constants/Constants";
import RouteTypes from "constants/RouteTypes";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import { ContainerSearch, Spinner } from "components/Common";
import {
  FilterPost,
  ViewCheckedPost,
  AddPostResult,
  AddPostToFolders,
  ViewDetailsNews,
} from "components/Common/Popup";
import { QuantityLabel } from "./Post.styles";
import ColorName from "../../../constants/Variables";
import PostData from "./PostData";
import RenderPDF from "../../../components/Common/RenderPDF";

const { fetchAllSource, clearSources } = SourceSettingsActions;
const { searchAccounts, clearPackages } = AccountActions;
const { searchFolders, clearFolders } = FoldersActions;
const { searchFilters, clearFilters } = FiltersActions;
const {
  clearDataRecord,
  filterDataRecordInPost,
  clearDataRecords,
  hiddenDataRecord,
  hiddenDataRecords,
  clearHidePost,
} = DataRecordsActions;

const SORT_BY = [
  {
    label: "author",
    value: "author",
  },
  {
    label: "headline",
    value: "title",
  },
  {
    label: "newest",
    value: "newest",
  },
  {
    label: "oldest",
    value: "oldest",
  },
];

const defaultFilterPost = {
  dateFrom: "",
  dateTo: "",
  filterIds: [],
  mediaType: ["print", "web", "tv", "social network"],
  sortBy: "",
  keyword: "",
  accountId: [],
  source: "",
  status: "active",
  limit: 20,
  offset: 1,
};

const DEFAULT_SHOW_SELECTED = [
  { lable: "Open", value: "open" },
  { lable: "Close", value: "close" },
];

const MEDIA_TYPES = [
  { label: "Print", value: "print" },
  { label: "TV", value: "tv" },
  { label: "WEB", value: "web" },
  { label: "Social Network", value: "social network" },
];

const Post: React.FC = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const accountIsLoading = useSelector((state: RootState) => {
    return state.Account.isLoading;
  });
  // const sourceIsLoading = useSelector((state: RootState) => {
  //   return state.SourceSettings.isLoading;
  // });
  const isDataRecordLoading = useSelector((state: RootState) => {
    return state.DataRecord.isLoading;
  });
  const isHiddenSuccess = useSelector((state: RootState) => {
    return state.DataRecord.isHiddenSuccess;
  });
  // const folderIsLoading = useSelector((state: RootState) => {
  //   return state.Folders.isLoading;
  // });
  const accounts = useSelector((state: RootState) => {
    return state.Account.accounts;
  });
  const sources = useSelector((state: RootState) => {
    return state.SourceSettings.sourceSettings;
  });
  const dataRecord = useSelector((state: RootState) => {
    return state.DataRecord.dataRecord;
  });
  const folders = useSelector((state: RootState) => {
    return state.Folders.folders;
  });
  const filters = useSelector((state: RootState) => {
    return state.Filters.filters;
  });
  const responseMessages = useSelector((state: RootState) => {
    return state.Folders.responseMessages;
  });
  const isSendSuccess = useSelector((state: RootState) => {
    return state.DataRecord.isSendMailSuccess;
  });
  const [dataAddToFolder, setDataAddToFolder] = useState({});
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  const [isShowResponseMessages, setIsShowResponseMessages] =
    useState<boolean>(false);
  const [isShowNewsDetails, setIsShowNewsDetails] = useState<boolean>(false);
  const [dataRecords, setDataRecords] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>(defaultFilterPost);
  const [isShowFilterPopup, setIsShowFilterPopup] = useState<boolean>(false);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [isShowViewPost, setIsShowViewPost] = useState<boolean>(false);
  const [checkedPost, setCheckedPost] = useState<any>([]);
  const [dataCheckeckPost, setDataCheckedPost] = useState<any[]>([]);
  const [newsDetails, setNewsDetails] = useState<any>({});
  const [isShowAddPostToFolderPopup, setIsShowAddPostToFolderPopup] =
    useState<boolean>(false);
  const [addFolderOptions, setAddFolderOptions] = useState<any[]>([]);
  const [filterOptions, setFilterOptions] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [valueFolder, setValueFolder] = useState<string>("");
  const [pos, setPos] = useState(false);
  const [showSelected, setShowSelected] = useState<boolean>(false);

  useEffect(() => {
    dispatch(searchFilters({ status: CONSTANTS.STATUS.ACTIVE, limit: 100 }));
    dispatch(searchFolders({ status: CONSTANTS.STATUS.ACTIVE, limit: 100 }));
    dispatch(searchAccounts({ status: CONSTANTS.STATUS.ACTIVE, limit: 100 }));
    dispatch(fetchAllSource());
    dispatch(filterDataRecordInPost(defaultFilterPost));

    return () => {
      dispatch(clearDataRecords());
      dispatch(clearDataRecord());
      dispatch(clearFolders());
      dispatch(clearFilters());
      dispatch(clearSources());
      dispatch(clearPackages());
    };
  }, []);

  useEffect(() => {
    if (isSendSuccess || isHiddenSuccess) {
      setDataCheckedPost([]);
      setCheckedPost([]);
      dispatch(clearHidePost());
    }
  }, [isSendSuccess, isHiddenSuccess]);

  useEffect(() => {
    if (!_.isEmpty(dataRecord)) {
      const keyword = _.get(filterData, "keyword");
      const getDataRecords: any[] = _.get(dataRecord, "listRecords");
      const resolveData: any[] = [];
      if (_.isArray(getDataRecords) && !_.isEmpty(getDataRecords)) {
        if (_.trim(keyword))
          _.forEach(getDataRecords, (item) => {
            const title = _.get(item, "title");
            const content = _.get(item, "content");
            if (
              _.includes(_.lowerCase(title), _.lowerCase(_.trim(keyword))) ||
              _.includes(_.lowerCase(content), _.lowerCase(_.trim(keyword)))
            ) {
              resolveData.push({
                ...item,
                listKeywords: [..._.get(item, "listKeywords") || [], keyword],
              });
            }
          });
        else resolveData.push(...getDataRecords);
        if (currentPage > 1) setDataRecords([...dataRecords, ...resolveData]);
        else setDataRecords(resolveData);
      } else setDataRecords([]);
    }
  }, [dataRecord]);

  useEffect(() => {
    if (filterData) {
      // dispatch(clearDataRecord());
      const accountIds = _.get(filterData, "accountId");
      const getFilters = _.get(filters, "items");
      if (!_.isEmpty(accountIds)) {
        if (_.isEmpty(filters)) {
          dispatch(searchFilters({ accountId: accountIds }));
        }
      }
      const packageList = _.get(accounts, "items");
      const filterPackages: any[] = [];
      _.forEach(packageList, (item) => {
        if (_.includes(accountIds, _.get(item, "id"))) {
          filterPackages.push({
            label: _.get(item, "accountName"),
            value: _.get(item, "id"),
          });
        }
      });
      if (!_.isEmpty(getFilters)) {
        const resolveFilters = _.filter(
          getFilters,
          (item) => _.includes(accountIds, _.get(item, "accountId.id")) && item
        );
        setFilterOptions(
          generateDropdown({
            data: resolveFilters,
            key: "filterName",
            value: "id",
          })
        );
      }
      setAddFolderOptions(filterPackages);
    }
  }, [filterData]);

  useEffect(() => {
    if (!_.isEmpty(responseMessages)) setIsShowResponseMessages(true);
  }, [responseMessages]);

  const validateFilter = () => {
    const validateFilter = [
      {
        label: t("label.packages"),
        value: _.get(filterData, "accountId"),
      },
      // {
      //   label: t("label.sources"),
      //   value: _.get(filterData, "source"),
      // },
    ];
    let flag = true;
    let message = `${t("alert.foundEmptyFields")}`;
    _.map(validateFilter, (item) => {
      const { label, value } = item;
      if (!value || _.isEmpty(value)) {
        flag = false;
        message += `${label}\n`;
      }
    });
    return { flag, message };
  };

  const onSubmit = (offset: number) => {
    // const { flag, message } = validateFilter();
    // if (!flag)
    //   return popupAlert({
    //     title: t("alert.warning"),
    //     type: "warning",
    //     text: message,
    //   });
    const resolvePayload = {
      ...filterData,
      offset,
    };
    const mediaType = _.get(filterData, "mediaType");
    const filterIds = _.get(filterData, "filterIds");
    const accountId = _.get(filterData, "accountId");
    const source = _.get(filterData, "source");
    const status = _.get(filterData, "status");
    _.assign(resolvePayload, {
      mediaType: _.join(mediaType, ","),
      filterIds: _.join(filterIds, ","),
      accountId: _.join(accountId, ","),
      source: _.join(source, ","),
      status: status === "all" ? "" : status,
    });
    dispatch(filterDataRecordInPost(resolvePayload));
    setCurrentPage(offset);
    if (offset === 1) setDataRecords([]);
    if (!isFiltered) setIsFiltered(true);
    setIsOpenFilter(false);
    setShowSelected(false);
  };

  const onReset = async () => {
    setFilterData(defaultFilterPost);
    dispatch(clearDataRecord());
  };

  const onChangeFilter = (key: string, value: string | string[] | []) => {
    const newFilterData = {
      ...filterData,
      [key]: value,
    };
    setFilterData(newFilterData);
  };

  const onChangeFilterAccounts = (
    key: string,
    value: string | string[] | []
  ) => {
    const newFilterData = {
      ...filterData,
      [key]: value,
    };
    const resolvePayload = {
      ...newFilterData,
    };
    const accountId = _.get(newFilterData, "accountId");
    const mediaType = _.get(newFilterData, "mediaType");
    const filterIds = _.get(newFilterData, "filterIds");
    const source = _.get(newFilterData, "source");
    const status = _.get(newFilterData, "status");
    _.assign(resolvePayload, {
      accountId: _.join(accountId, ","),
      mediaType: _.join(mediaType, ","),
      filterIds: _.join(filterIds, ","),
      source: _.join(source, ","),
      status: status === "all" ? "" : status,
    });
    setFilterData(newFilterData);
    dispatch(filterDataRecordInPost(resolvePayload));
    setShowSelected(false);
  };

  const onCloseFilter = (value: any) => {
    if (value) setFilterData({ ...filterData, ...value });
    setIsShowFilterPopup(false);
  };

  const onChangeCheckedPost = (post: any) => {
    const postId = _.get(post, "id");
    const isIncludes = _.includes(checkedPost, postId);
    if (isIncludes) {
      const newCheckedPost = _.filter(checkedPost, (item) => item !== postId);
      const newDataCheckedPost = _.filter(
        dataCheckeckPost,
        (item) => _.get(item, "id") !== postId
      );
      setDataCheckedPost(newDataCheckedPost);
      setCheckedPost(newCheckedPost);
    } else {
      setDataCheckedPost([...dataCheckeckPost, post]);
      setCheckedPost([...checkedPost, postId]);
    }
  };

  const onChangeSelectAll = (ids: string[]) => {
    if (checkedPost.length === dataRecords.length) {
      setDataCheckedPost([]);
      setCheckedPost([]);
    } else {
      setDataCheckedPost(dataRecords);
      setCheckedPost(ids);
    }
  };

  const handlePostActions = (action: string, item?: string) => {
    if (action === "add") {
      setDataAddToFolder({
        packages: _.get(filterData, "accountId"),
        data: [item],
      });
      setIsShowAddPostToFolderPopup(true);
    }
    if (action === "view") {
      setNewsDetails(item);
      setIsShowNewsDetails(true);
    }
    if (action === "hidden") {
      const currentStatus = _.get(item, "status");
      const dataRecordId = _.get(item, "id");
      popupAlert({
        title: t("alert.warning"),
        text: t("alert.warningBeforeHiddenDataRecord"),
        type: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willContinue) => {
        if (willContinue) {
          if (currentStatus === CONSTANTS.STATUS.ACTIVE) {
            const resolvePayload = {
              ...filterData,
              offset: currentPage,
            };
            const mediaType = _.get(filterData, "mediaType");
            const filterIds = _.get(filterData, "filterIds");
            const accountId = _.get(filterData, "accountId");
            const source = _.get(filterData, "source");
            const status = _.get(filterData, "status");
            _.assign(resolvePayload, {
              mediaType: _.join(mediaType, ","),
              filterIds: _.join(filterIds, ","),
              accountId: _.join(accountId, ","),
              source: _.join(source, ","),
              status: status === "all" ? "" : status,
            });
            await dispatch(
              hiddenDataRecord(item, dataRecordId, resolvePayload)
            );
          }
        }
      });
    }
  };

  const onSendMailWithCheckedPosts = () => {
    if (_.isEmpty(checkedPost))
      return popupAlert({
        type: "warning",
        title: t("alert.warning"),
        text: t("alert.atLeastOnePost"),
      });
    setIsShowViewPost(true);
  };

  const onHiddenCheckedPosts = () => {
    if (_.isEmpty(checkedPost))
      return popupAlert({
        type: "warning",
        title: t("alert.warning"),
        text: t("alert.atLeastOnePost"),
      });
    popupAlert({
      title: t("alert.warning"),
      text: t("alert.warningBeforeHiddenDataRecord"),
      type: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willContinue) => {
      if (willContinue) {
        const resolvePayload = {
          ...filterData,
          offset: currentPage,
        };
        const mediaType = _.get(filterData, "mediaType");
        const filterIds = _.get(filterData, "filterIds");
        const accountId = _.get(filterData, "accountId");
        const source = _.get(filterData, "source");
        const status = _.get(filterData, "status");
        _.assign(resolvePayload, {
          mediaType: _.join(mediaType, ","),
          filterIds: _.join(filterIds, ","),
          accountId: _.join(accountId, ","),
          source: _.join(source, ","),
          status: status === "all" ? "" : status,
        });
        await dispatch(hiddenDataRecords(checkedPost, resolvePayload));
      }
    });
  };

  const onChangePage = (page: number) => {
    const newFilter = {
      ...filterData,
      offset: page,
    };
    setFilterData(newFilter);
    onSubmit(page);
  };

  const onAddPostToFolder = (packageId: string) => {
    if (_.isEmpty(dataCheckeckPost)) {
      setValueFolder("");
      return popupAlert({
        title: t("alert.warning"),
        text: t("alert.atLeastOnePost"),
        type: "warning",
      });
    }

    let isIncludesPostOfPackage = false;
    _.forEach(dataCheckeckPost, (item) => {
      const ofPackageId = _.get(item, "account.id");
      if (ofPackageId === packageId) isIncludesPostOfPackage = true;
    });

    if (!isIncludesPostOfPackage) {
      setValueFolder("");
      return popupAlert({
        title: t("alert.warning"),
        text: t("alert.noPostFoundInTheSelectedPackage"),
        type: "warning",
      });
    }

    let isExistFolder = false;
    const folderList = _.get(folders, "items");
    _.forEach(folderList, (item) => {
      const ofPackageId = _.get(item, "accountId.id");
      if (ofPackageId === packageId) isExistFolder = true;
    });
    if (!isExistFolder) {
      setValueFolder("");
      return popupAlert({
        title: t("alert.warning"),
        text: t("alert.noFolderFoundToAddSelectedPosts"),
        type: "warning",
      });
    }
    setDataAddToFolder({
      packages: [packageId],
      data: dataCheckeckPost,
    });
    setIsShowAddPostToFolderPopup(true);
    setValueFolder(packageId);
  };

  const handleCheckMediaType = (value: string) => {
    let newMediaTypes = [..._.get(filterData, "mediaType")];
    if (!_.includes(newMediaTypes, value)) newMediaTypes.push(value);
    else newMediaTypes = _.filter(newMediaTypes, (el) => el !== value);
    setFilterData({ ...filterData, mediaType: newMediaTypes });
  };

  const onFetchMore = () => {
    if (!showSelected)
      if (
        !isDataRecordLoading &&
        currentPage < _.get(dataRecord, "meta.totalPages")
      )
        onChangePage(filterData.offset + 1);
  };

  // Renders
  const _renderQtyByMediaType = () => {
    const mediaType = _.get(filterData, "mediaType");
    return (
      <Grid container spacing={2}>
        <Grid item>
          <QuantityLabel>
            <i className="fas fa-folder-open" />
            {t("label.all")} {_.get(dataRecord, "total") || 0}
          </QuantityLabel>
        </Grid>
        {_.includes(mediaType, "print") && (
          <Grid item>
            <QuantityLabel>
              <i className="fas fa-print" />
              {t("label.print")} {_.get(dataRecord, "totalPrint") || 0}
            </QuantityLabel>
          </Grid>
        )}
        {_.includes(mediaType, "web") && (
          <Grid item>
            <QuantityLabel>
              <i className="fab fa-chrome" />
              {t("label.web")} {_.get(dataRecord, "totalWeb") || 0}
            </QuantityLabel>
          </Grid>
        )}
        {_.includes(mediaType, "tv") && (
          <Grid item>
            <QuantityLabel>
              <i className="fas fa-tv" />
              {t("label.tv")} {_.get(dataRecord, "totalTv") || 0}
            </QuantityLabel>
          </Grid>
        )}
        {_.includes(mediaType, "social network") && (
          <Grid item>
            <QuantityLabel>
              <i className="fas fa-share-alt" />
              {t("label.social")} {_.get(dataRecord, "totalSocial") || 0}
            </QuantityLabel>
          </Grid>
        )}
      </Grid>
    );
  };

  const _renderAccountOptions = () => (
    <FormControl fullWidth>
      <InputLabel id="select-label" sx={{ top: -5 }}>
        Accounts
      </InputLabel>
      <Select
        size="small"
        labelId="select-filter"
        value={_.get(filterData, "accountId")}
        label="Accounts"
        multiple
        onChange={(e) => {
          if (_.isArray(e.target.value))
            onChangeFilterAccounts("accountId", e.target.value);
        }}
        renderValue={(selected) => (
          <Box
            sx={{
              display: "flex",
              width: 1,
              gap: 0.5,
            }}
          >
            {selected.length} accounts selected
          </Box>
        )}
      >
        {_.map(_.get(accounts, "items") || [], (item, index) => (
          <MenuItem key={index} value={_.get(item, "id")}>
            {_.get(item, "accountName")}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const _renderByDate = () => (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <DatePicker
          label="Date From"
          format="DD/MM/YYYY"
          value={filterData.dateFrom ? dayjs(filterData.dateFrom) : null}
          onChange={(e: Dayjs | null) =>
            onChangeFilter("dateFrom", dayjs(e).format("YYYY/MM/DD 00:00:00"))
          }
          sx={{ width: 1 }}
          slotProps={{ textField: { size: "small" } }}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          label="Date To"
          format="DD/MM/YYYY"
          value={filterData.dateTo ? dayjs(filterData.dateTo) : null}
          onChange={(e: Dayjs | null) =>
            onChangeFilter("dateTo", dayjs(e).format("YYYY/MM/DD 23:59:59"))
          }
          sx={{ width: 1 }}
          slotProps={{ textField: { size: "small" } }}
        />
      </Grid>
    </Grid>
  );

  const _renderFilters = () => (
    <FormControl fullWidth>
      <InputLabel id="select-filter" sx={{ top: -5 }}>
        Filters
      </InputLabel>
      <Select
        labelId="select-filter"
        value={_.get(filterData, "filterIds")}
        label="Filters"
        multiple
        size="small"
        disabled={_.isEmpty(filterOptions)}
        onChange={(e) => {
          if (_.isArray(e.target.value))
            onChangeFilter("filterIds", e.target.value);
        }}
        renderValue={(selected) => (
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              width: 1,
              overflow: "auto",
            }}
          >
            {selected.map((value: any) => (
              <Chip
                key={value}
                label={_.find(filterOptions, ["value", value])?.label}
              />
            ))}
          </Box>
        )}
      >
        {_.map(filterOptions, (item, index) => (
          <MenuItem key={index} value={_.get(item, "value")}>
            {_.get(item, "label")}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const _renderMediaTypes = () => (
    <Grid item xs={6}>
      <FormLabel>Media Types</FormLabel>
      <FormGroup>
        {_.map(MEDIA_TYPES, (field, index) => {
          const { value, label } = field;
          const checked = _.includes(_.get(filterData, "mediaType"), value);
          return (
            <FormControlLabel
              key={`mediaType-${value}-${index}`}
              control={<Checkbox size="small" checked={checked} />}
              label={label}
              onChange={() => handleCheckMediaType(value)}
            />
          );
        })}
      </FormGroup>
    </Grid>
  );

  const _renderSortBy = () => (
    <Grid item xs={6}>
      <FormControl>
        <FormLabel id="sortBy">{t("label.sortBy")}</FormLabel>
        <RadioGroup
          aria-labelledby="sortBy"
          value={_.get(filterData, "sortBy")}
          onChange={(e) => onChangeFilter("sortBy", e.target.value)}
          row
        >
          {_.map(SORT_BY, (field, index) => {
            const { value, label } = field;
            return (
              <FormControlLabel
                key={`sortBy-${value}-${index}`}
                value={value}
                label={t(`label.${label}`)}
                control={<Radio size="small" />}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Grid>
  );

  const _renderStickyHeader = () => (
    <ContainerSearch
      className={`post-search-field ${isDataRecordLoading ? "loading" : ""}`}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography
                sx={{ fontWeight: "bold", color: ColorName.burntSienna }}
              >
                Today: {dayjs().format("DD/MM/YYYY")}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setIsOpenFilter(!isOpenFilter)}>
                {isOpenFilter ? <FilterAltOffIcon /> : <FilterAltIcon />}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Collapse in={isOpenFilter}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {_renderByDate()}
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {_renderFilters()}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      onChange={(e) =>
                        onChangeFilter("keyword", e.target.value)
                      }
                      fullWidth
                      value={_.get(filterData, "keyword") || ""}
                      label={t("placeholder.keyword")}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {_renderAccountOptions()}
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-folder" sx={{ top: -5 }}>
                        {t("button.addSelectedPostToFolder")}
                      </InputLabel>
                      <Select
                        value={valueFolder}
                        size="small"
                        labelId="select-folder"
                        fullWidth
                        label={t("button.addSelectedPostToFolder")}
                        onChange={(e) =>
                          onAddPostToFolder(_.toString(e.target.value))
                        }
                        disabled={_.isEmpty(addFolderOptions)}
                      >
                        {_.map(addFolderOptions, (item, index) => (
                          <MenuItem key={index} value={_.get(item, "value")}>
                            {_.get(item, "label")}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={8}>
                      {_renderQtyByMediaType()}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container spacing={1}>
                  {_renderMediaTypes()}
                  {_renderSortBy()}
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="burntSienna"
                      fullWidth
                      onClick={() => onSubmit(1)}
                      size="small"
                    >
                      {t("button.filter")}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="error"
                      fullWidth
                      onClick={() => onReset()}
                      size="small"
                    >
                      {t("button.reset")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={6}>
                  {!isOpenFilter ? (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        {_renderAccountOptions()}
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <InputLabel id="select-folder" sx={{ top: -5 }}>
                            {t("button.addSelectedPostToFolder")}
                          </InputLabel>
                          <Select
                            value={valueFolder}
                            size="small"
                            labelId="select-folder"
                            fullWidth
                            label={t("button.addSelectedPostToFolder")}
                            onChange={(e) =>
                              onAddPostToFolder(_.toString(e.target.value))
                            }
                            disabled={_.isEmpty(addFolderOptions)}
                          >
                            {_.map(addFolderOptions, (item, index) => (
                              <MenuItem
                                key={index}
                                value={_.get(item, "value")}
                              >
                                {_.get(item, "label")}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      Selected stories: <b>{checkedPost.length}</b> --- Filter
                      results: <b>{dataRecords?.length || 0}</b> of{" "}
                      <b>{_.get(dataRecord, "meta.totalItems") || 0}</b>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="end"
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => onSendMailWithCheckedPosts()}
                        size="small"
                      >
                        {t("button.sendMailWithSelectedPost")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={() => onHiddenCheckedPosts()}
                        color="error"
                        fullWidth
                        size="small"
                      >
                        Hide
                      </Button>
                    </Grid>
                    <Grid item>
                      <RenderPDF
                        isDownload
                        data={dataCheckeckPost}
                        multipleRow
                        isHaveButton
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {!isOpenFilter && (
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    {_renderQtyByMediaType()}
                  </Grid>
                  <Grid item xs={5}>
                    <Box>
                      Selected stories: <b>{checkedPost.length}</b> --- Filter
                      results: <b>{dataRecords?.length || 0}</b> of{" "}
                      <b>{_.get(dataRecord, "meta.totalItems") || 0}</b>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sx={{ pt: "0!important" }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedPost.length === dataRecords.length}
                      />
                    }
                    label={<Typography>Select All</Typography>}
                    onChange={() => onChangeSelectAll(_.map(dataRecords, "id"))}
                  />
                </Grid>
                <Box mb={1}>
                  <FormControlLabel
                    control={<Checkbox checked={showSelected} />}
                    label={
                      <Typography>{t("label.showSelectedStories")}</Typography>
                    }
                    onChange={(event: any) =>
                      setShowSelected(event?.target?.checked)
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContainerSearch>
  );

  const renderMain = () => {
    return (
      <>
        {isShowFilterPopup && (
          <FilterPost
            filterData={filterData}
            onClose={(value: any) => onCloseFilter(value)}
            accountId={_.get(filterData, "accountId")}
          />
        )}
        {isShowViewPost && (
          <ViewCheckedPost
            dataRecords={dataCheckeckPost}
            onClose={() => setIsShowViewPost(false)}
          />
        )}
        {isShowResponseMessages && (
          <AddPostResult onClose={() => setIsShowResponseMessages(false)} />
        )}
        {isShowAddPostToFolderPopup && (
          <AddPostToFolders
            postPayload={dataAddToFolder}
            // packages={_.get(filterData, "accountId")}
            onClose={() => {
              setIsShowAddPostToFolderPopup(false);
            }}
          />
        )}
        {isShowNewsDetails && (
          <ViewDetailsNews
            data={newsDetails}
            onClose={() => {
              setIsShowNewsDetails(false);
              setNewsDetails({});
            }}
          />
        )}
        {_renderStickyHeader()}

        <Grid container justifyContent="center">
          <PostData
            data={showSelected ? dataCheckeckPost : dataRecords}
            checked={checkedPost}
            onCheck={(post: any) => onChangeCheckedPost(post)}
            onClick={(action: string, item: any) =>
              handlePostActions(action, item)
            }
          />
          {isDataRecordLoading && <Spinner />}
          {currentPage === _.get(dataRecord, "meta.totalPages") &&
            "There is no more articles!"}
        </Grid>
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.ADMIN_ROUTES.POST}
      // parent={RouteTypes.ADMIN_ROUTES.FOLDERS}
      title={t("titlePage.posts")}
      onScrollBottom={() => onFetchMore()}
    />
  );
};
export default Post;
