import Api from "./Base";
import { API } from "constants/Constants";

const { USER_OF_CLIENT } = API;

export function registerOfClient(data: any) {
  return Api.postFormData(USER_OF_CLIENT.REGISTER, data);
}

export function registerUserOfClient(data: any) {
  return Api.postFormData(USER_OF_CLIENT.REGISTER_USER, data);
}

export function fetchUsersOfClient(data: any) {
  return Api.get(USER_OF_CLIENT.BASIC, data);
}

export function updateUserOfClient(data: any, id: string) {
  return Api.putFormData(`${USER_OF_CLIENT.UPDATE}/${id}`, data);
}

export function updateAccountListUserOfClient(id: string, data: any) {
  return Api.put(`${USER_OF_CLIENT.UPDATE_ACCOUNT_LIST}/${id}`, data);
}

export function deactivateUserOfClient(id: string) {
  return Api.put(`${USER_OF_CLIENT.DEACTIVATE}/${id}`);
}

export function activateUserOfClient(id: string) {
  return Api.put(`${USER_OF_CLIENT.ACTIVATE}/${id}`);
}

export function forgotPasswordOfClient(data: any) {
  return Api.post(USER_OF_CLIENT.FORGOT_PASSWORD, data);
}

export function resetPasswordOfClient(data: any) {
  return Api.post(USER_OF_CLIENT.RESET_PASSWORD, data);
}

export function verifyAccountOfClient(data: any) {
  return Api.post(USER_OF_CLIENT.VERIFY_ACCOUNT, data);
}

export function exportUsersOfClient() {
  return Api.getBlob(USER_OF_CLIENT.EXPORT);
}

export function getUserByIdOfClient(id: string) {
  return Api.get(`${USER_OF_CLIENT.GET_USER_BY_ID}/${id}`);
}

export function updateUserForSelfOfClient(data: any) {
  return Api.putFormData(USER_OF_CLIENT.UPDATE_FOR_SELF, data);
}

export function getAllMember(data: any) {
  return Api.get(USER_OF_CLIENT.GET_ALL_MEMBER, data);
}
