import _ from "lodash";
import React, { KeyboardEventHandler, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { TagAreaContainer } from "./TagArea.styles";

const components = {
  DropdownIndicator: null,
};

const createOption = (label: string) => ({
  label,
  value: label,
});

interface SectionProps {
  tags: string[];
  onChange(value: string[]): void;
  placeholder?: string;
}

const TagArea = (props: SectionProps) => {
  const { tags, onChange, placeholder } = props;
  const [inputValue, setInputValue] = useState<string>("");
  const [value, setValue] = useState<any[]>([]);

  useEffect(() => {
    if (tags) {
      const resolveTags: any[] = [];
      _.forEach(tags, (tag) => resolveTags.push(createOption(tag)));
      setValue(resolveTags);
    }
  }, [tags]);

  const handleChange = (newValue: any, actionMeta: any) => {
    const resolveValue: string[] = [];
    _.forEach(newValue, (item) => resolveValue.push(_.get(item, "value")));
    onChange([...resolveValue]);
  };

  const handleInputChange = (newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const resolveNewValue = () => {
    const resolveValue: string[] = [];
    _.forEach(value, (item) => resolveValue.push(_.get(item, "value")));
    const isIncludes = _.includes(tags, inputValue);
    if (!isIncludes) onChange([...resolveValue, inputValue]);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setInputValue("");
        resolveNewValue();
    }
  };

  return (
    <TagAreaContainer>
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={value}
        className="tag-area-container"
        classNamePrefix="tag-area"
      />
    </TagAreaContainer>
  );
};

export default TagArea;
