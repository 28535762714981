/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route, Switch } from "react-router-dom";
import ROUTES from "constants/RouteTypes";

// Authentication
import {
  ForgotPassword,
  Login,
  NewPassword,
  VerifyAccount,
  UserRegister,
  UserLogin,
} from "containers/Authentication";

// Admin
import ManageSources from "containers/Admin/FileRecord";
import AddCsv from "containers/Admin/AddCsv";
import DataRecord from "containers/Admin/DataRecord";
import DataRecordDetails from "containers/Admin/DataRecord/Details";
import CategoryDetails from "containers/Admin/Categories/Details";
import Categories from "containers/Admin/Categories";
import SourcesSettings from "containers/Admin/SourcesSettings";
import SourceDetails from "containers/Admin/SourcesSettings/Details";
import Logs from "containers/Admin/Logs";
import Members from "containers/Admin/Members";
import Clients from "containers/Admin/Clients";
import CreateClient from "containers/Admin/Clients/Create";
import EditClient from "containers/Admin/Clients/Edit";
import CreateClientStepSecond from "containers/Admin/Clients/CreateClientStepSecond";
import Roles from "containers/Admin/Roles";
import RoleDetails from "containers/Admin/Roles/Details";
import Filters from "containers/Admin/Filters";
import FilterDetails from "containers/Admin/Filters/FilterDetails";
import Profile from "containers/Admin/Profile";
import Folders from "containers/Admin/Folders";
import EmailTemplate from "containers/Admin/Template/EmailManagement";
import EmailDeliveryManagement from "containers/Admin/EmailDelivery/EmailDeliveryManagement";
import EmailDeliveryDetails from "containers/Admin/EmailDelivery/EmailDeliveryDetails";
import Post from "containers/Admin/Post";
import Reports from "containers/Admin/Reports";
import ReportsFilter from "containers/Admin/Reports/ReportsFilter";
import CreateReport from "containers/Admin/Reports/createReport.v2";
import UpdateReport from "containers/Admin/Reports/updateReport.v2";
import UsersManagement from "containers/Admin/Users/UsersManagement";
import PackageDetails from "containers/Admin/Packages/PackageDetails";
import PackagesManagement from "containers/Admin/Packages/PackagesManagement";
import Contact from "containers/Admin/Contact";
import Hidden from "containers/Admin/Hidden";
import Training from "containers/Admin/Training";
import SMTP from "containers/Admin/SMTP";
import DownloadSource from "containers/Admin/DownloadSource";

// User
import UserArticles from "containers/User/Articles";
import UserArticlesDetails from "containers/User/Articles/ArticlesDetails";
import UserProfile from "containers/User/Profile";

// Client
import ClientLogs from "containers/Client/Logs";
import ClientPackagesManagement from "containers/Client/Packages/PackagesManagement";
import AccountDetails from "containers/Client/Packages/PackageDetails";
import ClientUserManagement from "containers/Client/Users/UsersManagement";
import FiltersClient from "containers/Client/Filters";
import FiltersDetailsClient from "containers/Client/Filters/FilterDetails";
import ProtectedRoute from "./ProtectedRoute";
import ClientContact from "containers/Client/Contact";
import ClientProfile from "containers/Client/Profile";
import Homepage from "containers/Admin/Homepage";
import NotFoundPage from "../containers/CommonContainers/NotFoundPage";

const PortalRoute = () => {
  return (
    <div>
      <Switch>
        <Route
          // exact
          path={ROUTES.AUTHENTICATION_ROUTES.AUTHENTICATION}
          component={Login}
        />
        <Route
          // exact
          path={ROUTES.AUTHENTICATION_ROUTES.FORGOT_PASSWORD}
          component={ForgotPassword}
        />
        <Route
          // exact
          path={ROUTES.AUTHENTICATION_ROUTES.RESET_PASSWORD}
          component={NewPassword}
        />
        <Route
          // exact
          path={ROUTES.AUTHENTICATION_ROUTES.VERIFY_ACCOUNT}
          component={VerifyAccount}
        />
        <Route
          // exact
          path={ROUTES.AUTHENTICATION_ROUTES.USER_REGISTER}
          component={UserRegister}
        />
        <Route
          // exact
          path={ROUTES.AUTHENTICATION_ROUTES.USER_LOGIN}
          component={UserLogin}
        />
        {/* Admin Routes */}
        <ProtectedRoute
          exact
          path={ROUTES.ADMIN_ROUTES.HOMEPAGE}
          component={Homepage}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.FILE_RECORD}
          component={ManageSources}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.ADD_CSV}
          component={AddCsv}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.DATA_RECORD}
          component={DataRecord}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.DATA_RECORD_DETAILS}
          component={DataRecordDetails}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.CATEGORIES_DETAILS}
          component={CategoryDetails}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.CATEGORIES}
          component={Categories}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.USERS}
          component={UsersManagement}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.SOURCE_DETAILS}
          component={SourceDetails}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.PACKAGE_DETAILS}
          component={PackageDetails}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.PACKAGES}
          component={PackagesManagement}
        />

        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.SOURCES_SETTINGS}
          component={SourcesSettings}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.LOGS}
          component={Logs}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.MEMBERS}
          component={Members}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.CREATE_CLIENT}
          component={CreateClient}
        />

        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.EDIT_CLIENT}
          component={EditClient}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.CLIENTS}
          component={Clients}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.ROLE_DETAILS}
          component={RoleDetails}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.ROLES}
          component={Roles}
        />

        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.CLIENT_STEP_SECOND}
          component={CreateClientStepSecond}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.FILTERS}
          component={Filters}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.FILTER_DETAILS}
          component={FilterDetails}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.PROFILE}
          component={Profile}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.FOLDERS}
          component={Folders}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.EMAIL_TEMPLATE}
          component={EmailTemplate}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.EMAIL_DELIVERY_DETAILS}
          component={EmailDeliveryDetails}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.EMAIL_DELIVERY}
          component={EmailDeliveryManagement}
        />

        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.POST}
          component={Post}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.REPORT_FILTER}
          component={ReportsFilter}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.CREATE_REPORT}
          component={CreateReport}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.UPDATE_REPORT}
          component={UpdateReport}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.REPORT}
          component={Reports}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.CONTACT}
          component={Contact}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.ADMIN_ROUTES.HIDDEN}
          component={Hidden}
        />
        <Route
          // exact
          path={ROUTES.ADMIN_ROUTES.TRAINING}
          component={Training}
        />
        <Route
          // exact
          path={ROUTES.ADMIN_ROUTES.STMP}
          component={SMTP}
        />
        <Route
          // exact
          path={ROUTES.ADMIN_ROUTES.DOWNLOAD_SOURCE}
          component={DownloadSource}
        />

        {/* User Routes */}
        <Route
          // exact
          path={ROUTES.USER_ROUTES.PROFILE}
          component={UserProfile}
        />
        <Route
          // exact
          path={ROUTES.USER_ROUTES.USER_ARTICLES_DETAILS}
          component={UserArticlesDetails}
        />
        <Route
          // exact
          path={ROUTES.USER_ROUTES.USER_ARTICLES}
          component={UserArticles}
        />
        {/* Clients Routes */}
        <ProtectedRoute
          // exact
          path={ROUTES.CLIENT_ROUTES.LOGS}
          component={ClientLogs}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.CLIENT_ROUTES.USER_MANAGEMENT}
          component={ClientUserManagement}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.CLIENT_ROUTES.PACKAGES_DETAILS}
          component={AccountDetails}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.CLIENT_ROUTES.PACKAGES_MANAGEMENT}
          component={ClientPackagesManagement}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.CLIENT_ROUTES.FILTERS_DETAILS}
          component={FiltersDetailsClient}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.CLIENT_ROUTES.FILTERS}
          component={FiltersClient}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.CLIENT_ROUTES.CONTACT}
          component={ClientContact}
        />
        <ProtectedRoute
          // exact
          path={ROUTES.CLIENT_ROUTES.PROFILE}
          component={ClientProfile}
        />
        <Route path="*" exact={true} component={NotFoundPage} />
      </Switch>
    </div>
  );
};

export default PortalRoute;
