import styled, { keyframes } from "styled-components";
import ColorName from "constants/Variables";
import { VerticalContainer } from "components/Common";

interface SectionProps {
  height?: number;
}

const showMenu = keyframes`
  from { 
    width: 62px; 
  }
  to {
    width: 200px;
  }
`;

const hideMenu = keyframes`
  from {
    width: 200px;
  }
  to {
    width: 62px;
  }
`;

const MenuItem = styled.div`
  /* &:first-child {
    padding: 0;
    height: 60px;
  } */
  user-select: none;
  position: relative;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  height: 50px;

  &.active {
    text-shadow: 1px 0 0 ${ColorName.william};
    background: ${ColorName.linkWater};
    border: 1px solid ${ColorName.spindle};
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-sizing: border-box;
  }

  & img {
    margin-right: 1em;
  }

  & i {
    position: absolute;
    right: 1em;
    font-size: 12px;
  }

  &:first-child {
    div {
      cursor: pointer;
    }
  }
`;

const ChildItem = styled.div`
  padding: 12px;
  margin-left: 18px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  // height: 20px;

  &.active {
    text-shadow: 1px 0 0 ${ColorName.william};
    background: ${ColorName.zircon};
    border-radius: 2px;
  }

  & img {
    object-fit: scale-down;
    max-width: 20px;
  }
`;

const Wrapper = styled(VerticalContainer)`
  position: relative;
  padding: 1em;
  background: ${ColorName.zircon};
  min-height: calc(100vh - 110px);
  height: 100%;
  border-right: 1px solid ${ColorName.linkWater};
  border-bottom: 1px solid ${ColorName.linkWater};
  & .toggle {
    position: absolute;
    right: -11px;
    top: 30px;
    font-size: 20px;
    color: ${ColorName.william};
    background: ${ColorName.white};
    border-radius: 50%;
    display: flex;
    cursor: pointer;
  }
  &.show-first {
    & .show-sidebar {
      width: 200px;
    }
    & .hide-sidebar {
      width: 62px;
    }
  }

  &.show-not-first {
    & .show-sidebar {
      animation: ${showMenu} 0.5s;
      animation-fill-mode: forwards;
    }
    & .hide-sidebar {
      animation: ${hideMenu} 0.5s;
      animation-fill-mode: forwards;
    }
  }
`;

const MenuChildItem = styled.div`
  height: 0px;
  flex-direction: column;
  display: flex;
  border-left: 4px solid #ecf2fa;
  cursor: pointer;
  overflow: hidden;

  &.show-child-item {
    margin-left: 25px;
    border-left: 2px solid ${ColorName.white};
  }

  &.active {
    height: ${(props: SectionProps) =>
      `${props.height && props.height * 50}px` || "auto"};
    margin: 10px 0px 10px 20px;
  }

  & img {
    margin-right: 1em;
  }
`;

const Menu = styled.div`
  font-size: 14px;
  color: ${ColorName.william};
  line-height: 1.1;
  position: relative;

  & .menu-navigation {
    overflow-y: auto;
    height: 100%;
    max-height: calc(100vh - 88px);
  }

  /* &.loading {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 5;
    }
  } */
  & i {
    &.icon-menu {
      display: flex;
      font-size: 25px;
      position: unset;
      right: 0;
    }
  }

  &.hide-sidebar {
    min-width: 62px;
    padding-right: 0em;
    & img {
      margin: 0;
    }
    & span {
      display: none;
    }
    & ${MenuItem} {
      & i {
        display: none;
        &.icon-menu {
          display: flex;
          font-size: 25px;
        }
      }
      &.active {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    & ${MenuChildItem} {
      &.active {
        margin: 10px 0px 10px 15px;
      }
      border: none;
      & ${ChildItem} {
        &.active {
          background: ${ColorName.linkWater};
        }
        height: 20px;
        padding: 12px;
        margin: 0;
      }
    }
  }
`;

const NewMessagesBox = styled.div`
  width: 10px;
  height: 10px;
  background: ${ColorName.piper};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 10px;
`;

export { Wrapper, Menu, MenuItem, ChildItem, MenuChildItem, NewMessagesBox };
