/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { RootState } from "reducers";

import {
  Dropdown,
  Button,
  Input,
  Checkbox,
  FormGroup,
  FormInline,
  FormLabel,
  TitlePartOfForm,
  Avatar,
} from "components/Common";

import { UsersActions, EmailTemplateActions, RoleActions } from "actions";
import {
  TemplateDetailsContent,
  TemplateDetailsContainer,
  TemplateDetailsForm,
  ButtonField,
  TemplateDetailsHeader,
  OrderLayer,
  LoadingLayer,
  Spinner,
} from "../Popup.styles";
import { CONSTANTS } from "constants/Constants";
import { EmailTemplateDetails } from "../Template";
import { generateDropdown, popupAlert, validatePassword } from "libs/Utils";
import { useTranslation } from "react-i18next";
import countries from "libs/countries";
import { Images } from "../../../../themes";

const { fetchRoles } = RoleActions;
const { updateUser, registerUser, clearUserDetails } = UsersActions;
const { getEmailTemplateByType, clearEmailTemplateDetails } =
  EmailTemplateActions;

interface SectionProps {
  onClose?: () => void;
  data: any;
}

const MemberDetails: React.FC<SectionProps> = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { onClose, data } = props;
  const dispatch = useDispatch();

  const membersIsLoading = useSelector((state: RootState) =>
    _.get(state, "Users.isLoading")
  );
  const emailTemplate = useSelector((state: RootState) =>
    _.get(state, "EmailTemplate.emailTemplate")
  );
  const emailTemplateIsLoading = useSelector((state: RootState) =>
    _.get(state, "EmailTemplate.isLoading")
  );
  const roleIsLoading = useSelector((state: RootState) => {
    return state.Role.isLoading;
  });
  const roles = useSelector((state: RootState) => {
    return state.Role.roles;
  });
  const [isUpdatePassword, setIsUpdatePassword] = useState<boolean>(false);
  const [isShowCreateTemplate, setIsShowCreateTemplate] =
    useState<boolean>(false);
  const [accountData, setAccountData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    confirmationPassword: "",
    role: "",
    contactNumber: "",
    address: "",
    city: "",
    region: "",
    zipCode: "",
    userType: "",
    country: "",
  });
  const [extraData, setExtraData] = useState({
    facebook: "",
    twitter: "",
    linkedin: "",
    pinterest: "",
    instagram: "",
  });
  const [urlPreview, setUrlPreview] = useState("");
  const [accountImage, setAccountImage] = useState();

  useEffect(() => {
    dispatch(getEmailTemplateByType(CONSTANTS.EMAIL_TYPE.VERIFY_ACCOUNT));
    dispatch(fetchRoles({ status: CONSTANTS.STATUS.ACTIVE }));

    return () => {
      dispatch(clearEmailTemplateDetails());
      dispatch(clearUserDetails());
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const avatar = _.get(data, "userData.avatar");
      if (avatar) {
        const getImagePath = _.get(avatar, "imagePath");
        setUrlPreview(getImagePath);
      }

      setAccountData({
        ...accountData,
        firstName: _.get(data, "userData.firstName"),
        lastName: _.get(data, "userData.lastName"),
        email: _.get(data, "email"),
        username: _.get(data, "username"),
        role: _.get(data, "role.id"),
        contactNumber: _.get(data, "userData.phoneNumber"),
        address: _.get(data, "userData.address"),
        city: _.get(data, "userData.city"),
        region: _.get(data, "userData.regionArea"),
        country: _.get(data, "userData.country"),
        zipCode: _.get(data, "userData.zipCode"),
        userType: _.get(data, "userType"),
      });
    }
  }, [data]);

  const processData = () => {
    const isHasId = _.has(data, "id");
    const formData = new FormData();
    formData.append("email", _.trim(accountData.email));
    formData.append("username", _.trim(accountData.username));
    formData.append("role", _.trim(accountData.role));
    formData.append("userType", "admin");
    formData.append("userData[firstName]", _.trim(accountData.firstName));
    formData.append("userData[lastName]", _.trim(accountData.lastName));
    formData.append("userData[address]", _.trim(accountData.address));
    formData.append("userData[phoneNumber]", _.trim(accountData.contactNumber));
    formData.append("userData[country]", _.trim(accountData.country));
    formData.append("userData[city]", _.trim(accountData.city));
    if (accountData.zipCode)
      formData.append("userData[zipCode]", _.trim(accountData.zipCode));
    if (accountData.region)
      formData.append("userData[regionArea]", _.trim(accountData.region));
    formData.append("userData[extraData]", JSON.stringify(extraData));
    if (accountImage) formData.append("userData[avatar]", accountImage || "");
    if ((isHasId && isUpdatePassword) || !isHasId) {
      formData.append("password", _.trim(accountData.password));
      formData.append(
        "confirmationPassword",
        _.trim(accountData.confirmationPassword)
      );
    }
    return formData;
  };

  const createAccount = async () => {
    const validateData: any[] = [
      {
        label: t("label.memberType"),
        value: accountData.role,
      },
      {
        label: t("label.firstName"),
        value: accountData.lastName,
      },
      {
        label: t("label.lastName"),
        value: accountData.lastName,
      },
      {
        label: t("label.username"),
        value: accountData.username,
      },
      {
        label: t("label.email"),
        value: accountData.email,
      },
      // {
      //   label: t("label.streetAddress"),
      //   value: accountData.address,
      // },
      // {
      //   label: t("label.country"),
      //   value: accountData.country,
      // },
      // {
      //   label: t("label.city"),
      //   value: accountData.city,
      // },
      // {
      //   label: t("label.contactNumber"),
      //   value: accountData.contactNumber,
      // },
    ];
    const isHasId = _.has(data, "id");
    if ((isHasId && isUpdatePassword) || !isHasId) {
      validateData.push(
        ...[
          {
            label: t("label.password"),
            value: _.trim(accountData.password),
          },
          {
            label: t("label.confirmPassword"),
            value: _.trim(accountData.confirmationPassword),
          },
        ]
      );
    }

    let message = `${t("alert.foundEmptyFields")}`;
    let flag = false;
    _.map(validateData, (item: any) => {
      const { value, label } = item;
      if (_.isEmpty(_.trim(value))) {
        flag = true;
        message += `${label}\n`;
      }
    });
    if (flag) {
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });
    } else {
      if ((isHasId && isUpdatePassword) || !isHasId) {
        const valid = validatePassword({
          password: {
            label: t("label.password"),
            value: accountData.password,
          },
          confirmPassword: {
            label: t("label.confirmPassword"),
            value: accountData.confirmationPassword,
          },
          rules: {
            atLeastChars: 6,
            atLeastOneDigit: true,
            atLeastOneLowerCase: true,
          },
        });

        if (!valid) return;
      }
      const userData = processData();
      const userId = _.get(data, "id");
      if (userId) await dispatch(updateUser(userData, userId));
      else await dispatch(registerUser(userData));
      if (onClose) onClose();
    }
  };

  const onCloseTemplate = async () => {
    await setIsShowCreateTemplate(false);
    await dispatch(getEmailTemplateByType(CONSTANTS.EMAIL_TYPE.EMAIL_SCHEDULE));
  };

  const handleDisabledPassword = () => {
    const isHasId = _.has(data, "id");
    if (isHasId) {
      if (isUpdatePassword) return false;
      return true;
    }
    return false;
  };

  const setLogo = (e: any) => {
    if (e) {
      const url = URL.createObjectURL(e.target.files[0]);
      setAccountImage(e.target.files[0]);
      setUrlPreview(url);
    }
  };

  return (
    <TemplateDetailsContainer>
      <TemplateDetailsContent>
        {!_.has(emailTemplate, "id") && (
          <OrderLayer>
            <p>{t("description.emptyVeriyAccountTemplate")}</p>
            <Button
              buttonWidth="150px"
              onClick={() => setIsShowCreateTemplate(true)}
            >
              {t("button.createTemplate")}
            </Button>
          </OrderLayer>
        )}
        {isShowCreateTemplate && (
          <EmailTemplateDetails onClose={() => onCloseTemplate()} />
        )}
        {(membersIsLoading || roleIsLoading || emailTemplateIsLoading) && (
          <LoadingLayer>
            <Spinner />
          </LoadingLayer>
        )}
        <TemplateDetailsHeader>
          {t("headerPopup.memberDetails")}
        </TemplateDetailsHeader>
        <TemplateDetailsForm height="480px">
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.memberType")}
              </FormLabel>
              <Dropdown
                value={accountData.role || ""}
                width="300px"
                callbackPayload={(value: any) =>
                  setAccountData({ ...accountData, role: value })
                }
                options={generateDropdown({
                  data: roles,
                  key: "name",
                  value: "id",
                })}
                placeholder={t("placeholder.memberType")}
                search={false}
                loading={roleIsLoading}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.firstName")}</FormLabel>
              <Input
                value={accountData.firstName || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({
                    ...accountData,
                    firstName: e.target.value,
                  })
                }
                placeholder={t("placeholder.firstName")}
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.lastName")}</FormLabel>
              <Input
                value={accountData.lastName || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, lastName: e.target.value })
                }
                placeholder={t("placeholder.lastName")}
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.username")}</FormLabel>
              <Input
                value={accountData.username}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, username: e.target.value })
                }
                placeholder={t("placeholder.username")}
                width="300px"
                disabled={_.has(data, "id")}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.email")}</FormLabel>
              <Input
                value={accountData.email || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, email: e.target.value })
                }
                placeholder={t("placeholder.email")}
                width="300px"
                disabled={_.has(data, "id")}
              />
            </FormGroup>
          </FormInline>
          {_.has(data, "id") && (
            <Checkbox
              checked={isUpdatePassword}
              context={t("label.alsoUpdateThePassword")}
              onChangeHandler={(type: boolean) =>
                setIsUpdatePassword(!isUpdatePassword)
              }
            />
          )}
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.password")}</FormLabel>
              <Input
                value={accountData.password}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, password: e.target.value })
                }
                placeholder={t("placeholder.password")}
                width="300px"
                type="password"
                disabled={handleDisabledPassword()}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.confirmPassword")}
              </FormLabel>
              <Input
                value={accountData.confirmationPassword || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({
                    ...accountData,
                    confirmationPassword: e.target.value,
                  })
                }
                placeholder={t("placeholder.confirmPassword")}
                width="300px"
                type="password"
                disabled={handleDisabledPassword()}
              />
            </FormGroup>
          </FormInline>
          <TitlePartOfForm>
            {t("titleSection.contactInformation")}
          </TitlePartOfForm>
          <FormInline>
            <FormGroup>
              <FormLabel>
                {t("label.streetAddress")}
              </FormLabel>
              <Input
                value={accountData.address || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, address: e.target.value })
                }
                placeholder={t("placeholder.streetAddress")}
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t("label.country")}</FormLabel>
              <Dropdown
                value={accountData.country || ""}
                width="300px"
                callbackPayload={(value: any) =>
                  setAccountData({ ...accountData, country: value })
                }
                options={countries}
                placeholder={t("placeholder.country")}
                search={false}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.city")}</FormLabel>
              <Input
                value={accountData.city || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, city: e.target.value })
                }
                placeholder={t("placeholder.city")}
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t("label.regionArea")}</FormLabel>
              <Input
                value={accountData.region || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, region: e.target.value })
                }
                placeholder={t("placeholder.regionArea")}
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.postalOrZipCode")}</FormLabel>
              <Input
                value={accountData.zipCode || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, zipCode: e.target.value })
                }
                placeholder={t("placeholder.postalOrZipCode")}
                width="300px"
                isNum
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>
                {t("label.contactNumber")}
              </FormLabel>
              <Input
                value={accountData.contactNumber || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({
                    ...accountData,
                    contactNumber: e.target.value,
                  })
                }
                placeholder={t("placeholder.phoneNumber")}
                width="300px"
                isNum
              />
            </FormGroup>
          </FormInline>
          <TitlePartOfForm>{t("titleSection.social")}</TitlePartOfForm>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.facebookProfile")}</FormLabel>
              <Input
                value={extraData.facebook || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, facebook: e.target.value })
                }
                placeholder="http://"
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t("label.instagramProfile")}</FormLabel>
              <Input
                value={extraData.instagram || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, instagram: e.target.value })
                }
                placeholder="http://"
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.twitterProfile")}</FormLabel>
              <Input
                value={extraData.twitter || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, twitter: e.target.value })
                }
                placeholder="http://"
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t("label.linkedInProfile")}</FormLabel>
              <Input
                value={extraData.linkedin || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, linkedin: e.target.value })
                }
                placeholder="http://"
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.pinterestProfile")}</FormLabel>
              <Input
                value={extraData.pinterest || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, pinterest: e.target.value })
                }
                placeholder="http://"
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormGroup>
            <FormLabel>
              {t("label.uploadAvatar")}{" "}
              <span className="description">({t("label.demensionImage")})</span>
            </FormLabel>
            <Avatar
              src={urlPreview || Images.defaultPhoto}
              onChange={(e: any) => setLogo(e)}
            />
          </FormGroup>
        </TemplateDetailsForm>
        <ButtonField>
          <Button
            type="submit"
            onClick={() => createAccount()}
            buttonWidth="100px"
            buttonMargin="0 10px 0 0"
          >
            {_.has(data, "id") ? t("button.save") : t("button.create")}
          </Button>
          <Button
            type="submit"
            onClick={() => onClose && onClose()}
            buttonWidth="100px"
          >
            {t("button.cancel")}
          </Button>
        </ButtonField>
      </TemplateDetailsContent>
    </TemplateDetailsContainer>
  );
};

export default MemberDetails;
