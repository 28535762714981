import styled from "styled-components";
import { HorizontalContainer } from "../CommonLayout";
import ColorName from "constants/Variables";

interface SectionProps {
  margin?: string;
  width?: string;
  isRequired?: boolean;
  minHeight?: string;
  flex?: number;
}

const ComponentTextarea = styled(HorizontalContainer)`
  width: ${(props: SectionProps) => props.width || "100%"};
  margin: ${(props: SectionProps) => props.margin || "unset"};
  position: relative;
  color: ${ColorName.william};
  flex: ${(props: SectionProps) => props.flex || "unset"};
  & .label-required {
    position: absolute;
    z-index: 1;
    top: -10px;
    left: 1.1em;
    padding: 0 4px;
    /* background-color: white; */
    font-weight: 400;
    font-size: 12px;
    color: ${ColorName.william};
  }
  & .background-error {
    position: absolute;
    left: 1em;
    padding: 0 0.4em;
    font-size: 12px;
    line-height: 9px;
    color: transparent;
    background: white;
  }
`;

const TextareaMain = styled.textarea`
  border-radius: 2px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding: 0.5em 1em;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  min-height: ${(props: SectionProps) => props.minHeight || "150px"};
  resize: none;
  border: 1px solid
    ${(props: SectionProps) =>
      props.isRequired ? "#f85353" : ColorName.linkWater};
  box-shadow: 0px 0px 2px
    ${(props: SectionProps) =>
      props.isRequired ? "#f85353" : "rgb(62 138 182 / 25%)"};
  color: ${ColorName.william};

  font-family: "Be Vietnam";

  &:focus {
    border: 1px solid ${ColorName.william};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  &#custom-file-input {
    border: none;
    box-shadow: none;
    padding: 0;
  }

  &#custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }

  &#custom-file-input::before {
    content: "Choose a file";
    display: inline-block;
    border: 1px solid #999;
    border-radius: 3px;
    padding: 8px 12px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 600;
    background: ${ColorName.burntSienna};
    color: white;
  }
`;

export { ComponentTextarea, TextareaMain };
