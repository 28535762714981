/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { RootState } from "reducers";
import { AccountOfClientActions, UserOfClientActions } from "actions";

import TableUltils from "libs/TableUltils";
import ColorName from "constants/Variables";
import { CONSTANTS } from "constants/Constants";
import {
  generateFilter,
  popupAlert,
  processDataDropDownAccount,
} from "libs/Utils";
import RouteTypes from "constants/RouteTypes";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Checkbox,
  Dropdown,
  ContainerSearch,
  FlexWrapRow,
  Button,
  Input,
  Table,
} from "components/Common";
import {
  LogsDetails,
  UserDetailsOfClient,
  ChangePackage,
} from "components/Common/Popup";
import { useLocation } from "react-router-dom";
import history from "../../../history";

const { searchAccountsOfClient, clearPackages } = AccountOfClientActions;
const {
  fetchUsersOfClient,
  activateUserOfClient,
  clearUserFilter,
  clearUserPayload,
  deactivateUserOfClient,
} = UserOfClientActions;

const DEFAULT_FILTER = {
  keyword: "",
  accountId: "",
  status: CONSTANTS.STATUS.ACTIVE,
  limit: 10,
  page: 1,
  userType: "user",
  sortBy: "updatedAt",
  orderBy: "DESC"
};

interface FilterStructure {
  keyword: string;
  accountId: string;
  status: string;
  limit: number;
  page: number;
  userType: string;
  sortBy: string;
  orderBy: string;
}

const InputStyleProps = {
  margin: "10px 10px 10px 0",
  width: "200px",
};

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "10px 10px 10px 0",
};

const ListUser = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state: RootState) => {
    return state.Account.isLoading;
  });
  const userIsLoading = useSelector((state: RootState) => {
    return state.Users.isLoading;
  });
  const users = useSelector((state: RootState) => {
    return state.Users.users;
  });
  // const filter = useSelector((state: RootState) => {
  //   return state.Users.filter;
  // });
  const packages = useSelector((state: RootState) => {
    return state.Account.accounts;
  });
  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const [isDisplayPopup, setIsDisplayPopup] = useState(false);
  const [dataPopup, setDataPopup] = useState<any>({});
  const [logData, setLogData] = useState<any>({});
  const [isShowChangePackagePopup, setIsShowChangePackagePopup] =
    useState<boolean>(false);

  useEffect(() => {
    const accountId = _.get(location, "state.accountId");

    const resolveFilter = {
      ...filter,
    };

    if (accountId) {
      _.assign(resolveFilter, { accountId });
      history.replace({ ...history.location, state: {} });
    }

    setFilter(resolveFilter);
    dispatch(
      searchAccountsOfClient({ status: CONSTANTS.STATUS.ACTIVE, limit: 100 })
    );
    dispatch(fetchUsersOfClient(generateFilter(resolveFilter)));
    return () => {
      dispatch(clearUserFilter());
      dispatch(clearUserPayload());
      dispatch(clearPackages());
    };
  }, []);

  const dataHead = [
    "no",
    "fullName",
    "email",
    "type",
    "clientName",
    "clientCode",
    "status",
    "actions",
  ];
  const dataTable = TableUltils._processDataTable(
    [
      "no",
      "id",
      "userData.fullName",
      "email",
      "userType",
      "clientId.clientName",
      "clientId.clientNumber",
      "status",
      "action",
    ],
    _.get(users, "items")
  );
  const listColumnCenter = [7, 8];

  const callbackAction = async (action: string, id: string, item: object) => {
    if (action === CONSTANTS.ACTION.LOG)
      setLogData({ logType: "user", rowId: id });

    if (action === CONSTANTS.ACTION.LIST_PACKAGES) {
      const data = _.find(users?.items, { id });
      setDataPopup(data);
      setIsShowChangePackagePopup(true);
    }

    if (action === CONSTANTS.ACTION.EDIT) {
      const data = _.find(users?.items, { id });
      setDataPopup(data);
      setIsDisplayPopup(true);
    }
    if (action === CONSTANTS.ACTION.DEACTIVATE) {
      const currentStatus = _.get(item, "status");
      const userName = _.get(item, "userData.fullName");
      const statusLabel =
        currentStatus === CONSTANTS.STATUS.ACTIVE
          ? t("alert.deactive")
          : t("alert.active");
      const content = document.createElement("span");
      content.innerHTML = `${t(
        "alert.areYouSureYouWantTo"
      )}</br> <b>${statusLabel}</b> ${t(
        "alert.theUser"
      )} <b>${userName}</b>  ?`;
      await popupAlert({
        type: "warning",
        title: t("alert.warning"),
        content,
        buttons: true,
        dangerMode: true,
      }).then(async (willContinue) => {
        if (willContinue) {
          if (currentStatus === CONSTANTS.STATUS.ACTIVE)
            await dispatch(deactivateUserOfClient(id));
          else await dispatch(activateUserOfClient(id));

          dispatch(fetchUsersOfClient(generateFilter(filter)));
        }
      });
    }
  };

  const setValueStatus = (status: string) => {
    const newFilter = {
      ...filter,
      status,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(fetchUsersOfClient(generateFilter(newFilter)));
  };

  const onChangePage = (page: number) => {
    const newFilter = {
      ...filter,
      page,
    };
    setFilter(newFilter);
    dispatch(fetchUsersOfClient(generateFilter(newFilter)));
  };

  const onCreate = async () => {
    setIsDisplayPopup(true);
  };

  const onReset = () => {
    setFilter(DEFAULT_FILTER);
    dispatch(fetchUsersOfClient(generateFilter(DEFAULT_FILTER)));
  };

  const onCloseUserDetails = async (isFetch?: boolean) => {
    if (isFetch) {
      await dispatch(fetchUsersOfClient(generateFilter(filter)));
    }
    setDataPopup({});
    if (isDisplayPopup) setIsDisplayPopup(false);
    if (isShowChangePackagePopup) setIsShowChangePackagePopup(false);
  };

  const onSearch = async () => {
    const newFilter = {
      ...filter,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(fetchUsersOfClient(generateFilter(newFilter)));
  };

  const _renderTable = () => {
    return (
      <Table
        dataHeader={dataHead}
        dataTable={dataTable}
        fitColumns={[1, 7]}
        listColumnCenter={listColumnCenter}
        hasPagination={true}
        callbackCurrentPageLink={(e: number) => onChangePage(e)}
        metaPagination={users.meta}
        callBackAction={callbackAction}
        listActionItem={[
          CONSTANTS.ACTION.LIST_PACKAGES,
          CONSTANTS.ACTION.EDIT,
          CONSTANTS.ACTION.DEACTIVATE,
          CONSTANTS.ACTION.LOG,
        ]}
        loading={userIsLoading}
      />
    );
  };

  const renderMain = () => {
    return (
      <>
        {isDisplayPopup && (
          <UserDetailsOfClient
            onClose={(isFetch?: boolean) => onCloseUserDetails(isFetch)}
            data={dataPopup}
          />
        )}
        {isShowChangePackagePopup && (
          <ChangePackage
            onClose={(isFetch?: boolean) => onCloseUserDetails(isFetch)}
            data={dataPopup}
          />
        )}
        {!_.isEmpty(logData) && (
          <LogsDetails onClose={() => setLogData({})} data={logData} />
        )}
        <ContainerSearch className={userIsLoading ? "loading" : ""}>
          <FlexWrapRow>
            <Dropdown
              value={_.get(filter, "accountId")}
              callbackPayload={(value) =>
                setFilter({ ...filter, accountId: value })
              }
              options={processDataDropDownAccount(packages.items)}
              placeholder={t("placeholder.package")}
              search={false}
              loading={isLoading}
              disabled={
                _.isEmpty(_.get(filter, " clientId")) &&
                _.isEmpty(packages.items)
              }
              {...InputStyleProps}
            />
            <Input
              value={_.get(filter, "keyword")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, keyword: e.target.value })
              }
              onEnter={() => onSearch()}
              placeholder={t("placeholder.keyword")}
              {...InputStyleProps}
            />
            <Button onClick={() => onSearch()} {...ButtonStyleProps}>
              {t("button.search")}
            </Button>
            <Button onClick={() => onReset()} {...ButtonStyleProps}>
              {t("button.reset")}
            </Button>
            <Button
              onClick={() => onCreate()}
              background={ColorName.william}
              {...ButtonStyleProps}
            >
              + {t("button.create")}
            </Button>
          </FlexWrapRow>
          <Checkbox
            checked={filter?.status === CONSTANTS.STATUS.INACTIVE}
            context={t("label.showInactiveUsers")}
            onChangeHandler={(e: any) =>
              setValueStatus(
                e ? CONSTANTS.STATUS.INACTIVE : CONSTANTS.STATUS.ACTIVE
              )
            }
            margin="10px 0 0 0"
          />
        </ContainerSearch>
        {_renderTable()}
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.CLIENT_ROUTES.USER_MANAGEMENT}
      title={t("titlePage.users")}
    />
  );
};

export default ListUser;
