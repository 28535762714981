import styled from "styled-components";
import ColorName from "constants/Variables";

const UserFooterContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${ColorName.linkWater};
  font-weight: bold;
`;

export { UserFooterContainer };
