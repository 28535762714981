import ActionTypes from "constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  filters: [],
  filter: {},
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.CLEAR_FILTERS:
      return {
        ...state,
        filters: [],
      };
    case ActionTypes.CLEAR_FILTER_DETAILS:
      return {
        ...state,
        filter: {},
      };
    case ActionTypes.FILTER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.FILTER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FILTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
      };
    case ActionTypes.CREATE_FILTER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.FETCH_FILTER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        filters: payload,
      };
    case ActionTypes.GET_FILTER_BY_ID_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        filter: payload,
      };
    case ActionTypes.UPDATE_FILTER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        filter: payload,
      };
    case ActionTypes.DEACTIVATE_FILTER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    case ActionTypes.ACTIVATE_FILTER_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
