/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import parse from "html-react-parser";

import { Button } from "components/Common";
import {
  DataPostContainer,
  DataPostItem,
  PreviewContent,
  DatatPostContent,
  TemplateDetailsContainer,
  TemplateDetailsContent,
  TemplateDetailsHeader,
  TemplateDetailsForm,
  ButtonField,
  TotalPostRow,
} from "../Popup.styles";
import { useTranslation } from "react-i18next";
import SendCheckedPost from "./SendCheckedPost";
import ColorName from "constants/Variables";
import { useDispatch, useSelector } from "react-redux";
import { DataRecordsActions } from "actions";

interface SectionProps {
  dataRecords: any[];
  onClose(): void;
}

const { setDefaultSendPostStatus } = DataRecordsActions;

const ViewPostChecked = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const { onClose, dataRecords } = props;
  const [isShowSendMail, setIsShowSendMail] = useState<boolean>(false);
  const sendPostStatus = useSelector((state) =>
    _.get(state, "DataRecord.sendPostStatus")
  );

  useEffect(() => {
    if (sendPostStatus === 2) onClose();
    return () => {
      dispatch(setDefaultSendPostStatus());
    };
  }, [sendPostStatus]);

  const handleClose = (e: any) => {
    const className = e.target.classList;
    if (className.contains("blur_layer") && onClose) onClose();
  };

  const renderTableArticleQty = () => {
    return (
      <TotalPostRow>
        <span>
          {t("label.totalPosts")}: <b>{dataRecords.length}</b>
        </span>
      </TotalPostRow>
    );
  };

  const renderOriginalLink = (link: string) => {
    if (link.length < 50) return link;
    const chunkLink = _.chunk(link, 25);
    const resolveLink = `${_.join(chunkLink[0], "")}...${
      chunkLink.length > 2 ? _.join(chunkLink[chunkLink.length - 1], "") : ""
    }`;
    return resolveLink;
  };

  const renderPost = () => {
    if (!_.isEmpty(dataRecords))
      return _.map(dataRecords, (item, index) => {
        const content = _.get(item, "content");
        const originalLink = _.get(item, "originalLink");
        const publishedAt = _.get(item, "publishedAt");
        const author = _.get(item, "author");
        const createdAt = _.get(item, "createdAt");
        const title = _.get(item, "title");
        const source = _.get(item, "source");
        const link = _.get(source, "link");

        const timeValue = dayjs(new Date(createdAt));
        let resultCreatedAt;
        if (timeValue.isValid()) resultCreatedAt = createdAt;
        else {
          if (_.includes(createdAt, "a.m."))
            resultCreatedAt = _.replace(createdAt, /a.m./g, "am");
          if (_.includes(createdAt, "p.m."))
            resultCreatedAt = _.replace(createdAt, /p.m./g, "pm");
        }

        return (
          <DataPostItem key={`post-item-${index}`}>
            <DatatPostContent>
              <div>
                <span>{title}</span>
                <span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={originalLink ? originalLink : link}
                  >
                    {originalLink ? renderOriginalLink(originalLink) : link}
                  </a>{" "}
                  {t("label.createdAt")}{" "}
                  {dayjs(resultCreatedAt).format("DD-MM-YYYY, HH:mm:ss")}
                </span>
              </div>

              <PreviewContent>
                {content ? parse(content) : t("label.dataRecordNotFound")}
              </PreviewContent>
              <span>
                {author && (
                  <span>
                    <b> {t("label.author")}:</b> {author}
                  </span>
                )}
                <span>
                  <b> {t("label.publishedDate")}:</b>{" "}
                  {dayjs(publishedAt).format("DD-MM-YYYY, HH:mm:ss")}
                </span>
              </span>
            </DatatPostContent>
          </DataPostItem>
        );
      });
  };
  return (
    <TemplateDetailsContainer
      className="blur_layer"
      onClick={(e: any) => handleClose(e)}
    >
      <TemplateDetailsContent
        width={_.isEmpty(dataRecords) ? "500px" : "900px"}
      >
        {isShowSendMail && (
          <SendCheckedPost
            checkedData={dataRecords}
            onClose={() => setIsShowSendMail(false)}
          />
        )}
        <TemplateDetailsHeader>
          {t("headerPopup.selectedPosts")}
        </TemplateDetailsHeader>
        {renderTableArticleQty()}
        <TemplateDetailsForm height="400px">
          <DataPostContainer>{renderPost()}</DataPostContainer>
        </TemplateDetailsForm>
        <ButtonField>
          <Button
            onClick={() => setIsShowSendMail(true)}
            buttonMargin="0 0 0 10px"
            background={ColorName.william}
          >
            {t("button.next")}
          </Button>
          <Button onClick={() => onClose()} buttonMargin="0 0 0 10px">
            {t("button.close")}
          </Button>
        </ButtonField>
      </TemplateDetailsContent>
    </TemplateDetailsContainer>
  );
};

export default ViewPostChecked;
