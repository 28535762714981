import { createTheme, PaletteColorOptions } from "@mui/material/styles";
import CommonColor from "./constants/Variables";

declare module "@mui/material/styles" {
  interface CustomPalette {
    burntSienna: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    burntSienna: true;
  }
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor, contrastText: "#fff" } });

const CoreTheme = createTheme({
  palette: {
    burntSienna: createColor(CommonColor.burntSienna),
  },
});
export default CoreTheme;
