/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { RootState } from "reducers";
import { DataRecordsActions } from "actions";

import RouteTypes from "constants/RouteTypes";
import { Images } from "themes";

import history from "../../../history";

import UserDefaultLayout from "components/AdminLayout/DefaultLayout/UserDefaultLayout";
import {
  BackButton,
  ArticlesDetailsContainer,
  ColItem,
  NotFoundContainer,
  SkeletonLoading,
} from "./Articles.styles";
import { useTranslation } from "react-i18next";
import { RenderPDF } from "../../../components/Common";

const { getDataRecordByID } = DataRecordsActions;

const ArticlesDetails = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const location = useLocation();

  const dataRecordsIsLoading = useSelector((state: RootState) => {
    return state.DataRecord.isLoading;
  });
  const dataRecord = useSelector((state: RootState) => {
    return state.DataRecord.dataRecord;
  });

  useEffect(() => {
    const pathName = location.pathname;
    const splitPathname = pathName.split("/");
    const articleId = splitPathname[splitPathname.length - 1];
    dispatch(getDataRecordByID(articleId));
  }, []);

  const renderNotFound = () => {
    return (
      <NotFoundContainer>
        <img src={Images.notFound} alt="" />
        <h2>{t("label.pageNotFound")}</h2>
        <p>{t("label.articlesNotFound")}</p>
      </NotFoundContainer>
    );
  };

  const renderSkeleton = () => {
    return (
      <ArticlesDetailsContainer
        style={{ overflow: dataRecordsIsLoading ? "hidden" : "auto" }}
      >
        <SkeletonLoading>
          <div className="title-details" />
          <div className="title-details" />
          <div className="author-details" />
          <div className="date-time-details">
            <span className="category" />
            <span className="date" />
          </div>
          <div className="content-details" />
          <div className="content-details" />
          <div className="content-details" />
          <div className="content-details" />
          <div className="content-details" />
          <div className="content-details" />
          <div className="content-details" />
          <div className="content-details" />
          <div className="content-details" />
          <div className="content-details" />
        </SkeletonLoading>
      </ArticlesDetailsContainer>
    );
  };

  const renderTvContent = (payload: any) => {
    let originalLink = _.get(payload, "originalLink");
    const isYoutube = _.includes(originalLink, "youtube");
    if (isYoutube) originalLink = originalLink.replace("watch?v=", "embed/");

    return (
      <iframe
        width="100%"
        height="400px"
        src={originalLink}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded video"
      />
    );
  };

  const _renderDownloadVideoBtn = (payload: any) => {
    let originalLink = _.get(payload, "originalLink");
    let isDownload = true;
    const isGoogleDriveLink = _.includes(originalLink, "drive.google");
    if (isGoogleDriveLink) {
      const replacePrefix = `${originalLink.replace("file/d/", "u/0/uc?id=")}`;
      originalLink = `${replacePrefix.replace("/preview", "")}`;
    } else isDownload = false;
    return (
      <a
        href={originalLink}
        target="__blank"
        className={`action-btn`}
        download={isDownload}
      >
        <i className="fas fa-download" />
      </a>
    );
  };

  const renderDataRecordDetails = () => {
    const title = _.get(dataRecord, "title");
    const content = _.get(dataRecord, "content");
    const sourceName = _.get(dataRecord, "source.name");
    const mediaType = _.get(dataRecord, "source.mediaType");
    const publishedAt = _.get(dataRecord, "publishedAt");
    return (
      <>
        <ArticlesDetailsContainer>
          <h1>{title}</h1>
          <div>
            <p className="source">{sourceName}</p>
            <p>
              <span className="media-type">{_.capitalize(mediaType)}</span>
              <span>-</span>
              <span className="time">
                {dayjs(publishedAt).format("YYYY, MMM D")}
              </span>
              {mediaType !== "tv" && (
                <>
                  <span>-</span>
                  <RenderPDF isDownload data={dataRecord} />
                </>
              )}
              {mediaType === "tv" && _renderDownloadVideoBtn(dataRecord)}
            </p>
          </div>
          <p className="content">
            {mediaType === "tv" ? renderTvContent(dataRecord) : content}
          </p>
          <ColItem>
            <BackButton
              onClick={() =>
                history.push({ pathname: RouteTypes.USER_ROUTES.USER_ARTICLES })
              }
            >
              {t("button.returnToPortal")}
            </BackButton>
          </ColItem>
        </ArticlesDetailsContainer>
      </>
    );
  };

  const renderMain = () => {
    if (dataRecordsIsLoading) return renderSkeleton();
    if (_.isEmpty(dataRecord) && !dataRecordsIsLoading) return renderNotFound();
    return renderDataRecordDetails();
  };
  return (
    <UserDefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.USER_ROUTES.USER_ARTICLES_DETAILS}
    />
  );
};

export default ArticlesDetails;
