/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import _ from "lodash";

import { RootState } from "reducers";
import { SourceSettingsActions } from "actions";

import history from "../../../history";
import { popupAlert } from "libs/Utils";

import { CONSTANTS } from "constants/Constants";
import languages from "libs/languages";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Button,
  Input,
  Dropdown,
  FormGroup,
  FormDetails,
  FormLabel,
  FormInline,
  ButtonField,
  Avatar,
} from "components/Common";
import RouteTypes from "constants/RouteTypes";
import { useTranslation } from "react-i18next";
import { Images } from "themes";
import {
  Box,
  Divider,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import ColorName from "constants/Variables";

export interface ITableAudince {
  label: string;
  value: string;
}

const KEY_RATING: ITableAudince[] = [
  {
    label: "Rating",
    value: "rating",
  },
];

const options_language: ITableAudince[] = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "Albania",
    value: "al",
  },
];

const KEY_BODY_RATING: string[] = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const {
  createSourceSetting,
  getSourceSettingByID,
  updateSourceSetting,
  clearSourceDetails,
} = SourceSettingsActions;

interface IDState {
  id: any;
}

const InputStyleProps = {
  width: "100%",
  // margin: "10px 20px 10px 0",
};

const SourceDetails = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state: RootState) => {
    return state.SourceSettings.isLoading;
  });
  const source = useSelector((state: RootState) => {
    return state.SourceSettings.sourceSetting;
  });
  const [sourceData, setSourceData] = useState({
    name: "",
    code: "",
    link: "",
    mediaCost: "",
    mediaType: CONSTANTS.MEDIA_TYPE.WEB,
    language: "en",
  });

  // const [mediaCost, setMediaCost] = useState<any>({});
  const [circulation, setCirculation] = useState<string>("0");
  const [sourceLogo, setSourceLogo] = useState();
  const [urlPreview, setUrlPreview] = useState<string>("0");
  const [totalVisitors, setTotalVisitors] = useState<string>("0");
  const [frontWebPageCost, setFrontWebPageCost] = useState<string>("0");
  const [insideWebPageCost, setInsideWebPageCost] = useState<string>("0");
  const [ratting, setRatting] = useState<any>({});
  const [airtimeTariff, setAirtimeTariff] = useState<string>("0");
  const [pagePerVisit, setPagePerVisit] = useState<string>("0");

  const optionStatus = [
    { label: t("dropdown.print"), value: CONSTANTS.MEDIA_TYPE.PRINT },
    { label: t("dropdown.web"), value: CONSTANTS.MEDIA_TYPE.WEB },
    { label: t("dropdown.tv"), value: CONSTANTS.MEDIA_TYPE.TV },
    {
      label: t("dropdown.socialNetwork"),
      value: CONSTANTS.MEDIA_TYPE.SOCIAL_NETWORK,
    },
  ];
  const [sourceId, setSourceId] = useState<string>("");

  useEffect(() => {
    return () => {
      dispatch(clearSourceDetails());
    };
  }, []);

  useEffect(() => {
    if (location.state) {
      const id = (location.state as IDState).id || "";
      if (id) {
        setSourceId(id);
        dispatch(getSourceSettingByID(id));
      }
    }
  }, [location, dispatch]);

  useEffect(() => {
    if (!_.isEmpty(source)) {
      const ratingdata = JSON.parse(_.get(source, "ratting"));
      const resolveSource = {
        ...source,
      };
      const logo = _.get(source, "logo");
      if (logo) {
        const getImagePath = _.get(logo, "imagePath");
        setUrlPreview(getImagePath);
      } else _.assign(resolveSource, { logo: "" });
      setSourceData(resolveSource);
      if (source.airtimeTariff !== null) setAirtimeTariff(source.airtimeTariff);
      if (source.circulation !== null) setCirculation(source.circulation);
      if (source.frontWebPageCost !== null)
        setFrontWebPageCost(source.frontWebPageCost);
      if (source.insideWebPageCost !== null)
        setInsideWebPageCost(source.insideWebPageCost);
      if (source.pagePerVisit !== null) setPagePerVisit(source.pagePerVisit);
      if (source.ratingdata !== null) setRatting(ratingdata);
    }
  }, [source]);

  const checkRequired = () => {
    let message = `${t("alert.foundEmptyFields")}`;
    let flag = true;
    const data = [
      {
        label: t("label.sourceName"),
        value: _.get(sourceData, "name"),
      },
      {
        label: t("label.sourceCode"),
        value: _.get(sourceData, "code"),
      },
      // {
      //   label: t("label.circulation"),
      //   value: _.get(sourceData, "circulation"),
      // },
      {
        label: t("label.mediaType"),
        value: _.get(sourceData, "mediaType"),
      },
    ];

    const mediaType = _.get(sourceData, "mediaType");
    if (mediaType === "web")
      data.push(
        ...[
          {
            label: t("label.inputPath"),
            value: _.get(sourceData, "link"),
          },
        ]
      );

    _.map(data, (item) => {
      const value = _.get(item, "value");
      const label = _.get(item, "label");
      if (_.isEmpty(_.trim(value))) {
        flag = false;
        message += `${label}\n`;
      }
    });
    return { message, flag };
  };

  const onSubmit = async () => {
    const { message, flag } = checkRequired();
    if (!flag) {
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });
    }

    const formData = new FormData();
    formData.append("name", sourceData.name);
    formData.append("code", sourceData.code);
    formData.append("link", sourceData.link);
    formData.append("circulation", circulation);
    formData.append("mediaType", sourceData.mediaType);
    formData.append("totalVisitors", totalVisitors);
    formData.append("frontWebPageCost", frontWebPageCost);
    formData.append("insideWebPageCost", insideWebPageCost);
    formData.append("airtimeTariff", airtimeTariff);
    formData.append("pagePerVisit", pagePerVisit);
    formData.append("ratting", JSON.stringify(ratting));
    if (sourceLogo) formData.append("logo", sourceLogo || "");
    formData.append("language", sourceData.language);

    const filter = _.get(location, "state.filter");
    const resolveFilter = {
      pathname: RouteTypes.ADMIN_ROUTES.SOURCES_SETTINGS,
      state: { filter, from: RouteTypes.ADMIN_ROUTES.SOURCE_DETAILS },
    };

    if (sourceId)
      await dispatch(updateSourceSetting(formData, sourceId, resolveFilter));
    else await dispatch(createSourceSetting(formData, resolveFilter));
  };

  const setLogo = (e: any) => {
    if (e.target.files[0]) {
      const url = URL.createObjectURL(e.target.files[0]);
      setSourceLogo(e.target.files[0]);
      setUrlPreview(url);
    }
  };

  const onChangeInput = (key: string, value: string) => {
    const newData = {
      ...sourceData,
      [key]: value,
    };

    // if (key === "mediaType" && value === "print")
    //   _.assign(newData, { link: "" });

    setSourceData(newData);
  };

  const onChangeMediaType = (key: string, value: string) => {
    const newData = {
      ...sourceData,
      [key]: value,
    };
    if (value === "print") newData["link"] = "PRINT NEWS";
    else if (value === "tv") newData["link"] = "TV NEWS";
    else newData["link"] = "";
    setAirtimeTariff("0");
    setCirculation("0");
    setFrontWebPageCost("0");
    setInsideWebPageCost("0");
    setPagePerVisit("0");
    setRatting({});
    setSourceData(newData);
  };

  const onChangeInputRating = (key: string, value: string) => {
    const newData = {
      ...ratting,
      [key]: value,
    };
    setRatting(newData);
  };

  const onBack = () => {
    const filter = _.get(location, "state.filter");
    history.push({
      pathname: RouteTypes.ADMIN_ROUTES.SOURCES_SETTINGS,
      state: { filter, from: RouteTypes.ADMIN_ROUTES.SOURCE_DETAILS },
    });
  };

  const _renderPagesMedia = () => {
    switch (sourceData.mediaType) {
      case "tv":
        return _renderTV();
      case "web":
        return _renderWeb();
      case "print":
        return _renderWeb();
    }
  };

  const _renderBodyTable = () => {
    return (
      <TableBody>
        {_.map(KEY_BODY_RATING, (item, index) => {
          return (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  type="number"
                  label={item}
                  variant="outlined"
                  size="small"
                  value={_.get(ratting, item)}
                  onChange={(e) => onChangeInputRating(item, e.target.value)}
                  sx={{
                    "& label": {
                      textTransform: "capitalize",
                    },
                  }}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const _renderTV = () => {
    return (
      <Grid container>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel>{t("label.audience")}(Rating)</FormLabel>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
              <TableContainer sx={{ maxWidth: 250 }}>
                <Table>{_renderBodyTable()}</Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel>{t("label.mediaCost")}</FormLabel>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} display={"flex"} mt={2}>
              <TextField
                label={t("placeholder.airtimeTariff")}
                fullWidth
                variant="outlined"
                size="small"
                value={airtimeTariff}
                type="number"
                onChange={(e) => setAirtimeTariff(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const _renderWeb = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel>{t("label.audience")}</FormLabel>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {sourceData.mediaType === "web" ? (
              <Grid item xs={12} display={"flex"}>
                <TextField
                  label={t("placeholder.totalVisitors")}
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={totalVisitors}
                  type="number"
                  onChange={(e) => setTotalVisitors(e.target.value)}
                />
              </Grid>
            ) : (
              <Grid item xs={12} display={"flex"}>
                <TextField
                  label={t("placeholder.circulation")}
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={circulation}
                  type="number"
                  onChange={(e) => setCirculation(e.target.value)}
                />
              </Grid>
            )}
            <Grid item xs={12} display={"flex"}>
              <TextField
                label={t("placeholder.pages/visit")}
                fullWidth
                variant="outlined"
                size="small"
                value={pagePerVisit}
                type="number"
                onChange={(e) => setPagePerVisit(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel>{t("label.mediaCost")}</FormLabel>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} display={"flex"}>
              <TextField
                label={t("placeholder.priceForTheMainPageArticle")}
                fullWidth
                variant="outlined"
                size="small"
                value={frontWebPageCost}
                type="number"
                onChange={(e) => setFrontWebPageCost(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} display={"flex"}>
              <TextField
                label={t("placeholder.priceForTheinsidePageArticle")}
                fullWidth
                variant="outlined"
                size="small"
                value={insideWebPageCost}
                type="number"
                onChange={(e) => setInsideWebPageCost(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderMain = () => {
    const isShowLink =
      _.get(sourceData, "mediaType") === "web" ||
      _.get(sourceData, "mediaType") === "social network";
    return (
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              {/* <Dropdown
                value={sourceData.mediaType}
                callbackPayload={(value) =>
                  onChangeMediaType("mediaType", value)
                }
                options={optionStatus}
                placeholder={t("placeholder.mediaType")}
                search={false}
                loading={isLoading}
                {...InputStyleProps}
              /> */}
              <TextField
                size="small"
                select
                value={sourceData.mediaType}
                fullWidth
                label={t(`placeholder.mediaType`)}
                onChange={(e: any) =>
                  onChangeMediaType("mediaType", e.target.value)
                }
              >
                {optionStatus.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                value={sourceData.name}
                label={t(`placeholder.${isLoading ? "loading" : "sourceName"}`)}
                fullWidth
                required
                variant="outlined"
                size="small"
                onChange={(e) => onChangeInput("name", e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                value={sourceData.code}
                label={t(`placeholder.${isLoading ? "loading" : "sourceCode"}`)}
                fullWidth
                required
                variant="outlined"
                size="small"
                onChange={(e) => onChangeInput("code", e.target.value)}
                disabled={Boolean(sourceId)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                size="small"
                select
                label={t(`placeholder.language`)}
                value={_.get(sourceData, "language")}
                fullWidth
                onChange={(e: any) => onChangeInput("language", e.target.value)}
              >
                {options_language.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            {isShowLink && (
              <Grid item xs={10}>
                <FormGroup>
                  <TextField
                    value={sourceData.link}
                    label={t(
                      `placeholder.${isLoading ? "loading" : "inputPath"}`
                    )}
                    required
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={(e) => onChangeInput("link", e.target.value)}
                    // disabled={_.get(sourceData, "mediaType") === "print"}
                  />
                </FormGroup>
              </Grid>
            )}
          </Grid>
          {_renderPagesMedia()}
        </Grid>
        <Grid item xs={4}>
          <Grid item xs={12} textAlign={"center"}>
            <FormLabel>
              {t("label.uploadLogo")}{" "}
              <span className="description">({t("label.demensionImage")})</span>
            </FormLabel>
            <Box sx={{ textAlign: "-webkit-center" }}>
              <Avatar
                src={urlPreview || Images.defaultPhoto}
                onChange={(e) => setLogo(e)}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12} textAlign={"end"} mt={2} mb={4}>
          <Button onClick={() => onSubmit()} buttonMargin="0 10px 0 0">
            {_.has(source, "id") ? t("button.save") : t("button.create")}
          </Button>
          <Button onClick={() => onBack()} buttonMargin="0 10px 0 0">
            {t("button.back")}
          </Button>
        </Grid>
      </Grid>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.ADMIN_ROUTES.SOURCES_SETTINGS}
      parent={RouteTypes.ADMIN_ROUTES.MANAGE_SOURCES}
      title={t("titlePage.sourceSettings")}
      subTitle={
        _.has(source, "id")
          ? t("titlePage.editSource")
          : t("titlePage.createNewSource")
      }
    />
  );
};

export default SourceDetails;
