/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { RootState } from "reducers";
import { FiltersActions } from "actions";

import ColorName from "constants/Variables";
import { CONSTANTS, ROUTES } from "constants/Constants";
import RouteTypes from "constants/RouteTypes";

import TableUltils from "libs/TableUltils";
import { generateFilter, popupAlert } from "libs/Utils";
import history from "../../../history";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Input,
  Button,
  Table,
  Checkbox,
  ContainerSearch,
  FlexWrapRow,
} from "components/Common";
import { useTranslation } from "react-i18next";
import { LogsDetails } from "components/Common/Popup";
import { useLocation } from "react-router-dom";

const {
  searchFilters,
  deactivateFilter,
  activateFilter,
  // exportFilter,
  clearFilters,
} = FiltersActions;

const DEFAULT_FILTER = {
  filterName: "",
  clientName: "",
  clientNumber: "",
  accountName: "",
  status: CONSTANTS.STATUS.ACTIVE,
  page: 1,
  limit: 10,
  sortBy: "updatedAt",
  orderBy: "DESC",
};

interface FilterStructure {
  filterName: string;
  clientName: string;
  clientNumber: string;
  accountName: string;
  status: string;
  page: number;
  limit: number;
  sortBy: string;
  orderBy: string;
}

const InputStyleProps = {
  width: "200px",
  margin: "10px 10px 10px 0",
};

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "10px 10px 10px 0",
};

const Filters = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const location = useLocation();
  const filtersIsLoading = useSelector((state: RootState) => {
    return state.Filters.isLoading;
  });
  const filters = useSelector((state: RootState) => {
    return state.Filters.filters;
  });

  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const [logData, setLogData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataFilters, setDataFilters] = useState<any>([]);

  const dataHead = [
    "no",
    "filterName",
    "clientName",
    "clientNumber",
    "packageName",
    "status",
    "actions",
  ];

  const dataTable = TableUltils._processDataTable(
    [
      "no",
      "id",
      "filterName",
      "clientId.clientName",
      "clientId.clientNumber",
      "accountId.accountName",
      "status",
      "action",
    ],
    dataFilters
  );

  const listColumnCenter = [6, 7];

  useEffect(() => {
    const stateFilter: any = _.get(location, "state.filter");
    const prevRoute = _.get(location, "state.from");
    const resolveFilter = {
      ...filter,
    };
    if (stateFilter && prevRoute === RouteTypes.ADMIN_ROUTES.FILTER_DETAILS) {
      _.assign(resolveFilter, { ...stateFilter });
      history.replace({ ...history.location, state: {} });
    }
    setFilter(resolveFilter);
    dispatch(searchFilters(generateFilter(resolveFilter)));

    return () => {
      dispatch(clearFilters());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(filters)) {
      const getDataFilter: any[] = _.get(filters, "items");
      if (currentPage > 1) {
        setDataFilters([...dataFilters, ...getDataFilter]);
      } else setDataFilters(getDataFilter);
    }
  }, [filters]);

  const callbackCurrentPageLink = (page: number) => {
    const newFilter: any = {
      ...filter,
      page,
    };
    setFilter(newFilter);
    dispatch(searchFilters(generateFilter(newFilter)));
  };

  const callbackAction = async (action: string, id: string, item: object) => {
    if (action === CONSTANTS.ACTION.EDIT)
      history.push({
        pathname: ROUTES.FILTER_DETAILS,
        state: {
          id,
          filter,
        },
      });

    if (action === CONSTANTS.ACTION.LOG)
      setLogData({ logType: "filter", rowId: id });

    if (action === CONSTANTS.ACTION.DEACTIVATE) {
      const status = _.get(item, "status");
      const filterName = _.get(item, "filterName");
      const statusLabel =
        status === CONSTANTS.STATUS.ACTIVE ? "deactivate" : "activate";
      const content = document.createElement("span");
      content.innerHTML = `${t(
        "alert.areYouSureYouWantTo"
      )}</br> <b>${statusLabel}</b> ${t(
        "alert.theFilter"
      )} <b>${filterName}</b>?`;
      await popupAlert({
        type: "warning",
        title: t("alert.warning"),
        content,
        buttons: true,
        dangerMode: true,
      }).then(async (willContinue) => {
        if (willContinue) {
          if (status === CONSTANTS.STATUS.ACTIVE)
            await dispatch(deactivateFilter(id));
          else await dispatch(activateFilter(id));
          await dispatch(searchFilters(generateFilter(filter)));
        }
      });
    }
  };

  const onReset = async () => {
    setFilter(DEFAULT_FILTER);
    setCurrentPage(1);
    await dispatch(searchFilters(generateFilter(DEFAULT_FILTER)));
  };

  const setValueStatus = (status: string) => {
    const newFilter = {
      ...filter,
      status,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(searchFilters(generateFilter(newFilter)));
    setCurrentPage(1);
  };

  const addFilter = (page: number) => {
    const newFilter = {
      ...filter,
      page: page,
    };
    setFilter(newFilter);
    dispatch(searchFilters(generateFilter(newFilter)));
    setCurrentPage(page);
  };

  const onCreate = () => {
    history.push({
      pathname: ROUTES.FILTER_DETAILS,
      state: {
        filter,
      },
    });
  };

  const onFetchMore = () => {
    if (!filtersIsLoading && currentPage < _.get(filters, "meta.totalPages"))
      addFilter(filter.page + 1);
  };

  const onShowMore = () => {
    if (!filtersIsLoading && currentPage < _.get(filters, "meta.totalPages"))
      addFilter(filter.page + 1);
  };

  const _renderTable = () => {
    return (
      <Table
        dataHeader={dataHead}
        dataTable={dataTable}
        fitColumns={[1, 6, 7]}
        listColumnCenter={listColumnCenter}
        hasPagination={true}
        callbackCurrentPageLink={callbackCurrentPageLink}
        metaPagination={filters.meta}
        callBackAction={callbackAction}
        listActionItem={[
          CONSTANTS.ACTION.EDIT,
          CONSTANTS.ACTION.DEACTIVATE,
          CONSTANTS.ACTION.LOG,
        ]}
        loading={filtersIsLoading}
        showMore={onShowMore}
      />
    );
  };

  const renderMain = () => {
    return (
      <>
        {!_.isEmpty(logData) && (
          <LogsDetails onClose={() => setLogData({})} data={logData} />
        )}
        <ContainerSearch className={filtersIsLoading ? "loading" : ""}>
          <FlexWrapRow>
            <Input
              value={_.get(filter, "filterName")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, filterName: e.target.value })
              }
              onEnter={() => addFilter(1)}
              placeholder={t("placeholder.filterName")}
              {...InputStyleProps}
            />
            <Input
              value={_.get(filter, "clientName")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, clientName: e.target.value })
              }
              onEnter={() => addFilter(1)}
              placeholder={t("placeholder.clientName")}
              {...InputStyleProps}
            />
            <Input
              value={_.get(filter, "clientNumber")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, clientNumber: e.target.value })
              }
              onEnter={() => addFilter(1)}
              placeholder={t("placeholder.clientNumber")}
              {...InputStyleProps}
              isNum
            />
            <Input
              value={_.get(filter, "accountName")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, accountName: e.target.value })
              }
              onEnter={() => addFilter(1)}
              placeholder={t("placeholder.packageName")}
              {...InputStyleProps}
            />

            <Button onClick={() => addFilter(1)} {...ButtonStyleProps}>
              {t("button.search")}
            </Button>
            <Button onClick={() => onReset()} {...ButtonStyleProps}>
              {t("button.reset")}
            </Button>
            {/* <Button
              onClick={() => dispatch(exportFilter())}
              {...ButtonStyleProps}
            >
              {t("button.export")}
            </Button> */}
            <Button
              onClick={() => onCreate()}
              background={ColorName.william}
              {...ButtonStyleProps}
            >
              + {t("button.create")}
            </Button>
          </FlexWrapRow>
          <Checkbox
            checked={_.get(filter, "status") === CONSTANTS.STATUS.INACTIVE}
            context={t("label.showInactiveFilters")}
            onChangeHandler={(e: boolean) =>
              setValueStatus(
                e ? CONSTANTS.STATUS.INACTIVE : CONSTANTS.STATUS.ACTIVE
              )
            }
            margin="10px 0 0 0"
          />
        </ContainerSearch>
        {_renderTable()}
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.ADMIN_ROUTES.FILTERS}
      title={t("titlePage.filters")}
      onScrollBottom={() => onFetchMore()}
    />
  );
};

export default Filters;
