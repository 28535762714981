import React from "react";
import { useTranslation } from "react-i18next";
import { UserFooterContainer } from "./UserFooter.styles";

const Footer = () => {
  const { t } = useTranslation("translation");

  const currentYear = new Date().getFullYear();

  return (
    <UserFooterContainer>
      {currentYear} © {t("footer.allRightReserved")}
    </UserFooterContainer>
  );
};

export default Footer;
