import styled from "styled-components";
import ColorName from "constants/Variables";
import { VerticalContainer } from "components/Common";

interface SectionProps {
  background?: string;
}

const Wrapper = styled(VerticalContainer)`
  padding: 1em 2em;
  display: flex;
  background: ${ColorName.white};
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 36px;
  font-weight: 700;
  color: ${ColorName.william};
  display: flex;
  justify-content: space-between;
`;

const SubTitle = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: ${ColorName.nepal};
`;

const Avatar = styled.div`
  display: flex;
  justify-content: flex-end;
  & img {
    object-fit: cover;
    width: 100%;
    height: 30px;
  }
  position: relative;
  & .wrapper-avatar {
    width: 30px;
    height: 30px;
    border: 1px solid ${ColorName.william};
    cursor: pointer;
    background: url(${(props: SectionProps) => props.background});
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    background-position: center;
  }
`;

const UserOption = styled(VerticalContainer)`
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: absolute;
  top: 60px;
  z-index: 9;
  min-width: 9em;
  max-width: 9em;
  & .user-info {
    background: ${ColorName.white};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 1em;
    & .status {
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: ${ColorName.william};
      margin-bottom: 0.2em;
    }
    & .full-name {
      font-weight: bold;
      font-size: 23px;
      line-height: 28px;
      color: ${ColorName.william};
    }
  }
  & .user-option {
    padding: 1em;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: ${ColorName.william};
    & .item-option {
      margin-top: 15px;
      &:first-child {
        margin-top: 0;
      }
      &:hover {
        cursor: pointer;
        color: ${ColorName.william};
        font-weight: bold;
      }
    }
  }
`;

const FlexHorizontal = styled.div`
  display: flex;
  align-items: center;
`;

const Dropdown = styled.div`
  position: relative;
  margin-right: 10px;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;
const DropdownMenu = styled.div`
  position: absolute;
  height: auto;
  padding: 10px 0;
  top: 20px;
  right: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  z-index: 9;
  background: ${ColorName.white};
`;
const DropdownItem = styled.div`
  height: 25px;
  display: flex;
  align-items: center;
  padding: 15px 15px;
  width: 100px;
  span {
    font-size: 13px;
    margin-left: 5px;
  }

  &:hover {
    cursor: pointer;
    background: ${ColorName.william};
    span {
      color: ${ColorName.white};
    }
  }
`;

const DropdownToggle = styled.div`
  display: flex;
  height: 20px;
  &:hover {
    cursor: pointer;
  }
`;

export {
  Wrapper,
  Title,
  SubTitle,
  UserOption,
  Avatar,
  DropdownToggle,
  FlexHorizontal,
  Dropdown,
  DropdownMenu,
  DropdownItem,
};
