/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "reducers";
import { DataRecordsActions } from "actions";

import RouteTypes from "constants/RouteTypes";
import { Images } from "themes";

import Pagination from "./Pagination";

import UserDefaultLayout from "components/AdminLayout/DefaultLayout/UserDefaultLayout";
import {
  ArticlesContainer,
  ArticlesDescription,
  ArticleTime,
  ArticleAuthor,
  ArticleContent,
  SkeletonLoading,
  NotFoundContainer,
  Button,
  ArticlesFilter,
  AdvanceFilter,
  AdvanceFilterLabel,
} from "./Articles.styles";
import {
  generateDropdown,
  //  generateDropdown,
  generateFilter,
  getSavedToken,
} from "libs/Utils";
import { Link } from "react-router-dom";
import {
  Dropdown,
  Input,
  // Dropdown,
  SelectDatetime,
} from "../../../components/Common";
import images from "../../../themes/Images";
import { useTranslation } from "react-i18next";

const { getDataRecordForUserPortal } = DataRecordsActions;

const DEFAULT_FILTER = {
  limit: 10,
  offset: 1,
  dateFrom: "",
  dateTo: "",
  filterIds: [],
  orderBy: "DESC",
  sortBy: "newest",
  mediaType: ["print", "web", "tv", "social network"],
};

interface FilterStructure {
  limit: number;
  offset: number;
  filterIds: string[];
  orderBy: string;
  sortBy: string;
  dateFrom: string;
  dateTo: string;
  mediaType: string[];
}

const SORT_BY = [
  // {
  //   label: "Author",
  //   value: "author",
  // },
  {
    label: "Headline",
    value: "title",
  },
  {
    label: "Newest",
    value: "newest",
  },
  {
    label: "Oldest",
    value: "oldest",
  },
];

const ORDER_BY = [
  {
    label: "A to Z",
    value: "ASC",
  },
  {
    label: "Z to A",
    value: "DESC",
  },
];

const Articles = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const isLogged = useSelector((state: RootState) =>
    _.get(state, "Authentication.isLogged")
  );
  const dataRecords = useSelector((state: RootState) =>
    _.get(state, "DataRecord.dataForUser")
  );
  const dataRecordsIsLoading = useSelector((state: RootState) =>
    _.get(state, "DataRecord.isLoading")
  );
  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const token = getSavedToken();

  const [isShowAdvanceFilter, setIsShowAdvanceFilter] =
    useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [filterOptions, setFilterOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const MEDIA_TYPE_OPTIONS = [
    {
      label: "TV",
      value: "tv",
    },
    {
      label: t("dropdown.print"),
      value: "print",
    },
    {
      label: "Web",
      value: "web",
    },
    {
      label: t("dropdown.socialNetwork"),
      value: "social network",
    },
  ];

  useEffect(() => {
    if (isLogged && token) {
      const resolveFilter = {
        ...filter,
      };
      const sortBy = _.get(filter, "sortBy");
      if (sortBy === "newest")
        _.assign(resolveFilter, { sortBy: "publishedAt", orderBy: "DESC" });
      if (sortBy === "oldest")
        _.assign(resolveFilter, { sortBy: "publishedAt", orderBy: "ASC" });
      dispatch(getDataRecordForUserPortal(generateFilter(resolveFilter)));
    }
  }, [isLogged, token]);

  useEffect(() => {
    if (!_.isEmpty(dataRecords) && firstLoad) {
      const filters = _.get(dataRecords, "listFilters");
      const getFilterOptions = generateDropdown({
        data: filters,
        key: "filterName",
        value: "id",
      });
      const filterIds: string[] = [];
      _.forEach(getFilterOptions, (item) => filterIds.push(item.value));

      setFilterOptions(getFilterOptions);
      setFilter({ ...filter, filterIds });
      setFirstLoad(false);
    }
  }, [dataRecords]);

  const renderContent = (content: string) => {
    const maxLength = 150;
    let trimmedString = content.substr(0, maxLength);

    content = trimmedString.substr(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    );
    return trimmedString + "...";
  };

  const onChangeFilter = (key: string, value: any) => {
    const newFilter = {
      ...filter,
      [key]: value,
    };
    if (key === "sortBy") {
      if (value === "title") _.assign(newFilter, { orderBy: "DESC" });
    }

    setFilter(newFilter);
  };

  const resolveFilterBeforeSubmit = (filter: any) => {
    const resolveFilter = { ...filter };
    const sortBy = _.get(filter, "sortBy");
    if (sortBy === "newest")
      _.assign(resolveFilter, { sortBy: "publishedAt", orderBy: "DESC" });
    if (sortBy === "oldest")
      _.assign(resolveFilter, { sortBy: "publishedAt", orderBy: "ASC" });
    dispatch(getDataRecordForUserPortal(generateFilter(resolveFilter)));
  };

  const callbackCurrentPage = (e: number) => {
    const newFilter = {
      ...filter,
      offset: e,
    };
    setFilter(newFilter);
    resolveFilterBeforeSubmit(newFilter);
  };

  const onFilter = () => {
    const newFilter = {
      ...filter,
      offset: 1,
    };
    setFilter(newFilter);
    resolveFilterBeforeSubmit(newFilter);
  };

  const onReset = () => {
    const filterIds: string[] = [];
    _.forEach(filterOptions, (item) => filterIds.push(item.value));
    const newFilter = {
      ...DEFAULT_FILTER,
      filterIds,
    };
    setFilter(newFilter);
    resolveFilterBeforeSubmit(newFilter);
  };

  const renderVideo = (payload: any) => {
    const mediaType = _.get(payload, "source.mediaType");
    if (mediaType === "tv") {
      let originalLink = _.get(payload, "originalLink");
      if (!originalLink) return null;
      const isYoutube = _.includes(originalLink, "youtube");
      if (isYoutube) originalLink = originalLink.replace("watch?v=", "embed/");

      return (
        <iframe
          width="100%"
          height="400px"
          src={originalLink}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded video"
        />
      );
    }
    return null;
  };

  const renderArticles = () => {
    const articles = _.get(dataRecords, "listRecords");
    const render = _.map(articles, (article, index) => {
      const mediaType = _.get(article, "source.mediaType");
      return (
        <ArticleContent key={`article-${index}`}>
          <ArticleAuthor>{_.get(article, "author")}</ArticleAuthor>
          <Link to={`${RouteTypes.USER_ROUTES.USER_ARTICLES}/${article.id}`}>
            {_.get(article, "title")}
          </Link>
          <ArticlesDescription>
            {mediaType === "tv"
              ? renderVideo(article)
              : renderContent(_.get(article, "content"))}
          </ArticlesDescription>
          <ArticleTime>
            {dayjs(_.get(article, "publishedAt")).format("YYYY, MMM D")}
          </ArticleTime>
        </ArticleContent>
      );
    });
    if (_.isEmpty(render))
      return (
        <NotFoundContainer className="empty_list">
          <img src={Images.emptyArticles} alt="" />
          <h2>We couldn't find any articles based on package filter</h2>
          <p>Please try later!</p>
        </NotFoundContainer>
      );
    return render;
  };

  const renderSkeletonLoading = () => {
    const skeletonItem = [0, 1, 2];
    const render = _.map(skeletonItem, (item) => {
      return (
        <SkeletonLoading className="articles-list" key={item}>
          <div className="author" />
          <div className="title" />
          <div className="content" />
          <div className="content" />
          <div className="date-time">
            <span className="category" />
            <span className="date" />
          </div>
        </SkeletonLoading>
      );
    });
    return <ArticlesContainer>{render} </ArticlesContainer>;
  };

  const renderTutorialSignIn = () => {
    return (
      <NotFoundContainer>
        <img src={images.guideToSignIn} alt="" style={{ maxWidth: "720px" }} />
        <p>Please login to read more news</p>
      </NotFoundContainer>
    );
  };

  const renderFilter = () => {
    return (
      <>
        <h2>Filter</h2>
        <ArticlesFilter>
          <SelectDatetime
            callbackPayload={(e) => onChangeFilter("dateFrom", e)}
            defaultValue={_.get(filter, "dateFrom")}
            placeholder="Date from"
            margin="10px 10px 10px 0px"
            width="150px"
            maxDate={_.get(filter, "dateTo") || new Date().toLocaleString()}
          />
          <SelectDatetime
            callbackPayload={(e) => onChangeFilter("dateTo", e)}
            defaultValue={_.get(filter, "dateTo")}
            placeholder="Date to"
            margin="10px 10px 10px 0"
            width="150px"
            minDate={_.get(filter, "dateFrom") || new Date().toLocaleString()}
          />

          <Input
            value={_.get(filter, "keyword")}
            onChangeHandler={(e: any) =>
              onChangeFilter("keyword", e.target.value)
            }
            placeholder="Keyword"
            width="200px"
            margin="10px 10px 10px 0"
          />
        </ArticlesFilter>
        <AdvanceFilterLabel
          onClick={() => setIsShowAdvanceFilter(!isShowAdvanceFilter)}
        >
          Advance{" "}
          <i
            className={`fas fa-angle-${isShowAdvanceFilter ? "up" : "down"}`}
          ></i>
        </AdvanceFilterLabel>
        <AdvanceFilter className={isShowAdvanceFilter ? "show" : ""}>
          <Dropdown
            value={_.get(filter, "filterIds")}
            margin="5px 10px 10px 0"
            callbackPayload={(e) => onChangeFilter("filterIds", e)}
            options={filterOptions}
            placeholder="Filters"
            width="200px"
            multiple
          />
          <Dropdown
            value={_.get(filter, "mediaType")}
            margin="5px 10px 10px 0"
            callbackPayload={(e) => onChangeFilter("mediaType", e)}
            options={MEDIA_TYPE_OPTIONS}
            placeholder="Media type"
            width="200px"
            multiple
          />
          <Dropdown
            value={_.get(filter, "sortBy")}
            margin="5px 10px 10px 0"
            callbackPayload={(e) => onChangeFilter("sortBy", e)}
            options={SORT_BY}
            placeholder="Sort by"
            width="200px"
          />
          {_.get(filter, "sortBy") === "title" && (
            <Dropdown
              value={_.get(filter, "orderBy")}
              margin="5px 10px 10px 0"
              callbackPayload={(e) => onChangeFilter("orderBy", e)}
              options={ORDER_BY}
              placeholder="Order by"
              width="200px"
            />
          )}
        </AdvanceFilter>
        <ArticlesFilter style={{ height: "55px" }}>
          <Button
            onClick={() => onFilter()}
            style={{ margin: "10px 10px 10px 0" }}
          >
            Filter
          </Button>
          <Button
            onClick={() => onReset()}
            style={{ margin: "10px 10px 10px 0" }}
          >
            Reset
          </Button>
        </ArticlesFilter>
      </>
    );
  };

  const renderMain = () => {
    if (dataRecordsIsLoading) return renderSkeletonLoading();
    return (
      <ArticlesContainer>
        {isLogged && renderFilter()}
        {isLogged ? renderArticles() : renderTutorialSignIn()}
        {!_.isEmpty(_.get(dataRecords, "listRecords")) && (
          <Pagination
            maxPage={_.toNumber(_.get(dataRecords, "meta.total")) / 10}
            currentPage={_.toNumber(_.get(dataRecords, "meta.currentPage"))}
            numberPageDisplay={2}
            totalCount={_.get(dataRecords, "meta.totalItems")}
            clickLinkPagination={(e: any) => callbackCurrentPage(e)}
            clickLinkNext={(e: any) => callbackCurrentPage(e)}
            clickLinkPrevious={(e: any) => callbackCurrentPage(e)}
            clickLinkFirst={() => callbackCurrentPage(1)}
            clickLinkLast={() =>
              callbackCurrentPage(
                _.toNumber(_.get(dataRecords, "meta.totalItems")) / 10
              )
            }
          />
        )}
      </ArticlesContainer>
    );
  };
  return (
    <UserDefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.USER_ROUTES.USER_ARTICLES}
    />
  );
};

export default Articles;
