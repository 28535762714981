import styled from "styled-components";
import ColorName from "../../../constants/Variables";

const NotFoundPageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const NotFoundContent = styled.div`
  padding: 10px;
  box-sizing: border-box;
`;

const Paragraph = styled.p`
  text-align: center;
  span {
    display: inline-block;
    margin-left: 5px;
    text-decoration: underline;
    color: ${ColorName.william};
    &:hover {
      cursor: pointer;
    }
  }
`;

export { NotFoundPageContainer, NotFoundContent, Paragraph };
