import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { RootState } from "reducers";
import { UsersActions } from "actions";

import { Images } from "themes";

import { Wrapper, Title } from "./Authentication.styles";
import { Input, Button, Loading } from "components/Common";
import _ from "lodash";
import { popupAlert } from "../../libs/Utils";

const { resetPassword } = UsersActions;

const ForgotPassword = () => {
  const [confirmationNewPassword, setConfirmationNewPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => {
    return state.Authentication.isLoading;
  });

  const changePassword = () => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const email = params.get("e");
    const code = params.get("c");

    const requireData = [
      {
        label: "New password",
        value: _.trim(newPassword),
      },
      {
        label: "Confirm password",
        value: _.trim(confirmationNewPassword),
      },
    ];
    let isValid = true;
    let message = "Found empty field(s):\n";
    _.map(requireData, (item) => {
      const value = item?.value;
      const label = item?.label;
      if (_.isEmpty(_.trim(value))) {
        isValid = false;
        message += `${label}\n`;
      }
    });

    if (!isValid) {
      return popupAlert({ type: "warning", title: "Warning", text: message });
    }

    const isSamePassword =
      _.trim(newPassword) === _.trim(confirmationNewPassword);
    if (!isSamePassword)
      return popupAlert({
        type: "warning",
        title: "Warning",
        text: "New password and confirm password have to same.\n Please retype!",
      });

    const data = {
      email,
      code,
      newPassword,
      confirmationNewPassword,
    };
    dispatch(resetPassword(data));
  };

  return (
    <Wrapper>
      <div className="container-login">
        <div className="header-login">
          <img src={Images.logo} alt="Media Monitoring system" />
        </div>
        {isLoading && <Loading />}
        <Title>New Password</Title>
        <Input
          type="password"
          margin="10px 0"
          onChangeHandler={(e: any) => setNewPassword(e.target.value)}
          placeholder="New Password"
          onEnter={() => changePassword()}
        />
        <Input
          type="password"
          margin="10px 0"
          onChangeHandler={(e: any) =>
            setConfirmationNewPassword(e.target.value)
          }
          placeholder="Confirm New Password"
          onEnter={() => changePassword()}
        />
        <Button
          onClick={() => changePassword()}
          className="form-button"
          buttonMargin="10px 0"
        >
          Change Password
        </Button>
      </div>
    </Wrapper>
  );
};

export default ForgotPassword;
