import ActionTypes from "constants/ActionTypes";

const initialState = {
  hasError: false,
  isLoading: false,
  isSuccess: false,
  members: [],
  member: {},
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.CLEAR_MEMBER_DETAILS:
      return {
        ...state,
        member: {},
      };
    case ActionTypes.MEMBER_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    case ActionTypes.MEMBER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.MEMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.CREATE_MEMBER_SUCCESS:
      return {
        ...state,
        user: payload,
        isLoading: false,
        isSuccess: true,
      };
    case ActionTypes.UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        user: payload,
        isLoading: false,
        isSuccess: true,
      };
    case ActionTypes.GET_MEMBER_BY_ID_SUCCESS:
      return {
        ...state,
        user: payload,
        isLoading: false,
        isSuccess: true,
        member: payload,
      };
    case ActionTypes.FETCH_MEMBER_SUCCESS:
      return {
        ...state,
        user: payload,
        isLoading: false,
        isSuccess: true,
        members: payload,
      };
    case ActionTypes.SEARCH_MEMBER_SUCCESS:
      return {
        ...state,
        user: payload,
        isLoading: false,
        isSuccess: true,
        members: payload,
      };
    default:
      return state;
  }
};
