import styled from "styled-components";
import ColorName from "constants/Variables";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1140px;
`;

const ContainerForm = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  & .form-title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 41px;
    color: ${ColorName.william};
    border-top: 1px solid ${ColorName.william};
    max-width: 620px;
    width: 100%;
    padding-top: 1em;
  }
  & .form-group {
    color: ${ColorName.william};
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    & .empty {
      color: ${ColorName.amaranth};
      font-size: 12px;
      height: 17px;
    }
    &.for-setting {
      align-items: flex-start;
    }
    & .container-element {
      width: 300px;
      padding: 15px 20px 15px 0;
    }

    & span {
      min-height: 1px;
    }
    &.fields {
      align-items: flex-start;
      margin: 10px 0;
    }
  }
  & #custom-file-input {
    border: none;
    box-shadow: none;
    padding: 0;
    width: 120px;
  }

  & #custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }

  & #custom-file-input::before {
    content: "Choose a file";
    display: inline-block;
    border: 1px solid #999;
    border-radius: 3px;
    padding: 8px 12px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 600;
    background: ${ColorName.burntSienna};
    color: white;
  }
  & .row-fields {
    position: relative;
    margin-top: 10px;
    & i {
      position: absolute;
      right: 0px;
      bottom: -25px;
      height: 16px;
      cursor: pointer;
      &.fa-times-circle {
        right: -10px;
        top: -5px;
        z-index: 9;
      }
    }
  }
`;

const TemplateDetailsContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.25);
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  & .justify-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & .justify-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .justify-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

const TemplateDetailsHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${ColorName.william};
  color: white;
  font-size: 15px;
  height: 35px;
`;

const TemplateDetailsContent = styled.div`
  width: 50vw;
  height: 70vh;
  max-width: 1040px;
  max-height: 600px;
  background: white;
`;

const TemplateDetailsForm = styled.div`
  padding: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  & .content {
    width: 100%;
    max-width: 720px;
  }

  & .form-group {
    color: ${ColorName.william};
    align-items: center;
    font-size: 16px;
    margin-bottom: 15px;
    & .empty {
      color: ${ColorName.amaranth};
      font-size: 12px;
      height: 17px;
    }
    > span {
      display: inline-block;
      min-height: 1px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &.fields {
      align-items: flex-start;
      margin: 10px 0;
    }
  }
`;

const FormRow = styled.div`
  display: flex;
  & .form-group {
    flex: 1;
    max-width: calc(50% - 10px);
    &:nth-child(1) {
      margin-right: 20px;
    }
  }
`;

const ButtonField = styled.div`
  display: flex;
  background: ${ColorName.white};
  justify-content: flex-end;
  padding: 10px 15px 10px 0;

  &.flex-justify-start {
    justify-content: flex-start;
  }
`;

const InputNote = styled.span``;

const EmailTemplateContent = styled.div`
  position: relative;
`;

const PreLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-weight: 600;
    font-size: 16px;
    color: ${ColorName.william};
  }
`;

export {
  Container,
  ContainerForm,
  TemplateDetailsContainer,
  TemplateDetailsHeader,
  TemplateDetailsContent,
  TemplateDetailsForm,
  ButtonField,
  InputNote,
  FormRow,
  EmailTemplateContent,
  PreLayer,
};
