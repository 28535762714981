/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { RootState } from "reducers";
import { RoleActions } from "actions";

import RouteTypes from "constants/RouteTypes";
import ColorName from "constants/Variables";
import { CONSTANTS } from "constants/Constants";

import history from "../../../history";
import TableUltils from "libs/TableUltils";
import { generateFilter, popupAlert } from "libs/Utils";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Button,
  Input,
  Checkbox,
  Table,
  ContainerSearch,
  FlexWrapRow,
} from "components/Common";
import { useTranslation } from "react-i18next";
import { LogsDetails } from "components/Common/Popup";
import { useLocation } from "react-router-dom";

const { searchRoles, activateRole, deactivateRole, clearRoles } = RoleActions;

const DEFAULT_FILTER = {
  keyword: "",
  status: CONSTANTS.STATUS.ACTIVE,
  limit: 10,
  page: 1,
  orderBy: "DESC",
  sortBy: "updatedAt",
};

interface FilterStructure {
  keyword: string;
  status: string;
  limit: number;
  page: number;
}

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "10px 10px 10px 0",
};

const SourceSettings = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const location = useLocation();
  const rolesIsLoading = useSelector((state: RootState) => {
    return state.Role.isLoading;
  });
  const roles = useSelector((state: RootState) => {
    return state.Role.roles;
  });
  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const [logData, setLogData] = useState<any>({});

  useEffect(() => {
    const stateFilter: any = _.get(location, "state.filter");
    const prevRoute = _.get(location, "state.from");
    const resolveFilter = {
      ...filter,
    };
    if (stateFilter && prevRoute === RouteTypes.ADMIN_ROUTES.ROLE_DETAILS) {
      _.assign(resolveFilter, { ...stateFilter });
      history.replace({ ...history.location, state: {} });
    }
    setFilter(resolveFilter);
    dispatch(searchRoles(generateFilter(resolveFilter)));

    return () => {
      dispatch(clearRoles());
    };
  }, []);

  const dataHead = [
    "no",
    "roleName",
    "description",
    "status",
    "createdAt",
    "actions",
  ];

  const dataTable = TableUltils._processDataTable(
    ["no", "id", "name", "description", "status", "createdAt", "action"],
    roles
  );

  const listColumnCenter = [4, 6];

  const callbackAction = async (action: string, id: string, item: any) => {
    if (action === CONSTANTS.ACTION.EDIT) {
      history.push({
        pathname: RouteTypes.ADMIN_ROUTES.ROLE_DETAILS,
        state: { id, filter },
      });
    }
    if (action === CONSTANTS.ACTION.LOG)
      setLogData({ logType: "role", rowId: id });

    if (action === CONSTANTS.ACTION.DEACTIVATE) {
      const status = _.get(item, "status");
      const roleName = _.get(item, "name");
      const statusLabel =
        status === CONSTANTS.STATUS.ACTIVE
          ? t("alert.deactive")
          : t("alert.active");
      const content = document.createElement("span");
      content.innerHTML = `${t(
        "alert.areYouSureYouWantTo"
      )}</br> <b>${statusLabel}</b> ${t("alert.theRole")} <b>${roleName}</b>?`;
      await popupAlert({
        type: "warning",
        title: t("alert.warning"),
        content,
        buttons: true,
        dangerMode: true,
      }).then(async (willContinue) => {
        if (willContinue) {
          if (status === CONSTANTS.STATUS.ACTIVE)
            await dispatch(deactivateRole(id));
          else await dispatch(activateRole(id));
          await dispatch(searchRoles(generateFilter(filter)));
        }
      });
    }
  };

  const onChangeRoleStatus = async (status: string) => {
    const newFilter = {
      ...filter,
      status,
      page: 1,
    };
    setFilter(newFilter);
    await dispatch(searchRoles(generateFilter(newFilter)));
  };

  const onReset = async () => {
    setFilter(DEFAULT_FILTER);
    await dispatch(searchRoles(generateFilter(DEFAULT_FILTER)));
  };

  const onSearch = async () => {
    const newFilter = {
      ...filter,
      page: 1,
    };
    setFilter(newFilter);
    await dispatch(searchRoles(generateFilter(newFilter)));
  };

  const onCreate = () => {
    history.push({
      pathname: RouteTypes.ADMIN_ROUTES.ROLE_DETAILS,
      state: { filter },
    });
  };

  const _renderTable = () => {
    return (
      <Table
        dataHeader={dataHead}
        dataTable={dataTable}
        listActionItem={[
          CONSTANTS.ACTION.EDIT,
          CONSTANTS.ACTION.DEACTIVATE,
          CONSTANTS.ACTION.LOG,
        ]}
        callBackAction={callbackAction}
        fitColumns={[1, 4, 5, 6]}
        listColumnCenter={listColumnCenter}
        loading={rolesIsLoading}
        isShowMore={false}
      />
    );
  };

  const renderMain = () => {
    return (
      <>
        {!_.isEmpty(logData) && (
          <LogsDetails onClose={() => setLogData({})} data={logData} />
        )}
        <ContainerSearch className={rolesIsLoading ? "loading" : ""}>
          <FlexWrapRow>
            <Input
              margin="10px 10px 10px 0"
              width="200px"
              placeholder={t("placeholder.roleName")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, keyword: e.target.value })
              }
              onEnter={() => onSearch()}
              value={_.get(filter, "keyword")}
            />
            <Button onClick={() => onSearch()} {...ButtonStyleProps}>
              {t("button.search")}
            </Button>
            <Button onClick={() => onReset()} {...ButtonStyleProps}>
              {t("button.reset")}
            </Button>
            <Button
              onClick={() => onCreate()}
              background={ColorName.william}
              {...ButtonStyleProps}
            >
              + {t("button.create")}
            </Button>
          </FlexWrapRow>
          <Checkbox
            checked={_.get(filter, "status") === CONSTANTS.STATUS.INACTIVE}
            context={t("label.showInactiveRoles")}
            onChangeHandler={(e: boolean) =>
              onChangeRoleStatus(
                e ? CONSTANTS.STATUS.INACTIVE : CONSTANTS.STATUS.ACTIVE
              )
            }
            margin="10px 0 0 0"
          />
        </ContainerSearch>
        {_renderTable()}
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.ADMIN_ROUTES.ROLES}
      title={t("titlePage.roles")}
    />
  );
};

export default SourceSettings;
