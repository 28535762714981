import _ from "lodash";

interface tempObject {
  [key: string]: any;
}

const _processDataTable = (listColumnData: any, dataTable: any) => {
  let dataProcess: any = [];
  _.map(dataTable, (item) => {
    let temp: tempObject = {};
    _.map(listColumnData, (column) => {
      if (column === "mediaType") temp[column] = _.toUpper(_.get(item, column));
      else temp[column] = _.get(item, column);
    });
    dataProcess.push(temp);
  });
  return dataProcess;
};

const _processDataTableLogs = (listColumnData: any, dataTable: any) => {
  let dataProcess: any = [];
  _.map(dataTable, (item) => {
    let temp: tempObject = {};
    _.map(listColumnData, (column) => {
      if (column === "content") {
        const itemType = _.get(item, "data.type");
        if (itemType === "expire")
          return (temp[column] =
            _.capitalize(_.get(item, "logType")) +
            " " +
            _.get(item, "name") +
            " is going to " +
            _.get(item, "data.type") +
            " soon");
        if (itemType === "expired")
          return (temp[column] =
            _.capitalize(_.get(item, "logType")) +
            " " +
            _.get(item, "name") +
            " was " +
            _.get(item, "data.type"));
        return (temp[column] =
          _.capitalize(_.get(item, "logType")) +
          " " +
          _.get(item, "name") +
          " was " +
          _.get(item, "data.type") +
          "d by " +
          _.get(item, "data.createdBy"));
      }

      if (column === "logType")
        return (temp[column] = _.capitalize(_.get(item, "logType")));
      return (temp[column] = _.get(item, column));
    });
    dataProcess.push(temp);
  });
  return dataProcess;
};

export default {
  _processDataTable,
  _processDataTableLogs,
};
