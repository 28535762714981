/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useLocation } from "react-router-dom";

import { RootState } from "reducers";
import {
  AccountActions,
  CategoriesActions,
  ClientActions,
  DataRecordsActions,
  FiltersActions,
  SourceSettingsActions,
} from "actions";

import { CONSTANTS } from "constants/Constants";
import RouteTypes from "constants/RouteTypes";

import history from "../../../history";
import {
  processDataDropDownAccount,
  processDataDropDownClient,
  processDataDropDown,
  popupAlert,
} from "libs/Utils";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  ContainerFormCreateUser,
  Content,
  RelativeField,
  QueryBox,
  RemoveButton,
  PreviewRawContent,
} from "./Filters.styles";
import {
  Input,
  Dropdown,
  Button,
  Textarea,
  Loading,
  LoadingLayer,
  Spinner,
  FormDetails,
  FormGroup,
  FormInline,
  FormLabel,
  ButtonField,
  TitlePartOfForm,
} from "components/Common";
import { ResultFilterTest } from "components/Common/Popup";
import { useTranslation } from "react-i18next";

const { searchAccounts } = AccountActions;
const { fetchAllClients } = ClientActions;
const { fetchAllSource } = SourceSettingsActions;
const { fetchAllCategories } = CategoriesActions;
const { previewDataRecordBeforeCreate } = DataRecordsActions;
const { clearFilterDetails, createFilter, getFilterByID, updateFilter } =
  FiltersActions;

interface SectionProps {}

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "100px 10px 30px 0",
};

const FilterDetails: React.FC<SectionProps> = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state: RootState) => {
    return state.Users.isLoading;
  });
  const dateRecordIsLoading = useSelector((state: RootState) => {
    return state.DataRecord.isLoading;
  });
  const clients = useSelector((state: RootState) => {
    return state.Clients.clients;
  });
  const clientsIsLoading = useSelector((state: RootState) => {
    return state.Clients.isLoading;
  });
  const dataRecord = useSelector((state: RootState) => {
    return state.DataRecord.dataRecord;
  });
  const accounts = useSelector((state: RootState) => {
    return state.Account.accounts;
  });
  const accountsIsLoading = useSelector((state: RootState) => {
    return state.Account.isLoading;
  });
  const sources = useSelector((state: RootState) => {
    return state.SourceSettings.sourceSettings;
  });
  const sourcesIsLoading = useSelector((state: RootState) => {
    return state.SourceSettings.isLoading;
  });
  const categories = useSelector((state: RootState) => {
    return state.Categories.categories;
  });
  const categoriesIsLoading = useSelector((state: RootState) => {
    return state.Categories.isLoading;
  });
  const filter = useSelector((state: RootState) => {
    return state.Filters.filter;
  });
  const filtersIsLoading = useSelector((state: RootState) => {
    return state.Filters.isLoading;
  });
  const [clientId, setClientId] = useState("");
  const [isShowTestDataPopup, setIsShowTestDataPopup] = useState(false);
  const [filterData, setFilterData] = useState({
    clientId: "",
    accountId: "",
    filterName: "",
    mediaType: [],
    source: [],
    category: [],
    exactPharse: "",
    exclude: "",
    language: "en",
  });

  const dataDropdownSearchType = [
    {
      label: t("label.or"),
      value: "or",
    },
    {
      label: t("label.and"),
      value: "and",
    },
    {
      label: t("label.not"),
      value: "not",
    },
  ];

  const [advancedData, setAdvancedData]: any = useState([
    {
      relative: "",
      content: [
        {
          keyword: "",
          type: "or",
        },
      ],
    },
  ]);

  const optionMediaType = [
    { label: t("dropdown.print"), value: CONSTANTS.MEDIA_TYPE.PRINT },
    { label: t("dropdown.web"), value: CONSTANTS.MEDIA_TYPE.WEB },
    { label: t("dropdown.tv"), value: CONSTANTS.MEDIA_TYPE.TV },
    {
      label: t("dropdown.socialNetwork"),
      value: CONSTANTS.MEDIA_TYPE.SOCIAL_NETWORK,
    },
  ];

  const dataDropdownClients = processDataDropDownClient(clients);
  const dataDropdownAccounts = processDataDropDownAccount(accounts.items);
  const dataDropdownSources = processDataDropDown(
    _.filter(sources, (item) =>
      _.includes(filterData.mediaType, item?.mediaType)
    )
  );
  const dataDropdownCategories = processDataDropDown(categories);
  const dataDropdownLanguage = [
    {
      label: "Both",
      value: "both",
    },
    {
      label: "English",
      value: "en",
    },
    {
      label: "Albanian",
      value: "al",
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(clearFilterDetails());
      history.replace({ ...history.location, state: {} });
    };
  }, []);

  useEffect(() => {
    dispatch(fetchAllClients({ status: CONSTANTS.STATUS.ACTIVE }));
    dispatch(fetchAllSource({ status: CONSTANTS.STATUS.ACTIVE }));
    dispatch(fetchAllCategories({ status: CONSTANTS.STATUS.ACTIVE }));
    const id: string = _.get(location, "state.id") || "";
    if (id) dispatch(getFilterByID(id));
  }, [dispatch, location]);

  useEffect(() => {
    if (clientId) {
      dispatch(searchAccounts({ clientId, status: CONSTANTS.STATUS.ACTIVE }));
    }
  }, [clientId, dispatch]);

  useEffect(() => {
    if (!_.isEmpty(filter)) {
      const { contentFilter, ...rest } = filter;
      const clientId = _.get(rest, "clientId.id");
      const accountId = _.get(rest, "accountId.id");
      setClientId(clientId);
      setAdvancedData(contentFilter);
      setFilterData({ ...rest, accountId, clientId });
    }
  }, [filter]);

  const validateQueryField = () => {
    let flag = true;
    _.map(advancedData, (item) => {
      const content = _.get(item, "content");
      _.map(content, (childItem) => {
        const keyword = _.get(childItem, "keyword");
        if (!keyword) flag = false;
      });
    });
    return flag;
  };

  const validateData = () => {
    let message = `${t("alert.foundEmptyFields")}`;
    let flag = true;
    const data = [
      {
        label: t("label.client"),
        value: _.get(filterData, "clientId"),
      },
      {
        label: t("label.package"),
        value: _.get(filterData, "accountId"),
      },
      {
        label: t("label.filterName"),
        value: _.get(filterData, "filterName"),
      },
      {
        label: t("label.mediaTypes"),
        value: _.get(filterData, "mediaType"),
      },
      {
        label: t("label.sources"),
        value: _.get(filterData, "source"),
      },
      {
        label: t("label.categories"),
        value: _.get(filterData, "category"),
      },
    ];
    _.map(data, (item) => {
      const value = _.get(item, "value");
      const label = _.get(item, "label");
      if ((_.isString(value) && _.isEmpty(_.trim(value))) || _.isEmpty(value)) {
        flag = false;
        message += `${label}\n`;
      }
    });
    const isEmptyKeyword = validateQueryField();
    if (!isEmptyKeyword) {
      flag = false;
      message += `${t("label.atLeastOneKeyword")}\n`;
    }
    return { flag, message };
  };

  const onSubmit = async () => {
    const { flag, message } = validateData();

    if (!flag)
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });
    else {
      const data = {
        clientId: filterData.clientId,
        accountId: filterData.accountId,
        filterName: filterData.filterName,
        mediaType: filterData.mediaType,
        source: filterData.source,
        category: filterData.category,
        language: filterData.language,
        contentFilter: advancedData,
      };
      const filterId = _.get(filterData, "id");
      const resolveData = {
        ...data,
        exactPharse: filterData.exactPharse,
        exclude: filterData.exclude,
      };

      const filter = _.get(location, "state.filter");
      const resolveFilter = {
        pathname: RouteTypes.ADMIN_ROUTES.FILTERS,
        state: { filter, from: RouteTypes.ADMIN_ROUTES.FILTER_DETAILS },
      };

      if (filterId) {
        await dispatch(updateFilter(resolveData, filterId, resolveFilter));
      } else await dispatch(createFilter(data, resolveFilter));
    }
  };

  const removeChildQuery = async (index: number, childIndex: number) => {
    const isAgree = await popupAlert({
      type: "warning",
      title: t("alert.warning"),
      text: t("alert.warningBeforeRemoveQuery"),
      buttons: true,
      dangerMode: true,
    });
    if (isAgree) {
      const data = [...advancedData];
      const currentData = data[index];
      const currentContent = _.get(currentData, "content");
      const newContent = _.filter(
        currentContent,
        (item, index) => _.toNumber(index) !== childIndex
      );
      const resolveData = { ...currentData, content: newContent };
      data[index] = resolveData;
      setAdvancedData(data);
    }
  };

  const removeQuery = async (index: number) => {
    const isAgree = await popupAlert({
      type: "warning",
      title: t("alert.warning"),
      text: t("alert.warningBeforeRemoveQuery"),
      buttons: true,
      dangerMode: true,
    });
    if (isAgree) {
      const data = [...advancedData];
      const newContent = _.filter(
        data,
        (item, dataIndex) => _.toNumber(dataIndex) !== index
      );
      setAdvancedData(newContent);
    }
  };

  const addNewQuery = async () => {
    const isValid = validateQueryField();
    if (!isValid)
      return await popupAlert({
        type: "warning",
        title: t("alert.warning"),
        text: t("alert.warningEmptyFieldBeforeAddNewQuery"),
      });
    const newQuery = {
      relative: "or",
      content: [
        {
          keyword: "",
          type: "or",
        },
      ],
    };
    const data = [...advancedData, newQuery];
    setAdvancedData(data);
  };

  const setValueForInput = (
    field: string,
    value: any,
    index: number,
    childIndex?: number
  ) => {
    const newValue = {
      [field]: value,
    };
    const data = [...advancedData];
    const currentData = data[index];
    if (_.isNumber(childIndex)) {
      const childContent = _.get(currentData, "content");
      childContent[childIndex] = {
        ...childContent[childIndex],
        ...newValue,
      };
      data[index] = {
        ...currentData,
        content: childContent,
      };
      setAdvancedData(data);
    } else {
      data[index] = {
        ...data[index],
        ...newValue,
      };
      setAdvancedData(data);
    }
  };

  const addNewColumn = async (index: number) => {
    const isValid = validateQueryField();
    if (!isValid)
      return await popupAlert({
        type: "warning",
        title: t("alert.warning"),
        text: t("alert.warningEmptyFieldBeforeAddNewQuery"),
      });
    const data = [...advancedData];
    if (_.isNumber(index)) {
      const currentQuery = data[index];
      const newContent = [
        ...currentQuery.content,
        {
          keyword: "",
          type: "or",
          relative: "and",
        },
      ];
      data[index].content = newContent;
      setAdvancedData(data);
    }
  };

  const requireBeforeTest = () => {
    let message = `${t("alert.foundEmptyFields")}`;
    let flag = true;
    const data = [
      {
        label: t("label.mediaTypes"),
        value: _.get(filterData, "mediaType"),
      },
      {
        label: t("label.sources"),
        value: _.get(filterData, "source"),
      },
      {
        label: t("label.categories"),
        value: _.get(filterData, "category"),
      },
    ];
    _.map(data, (item) => {
      const value = _.get(item, "value");
      const label = _.get(item, "label");
      if (_.isEmpty(value)) {
        flag = false;
        message += `${label}\n`;
      }
    });
    const isEmptyKeyword = validateQueryField();
    if (!isEmptyKeyword) {
      flag = false;
      message += `${t("label.atLeastOneKeyword")}\n`;
    }
    return { flag, message };
  };

  const onTest = async () => {
    const { flag, message } = requireBeforeTest();
    if (!flag)
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });
    const resolveData = {
      ...filterData,
      page: 1,
      limit: 10,
      category: _.join(filterData.category, ","),
      mediaType: _.join(filterData.mediaType, ","),
      source: _.join(filterData.source, ","),
      contentFilter: advancedData,
      language: filterData.language,
      exactPharse: _.get(filterData, "exactPharse") || "",
      exclude: _.get(filterData, "exclude") || "",
    };
    dispatch(
      previewDataRecordBeforeCreate(
        _.pickBy(_.omit(resolveData, ["id"]), _.identity)
      )
    );
    setIsShowTestDataPopup(true);
  };

  const onBack = () => {
    const filter = _.get(location, "state.filter");
    history.push({
      pathname: RouteTypes.ADMIN_ROUTES.FILTERS,
      state: { filter, from: RouteTypes.ADMIN_ROUTES.FILTER_DETAILS },
    });
  };

  const renderQueryBox = (
    content: any[],
    index: number,
    filterLength: number
  ) => {
    if (_.isArray(content) && !_.isEmpty(content)) {
      const render = _.map(content, (contentItem, innerIndex) => {
        const childIndex = _.toNumber(innerIndex);
        return (
          <div key={`query-${innerIndex}`} className="advanced-field">
            <RelativeField>
              {_.get(contentItem, "relative") && (
                <Dropdown
                  value={contentItem.relative}
                  width="100px"
                  callbackPayload={(value) =>
                    setValueForInput("relative", value, index, childIndex)
                  }
                  placeholder={filtersIsLoading ? t(`placeholder.loading`) : ""}
                  options={dataDropdownSearchType}
                  margin="10px 20px 10px 0"
                />
              )}
            </RelativeField>
            <Textarea
              value={contentItem.keyword || ""}
              margin="10px 20px 10px 0"
              onChangeHandler={(e) =>
                setValueForInput("keyword", e.target.value, index, childIndex)
              }
              placeholder={t(
                `placeholder.${filtersIsLoading ? "loading" : "keyword"}`
              )}
              width="215px"
              rows={2}
            />
            <Dropdown
              value={contentItem.type}
              width="100px"
              callbackPayload={(value) =>
                setValueForInput("type", value, index, childIndex)
              }
              options={dataDropdownSearchType}
              margin="10px 20px 10px 0"
            />
            {innerIndex !== 0 && (
              <Button
                type="submit"
                onClick={() => removeChildQuery(index, childIndex)}
                buttonWidth="unset"
                buttonMargin="10px 10px 10px 0"
              >
                {t("button.remove")}
              </Button>
            )}

            {content.length - 1 === childIndex && (
              <Button
                type="submit"
                onClick={() => addNewColumn(index)}
                buttonWidth="unset"
                buttonMargin="10px 10px 10px 0"
              >
                + {t("button.moreCondition")}
              </Button>
            )}
          </div>
        );
      });
      return (
        <QueryBox>
          {render}
          {filterLength > 1 && (
            <RemoveButton onClick={() => removeQuery(index)}>
              <i className="fas fa-times" />
            </RemoveButton>
          )}
        </QueryBox>
      );
    }
  };

  const _renderAdvancedFilter = () => {
    const render = _.map(advancedData, (item, index) => {
      const isLastQuery = _.toNumber(index) === advancedData.length - 1;
      const content = _.get(item, "content");
      return (
        <React.Fragment key={index}>
          {_.toNumber(index) !== 0 &&
            _.toNumber(index) < advancedData.length && (
              <Dropdown
                key={`parent-relative-${index}`}
                value={item.relative}
                width="100px"
                callbackPayload={(value) =>
                  setValueForInput("relative", value, _.toNumber(index))
                }
                options={dataDropdownSearchType}
                placeholder={filtersIsLoading ? t("placeholder.loading") : ""}
                margin="10px 20px 10px 0"
              />
            )}
          {renderQueryBox(content, _.toNumber(index), advancedData.length)}
          {isLastQuery && (
            <Button
              key={`add-condition-button-${index}`}
              type="submit"
              onClick={() => addNewQuery()}
              buttonWidth="unset"
              buttonMargin="10px 10px 10px 0"
            >
              + {t("button.moreCondition")}
            </Button>
          )}
        </React.Fragment>
      );
    });
    return render;
  };

  const renderPreviewRaw = () => {
    const render = _.map(advancedData, (item) => {
      const content = _.get(item, "content");
      const relative = _.get(item, "relative");
      const childRender = _.map(content, (childItem) => {
        const keyword = _.get(childItem, "keyword");
        if (!keyword) return "";
        const type = _.get(childItem, "type");
        const childRelative = _.get(childItem, "relative");
        const result = _.replace(
          keyword,
          / ,|, | , |,/g,
          ` ${_.upperCase(type)} `
        );
        if (childRelative)
          return `\n${_.upperCase(childRelative)}\n ( ${result} )`;
        return `( ${result} )`;
      });
      const childRenderResult = _.join(childRender, "");
      if (relative && childRender.length > 1)
        return ` \n${_.upperCase(relative)}\n (${childRenderResult})\n`;
      if (relative && childRender.length === 1)
        return ` \n${_.upperCase(relative)}\n ${childRenderResult}\n`;
      if (!relative && childRender.length > 1) return `(${childRenderResult})`;
      if (!relative && childRender.length === 1) return `${childRenderResult}`;
      return "";
    });

    return render;
  };

  const renderMain = () => {
    return (
      <>
        {isShowTestDataPopup && (
          <ResultFilterTest
            data={dataRecord}
            loading={dateRecordIsLoading}
            onClose={() => setIsShowTestDataPopup(false)}
          />
        )}
        {isLoading && <Loading />}
        <FormDetails>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.client")}</FormLabel>
              <Dropdown
                value={clientId}
                width="300px"
                callbackPayload={(value) => {
                  setClientId(value);
                  setFilterData({
                    ...filterData,
                    clientId: value,
                    accountId: "",
                  });
                }}
                options={dataDropdownClients}
                placeholder={t(
                  `placeholder.${filtersIsLoading ? "loading" : "client"}`
                )}
                loading={clientsIsLoading}
                search={true}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.package")}</FormLabel>
              <Dropdown
                value={filterData.accountId}
                width="300px"
                callbackPayload={(value) =>
                  setFilterData({ ...filterData, accountId: value })
                }
                options={dataDropdownAccounts}
                placeholder={t(
                  `placeholder.${filtersIsLoading ? "loading" : "package"}`
                )}
                search={false}
                loading={accountsIsLoading}
                disabled={!clientId}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.filterName")}
              </FormLabel>
              <Input
                value={filterData.filterName}
                onChangeHandler={(e) =>
                  setFilterData({ ...filterData, filterName: e.target.value })
                }
                placeholder={t(
                  `placeholder.${filtersIsLoading ? "loading" : "filterName"}`
                )}
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.language")}</FormLabel>
              <Dropdown
                value={filterData.language}
                width="300px"
                callbackPayload={(value) =>
                  setFilterData({ ...filterData, language: value })
                }
                options={dataDropdownLanguage}
                placeholder={t(
                  `placeholder.${filtersIsLoading ? "loading" : "language"}`
                )}
                search={false}
                loading={accountsIsLoading}
              />
            </FormGroup>
          </FormInline>
        </FormDetails>
        <Content>
          <TitlePartOfForm aria-label="require">
            {t("label.searchFor")}
          </TitlePartOfForm>
          <ContainerFormCreateUser>
            <div className="form-group">
              <div className="container-element">
                <div>{t("label.noteComma")}</div>
                <div className="form-group">
                  <div className="container-element">
                    <span>{t("label.inclusive")}</span>
                    <Textarea
                      value={_.get(filterData, "exactPharse") || ""}
                      margin="10px 20px 10px 0"
                      onChangeHandler={(e) =>
                        setFilterData({
                          ...filterData,
                          exactPharse: e.target.value,
                        })
                      }
                      placeholder={t(
                        `placeholder.${
                          filtersIsLoading ? "loading" : "exactPhrase"
                        }`
                      )}
                      width="100%"
                      // rows={2}
                    />
                  </div>
                  <div className="container-element">
                    <span>{t("label.exclusive")}</span>
                    <Textarea
                      value={_.get(filterData, "exclude") || ""}
                      margin="10px 20px 10px 0"
                      onChangeHandler={(e) =>
                        setFilterData({
                          ...filterData,
                          exclude: e.target.value,
                        })
                      }
                      placeholder={t(
                        `placeholder.${
                          filtersIsLoading ? "loading" : "excludePhrase"
                        }`
                      )}
                      width="100%"
                      // rows={2}
                    />
                  </div>
                </div>
                {_renderAdvancedFilter()}
              </div>
              <PreviewRawContent>
                <code>{renderPreviewRaw()}</code>
              </PreviewRawContent>
            </div>
            <TitlePartOfForm>{t("label.mediaType")}</TitlePartOfForm>
            <FormDetails>
              <FormInline>
                <FormGroup>
                  <FormLabel aria-label="require">
                    {t("label.categories")}
                  </FormLabel>
                  <Dropdown
                    value={_.filter(filterData.category, (el) => el !== "all")}
                    width="300px"
                    callbackPayload={(value) => {
                      if (value.includes("all")) {
                        const allCate: any = _.compact(
                          _.map(dataDropdownCategories, (item) => {
                            if (item.value !== "all") return item.value;
                          })
                        );
                        setFilterData({
                          ...filterData,
                          category: allCate,
                        });
                      } else setFilterData({ ...filterData, category: value });
                    }}
                    options={dataDropdownCategories}
                    placeholder={t(
                      `placeholder.${
                        filtersIsLoading ? "loading" : "categories"
                      }`
                    )}
                    multiple
                    isUp
                    loading={categoriesIsLoading}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel aria-label="require">
                    {t("label.mediaTypes")}
                  </FormLabel>
                  <Dropdown
                    value={filterData.mediaType}
                    width="300px"
                    callbackPayload={(value) =>
                      setFilterData({ ...filterData, mediaType: value })
                    }
                    options={optionMediaType}
                    placeholder={t(
                      `placeholder.${
                        filtersIsLoading ? "loading" : "mediaType"
                      }`
                    )}
                    multiple
                    isUp
                  />
                </FormGroup>
              </FormInline>
              <FormGroup>
                <FormLabel aria-label="require">
                  {t("label.sourceName")}
                </FormLabel>
                <Dropdown
                  value={_.filter(filterData.source, (el) => el !== "all")}
                  width="300px"
                  callbackPayload={(value) => {
                    if (value.includes("all")) {
                      const allCate: any = _.compact(
                        _.map(dataDropdownSources, (item) => {
                          if (item.value !== "all") return item.value;
                        })
                      );
                      setFilterData({
                        ...filterData,
                        source: allCate,
                      });
                    } else setFilterData({ ...filterData, source: value });
                  }}
                  options={dataDropdownSources}
                  placeholder={t(
                    `placeholder.${filtersIsLoading ? "loading" : "sources"}`
                  )}
                  multiple
                  disabled={_.isEmpty(filterData.mediaType)}
                  isUp
                  loading={sourcesIsLoading}
                />
              </FormGroup>
            </FormDetails>

            <ButtonField margin="20px 0 50px 0">
              <Button onClick={() => onSubmit()} {...ButtonStyleProps}>
                {_.get(location, "state.id")
                  ? t("button.save")
                  : t("button.create")}
                {filtersIsLoading && (
                  <LoadingLayer>
                    <Spinner />
                  </LoadingLayer>
                )}
              </Button>
              <Button onClick={() => onTest()} {...ButtonStyleProps}>
                {t("button.test")}
              </Button>
              <Button onClick={() => onBack()} {...ButtonStyleProps}>
                {t("button.back")}
              </Button>
            </ButtonField>
          </ContainerFormCreateUser>
        </Content>
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.ADMIN_ROUTES.FILTERS}
      title={t("titlePage.filters")}
      subTitle={
        _.get(location, "state.id")
          ? t("titlePage.editFilter")
          : t("titlePage.createNewFilter")
      }
    />
  );
};

export default FilterDetails;
