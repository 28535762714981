/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { ComponentCalendar, ContainerDateTime } from "./Calendar.styles";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useOutsideClick } from "libs/Utils";
import dayjs from "dayjs";

interface SectionProps {
  margin?: string;
  width?: string;
  placeholder?: any;
  isRequired?: boolean;
  callbackPayload?: (payload?: any) => void;
  defaultValue?: string;
  disabled?: boolean;
  disabledPreviousDate?: boolean;
  minDate?: string;
  maxDate?: string;
  className?: string;
  leftPosition?: boolean;
}

const SelectDatetime: React.FC<SectionProps> = (props: SectionProps) => {
  const {
    margin,
    callbackPayload,
    placeholder,
    width,
    isRequired = false,
    defaultValue,
    disabled,
    disabledPreviousDate,
    minDate,
    maxDate,
    className,
    leftPosition = true,
  } = props;
  const [showCalendar, setShowCalendar] = useState(false);
  const [value, setValue] = useState(new Date());
  const [dateTimeLabel, setDateTimeLabel] = useState("");

  const formatDateComma = (date: any) => {
    return dayjs(date).format("YYYY-MM-DD");
  };

  useEffect(() => {
    if (placeholder) setDateTimeLabel(placeholder);
    else setDateTimeLabel(formatDateComma(new Date()));
  }, []);

  useEffect(() => {
    if (defaultValue) {
      setDateTimeLabel(dayjs(defaultValue).format("DD-MM-YYYY"));
      setValue(new Date(defaultValue));
    } else {
      setDateTimeLabel(placeholder);
      setValue(new Date());
    }
  }, [defaultValue]);

  useEffect(() => {
    if (placeholder && !defaultValue) setDateTimeLabel(placeholder);
  }, [placeholder]);

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => setShowCalendar(false));

  const onChangeDateTime = (data: any) => {
    setShowCalendar(false);
    if (callbackPayload) callbackPayload(formatDateComma(data));
  };

  const generateMinDate = () => {
    if (minDate) return new Date(minDate);
    if (disabledPreviousDate) return new Date();
    return new Date(2017, 0, 1);
  };

  const generateMaxDate = () => {
    const currentYear = new Date().getFullYear();
    if (maxDate) return new Date(maxDate);

    return new Date(currentYear + 2, 0, 1);
  };

  const renderMainDatetime = () => {
    return (
      <ContainerDateTime
        margin={margin}
        ref={ref}
        width={width}
        isRequired={isRequired}
        className={`${disabled ? "disabled" : ""} ${className}`}
      >
        <div
          className={`component-date ${showCalendar && "active"}`}
          onClick={() => {
            !disabled && setShowCalendar(!showCalendar);
          }}
        >
          <span>{dateTimeLabel}</span>
          <i className="fas fa-calendar" />
        </div>
        {showCalendar && (
          <ComponentCalendar isPositionLeft={leftPosition}>
            <Calendar
              onChange={(val: any) => onChangeDateTime(val)}
              value={value}
              nextLabel={<i className="fas fa-chevron-right" />}
              prevLabel={<i className="fas fa-chevron-left" />}
              minDate={generateMinDate()}
              maxDate={generateMaxDate()}
            />
          </ComponentCalendar>
        )}
      </ContainerDateTime>
    );
  };

  return renderMainDatetime();
};

export default SelectDatetime;
