/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  DefaultLayoutContainer,
  UserContainer,
  UserContent,
} from "./DefaultLayout.styles";
import { Loading } from "components/Common";
import Header from "../Layout/UserHeader";
import Footer from "../Layout/UserFooter";
import { getSavedUserData } from "../../../libs/Utils";
import _ from "lodash";
import history from "../../../history";
import RouteTypes from "../../../constants/RouteTypes";

interface SectionProps {
  content: JSX.Element;
  currentPage?: string;
  loading?: boolean;
}

const UserDefaultLayout: React.FC<SectionProps> = (props: SectionProps) => {
  const { content, loading, currentPage } = props;

  useEffect(() => {
    const userData = getSavedUserData();
    if (_.isEmpty(userData) && _.includes(RouteTypes.USER_ROUTES, currentPage))
      history.push(RouteTypes.USER_ROUTES.USER_ARTICLES);
      
  }, []);

  return (
    <>
      <DefaultLayoutContainer>
        {loading && <Loading />}
        <UserContainer>
          <Header />
          <UserContent>{content}</UserContent>
          <Footer />
        </UserContainer>
      </DefaultLayoutContainer>
    </>
  );
};

export default UserDefaultLayout;
