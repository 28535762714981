import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  Box,
  Grid,
  Typography,
  TextField,
  IconButton,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  Chip,
  FormLabel,
  RadioGroup,
  Radio,
  Button,
  Tab,
  Divider,
  MenuProps,
  Menu,
  alpha,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { saveAs } from "file-saver";
import { Packer } from "docx";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import PDFFile from "./ExportReportPDFFile";
import WordFile from "./ExportReportWordFile";
import ExcelFile from "./ExportReportToExcel";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import PreviewIcon from "@mui/icons-material/Preview";
import UndoIcon from "@mui/icons-material/Undo";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { RootState } from "reducers";
import {
  DataRecordsActions,
  FoldersActions,
  ReportActions,
  EmailTemplateActions,
} from "actions";
import {
  generateFilter,
  popupAlert,
  processDataDropDownFolders,
} from "libs/Utils";
import history from "../../../history";

import { Images } from "themes";
import RouteTypes from "constants/RouteTypes";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import { OrderLayer } from "./Reports.styles";
import {
  LoadingLayer,
  Spinner,
  FormGroup,
  Avatar,
  PostData,
} from "components/Common";
import { EmailTemplateDetails } from "components/Common/Popup";
import { CONSTANTS } from "constants/Constants";
import PostReport from "./PostReports";
import ColorName from "../../../constants/Variables";

const { getEmailTemplateByType, clearEmailTemplateDetails } =
  EmailTemplateActions;
const { fetchFolders } = FoldersActions;
const { searchDataRecord, clearDataRecords, getDataRecordForFolderById } =
  DataRecordsActions;
const { createReport, searchReports, clearReportDetails } = ReportActions;

interface ReportDetailsStructure {
  reportName: string;
  logo: string;
  orderBy: string;
  posts: string[];
  listData: string[];
  fromDate: string;
  toDate: string;
  isUseCoverSheet?: boolean;
  coverTitle?: string;
}

interface FilterStructure {
  dateFrom: string;
  dateTo: string;
  keywords: string;
  sortBy: string;
  orderBy: string;
  limit: number;
  page: number;
}

const DEFAULT_FILTER = {
  dateFrom: "",
  dateTo: "",
  keywords: "",
  sortBy: "mediaType",
  orderBy: "DESC",
  limit: 10,
  page: 1,
};

const DEFAULT_DETAILS = {
  reportName: "",
  logo: "",
  orderBy: "mediaType",
  posts: [],
  listData: [],
  fromDate: dayjs().format("YYYY-MM-DD"),
  toDate: dayjs().format("YYYY-MM-DD"),
  isUseCoverSheet: false,
  coverTitle: "",
};

const DEFAULT_LIST_OF_FIELDS = ["title", "publishedAt", "source", "link"];

interface DialogTitleProps {
  children?: React.ReactNode;
  onClose: () => void;
}

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const CreateReport: React.FC = () => {
  // Constructors
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const isFolderLoading = useSelector((state: RootState) => {
    return state.Folders.isLoading;
  });
  const foldersPayload = useSelector((state: RootState) => {
    return state.Folders.folders;
  });
  const dataRecords = useSelector((state: RootState) => {
    return state.DataRecord.dataRecords;
  });
  const dataRecord = useSelector((state: RootState) => {
    return state.DataRecord.dataRecord;
  });
  const reportFilter = useSelector((state: RootState) => {
    return state.Reports.report;
  });
  const reportFilterIsLoading = useSelector((state: RootState) => {
    return state.Reports.isLoading;
  });
  const emailTemplate = useSelector((state: RootState) =>
    _.get(state, "EmailTemplate.emailTemplate")
  );
  const emailTemplateIsLoading = useSelector((state: RootState) =>
    _.get(state, "EmailTemplate.isLoading")
  );

  const LIST_OF_FIELDS = [
    { label: t("label.headline"), value: "title" },
    { label: t("label.publishedDate"), value: "publishedAt" },
    { label: t("label.sourceName"), value: "source" },
    { label: t("label.webURL"), value: "link" },
  ];

  const LIST_POST_BY = [
    { label: t("label.mediaType"), value: "mediaType" },
    { label: t("label.publishedDate"), value: "publishedAt" },
    { label: t("label.newestPostsFirst"), value: "newestPosts" },
    { label: t("label.oldestPostsFirst"), value: "oldestPosts" },
  ];

  // const EXPORT_BY = [
  //   { label: "Word", value: "word" },
  //   { label: "PDF", value: "pdf" },
  //   { label: "XLS", value: "xls" },
  // ];

  const [isShowCreateTemplate, setIsShowCreateTemplate] =
    useState<boolean>(false);
  const [urlPreview, setUrlPreview] = useState("");
  const [isUseSelectedFolders, setIsUseSelectedFolders] =
    useState<boolean>(false);
  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const [reportDetails, setReportDetails] =
    useState<ReportDetailsStructure>(DEFAULT_DETAILS);
  // const [exportBy, setExportBy] = useState<string>("word");
  const [isShowCheckedFirst, setIsShowCheckedFirst] = useState<boolean>(false);
  const [checkedPost, setCheckedPost] = useState<string[]>([]);
  const [total, setTotal] = useState<any>({});
  const [selectedFolders, setSelectedFolders] = useState<string[]>([]);
  const [listOfFields, setListOfFields] = useState<string[]>(
    DEFAULT_LIST_OF_FIELDS
  );
  // const [titleCover, setTitleCover] = useState<string>("");
  const [isOpenArticles, setIsOpenArticles] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("selected");
  const [btnEl, setBtnEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    dispatch(fetchFolders({ page: 1, limit: 0, status: "active" }));
    dispatch(getEmailTemplateByType(CONSTANTS.EMAIL_TYPE.EMAIL_REPORT));
    dispatch(searchDataRecord(generateFilter(DEFAULT_FILTER)));
    return () => {
      dispatch(clearReportDetails());
      dispatch(clearEmailTemplateDetails());
      dispatch(clearDataRecords());
    };
  }, []);

  useEffect(() => {
    if (checkedPost) {
      let web = 0;
      let tv = 0;
      let print = 0;
      let social = 0;
      let all = 0;
      _.forEach(checkedPost, (item) => {
        const mediaType = _.get(item, "source.mediaType");
        if (mediaType === "web") web += 1;
        if (mediaType === "tv") tv += 1;
        if (mediaType === "print") print += 1;
        if (mediaType === "social network") social += 1;
        all += 1;
      });
      setTotal({
        web,
        tv,
        print,
        social,
        all,
      });
    }
  }, [checkedPost]);

  useEffect(() => {
    if (!isUseSelectedFolders) setSelectedFolders([]);
  }, [isUseSelectedFolders]);

  const onChangeListOfFied = (value: string) => {
    const currentFields = [...listOfFields];
    const isIncludes = _.includes(currentFields, value);
    if (isIncludes) {
      const removeValue = _.filter(currentFields, (field) => field !== value);
      setListOfFields(removeValue);
    } else setListOfFields([...currentFields, value]);
  };

  const onChangeCheckedPost = (
    newCheckedPost: string[],
    isSelected?: boolean
  ) => {
    const resolveCheckedPost: any[] = [...checkedPost];
    if (isSelected) {
      const oldCheckedPost = [...checkedPost];
      _.forEach(oldCheckedPost, (item) => {
        const id = _.get(item, "id");
        if (_.includes(newCheckedPost, id)) resolveCheckedPost.push(item);
      });
    } else {
      _.forEach(_.get(dataRecords, "items"), (item) => {
        if (_.includes(newCheckedPost, _.get(item, "id")))
          resolveCheckedPost.push(item);
      });
      _.forEach(_.get(dataRecord, "items"), (item) => {
        if (_.includes(newCheckedPost, _.get(item, "dataRecordId.id"))) {
          const { dataRecordId } = item;
          resolveCheckedPost.push(dataRecordId);
        }
      });
    }
    const uniqDataRecord = _.uniqBy(resolveCheckedPost, "id");
    setCheckedPost(uniqDataRecord);
  };

  const onCheckedPost = (value: string, isSelected?: boolean) => {
    const listData = _.get(reportDetails, "listData");
    const includes = _.includes(listData, value);

    if (includes) {
      const removeCheckedValue = _.filter(listData, (post) => post !== value);
      onChangeCheckedPost(removeCheckedValue, isSelected);
      setReportDetails({
        ...reportDetails,
        listData: removeCheckedValue,
      });
    } else {
      onChangeCheckedPost([...listData, value], isSelected);
      setReportDetails({
        ...reportDetails,
        listData: [...listData, value],
      });
    }
  };

  const setLogo = (e: any) => {
    if (e.target.files[0]) {
      const url = URL.createObjectURL(e.target.files[0]);
      setReportDetails({ ...reportDetails, logo: e.target.files[0] });
      setUrlPreview(url);
    }
  };

  const validateData = () => {
    let flag = true;
    let message = `${t("alert.foundEmptyFields")}`;
    const data = [
      {
        label: t("label.reportName"),
        value: _.get(reportDetails, "reportName"),
      },
    ];
    _.map(data, (item) => {
      const { value, label } = item;
      if (_.isEmpty(_.trim(value))) {
        message += `${label}\n`;
        flag = false;
      }
    });
    return { flag, message };
  };

  const onCreate = async () => {
    const { flag, message } = validateData();

    if (!flag) {
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });
    }
    const formData = new FormData();
    formData.append("reportName", reportDetails.reportName);
    formData.append("fromDate", reportDetails.fromDate);
    formData.append("toDate", reportDetails.toDate);
    if (reportDetails.isUseCoverSheet)
      formData.append(
        "isUseCoverSheet",
        reportDetails.isUseCoverSheet.toString()
      );
    if (reportDetails.coverTitle)
      formData.append("coverTitle", reportDetails.coverTitle);
    if (isUseSelectedFolders && !_.isEmpty(selectedFolders))
      _.map(selectedFolders, (folder) => {
        if (folder) formData.append("folders[]", folder);
      });

    if (listOfFields)
      _.map(listOfFields, (field) => {
        if (field) formData.append("listField[]", field);
      });

    formData.append("orderBy", filter.orderBy);
    if (!_.isEmpty(reportDetails.listData))
      _.map(reportDetails.listData, (post) => {
        if (post) formData.append("listData[]", post);
      });
    else
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: t("alert.atLeastOneDataRecord"),
      });
    if (reportDetails.logo) formData.append("logo", reportDetails.logo || "");

    dispatch(createReport(formData));
    dispatch(searchReports({ fetch: "all" }));
  };

  const onClear = async () => {
    const isAgree = await popupAlert({
      title: t("alert.warning"),
      type: "warning",
      text: t("alert.warningBeforeClearAll"),
      buttons: ["No", true],
    });
    if (!isAgree) return;
    dispatch(clearReportDetails());
    dispatch(clearDataRecords());
    setReportDetails(DEFAULT_DETAILS);
    setFilter(DEFAULT_FILTER);
    setUrlPreview("");
    setIsShowCheckedFirst(false);
    setIsUseSelectedFolders(false);
    setCheckedPost([]);
  };

  const orderByPostData = () => {
    const dataRecordPayload =
      selectedTab === "filtered"
        ? _.get(dataRecords, "items")
        : _.get(dataRecord, "items");
    if (
      dataRecordPayload &&
      _.isArray(dataRecordPayload) &&
      isShowCheckedFirst
    ) {
      const listData = _.get(reportDetails, "listData");
      const checkedData = _.filter(dataRecords.items, (item) =>
        _.includes(listData, _.get(item, "id"))
      );
      const cloneDeepData = _.cloneDeep(dataRecordPayload);
      const reOrderData = _.sortBy(cloneDeepData, (a, b) => {
        const createdAtOfA: any = _.get(a, "createdAt");
        const createdAtOfB: any = _.get(b, "createdAt");
        const dateA: any = new Date(createdAtOfA);
        const dateB: any = new Date(createdAtOfB);
        return dateA - dateB;
      });
      return _.uniqBy([...checkedData, ...reOrderData], "id");
    }
    return dataRecordPayload;
  };

  const onCloseTemplate = () => {
    setIsShowCreateTemplate(false);
    dispatch(getEmailTemplateByType(CONSTANTS.EMAIL_TYPE.EMAIL_REPORT));
  };

  const onChangeOrderBy = (newOrder: string) => {
    let resolveOrder = {
      orderBy: "DESC",
      sortBy: newOrder,
    };
    if (newOrder === "newestPosts")
      _.assign(resolveOrder, { sortBy: "createdAt" });
    if (newOrder === "oldestPosts")
      _.assign(resolveOrder, { sortBy: "createdAt", orderBy: "ASC" });

    const newFilter = {
      ...filter,
      ...resolveOrder,
    };

    setFilter(newFilter);
    setReportDetails({
      ...reportDetails,
      orderBy: newOrder,
    });
    dispatch(searchDataRecord(generateFilter(newFilter)));
  };

  const onClickFilters = () => {
    setSelectedTab("filtered");
    setFilter({ ...filter, page: 1 });
    dispatch(searchDataRecord(generateFilter(filter)));
  };

  const onClickFolders = () => {
    if (_.isEmpty(selectedFolders))
      return popupAlert({
        type: "warning",
        title: t("alert.warning"),
        text: t("alert.warningSelectUseFolders"),
      });

    setSelectedTab("folder");
    setFilter({ ...filter, page: 1 });
    dispatch(
      getDataRecordForFolderById({
        folderId: selectedFolders,
        keyword: _.get(filter, "keyword"),
      })
    );
  };

  const onSelectTab = (value: string) => {
    switch (value) {
      case "filtered": {
        onClickFilters();
        break;
      }
      case "folder": {
        onClickFolders();
        break;
      }
      default: {
        setSelectedTab("selected");
      }
    }
  };

  const onExport = async (action: string) => {
    if (_.isEmpty(checkedPost))
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: t("alert.atLeastOnePost"),
      });

    const reportName = _.lowerCase(_.get(reportDetails, "reportName"));
    const reportDate = dayjs().format("DD_MM_YYYY");
    const fileName = `${reportName}_${reportDate}`;
    const resolveReportName = _.replace(fileName, new RegExp(" ", "g"), "_");
    if (action === "word") {
      const doc = WordFile({
        data: checkedPost,
        total,
        logo: _.get(reportDetails, "logo"),
        title: _.get(reportDetails, "coverTitle") || "",
      });

      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, `${resolveReportName}.docx`);
      });
    }
    if (action === "pdf") {
      const pdfFile: any = await PDFFile({
        data: checkedPost,
        total,
        logo: _.get(reportDetails, "logo"),
        title: _.get(reportDetails, "coverTitle") || "",
      });
      saveAs(pdfFile, `${resolveReportName}.pdf`);
    }
    if (action === "xls") {
      const excelFile: any = ExcelFile({ data: checkedPost, total });
      saveAs(excelFile, `${resolveReportName}.xlsx`);
    }
  };

  const _renderFolders = useMemo(() => {
    const options = processDataDropDownFolders(foldersPayload?.items || []);
    return (
      <FormControl fullWidth>
        <InputLabel id="select-folder">Folders</InputLabel>
        <Select
          labelId="select-folder"
          value={selectedFolders}
          label="Folders"
          multiple
          disabled={!isUseSelectedFolders}
          onChange={(e) => {
            if (_.isArray(e.target.value)) setSelectedFolders(e.target.value);
          }}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
              }}
            >
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={_.find(options, ["value", value])?.label}
                />
              ))}
            </Box>
          )}
        >
          {_.map(options, (option) => (
            <MenuItem value={option.value} key={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }, [foldersPayload, selectedFolders, isUseSelectedFolders]);

  const _renderFilter = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography sx={{ color: ColorName.burntSienna, fontWeight: "bold" }}>
          Filter Articles
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DatePicker
              label="Date From"
              format="DD/MM/YYYY"
              value={filter.dateFrom ? dayjs(filter.dateFrom) : null}
              onChange={(e: Dayjs | null) => {
                setFilter({
                  ...filter,
                  dateFrom: dayjs(e).format("YYYY-MM-DD"),
                });
                setReportDetails({
                  ...reportDetails,
                  fromDate: dayjs(e).format("YYYY-MM-DD"),
                });
                if (selectedTab === "filtered") {
                  dispatch(
                    searchDataRecord(
                      generateFilter({
                        ...filter,
                        dateFrom: dayjs(e).format("YYYY-MM-DD"),
                        page: 1,
                      })
                    )
                  );
                }
              }}
              sx={{ width: 1 }}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label="Date To"
              format="DD/MM/YYYY"
              value={filter.dateTo ? dayjs(filter.dateTo) : null}
              onChange={(e: Dayjs | null) => {
                setFilter({
                  ...filter,
                  dateTo: dayjs(e).format("YYYY-MM-DD"),
                });
                setReportDetails({
                  ...reportDetails,
                  toDate: dayjs(e).format("YYYY-MM-DD"),
                });
                if (selectedTab === "filtered") {
                  dispatch(
                    searchDataRecord(
                      generateFilter(
                        _.omit({
                          ...filter,
                          dateTo: dayjs(e).format("YYYY-MM-DD"),
                          page: 1,
                        })
                      )
                    )
                  );
                }
              }}
              sx={{ width: 1 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) =>
                setFilter({ ...filter, keywords: e.target.value })
              }
              fullWidth
              value={_.get(filter, "keywords") || ""}
              label={t("placeholder.keyword")}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const _renderUseFolders = () => (
    <Grid container>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox checked={isUseSelectedFolders} />}
          label={
            <Typography
              sx={{
                color: ColorName.burntSienna,
                fontWeight: "bold",
              }}
            >
              Use Folders
            </Typography>
          }
          onChange={() => setIsUseSelectedFolders(!isUseSelectedFolders)}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            {_renderFolders}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const _renderBasic = () => (
    <Grid item xs={12}>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ color: ColorName.burntSienna, fontWeight: "bold" }}>
            Report Basic
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={(e) =>
              setReportDetails({
                ...reportDetails,
                reportName: e.target.value,
              })
            }
            fullWidth
            value={_.get(reportDetails, "reportName") || ""}
            label={t("placeholder.reportName")}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {_renderFilter()}
            </Grid>
            <Grid item xs={6}>
              {_renderUseFolders()}
            </Grid>
            {/* <Grid item xs={12}>
              <Button
                onClick={() => setIsOpenArticles(true)}
                fullWidth
                variant="contained"
                startIcon={<PreviewIcon />}
              >
                Selected Articles ({checkedPost.length})
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const _renderListOrderBy = useMemo(
    () => (
      <FormControl>
        <FormLabel id="listPostBy">{t("label.listPostsBy")}</FormLabel>
        <RadioGroup
          aria-labelledby="listPostBy"
          value={reportDetails.orderBy}
          onChange={(e) => onChangeOrderBy(e.target.value)}
        >
          {_.map(LIST_POST_BY, (field, index) => {
            const { value, label } = field;
            return (
              <FormControlLabel
                key={`listPostBy-${value}-${index}`}
                value={value}
                label={label}
                control={<Radio />}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    ),
    [filter, reportDetails]
  );

  // const _renderExportBy = useMemo(
  //   () => (
  //     <FormControl>
  //       <FormLabel id="exportBy">{t("label.exportBy")}</FormLabel>
  //       <RadioGroup
  //         aria-labelledby="exportBy"
  //         value={exportBy}
  //         onChange={(e) => setExportBy(e.target.value)}
  //         row
  //       >
  //         {_.map(EXPORT_BY, (field, index) => {
  //           const { value, label } = field;
  //           return (
  //             <FormControlLabel
  //               key={`exportBy-${value}-${index}`}
  //               value={value}
  //               label={label}
  //               control={<Radio />}
  //             />
  //           );
  //         })}
  //       </RadioGroup>
  //     </FormControl>
  //   ),
  //   [exportBy]
  // );

  const _renderListOfFields = useMemo(
    () => (
      <>
        <FormLabel>{t("label.listOfFields")}</FormLabel>
        <FormGroup>
          {_.map(LIST_OF_FIELDS, (field, index) => {
            const { value, label } = field;
            const checked = _.includes(listOfFields, value);
            return (
              <FormControlLabel
                key={`listOfFields-${value}-${index}`}
                control={<Checkbox checked={checked} />}
                label={label}
                onChange={() => onChangeListOfFied(value)}
              />
            );
          })}
        </FormGroup>
      </>
    ),
    [listOfFields]
  );

  const _renderDesign = () => (
    <Grid item xs={12}>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ color: ColorName.burntSienna, fontWeight: "bold" }}>
            Report Design
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Grid container justifyContent="center">
                <Avatar
                  src={urlPreview || Images.defaultLogo}
                  onChange={(e: any) => setLogo(e)}
                />
                <Typography
                  sx={{ color: ColorName.william, fontSize: 12, mt: 0.5 }}
                >
                  {t("label.demensionImage")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    value={_.get(reportDetails, "coverTitle")}
                    onChange={(e) =>
                      setReportDetails({
                        ...reportDetails,
                        coverTitle: e.target.value,
                      })
                    }
                    fullWidth
                    disabled={!_.get(reportDetails, "isUseCoverSheet")}
                    label={t("placeholder.title")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={_.get(reportDetails, "isUseCoverSheet")}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: 12 }}>
                        Use a Cover Sheet for this report
                      </Typography>
                    }
                    onChange={(e) =>
                      setReportDetails({
                        ...reportDetails,
                        isUseCoverSheet: !_.get(
                          reportDetails,
                          "isUseCoverSheet"
                        ),
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const _renderStoriesDisplay = () => (
    <Grid item xs={12}>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ color: ColorName.burntSienna, fontWeight: "bold" }}>
            Stories display
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              {_renderListOfFields}
            </Grid>
            <Grid item xs={4}>
              {_renderListOrderBy}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const _renderButtons = () => (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item sx={{ mr: "auto" }}>
          <Button
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={() =>
              history.push({ pathname: RouteTypes.ADMIN_ROUTES.REPORT })
            }
            color="error"
          >
            {t("button.back")}
          </Button>
        </Grid>
        {/* <Grid item>
          <Button
            variant="contained"
            startIcon={<FileDownloadIcon />}
            onClick={() => onExport()}
            color="success"
          >
            {t("button.export")}
          </Button>
        </Grid> */}
        <Grid item>
          <Button
            variant="contained"
            color="success"
            startIcon={<FileDownloadIcon />}
            disableElevation
            id="expand-btn-menu"
            aria-controls={Boolean(btnEl) ? "customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={Boolean(btnEl) ? "true" : undefined}
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(e) => setBtnEl(e.currentTarget)}
          >
            {t("button.export")}
          </Button>
          <StyledMenu
            id="customized-menu"
            MenuListProps={{
              "aria-labelledby": "expand-btn-menu",
            }}
            anchorEl={btnEl}
            open={Boolean(btnEl)}
            onClose={() => setBtnEl(null)}
          >
            <MenuItem onClick={() => onExport("pdf")} disableRipple>
              PDF
            </MenuItem>
            <MenuItem onClick={() => onExport("xls")} disableRipple>
              EXCEL
            </MenuItem>
            <MenuItem onClick={() => onExport("word")} disableRipple>
              WORD
            </MenuItem>
          </StyledMenu>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => onCreate()}
            color="success"
          >
            {t("button.create")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<UndoIcon />}
            onClick={() => onClear()}
            color="info"
          >
            {t("button.clearAll")}
          </Button>
        </Grid>
      </Grid>
      ;
    </Grid>
  );

  const _renderDialog = () => (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={isOpenArticles}
      fullScreen
    >
      <BootstrapDialogTitle
        onClose={() => {
          setIsOpenArticles(false);
          setSelectedTab("selected");
        }}
      >
        Articles
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Box sx={{ minWidth: 1 }}>
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={(_event, value) => onSelectTab(value)}>
                <Tab label="Selected Articles" value="selected" />
                <Tab label="Filtered Articles" value="filtered" />
                <Tab label="Articles in Folders" value="folder" />
              </TabList>
            </Box>
            <TabPanel value="selected">
              <PostData
                checked={_.get(reportDetails, "listData")}
                data={checkedPost}
                onCheck={(value: string) => onCheckedPost(value, true)}
                isHidePostOptions
              />
            </TabPanel>
            <TabPanel value="filtered">
              <PostReport
                checked={_.get(reportDetails, "listData")}
                data={orderByPostData()}
                onCheck={(value: string) => onCheckedPost(value)}
                meta={_.get(dataRecords, "meta")}
                onChangePage={(page: number) => {
                  dispatch(
                    searchDataRecord(generateFilter({ ...filter, page }))
                  );
                }}
              />
            </TabPanel>
            <TabPanel value="folder">
              <PostReport
                checked={_.get(reportDetails, "listData")}
                data={orderByPostData()}
                onCheck={(value: string) => onCheckedPost(value)}
                meta={_.get(dataRecord, "meta")}
                onChangePage={(page: number) => {
                  dispatch(
                    getDataRecordForFolderById({
                      folderId: selectedFolders,
                      page,
                      keyword: _.get(filter, "keyword"),
                    })
                  );
                }}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );

  const _renderArticles = () => (
    <Grid item xs={12}>
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={(_event, value) => onSelectTab(value)}>
            <Tab label="Filtered Articles" value="filtered" />
            <Tab label="Selected Articles" value="selected" />
            <Tab label="Articles in Folders" value="folder" />
          </TabList>
        </Box>
        <TabPanel value="selected">
          <PostData
            checked={_.get(reportDetails, "listData")}
            data={checkedPost}
            onCheck={(value: string) => onCheckedPost(value, true)}
            isHidePostOptions
          />
        </TabPanel>
        <TabPanel value="filtered">
          <PostReport
            checked={_.get(reportDetails, "listData")}
            data={orderByPostData()}
            onCheck={(value: string) => onCheckedPost(value)}
            meta={_.get(dataRecords, "meta")}
            onChangePage={(page: number) => {
              dispatch(
                searchDataRecord(
                  generateFilter(
                    _.omit({ ...filter, page }, ["orderBy", "sortBy"])
                  )
                )
              );
            }}
          />
        </TabPanel>
        <TabPanel value="folder">
          <PostReport
            checked={_.get(reportDetails, "listData")}
            data={orderByPostData()}
            onCheck={(value: string) => onCheckedPost(value)}
            meta={_.get(dataRecord, "meta")}
            onChangePage={(page: number) => {
              dispatch(
                getDataRecordForFolderById({
                  folderId: selectedFolders,
                  page,
                  keyword: _.get(filter, "keyword"),
                })
              );
            }}
          />
        </TabPanel>
      </TabContext>
    </Grid>
  );

  const renderMain = () => {
    return (
      <Grid container rowSpacing={2} sx={{ position: "relative" }}>
        {/* {_renderDialog()} */}
        {!_.has(emailTemplate, "id") && (
          <OrderLayer>
            <p>{t("description.emptyReportTemplate")}</p>
            <Button
              sx={{ width: 150 }}
              onClick={() => setIsShowCreateTemplate(true)}
            >
              {t("button.createTemplate")}
            </Button>
          </OrderLayer>
        )}
        {isShowCreateTemplate && (
          <EmailTemplateDetails onClose={() => onCloseTemplate()} />
        )}
        {(emailTemplateIsLoading || reportFilterIsLoading) && (
          <LoadingLayer>
            <Spinner />
          </LoadingLayer>
        )}
        {_renderBasic()}
        <Grid item xs={12}>
          <Divider variant="middle" sx={{ width: 1 }} />
        </Grid>
        {_renderDesign()}
        <Grid item xs={12}>
          <Divider variant="middle" sx={{ width: 1 }} />
        </Grid>
        {_renderStoriesDisplay()}
        <Grid item xs={12}>
          <Divider variant="middle" sx={{ width: 1 }} />
        </Grid>
        {_renderArticles()}
        <Grid item xs={12}>
          <Divider variant="middle" sx={{ width: 1 }} />
        </Grid>
        {_renderButtons()}
      </Grid>
    );
  };

  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.ADMIN_ROUTES.CREATE_REPORT}
      title={t("titlePage.reports")}
      subTitle={t("titlePage.createNewReport")}
    />
  );
};
export default CreateReport;
