/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { RootState } from "reducers";
import { FileRecordActions } from "actions";

import ColorName from "constants/Variables";
import { CONSTANTS, ROUTES } from "constants/Constants";

import TableUltils from "libs/TableUltils";
import history from "../../../history";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Input,
  Filter,
  Button,
  Table,
  SelectDatetime,
  ContainerSearch,
  FlexWrapRow,
} from "components/Common";
import { useTranslation } from "react-i18next";
import { generateFilter } from "libs/Utils";

const { searchFileRecord, clearFileRecords } = FileRecordActions;

interface FilterStructure {
  status: string;
  page: number;
  limit: number;
  dateFrom: string;
  dateTo: string;
  keyword: string;
}

const DEFAULT_FILTER = {
  page: 1,
  limit: 10,
  status: "",
  dateFrom: "",
  dateTo: "",
  keyword: "",
};

const STATUS = [
  {
    label: "allData",
    value: "",
  },
  {
    label: "fileRecordProcessed",
    value: CONSTANTS.STATUS.ACTIVE,
  },
  {
    label: "notProcessing",
    value: CONSTANTS.STATUS.INACTIVE,
  },
];

const InputStyleProps = {
  margin: "10px 10px 10px 0",
  width: "200px",
};

const ButtonStyleProps = {
  buttonMargin: "10px 10px 10px 0",
};

const FileRecord = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const fileRecordsIsLoading = useSelector((state: RootState) => {
    return state.FileRecord.isLoading;
  });
  const fileRecords = useSelector((state: RootState) => {
    return state.FileRecord.sources;
  });
  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataFileRecords, setDataFileRecords] = useState<any>([]);

  useEffect(() => {
    dispatch(searchFileRecord(generateFilter(filter)));
    return () => {
      dispatch(clearFileRecords());
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(fileRecords)) {
      const getDataFileRecords: any[] = _.get(fileRecords, "items");
      if (currentPage > 1) {
        setDataFileRecords([...dataFileRecords, ...getDataFileRecords]);
      } else setDataFileRecords(getDataFileRecords);
    }
  }, [fileRecords]);

  const dataHead = [
    "no",
    "name",
    "code",
    "uploadBy",
    "status",
    "retry",
    "uploadedAt",
  ];
  const dataTable = TableUltils._processDataTable(
    ["no", "id", "name", "sourceCode", "link", "status", "retry", "createdAt"],
    dataFileRecords
  );

  const listColumnCenter = [5, 6, 7];

  const callbackCurrentPageLink = (page: number) => {
    const newFilter: FilterStructure = { ...filter, page };
    setFilter(newFilter);
    dispatch(searchFileRecord(generateFilter(newFilter)));
  };

  const onSubmitFilter = (page: number) => {
    const newFilter = {
      ...filter,
      page: page,
    };
    setFilter(newFilter);
    dispatch(searchFileRecord(generateFilter(newFilter)));
    setCurrentPage(page);
  };

  const onResetFilter = () => {
    setCurrentPage(1);
    setFilter(DEFAULT_FILTER);
    dispatch(searchFileRecord(generateFilter(DEFAULT_FILTER)));
  };

  const onChangeStatus = (status: string) => {
    const newFilter: FilterStructure = {
      ...filter,
      status,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(searchFileRecord(generateFilter(newFilter)));
  };

  const onFetchMore = () => {
    if (
      !fileRecordsIsLoading &&
      currentPage < _.get(fileRecords, "meta.totalPages")
    )
      onSubmitFilter(filter.page + 1);
  };

  const showMore = () => {
    if (
      !fileRecordsIsLoading &&
      currentPage < _.get(fileRecords, "meta.totalPages")
    )
      onSubmitFilter(filter.page + 1);
  };

  //   const onFetchMore = () => {
  //   if (
  //     !categoriesIsLoading &&
  //     currentPage < _.get(categories, "meta.totalPages")
  //   )
  //     getSearchCategories(filter.page + 1);
  // };

  // Filter by process status of file record
  const renderFilterByStatus = () => {
    return (
      <ContainerSearch className={fileRecordsIsLoading ? "loading" : ""}>
        <FlexWrapRow>
          {_.map(STATUS, (item, index) => {
            const value = _.get(item, "value");
            const label = _.get(item, "label");
            return (
              <Filter
                key={`filter-${index}`}
                className={value === _.get(filter, "status") ? "active" : ""}
                onChangeHandler={() => onChangeStatus(value)}
                text={t(`button.${label}`)}
                width="fit-content"
                margin="10px 10px 10px 0"
              />
            );
          })}
        </FlexWrapRow>
      </ContainerSearch>
    );
  };

  // Filter by time uploaded and keyword of file record
  const renderDefaultFilter = () => {
    return (
      <ContainerSearch className={fileRecordsIsLoading ? "loading" : ""}>
        <FlexWrapRow>
          <SelectDatetime
            placeholder={t("placeholder.dateFrom")}
            defaultValue={_.get(filter, "dateFrom")}
            callbackPayload={(e) => setFilter({ ...filter, dateFrom: e })}
            {...InputStyleProps}
          />
          <SelectDatetime
            placeholder={t("placeholder.dateTo")}
            defaultValue={_.get(filter, "dateTo")}
            callbackPayload={(e) => setFilter({ ...filter, dateTo: e })}
            {...InputStyleProps}
          />
          <Input
            value={_.get(filter, "keyword")}
            onChangeHandler={(e) =>
              setFilter({ ...filter, keyword: e.target.value })
            }
            onEnter={() => onSubmitFilter(1)}
            placeholder={t("placeholder.searchByNameOrLink")}
            {...InputStyleProps}
          />
          <Button onClick={() => onSubmitFilter(1)} {...ButtonStyleProps}>
            {t("button.search")}
          </Button>
          <Button onClick={() => onResetFilter()} {...ButtonStyleProps}>
            {t("button.reset")}
          </Button>
          <Button
            onClick={() => history.push(ROUTES.ADD_CSV)}
            background={ColorName.william}
            {...ButtonStyleProps}
          >
            + {t("button.addCSV")}
          </Button>
        </FlexWrapRow>
      </ContainerSearch>
    );
  };

  // Render table
  const _renderTable = () => {
    return (
      <Table
        dataHeader={dataHead}
        dataTable={dataTable}
        listActionItem={[CONSTANTS.ACTION.VIEW]}
        fitColumns={[1, 7]}
        listColumnCenter={listColumnCenter}
        hasPagination={true}
        callbackCurrentPageLink={callbackCurrentPageLink}
        metaPagination={fileRecords?.meta}
        loading={fileRecordsIsLoading}
        showMore={showMore}
      />
    );
  };

  const renderMain = () => {
    return (
      <>
        {renderFilterByStatus()}
        {renderDefaultFilter()}
        {_renderTable()}
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={ROUTES.FILE_RECORD}
      parent={ROUTES.MANAGE_RECORD}
      title={t("titlePage.fileRecords")}
      onScrollBottom={() => onFetchMore()}
    />
  );
};

export default FileRecord;
