import styled from "styled-components";
import ColorName from "constants/Variables";

interface SectionProps {
  width?: string;
  maxHeight?: string;
}

const ContainerSearch = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: 1140px;
  flex-wrap: wrap;
`;

const ContainerInformation = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  overflow: auto;
  position: relative;
  min-height: 100px;
  transition: all ease 0.25s;

  /* &.post-container {
    min-height: 0px;
    max-height: 0px;
  }

  &.active {
    max-height: 1200px;
  } */
`;

const LabelSelected = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;

  span {
    font-weight: bold;
    display: inline-block;
    width: 40px;
    margin-left: 5px;
  }

  i {
    margin-right: 10px;
  }
`;

const DataPostContainer = styled.div`
  max-width: 1140px;
  overflow: hidden;
`;

const LogsEmptyData = styled.div`
  margin-bottom: 15px;
`;

const DataPostItem = styled.div`
  position: relative;
  padding: 25px 10px;
  box-sizing: border-box;
  &:nth-child(even) {
    background: ${ColorName.linkWater};
  }

  span {
    color: ${ColorName.william};
    margin-right: 10px;
  }
`;

const PreviewContent = styled.div`
  margin: 15px 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const PostMenu = styled.div`
  position: absolute;
  right: 0px;
  top: 100%;
  width: 180px;
  height: 0px;
  background: transparent;
  border: 1px solid transparent;
  overflow: hidden;
`;

const PostMenuItem = styled.div`
  padding: 8px 20px;
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: ${ColorName.linkWater};
  }
`;

const MenuButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  display: flex;
  justify-content: center;
  i {
    color: ${ColorName.william};
  }
  &:hover {
    cursor: pointer;
    ${PostMenu} {
      background: #fff;
      height: auto;
      border: 1px solid #cadeed;
    }
  }
`;

const FitlerButton = styled.div`
  margin: 10px 20px 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${ColorName.william};
  color: ${ColorName.william};
  border-radius: 3px;
  &.enable:hover {
    cursor: pointer;
    color: ${ColorName.white};
    border: 1px solid ${ColorName.white};
    background: ${ColorName.william};
  }

  &.disable {
    opacity: 0.6;
    &:hover {
      cursor: not-allowed;
    }
  }
`;

const TemplateDetailsContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.25);
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  & .justify-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & .justify-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .justify-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  & span {
    &.rct-node-icon {
      color: ${ColorName.william};
    }
  }
`;

const TemplateDetailsHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${ColorName.william};
  color: white;
  font-size: 15px;
  height: 35px;
  position: relative;
`;

const TemplateDetailsContent = styled.div`
  width: ${(props: SectionProps) => props.width || "70vw"};
  /* height: 60vh; */
  max-width: 1120px;
  /* max-height: 400px; */
  background: white;
`;

const TemplateDetailsForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  height: 100%;
  max-height: ${(props: SectionProps) => props.maxHeight || "270px"};
  justify-content: center;
  overflow: auto;

  &.add-post {
    height: 100%;
    max-height: 200px;
    overflow: auto;
  }

  &.flex-start {
    justify-content: flex-start;
  }

  & .form-group {
    color: ${ColorName.william};
    align-items: center;
    font-size: 16px;
    margin: 10px 20px;
    flex: 1;
    & .empty {
      color: ${ColorName.amaranth};
      font-size: 12px;
      height: 17px;
    }
    > span {
      display: inline-block;
      min-height: 1px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &.fields {
      align-items: flex-start;
      margin: 10px 0;
    }
  }
`;

const ButtonField = styled.div`
  display: flex;
  background: ${ColorName.white};
  justify-content: flex-end;
  padding: 10px 15px 10px 0;

  &.mt-2 {
    margin-top: 20px;
  }
`;

const DatatPostContent = styled.div`
  &.blur {
    opacity: 0.4;
  }
`;

const FilterMediaTypeRow = styled.div`
  display: flex;
  align-items: center;
`;

const FilterMediaTypeButton = styled.div`
  margin-right: 5px;
  padding: 5px;
  border: 1px solid transparent;
  height: max-content;
  border-radius: 5px;
  user-select: none;

  i {
    margin-right: 5px;
  }

  &.active,
  &:hover {
    border-color: ${ColorName.william};
    color: ${ColorName.william};
    cursor: pointer;
  }
`;

const RowItem = styled.div`
  display: flex;
`;

const ColItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  &.mb-1 {
    margin-bottom: 1rem;
  }

  & .label {
    width: 70px;
    display: inline-block;
  }
`;

const AdvancedFilterLabel = styled.div`
  width: max-content;
  color: ${ColorName.william};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &.disable {
    opacity: 0.6;
    &:hover {
      cursor: not-allowed;
      text-decoration: none;
    }
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  transition: all ease 0.25s;
  &:hover {
    cursor: pointer;
    color: ${ColorName.burntSienna};
  }
`;

const ContentInformation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & .action-btn {
    display: inline-flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: ${ColorName.linkWater};
    color: ${ColorName.william};
    margin-right: 5px;
    margin-top: 5px;

    &:hover {
      cursor: pointer;
      background: ${ColorName.william};
      color: ${ColorName.linkWater};
    }

    &.disabled {
      opacity: 0.7;
      cursor: not-allowed;

      &:hover {
        background: ${ColorName.linkWater};
        color: ${ColorName.william};
      }
    }
  }
`;

const TitleContent = styled.h2`
  text-align: left;
`;

const DescriptionContent = styled.p`
  white-space: pre-wrap;
`;

const QuantityLabel = styled.div`
  margin: 10px 15px 10px 0;
  font-size: 15px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    margin-right: 5px;
  }
`;

export {
  ContainerSearch,
  ContainerInformation,
  LabelSelected,
  DataPostContainer,
  DataPostItem,
  PreviewContent,
  MenuButton,
  PostMenu,
  PostMenuItem,
  FitlerButton,
  TemplateDetailsContainer,
  TemplateDetailsHeader,
  TemplateDetailsContent,
  ButtonField,
  TemplateDetailsForm,
  DatatPostContent,
  FilterMediaTypeButton,
  FilterMediaTypeRow,
  RowItem,
  ColItem,
  LogsEmptyData,
  AdvancedFilterLabel,
  CloseButton,
  ContentInformation,
  TitleContent,
  DescriptionContent,
  QuantityLabel,
};
