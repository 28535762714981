/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { RootState } from "reducers";

import {
  Dropdown,
  Button,
  Input,
  FormGroup,
  FormInline,
  FormLabel,
  TitlePartOfForm,
  Avatar,
} from "components/Common";

import countries from "libs/countries";
import {
  UsersActions,
  EmailTemplateActions,
  AccountActions,
  ClientActions,
} from "actions";
import PasswordGenerator from "generate-password";
import {
  TemplateDetailsContent,
  TemplateDetailsContainer,
  TemplateDetailsForm,
  ButtonField,
  TemplateDetailsHeader,
  OrderLayer,
  LoadingLayer,
  Spinner,
} from "../Popup.styles";
import { CONSTANTS } from "constants/Constants";
import { EmailTemplateDetails } from "../Template";
import { useTranslation } from "react-i18next";
import {
  popupAlert,
  processDataDropDownAccount,
  processDataDropDownClient,
} from "libs/Utils";
import { Images } from "themes";

const { updateUser, registerUser, clearUserDetails } = UsersActions;
const { getEmailTemplateByType, clearEmailTemplateDetails } =
  EmailTemplateActions;
const { fetchPackagesForUserPortal } = AccountActions;
const { fetchAllClients } = ClientActions;

interface SectionProps {
  onClose: (isFetch?: boolean) => void;
  data: any;
}

const UserDetails: React.FC<SectionProps> = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { onClose, data } = props;
  const dispatch = useDispatch();

  const emailTemplate = useSelector((state: RootState) =>
    _.get(state, "EmailTemplate.emailTemplate")
  );
  const emailTemplateIsLoading = useSelector((state: RootState) =>
    _.get(state, "EmailTemplate.isLoading")
  );
  const packages = useSelector((state: RootState) => {
    return state.Account.packagesForUserPotal;
  });
  const packagesIsLoading = useSelector((state: RootState) => {
    return state.Account.isLoading;
  });
  const clients = useSelector((state: RootState) => {
    return state.Clients.clients;
  });
  const clientsIsLoading = useSelector((state: RootState) => {
    return state.Clients.isLoading;
  });
  const usersIsLoading = useSelector((state: RootState) => {
    return state.Users.isLoading;
  });
  const isRegisterSuccess = useSelector((state: RootState) => {
    return state.Users.isRegisterSuccess;
  });
  const isUpdateSuccess = useSelector((state: RootState) => {
    return state.Users.isUpdateSuccess;
  });
  const [isShowCreateTemplate, setIsShowCreateTemplate] =
    useState<boolean>(false);
  const [country, setCountry] = useState("");
  const [accountImage, setAccountImage] = useState();
  const [accountData, setAccountData] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    contactNumber: "",
    address: "",
    city: "",
    region: "",
    zipCode: "",
    userType: "",
    clientId: "",
    accountId: "",
  });
  const [urlPreview, setUrlPreview] = useState("");
  const [extraData, setExtraData] = useState({
    facebook: "",
    twitter: "",
    linkedin: "",
    pinterest: "",
    instagram: "",
  });

  const packageDropdown = processDataDropDownAccount(packages);
  const clientsDropdown = processDataDropDownClient(clients);

  useEffect(() => {
    dispatch(getEmailTemplateByType(CONSTANTS.EMAIL_TYPE.VERIFY_ACCOUNT));
    dispatch(fetchAllClients());
    return () => {
      dispatch(clearEmailTemplateDetails());
      dispatch(clearUserDetails());
    };
  }, []);

  useEffect(() => {
    if ((isRegisterSuccess === 2 || isUpdateSuccess === 2) && onClose)
      onClose(true);
  }, [isRegisterSuccess, isUpdateSuccess]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const clientId = _.get(data, "clientId.id");
      const accountManager = _.get(data, "accountManageUser");
      const accountId: string[] = [];
      _.forEach(accountManager, (account) =>
        accountId.push(_.get(account, "accountId.id"))
      );

      const avatar = _.get(data, "userData.avatar");
      if (avatar) {
        const getImagePath = _.get(avatar, "imagePath");
        setUrlPreview(getImagePath);
      }

      setAccountData({
        firstName: _.get(data, "userData.firstName"),
        lastName: _.get(data, "userData.lastName"),
        email: _.get(data, "email"),
        username: _.get(data, "username"),
        contactNumber: _.get(data, "userData.phoneNumber"),
        address: _.get(data, "userData.address"),
        city: _.get(data, "userData.city"),
        region: _.get(data, "userData.regionArea"),
        zipCode: _.get(data, "userData.zipCode"),
        userType: _.get(data, "userType"),
        clientId,
        accountId,
      });
      if (accountId && clientId) dispatch(fetchPackagesForUserPortal(clientId));
      if (!_.isEmpty(_.get(data, "userData.extraData")))
        setExtraData(JSON.parse(_.get(data, "userData.extraData")));
      setCountry(_.get(data, "userData.country"));
    }
  }, [data]);

  const processData = (type?: string, newAccountId?: any, removeIds?: any) => {
    const formData = new FormData();
    const userType = _.get(accountData, "userType");
    const accountId = _.get(accountData, "accountId");
    if (userType === CONSTANTS.USER_TYPE.USER && type !== "update")
      for (const accId of accountId) formData.append("accountId[]", accId);
    if (type === "update" && !_.isEmpty(removeIds)) {
      for (const removeId of removeIds)
        formData.append("removeAccountId[]", removeId);
    }
    if (type === "update" && !_.isEmpty(newAccountId)) {
      for (const newId of newAccountId)
        formData.append("newAccountId[]", newId);
    }
    formData.append("clientId", _.trim(accountData.clientId));
    formData.append("email", _.trim(accountData.email));
    formData.append("username", _.trim(accountData.username));
    formData.append("userType", _.trim(accountData.userType));
    formData.append("userData[firstName]", _.trim(accountData.firstName));
    formData.append("userData[lastName]", _.trim(accountData.lastName));
    formData.append("userData[address]", _.trim(accountData.address));
    formData.append("userData[phoneNumber]", _.trim(accountData.contactNumber));
    formData.append("userData[country]", _.trim(country));
    formData.append("userData[city]", _.trim(accountData.city));
    if (accountData.zipCode)
      formData.append("userData[zipCode]", _.trim(accountData.zipCode));
    if (accountData.region)
      formData.append("userData[regionArea]", _.trim(accountData.region));
    formData.append("userData[extraData]", JSON.stringify(extraData));
    if (accountImage) formData.append("userData[avatar]", accountImage || "");

    const userId = _.get(data, "id");
    if (!userId) {
      const password = PasswordGenerator.generate({
        length: 10,
        numbers: true,
        lowercase: true,
        uppercase: true,
        strict: true,
      });
      formData.append("password", _.trim(password));
      formData.append("confirmationPassword", _.trim(password));
    }

    return formData;
  };

  const validateData = () => {
    let flag = true;
    let message = `${t("alert.foundEmptyFields")}`;
    const data: any[] = [
      {
        label: t("label.firstName"),
        value: accountData.lastName,
      },
      {
        label: t("label.lastName"),
        value: accountData.lastName,
      },
      {
        label: t("label.username"),
        value: accountData.username,
      },
      {
        label: t("label.email"),
        value: accountData.email,
      },
      {
        label: t("label.userType"),
        value: accountData.userType,
      },
      // {
      //   label: t("label.streetAddress"),
      //   value: accountData.address,
      // },
      // {
      //   label: t("label.country"),
      //   value: country,
      // },
      // {
      //   label: t("label.city"),
      //   value: accountData.city,
      // },
      // {
      //   label: t("label.contactNumber"),
      //   value: accountData.contactNumber,
      // },
      // {
      //   label: t("label.client"),
      //   value: accountData.clientId,
      // },
    ];
    // const userType = _.get(accountData, "userType");
    // if (userType === CONSTANTS.USER_TYPE.USER) {
    //   data.push(
    //     ...[
    //       {
    //         label: t("label.package"),
    //         value: accountData.accountId,
    //       },
    //     ]
    //   );
    // }
    _.map(data, (item) => {
      const label = _.get(item, "label");
      const value = _.get(item, "value");
      if (_.isEmpty(_.trim(value))) {
        flag = false;
        message += `${label}\n`;
      }
    });

    return { flag, message };
  };

  const createAccount = async () => {
    const { flag, message } = validateData();
    if (!flag)
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });
    else {
      const userData = processData();
      const userId = _.get(data, "id");
      if (userId) {
        const newAcountIdState: string[] = [];
        const removeAccount: any[] = [];
        const accountManager = _.get(data, "accountManageUser");
        const accountManagerIds = _.map(
          accountManager,
          (item: any) => item?.accountId?.id
        );
        if (accountManager) {
          const removeAccountIds = accountManagerIds.filter(
            (element) => !accountData.accountId.includes(element)
          );
          if (!_.isEmpty(removeAccountIds))
            removeAccount.push(removeAccountIds);
        }
        if (accountData?.accountId) {
          const newAccountIds = accountData?.accountId.filter(
            (item: any) => !accountManagerIds.includes(item)
          );
          if (!_.isEmpty(newAccountIds)) newAcountIdState.push(newAccountIds);
        }
        const userDataUpdate = processData(
          "update",
          newAcountIdState,
          removeAccount
        );
        await dispatch(updateUser(userDataUpdate, userId));
      } else await dispatch(registerUser(userData));
    }
  };

  const setLogo = (e: any) => {
    if (e) {
      const url = URL.createObjectURL(e.target.files[0]);
      setAccountImage(e.target.files[0]);
      setUrlPreview(url);
    }
  };

  const onCloseTemplate = async () => {
    await setIsShowCreateTemplate(false);
    await dispatch(getEmailTemplateByType(CONSTANTS.EMAIL_TYPE.EMAIL_SCHEDULE));
  };

  return (
    <TemplateDetailsContainer>
      <TemplateDetailsContent>
        {isShowCreateTemplate && (
          <EmailTemplateDetails onClose={() => onCloseTemplate()} />
        )}
        {!_.has(emailTemplate, "id") && (
          <OrderLayer>
            <p>{t("description.emptyVeriyAccountTemplate")}</p>
            <Button
              buttonWidth="150px"
              onClick={() => setIsShowCreateTemplate(true)}
            >
              {t("button.createTemplate")}
            </Button>
          </OrderLayer>
        )}
        {(emailTemplateIsLoading || usersIsLoading) && (
          <LoadingLayer>
            <Spinner />
          </LoadingLayer>
        )}
        <TemplateDetailsHeader>
          {_.has(data, "id")
            ? t("headerPopup.editUser")
            : t("headerPopup.createNewUser")}
        </TemplateDetailsHeader>
        <TemplateDetailsForm height="500px">
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.firstName")}</FormLabel>
              <Input
                value={accountData.firstName || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({
                    ...accountData,
                    firstName: e.target.value,
                  })
                }
                placeholder={t("placeholder.firstName")}
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.lastName")}</FormLabel>
              <Input
                value={accountData.lastName || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, lastName: e.target.value })
                }
                placeholder={t("placeholder.lastName")}
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.username")}</FormLabel>
              <Input
                value={accountData.username || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, username: e.target.value })
                }
                placeholder={t("placeholder.username")}
                width="300px"
                disabled={_.has(data, "id")}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.email")}</FormLabel>
              <Input
                value={accountData.email || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, email: e.target.value })
                }
                placeholder={t("placeholder.email")}
                width="300px"
                disabled={_.has(data, "id")}
                maxLength={64}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.userType")}</FormLabel>
              <Dropdown
                value={accountData.userType || ""}
                width="300px"
                callbackPayload={(value: any) => {
                  const newPayload = {
                    ...accountData,
                    userType: value,
                  };
                  if (value === "client")
                    _.assign(newPayload, { accountId: "" });
                  setAccountData(newPayload);
                }}
                options={[
                  {
                    label: t("dropdown.client"),
                    value: "client",
                  },
                  {
                    label: t("dropdown.user"),
                    value: "user",
                  },
                ]}
                // disabled={_.has(data, "id")}
                placeholder={t("placeholder.userType")}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.client")}</FormLabel>
              <Dropdown
                value={_.get(accountData, "clientId")}
                width="300px"
                options={clientsDropdown}
                callbackPayload={(value: any) => {
                  setAccountData({
                    ...accountData,
                    clientId: value,
                    accountId: "",
                  });
                  dispatch(fetchPackagesForUserPortal(value));
                }}
                placeholder={t("placeholder.client")}
                loading={clientsIsLoading}
                // disabled={_.has(data, "id")}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t("label.package")}</FormLabel>
              <Dropdown
                value={accountData?.accountId || ""}
                width="300px"
                callbackPayload={(value: any) =>
                  setAccountData({ ...accountData, accountId: value })
                }
                options={packageDropdown}
                placeholder={t("placeholder.package")}
                disabled={
                  _.isEmpty(clients) ||
                  !_.get(accountData, "clientId") ||
                  _.get(accountData, "userType") === "client"
                }
                multiple
                loading={packagesIsLoading}
              />
            </FormGroup>
          </FormInline>
          <TitlePartOfForm>
            {t("titleSection.contactInformation")}
          </TitlePartOfForm>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.streetAddress")}</FormLabel>
              <Input
                value={accountData.address || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, address: e.target.value })
                }
                placeholder={t("placeholder.streetAddress")}
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t("label.country")}</FormLabel>
              <Dropdown
                value={country}
                width="300px"
                callbackPayload={(value: any) => setCountry(value)}
                options={countries}
                placeholder={t("placeholder.country")}
                search={false}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.city")}</FormLabel>
              <Input
                value={accountData.city || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, city: e.target.value })
                }
                placeholder={t("placeholder.city")}
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t("label.regionArea")}</FormLabel>
              <Input
                value={accountData.region || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, region: e.target.value })
                }
                placeholder={t("placeholder.regionArea")}
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.postalOrZipCode")}</FormLabel>
              <Input
                value={accountData.zipCode || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, zipCode: e.target.value })
                }
                placeholder={t("placeholder.postalOrZipCode")}
                width="300px"
                isNum
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t("label.contactNumber")}</FormLabel>
              <Input
                value={accountData.contactNumber || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({
                    ...accountData,
                    contactNumber: e.target.value,
                  })
                }
                placeholder={t("placeholder.phoneNumber")}
                width="300px"
                isNum
              />
            </FormGroup>
          </FormInline>
          <TitlePartOfForm>{t("titleSection.social")}</TitlePartOfForm>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.facebookProfile")}</FormLabel>
              <Input
                value={extraData.facebook || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, facebook: e.target.value })
                }
                placeholder="http://"
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t("label.instagramProfile")}</FormLabel>
              <Input
                value={extraData.instagram || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, instagram: e.target.value })
                }
                placeholder="http://"
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.twitterProfile")}</FormLabel>
              <Input
                value={extraData.twitter || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, twitter: e.target.value })
                }
                placeholder="http://"
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t("label.linkedInProfile")}</FormLabel>
              <Input
                value={extraData.linkedin || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, linkedin: e.target.value })
                }
                placeholder="http://"
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.pinterestProfile")}</FormLabel>
              <Input
                value={extraData.pinterest || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, pinterest: e.target.value })
                }
                placeholder="http://"
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormGroup>
            <FormLabel>
              {t("label.uploadAvatar")}{" "}
              <span className="description">({t("label.demensionImage")})</span>
            </FormLabel>
            <Avatar
              src={urlPreview || Images.defaultPhoto}
              onChange={(e: any) => setLogo(e)}
            />
          </FormGroup>
        </TemplateDetailsForm>
        <ButtonField>
          <Button
            type="submit"
            onClick={() => createAccount()}
            buttonWidth="100px"
            buttonMargin="0 10px 0 0"
          >
            {_.has(data, "id") ? t("button.save") : t("button.create")}
          </Button>
          <Button type="submit" onClick={() => onClose()} buttonWidth="100px">
            {t("button.cancel")}
          </Button>
        </ButtonField>
      </TemplateDetailsContent>
    </TemplateDetailsContainer>
  );
};

export default UserDetails;
