import styled from "styled-components";
import { HorizontalContainer } from "components/Common";
import ColorName from "constants/Variables";

interface SectionProps {
  margin?: string;
  color?: string;
  width?: string;
}

const ComponentCheckBox = styled(HorizontalContainer)`
  display: flex;
  align-items: center;
  width: ${(props: SectionProps) => props.width || "max-content"};
  margin: ${(props: SectionProps) => props.margin || "unset"};
  color: ${(props: SectionProps) => props.color || ColorName.william};
  &:hover {
    cursor: pointer;
  }
  label {
    display: block;
    padding-left: 25px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* &:hover {
      cursor: pointer;
    } */

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
        background-color: ${(props: SectionProps) =>
          props.color || ColorName.william};
        &:after {
          display: block;
        }
      }
    }

    & .checkmark {
      position: absolute;
      top: 4px;
      left: 0;
      height: 12px;
      width: 12px;
      border-radius: 4px;
      border: 2px solid
        ${(props: SectionProps) => props.color || ColorName.william};
      background-color: ${ColorName.white};

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 3px;
        top: 0px;
        width: 3px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  &.disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
`;

export { ComponentCheckBox };
