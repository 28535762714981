import styled from "styled-components";

const AnotherContactField = styled.div`
  height: 0px;
  overflow: hidden;
  transition: all ease 0.25s;

  &.show {
    height: 190px;
    overflow: visible;
  }
`;

export { AnotherContactField };
