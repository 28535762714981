import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RouteTypes from "constants/RouteTypes";
import history from "../../../../history";
import { RootState } from "reducers";
import { Images } from "themes";
import {
  UserHeaderContainer,
  HeaderContent,
  Logo,
  LoginButton,
  UserMenu,
  MenuItem,
  Avatar,
} from "./UserHeader.styles";
import { getSavedUserData, popupAlert } from "libs/Utils";
import _ from "lodash";
import { getToken } from "api/Base";
import {
  AuthenticationActions,
  DataRecordsActions,
  UserOfClientActions,
} from "actions";
import { useTranslation } from "react-i18next";

const { userLogOut } = AuthenticationActions;
const { clearDataRecordForUser } = DataRecordsActions;
const { setDefaultRegisterResult } = UserOfClientActions;

const Header = () => {
  const { t } = useTranslation("translation");

  const dispatch = useDispatch();
  const isLogged = useSelector((state: RootState) =>
    _.get(state, "Authentication.isLogged")
  );
  const loginResult = useSelector((state: RootState) =>
    _.get(state, "Authentication.loginResult")
  );
  const isUpdateSuccess = useSelector((state: RootState) =>
    _.get(state, "Users.isUpdateSuccess")
  );
  const userData = getSavedUserData();
  const token = getToken();
  const menuRef = useRef<HTMLDivElement>(null);

  const [avatarUrl, setAvatarUrl] = useState<string>("");

  const [isShowMenu, setIsShowMenu] = useState<boolean>(false);

  const handleClickOutside = (event: any) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsShowMenu(false);
      menuRef.current.blur();
    } else return;
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // if (loginResult === 2 || isLogged) setIsShowLoginPopup(false);
    const userData = getSavedUserData();
    if (userData) {
      const avatar = _.get(userData, "userData.avatar.imagePath");
      if (avatar) setAvatarUrl(avatar);
    }
    dispatch(setDefaultRegisterResult());
  }, [loginResult, isLogged, isUpdateSuccess]);

  useEffect(() => {
    // if (
    //   currentPage !== RouteTypes.USER_ROUTES.USER_ARTICLES_DETAILS &&
    //   !isLogged
    // )
    //   setIsShowLoginPopup(true);
    // else if (isLogged || token) {
    //   setIsShowLoginPopup(false);
    // }
    if (!isLogged && !token) dispatch(userLogOut());
  }, [isLogged]);

  const onLogOut = async () => {
    const isAgree = await popupAlert({
      title: t("alert.warning"),
      type: "warning",
      text: t("alert.warningBeforeLogOut"),
      buttons: true,
    });
    if (isAgree) {
      dispatch(clearDataRecordForUser());
      dispatch(userLogOut());
    }
  };

  return (
    <UserHeaderContainer>
      <HeaderContent>
        <Logo
          onClick={() => {
            if (isLogged) history.push(RouteTypes.USER_ROUTES.USER_ARTICLES);
            else history.push(RouteTypes.AUTHENTICATION_ROUTES.USER_LOGIN);
          }}
        >
          <img src={Images.logo} alt="user logo" className="logo-desktop" />
          <img
            src={Images.logoMobile}
            alt="logo mobile"
            className="logo-mobile"
          />
        </Logo>
        <LoginButton>
          {isLogged && <Avatar background={avatarUrl || Images.defaultPhoto} />}
          {isLogged ? (
            <span className="name" onClick={() => setIsShowMenu(true)}>
              {t("header.hello")},
              {_.capitalize(_.get(userData, "userData.fullName"))}
            </span>
          ) : (
            <span
              onClick={() =>
                history.push(RouteTypes.AUTHENTICATION_ROUTES.USER_LOGIN)
              }
            >
              {t("header.logIn")}
            </span>
          )}
          {isLogged && <i className="fas fa-sort-down" />}
          {isLogged && isShowMenu && (
            <UserMenu ref={menuRef}>
              <MenuItem
                onClick={() =>
                  history.push({ pathname: RouteTypes.USER_ROUTES.PROFILE })
                }
              >
                <i className="fas fa-user" />
                {t("header.myProfile")}
              </MenuItem>
              <MenuItem onClick={() => onLogOut()}>
                <i className="fas fa-sign-out-alt" />
                {t("header.logOut")}
              </MenuItem>
            </UserMenu>
          )}
        </LoginButton>
      </HeaderContent>
    </UserHeaderContainer>
  );
};

export default Header;
