import ActionTypes from 'constants/ActionTypes';

const initialState = {
	hasError: false,
	isLoading: false,
	isSuccess: false,
	logs: [],
	log: {}
};

export default (state = initialState, action: any) => {
	const { type, payload } = action;
	switch (type) {
		case ActionTypes.CLEAR_LOGS:
			return {
				...state,
				logs: [],
			};
		case ActionTypes.LOG_FAILURE:
			return {
				...state,
				hasError: true,
				isLoading: false
			};
		case ActionTypes.LOG_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case ActionTypes.FETCH_LOG_SUCCESS:
			return {
				...state,
				isSuccess: true,
				isLoading: false,
				logs: payload
			};
		case ActionTypes.GET_LOG_BY_ID_SUCCESS:
			return {
				...state,
				isSuccess: true,
				isLoading: false,
				log: payload
			};
		default:
			return state;
	}
};
