import ActionTypes from "constants/ActionTypes";
import API from "api";
import _ from "lodash";
import { responseAlert } from "libs/alert";
import history from "../history";
import RouteTypes from "../constants/RouteTypes";

const clearReportDetails = () => {
  return {
    type: ActionTypes.CLEAR_REPORT_DETAILS,
  };
};

const reportFail = () => {
  return {
    type: ActionTypes.REPORT_FAILURE,
  };
};

const reportLoading = () => {
  return {
    type: ActionTypes.REPORT_REQUEST,
  };
};

const createReportSuccess = () => {
  return {
    type: ActionTypes.CREATE_REPORT_SUCCESS,
  };
};

const updateReportSuccess = (payload = {}) => {
  return {
    type: ActionTypes.UPDATE_REPORT_SUCCESS,
    payload,
  };
};

const fetchReportsSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_REPORT_SUCCESS,
    payload,
  };
};

const searchReportsSuccess = (payload = {}) => {
  return {
    type: ActionTypes.SEARCH_REPORT_SUCCESS,
    payload,
  };
};

const getReportByIdSuccess = (payload = {}) => {
  return {
    type: ActionTypes.GET_REPORT_BY_ID_SUCCESS,
    payload,
  };
};

const createReport = (data: any) => {
  return async (dispatch: any) => {
    dispatch(reportLoading());
    await API.createReport(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          await responseAlert({ res, label: "Report created" });
          dispatch(createReportSuccess());
          history.push({ pathname: RouteTypes.ADMIN_ROUTES.REPORT });
        } else {
          responseAlert({ res });
          dispatch(reportFail());
        }
      })
      .catch((e: any) => {
        dispatch(reportFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchReports = () => {
  return async (dispatch: any) => {
    dispatch(reportLoading());
    await API.fetchReports()
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchReportsSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(reportFail());
        }
      })
      .catch((e: any) => {
        dispatch(reportFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getReportByID = (id: string) => {
  return async (dispatch: any) => {
    dispatch(reportLoading());
    await API.getReportById(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(getReportByIdSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(reportFail());
        }
      })
      .catch((e: any) => {
        dispatch(reportFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const searchReports = (data: any) => {
  return async (dispatch: any) => {
    dispatch(reportLoading());
    await API.searchReports(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(searchReportsSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(reportFail());
        }
      })
      .catch((e: any) => {
        dispatch(reportFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const updateReport = (data: any, id: string, action?: string) => {
  return async (dispatch: any) => {
    dispatch(reportLoading());
    await API.updateReport(data, id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          if (action === "remove")
            await responseAlert({ res, label: "Remove reports" });
          else await responseAlert({ res, label: "Update reports" });
          if (res.payload.status === "inactive") {
            dispatch(
              searchReports({
                fetch: "all",
                sortBy: "updatedAt",
                orderBy: "DESC",
              })
            );
          }
          dispatch(updateReportSuccess({}));
          history.push({ pathname: RouteTypes.ADMIN_ROUTES.REPORT });
        } else {
          responseAlert({ res });
          dispatch(reportFail());
        }
      })
      .catch((e: any) => {
        dispatch(reportFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getEmailDeliveryRequest = () => {
  return {
    type: ActionTypes.FETCH_REPORT_EMAIL_DISTIBUTION_LOADING,
  };
};

const fetchEmailDeliverySuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_REPORT_EMAIL_DISTIBUTION_SUCCESS,
    payload,
  };
};

const fetchEmailDeliveryError = () => {
  return {
    type: ActionTypes.FETCH_REPORT_EMAIL_DISTIBUTION_FAILURE,
  };
};

const fetchEmailDelivery = () => {
  return async (dispatch: any) => {
    dispatch(reportLoading());
    await API.searchEmailDelivery({
      status: "active",
      limit: 100,
      page: 1,
      orderBy: "ASC",
      sortBy: "createdAt",
    })
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(fetchEmailDeliverySuccess(res.payload?.items || []));
        } else {
          responseAlert({ res });
          dispatch(fetchEmailDeliveryError());
        }
      })
      .catch((e: any) => {
        dispatch(fetchEmailDeliveryError());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getEmailDeliverySuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_EMAIL_DISTIBUTION_SUCCESS,
    payload,
  };
};

const getEmailDeliveryError = () => {
  return {
    type: ActionTypes.GET_EMAIL_DISTIBUTION_FAILURE,
  };
};

const getEmailDelivery = (id: string) => {
  return async (dispatch: any) => {
    dispatch(getEmailDeliveryRequest());
    await API.getEmailDeliveryByID(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          const emails: string[] = [...res.payload.emailReceive];
          if (res.payload?.accountId) {
            const users =
              _.get(res.payload, "accountId.accountManageUser") || [];
            if (!_.isEmpty(users))
              _.forEach(users, (user) => {
                emails.push(_.get(user, "userId.email"));
              });
          }
          dispatch(getEmailDeliverySuccess(emails));
        } else {
          responseAlert({ res });
          dispatch(getEmailDeliveryError());
        }
      })
      .catch((e: any) => {
        dispatch(getEmailDeliveryError());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

export {
  createReport,
  fetchReports,
  getReportByID,
  searchReports,
  updateReport,
  clearReportDetails,
  fetchEmailDelivery,
  getEmailDelivery,
};
