import ActionTypes from "constants/ActionTypes";
import API from "api";
import _ from "lodash";
import history from "../history";
import { ROUTES } from "constants/Constants";
import { saveAs } from "file-saver";
import { responseAlert } from "libs/alert";

const clearClientsPayload = () => {
  return {
    type: ActionTypes.CLEAR_CLIENTS_PAYLOAD,
  };
};

const clientFail = () => {
  return {
    type: ActionTypes.CLIENT_FAILURE,
  };
};

const clientLoading = () => {
  return {
    type: ActionTypes.CLIENT_REQUEST,
  };
};

const fetchClientsSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_CLIENT_SUCCESS,
    payload,
  };
};

const fetchUsersClientSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_USERS_CLIENT_SUCCESS,
    payload,
  };
};

const clientsSuccess = () => {
  return {
    type: ActionTypes.CLIENT_SUCCESS,
  };
};

const getClientByIdSuccess = (payload = {}) => {
  return {
    type: ActionTypes.GET_CLIENT_BY_ID_SUCCESS,
    payload,
  };
};

const registerClientSuccess = (payload = {}) => {
  return {
    type: ActionTypes.REGISTER_CLIENT_SUCCESS,
    payload,
  };
};

const fetchAllClientsSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_ALL_CLIENTS_SUCCESS,
    payload,
  };
};

const fetchAllClients = (data?: any) => {
  return async (dispatch: any) => {
    dispatch(clientLoading());
    await API.fetchAllClients(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchAllClientsSuccess(_.get(res, "payload")));
        else {
          responseAlert({ res });
          dispatch(clientFail());
        }
      })
      .catch((e: any) => {
        dispatch(clientFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchClients = () => {
  return async (dispatch: any) => {
    dispatch(clientLoading());
    await API.fetchClients()
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchClientsSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(clientFail());
        }
      })
      .catch((e: any) => {
        dispatch(clientFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getClientsByID = (id: string) => {
  return async (dispatch: any) => {
    dispatch(clientLoading());
    await API.getClientByID(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(getClientByIdSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(clientFail());
        }
      })
      .catch((e: any) => {
        dispatch(clientFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const searchClients = (data: any) => {
  return async (dispatch: any) => {
    dispatch(clientLoading());
    await API.searchClients(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchClientsSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(clientFail());
        }
      })
      .catch((e: any) => {
        dispatch(clientFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const registerClient = (data: any, filter: any) => {
  return async (dispatch: any) => {
    dispatch(clientLoading());
    await API.registerClient(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(registerClientSuccess(res.payload));
          await responseAlert({ res, label: "Client registered" });
          history.push({
            pathname: ROUTES.CLIENT_STEP_SECOND,
            state: { id: res.payload.id, filter },
          });
        } else {
          responseAlert({ res });
          dispatch(clientFail());
        }
      })
      .catch((e: any) => {
        if (!_.isEmpty(e)) responseAlert({ res: e });
        dispatch(clientFail());
      });
  };
};

const updateClient = (data: any, id: string, filter: any) => {
  return async (dispatch: any) => {
    dispatch(clientLoading());
    await API.updateClient(data, id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(clientsSuccess());
          await responseAlert({ res, label: "Client updated" });
          history.push(filter);
        } else {
          responseAlert({ res });
          dispatch(clientFail());
        }
      })
      .catch((e: any) => {
        if (!_.isEmpty(e)) responseAlert({ res: e });
        dispatch(clientFail());
      });
  };
};

const fetchUsersClient = (data: any) => {
  return async (dispatch: any) => {
    dispatch(clientLoading());
    await API.fetchUsersClient(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchUsersClientSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(clientFail());
        }
      })
      .catch((e: any) => {
        dispatch(clientFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const updateClientSecondStep = (data: any, id: string, filter: any) => {
  return async (dispatch: any) => {
    dispatch(clientLoading());
    await API.updateClientSecondStep(data, id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(clientsSuccess());
          await responseAlert({ res, label: "Client's contact created" });
          history.push(filter);
        } else {
          responseAlert({ res });
          dispatch(clientFail());
        }
      })
      .catch((e: any) => {
        if (!_.isEmpty(e)) responseAlert({ res: e });
        dispatch(clientFail());
      });
  };
};

const deactivateClient = (id: string) => {
  return async (dispatch: any) => {
    dispatch(clientLoading());
    await API.deactivateClient(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          // popupAlert({ title: "Deactivate Client Successful", type: "success" });
          responseAlert({ res, label: "Client deactivated" });
          dispatch(clientsSuccess());
        } else {
          responseAlert({ res });
          dispatch(clientFail());
        }
      })
      .catch((e: any) => {
        dispatch(clientFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const activateClient = (id: string) => {
  return async (dispatch: any) => {
    dispatch(clientLoading());
    await API.activateClient(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Client activated" });
          dispatch(clientsSuccess());
        } else {
          responseAlert({ res });
          dispatch(clientFail());
        }
      })
      .catch((e: any) => {
        dispatch(clientFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const exportClients = () => {
  return async (dispatch: any) => {
    dispatch(clientLoading());
    await API.exportClients()
      .then(async (res: any) => {
        saveAs(res, "clients.xlsx");
        dispatch(clientsSuccess());
      })
      .catch((e: any) => {
        dispatch(clientFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

export {
  fetchClients,
  getClientsByID,
  searchClients,
  registerClient,
  updateClient,
  fetchUsersClient,
  updateClientSecondStep,
  deactivateClient,
  activateClient,
  exportClients,
  fetchAllClients,
  clearClientsPayload,
};
