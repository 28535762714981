/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useLocation } from "react-router-dom";

import { RootState } from "reducers";
import { ClientActions, AccountActions, UsersActions } from "actions";

import countries from "libs/countries";
import history from "../../../history";
import { CONSTANTS, ROUTES } from "constants/Constants";
import { popupAlert, processDataDropDownUser } from "libs/Utils";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import { AnotherContactField } from "./Clients.styles";
import {
  Button,
  Dropdown,
  // Checkbox,
  Input,
  SelectDatetime,
  Textarea,
  LoadingLayer,
  Spinner,
  FormDetails,
  FormGroup,
  FormInline,
  FormLabel,
  ButtonField,
  TitlePartOfForm,
  InputFile,
  ImagePreview,
} from "components/Common";
import { PackageDetails, UserForClient } from "components/Common/Popup";
import { useTranslation } from "react-i18next";
import RouteTypes from "constants/RouteTypes";
import { Images } from "themes";

const { getClientsByID, updateClient, fetchUsersClient } = ClientActions;
const { setDefaultRegisterResult } = UsersActions;
const { setDefaultPackageStatus, searchAccounts } = AccountActions;

interface IDState {
  id: any;
}

const CreateClient = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => {
    return state.Clients.isLoading;
  });
  const userIsLoading = useSelector((state: RootState) => {
    return state.Users.isLoading;
  });

  const users = useSelector((state: RootState) => {
    return state.Clients.usersClient;
  });
  const client = useSelector((state: RootState) => {
    return state.Clients.client;
  });
  const packages = useSelector((state: RootState) => {
    return state.Account.accounts;
  });

  const [clientData, setClientData] = useState({
    clientNumber: "",
    clientName: "",
    billingPeriod: "",
    billViaEmail: false,
    billViaPost: false,
    accountManager: "",
    samePrimaryContact: false,
    sameSecondaryContact: false,
    startDate: "",
    endDate: "",
    description: "",
  });

  const [billing, setBilling] = useState({
    billingAddress: "",
    primaryContact: "",
    secondaryContact: "",
  });

  const [userData, setUserData] = useState({
    address: "",
    city: "",
    country: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    region: "",
  });

  const [clientLogo, setClientLogo] = useState();
  const [filename, setFilename] = useState<string>("");
  const [isAllFieldEmpty, setIsAllFieldEmpty] = useState(false);
  const [isShowUserPopup, setIsShowUserPopup] = useState(false);
  const [isShowPackagePopup, setIsShowPackagePopup] = useState(false);
  const location = useLocation();
  const [urlPreview, setUrlPreview] = useState("");
  const [isAddAnotherContact, setIsAddAnotherContact] = useState(false);

  useEffect(() => {
    if (location.state) {
      const id = (location.state as IDState).id || "";
      if (!id) return history.push(ROUTES.CLIENTS);
      if (id) dispatch(getClientsByID(id));
    } else {
      history.push(ROUTES.CLIENTS);
    }
  }, [location, dispatch]);

  useEffect(() => {
    if (location.state) {
      const id = (location.state as IDState).id || "";
      if (id) {
        dispatch(
          fetchUsersClient({
            clientId: id,
            page: 1,
            limit: 100,
            status: CONSTANTS.STATUS.ACTIVE,
          })
        );
        dispatch(searchAccounts({ clientId: id, page: 1, limit: 100 }));
      } else history.push(ROUTES.CLIENTS);
    } else {
      history.push(ROUTES.CLIENTS);
    }
  }, [dispatch, location.state]);

  useEffect(() => {
    if (!_.isEmpty(client)) {
      const resolveClientData = { ...client };
      const primaryContact = _.get(client, "primaryContact.id");
      if (primaryContact) _.assign(resolveClientData, { primaryContact });

      const avatar = _.get(client, "userData.avatar");
      if (avatar) {
        const getFilename = _.get(avatar, "imageNameConvert");
        const getImagePath = _.get(avatar, "imagePath");
        setUrlPreview(getImagePath);
        setFilename(getFilename);
      }

      setClientData(resolveClientData);
      setUserData(_.get(client, "userData"));
      // setUrlPreview(_.get(client, "userData.avatar"));
      setBilling({
        billingAddress: _.get(client, "billingAddress.id"),
        primaryContact: _.get(client, "primaryContact.id"),
        secondaryContact: _.get(client, "secondContact.id"),
      });
      if (_.get(client, "secondContact.id")) setIsAddAnotherContact(true);
    }
  }, [client]);

  const dataDropdownUsers = processDataDropDownUser(_.get(users, "items"));
  // const dataDropdownPeriod = [
  //   { name: `3 ${t("dropdown.months")}`, value: "3 months" },
  //   { name: `6 ${t("dropdown.months")}`, value: "6 months" },
  //   { name: t("dropdown.everyYear"), value: "every yaer" },
  // ];

  const processData = () => {
    const formData = new FormData();
    // formData.append("clientNumber", _.trim(clientData.clientNumber));
    formData.append("clientName", _.trim(clientData.clientName));
    formData.append("userData[address]", _.trim(userData.address));
    formData.append("userData[phoneNumber]", _.trim(userData.phoneNumber));
    formData.append("userData[country]", _.trim(userData.country));
    formData.append("userData[city]", _.trim(userData.city));
    if (userData.zipCode)
      formData.append("userData[zipCode]", _.trim(userData.zipCode));
    if (userData.region)
      formData.append("userData[regionArea]", _.trim(userData.region));
    formData.append("accountManager", _.trim(clientData.accountManager));
    if (clientData.billingPeriod)
      formData.append("billingPeriod", _.trim(clientData.billingPeriod));
    if (
      billing.billingAddress !== _.get(client, "billingAddress.id") &&
      billing.billingAddress
    )
      formData.append("billingAddress", _.trim(billing.billingAddress));
    if (
      billing.primaryContact !== _.get(client, "primaryContact.id") &&
      billing.primaryContact
    )
      formData.append("primaryContact", _.trim(billing.primaryContact));
    if (
      billing.secondaryContact !== _.get(client, "secondContact.id") &&
      billing.secondaryContact &&
      isAddAnotherContact
    )
      formData.append("secondContact", _.trim(billing.secondaryContact));
    formData.append("description", _.trim(clientData.description));
    formData.append("startDate", _.trim(clientData.startDate));
    formData.append("endDate", _.trim(clientData.endDate));
    if (clientLogo) formData.append("userData[avatar]", clientLogo || "");
    return formData;
  };

  const validateData = () => {
    let flag = true;
    let message = `${t("alert.foundEmptyFields")}`;
    const data = [
      {
        label: t("label.clientName"),
        value: _.get(clientData, "clientName"),
      },
      // {
      //   label: t("label.clientNumber"),
      //   value: _.get(clientData, "clientNumber"),
      // },
      {
        label: t("label.startDate"),
        value: _.get(clientData, "startDate"),
      },
      {
        label: t("label.endDate"),
        value: _.get(clientData, "endDate"),
      },
      {
        label: t("label.primaryContact"),
        value: _.get(billing, "primaryContact"),
      },
      {
        label: t("label.address"),
        value: _.get(userData, "address"),
      },
      {
        label: t("label.city"),
        value: _.get(userData, "city"),
      },
      {
        label: t("label.country"),
        value: _.get(userData, "country"),
      },
      {
        label: t("label.phoneNumber"),
        value: _.get(userData, "phoneNumber"),
      },
      {
        label: t("label.accountManager"),
        value: _.get(clientData, "accountManager"),
      },
      {
        label: t("label.description"),
        value: _.get(clientData, "description"),
      },
    ];

    if (isAddAnotherContact)
      data.push(
        ...[
          {
            label: t("label.secondaryContact"),
            value: _.get(billing, "secondaryContact"),
          },
        ]
      );

    _.map(data, (item) => {
      const { value, label } = item;
      if (_.isEmpty(_.trim(value))) {
        message += `${label}\n`;
        flag = false;
      }
    });
    return { flag, message };
  };

  const createClient = async () => {
    const { flag, message } = validateData();

    if (!flag)
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });
    else {
      const data = processData();
      setIsAllFieldEmpty(false);
      const filter = _.get(location, "state.filter");

      const resolveFilter = {
        pathname: RouteTypes.ADMIN_ROUTES.CLIENTS,
        state: { filter, from: RouteTypes.ADMIN_ROUTES.EDIT_CLIENT },
      };
      await dispatch(updateClient(data, client.id, resolveFilter));
    }
  };

  const setLogo = (e: any) => {
    if (e.target.files) {
      const url = URL.createObjectURL(e.target.files[0]);
      setClientLogo(e.target.files[0]);
      setUrlPreview(url);
      setFilename(_.get(e.target.files[0], "name"));
    }
  };

  const generateUserOptions = (key: "primary" | "secondary") => {
    const resolveOptions: any[] = [];
    let preventId = "";
    if (key === "primary") preventId = _.get(billing, "secondaryContact");

    if (key === "secondary") preventId = _.get(billing, "primaryContact");

    _.forEach(dataDropdownUsers, (user: any) => {
      if (preventId !== _.get(user, "value")) resolveOptions.push(user);
    });
    return resolveOptions;
  };

  // const generateSameAddress = (value: string) => {
  //   const resolveAddress = {
  //     sameSecondaryContact: false,
  //     samePrimaryContact: false,
  //   };
  //   if (value === billing.primaryContact)
  //     _.assign(resolveAddress, { samePrimaryContact: true });
  //   else if (value === billing.secondaryContact)
  //     _.assign(resolveAddress, { sameSecondaryContact: true });
  //   return resolveAddress;
  // };

  const onCloseUserPopup = async () => {
    const id = _.get(location, "state.id");
    await dispatch(
      fetchUsersClient({
        clientId: id,
        limit: 100,
        status: CONSTANTS.STATUS.ACTIVE,
      })
    );
    dispatch(setDefaultRegisterResult());
    setIsShowUserPopup(false);
  };

  const onClosePackagePopup = async (isFetch?: boolean) => {
    const clientId = _.get(client, "id");
    if (isFetch && clientId)
      await dispatch(searchAccounts({ clientId, page: 1, limit: 100 }));
    setIsShowPackagePopup(false);
  };

  const renderMain = () => {
    return (
      <>
        {isShowUserPopup && (
          <UserForClient
            clientId={_.get(location, "state.id") || ''}
            onClose={() => onCloseUserPopup()}
          />
        )}
        {isShowPackagePopup && (
          <PackageDetails
            onClose={(isFetch?: boolean) => onClosePackagePopup(isFetch)}
            clientId={_.get(client, "id")}
          />
        )}
        <FormDetails>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.clientName")}
              </FormLabel>
              <Input
                value={clientData.clientName || ""}
                onChangeHandler={(e) =>
                  setClientData({ ...clientData, clientName: e.target.value })
                }
                isRequired={!clientData.clientName && isAllFieldEmpty}
                placeholder={t(
                  `placeholder.${isLoading ? "loading" : "clientName"}`
                )}
                width="300px"
                // disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.clientNumber")}
              </FormLabel>
              <Input
                value={clientData.clientNumber || ""}
                onChangeHandler={(e) =>
                  setClientData({ ...clientData, clientNumber: e.target.value })
                }
                isRequired={!clientData.clientNumber && isAllFieldEmpty}
                placeholder={t(
                  `placeholder.${isLoading ? "loading" : "clientNumber"}`
                )}
                width="300px"
                isNum
                disabled={true}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("placeholder.startDate")}
              </FormLabel>
              <SelectDatetime
                callbackPayload={(e) =>
                  setClientData({ ...clientData, startDate: e })
                }
                defaultValue={_.get(clientData, "startDate")}
                maxDate={clientData?.endDate || new Date().toLocaleString()}
                placeholder={t(
                  `placeholder.${isLoading ? "loading" : "startDate"}`
                )}
                disabledPreviousDate
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.endDate")}</FormLabel>
              <SelectDatetime
                callbackPayload={(e) =>
                  setClientData({ ...clientData, endDate: e })
                }
                minDate={clientData?.startDate || new Date().toLocaleString()}
                defaultValue={_.get(clientData, "endDate")}
                placeholder={t(
                  `placeholder.${isLoading ? "loading" : "endDate"}`
                )}
                disabledPreviousDate
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <TitlePartOfForm aria-label="require">
            {t("label.companyAddress")}
          </TitlePartOfForm>

          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.streetAddress")}
              </FormLabel>
              <Input
                value={userData.address || ""}
                onChangeHandler={(e) =>
                  setUserData({ ...userData, address: e.target.value })
                }
                isRequired={!userData.address && isAllFieldEmpty}
                placeholder={t(
                  `placeholder.${isLoading ? "loading" : "streetAddress"}`
                )}
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.city")}</FormLabel>
              <Input
                value={userData.city || ""}
                onChangeHandler={(e) =>
                  setUserData({ ...userData, city: e.target.value })
                }
                isRequired={!userData.city && isAllFieldEmpty}
                placeholder={t(`placeholder.${isLoading ? "loading" : "city"}`)}
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.country")} (*)
              </FormLabel>
              <Dropdown
                value={userData.country || ""}
                width="300px"
                callbackPayload={(value) =>
                  setUserData({ ...userData, country: value })
                }
                options={countries}
                isRequired={!userData.country && isAllFieldEmpty}
                placeholder={t(
                  `placeholder.${isLoading ? "loading" : "country"}`
                )}
                search={true}
                loading={isLoading}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.postalOrZipCode")}</FormLabel>
              <Input
                value={userData.zipCode || ""}
                onChangeHandler={(e) =>
                  setUserData({ ...userData, zipCode: e.target.value })
                }
                placeholder={t(
                  `placeholder.${isLoading ? "loading" : "postalOrZipCode"}`
                )}
                width="300px"
                isNum
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.contactNumber")}
              </FormLabel>
              <Input
                value={userData.phoneNumber || ""}
                onChangeHandler={(e) =>
                  setUserData({ ...userData, phoneNumber: e.target.value })
                }
                isRequired={!userData.phoneNumber && isAllFieldEmpty}
                placeholder={t(
                  `placeholder.${isLoading ? "loading" : "contactNumber"}`
                )}
                width="300px"
                isNum
              />
            </FormGroup>
          </FormInline>
          <TitlePartOfForm aria-label="require">
            {t("label.primaryContact")}
          </TitlePartOfForm>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.selectUser")}
              </FormLabel>
              {!_.isEmpty(dataDropdownUsers) && (
                <Dropdown
                  value={billing.primaryContact || ""}
                  width="300px"
                  callbackPayload={(value) => {
                    setBilling({
                      ...billing,
                      primaryContact: value,
                      billingAddress: "",
                    });
                    setClientData({
                      ...clientData,
                      samePrimaryContact: false,
                      sameSecondaryContact: false,
                    });
                  }}
                  options={generateUserOptions("primary")}
                  placeholder={t(
                    `placeholder.${userIsLoading ? "loading" : "selectUser"}`
                  )}
                  search={false}
                  isRequired={!billing.primaryContact && isAllFieldEmpty}
                  loading={userIsLoading}
                />
              )}
              {_.isEmpty(_.get(users, "items")) && (
                <div className="empty">{t("label.noUserFound")}</div>
              )}
            </FormGroup>
            <Button
              type="submit"
              onClick={() =>
                !_.isEmpty(_.get(packages, "items")) && setIsShowUserPopup(true)
              }
              buttonWidth="170px"
              buttonMargin="10px 10px -40px 0"
              disabled={_.isEmpty(_.get(packages, "items"))}
            >
              + {t("button.createUser")}
            </Button>
          </FormInline>
          {/* <Button
            onClick={() => setIsAddAnotherContact(!isAddAnotherContact)}
            buttonMargin="10px 10px 20px 0"
          >
            {isAddAnotherContact ? "Remove" : "Add"} another contact
          </Button> */}
          <AnotherContactField className={isAddAnotherContact ? "show" : ""}>
            <TitlePartOfForm>{t("label.secondaryContact")}</TitlePartOfForm>
            <FormInline>
              <FormGroup>
                <FormLabel>{t("label.selectUser")}</FormLabel>
                {!_.isEmpty(dataDropdownUsers) && (
                  <Dropdown
                    value={billing.secondaryContact || ""}
                    width="300px"
                    callbackPayload={(value) => {
                      setBilling({
                        ...billing,
                        secondaryContact: value,
                        billingAddress: "",
                      });
                      setClientData({
                        ...clientData,
                        samePrimaryContact: false,
                        sameSecondaryContact: false,
                      });
                    }}
                    options={generateUserOptions("secondary")}
                    margin="10px 20px 10px 0"
                    placeholder={t(
                      `placeholder.${userIsLoading ? "loading" : "selectUser"}`
                    )}
                    search={false}
                    loading={userIsLoading}
                  />
                )}
                {_.isEmpty(_.get(users, "items")) && (
                  <div className="empty">{t("label.noUserFound")}</div>
                )}
              </FormGroup>
              <Button
                type="submit"
                onClick={() =>
                  !_.isEmpty(_.get(packages, "items")) &&
                  setIsShowUserPopup(true)
                }
                buttonWidth="170px"
                buttonMargin="10px 10px -40px 0"
                disabled={_.isEmpty(_.get(packages, "items"))}
              >
                + {t("button.createUser")}
              </Button>
            </FormInline>
          </AnotherContactField>
          <FormInline>
            <Button
              onClick={() => setIsAddAnotherContact(!isAddAnotherContact)}
              buttonMargin="10px 10px 20px 0"
            >
              {isAddAnotherContact ? "Remove" : "Add"} another contact
            </Button>
            <Button
              type="submit"
              onClick={() => {
                dispatch(setDefaultPackageStatus());
                setIsShowPackagePopup(true);
              }}
              buttonWidth="170px"
              buttonMargin="10px 10px 20px 0"
            >
              + {t("button.createPackage")}
            </Button>
          </FormInline>
          {/* <div className="form-title">{t("label.billingDetails")}</div>

          <div className="form-group">
            <div className="container-element">
              <span>{t("label.billingPeriod")}</span>
              <Dropdown
                value={clientData.billingPeriod || ""}
                width="300px"
                callbackPayload={(value) =>
                  setClientData({ ...clientData, billingPeriod: value })
                }
                options={dataDropdownPeriod}
                margin="10px 20px 10px 0"
                placeholder={t(
                  `placeholder.${isLoading ? "loading" : "billingPeriod"}`
                )}
                search={false}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="container-element">
              <Checkbox
                checked={clientData.billViaPost || false}
                context={t("label.billViaPost")}
                onChangeHandler={(value) =>
                  setClientData({
                    ...clientData,
                    billViaPost: value,
                    billViaEmail: false,
                  })
                }
              />
            </div>
            <div className="container-element">
              <Checkbox
                checked={clientData.billViaEmail || false}
                context={t("label.billViaEmail")}
                onChangeHandler={(value) =>
                  setClientData({
                    ...clientData,
                    billViaEmail: value,
                    billViaPost: false,
                  })
                }
              />
            </div>
          </div>
          <div className="form-title">{t("label.billingAddress")}</div>
          <div className="form-group">
            <div className="container-element">
              <Checkbox
                checked={clientData.samePrimaryContact || false}
                context={t("label.sameAsPrimaryContact")}
                onChangeHandler={(value) => {
                  setClientData({
                    ...clientData,
                    samePrimaryContact: value,
                    sameSecondaryContact: false,
                  });
                  setBilling({
                    ...billing,
                    billingAddress: value ? billing.primaryContact : "",
                  });
                }}
              />
              <div className="empty">
                {clientData.samePrimaryContact && !billing.primaryContact
                  ? t("label.youDontHavePrimaryContact")
                  : " "}
              </div>
            </div>
            <div className="container-element">
              <Checkbox
                checked={clientData.sameSecondaryContact || false}
                context={t("label.sameAsSecondaryContact")}
                onChangeHandler={(value) => {
                  setClientData({
                    ...clientData,
                    sameSecondaryContact: value,
                    samePrimaryContact: false,
                  });
                  setBilling({
                    ...billing,
                    billingAddress: value ? billing.secondaryContact : "",
                  });
                }}
              />
              <div className="empty">
                {clientData.sameSecondaryContact && !billing.secondaryContact
                  ? t("label.youDontHaveSecondaryContact")
                  : " "}
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="container-element">
              <span>{t("label.selectUser")}</span>
              {!_.isEmpty(dataDropdownUsers) && (
                <Dropdown
                  value={billing.billingAddress || ""}
                  width="300px"
                  callbackPayload={(value) => {
                    const sameAddress = generateSameAddress(value);
                    setClientData({ ...clientData, ...sameAddress });
                    setBilling({ ...billing, billingAddress: value });
                  }}
                  options={dataDropdownUsers}
                  margin="10px 20px 10px 0"
                  placeholder={t(
                    `placeholder.${userIsLoading ? "loading" : "selectUser"}`
                  )}
                  search={false}
                  isRequired={!billing.billingAddress && isAllFieldEmpty}
                  loading={userIsLoading}
                />
              )}
              {_.isEmpty(_.get(users, "items")) && (
                <div className="empty">{t("label.noUserFound")}</div>
              )}
            </div>
            <div className="container-element">
              <Button
                type="submit"
                onClick={() => setIsDisplayPopup(true)}
                buttonWidth="150px"
                buttonMargin="10px 10px -40px 0"
              >
                + {t("button.create")}
              </Button>
            </div>
          </div> */}

          <TitlePartOfForm>{t("label.clientInformation")}</TitlePartOfForm>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.accountManager")}
              </FormLabel>
              <Input
                value={clientData.accountManager || ""}
                margin="10px 20px 10px 0"
                onChangeHandler={(e) =>
                  setClientData({
                    ...clientData,
                    accountManager: e.target.value,
                  })
                }
                isRequired={!clientData.accountManager && isAllFieldEmpty}
                placeholder={t(
                  `placeholder.${isLoading ? "loading" : "accountManager"}`
                )}
                width="300px"
              />
            </FormGroup>
          </FormInline>

          <FormInline>
            <FormGroup>
              <FormLabel>
                {t("label.uploadAvatar")}{" "}
                <span className="description">
                  ({t("label.demensionImage")})
                </span>
              </FormLabel>
              <ImagePreview
                src={urlPreview || Images.defaultPhoto}
                alt={t("label.avatar")}
              />
              <InputFile
                onChangeHandler={(e) => setLogo(e)}
                margin="10px 10px 10px 0"
                filename={filename}
              />
            </FormGroup>
          </FormInline>

          <FormGroup>
            <FormLabel aria-label="require">{t("label.description")}</FormLabel>
            <Textarea
              value={_.get(clientData, "description") || ""}
              onChangeHandler={(e: any) =>
                setClientData({
                  ...clientData,
                  description: e.target.value,
                })
              }
              margin="10px 20px 10px 0"
              placeholder={t(
                `placeholder.${isLoading ? "loading" : "clientDescription"}`
              )}
              width="100%"
              rows={5}
            />
          </FormGroup>
          <ButtonField margin="10px 0 50px 0">
            <Button
              type="submit"
              onClick={() => createClient()}
              buttonWidth="100px"
              buttonMargin="10px 10px 10px 0"
              className={isLoading ? "loading" : ""}
            >
              {t("button.save")}
              {isLoading && (
                <LoadingLayer>
                  <Spinner />
                </LoadingLayer>
              )}
            </Button>
            <Button
              type="submit"
              onClick={() => history.push(ROUTES.CLIENTS)}
              buttonWidth="100px"
              buttonMargin="10px 10px 10px 0"
            >
              {t("button.back")}
            </Button>
          </ButtonField>
        </FormDetails>
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      parent={RouteTypes.ADMIN_ROUTES.GROUPS}
      currentPage={RouteTypes.ADMIN_ROUTES.CLIENTS}
      title={t("titlePage.editClient")}
    />
  );
};

export default CreateClient;
