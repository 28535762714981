import React from "react";
import { Box, Fab } from "@mui/material";
import {
  MailOutline,
  LinkedIn,
  Facebook,
  Twitter,
  KeyboardArrowUp,
  Share,
} from "@mui/icons-material";

import { fabStyle, mainStyles } from "./FloatAction.styles";

const FloatAction: React.FC<{ callback?(): void; pos: boolean }> = (props: {
  callback?(): void;
  pos: boolean;
}) => {
  const { callback, pos } = props;

  const moveToTop = () => {
    if (callback) callback();
  };

  return (
    <Box sx={mainStyles}>
      <Fab
        onClick={moveToTop}
        sx={fabStyle(pos)}
        size="small"
        title="scroll to top"
        id="moveToTop"
      >
        <KeyboardArrowUp />
      </Fab>
    </Box>
  );
};

export default FloatAction;
