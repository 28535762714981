import ActionTypes from "constants/ActionTypes";
import API from "api";
import _ from "lodash";
import axios from "axios";

import { responseAlert } from "libs/alert";
const clearSystemConfig = () => {
  return {
    type: ActionTypes.CLEAR_SYSTEM_CONFIG,
  };
};

const fetchSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_SYSTEM_CONFIG_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ActionTypes.FETCH_SYSTEM_CONFIG_FAILURE,
  };
};

const configLoading = () => {
  return {
    type: ActionTypes.SYSTEM_CONFIG_REQUEST,
  };
};

const updateSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_SYSTEM_CONFIG_SUCCESS,
    payload,
  };
};

const updateFail = () => {
  return {
    type: ActionTypes.UPDATE_SYSTEM_CONFIG_FAILURE,
  };
};

const testSuccess = () => {
  return {
    type: ActionTypes.TEST_EMAIL_SUCCESS,
  };
};

const testFail = () => {
  return {
    type: ActionTypes.TEST_EMAIL_FAILURE,
  };
};

const fetchSystemConfig = (data?: any) => {
  return async (dispatch: any) => {
    dispatch(configLoading());
    await API.fetchSystemConfig(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(fetchSuccess(res?.payload));
        } else {
          responseAlert({ res });
          dispatch(fetchFail());
        }
      })
      .catch((e: any) => {
        // dispatch(fetchFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const executeJenkinsJob = () => {
  const jenkinsUrl =
    "http://79.106.96.66:8484/job/Media-Monitoring-Webservice-Production/build";
  const username = "admin";
  const password = "11721da99e1529daa0da089278c4b604ab";
  // Base64 encode the username and password for basic authentication
  const basicAuth = btoa(`${username}:${password}`);
  // Set the request headers, including Authorization for basic authentication
  const config = {
    headers: {
      Authorization: `Basic ${basicAuth}`,
    },
    withCredentials: true, // Include credentials (e.g., cookies) in the request
  };
  // Send the POST request to Jenkins
  axios
    .post(jenkinsUrl, null, config)
    .then((response) => {
      console.log("Jenkins job execution successful:", response.data);
    })
    .catch((error) => {
      console.error("Error executing Jenkins job:", error);
    });
};

const updateSTMP = (data: any, id: any) => {
  return async (dispatch: any) => {
    dispatch(configLoading());
    await API.updateSTMPConfig(data, id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(updateSuccess(res.payload));
          await responseAlert({ res, label: "STMP updated" });
          // executeJenkinsJob();
          await API.restartSystem();
        } else {
          responseAlert({ res });
          dispatch(updateFail());
        }
      })
      .catch((e: any) => {
        dispatch(updateFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const testEmail = () => {
  return async (dispatch: any) => {
    dispatch(configLoading());
    await API.testEmail()
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(testSuccess());
          await responseAlert({ res, label: "Send test mail" });
        } else {
          responseAlert({ res });
          dispatch(testFail());
        }
      })
      .catch((e: any) => {
        dispatch(testFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

export { fetchSystemConfig, updateSTMP, clearSystemConfig, testEmail };
