import React from "react";
import { ComponentReportCard, Spinner } from "./ReportCard.styles";

interface SectionProps {
  number?: string;
  text?: string;
  loading?: boolean;
}

const ReportCard: React.FC<SectionProps> = (props: SectionProps) => {
  const { number, text, loading } = props;

  return (
    <ComponentReportCard>
      <div>{loading ? <Spinner /> : <p>{number} {text}</p>}</div>
    </ComponentReportCard>
  );
};

export default ReportCard;
