import RouteTypes from "constants/RouteTypes";
import { Icons } from "themes";

const ROLE = {
  ADMIN: "admin",
  CLIENT: "client",
};

export default {
  [ROLE.ADMIN]: [
    {
      label: "dashboard",
      router: RouteTypes.ADMIN_ROUTES.HOMEPAGE,
      icon: Icons.dashboard,
    },
    {
      label: "manageSources",
      router: RouteTypes.ADMIN_ROUTES.MANAGE_SOURCES,
      icon: Icons.manageSources,
      child: [
        {
          label: "sources",
          router: RouteTypes.ADMIN_ROUTES.SOURCES_SETTINGS,
          icon: Icons.sources,
        },
        {
          label: "categories",
          router: RouteTypes.ADMIN_ROUTES.CATEGORIES,
          icon: Icons.categories,
        },
      ],
    },
    {
      label: "reports",
      router: RouteTypes.ADMIN_ROUTES.REPORT,
      icon: Icons.reports,
    },
    {
      label: "posts",
      router: RouteTypes.ADMIN_ROUTES.POST,
      icon: Icons.post,
    },
    {
      label: "dataRecords",
      router: RouteTypes.ADMIN_ROUTES.DATA_RECORD,
      icon: Icons.dataRecord,
    },
    {
      label: "downloadSource",
      router: RouteTypes.ADMIN_ROUTES.DOWNLOAD_SOURCE,
      icon: Icons.distribution,
    },
    {
      label: "fileRecords",
      router: RouteTypes.ADMIN_ROUTES.FILE_RECORD,
      icon: Icons.fileRecord,
    },
    {
      label: "members",
      router: RouteTypes.ADMIN_ROUTES.MEMBERS,
      icon: Icons.users,
    },
    {
      label: "groups",
      router: RouteTypes.ADMIN_ROUTES.GROUPS,
      icon: Icons.group,
      child: [
        {
          label: "clients",
          router: RouteTypes.ADMIN_ROUTES.CLIENTS,
          icon: Icons.clients,
        },
        {
          label: "users",
          router: RouteTypes.ADMIN_ROUTES.USERS,
          icon: Icons.groupUsers,
        },
      ],
    },
    {
      label: "training",
      router: RouteTypes.ADMIN_ROUTES.TRAINING,
      icon: Icons.training,
    },
    {
      label: "roles",
      router: RouteTypes.ADMIN_ROUTES.ROLES,
      icon: Icons.roles,
    },
    // {
    //   label: "Clients",
    //   router: RouteTypes.ADMIN_ROUTES.CLIENTS,
    //   icon: Icons.clients,
    // },
    {
      label: "filters",
      router: RouteTypes.ADMIN_ROUTES.FILTERS,
      icon: Icons.filters,
    },

    {
      label: "emailTemplates",
      router: RouteTypes.ADMIN_ROUTES.EMAIL_TEMPLATE,
      icon: Icons.template,
    },
    {
      label: "emailSchedules",
      router: RouteTypes.ADMIN_ROUTES.EMAIL_DELIVERY,
      icon: Icons.email,
    },
    {
      label: "logs",
      router: RouteTypes.ADMIN_ROUTES.LOGS,
      icon: Icons.logs,
    },
    {
      label: "folders",
      router: RouteTypes.ADMIN_ROUTES.FOLDERS,
      icon: Icons.reports,
      child: [
        {
          label: "folders",
          router: RouteTypes.ADMIN_ROUTES.FOLDERS,
          icon: Icons.folders,
        },
        {
          label: "hidden",
          router: RouteTypes.ADMIN_ROUTES.HIDDEN,
          icon: Icons.hide,
        },
      ],
    },
    {
      label: "configurations",
      router: RouteTypes.ADMIN_ROUTES.CONFIG,
      icon: Icons.manageSources,
      child: [
        {
          label: "STMP",
          router: RouteTypes.ADMIN_ROUTES.STMP,
          icon: Icons.manageSources,
        },
      ],
    },
    {
      label: "contact",
      router: RouteTypes.ADMIN_ROUTES.CONTACT,
      icon: Icons.contact,
    },
  ],
  [ROLE.CLIENT]: [
    {
      label: "packages",
      router: RouteTypes.CLIENT_ROUTES.PACKAGES_MANAGEMENT,
      icon: Icons.package,
    },
    {
      label: "users",
      router: RouteTypes.CLIENT_ROUTES.USER_MANAGEMENT,
      icon: Icons.users,
    },
    {
      label: "filters",
      router: RouteTypes.CLIENT_ROUTES.FILTERS,
      icon: Icons.filters,
    },
    {
      label: "logs",
      router: RouteTypes.CLIENT_ROUTES.LOGS,
      icon: Icons.logs,
    },
    {
      label: "contact",
      router: RouteTypes.CLIENT_ROUTES.CONTACT,
      icon: Icons.contact,
    },
  ],
};
