import ActionTypes from "constants/ActionTypes";
import API from "api";
import _ from "lodash";
import { responseAlert } from "libs/alert";
import history from "../history";

const clearRoles = () => {
  return {
    type: ActionTypes.CLEAR_ROLES,
  };
};

const clearRoleDetails = () => {
  return {
    type: ActionTypes.CLEAR_ROLE_DETAILS,
  };
};

const roleFail = () => {
  return {
    type: ActionTypes.ROLE_FAILURE,
  };
};

const roleLoading = () => {
  return {
    type: ActionTypes.ROLE_REQUEST,
  };
};

const roleSuccess = () => {
  return {
    type: ActionTypes.ROLE_SUCCESS,
  };
};

const createRoleSuccess = () => {
  return {
    type: ActionTypes.CREATE_ROLE_SUCCESS,
  };
};

const updateRoleSuccess = (payload = {}) => {
  return {
    type: ActionTypes.UPDATE_ROLE_SUCCESS,
    payload,
  };
};

const fetchRolesSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_ROLE_SUCCESS,
    payload,
  };
};

const fetchPrivilegesSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_PRIVILEGES_SUCCESS,
    payload,
  };
};

const getRoleByIdSuccess = (payload = {}) => {
  return {
    type: ActionTypes.GET_ROLE_BY_ID_SUCCESS,
    payload,
  };
};

const createRole = (data: any, filter: any) => {
  return async (dispatch: any) => {
    dispatch(roleLoading());
    await API.addRole(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(createRoleSuccess());
          await responseAlert({ res, label: "Role created" });
          history.push(filter);
        } else {
          responseAlert({ res });
          dispatch(roleFail());
        }
      })
      .catch((e: any) => {
        dispatch(roleFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchRoles = (data?: any) => {
  return async (dispatch: any) => {
    dispatch(roleLoading());
    await API.fetchRoles(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          const resolvePayload = _.filter(
            res?.payload,
            (item) => item.name !== "super admin"
          );
          dispatch(fetchRolesSuccess(resolvePayload));
        } else {
          responseAlert({ res });
          dispatch(roleFail());
        }
      })
      .catch((e: any) => {
        dispatch(roleFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getRoleByID = (id: string) => {
  return async (dispatch: any) => {
    dispatch(roleLoading());
    await API.getRoleByID(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(getRoleByIdSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(roleFail());
        }
      })
      .catch((e: any) => {
        dispatch(roleFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const searchRoles = (data: any) => {
  return async (dispatch: any) => {
    dispatch(roleLoading());
    await API.searchRoles(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          const resolvePayload = _.filter(
            res?.payload,
            (item) => item.name !== "super admin"
          );
          dispatch(fetchRolesSuccess(resolvePayload));
        } else {
          responseAlert({ res });
          dispatch(roleFail());
        }
      })
      .catch((e: any) => {
        dispatch(roleFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const updateRole = (data: any, id: any, filter: any) => {
  return async (dispatch: any) => {
    dispatch(roleLoading());
    await API.updateRole(data, id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(updateRoleSuccess(res.payload));
          await responseAlert({ res, label: "Role updated" });
          history.push(filter);
        } else {
          responseAlert({ res });
          dispatch(roleFail());
        }
      })
      .catch((e: any) => {
        dispatch(roleFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchPrivileges = () => {
  return async (dispatch: any) => {
    dispatch(roleLoading());
    await API.fetchPrivileges()
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchPrivilegesSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(roleFail());
        }
      })
      .catch((e: any) => {
        dispatch(roleFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const deactivateRole = (id: string) => {
  return async (dispatch: any) => {
    dispatch(roleLoading());
    await API.deactivateRole(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Role deactivated" });
          dispatch(roleSuccess());
        } else {
          responseAlert({ res });
          dispatch(roleFail());
        }
      })
      .catch((e: any) => {
        dispatch(roleFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const activateRole = (id: string) => {
  return async (dispatch: any) => {
    dispatch(roleLoading());
    await API.activateRole(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Role activated" });
          dispatch(roleSuccess());
        } else {
          responseAlert({ res });
          dispatch(roleFail());
        }
      })
      .catch((e: any) => {
        dispatch(roleFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

export {
  createRole,
  fetchRoles,
  getRoleByID,
  searchRoles,
  updateRole,
  fetchPrivileges,
  deactivateRole,
  activateRole,
  clearRoleDetails,
  clearRoles,
};
