import ActionTypes from "constants/ActionTypes";
import API from "api";
import history from "../history";
import _ from "lodash";
import { ROUTES } from "constants/Constants";
import { saveAs } from "file-saver";
import { responseAlert } from "libs/alert";
import RouteTypes from "constants/RouteTypes";
import { generateFilter, saveUserData, saveUserName } from "libs/Utils";

const setDefaultRegisterResult = () => {
  return {
    type: ActionTypes.SET_DEFAULT_REGISTER_RESULT,
  };
};

const setDefaultUpdatePackageCode = () => {
  return {
    type: ActionTypes.SET_DEFAULT_UPDATE_PACKAGE_CODE,
  };
};

const setUserFilter = (payload: any) => {
  return {
    type: ActionTypes.SET_USERS_FILTER,
    payload,
  };
};

const clearUserFilter = () => {
  return {
    type: ActionTypes.CLEAR_USERS_FILTER,
  };
};

const clearUserDetails = () => {
  return {
    type: ActionTypes.CLEAR_USER_DETAILS,
  };
};

const clearUserPayload = () => {
  return {
    type: ActionTypes.CLEAR_USERS_PAYLOAD,
  };
};

const userHasError = () => {
  return {
    type: ActionTypes.USER_FAILURE,
  };
};
const userSuccess = () => {
  return {
    type: ActionTypes.USER_SUCCESS,
  };
};

const registerSuccess = () => {
  return {
    type: ActionTypes.REGISTER_USER_SUCCESS,
  };
};

const registerFailure = () => {
  return {
    type: ActionTypes.REGISTER_USER_FAILURE,
  };
};

const changePasswordSuccess = () => {
  return {
    type: ActionTypes.RESET_PASSWORD_SUCCESS,
  };
};

const verifySuccess = () => {
  return {
    type: ActionTypes.VERIFY_SUCCESS,
  };
};

const forgotPasswordSuccess = () => {
  return {
    type: ActionTypes.FORGOT_PASSWORD_SUCCESS,
  };
};

const userIsLoading = (boolen = false) => {
  return {
    type: ActionTypes.USER_REQUEST,
  };
};

const fetchUserClientSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_USERS_CLIENT_SUCCESS,
    payload,
  };
};

const fetchUserSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_USER_SUCCESS,
    payload,
  };
};

const getUserByIdSuccess = (payload = {}) => {
  return {
    type: ActionTypes.GET_USER_BY_ID_SUCCESS,
    payload,
  };
};

const updateUserForSelfSuccess = (payload = {}) => {
  return {
    type: ActionTypes.UPDATE_USER_FOR_SELF_SUCCESS,
    payload,
  };
};

const updateUserSuccess = () => {
  return {
    type: ActionTypes.UPDATE_USER_SUCCESS,
  };
};

const updateUserPackageSuccess = () => {
  return {
    type: ActionTypes.UPDATE_USER_PACKAGE_SUCCESS,
  };
};

const register = (data: any, isShowMessage?: boolean) => {
  return async (dispatch: any) => {
    dispatch(userIsLoading(true));
    await API.register(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          await dispatch(registerSuccess());
          if (isShowMessage)
            responseAlert({
              message: "Please check your email for confirmation!",
              res,
              label: "Registered",
            });
          else
            responseAlert({
              res,
              label: "Registered",
            });
        } else {
          await dispatch(registerFailure());
          responseAlert({
            message: _.get(res, "message"),
          });
        }
      })
      .catch((e: any) => {
        if (!_.isEmpty(e)) responseAlert({ res: e });
        dispatch(registerFailure());
      });
  };
};

const forgotPassword = (data: any) => {
  return async (dispatch: any) => {
    dispatch(userIsLoading(true));
    await API.forgotPassword(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(forgotPasswordSuccess());
          responseAlert({ res, label: "Please check your mail" });
        } else {
          responseAlert({ res });
          dispatch(userHasError());
        }
      })
      .catch((e: any) => {
        if (!_.isEmpty(e)) responseAlert({ res: e });
        dispatch(userHasError());
      });
  };
};

const resetPassword = (data: any) => {
  return async (dispatch: any) => {
    dispatch(userIsLoading(true));
    await API.resetPassword(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(changePasswordSuccess());
          responseAlert({ res, label: "Reset password" });
          history.push(ROUTES.HOMEPAGE);
        } else {
          responseAlert({ res });
          dispatch(userHasError());
        }
      })
      .catch((e: any) => {
        if (!_.isEmpty(e)) responseAlert({ res: e });
        dispatch(userHasError());
      });
  };
};

const verifyAccount = (data: any) => {
  return async (dispatch: any) => {
    dispatch(userIsLoading(true));
    await API.verifyAccount(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(verifySuccess());
          responseAlert({ res, label: "Verify account" });
          history.push(RouteTypes.USER_ROUTES.USER_ARTICLES);
        } else {
          if (!_.isEmpty(res)) responseAlert({ res });
          dispatch(userHasError());
          history.push(RouteTypes.USER_ROUTES.USER_ARTICLES);
        }
      })
      .catch((e: any) => {
        if (!_.isEmpty(e)) responseAlert({ res: e });
        dispatch(userHasError());
      });
  };
};

const fetchUsers = (data: any) => {
  return async (dispatch: any) => {
    dispatch(userIsLoading());
    await API.fetchUsers(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchUserSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(userHasError());
        }
      })
      .catch((e: any) => {
        dispatch(userHasError());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const registerUser = (data: any) => {
  return async (dispatch: any) => {
    dispatch(userIsLoading(true));
    await API.registerUser(data)
      .then(async (res: any) => {
        if (res.status === true) {
          await responseAlert({ res, label: "User created" });
          dispatch(registerSuccess());
        } else {
          await responseAlert({ res });
          dispatch(registerFailure());
        }
      })
      .catch((e: any) => {
        if (!_.isEmpty(e)) responseAlert({ res: e });
        dispatch(userHasError());
      });
  };
};

const searchUsers = (data: any) => {
  return async (dispatch: any) => {
    dispatch(userIsLoading(true));
    await API.fetchUsersClient(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchUserClientSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(userHasError());
        }
      })
      .catch((e: any) => {
        dispatch(userHasError());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const registerUserAndFetch = (data: any, id: string) => {
  return async (dispatch: any) => {
    dispatch(userIsLoading(true));
    await API.registerUser(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(registerSuccess());
          dispatch(searchUsers({ clientId: id, limit: 100 }));
          responseAlert({ res, label: "User created" });
        } else {
          if (!_.isEmpty(res)) responseAlert({ res });
          dispatch(userHasError());
        }
      })
      .catch((e: any) => {
        if (!_.isEmpty(e)) responseAlert({ res: e });
        dispatch(userHasError());
      });
  };
};

const updateUser = (data: any, id: any, isCurrentUser?: boolean) => {
  return async (dispatch: any) => {
    dispatch(userIsLoading());
    await API.updateUser(data, id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          if (isCurrentUser) {
            const payload = _.get(res, "payload");
            const omitUserData = _.omit(payload, [
              "clientId",
              "password",
              "userRequest",
            ]);
            await saveUserName(_.get(omitUserData, "email"));
            await saveUserData({ ...omitUserData });
          }
          await responseAlert({ res, label: "User updated" });
          dispatch(updateUserSuccess());
        } else {
          responseAlert({ res });
          dispatch(userHasError());
        }
      })
      .catch((e: any) => {
        dispatch(userHasError());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const updateAccountListUser = (id: string, data: any) => {
  return async (dispatch: any) => {
    dispatch(userIsLoading());
    await API.updateAccountListUser(id, data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "User updated" });
          dispatch(updateUserPackageSuccess());
        } else {
          responseAlert({ res });
          dispatch(userHasError());
        }
      })
      .catch((e: any) => {
        dispatch(userHasError());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const deactivateUser = (id: string) => {
  return async (dispatch: any) => {
    dispatch(userIsLoading());
    await API.deactivateUser(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "User deactivated" });
          dispatch(userSuccess());
        } else {
          responseAlert({ res });
          dispatch(userHasError());
        }
      })
      .catch((e: any) => {
        dispatch(userHasError());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const activateUser = (id: string) => {
  return async (dispatch: any) => {
    dispatch(userIsLoading());
    await API.activateUser(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "User activated" });
          dispatch(userSuccess());
        } else {
          responseAlert({ res });
          dispatch(userHasError());
        }
      })
      .catch((e: any) => {
        dispatch(userHasError());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const exportUsers = () => {
  return async (dispatch: any) => {
    dispatch(userIsLoading());
    await API.exportUsers()
      .then(async (res: any) => {
        saveAs(res, "users.xlsx");
        dispatch(userSuccess());
      })
      .catch((e: any) => {
        dispatch(userHasError());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getUserById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(userIsLoading());
    await API.getUserById(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(getUserByIdSuccess(_.get(res, "payload")));
        else {
          responseAlert({ res });
          dispatch(userHasError());
        }
      })
      .catch((e: any) => {
        dispatch(userHasError());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const updateUserForSelf = (data: any) => {
  return async (dispatch: any) => {
    dispatch(userIsLoading());
    await API.updateUserForSelf(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          const payload = _.get(res, "payload");
          const omitUserData = _.omit(payload, ["password", "userRequest"]);
          await saveUserData({ ...omitUserData });
          responseAlert({ res, label: "Profile updated" });
          dispatch(updateUserForSelfSuccess());
        } else {
          responseAlert({ res });
          dispatch(userHasError());
        }
      })
      .catch((e: any) => {
        dispatch(userHasError());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const deleteUser = (id: string, filter: any) => {
  return async (dispatch: any) => {
    dispatch(userIsLoading());
    await API.deleteUser(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status)
          dispatch(
            fetchUsers(
              generateFilter({
                ...filter,
                userType: filter.userType === "all" ? "" : filter.userType,
              })
            )
          );
        else {
          responseAlert({ res });
          dispatch(userHasError());
        }
      })
      .catch((e: any) => {
        dispatch(userHasError());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

export {
  register,
  forgotPassword,
  resetPassword,
  verifyAccount,
  registerUser,
  registerUserAndFetch,
  updateUser,
  updateAccountListUser,
  searchUsers,
  fetchUsers,
  deactivateUser,
  activateUser,
  exportUsers,
  setUserFilter,
  clearUserFilter,
  getUserById,
  clearUserPayload,
  clearUserDetails,
  setDefaultRegisterResult,
  setDefaultUpdatePackageCode,
  updateUserForSelf,
  deleteUser,
};
