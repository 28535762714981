import React, { useRef } from "react";
import { ContainerPopup, Content } from "./Disconnect.styles";

const Disconnect: React.FC = () => {
  const popupRef = useRef<HTMLHeadingElement>(null);
  return (
    <ContainerPopup>
      <Content ref={popupRef}>
        <div className="content">
          The server is being restarted, Please wait a minutes...
        </div>
      </Content>
    </ContainerPopup>
  );
};

export default Disconnect;
