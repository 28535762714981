import _ from "lodash";
import { ChartData, ChartDataset } from "chart.js";
import dayjs from "dayjs";

const generateRandomColor = (size: number) => {
  const genRanHex = [...Array(size)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join("");
  return `#${genRanHex}`;
};

const generateCoverageByMediaType = (
  params: any,
  payload: any
): ChartData<"bar"> => {
  const { mediaTypes } = params;
  const mediaTypeToArr = _.orderBy(mediaTypes.split(","), "asc");
  const labels: string[] = _.map(mediaTypeToArr, (type) => _.toUpper(type));

  const datasets: ChartDataset<"bar">[] = [
    {
      label: "",
      backgroundColor: _.map(mediaTypeToArr, () => generateRandomColor(6)),
      data: _.map(
        mediaTypeToArr,
        (type) => _.filter(payload, ["source.mediaType", type]).length
      ),
    },
  ];
  return {
    labels,
    datasets,
  };
};

const generateMediaReach = (params: any, payload: any): ChartData<"bar"> => {
  const { mediaTypes } = params;
  const mediaTypeToArr = mediaTypes.split(",");
  const resolvePayloadDate = _.map(
    _.orderBy(payload, "createdAt", "asc"),
    (item) => {
      return {
        ...item,
        createdAt: dayjs(item?.createdAt.split(",")[0]).format("DD/MM/YYYY"),
      };
    }
  );
  const groupedData = _.groupBy(resolvePayloadDate, "createdAt");
  const labels: string[] = _.keys(groupedData);

  const datasets: ChartDataset<"bar">[] = [];
  _.forEach(mediaTypeToArr, (mediaType) => {
    datasets.push({
      label: _.toUpper(mediaType),
      data: _.map(
        groupedData,
        (date) => _.filter(date, ["source.mediaType", mediaType]).length
      ),
      backgroundColor: generateRandomColor(6),
    });
  });

  return {
    labels,
    datasets,
  };
};

const generateTopAuthors = (params: any, payload: any): ChartData<"bar"> => {
  const { topAuthors } = params;

  const groupedData = _.groupBy(payload, "author");
  const sortedData = _.orderBy(groupedData, "length", "desc");
  const slicedData = _.slice(sortedData, 0, topAuthors || 10);
  const resolvedData: any = {};
  _.forEach(slicedData, (item: any) => {
    resolvedData[item[0]?.author] = item;
  });
  const labels: string[] = _.keys(resolvedData);
  const datasets: ChartDataset<"bar">[] = [
    {
      label: "",
      backgroundColor: _.map(resolvedData, () => generateRandomColor(6)),
      data: _.map(resolvedData, (item: any) => item.length),
    },
  ];
  return {
    labels,
    datasets,
  };
};

const generateTopSources = (params: any, payload: any): ChartData<"bar"> => {
  const { topSources } = params;

  const groupedData = _.groupBy(payload, "source.name");
  const sortedData = _.orderBy(groupedData, "length", "desc");
  const slicedData = _.slice(sortedData, 0, topSources || 10);
  const resolvedData: any = {};
  _.forEach(slicedData, (item: any) => {
    resolvedData[item[0]?.source?.name] = item;
  });
  const labels: string[] = _.keys(resolvedData);
  const datasets: ChartDataset<"bar">[] = [
    {
      label: "",
      backgroundColor: _.map(resolvedData, () => generateRandomColor(6)),
      data: _.map(resolvedData, (item: any) => item.length),
    },
  ];
  return {
    labels,
    datasets,
  };
};

const generateStoriesOverTime = (
  params: any,
  payload: any
): ChartData<"bar"> => {
  const resolvePayloadDate = _.map(
    _.orderBy(payload, "createdAt", "asc"),
    (item) => {
      return {
        ...item,
        createdAt: dayjs(item?.createdAt.split(",")[0]).format("DD/MM/YYYY"),
      };
    }
  );
  const groupedData = _.groupBy(resolvePayloadDate, "createdAt");
  const labels: string[] = _.keys(groupedData);

  const datasets: ChartDataset<"bar">[] = [
    {
      label: "",
      backgroundColor: _.map(groupedData, () => generateRandomColor(6)),
      data: _.map(groupedData, (item: any) => item.length),
    },
  ];
  return {
    labels,
    datasets,
  };
};

const generateLatestStories = (payload: any) => {
  const sortedPayload = _.orderBy(payload, "createdAt", "desc");
  return _.slice(sortedPayload, 0, 5);
};

export {
  generateCoverageByMediaType,
  generateMediaReach,
  generateTopAuthors,
  generateTopSources,
  generateStoriesOverTime,
  generateLatestStories,
};
