/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { ContainerDropdown } from "./Dropdown.styles";
import Select from "react-select";
// import Multiselect from "multiselect-react-dropdown";

interface SectionProps {
  options: Array<any>;
  margin?: string;
  width?: string;
  placeholder?: string;
  callbackPayload?: (payload?: any) => void;
  value?: any;
  search?: boolean;
  multiple?: boolean;
  isRequired?: boolean;
  isUp?: boolean;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
}

const Dropdown: React.FC<SectionProps> = (props: SectionProps) => {
  const {
    callbackPayload,
    options,
    margin,
    width,
    placeholder,
    value,
    search = true,
    multiple = false,
    isRequired = false,
    isUp,
    disabled,
    loading,
  } = props;
  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  useEffect(() => {
    const reactSelectContainer = document.querySelector(
      ".react-select__value-container"
    );
    if (reactSelectContainer) {
      reactSelectContainer.addEventListener("wheel", (event: any) => {
        event.preventDefault();
        reactSelectContainer.scrollLeft += event.deltaY;
      });
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(value)) {
      if (multiple) {
        const resolveSelectedOptions: { label: string; value: string }[] = [];
        _.forEach(value, (item) => {
          const findOption = _.find(options, { value: item });
          if (findOption) resolveSelectedOptions.push(findOption);
        });
        setSelectedOptions(resolveSelectedOptions);
      } else {
        const findOption = _.find(options, { value });
        setSelectedOptions(findOption);
      }
    } else setSelectedOptions([]);
  }, [value, options]);

  const handleChange = (newSelectedOptions: any) => {
    if (callbackPayload) {
      if (multiple) {
        const newValue: string | string[] = [];
        _.forEach(newSelectedOptions, (option) =>
          newValue.push(_.get(option, "value"))
        );
        return callbackPayload(newValue);
      } else callbackPayload(_.get(newSelectedOptions, "value"));
    }
  };

  const filterOptions = (
    candidate: { label: string; value: string; data: any },
    input: string
  ) => {
    if (input) {
      return _.includes(_.lowerCase(candidate.label), _.lowerCase(input));
    }
    return true;
  };

  return (
    <ContainerDropdown
      className={`${disabled ? "disabled" : ""} ${isUp ? "up" : ""}`}
      margin={margin}
      width={width}
      isRequired={isRequired}
    >
      {/* <Multiselect
        options={options}
        showCheckbox={multiple}
        disable={disabled}
        singleSelect={!multiple}
        displayValue="label"
      /> */}
      <Select
        options={options}
        value={selectedOptions}
        isLoading={loading}
        isDisabled={disabled}
        isSearchable={search}
        onChange={handleChange}
        isMulti={multiple}
        className="react-select-container"
        classNamePrefix="react-select"
        closeMenuOnSelect={!multiple}
        placeholder={placeholder}
        maxMenuHeight={200}
        menuPlacement={isUp ? "top" : "bottom"}
        filterOption={filterOptions}
        controlShouldRenderValue={true}
        hideSelectedOptions={false}
        // formatOptionLabel={(e: any) => (
        //   <div style={{ display: "flex", alignItems: "center" }}>
        //     <SquareBox></SquareBox>
        //     <span style={{ marginLeft: 5 }}>{e.label}</span>
        //   </div>
        // )}
      />
    </ContainerDropdown>
  );
};

export default Dropdown;
