/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ComponentInput, InputMain, EyeButton } from "./Input.styles";
import trim from "lodash/trim";
import { useTranslation } from "react-i18next";

interface SectionProps {
  margin?: string;
  id?: string;
  width?: string;
  placeholder?: string;
  type?: string;
  value?: any;
  ref?: any;
  onChangeHandler?: (e: any) => void;
  onKeyPress?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  isRequired?: boolean;
  disabled?: boolean;
  accept?: string;
  isNum?: boolean;
  onEnter?(): void;
  defaultValue?: string;
  maxLength?: number;
}

const Input: React.FC<SectionProps> = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const {
    margin,
    width,
    placeholder,
    type = "text",
    value,
    onChangeHandler,
    isRequired = false,
    id,
    ref,
    disabled = false,
    accept,
    isNum,
    defaultValue,
    onEnter,
    maxLength = 32,
  } = props;
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [inputType, setInputType] = useState<string>("");
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false);

  useEffect(() => {
    setInputType(type);
  }, []);

  const onChangeInput = (e: any) => {
    if (onChangeHandler) onChangeHandler(e);
  };

  return (
    <ComponentInput
      width={width}
      margin={margin}
      data-tip={`${t("label.limitTextboxCharacters")} ${maxLength} ${t(
        "label.characters"
      )}`}
      className={`${disabled ? "disabled" : ""} ${
        isShowTooltip ? "show-tooltip" : ""
      }`}
    >
      <InputMain
        type={inputType}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={(e) => {}}
        value={value}
        isRequired={isRequired}
        id={id}
        onInput={(event: any) => {
          const textLength = event.target.value.length;
          if (textLength < maxLength) {
            if (isShowTooltip) setIsShowTooltip(false);
            const isValid = event.target.validity.valid;
            if (isNum) {
              if (isValid) onChangeInput(event);
              else event.preventDefault();
            } else onChangeInput(event);
          } else setIsShowTooltip(true);
        }}
        onKeyDown={(event: any) =>
          event.keyCode === 13 &&
          trim(event.target.value) &&
          onEnter &&
          onEnter()
        }
        ref={ref}
        disabled={disabled}
        accept={accept}
        pattern={isNum ? "[0-9]*" : ""}
        autoComplete="off"
        autoCorrect="off"
      />
      {type === "password" && (
        <EyeButton>
          <i
            className={`fas ${isShowPassword ? "fa-eye" : "fa-eye-slash"} ${
              disabled ? "disabled" : ""
            }`}
            onClick={() => {
              if (disabled) return;
              setIsShowPassword(!isShowPassword);
              setInputType(!isShowPassword ? "text" : "password");
            }}
          ></i>
        </EyeButton>
      )}
    </ComponentInput>
  );
};

export default Input;
