import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";

import { RootState } from "reducers";

import { generateDropdown } from "libs/Utils";

import { Button, Dropdown, SelectDatetime, Radio } from "components/Common";
import {
  TemplateDetailsContainer,
  TemplateDetailsHeader,
  TemplateDetailsContent,
  ButtonField,
  TemplateDetailsForm,
} from "../Popup.styles";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

interface FilterData {
  dateFrom: string;
  dateTo: string;
  filterIds: string[] | [];
  mediaType: string[] | [];
  sortBy: string;
  status: string;
}

interface SectionProps {
  onClose(newFilter?: FilterData): void;
  // onReset(): void;
  filterData: FilterData;
  accountId: string[];
}

const defaultFilterPost = {
  dateFrom: `${dayjs().subtract(7, "day").format("YYYY/MM/DD")} 00:00:00`,
  dateTo: `${dayjs().format("YYYY/MM/DD")} 23:59:59`,
  filterIds: [],
  mediaType: ["print", "web", "tv", "social network"],
  sortBy: "author",
  status: "all",
};

const SORT_BY = [
  {
    label: "author",
    value: "author",
  },
  {
    label: "headline",
    value: "title",
  },
  {
    label: "newest",
    value: "newest",
  },
  {
    label: "oldest",
    value: "oldest",
  },
];

const FilterPost = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { onClose, filterData, accountId } = props;
  const filters = useSelector((state: RootState) => {
    return state.Filters.filters;
  });
  const [filter, setFilter] = useState<FilterData>(defaultFilterPost);
  const [filterOptions, setFilterOptions] = useState<any[]>([]);

  useEffect(() => {
    const getFilters = _.get(filters, "items");
    if (!_.isEmpty(getFilters)) {
      const resolveFilters = _.filter(
        getFilters,
        (item) => _.includes(accountId, _.get(item, "accountId.id")) && item
      );
      setFilterOptions(
        generateDropdown({
          data: resolveFilters,
          key: "filterName",
          value: "id",
        })
      );
    }
  }, [filters]);

  useEffect(() => {
    if (!_.isEmpty(accountId)) {
      const getFilters = _.get(filters, "items");
      const resolveFilters: string[] = [];
      _.forEach(getFilters, (filter) => {
        const filterBelongAccount = _.get(filter, "accountId.id");
        const filterId = _.get(filter, "id");
        if (_.includes(accountId, filterBelongAccount))
          resolveFilters.push(filterId);
      });
      setFilter({ ...filter, ...filterData, filterIds: resolveFilters });
    }
  }, [accountId]);

  const onChangeFilter = (key: string, value: string) => {
    const newFilter = {
      ...filter,
      [key]: value,
    };
    if (key === "dateFrom" || key === "dateTo")
      _.assign(newFilter, {
        [key]: `${dayjs(value).format("YYYY/MM/DD")} 00:00:00`,
      });

    setFilter(newFilter);
  };

  const renderMain = () => {
    return (
      <TemplateDetailsContainer>
        <TemplateDetailsContent width="900px">
          <TemplateDetailsHeader>
            {t("headerPopup.filterPost")}
          </TemplateDetailsHeader>
          <TemplateDetailsForm height="450px" overflow="visible">
            <div className="form-group-row align-start">
              <div className="form-group column">
                <span>{t("label.date")}</span>
                <SelectDatetime
                  callbackPayload={(e) => onChangeFilter("dateFrom", e)}
                  defaultValue={_.get(filter, "dateFrom")}
                  placeholder={t("label.dateFrom")}
                  margin="10px 20px 10px 0px"
                  width="200px"
                />
                <SelectDatetime
                  callbackPayload={(e) => onChangeFilter("dateTo", e)}
                  defaultValue={_.get(filter, "dateTo")}
                  placeholder={t("label.dateTo")}
                  margin="10px 20px 10px 0"
                  width="200px"
                />
              </div>
              <div className="form-group column">
                <div className="form-group column">
                  <span>{t("label.filters")}</span>
                  <Dropdown
                    value={_.get(filter, "filterIds")}
                    margin="10px 20px 10px 0"
                    callbackPayload={(e) => onChangeFilter("filterIds", e)}
                    options={filterOptions}
                    placeholder={t("placeholder.filter")}
                    width="200px"
                    multiple={true}
                    search={true}
                  />
                </div>
                <div className="form-group column">
                  <span>{t("label.mediaType")}</span>
                  <Dropdown
                    value={_.get(filter, "mediaType")}
                    margin="10px 20px 10px 0"
                    callbackPayload={(e) => onChangeFilter("mediaType", e)}
                    options={[
                      { label: t("dropdown.print"), value: "print" },
                      { label: t("dropdown.tv"), value: "tv" },
                      { label: t("dropdown.web"), value: "web" },
                      {
                        label: t("dropdown.socialNetwork"),
                        value: "social network",
                      },
                    ]}
                    placeholder={t("placeholder.mediaType")}
                    width="200px"
                    multiple={true}
                    search={true}
                  />
                </div>
              </div>
              <div className="form-group column">
                <span>{t("label.status")}</span>
                <Dropdown
                  value={_.get(filter, "status")}
                  margin="10px 20px 10px 0"
                  callbackPayload={(e) => onChangeFilter("status", e)}
                  options={[
                    { label: t("dropdown.all"), value: "all" },
                    { label: t("dropdown.active"), value: "active" },
                    { label: t("dropdown.inactive"), value: "inactive" },
                  ]}
                  placeholder={t("placeholder.status")}
                  width="200px"
                />
              </div>
              <div className="form-group column">
                <span>{t("label.sortBy")}</span>
                {_.map(SORT_BY, (item) => {
                  const sortBy = _.get(filter, "sortBy");
                  const checked = item.value === sortBy;
                  return (
                    <Radio
                      key={`radio-${item.value}`}
                      checked={checked}
                      context={t(`label.${item.label}`)}
                      onChangeHandler={() =>
                        onChangeFilter("sortBy", item.value)
                      }
                    />
                  );
                })}
              </div>
            </div>
          </TemplateDetailsForm>
          <ButtonField>
            <Button
              type="submit"
              buttonWidth="100px"
              onClick={() => onClose && onClose(filter)}
            >
              {t("button.save")}
            </Button>
            <Button
              onClick={() => setFilter(defaultFilterPost)}
              type="submit"
              buttonWidth="100px"
              buttonMargin="0 0 0 10px"
            >
              {t("button.reset")}
            </Button>
            <Button
              onClick={() => onClose()}
              type="submit"
              buttonWidth="100px"
              buttonMargin="0 0 0 10px"
            >
              {t("button.cancel")}
            </Button>
          </ButtonField>
        </TemplateDetailsContent>
      </TemplateDetailsContainer>
    );
  };
  return renderMain();
};

export default FilterPost;
