import styled from "styled-components";
import ColorName from "constants/Variables";

const EmailEditorContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmailEditorContent = styled.div`
  background: ${ColorName.white};
  position: relative;
  transition: all ease 0.25s;
`;

const EmailEditorHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${ColorName.william};
  color: white;
  font-size: 15px;
  height: 35px;
`;

const EmailEditorButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${ColorName.linkWater};
`;

const VariablesContainer = styled.div`
  position: absolute;
  top: 0;
  left: calc(100% + 10px);
  padding: 10px 15px;
  border: 1px solid ${ColorName.william};
  background: ${ColorName.linkWater};
  width: 175px;

  b {
    color: ${ColorName.william};
  }
`;

const VariablesItem = styled.div`
  border-bottom: 1px solid ${ColorName.william};
  color: ${ColorName.william};
  padding: 6px 0;
  position: relative;
  &:last-child {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
`;

export {
  EmailEditorContainer,
  EmailEditorHeader,
  EmailEditorButton,
  EmailEditorContent,
  VariablesContainer,
  VariablesItem,
};
