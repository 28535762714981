import styled from "styled-components";
import ColorName from "constants/Variables";

interface SectionProps {
  margin?: string;
  width?: string;
  isRequired?: boolean;
}

// const SquareBox = styled.div`
//   width: 12px;
//   height: 12px;
//   border-radius: 5px;
//   border: 1px solid black;
// `;

const ContainerDropdown = styled.div`
  margin: ${(props: SectionProps) => props.margin || "unset"};
  width: ${(props: SectionProps) => props.width || "unset"};
  height: max-content;
  /* border: 1px solid
    ${(props: SectionProps) =>
    props.isRequired ? "#f85353" : ColorName.linkWater};
  box-shadow: 0px 0px 5px
    ${(props: SectionProps) =>
    props.isRequired ? "#f85353" : "rgb(62 138 182 / 25%)"}; */
  font-size: 14px;

  &.disabled {
    user-select: none;
    &:hover {
      /* border: 1px solid ${ColorName.linkWater}; */
      /* box-shadow: 0px 0px 2px rgb(62 138 182 / 25%); */
      .select-search:not(.select-search--multiple) .select-search__input:hover {
        border-color: transparent;
        cursor: not-allowed;
      }
    }
  }

  & .select-search {
    & .select-search__select {
      display: none;
    }
    &.has-focus {
      & .select-search__select {
        display: block;
      }
    }
  }
  &.up .select-search__select {
    top: unset !important;
    bottom: 44px;
  }
  .select-search {
    position: relative;
    font-family: "Be Vietnam", sans-serif;
    box-sizing: border-box;
  }

  .react-select-container {
    .react-select__value-container--is-multi.react-select__value-container--has-value {
      flex-wrap: nowrap;
      overflow: unset;
      overflow-x: auto;
    }
    .react-select__control {
      height: 40px;
      border-color: ${(props: SectionProps) =>
        props.isRequired ? "#f85353" : ColorName.linkWater};
      box-shadow: 0px 0px 5px
        ${(props: SectionProps) =>
          props.isRequired ? "#f85353" : "rgb(62 138 182 / 25%)"};
      font-weight: 400;
    }
    .react-select__menu {
      z-index: 9;
    }
    .react-select__control--is-focused {
      border-color: ${ColorName.william};
      /* box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15); */
    }

    .react-select__option {
      &:hover {
        cursor: pointer;
        background: ${ColorName.linkWater};
        color: ${ColorName.william};
      }
    }
    .react-select__option--is-selected {
      background: ${ColorName.william};
      color: ${ColorName.white};
      &:hover {
        background: ${ColorName.william};
        color: ${ColorName.white};
      }
    }
    .react-select__multi-value {
      min-width: unset;
    }
    .react-select__single-value {
      font-weight: 400;
      color: ${ColorName.william};
    }
    .react-select__multi-value__remove {
      &:hover {
        cursor: pointer;
      }
    }
    .react-select__clear-indicator:hover {
      cursor: pointer;
    }
    .react-select__indicator-separator {
      display: none;
    }
  }

  .select-search *,
  .select-search *::after,
  .select-search *::before {
    box-sizing: inherit;
  }

  .select-search__value {
    position: relative;
    z-index: 1;
  }

  .select-search__value::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
  }

  .select-search__input {
    display: block;
    height: 40px;
    width: 100%;
    padding: 0 40px 0 16px;
    background: #fff;
    border: 1px solid transparent;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    outline: none;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
  }

  .select-search__input::-webkit-search-decoration,
  .select-search__input::-webkit-search-cancel-button,
  .select-search__input::-webkit-search-results-button,
  .select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .select-search__input:not([readonly]):focus {
    cursor: initial;
  }

  .select-search__select {
    background: #fff;
    border: 1px solid ${ColorName.william};
    box-shadow: 0px 0px 2px rgb(62 138 182 / 25%);
    max-height: 200px;
  }

  .select-search__options {
    list-style: none;
    padding: 0;
  }

  .select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
  }

  .select-search__option,
  .select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
  }

  .select-search--multiple .select-search__option {
    height: 48px;
  }

  .select-search__option.is-selected {
    background: ${ColorName.william};
    color: #fff;
  }

  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
  }

  .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    background: ${ColorName.william};
    color: #fff;
  }

  .select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
  }

  .select-search.is-disabled {
    opacity: 0.5;
  }

  .select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 16px;
    width: 16px;
    height: 16px;
  }

  .select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
  }

  .select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
  }

  .select-search:not(.is-loading):not(.select-search--multiple)
    .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
  }

  .select-search--multiple .select-search__input {
    cursor: initial;
  }

  .select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
  }

  .select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
  }

  .select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: ${ColorName.william};
  }

  .select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 200px;
  }

  .select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
  }

  .select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
  }
`;

export { ContainerDropdown };
