import ActionTypes from "constants/ActionTypes";
import API from "api";
import _ from "lodash";
import { responseAlert } from "libs/alert";
import history from "../history";

const setDefaultPackageStatus = () => {
  return {
    type: ActionTypes.SET_DEFAULT_ACCOUNT_STATUS,
  };
};

const clearPackageDetails = () => {
  return {
    type: ActionTypes.CLEAR_PACKAGE_DETAILS,
  };
};

const clearPackages = () => {
  return {
    type: ActionTypes.CLEAR_PACKAGES,
  };
};

const accountFail = () => {
  return {
    type: ActionTypes.ACCOUNT_FAILURE,
  };
};

const accountSuccess = () => {
  return {
    type: ActionTypes.ACCOUNT_SUCCESS,
  };
};

const accountLoading = () => {
  return {
    type: ActionTypes.ACCOUNT_REQUEST,
  };
};

const createAccountSuccess = () => {
  return {
    type: ActionTypes.CREATE_ACCOUNT_SUCCESS,
  };
};

const updateAccountSuccess = (payload = {}) => {
  return {
    type: ActionTypes.UPDATE_ACCOUNT_SUCCESS,
    payload,
  };
};

const fetchAccountsSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_ACCOUNT_SUCCESS,
    payload,
  };
};

const fetchUserForAccountSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_USERS_FOR_ACCOUNT_SUCCESS,
    payload,
  };
};

const searchAccountsSuccess = (payload = {}) => {
  return {
    type: ActionTypes.SEARCH_ACCOUNT_SUCCESS,
    payload,
  };
};

const getAccountByIdSuccess = (payload = {}) => {
  return {
    type: ActionTypes.GET_ACCOUNT_BY_ID_SUCCESS,
    payload,
  };
};

const fetchPackageForUserPortalSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_PACKAGES_FOR_USER_PORTAL_SUCCESS,
    payload,
  };
};

const fetchPackagesForUserPortal = (clientId: string) => {
  return async (dispatch: any) => {
    dispatch(accountLoading());
    await API.fetchPackageForUserPortal(clientId)
      .then(async (res: any) => {
        const { status } = res;
        if (status)
          dispatch(fetchPackageForUserPortalSuccess(_.get(res, "payload")));
        else {
          responseAlert({ res });
          dispatch(accountFail());
        }
      })
      .catch((e: any) => {
        dispatch(accountFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const createAccount = (data: any, filter?: any) => {
  return async (dispatch: any) => {
    dispatch(accountLoading());
    await API.addAccount(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(createAccountSuccess());
          await responseAlert({ label: "Account created", res });
          if (filter) history.push(filter);
        } else {
          responseAlert({ res });
          dispatch(accountFail());
        }
      })
      .catch((e: any) => {
        dispatch(accountFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchAccounts = () => {
  return async (dispatch: any) => {
    dispatch(accountLoading());
    await API.fetchAccounts()
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchAccountsSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(accountFail());
        }
      })
      .catch((e: any) => {
        dispatch(accountFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchUserForAccount = (data: any) => {
  return async (dispatch: any) => {
    dispatch(accountLoading());
    await API.fetchUserForAccount(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchUserForAccountSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(accountFail());
        }
      })
      .catch((e: any) => {
        dispatch(accountFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getAccountByID = (id: string) => {
  return async (dispatch: any) => {
    dispatch(accountLoading());
    await API.getAccountByID(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(getAccountByIdSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(accountFail());
        }
      })
      .catch((e: any) => {
        dispatch(accountFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const searchAccounts = (data: any) => {
  return async (dispatch: any) => {
    dispatch(accountLoading());
    await API.searchAccounts(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(searchAccountsSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(accountFail());
        }
      })
      .catch((e: any) => {
        dispatch(accountFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const updateAccount = (data: any, id: any, filter: any) => {
  return async (dispatch: any) => {
    dispatch(accountLoading());
    await API.updateAccount(data, id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          dispatch(updateAccountSuccess(res.payload));
          await responseAlert({ res, label: "Account updated" });
          history.push(filter);
        } else {
          responseAlert({ res });
          dispatch(accountFail());
        }
      })
      .catch((e: any) => {
        dispatch(accountFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const deactivateAccount = (id: string) => {
  return async (dispatch: any) => {
    dispatch(accountLoading());
    await API.deactivateAccount(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "Account deactivated" });
          dispatch(accountSuccess());
        } else {
          responseAlert({ res });
          dispatch(accountFail());
        }
      })
      .catch((e: any) => {
        dispatch(accountFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const activateAccount = (id: string) => {
  return async (dispatch: any) => {
    dispatch(accountLoading());
    await API.activateAccount(id)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          responseAlert({ res, label: "account activated" });
          dispatch(accountSuccess());
        } else {
          responseAlert({ res });
          dispatch(accountFail());
        }
      })
      .catch((e: any) => {
        dispatch(accountFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

export {
  setDefaultPackageStatus,
  createAccount,
  fetchAccounts,
  getAccountByID,
  searchAccounts,
  updateAccount,
  fetchUserForAccount,
  deactivateAccount,
  activateAccount,
  clearPackageDetails,
  fetchPackagesForUserPortal,
  clearPackages,
};
