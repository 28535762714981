/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";

import {
  Button,
  Input,
  FormGroup,
  FormLabel,
  Textarea,
  LoadingLayer,
  Spinner,
} from "components/Common";
import {
  TemplateDetailsContainer,
  TemplateDetailsContent,
  TemplateDetailsHeader,
  ButtonField,
  TemplateDetailsForm,
} from "../Popup.styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DataRecordsActions } from "actions";
import { popupAlert } from "../../../../libs/Utils";

interface SectionProps {
  onClose(): void;
  checkedData: any;
}

const { sendCheckedPostToMail } = DataRecordsActions;

const regexEmail =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const SendMail = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const isLoading = useSelector((state) =>
    _.get(state, "DataRecord.isLoading")
  );

  const { onClose, checkedData } = props;

  const formRef = useRef<HTMLDivElement>(null);

  const [data, setData] = useState<any>({
    recordIds: [],
    emails: [],
    subject: "",
  });

  useEffect(() => {
    if (!_.isEmpty(checkedData)) {
      const recordIds: string[] = [];
      _.forEach(checkedData, (item) => {
        recordIds.push(_.get(item, "id"));
      });
      setData({ ...data, recordIds });
    }
  }, [checkedData]);

  const onSubmit = async () => {
    const resolveData = {
      ...data,
    };
    const emails = _.get(data, "emails").split(",");
    const resolveEmail: string[] = [];
    _.forEach(emails, (email) => {
      if (regexEmail.test(_.trim(email))) resolveEmail.push(_.trim(email));
    });
    _.assign(resolveData, { emails: resolveEmail });

    const requireFields = [
      {
        label: t("label.emails"),
        value: _.get(resolveData, "emails"),
      },
      {
        label: t("label.subject"),
        value: _.get(resolveData, "subject"),
      },
    ];
    let message = `${t("alert.foundEmptyFields")}`;
    let isValid = true;
    _.map(requireFields, (field) => {
      const value = _.get(field, "value");
      const label = _.get(field, "label");
      if ((_.isString(value) && _.isEmpty(_.trim(value))) || _.isEmpty(value)) {
        isValid = false;
        message += `${label}\n`;
      }
    });
    if (!isValid)
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
        buttons: true,
      });

    const content = document.createElement("span");
    content.innerHTML = `${t("alert.confirmSendCheckedPosts")}\n <b>${_.get(
      resolveData,
      "emails"
    ).join(", ")}</b>\n${t("alert.withSubject")} <b>${_.get(
      resolveData,
      "subject"
    )}</b>`;

    const isAgree = await popupAlert({
      title: t("alert.confirm"),
      type: "warning",
      buttons: {
        cancel: {
          text: t("alert.cancel"),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: t("alert.send"),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
          focus: true,
        },
      },
      content,
    });

    if (isAgree) dispatch(sendCheckedPostToMail(resolveData));
    // onClose();
  };

  return (
    <TemplateDetailsContainer>
      <TemplateDetailsContent width="400px">
        {isLoading && (
          <LoadingLayer>
            <Spinner />
          </LoadingLayer>
        )}
        <TemplateDetailsHeader>{t("label.sendEmail")}</TemplateDetailsHeader>
        <TemplateDetailsForm height="300px" ref={formRef}>
          <FormGroup>
            <FormLabel aria-label="require">{t("label.emails")} </FormLabel>
            <Textarea
              width="100%"
              value={_.get(data, "emails")}
              onChangeHandler={(e: any) =>
                setData({ ...data, emails: e.target.value })
              }
              placeholder={t("placeholder.noteComma")}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel aria-label="require">{t("label.subject")} </FormLabel>
            <Input
              width="100%"
              value={_.get(data, "subject")}
              onChangeHandler={(e: any) =>
                setData({ ...data, subject: e.target.value })
              }
            />
          </FormGroup>
        </TemplateDetailsForm>
        <ButtonField>
          <Button
            buttonWidth="100px"
            buttonMargin="0 10px 0 0 "
            onClick={() => onSubmit()}
          >
            {t("button.send")}
          </Button>
          <Button buttonWidth="100px" onClick={onClose}>
            {t("button.close")}
          </Button>
        </ButtonField>
      </TemplateDetailsContent>
    </TemplateDetailsContainer>
  );
};

export default SendMail;
