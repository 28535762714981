/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import TableUltils from "libs/TableUltils";
import RouteTypes from "constants/RouteTypes";
import { generateFilter } from "../../../libs/Utils";

import { LogsActions } from "actions";
import { RootState } from "reducers";

import { LogsDetails } from "components/Common/Popup";
import DefaultLayout from "components/AdminLayout/DefaultLayout";
import {
  Button,
  Input,
  Table,
  SelectDatetime,
  ContainerSearch,
  FlexWrapRow,
} from "components/Common";

interface FilterStructure {
  dateFrom: string;
  dateTo: string;
  keyword: string;
  page: number;
  limit: number;
}

const DEFAULT_FILTER = {
  dateFrom: "",
  dateTo: "",
  keyword: "",
  page: 1,
  limit: 10,
};

const InputStyleProps = {
  margin: "10px 10px 10px 0",
  width: "200px",
};

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "10px 10px 10px 0",
};

const { searchLogs, clearLogs } = LogsActions;

const Logs = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => {
    return state.Logs.isLoading;
  });
  const logs = useSelector((state: RootState) => {
    return state.Logs.logs;
  });

  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const [dataPopup, setDataPopup] = useState<any[]>([]);
  const [isPreViewPopup, setIsPreViewPopup] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(clearLogs());
    };
  }, []);

  useEffect(() => {
    dispatch(searchLogs(generateFilter(filter)));
  }, [dispatch]);

  const dataHead = [
    "no",
    "content",
    "logType",
    "logLevel",
    "createdAt",
    "preview",
  ];

  const dataTable = TableUltils._processDataTableLogs(
    ["no", "id", "content", "logType", "level", "createdAt", "preview"],
    logs.items
  );

  const listColumnCenter = [4, 5, 6];

  const callbackCurrentPageLink = (page: number) => {
    const newFilter: any = {
      ...filter,
      page,
    };
    setFilter(newFilter);
    dispatch(searchLogs(generateFilter(newFilter)));
  };

  const callBackPreview = (item: any) => {
    const itemId = _.get(item, "id");
    const findLog = _.find(logs?.items, { id: itemId });
    if (findLog) {
      setDataPopup([findLog]);
      setIsPreViewPopup(true);
    }
  };

  const _renderTable = () => {
    return (
      <Table
        dataHeader={dataHead}
        dataTable={dataTable}
        callBackPreview={callBackPreview}
        fitColumns={[1, 4, 5, 6]}
        listColumnCenter={listColumnCenter}
        hasPagination={true}
        callbackCurrentPageLink={callbackCurrentPageLink}
        metaPagination={logs?.meta}
        loading={isLoading}
      />
    );
  };

  const addFilter = () => {
    const newFilter = {
      ...filter,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(searchLogs(generateFilter(newFilter)));
  };

  const onReset = async () => {
    setFilter(DEFAULT_FILTER);
    await dispatch(searchLogs(generateFilter(DEFAULT_FILTER)));
  };

  const renderMain = () => {
    return (
      <>
        {isPreViewPopup && (
          <LogsDetails
            onClose={() => setIsPreViewPopup(false)}
            deferData={dataPopup}
          />
        )}
        <ContainerSearch className={isLoading ? "loading" : ""}>
          <FlexWrapRow>
            <SelectDatetime
              callbackPayload={(e) => setFilter({ ...filter, dateFrom: e })}
              defaultValue={_.get(filter, "dateFrom")}
              placeholder={t("placeholder.dateFrom")}
              maxDate={filter?.dateTo || new Date().toLocaleString()}
              {...InputStyleProps}
            />
            <SelectDatetime
              callbackPayload={(e) => setFilter({ ...filter, dateTo: e })}
              defaultValue={_.get(filter, "dateTo")}
              placeholder={t("placeholder.dateTo")}
              minDate={filter?.dateFrom || new Date().toLocaleString()}
              {...InputStyleProps}
            />
            <Input
              value={_.get(filter, "keyword")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, keyword: e.target.value })
              }
              onEnter={() => addFilter()}
              placeholder={t("placeholder.keyword")}
              {...InputStyleProps}
            />
            <Button onClick={() => addFilter()} {...ButtonStyleProps}>
              {t("button.search")}
            </Button>
            <Button onClick={() => onReset()} {...ButtonStyleProps}>
              {t("button.reset")}
            </Button>
          </FlexWrapRow>
        </ContainerSearch>
        {_renderTable()}
      </>
    );
  };
  return (
    <DefaultLayout
      content={renderMain()}
      currentPage={RouteTypes.CLIENT_ROUTES.LOGS}
      title={t("titlePage.logs")}
    />
  );
};

export default Logs;
