/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { RootState } from "reducers";
import {
  UsersActions,
  ClientActions,
  AccountActions,
  DataRecordsActions,
} from "actions";

import { Images } from "themes";

import { Title } from "./Authentication.styles";
import {
  AuthenticationForm,
  AuthenticationSwitch,
  AuthenticationContent,
  PopupWrapper,
  DescriptionWrapper,
  DescriptionContent,
  DataRecordItem,
  LoadingAuthenForm,
  Spinner,
} from "./UserAuthentication.styles";
import { Input, Button, FlexHorizontal, Dropdown } from "components/Common";
import { useTranslation } from "react-i18next";
import { generateDropdown, popupAlert, validatePassword } from "libs/Utils";
import history from "../../history";
import RouteTypes from "../../constants/RouteTypes";

const { register, setDefaultRegisterResult } = UsersActions;
const { fetchAllClients, clearClientsPayload } = ClientActions;
const { fetchPackagesForUserPortal } = AccountActions;
const { getPreviewDataRecordsForUser, clearDataRecordForUser } =
  DataRecordsActions;

interface UserDataStructure {
  firstName: string;
  lastName: string;
  password: string;
  confirmationPassword: string;
  email: string;
  username: string;
}

interface SectionProps {
  onClose?(): void;
}

const UserAuthentication = (props: SectionProps) => {
  const { onClose } = props;
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();

  const isLoading = useSelector((state: RootState) => {
    return state.Users.isLoading;
  });
  const dataRecordsIsLoading = useSelector((state: RootState) =>
    _.get(state, "DataRecord.isLoading")
  );
  const clients = useSelector((state: RootState) =>
    _.get(state, "Clients.clients")
  );
  const clientsIsLoading = useSelector((state: RootState) =>
    _.get(state, "Clients.isLoading")
  );
  const packages = useSelector((state: RootState) =>
    _.get(state, "Account.packagesForUserPotal")
  );
  const packagesIsLoading = useSelector((state: RootState) =>
    _.get(state, "Account.isLoading")
  );
  const dataRecords = useSelector((state: RootState) =>
    _.get(state, "DataRecord.dataForUser")
  );
  const registerResult = useSelector((state: RootState) =>
    _.get(state, "Users.isRegisterSuccess")
  );
  const isLogged = useSelector((state: RootState) => {
    return state.Authentication.isLogged;
  });

  const [widthPopup, setWidthPopup] = useState<string>("450px");
  const [className, setClassName] = useState<string>("");
  const [clientId, setClientId] = useState<string>("");
  const [accountId, setAccountId] = useState<string>("");
  const [userData, setUserData] = useState<UserDataStructure>({
    firstName: "",
    lastName: "",
    password: "",
    confirmationPassword: "",
    email: "",
    username: "",
  });
  const [required, setRequired] = useState<any>({
    firstName: false,
    lastName: false,
    password: false,
    confirmationPassword: false,
    email: false,
    clientId: false,
    accountId: false,
  });
  const [clientsOptions, setClientsOptions] = useState<any[]>([]);
  const [packagesOptions, setPackagesOptions] = useState<any[]>([]);

  useEffect(() => {
    dispatch(fetchAllClients());

    setTimeout(() => {
      setWidthPopup("1050px");
      setClassName("change-border-radius");
    }, 300);

    return () => {
      dispatch(clearDataRecordForUser());
      dispatch(clearClientsPayload());
    };
  }, []);

  useEffect(() => {
    if ((registerResult === 2 || isLogged) && onClose) {
      onClose();
      dispatch(setDefaultRegisterResult());
    }
  }, [registerResult]);

  useEffect(() => {
    if (!_.isEmpty(clientId) && !_.isEmpty(accountId)) {
      dispatch(getPreviewDataRecordsForUser({ clientId, accountId }));
    }
  }, [accountId]);

  useEffect(() => {
    if (!_.isEmpty(clients)) {
      const options = generateDropdown({
        data: clients,
        key: "clientName",
        value: "id",
      });
      setClientsOptions(options);
    }
  }, [clients]);

  useEffect(() => {
    if (clientId) {
      setWidthPopup("1050px");
      dispatch(fetchPackagesForUserPortal(clientId));
    }
  }, [clientId]);

  useEffect(() => {
    if (!_.isEmpty(packages)) {
      const options = generateDropdown({
        data: packages,
        key: "accountName",
        value: "id",
      });
      setPackagesOptions(options);
    }
  }, [packages]);

  const checkValidData = () => {
    const data = [
      {
        label: t("label.email"),
        value: _.get(userData, "email"),
      },
      {
        label: t("label.password"),
        value: _.get(userData, "password"),
      },
      {
        label: t("label.confirmPassword"),
        value: _.get(userData, "confirmationPassword"),
      },
      {
        label: t("label.firstName"),
        value: _.get(userData, "firstName"),
      },
      {
        label: t("label.lastName"),
        value: _.get(userData, "lastName"),
      },
      {
        label: t("label.client"),
        value: clientId,
      },
    ];

    if (clientId)
      data.push(
        ...[
          {
            label: t("label.package"),
            value: accountId,
          },
        ]
      );

    let isValid = true;
    let message = "Found empty field(s):\n";
    _.map(data, (item) => {
      const { value, label } = item;
      if (_.isEmpty(value)) {
        isValid = false;
        message += `${label}\n`;
      }
    });
    return { isValid, message };
  };

  const onCreate = async () => {
    const { isValid, message } = checkValidData();
    if (!isValid)
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });

    const isSamePassword = userData.password === userData.confirmationPassword;
    if (!isSamePassword)
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: t("alert.warningNotSamePassword"),
      });

    const valid = validatePassword({
      password: {
        label: t("label.password"),
        value: _.get(userData, "password"),
      },
      confirmPassword: {
        label: t("label.confirmPassword"),
        value: _.get(userData, "confirmationPassword"),
      },
      rules: {
        atLeastChars: 6,
        atLeastOneDigit: true,
        atLeastOneLowerCase: true,
      },
    });
    if (!valid) return;

    const formData = new FormData();
    formData.append("userData[firstName]", userData.firstName);
    formData.append("userData[lastName]", userData.lastName);
    formData.append("password", userData.password);
    formData.append("confirmationPassword", userData.confirmationPassword);
    formData.append("email", userData.email);
    formData.append("clientId", clientId);
    formData.append("accountId", accountId);
    formData.append("userType", "user");
    await dispatch(register(formData, true));
  };

  const onClosePopup = (e: any) => {
    const classList = _.get(e, "target.classList");
    if (classList.contains("blur_layer") && onClose) onClose();
  };

  const renderRecords = () => {
    if (_.isEmpty(dataRecords)) return <p>No result found</p>;
    const render = _.map(dataRecords, (item) => {
      return (
        <DataRecordItem>
          <div className="record-header">
            <p>
              Author: <b>{_.get(item, "author")}</b>
            </p>
            <p>
              Category: <b>{_.get(item, "category.name.en") || "N/I"}</b>
            </p>
          </div>
          <div className="record-content">
            <h2>{_.get(item, "title")}</h2>
            <p>{_.get(item, "content")}</p>
          </div>
        </DataRecordItem>
      );
    });
    return <>{render}</>;
  };

  const registerForm = () => {
    return (
      <AuthenticationForm className={className} style={{ width: "450px" }}>
        <div className="header-login">
          <img src={Images.logo} alt="Media Monitoring system" />
        </div>
        {/* {isLoading && <Loading />} */}
        <Title className="center">Register</Title>
        <Input
          margin="5px 0"
          onChangeHandler={(e) => {
            setUserData({ ...userData, username: e.target.value });
            setRequired({ ...required, username: false });
          }}
          placeholder="Enter you username"
          width="100%"
        />
        <Input
          margin="5px 0"
          onChangeHandler={(e) => {
            setUserData({ ...userData, email: e.target.value });
            setRequired({ ...required, email: false });
          }}
          placeholder="Enter your email"
          width="100%"
        />

        <Input
          margin="5px 5px 5px 0"
          onChangeHandler={(e) => {
            setUserData({ ...userData, firstName: e.target.value });
            setRequired({ ...required, firstName: false });
          }}
          placeholder="First Name"
          width="100%"
        />
        <Input
          margin="5px 0"
          onChangeHandler={(e) => {
            setUserData({ ...userData, lastName: e.target.value });
            setRequired({ ...required, lastName: false });
          }}
          placeholder="Last Name"
          width="100%"
        />
        <Input
          type="password"
          margin="5px 0"
          onChangeHandler={(e) => {
            setUserData({ ...userData, password: e.target.value });
            setRequired({ ...required, password: false });
          }}
          placeholder="Password"
          width="100%"
        />
        <Input
          type="password"
          margin="5px 0"
          onChangeHandler={(e) => {
            setUserData({ ...userData, confirmationPassword: e.target.value });
            setRequired({ ...required, confirmationPassword: false });
          }}
          placeholder="Confirm Password"
          width="100%"
        />
        <div className="footer">
          <Button
            onClick={() => onCreate()}
            className="form-button"
            buttonMargin="10px 0"
          >
            Register
          </Button>
        </div>
        <AuthenticationSwitch>
          Already have an account?
          <span
            onClick={() =>
              history.push(RouteTypes.AUTHENTICATION_ROUTES.USER_LOGIN)
            }
          >
            Sign in
          </span>
        </AuthenticationSwitch>
      </AuthenticationForm>
    );
  };

  const renderDescription = () => {
    const findClient = _.find(clients, { id: clientId });
    const findPackage = _.find(packages, { id: accountId });
    return (
      <DescriptionWrapper
        className={className}
        style={{ width: className ? "600px" : "0px" }}
      >
        <DescriptionContent>
          <FlexHorizontal className="align-center">
            <span className="label">Client:</span>
            <Dropdown
              value={clientId}
              margin="5px 5px 5px 0"
              callbackPayload={(e) => {
                setClientId(e);
                setRequired({ ...required, clientId: false });
              }}
              options={clientsOptions}
              placeholder={t("placeholder.client")}
              width="200px"
              search={true}
              loading={clientsIsLoading}
            />
          </FlexHorizontal>
          <p>{_.get(findClient, "description")}</p>
          {clientId && (
            <>
              <FlexHorizontal className="align-center">
                <span className="label">Package:</span>
                <Dropdown
                  value={accountId}
                  margin="5px 5px 5px 0"
                  callbackPayload={(e) => {
                    setAccountId(e);
                    setRequired({ ...required, accountId: false });
                  }}
                  options={packagesOptions}
                  placeholder={t("placeholder.package")}
                  width="200px"
                  search={true}
                  loading={packagesIsLoading}
                />
              </FlexHorizontal>
              <p> {_.get(findPackage, "description")}</p>
            </>
          )}
          {renderRecords()}
        </DescriptionContent>
      </DescriptionWrapper>
    );
  };

  const renderMain = () => {
    return (
      <AuthenticationContent style={{ display: "flex", width: widthPopup }}>
        {registerForm()}
        {renderDescription()}
        {(isLoading || dataRecordsIsLoading) && (
          <LoadingAuthenForm>
            <Spinner />
          </LoadingAuthenForm>
        )}
      </AuthenticationContent>
    );
  };

  return (
    <PopupWrapper className="blur_layer" onClick={(e: any) => onClosePopup(e)}>
      {renderMain()}
    </PopupWrapper>
  );
};

export default UserAuthentication;
