import ActionTypes from "constants/ActionTypes";
import API from "api";
import _ from "lodash";
import { responseAlert } from "libs/alert";
import {
  generateCoverageByMediaType,
  generateMediaReach,
  generateTopAuthors,
  generateTopSources,
  generateStoriesOverTime,
  generateLatestStories,
} from "libs/ChartUtils";

const clearDashboard = () => {
  return {
    type: ActionTypes.CLEAR_DASHBOARD,
  };
};

const reportFail = () => {
  return {
    type: ActionTypes.DASHBOARD_FAILURE,
  };
};

const reportLoading = () => {
  return {
    type: ActionTypes.DASHBOARD_REQUEST,
  };
};

const fetchReportSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_DASHBOARD_SUCCESS,
    payload,
  };
};

const fetchSourceSettingsSuccess = (payload = {}) => {
  return {
    type: ActionTypes.FETCH_DASHBOARD_SOURCE_SETTINGS_SUCCESS,
    payload,
  };
};

const reportDashboard = () => {
  return async (dispatch: any) => {
    dispatch(reportLoading());
    await API.reportDashboard()
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchReportSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(reportFail());
        }
      })
      .catch((e: any) => {
        dispatch(reportFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const getDashboardStatisticalFail = () => {
  return {
    type: ActionTypes.GET_DASHBOARD_STATISTICAL_FAILURE,
  };
};

const getDashboardStatisticalSuccess = (payload = {}) => {
  return {
    type: ActionTypes.GET_DASHBOARD_STATISTICAL_SUCCESS,
    payload,
  };
};

const getDashboardStatistical = (payload: any, charts: string[]) => {
  return async (dispatch: any) => {
    dispatch(reportLoading());
    await API.getDashboardStatistical(payload)
      .then(async (res: any) => {
        const { status } = res;
        if (status) {
          const results: any = {
            coverageByMediaType: null,
            mediaCost: null,
            mediaCostByMediaType: null,
            mediaReach: null,
            mediaReachByMediaType: null,
            storiesOverTime: null,
            topAuthors: null,
            topSources: null,
            latestStories: null,
            articles: [],
          };
          if (_.includes(charts, "coverageByMediaType"))
            results.coverageByMediaType = generateCoverageByMediaType(
              payload,
              res.payload
            );
          if (_.includes(charts, "mediaReach"))
            results.mediaReach = generateMediaReach(payload, res.payload);

          if (_.includes(charts, "mediaCost")) results.mediaCost = null;

          if (_.includes(charts, "mediaCostByMediaType"))
            results.mediaCostByMediaType = null;

          if (_.includes(charts, "mediaReachByMediaType"))
            results.mediaReachByMediaType = null;

          if (_.includes(charts, "storiesOverTime"))
            results.storiesOverTime = generateStoriesOverTime(
              payload,
              res.payload
            );

          if (_.includes(charts, "topAuthors"))
            results.topAuthors = generateTopAuthors(payload, res.payload);

          if (_.includes(charts, "topSources"))
            results.topSources = generateTopSources(payload, res.payload);
          if (_.includes(charts, "latestStories"))
            results.latestStories = generateLatestStories(res.payload);

          results.articles = _.orderBy(res.payload, "createdAt", "asc");

          dispatch(getDashboardStatisticalSuccess(results));
        } else {
          responseAlert({ res });
          dispatch(getDashboardStatisticalFail());
        }
      })
      .catch((e: any) => {
        dispatch(getDashboardStatisticalFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

const fetchAllSourceDashboard = (data?: any) => {
  return async (dispatch: any) => {
    dispatch(reportLoading());
    await API.getAllSources(data)
      .then(async (res: any) => {
        const { status } = res;
        if (status) dispatch(fetchSourceSettingsSuccess(res.payload));
        else {
          responseAlert({ res });
          dispatch(reportFail());
        }
      })
      .catch((e: any) => {
        dispatch(reportFail());
        if (!_.isEmpty(e)) responseAlert({ res: e });
      });
  };
};

export {
  reportDashboard,
  clearDashboard,
  getDashboardStatistical,
  generateTopSources,
  fetchAllSourceDashboard,
};
