import styled from "styled-components";
import ColorName from "constants/Variables";
import { device } from "constants/Devices";
import { HorizontalContainer } from "../CommonLayout";

interface SectionProps {
  width?: string;
  height?: string;
  maxHeight?: string;
  overflow?: string;
}

const LoadingLayer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

const ContainerPopup = styled(HorizontalContainer)`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  min-width: 375px;
  max-height: 85vh;
  border-radius: 20px;
  overflow: hidden;
  background-color: ${ColorName.white};
  position: relative;
  & .title {
    display: flex;
    place-content: space-between;
    position: relative;
    & .icon-close {
        cursor: pointer;
        font-weight: 900;
        display: flex;
        align-self: center;
        padding: 10px 30px;
        color: white;
    };
    background-color: #363b65;
  }
  & .content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-height: 50vh;
    overflow: auto;
    white-space: pre-line;
    background-color: #e6e6e6;
    &.flex-center {
      place-items: center;
      min-height: 200px;
      & .form-center {
        min-width: 200px;
      }
    }
  }
  & .footer {
    display: flex;
    padding: 10px 20px;
    justify-content: flex-end;
    border-top: 1px solid ${ColorName.linkWater};
  }
`;

const DowloadButton = styled.div`
  position: absolute;
  color: white;
  bottom: -48px;
  right: 94px;
  width: 40px;
  height: 35px;
  border-radius: 50%;
  & a {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    &:hover {
      background: rgba(255,255,255,0.1);
    }
  }
`

export {
  ContainerPopup,
  Content,
  DowloadButton
};
