/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { RootState } from "reducers";

import {
  Dropdown,
  Button,
  Input,
  FormGroup,
  FormInline,
  FormLabel,
  TitlePartOfForm,
  Avatar,
} from "components/Common";

import countries from "libs/countries";
import { UsersActions, EmailTemplateActions, ClientActions } from "actions";
import PasswordGenerator from "generate-password";
import {
  TemplateDetailsContent,
  TemplateDetailsContainer,
  TemplateDetailsForm,
  ButtonField,
  TemplateDetailsHeader,
  OrderLayer,
  LoadingLayer,
  Spinner,
} from "../Popup.styles";
import { CONSTANTS } from "constants/Constants";
import { EmailTemplateDetails } from "../Template";
import { useTranslation } from "react-i18next";
import { popupAlert } from "libs/Utils";
import { Images } from "themes";
import { searchAccounts } from "../../../../actions/Account.action";

const { registerUser, clearUserDetails } = UsersActions;
const { getEmailTemplateByType, clearEmailTemplateDetails } =
  EmailTemplateActions;
const { fetchAllClients } = ClientActions;

interface SectionProps {
  onClose: (isFetch?: boolean) => void;
  clientId: string;
}

const UserForClient: React.FC<SectionProps> = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { onClose, clientId } = props;
  const dispatch = useDispatch();

  const emailTemplate = useSelector((state: RootState) =>
    _.get(state, "EmailTemplate.emailTemplate")
  );
  const emailTemplateIsLoading = useSelector((state: RootState) =>
    _.get(state, "EmailTemplate.isLoading")
  );
  const usersIsLoading = useSelector((state: RootState) => {
    return state.Users.isLoading;
  });
  const isRegisterSuccess = useSelector((state: RootState) => {
    return state.Users.isRegisterSuccess;
  });
  const packages = useSelector((state: RootState) => {
    return state.Account.accounts;
  });
  const [isShowCreateTemplate, setIsShowCreateTemplate] =
    useState<boolean>(false);
  const [packageOptions, setPackageOptions] = useState<any[]>([]);
  const [country, setCountry] = useState("");
  const [accountImage, setAccountImage] = useState();
  const [accountData, setAccountData] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    contactNumber: "",
    address: "",
    city: "",
    region: "",
    zipCode: "",
    userType: "",
    clientId,
    accountId: "",
  });
  const [urlPreview, setUrlPreview] = useState("");
  const [extraData, setExtraData] = useState({
    facebook: "",
    twitter: "",
    linkedin: "",
    pinterest: "",
    instagram: "",
  });

  useEffect(() => {
    dispatch(getEmailTemplateByType(CONSTANTS.EMAIL_TYPE.VERIFY_ACCOUNT));
    dispatch(fetchAllClients());
    dispatch(searchAccounts({ clientId, page: 1, limit: 100 }));
    return () => {
      dispatch(clearEmailTemplateDetails());
      dispatch(clearUserDetails());
    };
  }, []);

  useEffect(() => {
    if (isRegisterSuccess === 2 && onClose) onClose(true);
  }, [isRegisterSuccess]);

  useEffect(() => {
    const packagesList = _.get(packages, 'items');
    if (!_.isEmpty(packagesList)) {
      const resolvePackageOptions: any[] = [];
      _.forEach(packagesList, (item) => {
        resolvePackageOptions.push({
          label: _.get(item, "accountName"),
          value: _.get(item, "id"),
        });
      });
      setPackageOptions(resolvePackageOptions);
    }
  }, [packages]);

  const processData = () => {
    const formData = new FormData();
    formData.append("clientId", accountData.clientId);
    formData.append("email", accountData.email);
    formData.append("username", accountData.username);
    formData.append("userType", "user");
    formData.append("userData[firstName]", accountData.firstName);
    formData.append("userData[lastName]", accountData.lastName);
    formData.append("userData[address]", accountData.address);
    formData.append("userData[phoneNumber]", accountData.contactNumber);
    formData.append("userData[country]", country);
    formData.append("userData[city]", accountData.city);
    const accountId = _.get(accountData, "accountId");
    if (!_.isEmpty(accountId))
      for (const accId of accountId) formData.append("accountId[]", accId);

    if (accountData.zipCode)
      formData.append("userData[zipCode]", accountData.zipCode);
    if (accountData.region)
      formData.append("userData[regionArea]", accountData.region);
    formData.append("userData[extraData]", JSON.stringify(extraData));
    if (accountImage) formData.append("userData[avatar]", accountImage || "");

    const password = PasswordGenerator.generate({
      length: 10,
      numbers: true,
      lowercase: true,
      uppercase: true,
      strict: true,
    });
    formData.append("password", password);
    formData.append("confirmationPassword", password);

    return formData;
  };

  const validateData = () => {
    let flag = true;
    let message = `${t("alert.foundEmptyFields")}`;
    const data: any[] = [
      {
        label: t("label.firstName"),
        value: accountData.lastName,
      },
      {
        label: t("label.lastName"),
        value: accountData.lastName,
      },
      {
        label: t("label.username"),
        value: accountData.username,
      },
      {
        label: t("label.email"),
        value: accountData.email,
      },
      // {
      //   label: t("label.userType"),
      //   value: accountData.userType,
      // },
      {
        label: t("label.streetAddress"),
        value: accountData.address,
      },
      {
        label: t("label.country"),
        value: country,
      },
      {
        label: t("label.city"),
        value: accountData.city,
      },
      {
        label: t("label.contactNumber"),
        value: accountData.contactNumber,
      },
      {
        label: t("label.package"),
        value: accountData.accountId,
      },
    ];
    _.map(data, (item) => {
      const label = _.get(item, "label");
      const value = _.get(item, "value");
      if (_.isEmpty(value)) {
        flag = false;
        message += `${label}\n`;
      }
    });

    return { flag, message };
  };

  const createAccount = async () => {
    const { flag, message } = validateData();
    if (!flag)
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
      });
    else {
      const userData = processData();
      await dispatch(registerUser(userData));
    }
  };

  const setLogo = (e: any) => {
    if (e) {
      const url = URL.createObjectURL(e.target.files[0]);
      setAccountImage(e.target.files[0]);
      setUrlPreview(url);
    }
  };

  const onCloseTemplate = async () => {
    await setIsShowCreateTemplate(false);
    await dispatch(getEmailTemplateByType(CONSTANTS.EMAIL_TYPE.EMAIL_SCHEDULE));
  };

  return (
    <TemplateDetailsContainer>
      <TemplateDetailsContent>
        {isShowCreateTemplate && (
          <EmailTemplateDetails onClose={() => onCloseTemplate()} />
        )}
        {!_.has(emailTemplate, "id") && (
          <OrderLayer>
            <p>{t("description.emptyVeriyAccountTemplate")}</p>
            <Button
              buttonWidth="150px"
              onClick={() => setIsShowCreateTemplate(true)}
            >
              {t("button.createTemplate")}
            </Button>
          </OrderLayer>
        )}
        {(emailTemplateIsLoading || usersIsLoading) && (
          <LoadingLayer>
            <Spinner />
          </LoadingLayer>
        )}
        <TemplateDetailsHeader>
          {t("headerPopup.createNewUser")}
        </TemplateDetailsHeader>
        <TemplateDetailsForm height="500px">
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.firstName")}</FormLabel>
              <Input
                value={accountData.firstName || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({
                    ...accountData,
                    firstName: e.target.value,
                  })
                }
                placeholder={t("placeholder.firstName")}
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.lastName")}</FormLabel>
              <Input
                value={accountData.lastName || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, lastName: e.target.value })
                }
                placeholder={t("placeholder.lastName")}
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.username")}</FormLabel>
              <Input
                value={accountData.username || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, username: e.target.value })
                }
                placeholder={t("placeholder.username")}
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.email")}</FormLabel>
              <Input
                value={accountData.email || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, email: e.target.value })
                }
                placeholder={t("placeholder.email")}
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.package")}</FormLabel>
              <Dropdown
                value={accountData.accountId || ""}
                width="300px"
                callbackPayload={(value: any) => {
                  const newPayload = {
                    ...accountData,
                    accountId: value,
                  };
                  setAccountData(newPayload);
                }}
                options={packageOptions}
                placeholder={t("placeholder.package")}
                search
                multiple
              />
            </FormGroup>
          </FormInline>
          <TitlePartOfForm aria-label="require">
            {t("titleSection.contactInformation")}
          </TitlePartOfForm>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.streetAddress")}
              </FormLabel>
              <Input
                value={accountData.address || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, address: e.target.value })
                }
                placeholder={t("placeholder.streetAddress")}
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.country")}</FormLabel>
              <Dropdown
                value={country}
                width="300px"
                callbackPayload={(value: any) => setCountry(value)}
                options={countries}
                placeholder={t("placeholder.country")}
                search={false}
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel aria-label="require">{t("label.city")}</FormLabel>
              <Input
                value={accountData.city || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, city: e.target.value })
                }
                placeholder={t("placeholder.city")}
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t("label.regionArea")}</FormLabel>
              <Input
                value={accountData.region || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, region: e.target.value })
                }
                placeholder={t("placeholder.regionArea")}
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.postalOrZipCode")}</FormLabel>
              <Input
                value={accountData.zipCode || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({ ...accountData, zipCode: e.target.value })
                }
                placeholder={t("placeholder.postalOrZipCode")}
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel aria-label="require">
                {t("label.contactNumber")}
              </FormLabel>
              <Input
                value={accountData.contactNumber || ""}
                onChangeHandler={(e: any) =>
                  setAccountData({
                    ...accountData,
                    contactNumber: e.target.value,
                  })
                }
                placeholder={t("placeholder.phoneNumber")}
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <TitlePartOfForm>{t("titleSection.social")}</TitlePartOfForm>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.facebookProfile")}</FormLabel>
              <Input
                value={extraData.facebook || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, facebook: e.target.value })
                }
                placeholder="http://"
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t("label.instagramProfile")}</FormLabel>
              <Input
                value={extraData.instagram || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, instagram: e.target.value })
                }
                placeholder="http://"
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.twitterProfile")}</FormLabel>
              <Input
                value={extraData.twitter || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, twitter: e.target.value })
                }
                placeholder="http://"
                width="300px"
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t("label.linkedInProfile")}</FormLabel>
              <Input
                value={extraData.linkedin || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, linkedin: e.target.value })
                }
                placeholder="http://"
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormInline>
            <FormGroup>
              <FormLabel>{t("label.pinterestProfile")}</FormLabel>
              <Input
                value={extraData.pinterest || ""}
                onChangeHandler={(e: any) =>
                  setExtraData({ ...extraData, pinterest: e.target.value })
                }
                placeholder="http://"
                width="300px"
              />
            </FormGroup>
          </FormInline>
          <FormGroup>
            <FormLabel>
              {t("label.uploadAvatar")}
              <span className="description">{t("label.demensionImage")}</span>
            </FormLabel>
            <Avatar
              src={urlPreview || Images.defaultPhoto}
              onChange={(e: any) => setLogo(e)}
            />
          </FormGroup>
        </TemplateDetailsForm>
        <ButtonField>
          <Button onClick={() => createAccount()} buttonMargin="0 10px 0 0">
            {t("button.create")}
          </Button>
          <Button type="submit" onClick={() => onClose()}>
            {t("button.cancel")}
          </Button>
        </ButtonField>
      </TemplateDetailsContent>
    </TemplateDetailsContainer>
  );
};

export default UserForClient;
