import React, { useEffect, useRef } from "react";
import { Box, Button } from "@mui/material";
import { ContainerPopup, Content, DowloadButton } from "./PreviewPDF.styles";
import _ from "lodash";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

interface IPreviewPDFStructure {
  file?: any;
  style?: object;
  clickClose: () => void;
  header: JSX.Element;
  changeView: () => void;
  isPDFView: boolean;
  content?: JSX.Element;
  title?: string;
}

const PreviewPDF: React.FC<IPreviewPDFStructure> = ({ file, header, clickClose, changeView, content, isPDFView, title = 'PDF' }) => {
  return (
    <ContainerPopup>
      <Content>
        <div className='title'>
          <span>{header && header}</span>
          <span onClick={() => clickClose()} className="icon-close">X
            <DowloadButton><a href={file} download={`${title}.pdf`} className="forButtonDownload"></a></DowloadButton></span>
        </div>
        {isPDFView && (
          <Box
            component="object"
            data={file}
            type="application/pdf"
            sx={{ width: 1, minHeight: "58vh" }}
          >
            <iframe
              id="my-iframe"
              width="100%"
              title={title}
              height="58vh"
              src={`https://docs.google.com/viewer?url=${file}&embedded=true`}
            />
          </Box>
        )}

        {!isPDFView && (
          <div className="content">{content && <>{content}</>}</div>
        )}

        <div className="footer" onClick={() => changeView()}>
          <Button variant="outlined">
            {isPDFView ? "Text View" : "PDF View"}
          </Button>
        </div>
      </Content>
    </ContainerPopup>
  );
};
export default PreviewPDF;
