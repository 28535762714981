/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import DefaultLayout from "components/AdminLayout/DefaultLayout";
import Table from "components/Common/Table";
import { RootState } from "reducers";
import Input from "components/Common/Input";
import { Button } from "components/Common/CommonLayout";
import TableUltils from "libs/TableUltils";
import ColorName from "constants/Variables";
import { CONSTANTS } from "constants/Constants";
import {
  generateDropdown,
  generateFilter,
  getSavedUserData,
  popupAlert,
  processDataDropDownAccount,
} from "libs/Utils";
import {
  AccountActions,
  UsersActions,
  ClientActions,
  AuthenticationActions,
} from "actions";
import RouteTypes from "constants/RouteTypes";
import {
  Checkbox,
  Dropdown,
  ContainerSearch,
  FlexWrapRow,
} from "components/Common";
import {
  LogsDetails,
  UserDetails,
  ChangePackage,
} from "components/Common/Popup";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import history from "../../../history";

const { fetchAllClients, clearClientsPayload } = ClientActions;
const { fetchPackagesForUserPortal } = AccountActions;
const { loginAs } = AuthenticationActions;
const {
  fetchUsers,
  deactivateUser,
  activateUser,
  clearUserFilter,
  clearUserPayload,
  setDefaultRegisterResult,
  deleteUser,
} = UsersActions;

const DEFAULT_FILTER = {
  userType: "all",
  accountId: "",
  clientId: "",
  keyword: "",
  status: CONSTANTS.STATUS.ACTIVE,
  limit: 10,
  page: 1,
  sortBy: "updatedAt",
  orderBy: "DESC",
};

interface FilterStructure {
  userType: string;
  accountId: string;
  clientId: string;
  keyword: string;
  status: string;
  limit: number;
  page: number;
  sortBy: string;
  orderBy: string;
}

const InputStyleProps = {
  width: "200px",
  margin: "10px 10px 10px 0",
};

const ButtonStyleProps = {
  buttonWidth: "100px",
  buttonMargin: "10px 10px 10px 0",
};

const ListUser = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => {
    return state.Account.isLoading;
  });
  const clientIsLoading = useSelector((state: RootState) => {
    return state.Clients.isLoading;
  });
  const loginAsIsLoading = useSelector((state: RootState) => {
    return state.Authentication.isLoading;
  });
  const usersIsLoading = useSelector((state: RootState) => {
    return state.Users.isLoading;
  });
  const clients = useSelector((state: RootState) => {
    return state.Clients.clients;
  });
  const users = useSelector((state: RootState) => {
    return state.Users.users;
  });
  const packages = useSelector((state: RootState) => {
    return state.Account.packagesForUserPotal;
  });
  const location = useLocation();

  const [listActions, setListActions] = useState<string[]>([
    CONSTANTS.ACTION.LIST_PACKAGES,
    CONSTANTS.ACTION.EDIT,
    CONSTANTS.ACTION.DEACTIVATE,
    CONSTANTS.ACTION.LOG,
    CONSTANTS.ACTION.DELETE,
  ]);
  const [filter, setFilter] = useState<FilterStructure>(DEFAULT_FILTER);
  const [logData, setLogData] = useState<any>({});
  const [isDisplayPopup, setIsDisplayPopup] = useState<boolean>(false);
  const [dataPopup, setDataPopup] = useState<any>({});
  const [isShowChangePackagePopup, setIsShowChangePackagePopup] =
    useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataUsers, setDataUsers] = useState<any>([]);

  useEffect(() => {
    const resolveFilter = {
      ...filter,
    };
    const currentUserType = _.get(getSavedUserData(), "username");
    if (currentUserType === "admin")
      setListActions([...listActions, CONSTANTS.ACTION.LOGIN_AS]);
    const stateFilter = _.get(location, "state.filter");
    if (stateFilter) {
      const clientId = _.get(stateFilter, "clientId");
      const accountId = _.get(stateFilter, "accountId");
      if (stateFilter && clientId)
        dispatch(fetchPackagesForUserPortal(clientId));
      if (stateFilter && clientId && accountId)
        _.assign(resolveFilter, { accountId, clientId });

      history.replace({ ...history.location, state: {} });
    }

    const userType = _.get(resolveFilter, "userType");
    setFilter(resolveFilter);
    dispatch(fetchAllClients({ status: CONSTANTS.STATUS.ACTIVE }));

    dispatch(
      fetchUsers(
        generateFilter({
          ...resolveFilter,
          userType: userType === "all" ? "" : userType,
        })
      )
    );

    return () => {
      dispatch(clearUserFilter());
      dispatch(clearClientsPayload());
      dispatch(clearUserPayload());
    };
  }, []);

  useEffect(() => {
    const clientId = _.get(filter, "clientId");
    const accountId = _.get(filter, "accountId");
    const userType = _.get(filter, "userType");
    if (filter && clientId) dispatch(fetchPackagesForUserPortal(clientId));
    if (filter && clientId && accountId)
      dispatch(
        fetchUsers({
          clientId,
          accountId,
          status: CONSTANTS.STATUS.ACTIVE,
          userType: userType === "all" ? "" : userType,
        })
      );
  }, []);

  useEffect(() => {
    if (!_.isEmpty(users)) {
      const getDataUsers: any[] = _.get(users, "items");
      if (currentPage > 1) {
        setDataUsers([...dataUsers, ...getDataUsers]);
      } else setDataUsers(getDataUsers);
    }
  }, [users]);

  const dataHead = [
    "no",
    "fullName",
    "email",
    "type",
    "clientName",
    "clientCode",
    "status",
    "actions",
  ];
  const dataTable = TableUltils._processDataTable(
    [
      "no",
      "id",
      "userData.fullName",
      "email",
      "userType",
      "clientId.clientName",
      "clientId.clientNumber",
      "status",
      "action",
    ],
    dataUsers
  );
  const listColumnCenter = [7, 8];

  const callbackAction = async (action: string, id: string, item: object) => {
    if (action === CONSTANTS.ACTION.LOG)
      setLogData({ logType: "user", rowId: id });

    if (action === CONSTANTS.ACTION.EDIT) {
      const data = _.find(users?.items, { id });
      setDataPopup(data);
      setIsDisplayPopup(true);
    }

    if (action === CONSTANTS.ACTION.LIST_PACKAGES) {
      const data = _.find(users?.items, { id });
      setDataPopup(data);
      setIsShowChangePackagePopup(true);
    }

    if (action === CONSTANTS.ACTION.LOGIN_AS) {
      const content = document.createElement("span");
      content.innerHTML = `${t("alert.areYouSureYouWantTo")} ${t(
        "alert.loginAs"
      )} ${t("alert.theUser")} <b>${_.get(item, "userData.fullName")}</b>  ?`;
      const isAgree = await popupAlert({
        title: t("alert.warning"),
        type: "warning",
        buttons: true,
        dangerMode: true,
        content,
      });
      if (isAgree) dispatch(loginAs(id));
      // setIsShowChangePackagePopup(true);
    }

    if (action === CONSTANTS.ACTION.DEACTIVATE) {
      const currentStatus = _.get(item, "status");
      const userName = _.get(item, "userData.fullName");
      const statusLabel =
        currentStatus === CONSTANTS.STATUS.ACTIVE
          ? t("alert.deactive")
          : t("alert.active");
      const content = document.createElement("span");
      content.innerHTML = `${t(
        "alert.areYouSureYouWantTo"
      )}</br> <b>${statusLabel}</b> ${t(
        "alert.theUser"
      )} <b>${userName}</b>  ?`;
      await popupAlert({
        type: "warning",
        title: t("alert.warning"),
        content,
        buttons: true,
        dangerMode: true,
      }).then(async (willContinue) => {
        if (willContinue) {
          if (currentStatus === CONSTANTS.STATUS.ACTIVE)
            await dispatch(deactivateUser(id));
          else await dispatch(activateUser(id));
          const userType = _.get(filter, "userType");
          const newFilter = {
            ...filter,
            userType: userType === "all" ? "" : userType,
          };
          dispatch(fetchUsers(generateFilter(newFilter)));
        }
      });
    }

    if (action === CONSTANTS.ACTION.DELETE) {
      const content = document.createElement("span");
      content.innerHTML = `${t("alert.areYouSureYouWantToRemove")} ${t(
        "alert.user"
      )}`;
      popupAlert({
        type: "warning",
        title: t("alert.warning"),
        content: content,
        buttons: true,
        dangerMode: true,
      }).then(async (willContinue) => {
        if (willContinue) {
          dispatch(deleteUser(id, filter));
        }
      });
    }
  };

  const setValueStatus = (status: string) => {
    const userType = _.get(filter, "userType");
    const newFilter = {
      ...filter,
      status,
      page: 1,
    };
    setFilter(newFilter);
    dispatch(
      fetchUsers(
        generateFilter({
          ...newFilter,
          userType: userType === "all" ? "" : userType,
        })
      )
    );
    setCurrentPage(1);
  };

  const onChangePage = (page: number) => {
    const userType = _.get(filter, "userType");
    const newFilter = {
      ...filter,
      page,
    };
    setFilter(newFilter);
    dispatch(
      fetchUsers(
        generateFilter({
          ...newFilter,
          userType: userType === "all" ? "" : userType,
        })
      )
    );
  };

  const _renderTable = () => {
    return (
      <Table
        dataHeader={dataHead}
        dataTable={dataTable}
        fitColumns={[1, 7]}
        listColumnCenter={listColumnCenter}
        hasPagination={true}
        callbackCurrentPageLink={(e: number) => onChangePage(e)}
        metaPagination={users.meta}
        callBackAction={callbackAction}
        listActionItem={listActions}
        loading={usersIsLoading}
        showMore={onShowMore}
      />
    );
  };

  const onCreate = () => {
    setIsDisplayPopup(true);
  };

  const onReset = () => {
    setFilter(DEFAULT_FILTER);
    setCurrentPage(1);
    const userType = _.get(DEFAULT_FILTER, "userType");
    dispatch(
      fetchUsers(
        generateFilter({
          ...DEFAULT_FILTER,
          userType: userType === "all" ? "" : userType,
        })
      )
    );
  };

  const onCloseUserDetails = async (isFetch?: boolean) => {
    if (isFetch) {
      const userType = _.get(filter, "userType");
      await dispatch(
        fetchUsers(
          generateFilter({
            ...filter,
            userType: userType === "all" ? "" : userType,
          })
        )
      );
    }
    dispatch(setDefaultRegisterResult());
    setDataPopup({});
    if (isDisplayPopup) setIsDisplayPopup(false);
    if (isShowChangePackagePopup) setIsShowChangePackagePopup(false);
  };

  const onSearch = async (page: number) => {
    const userType = _.get(filter, "userType");
    const newFilter = {
      ...filter,
      page: page,
    };
    setFilter(newFilter);
    setCurrentPage(page);
    dispatch(
      fetchUsers(
        generateFilter({
          ...newFilter,
          userType: userType === "all" ? "" : userType,
        })
      )
    );
  };

  const onFetchMore = () => {
    if (!usersIsLoading && currentPage < _.get(users, "meta.totalPages"))
      onSearch(filter.page + 1);
  };

  const onShowMore = () => {
    if (!usersIsLoading && currentPage < _.get(users, "meta.totalPages"))
      onSearch(filter.page + 1);
  };

  const onChangeClient = (clientId: string) => {
    setFilter({ ...filter, clientId });
    dispatch(fetchPackagesForUserPortal(clientId));
  };

  const renderMain = () => {
    return (
      <>
        {isDisplayPopup && (
          <UserDetails
            onClose={(isFetch?: boolean) => onCloseUserDetails(isFetch)}
            data={dataPopup}
          />
        )}
        {isShowChangePackagePopup && (
          <ChangePackage
            onClose={(isFetch?: boolean) => onCloseUserDetails(isFetch)}
            data={dataPopup}
            isAdmin
          />
        )}
        {!_.isEmpty(logData) && (
          <LogsDetails onClose={() => setLogData({})} data={logData} />
        )}
        <ContainerSearch className={usersIsLoading ? "loading" : ""}>
          <FlexWrapRow>
            <Dropdown
              value={_.get(filter, "userType")}
              callbackPayload={(value) =>
                setFilter({ ...filter, userType: value })
              }
              options={[
                { label: t("dropdown.all"), value: "all" },
                { label: t("dropdown.client"), value: "client" },
                { label: t("dropdown.user"), value: "user" },
              ]}
              placeholder={t("placeholder.userType")}
              search={false}
              loading={clientIsLoading}
              {...InputStyleProps}
            />
            <Dropdown
              value={_.get(filter, "clientId")}
              callbackPayload={(value) => onChangeClient(value)}
              options={generateDropdown({
                data: clients,
                key: "clientName",
                value: "id",
              })}
              placeholder={t("placeholder.client")}
              search={false}
              loading={clientIsLoading}
              {...InputStyleProps}
            />
            <Dropdown
              value={_.get(filter, "accountId")}
              callbackPayload={(value) =>
                setFilter({ ...filter, accountId: value })
              }
              options={processDataDropDownAccount(packages)}
              placeholder={t("placeholder.package")}
              search={false}
              loading={isLoading}
              disabled={
                _.isEmpty(_.get(filter, " clientId")) && _.isEmpty(packages)
              }
              {...InputStyleProps}
            />
            <Input
              value={_.get(filter, "keyword")}
              onChangeHandler={(e) =>
                setFilter({ ...filter, keyword: e.target.value })
              }
              onEnter={() => onSearch(1)}
              placeholder={t("placeholder.keyword")}
              {...InputStyleProps}
            />
            <Button onClick={() => onSearch(1)} {...ButtonStyleProps}>
              {t("button.search")}
            </Button>
            <Button onClick={() => onReset()} {...ButtonStyleProps}>
              {t("button.reset")}
            </Button>
            <Button
              onClick={() => onCreate()}
              background={ColorName.william}
              {...ButtonStyleProps}
            >
              + {t("button.create")}
            </Button>
          </FlexWrapRow>
          <Checkbox
            checked={_.get(filter, "status") === CONSTANTS.STATUS.INACTIVE}
            context={t("label.showInactiveUsers")}
            onChangeHandler={(type: boolean) =>
              setValueStatus(
                type ? CONSTANTS.STATUS.INACTIVE : CONSTANTS.STATUS.ACTIVE
              )
            }
            margin="10px 0 0 0"
          />
        </ContainerSearch>
        {_renderTable()}
      </>
    );
  };
  return (
    <DefaultLayout
      loading={loginAsIsLoading}
      content={renderMain()}
      parent={RouteTypes.ADMIN_ROUTES.GROUPS}
      currentPage={RouteTypes.ADMIN_ROUTES.USERS}
      title={t("titlePage.users")}
      onScrollBottom={() => onFetchMore()}
    />
  );
};

export default ListUser;
