/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React from "react";
import {
  MailTagListContainer,
  MailTagRow,
  MailsField,
  MailItem,
} from "./MailTagList.styles";
import { Textarea } from "components/Common";
import { useTranslation } from "react-i18next";

interface SectionProps {
  mails: string[];
  onChange(mails: string[]): void;
  required?: boolean;
}

const AddMoreMails = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { mails, onChange } = props;

  const removeMail = (value: string) => {
    const resolveMails = _.filter(mails, (item) => item !== value);
    if (onChange) onChange(resolveMails);
  };

  const onChangeInput = (e: any) => {
    const isIncludeComma = _.includes(e, ",");
    if (isIncludeComma) {
      const splitMails = _.split(e, ",");
      onChange(splitMails);
    } else onChange(_.compact([e]));
  };

  return (
    <MailTagListContainer>
      <span className="note">{t("label.noteComma")}</span>
      <MailTagRow>
        <Textarea
          width="50%"
          flex={1}
          rows={5}
          onChangeHandler={(e: any) => onChangeInput(e.target.value)}
          value={_.join(mails, ",")}
        />
        <MailsField>
          {!_.isEmpty(mails)
            ? _.map(mails, (item, index) => {
                return (
                  <MailItem
                    key={`mail-${index}`}
                    onClick={() => removeMail(item)}
                  >
                    <span>{_.trim(item)}</span>
                    <i className="fas fa-times" />
                  </MailItem>
                );
              })
            : t("label.emailIsEmpty")}
        </MailsField>
      </MailTagRow>
    </MailTagListContainer>
  );
};

export default AddMoreMails;
