import dayjs from "dayjs";
import _ from "lodash";
import XLSX from "xlsx-js-style";

interface SectionProps {
  data: any;
  total: any;
}

const ExcelFile = (props: SectionProps) => {
  const { data, total } = props;

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const ws_data = [
    [
      "No",
      "Headline",
      "Published Date",
      "Source",
      "",
      "",
      "Print",
      "Web",
      "TV",
      "Social Media",
      "All",
    ],
  ];

  _.forEach(data, (item: any, index: any) => {
    const resolveData = [
      index + 1,
      _.get(item, "title"),
      dayjs(_.get(item, "publishedAt")).format("MM-DD-YYYY"),
      _.get(item, "source.name"),
    ];
    if (index === 0)
      resolveData.push(
        ...[
          "",
          "",
          _.get(total, "print"),
          _.get(total, "web"),
          _.get(total, "tv"),
          _.get(total, "social"),
          _.get(total, "all"),
        ]
      );
    ws_data.push(resolveData);
  });

  const ws = XLSX.utils.aoa_to_sheet(ws_data);

  const HEADER_STYLE = {
    font: {
      name: "arial",
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true, // any truthy value here
    },
    border: {
      right: {
        style: "thin",
        color: "000000",
      },
      left: {
        style: "thin",
        color: "000000",
      },
      bottom: {
        style: "thin",
        color: "000000",
      },
    },
  };

  const CONTENT_STYLE = {
    font: {
      name: "arial",
      sz: "13",
    },
    alignment: {
      vertical: "center",
      horizontal: "left",
      wrapText: true, // any truthy value here
    },
    border: {
      right: {
        style: "thin",
        color: "000000",
      },
      left: {
        style: "thin",
        color: "000000",
      },
      bottom: {
        style: "thin",
        color: "000000",
      },
    },
  };

  const TOTAL_STYLE = {
    font: {
      name: "arial",
      sz: "13",
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
      wrapText: true, // any truthy value here
    },
    border: {
      right: {
        style: "thin",
        color: "000000",
      },
      left: {
        style: "thin",
        color: "000000",
      },
      bottom: {
        style: "thin",
        color: "000000",
      },
    },
  };

  if (
    ws["A1"] &&
    ws["B1"] &&
    ws["C1"] &&
    ws["D1"] &&
    ws["G1"] &&
    ws["H1"] &&
    ws["I1"] &&
    ws["J1"] &&
    ws["K1"] &&
    ws["G2"] &&
    ws["H2"] &&
    ws["I2"] &&
    ws["J2"] &&
    ws["K2"]
  ) {
    ws["A1"].s = HEADER_STYLE;
    ws["B1"].s = HEADER_STYLE;
    ws["C1"].s = HEADER_STYLE;
    ws["D1"].s = HEADER_STYLE;
    ws["G1"].s = HEADER_STYLE;
    ws["H1"].s = HEADER_STYLE;
    ws["I1"].s = HEADER_STYLE;
    ws["J1"].s = HEADER_STYLE;
    ws["K1"].s = HEADER_STYLE;
    ws["G2"].s = TOTAL_STYLE;
    ws["H2"].s = TOTAL_STYLE;
    ws["I2"].s = TOTAL_STYLE;
    ws["J2"].s = TOTAL_STYLE;
    ws["K2"].s = TOTAL_STYLE;
  }

  ws["!cols"] = [
    { width: 10 },
    { width: 50 },
    { width: 15 },
    { width: 20 },
    { width: 10 },
    { width: 10 },
    { width: 10 },
    { width: 10 },
    { width: 10 },
    { width: 15 },
    { width: 10 },
  ];
  const ws_rows = [{ hpt: 15 }];

  _.forEach(data, (item: any, index: any) => {
    const id = _.get(item, "id");
    const link = `${process.env.REACT_APP_FE_URL}/articles/${id}`;
    const currentCell = `B${index + 2}`;
    if (
      !ws[currentCell].l &&
      !ws[`A${index + 2}`].s &&
      !ws[`B${index + 2}`].s &&
      !ws[`C${index + 2}`].s &&
      !ws[`D${index + 2}`].s
    ) {
      ws[currentCell].l = { Target: link };
      ws[`A${index + 2}`].s = {
        ...CONTENT_STYLE,
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: true, // any truthy value here
        },
      };
      ws[`B${index + 2}`].s = CONTENT_STYLE;
      ws[`C${index + 2}`].s = CONTENT_STYLE;
      ws[`D${index + 2}`].s = CONTENT_STYLE;
      ws_rows.push({ hpt: 20 });
    }
  });

  ws["!rows"] = ws_rows;

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, ws, "Total");
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const resolveData = new Blob([excelBuffer], { type: fileType });
  return resolveData;
};

export default ExcelFile;
