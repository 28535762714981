import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import { Packer } from "docx";
import {
  Box,
  Grid,
  Typography,
  TextField,
  IconButton,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  // FormLabel,
  RadioGroup,
  Radio,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import { styled, alpha } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Menu, { MenuProps } from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { RootState } from "reducers";
import {
  DataRecordsActions,
  ReportActions,
  EmailTemplateActions,
  DashboardActions,
} from "actions";

import RouteTypes from "constants/RouteTypes";
import ColorName from "constants/Variables";
import { ViewDetailsNews } from "components/Common/Popup";
import PDFFile from "./ExportReportPDFFile";
import WordFile from "./ExportReportWordFile";
import ExcelFile from "./ExportReportToExcel";
import ExcelFileMail from "components/Common/Popup/Reports/AttachExcel";
import PDFFileMail from "components/Common/Popup/Reports/AttachPDF";

import history from "../../../history";
import { popupAlert, processDataDropDownReports } from "libs/Utils";

import DefaultLayout from "components/AdminLayout/DefaultLayout";
import { CONSTANTS } from "../../../constants/Constants";
import { FormLabel } from "components/Common";
import LoadingButton from "@mui/lab/LoadingButton";

const filterDataRecords = {
  page: 1,
  limit: 20,
  sortBy: "createdAt",
  orderBy: "DESC",
  status: "active",
};

const {
  searchReports,
  getReportByID,
  clearReportDetails,
  fetchEmailDelivery,
  getEmailDelivery,
  updateReport,
} = ReportActions;
const {
  clearDataRecords,
  setDefaultSendPostStatus,
  sendAttachmentToMail,
  sendCheckedPostToMail,
  searchDataRecord,
} = DataRecordsActions;
const { getEmailTemplateByType, clearEmailTemplateDetails } =
  EmailTemplateActions;
const { reportDashboard } = DashboardActions;

const regexEmail =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const CardStyles: any = {
  width: 1,
  transition: ".4s transform linear, .2s box-shadow linear",
  ":hover": {
    boxShadow: "0px 8px 14px -4px rgba(0,0,0,0.2)",
    transform: "translate(0px, -3px)",
  },
  cursor: "pointer",
};
interface DialogTitleProps {
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Reports: React.FC = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const isReportLoading = useSelector((state: RootState) => {
    return state.Reports.isLoading;
  });
  const isDataRecordLoading = useSelector((state: RootState) => {
    return state.DataRecord.isLoading;
  });
  const reports = useSelector((state: RootState) => {
    return state.Reports.reports;
  });
  const report = useSelector((state: RootState) => {
    return state.Reports.report;
  });
  const getEmailIsLoading = useSelector((state: RootState) => {
    return state.Reports.isGetEmailLoading;
  });
  const emailDistribution = useSelector((state: RootState) => {
    return state.Reports.emailDistribution;
  });
  const emailsStorage = useSelector((state: RootState) => {
    return state.Reports.emails;
  });
  const sendAttachStatus = useSelector((state: RootState) => {
    return state.DataRecord.sendAttachStatus;
  });
  const sendMailIsLoading = useSelector((state) =>
    _.get(state, "DataRecord.isLoading")
  );
  const dataRecords = useSelector((state: RootState) => {
    return state.DataRecord.dataRecords;
  });
  const dashboardData = useSelector((state: RootState) => {
    return state.Dashboard.reportDashboard;
  });
  const [filteredArticles, setFilteredArticles] = useState<any>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>("all");
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(50);
  const [exportBy, setExportBy] = useState<string>("pdf");
  const [reportId, setReportId] = useState<string>("");
  const [total, setTotal] = useState<any>({
    total: 0,
    totalPrint: 0,
    totalWeb: 0,
    totalSocialNetwork: 0,
    totalTv: 0,
  });
  const [isShowSendMailPopup, setIsShowSendMailPopup] =
    useState<boolean>(false);
  const [emails, setEmails] = useState<string>("");
  const [toEmails, setToEmails] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [selectEmailDistribution, setSelectEmailDistribution] =
    useState<string>("");
  const [previewArticle, setPreviewArticle] = useState<any>(null);
  const [fullArticles, setFullArticles] = useState<any>([]);
  const [fullPagination, setFullPagination] = useState<any>({
    page: 1,
    limit: 20,
  });
  const [btnEl, setBtnEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    dispatch(
      searchReports({ fetch: "all", sortBy: "updatedAt", orderBy: "DESC" })
    );
    dispatch(fetchEmailDelivery());
    dispatch(getEmailTemplateByType(CONSTANTS.EMAIL_TYPE.EMAIL_REPORT));
    dispatch(
      searchDataRecord({
        ...fullPagination,
        sortBy: "createdAt",
        orderBy: "DESC",
        status: "active",
      })
    );
    dispatch(reportDashboard());
    return () => {
      dispatch(clearReportDetails());
      dispatch(clearEmailTemplateDetails());
      dispatch(clearDataRecords());
    };
  }, []);

  useEffect(() => {
    if (reportId) dispatch(getReportByID(reportId));
    else dispatch(clearReportDetails());
  }, [reportId]);

  useEffect(() => {
    if (selectEmailDistribution)
      dispatch(getEmailDelivery(selectEmailDistribution));
  }, [selectEmailDistribution]);

  useEffect(() => {
    if (sendAttachStatus === 2) {
      setIsShowSendMailPopup(false);
      dispatch(setDefaultSendPostStatus());
    }
  }, [sendAttachStatus]);

  useEffect(() => {
    if (!_.isEmpty(emailsStorage)) setEmails(_.join(emailsStorage, ", "));
  }, [emailsStorage]);

  useEffect(() => {
    if (!_.isEmpty(report)) {
      let totalPrint = 0;
      let totalTv = 0;
      let totalWeb = 0;
      let totalSocialNetwork = 0;
      let total = 0;
      const listData = _.get(report, "listData");
      _.map(listData, (data) => {
        const mediaType = _.get(data, "source.mediaType");
        if (mediaType === "web") totalWeb += 1;
        else if (mediaType === "tv") totalTv += 1;
        else if (mediaType === "print") totalPrint += 1;
        else if (mediaType === "social network") totalSocialNetwork += 1;
        total += 1;
      });
      setTotal({
        totalSocialNetwork,
        totalPrint,
        totalWeb,
        totalTv,
        total,
      });
      setFilteredArticles(listData);
      setPage(0);
      setSelectedFilter("all");
      setFullArticles([]);
      setFullPagination({ page: 1, limit: 20 });
    } else {
      setReportId("");
      setFilteredArticles([]);
      setFullPagination({
        ...fullPagination,
        ...dataRecords?.meta,
        page: dataRecords?.meta?.currentPage || fullPagination.page + 1,
      });
      setTotal({
        totalSocialNetwork: _.get(dashboardData, "socialNetwork"),
        totalPrint: _.get(dashboardData, "print"),
        totalWeb: _.get(dashboardData, "web"),
        totalTv: _.get(dashboardData, "tv"),
        total: _.get(dashboardData, "dataRecord"),
      });
    }
  }, [report, dataRecords, dashboardData]);

  useEffect(() => {
    if (!_.isEmpty(dataRecords?.items))
      setFullArticles([...fullArticles, ...dataRecords?.items]);
  }, [dataRecords]);

  const onCreate = () => {
    dispatch(clearDataRecords());
    history.push({
      pathname: RouteTypes.ADMIN_ROUTES.CREATE_REPORT,
    });
  };

  const onEdit = async () => {
    if (!reportId)
      return popupAlert({
        type: "warning",
        title: t("alert.warning"),
        text: t("alert.warningEmptyReport"),
      });
    else
      history.push({
        pathname: RouteTypes.ADMIN_ROUTES.UPDATE_REPORT,
        state: { id: reportId },
      });
  };

  const onSendMail = async () => {
    if (!reportId)
      return popupAlert({
        type: "warning",
        title: t("alert.warning"),
        text: t("alert.warningEmptyReport"),
      });
    setIsShowSendMailPopup(true);
  };
  const checkSelected = (value: string) =>
    selectedFilter === value
      ? { background: ColorName.burntSienna, color: "white" }
      : null;

  const onChangeFilter = (value: string) => {
    if (_.isEmpty(report)) {
      setFullArticles([]);
      if (value === "all") {
        dispatch(searchDataRecord(filterDataRecords));
      } else {
        dispatch(
          searchDataRecord({
            ...filterDataRecords,
            mediaType: value,
          })
        );
      }
    } else {
      if (value === "all") setFilteredArticles(_.get(report, "listData"));
      else {
        const newArticles = _.filter(_.get(report, "listData"), [
          "source.mediaType",
          value,
        ]);
        setFilteredArticles(newArticles);
      }
      setPage(0);
    }
    if (value === "social network") {
      setSelectedFilter("socialNetwork");
    } else setSelectedFilter(value);
  };

  const onRemove = () => {
    const formData = new FormData();
    formData.append("status", "inactive");
    dispatch(updateReport(formData, reportId, "remove"));
  };

  const onSendEmail = async (e: any) => {
    e.preventDefault();
    const splitEmails = emails.split(",");
    const splitToEmails = toEmails.split(",");
    const resolveEmail: string[] = [];
    _.forEach(splitEmails, (email) => {
      if (regexEmail.test(_.trim(email))) resolveEmail.push(_.trim(email));
    });
    _.forEach(splitToEmails, (toEmail) => {
      if (regexEmail.test(_.trim(toEmail))) resolveEmail.push(_.trim(toEmail));
    });

    const requireFields = [
      {
        label: t("label.emails"),
        value: resolveEmail,
      },
      {
        label: t("label.subject"),
        value: subject,
      },
    ];
    let message = `${t("alert.foundEmptyFields")}`;
    let isValid = true;
    _.map(requireFields, (field) => {
      const value = _.get(field, "value");
      const label = _.get(field, "label");
      if ((_.isString(value) && _.isEmpty(_.trim(value))) || _.isEmpty(value)) {
        isValid = false;
        message += `${label}\n`;
      }
    });
    if (!isValid)
      return popupAlert({
        title: t("alert.warning"),
        type: "warning",
        text: message,
        buttons: ["No", "Yes"],
      });

    const content = document.createElement("span");
    content.innerHTML = `${t(
      "alert.confirmSendCheckedPosts"
    )}\n <b>${resolveEmail.join(", ")}</b>`;

    const isAgree = await popupAlert({
      title: t("alert.confirm"),
      type: "warning",
      buttons: {
        cancel: {
          text: t("alert.cancel"),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: t("alert.send"),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
          focus: true,
        },
      },
      content,
    });

    const formData = new FormData();
    formData.append("subject", subject);

    if (exportBy === "pdf") {
      const getPDFFile = await PDFFileMail({ data: _.get(report, "listData") });
      formData.append("attachment", getPDFFile);
    }
    if (exportBy === "xls") {
      const getXLSFile = await ExcelFileMail({
        data: _.get(report, "listData"),
        total,
      });
      formData.append("attachment", getXLSFile);
    }
    _.forEach(resolveEmail, (email) => formData.append("emails[]", email));

    if (isAgree && exportBy !== "none")
      dispatch(sendAttachmentToMail(formData));
    if (isAgree && exportBy === "none") {
      dispatch(
        sendCheckedPostToMail({
          recordIds: _.map(_.get(report, "listData") || [], (item) => item?.id),
          subject,
          emails: resolveEmail,
        })
      );
    }
  };

  const onLoadMoreFullArticles = () => {
    if (
      reportId &&
      _.slice(filteredArticles, page * limit, page * limit + limit).length !==
        filteredArticles.length
    )
      setPage(page + 1);
    else {
      if (
        !isDataRecordLoading &&
        fullArticles.length < _.get(fullPagination, "totalItems") &&
        _.isEmpty(report)
      ) {
        if (selectedFilter === "all") {
          dispatch(
            searchDataRecord({
              ...filterDataRecords,
              page: fullPagination.page + 1,
            })
          );
        } else {
          dispatch(
            searchDataRecord({
              ...filterDataRecords,
              page: fullPagination.page + 1,
              mediaType: selectedFilter,
            })
          );
        }
      }
    }
  };

  const fetchShowMore = () => {
    if (
      reportId &&
      _.slice(filteredArticles, page * limit, page * limit + limit).length !==
        filteredArticles.length
    )
      setPage(page + 1);
    else {
      if (
        !isDataRecordLoading &&
        fullArticles.length < _.get(fullPagination, "totalItems") &&
        _.isEmpty(report)
      ) {
        if (selectedFilter === "all") {
          dispatch(
            searchDataRecord({
              ...filterDataRecords,
              page: fullPagination.page + 1,
            })
          );
        } else {
          dispatch(
            searchDataRecord({
              ...filterDataRecords,
              page: fullPagination.page + 1,
              mediaType: selectedFilter,
            })
          );
        }
      }
    }
  };

  const onExport = async (type: string) => {
    const reportName = _.lowerCase(_.get(report, "reportName"));
    const reportDate = dayjs().format("DD_MM_YYYY");
    const fileName = `${reportName}_${reportDate}`;
    const resolveReportName = _.replace(fileName, new RegExp(" ", "g"), "_");
    const totalRecords = {
      web: total.totalWeb,
      tv: total.totalTv,
      print: total.totalPrint,
      social: total.totalSocialNetwork,
      all: total.total,
    };
    if (type === "word") {
      const doc = WordFile({
        data: filteredArticles,
        total: totalRecords,
        logo: _.get(report, "logo"),
        title: _.get(report, "coverTitle") || "",
      });

      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, `${resolveReportName}.docx`);
      });
    }
    if (type === "pdf") {
      const pdfFile: any = await PDFFile({
        data: filteredArticles,
        total: totalRecords,
        title: _.get(report, "coverTitle"),
        logo: _.get(report, "logo"),
      });
      saveAs(pdfFile, `${resolveReportName}.pdf`);
    }
    if (type === "xls") {
      const excelFile: any = ExcelFile({
        data: filteredArticles,
        total: totalRecords,
      });
      saveAs(excelFile, `${resolveReportName}.xlsx`);
    }
    setBtnEl(null);
  };

  // Renders
  const _tableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell align="center" width={100} sx={{ fontWeight: "bold" }}>
          No
        </TableCell>
        <TableCell align="left" sx={{ fontWeight: "bold" }}>
          Headline
        </TableCell>
        <TableCell align="center" width={200} sx={{ fontWeight: "bold" }}>
          Published Date
        </TableCell>
        <TableCell align="left" width={200} sx={{ fontWeight: "bold" }}>
          Source
        </TableCell>
        <TableCell align="center" width={50} sx={{ fontWeight: "bold" }}>
          Preview
        </TableCell>
        {/* <TableCell align="center" width={100}>
          Actions
        </TableCell> */}
      </TableRow>
    </TableHead>
  );

  const _renderTable = () => (
    <TableContainer component={Paper} sx={{ width: 1, mt: 2 }}>
      <Table stickyHeader sx={{ minWidth: 1 }}>
        {_tableHeader()}
        <TableBody>
          {_.isEmpty(filteredArticles) && (
            <TableRow>
              <TableCell colSpan={3}>No Articles(s) found</TableCell>
            </TableRow>
          )}
          {!_.isEmpty(filteredArticles) &&
            _.map(
              _.slice(filteredArticles, page * limit, page * limit + limit),
              (article, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {page * limit + index + 1}
                    </TableCell>
                    <TableCell align="left">
                      {_.get(article, "title")}
                    </TableCell>
                    <TableCell align="center">
                      {dayjs(_.get(article, "publishedAt")).format(
                        "DD/MM/YYYY"
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {_.get(article, "source.name")}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => setPreviewArticle(article)}>
                        <PreviewIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const _renderFullTable = () => (
    <TableContainer component={Paper} sx={{ width: 1, mt: 2 }}>
      <Table stickyHeader sx={{ minWidth: 1 }}>
        {_tableHeader()}
        <TableBody>
          {_.isEmpty(fullArticles) ? (
            <TableRow>
              <TableCell colSpan={3}>No Articles(s) found</TableCell>
            </TableRow>
          ) : (
            _.map(fullArticles, (article, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="left">{_.get(article, "title")}</TableCell>
                  <TableCell align="center">
                    {dayjs(_.get(article, "publishedAt")).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell align="left">
                    {_.get(article, "source.name")}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => setPreviewArticle(article)}>
                      <PreviewIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <Box
        sx={{
          width: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 1,
        }}
      >
        <LoadingButton
          loading={isDataRecordLoading}
          // sx={{ border: "1px solid red", borderRadius: "20px", p: 1 }}
          onClick={() => fetchShowMore()}
          disabled={
            dataRecords?.meta?.currentPage === dataRecords?.meta?.totalPages
          }
        >
          {t("button.showMore")}{" "}
        </LoadingButton>
      </Box>
    </TableContainer>
  );

  const _renderTotal = () => (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={2}>
        <Card
          sx={{ ...CardStyles, ...checkSelected("all") }}
          onClick={() => onChangeFilter("all")}
        >
          <CardContent>
            <Box textAlign="center">
              {t("label.all")}
              <br />
              <b>{_.get(total, "total")}</b>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={2}>
        <Card
          sx={{ ...CardStyles, ...checkSelected("web") }}
          onClick={() => onChangeFilter("web")}
        >
          <CardContent>
            <Box textAlign="center">
              {t("label.web")}
              <br />
              <b>{_.get(total, "totalWeb")}</b>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={2}>
        <Card
          sx={{ ...CardStyles, ...checkSelected("print") }}
          onClick={() => onChangeFilter("print")}
        >
          <CardContent>
            <Box textAlign="center">
              {t("label.print")}
              <br />
              <b>{_.get(total, "totalPrint")}</b>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={2}>
        <Card
          sx={{ ...CardStyles, ...checkSelected("tv") }}
          onClick={() => onChangeFilter("tv")}
        >
          <CardContent>
            <Box textAlign="center">
              {t("label.tv")}
              <br />
              <b>{_.get(total, "totalTv")}</b>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={2}>
        <Card
          sx={{ ...CardStyles, ...checkSelected("socialNetwork") }}
          onClick={() => onChangeFilter("social network")}
        >
          <CardContent>
            <Box textAlign="center">
              Social Network
              <br />
              <b>{_.get(total, "totalSocialNetwork")}</b>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  const _renderSummary = useMemo(
    () => (
      <>
        <Grid item>
          <Typography
            sx={{ color: ColorName.burntSienna }}
            variant="h5"
            align="center"
          >
            {_.get(report, "reportName")}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={1} justifyContent="end">
            <Grid item>
              <Button
                variant="contained"
                color="info"
                startIcon={<SendIcon />}
                onClick={() => onSendMail()}
              >
                {t("button.sendMail")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="warning"
                startIcon={<EditIcon />}
                onClick={() => onEdit()}
              >
                {t("button.edit")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="success"
                startIcon={<FileDownloadIcon />}
                disableElevation
                id="expand-btn-menu"
                aria-controls={Boolean(btnEl) ? "customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(btnEl) ? "true" : undefined}
                endIcon={<KeyboardArrowDownIcon />}
                onClick={(e) => setBtnEl(e.currentTarget)}
              >
                {t("button.export")}
              </Button>
              <StyledMenu
                id="customized-menu"
                MenuListProps={{
                  "aria-labelledby": "expand-btn-menu",
                }}
                anchorEl={btnEl}
                open={Boolean(btnEl)}
                onClose={() => setBtnEl(null)}
              >
                <MenuItem onClick={() => onExport("pdf")} disableRipple>
                  PDF
                </MenuItem>
                <MenuItem onClick={() => onExport("xls")} disableRipple>
                  EXCEL
                </MenuItem>
                <MenuItem onClick={() => onExport("word")} disableRipple>
                  WORD
                </MenuItem>
              </StyledMenu>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteForeverIcon />}
                onClick={() => onRemove()}
              >
                {t("button.remove")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>{_renderTotal()}</Grid>
      </>
    ),
    [report, filteredArticles, selectedFilter, page, limit, btnEl]
  );

  const _renderDialog = () => (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={isShowSendMailPopup}
      maxWidth="lg"
    >
      <BootstrapDialogTitle
        onClose={() => {
          setIsShowSendMailPopup(false);
        }}
      >
        Send Email
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3} component="form" onSubmit={onSendEmail}>
          {/* <Grid item xs={6}>
            <FormControl disabled={getEmailIsLoading || sendMailIsLoading}>
              <FormLabel id="exportBy">Send With</FormLabel>
              <RadioGroup
                aria-labelledby="exportBy"
                defaultValue={exportBy}
                onChange={(e) => setExportBy(e.target.value)}
                row
              >
                <FormControlLabel value="pdf" label="PDF" control={<Radio />} />
                <FormControlLabel
                  value="xls"
                  label="Excel"
                  control={<Radio />}
                />
                <FormControlLabel
                  value="none"
                  label="None"
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
          </Grid> */}
          <Grid item xs={5}>
            <FormControl
              fullWidth
              disabled={getEmailIsLoading || sendMailIsLoading}
            >
              <FormLabel aria-label="require">{t("label.to")}</FormLabel>
              <TextField
                fullWidth
                value={toEmails}
                label="To"
                onChange={(e) => setToEmails(e.target.value)}
                disabled={getEmailIsLoading || sendMailIsLoading}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} sx={{ textAlign: "center", mt: 6 }}>
            <Typography>AND/OR</Typography>
          </Grid>
          <Grid item xs={5}>
            <FormLabel aria-label="require">Emails Distibution</FormLabel>
            <FormControl
              fullWidth
              disabled={getEmailIsLoading || sendMailIsLoading}
            >
              <InputLabel id="select-label">Emails Distibution</InputLabel>
              <Select
                labelId="select-label"
                value={selectEmailDistribution}
                fullWidth
                label="Emails Distibution"
                onChange={(e) => setSelectEmailDistribution(e.target.value)}
              >
                {_.map(emailDistribution || [], (item, index) => (
                  <MenuItem key={index} value={_.get(item, "id")}>
                    {_.get(item, "scheduleName")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: ColorName.william,
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              Distribution List Emails
            </Typography>
            <Typography>{emails}</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormLabel aria-label="require">Subject</FormLabel>
            <TextField
              fullWidth
              value={subject}
              label="Subject"
              required
              onChange={(e) => setSubject(e.target.value)}
              disabled={getEmailIsLoading || sendMailIsLoading}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              rows={5}
              value={emails}
              label="Emails"
              required
              multiline
              onChange={(e) => setEmails(e.target.value)}
              disabled={getEmailIsLoading || sendMailIsLoading}
              helperText={t("placeholder.noteComma")}
            />
          </Grid> */}
          {/* <Grid item xs={12}>
            <Typography
              sx={{
                color: ColorName.william,
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              Description
            </Typography>
            <Typography>{emails}</Typography>
          </Grid> */}
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="end">
              <Button
                type="submit"
                variant="contained"
                endIcon={<SendIcon />}
                disabled={getEmailIsLoading || sendMailIsLoading}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </BootstrapDialog>
  );

  const _renderHeader = () => (
    <Grid container spacing={2} justifyContent="space-between">
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="select-label">Reports</InputLabel>
          <Select
            labelId="select-label"
            value={reportId}
            label="Reports"
            onChange={(e) => setReportId(e.target.value)}
            size="small"
            sx={{ mt: 1 }}
          >
            <MenuItem value=""> -- Default -- </MenuItem>
            {_.map(processDataDropDownReports(reports) || [], (option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          onClick={() => onCreate()}
          startIcon={<AddIcon />}
          color="success"
          sx={{
            fontWeight: 600,
            backgroundColor: ColorName.william,
            "&:hover": { background: ColorName.piper },
            mt: 1,
          }}
        >
          {t("button.create")}
        </Button>
      </Grid>
    </Grid>
  );

  const renderMain = () => {
    return (
      <Grid container direction="column" spacing={2}>
        {_renderDialog()}
        {previewArticle && (
          <ViewDetailsNews
            data={previewArticle}
            onClose={() => setPreviewArticle(null)}
          />
        )}
        <Grid item>
          <Typography sx={{ color: ColorName.burntSienna, fontWeight: "bold" }}>
            <i className="fas fa-calendar-day" /> Today:{" "}
            {dayjs().format("DD/MM/YYYY")}
          </Typography>
        </Grid>
        <Grid item>{_renderHeader()}</Grid>
        <Divider sx={{ my: 1 }} />
        {!_.isEmpty(report) && _renderSummary}
        {_.isEmpty(report) && _renderTotal()}
        {reportId && !_.isEmpty(report) ? _renderTable() : _renderFullTable()}
        {(isReportLoading || isDataRecordLoading) && (
          <Grid item textAlign="center">
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <DefaultLayout
      content={renderMain()}
      parent={RouteTypes.ADMIN_ROUTES.MANAGE_REPORTS}
      currentPage={RouteTypes.ADMIN_ROUTES.REPORT}
      title={t("titlePage.reports")}
      onScrollBottom={() => onLoadMoreFullArticles()}
    />
  );
};
export default Reports;
