import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Wrapper,
  Menu,
  ChildItem,
  MenuItem,
  MenuChildItem,
  NewMessagesBox,
} from "./SideMenu.styles";
import history from "../../../../history";
import Header from "../Header";
import SIDEMENU_LIST from "./SideMenuList";
import { getSavedUserData } from "libs/Utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RouteTypes from "../../../../constants/RouteTypes";
import DynamicMenuList from "./DynamicMenuList";

interface SectionProps {
  currentPage?: string;
  parent?: string;
}

const CONTACT_ROUTES = [
  RouteTypes.ADMIN_ROUTES.CONTACT,
  RouteTypes.CLIENT_ROUTES.CONTACT,
];

const SideMenu: React.FC<SectionProps> = (props: SectionProps) => {
  const { t } = useTranslation("translation");
  const { parent, currentPage } = props;

  const totalNewMessages = useSelector((state) =>
    _.get(state, "Message.totalNewMessages") || 0
  );

  const [activeParentMenu, setActiveParentMenu] = useState(
    parent ? parent : ""
  );
  const [isFull, setIsFull] = useState(
    _.isNull(localStorage.getItem("fullMenu")) ||
      localStorage.getItem("fullMenu") === "true"
      ? true
      : false
  );
  const [showFirst, setShowFirst] = useState(true);
  const userData = getSavedUserData();

  useEffect(() => {
    if (localStorage.getItem("fullMenu") === "false") return setIsFull(false);
  }, []);

  const onClickMenu = (router: string, child?: any[]) => {
    if (router) {
      if (child) {
        const activeMenu = activeParentMenu === router ? "" : router;
        setActiveParentMenu(activeMenu);
        localStorage.setItem("parentActive", activeMenu);
      } else history.push(router);
    }
  };

  const renderChildItem = (child: any[], parentRouter: string) => {
    if (_.isArray(child) && child) {
      const render = _.map(child, (item, index) => {
        const { label, router, icon } = item;
        return (
          <ChildItem
            className={currentPage === router ? "active" : ""}
            onClick={() => history.push({ pathname: router })}
            key={`child-item-${label}-${index}`}
          >
            <img src={icon} alt={t(`sideMenu.${label}`)} />
            <span>{t(`sideMenu.${label}`)}</span>
          </ChildItem>
        );
      });
      return (
        <MenuChildItem
          className={parentRouter === activeParentMenu ? "active" : ""}
          height={child && child.length}
        >
          {render}
        </MenuChildItem>
      );
    }
    return null;
  };

  const renderStaticMenu = (menu: any) => {
    const render = _.map(menu, (item, index) => {
      const { label, router, icon } = item;
      const child = _.get(item, "child");
      return (
        <React.Fragment key={`menu-group-${index}`}>
          <MenuItem
            className={currentPage === router ? "active" : ""}
            onClick={() => onClickMenu(router, child)}
            key={`menu-item-${index}`}
          >
            <img src={icon} alt={t(`sideMenu.${label}`)} />
            <span>{t(`sideMenu.${label}`)}</span>
            {child && (
              <i
                className={
                  router === activeParentMenu
                    ? "fas fa-chevron-down"
                    : "fas fa-chevron-right"
                }
              />
            )}
            {_.includes(CONTACT_ROUTES, router) && totalNewMessages > 0 && (
              <NewMessagesBox />
            )}
          </MenuItem>
          {child && renderChildItem(child, router)}
        </React.Fragment>
      );
    });
    return render;
  };

  const renderDynamicMenu = () => {
    const roleData = _.get(userData, "role");
    const roleName = _.get(roleData, "name");

    if (roleName === "Portal Administrator")
      return renderStaticMenu(SIDEMENU_LIST["admin"]);

    const description = _.get(roleData, "description");
    const accessPages = _.split(description, "/");
    const resolveMenu: any[] = [];
    _.forEach(accessPages, (item: string) => {
      const findMenuItem = _.get(DynamicMenuList, _.upperCase(item));
      if (findMenuItem) resolveMenu.push(findMenuItem);
    });

    return renderStaticMenu(resolveMenu);
  };

  const renderMenuNavigation = () => {
    const userType = _.get(userData, "userType");
    // const roleName = _.get(userData, "role.name");
    const isAdmin = userType === "admin";
    const isClient = !_.get(userData, "role") && userType === "client";
    let render;
    if (isAdmin || isClient) {
      const menu = _.get(SIDEMENU_LIST, userType);
      render = renderStaticMenu(menu);
    } else render = renderDynamicMenu();

    return render;
  };

  return (
    <Wrapper className={showFirst ? "show-first " : "show-not-first"}>
      {/* <div
        className="toggle"
        onClick={() => {
          setIsFull(!isFull);
          setShowFirst(false);
          localStorage.setItem("fullMenu", isFull ? "false" : "true");
        }}
      >
        <i
          className={`far fa-arrow-alt-circle-${
            isFull ? "left" : "right"
          } icon-menu`}
        />
      </div> */}
      <Menu className={isFull ? "show-sidebar" : "hide-sidebar"}>
        <MenuItem>
          <Header
            className={isFull ? "show-sidebar-logo" : "hide-sidebar-logo"}
          />
        </MenuItem>
        <div className="menu-navigation">{renderMenuNavigation()}</div>
      </Menu>
    </Wrapper>
  );
};

export default SideMenu;
